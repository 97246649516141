import { useQuery, type UseQueryOptions } from 'react-query'

import { type AxiosError } from 'axios'

import { getStreamingPipelineEvents } from 'api/clients'
import { useNetworkErrorToast, useStreamingClient } from 'api/hooks/index'
import type { GETStreamingPipelineEventsResponse } from 'api/types'

import { QueryKey } from 'constants/endpoint'

type TData = GETStreamingPipelineEventsResponse
type TError = AxiosError<unknown>
type TOptions = UseQueryOptions<TData, TError, TData>

export const useGetStreamingPipelineEvents = (
  pipelineId: string,
  projectId?: string,
  options?: TOptions
) => {
  const streamingClient = useStreamingClient()
  const makeErrorToast = useNetworkErrorToast()

  return useQuery<TData, TError>(
    [QueryKey.STREAMING_PIPELINE_EVENTS, pipelineId],
    async () =>
      // @ts-expect-error 'enabled' property below disables request if projectId is undefined
      getStreamingPipelineEvents({ projectId, pipelineId }, streamingClient),
    {
      ...options,
      enabled: !!projectId,
      onError: (error) => {
        makeErrorToast({ message: error.message })
      }
    }
  )
}
