import { type FunctionComponent } from 'react'

import classNames from 'classnames'

import { type SourceName } from 'types'
import {
  type StreamingDestinationId,
  type StreamingSourceId
} from 'types/Streaming'

import classes from './CircledImage.module.scss'

interface ImageProps {
  width?: number
  height?: number
  role: string
}

interface CircledImageProps {
  logoName: SourceName | StreamingSourceId | StreamingDestinationId
  className?: string
  Image: FunctionComponent<ImageProps>
  imageContainerSize: number
  imageSize: number
}

export const CircledImage: FunctionComponent<CircledImageProps> = ({
  Image,
  logoName,
  className,
  imageContainerSize,
  imageSize,
  ...props
}) => {
  return (
    <div className={classNames(classes.CircledImage, className)} {...props}>
      <div
        className={classNames(classes.CircledImage__ImageContainer)}
        style={{ width: imageContainerSize, height: imageContainerSize }}
      >
        <Image
          width={imageSize}
          height={imageSize}
          role="img"
          aria-label={`${logoName as string} image`}
        />
      </div>
    </div>
  )
}
