import type { ChangeEvent, FocusEvent, FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'

import classNames from 'classnames'
import { useFormikContext } from 'formik'

import { Field } from '@matillion/component-library'

import classes from 'modules/FormsContent/Form.module.scss'
import { ReadableCron } from 'modules/Projects/CreateSchedule/FormContent/Fields/Schedule/Common'
import { type ScheduleFormValues } from 'modules/Projects/CreateSchedule/types'
import { renderFormikError } from 'modules/utils'

export const CronExpression: FunctionComponent = () => {
  const { t } = useTranslation()
  const { values, handleChange, handleBlur, errors, touched } =
    useFormikContext<ScheduleFormValues>()

  const cronError = renderFormikError(
    errors.schedule?.cronExpression,
    Boolean(touched.schedule?.cronExpression)
  )

  const getValueInput = (val: string) => {
    return {
      target: {
        name: 'schedule.cronExpression',
        value: val
      }
    }
  }

  return (
    <div data-testid="schedule-cron-expression">
      <Field
        title={t('createSchedule.fields.cronExpression.title')}
        data-testid="schedule-cron-expression-input"
        name="cronExpression"
        supportText={t('createSchedule.fields.cronExpression.supportText')}
        value={values.schedule.cronExpression}
        placeholder={t('createSchedule.fields.cronExpression.placeholderText')}
        className={classNames(classes.Form__Field)}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          handleChange(getValueInput(e.target.value))
        }}
        onBlur={(e: FocusEvent<HTMLInputElement>) => {
          handleBlur(getValueInput(e.target.value))
        }}
        errorText={cronError ? t(cronError) : null}
        hasError={
          Boolean(errors.schedule?.cronExpression) &&
          Boolean(touched.schedule?.cronExpression)
        }
        required
      />
      <ReadableCron
        cronExpression={values.schedule.cronExpression}
        timezone={values.schedule.timezone?.name}
      />
    </div>
  )
}

export default CronExpression
