import { type FunctionComponent } from 'react'
import { useLocation } from 'react-router-dom'

import { PROJECTS_CREDENTIALS, PROJECTS_DEFAULTS } from 'constants/route'

import { SnowflakeCredentials } from './Credentials'
import { SnowflakeDefaults } from './Defaults'

interface SnowflakeProps {
  onCancel?: () => void
  onPrevious?: () => void
  showCancel?: boolean
  showPrevious?: boolean
  modalContent?: string
  excludeHeader?: boolean
  isEditEnvironment?: boolean
  isCreateEnvironment?: boolean
  keyPairEnabled?: boolean
}

const Snowflake: FunctionComponent<SnowflakeProps> = ({ ...props }) => {
  const location = useLocation()

  const currentPath = location.pathname.substring(
    location.pathname.lastIndexOf('/') + 1
  )

  const paths: { [key: string]: JSX.Element } = {
    [PROJECTS_CREDENTIALS]: <SnowflakeCredentials {...props} />,
    [PROJECTS_DEFAULTS]: <SnowflakeDefaults {...props} />
  }

  return paths[currentPath] ?? null
}

export default Snowflake
