import { isBefore, parseISO } from 'date-fns'
import { format, utcToZonedTime } from 'date-fns-tz'
import { capitalize } from 'lodash'
import { date, object, string } from 'yup'

import type {
  GETEnvironmentAgentsResponse,
  GETEnvironmentsResponse,
  GETSchedulesResponse
} from 'api/types'

import {
  defaultDateFormat,
  defaultOffsetFormat,
  defaultTimeFormat
} from 'constants/dateTimeFormats'

import { mapDayToText } from 'modules/Projects/CreateSchedule/FormContent/Fields/Schedule/StandardSchedule/DaysOfTheWeek'
import { type EditScheduleFormValues } from 'modules/Projects/EditSchedule/types'

import { ScheduleDisplayMode, ScheduleMethod, type ValidDays } from 'types'

import { isCronValid } from 'utils/cron'
import { nowPlus2Minutes } from 'utils/nowPlus2Minutes'

export const getScheduleType = (
  cronExpression?: string
): ScheduleMethod | undefined => {
  if (!cronExpression) {
    return undefined
  }

  const parts = cronExpression.split(' ')

  const minute = parts[1]
  const hour = parts[2]
  const dayOfMonth = parts[3]
  const dayOfWeek = parts[5]

  // Minute and Hour schedule types are only valid when dayOfMonth is '*',
  // OR when dayOfMonth is '?' while dayOfWeek is '*', otherwise the
  // expression can incorrectly be understood to be a Day or Week schedule type.
  //
  // E.g. A 'week' expression could be '0 0 */4 ? * MON,TUE ?', so for Hour and
  // Minute we need to check that dayOfWeek is also '*' when dayOfMonth is '?'
  if (
    hour !== '*' &&
    (dayOfMonth === '*' || (dayOfMonth === '?' && dayOfWeek === '*'))
  ) {
    return ScheduleMethod.HOUR
  } else if (
    minute !== '*' &&
    (dayOfMonth === '*' || (dayOfMonth === '?' && dayOfWeek === '*'))
  ) {
    return ScheduleMethod.MINUTE
  } else if (dayOfMonth !== '?' && dayOfWeek === '?') {
    return ScheduleMethod.DAY
  } else if (dayOfMonth === '?' && dayOfWeek !== '?') {
    return ScheduleMethod.WEEK
  }

  return undefined // If the expression doesn't match any known pattern
}

export const getRepeatEveryValue = (
  cronExpression?: string,
  scheduleType?: ScheduleMethod
) => {
  if (!cronExpression || !scheduleType) {
    return undefined
  }

  const splitCron = cronExpression.split(' ')
  const minutes = splitCron[1]
  const hours = splitCron[2]
  const days = splitCron[3]

  // 'Week' schedule type isn't covered as we can't change the 'Repeat every'
  // value for week
  switch (scheduleType) {
    case ScheduleMethod.MINUTE:
      return minutes
    case ScheduleMethod.HOUR:
      return hours
    case ScheduleMethod.DAY:
      return days
    default:
      return '1'
  }
}

export const initialValues = (
  scheduleId: string,
  selectedSchedule: GETSchedulesResponse,
  environments?: GETEnvironmentsResponse,
  agents?: GETEnvironmentAgentsResponse,
  isMHA?: boolean
): EditScheduleFormValues => {
  const s = selectedSchedule
  const e = environments?.find((x) => x.name === s.environment)
  const agent = agents?.find((x) => x.agentId === s.agentId)
  const cronExpression = s.cronExpression

  const [startDate, startTime, offset] = getEditScheduleStartDateTime({
    startDateTime: s.startTime,
    timezone: s.timezone
  })

  const displayMode = s.displayMode
  const splitCron = cronExpression?.split(' ')
  const repeatOn = splitCron?.[5]
  const hour = splitCron?.[2]
  const minute = splitCron?.[1]

  const scheduleType = getScheduleType(cronExpression)
  const repeatEvery = getRepeatEveryValue(cronExpression, scheduleType)

  const schedule: EditScheduleFormValues = {
    scheduleId,
    name: s.name,
    environment: {
      id: e?.id ?? '',
      name: e?.name ?? ''
    },
    pipeline: {
      id: s.job,
      name: s.job
    },
    agent: {
      id: s.agentId,
      name: (isMHA ? s.agentId : agent?.name) ?? ''
    },
    schedule: {
      displayMode: displayMode ?? ScheduleDisplayMode.ADVANCED,
      timezone: {
        id: s.timezone,
        name: s.timezone,
        utc: offset
      },
      startDate: startDate,
      cronExpression: cronExpression,
      repeatEvery: 1,
      unit: ScheduleMethod.DAY,
      repeatOn: [],
      hour: 8,
      minute: 0,
      startTime: startTime,
      ...(displayMode === ScheduleDisplayMode.BASIC && {
        cronExpression: '0 0 * ? * * *',
        repeatEvery: parseInt(repeatEvery?.match(/\/(\d+)/)?.[1] || '1'),
        unit: scheduleType ?? ScheduleMethod.DAY,
        repeatOn:
          scheduleType === ScheduleMethod.WEEK
            ? mapDayToText(
                repeatOn
                  ?.split(',')
                  .map((day) => capitalize(day)) as ValidDays[]
              )
            : [],
        hour: parseInt(hour ?? '8'),
        minute: parseInt(minute ?? '0')
      })
    }
  }

  return schedule
}

const getEditScheduleStartDateTime = ({
  startDateTime,
  timezone
}: {
  startDateTime: string
  timezone: string
}): string[] => {
  const today = Date.now()
  const parsedScheduleDateTime = parseISO(startDateTime)

  if (isBefore(parsedScheduleDateTime, today)) {
    const zonedDate = nowPlus2Minutes(timezone)
    return [
      format(zonedDate, defaultDateFormat, { timeZone: timezone }),
      format(zonedDate, defaultTimeFormat, { timeZone: timezone }),
      format(zonedDate, defaultOffsetFormat, { timeZone: timezone })
    ]
  }
  const zonedDateFromUtc = utcToZonedTime(parsedScheduleDateTime, timezone)

  return [
    format(zonedDateFromUtc, defaultDateFormat, { timeZone: timezone }),
    format(zonedDateFromUtc, defaultTimeFormat, { timeZone: timezone }),
    format(zonedDateFromUtc, defaultOffsetFormat, { timeZone: timezone })
  ]
}

export const validationSchema = object({
  name: string().required('editSchedule.fields.scheduleName.error.required'),
  environment: object({
    id: string().required('commonForm.fields.environment.error.required'),
    name: string().required('commonForm.fields.environment.error.required')
  })
    .required('commonForm.fields.environment.error.unmatched')
    .nullable(),
  pipeline: object({
    id: string().required('commonForm.fields.pipeline.error.required'),
    name: string().required('commonForm.fields.pipeline.error.required')
  })
    .required('commonForm.fields.pipeline.error.unmatched')
    .nullable(),
  agent: object({
    id: string().required('commonForm.fields.agent.error.required'),
    name: string().required('commonForm.fields.agent.error.required')
  })
    .required('commonForm.fields.agent.error.unmatched')
    .nullable(),
  schedule: object({
    timezone: object({
      id: string().required('editSchedule.fields.timezone.error.required'),
      name: string().required('editSchedule.fields.timezone.error.required')
    })
      .required('editSchedule.fields.timezone.error.unmatched')
      .nullable(),
    startDate: date().required('editSchedule.fields.startTime.error.required'),
    startTime: string().optional(), // to-do: update in the next parts
    cronExpression: string()
      .test(
        'Cron Validation',
        'editSchedule.fields.cronExpression.error.unmatched',
        (value) => {
          return isCronValid(value!)
        }
      )
      .required('editSchedule.fields.cronExpression.error.required')
  })
})
