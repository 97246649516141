import { useEffect, type FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import classNames from 'classnames'
import { capitalize } from 'lodash'

import { heap } from '@matillion/hub-client'

import classes from 'components/Form/Form.module.scss'
import { Header, HeaderTitle } from 'components/Header'

import { AppRoutes, PROJECTS_DEFAULTS } from 'constants/route'

import {
  SnowflakeDatabase,
  SnowflakeRole,
  SnowflakeSchema,
  SnowflakeWarehouse
} from 'modules/Common/WarehouseSources/Snowflake/Defaults/Fields'

import { Warehouse as WarehouseType } from 'types'

interface SnowflakeDefaultsProps {
  onCancel?: () => void
  onPrevious?: () => void
  showCancel?: boolean
  showPrevious?: boolean
  modalContent?: string
  isEditEnvironment?: boolean
}

export const SnowflakeDefaults: FunctionComponent<SnowflakeDefaultsProps> = ({
  onCancel,
  onPrevious,
  showCancel = false,
  showPrevious = false,
  modalContent,
  isEditEnvironment = false
}) => {
  const { t } = useTranslation()
  const location = useLocation()

  useEffect(() => {
    heap.track('Snowflake Defaults Screen', {
      onboarding: location.pathname.includes(
        `${AppRoutes.getOnboardingAdd()}/${PROJECTS_DEFAULTS}`
      )
    })
  }, [location.pathname])

  return (
    <>
      <Header
        onCancel={onCancel}
        showCancel={showCancel}
        onPrevious={onPrevious}
        showPrevious={showPrevious}
        modalContent={modalContent}
      >
        <HeaderTitle data-testid="snowflake-default-title">
          {t(
            isEditEnvironment
              ? 'formContent.editEnvironmentDefaults.title'
              : 'formContent.defaults.title',
            {
              projectType: capitalize(WarehouseType.Snowflake)
            }
          )}
        </HeaderTitle>
      </Header>
      <div
        className={classNames(
          classes.Form__InnerWrap,
          classes['Form__InnerWrap--space-top']
        )}
      >
        <SnowflakeRole />
        <SnowflakeWarehouse />
        <SnowflakeDatabase />
        <SnowflakeSchema />
      </div>
    </>
  )
}
