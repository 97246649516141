import { useState, type FC } from 'react'
import { useTranslation } from 'react-i18next'

import classNames from 'classnames'
import { useFormikContext } from 'formik'
import { map, sumBy, uniq } from 'lodash'

import { Icon, Table as T, Typography } from '@matillion/component-library'

import { IconButton } from 'components/IconButton/IconButton'

import { NO_DATA } from 'constants/strings'

import {
  useFormMetadata,
  type FormValues
} from 'modules/Projects/CreateStreamingPipeline/FormContent'
import { SchemaSelector } from 'modules/Projects/CreateStreamingPipeline/FormContent/ConfigurePipelineDetailsPage/Components/SchemaSelector'
import { getDatabaseString } from 'modules/Projects/CreateStreamingPipeline/FormContent/FormContent.util'

import { MultiTableSelector } from './MultiTableSelector'
import classes from './TableSelectionList.module.scss'

const { TableHeaderCell, Table, TableBody, TableRow, TableCell } = T

export const TableSelectionList: FC = () => {
  const { values } = useFormikContext<FormValues>()
  const { metadata } = useFormMetadata()
  const { t } = useTranslation()
  const [showSchemaSelector, setShowSchemaSelector] = useState(false)
  const [selectedSchema, setSelectedSchema] = useState<string | null>(null)

  const isConnected = metadata.validation.sourceConnection.isError === false
  const selectedSchemas = uniq(map(values.source.tables, 'schema'))
  const selectedSchemaCount = selectedSchemas.length
  const totalSchemaCount = isConnected
    ? Object.keys(metadata.source.schemas).length
    : NO_DATA
  const selectedTableCount = values.source.tables.length
  const totalTableCount = isConnected
    ? sumBy(
        selectedSchemas,
        (schema) => metadata.source.schemas[schema]?.tables?.length ?? 0
      )
    : NO_DATA

  const hasError = selectedTableCount === 0

  return (
    <div data-testid="table-selection-list">
      <Typography
        format="bcs"
        weight="bold"
        className={hasError && classes.TableSelectionList__ErroredText}
      >
        {t('createStreamingPipeline.fields.pipelineConfig.tablesList.title')}
      </Typography>
      <Table
        alt="light"
        className={classNames(
          classes.TableSelectionList__Table,
          hasError && classes.TableSelectionList__ErroredTable
        )}
      >
        <TableBody>
          <TableRow
            key="header"
            className={classes.TableSelectionList__TableHeader}
          >
            <TableHeaderCell width="4" align="left">
              {t(
                'createStreamingPipeline.fields.pipelineConfig.tablesList.databaseHeader'
              )}
            </TableHeaderCell>
            <TableHeaderCell width="3" align="left">
              {t(
                'createStreamingPipeline.fields.pipelineConfig.tablesList.schemasHeader'
              )}
            </TableHeaderCell>
            <TableHeaderCell width="3" align="left">
              {t(
                'createStreamingPipeline.fields.pipelineConfig.tablesList.tablesHeader'
              )}
            </TableHeaderCell>
            <TableHeaderCell width="2" />
          </TableRow>
          <TableRow className={classes.TableSelectionList__TableRow}>
            <TableCell width="4" align="left">
              <Typography format="bcs" data-testid="table-selections-database">
                {getDatabaseString(values, t)}
              </Typography>
            </TableCell>
            <TableCell width="3" align="left">
              <Typography
                format="bcs"
                data-testid="table-selections-schema-count"
              >{`${selectedSchemaCount} / ${totalSchemaCount}`}</Typography>
            </TableCell>
            <TableCell width="3" align="left">
              <Typography
                format="bcs"
                data-testid="table-selections-table-count"
              >{`${selectedTableCount} / ${totalTableCount}*`}</Typography>
            </TableCell>
            <TableCell width="2" align="right">
              <IconButton
                onClick={() => {
                  setShowSchemaSelector(true)
                }}
                disabled={!isConnected}
                disabledTooltipText={t(
                  'createStreamingPipeline.fields.pipelineConfig.tablesList.disabledTooltip'
                )}
                dataTestId="table-selection-button"
              >
                <Icon.Cog />
              </IconButton>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <Typography
        format="bcs"
        className={classes.TableSelectionList__SupportText}
      >
        {`* ${t('createStreamingPipeline.fields.pipelineConfig.tablesList.tableCountCaveat')}`}
      </Typography>

      {showSchemaSelector && !selectedSchema && (
        <SchemaSelector
          setSelectedSchema={setSelectedSchema}
          title={t(
            'createStreamingPipeline.fields.pipelineConfig.schemaSelector.title'
          )}
          onClose={() => {
            setShowSchemaSelector(false)
          }}
          onTransition={() => {
            setShowSchemaSelector(false)
          }}
        />
      )}

      {selectedSchema && (
        <MultiTableSelector
          onClose={() => {
            setSelectedSchema(null)
            setShowSchemaSelector(true)
          }}
          schema={selectedSchema}
        />
      )}
    </div>
  )
}
