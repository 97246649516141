import { type FC, type MouseEventHandler, type ReactNode } from 'react'

import { Tooltip } from '@matillion/component-library'

import classes from './IconButton.module.scss'

export interface IconButtonProps {
  children: ReactNode
  disabled: boolean
  disabledTooltipText: string
  onClick: MouseEventHandler<HTMLButtonElement>
  dataTestId: string
}

export const IconButton: FC<IconButtonProps> = ({
  children,
  disabled,
  disabledTooltipText,
  onClick,
  dataTestId
}) => {
  return (
    <Tooltip content={disabled ? disabledTooltipText : undefined}>
      <button
        type="button"
        disabled={disabled}
        onClick={onClick}
        data-testid={dataTestId}
        className={classes.IconButton}
      >
        {children}
      </button>
    </Tooltip>
  )
}
