import { type FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'

import { NoResourcesAvailable } from 'components/NoResourcesAvailable'

interface NoDataProps {
  onAdd: VoidFunction
}

const NoData: FunctionComponent<NoDataProps> = ({ onAdd }) => {
  const { t } = useTranslation()

  return (
    <NoResourcesAvailable
      title={t('secrets.createFirstCloudProviderCredential.title')}
      supportText={t('secrets.createFirstCloudProviderCredential.supportText')}
      buttonText={t('secrets.createFirstCloudProviderCredential.buttonText')}
      onButtonClick={onAdd}
      data-testid="add-first-cloud-provider-credential"
    />
  )
}

export default NoData
