import { type FC } from 'react'

import { Field, Radio } from '@matillion/component-library'

export const TableSelectorRadio: FC = ({ ...props }) => {
  return (
    <Field>
      <Radio {...props} />
    </Field>
  )
}
