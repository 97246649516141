import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
  type ReactNode
} from 'react'

import { set } from 'lodash'

import { type AutoCompleteItemId } from '@matillion/component-library'

interface SchemaMetadata {
  selected: boolean
  tables?: string[]
  tablesLoading: boolean
}

interface ValidationObject {
  isError?: boolean
  errorMessage?: string
}

interface SnowflakeMetadataObject {
  data: AutoCompleteItemId[]
  loading: boolean
}

export interface FormMetadata {
  editing: boolean
  validation: {
    sourceConnection: ValidationObject
    targetConnection: ValidationObject
    targetConfiguration: ValidationObject
    signalTable: ValidationObject
    pipelineConfiguration: ValidationObject
  }
  source: {
    schemas: Record<string, SchemaMetadata>
  }
  snowflakeOptions: {
    roles: SnowflakeMetadataObject
    warehouses: SnowflakeMetadataObject
    databases: SnowflakeMetadataObject
    schemas: SnowflakeMetadataObject
    stages: SnowflakeMetadataObject
  }
}

export type setMetadataType = React.Dispatch<React.SetStateAction<FormMetadata>>
export type MetadataProperty =
  | string
  | string[]
  | boolean
  | AutoCompleteItemId[]
  | null

interface FormMetadataContextType {
  metadata: FormMetadata
  setMetadata: setMetadataType
  setMetadataProperty: (propertyPath: string, value: MetadataProperty) => void
}

const FormMetadataContext = createContext<FormMetadataContextType | null>(null)

export const useFormMetadata = () => {
  const context = useContext(FormMetadataContext)
  if (!context) {
    throw new Error('useFormMetadata must be used within a FormMetaProvider')
  }
  return context
}

interface FormMetaProviderProps {
  children: ReactNode
  setMetadataPropertyCallback?: (
    propertyPath: string,
    value: MetadataProperty
  ) => void
  editing: boolean
  initialProperties?: object
}

export const FormMetadataProvider: React.FC<FormMetaProviderProps> = ({
  children,
  setMetadataPropertyCallback,
  editing,
  initialProperties
}) => {
  const [metadata, setMetadata] = useState<FormMetadata>({
    editing,
    validation: {
      sourceConnection: {},
      targetConnection: {},
      targetConfiguration: {},
      signalTable: {},
      pipelineConfiguration: {}
    },
    source: {
      schemas: {}
    },
    snowflakeOptions: {
      roles: {
        data: [],
        loading: false
      },
      warehouses: {
        data: [],
        loading: false
      },
      databases: {
        data: [],
        loading: false
      },
      schemas: {
        data: [],
        loading: false
      },
      stages: {
        data: [],
        loading: false
      }
    },
    ...initialProperties
  })

  const setMetadataProperty = useCallback(
    (propertyPath: string, value: MetadataProperty) => {
      setMetadata((prevContext) => set({ ...prevContext }, propertyPath, value))
      if (setMetadataPropertyCallback) {
        setMetadataPropertyCallback(propertyPath, value)
      }
    },
    [setMetadata, setMetadataPropertyCallback]
  )

  const value = useMemo(
    () => ({ metadata, setMetadata, setMetadataProperty }),
    [metadata, setMetadata, setMetadataProperty]
  )

  return (
    <FormMetadataContext.Provider value={value}>
      {children}
    </FormMetadataContext.Provider>
  )
}
