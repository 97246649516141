import { type FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'

import classNames from 'classnames'
import { useFormikContext } from 'formik'

import { Field } from '@matillion/component-library'

import classes from 'components/Form/Form.module.scss'

import { type CreateProjectFormikValueTypes } from 'modules/Projects/CreateProject/CreateProjectForm'

export const AwsAccessKeyId: FunctionComponent = () => {
  const { errors, handleChange, touched, values } =
    useFormikContext<CreateProjectFormikValueTypes>()
  const { t } = useTranslation()

  return (
    <div data-testid={`${values.type}-cloud-credentials-aws-access-key-id`}>
      <Field
        title={t('fields.awsAccessKeyId.title')}
        name="awsAccessKeyId"
        data-testid={`${values.type}-cloud-credentials-aws-access-key-id-input`}
        value={values.awsAccessKeyId}
        onChange={handleChange}
        placeholder={t('fields.awsAccessKeyId.placeholderText')}
        className={classNames(classes.Form__SpacingStyles)}
        supportText={t('fields.awsAccessKeyId.supportText')}
        errorText={
          errors?.awsAccessKeyId && touched.awsAccessKeyId
            ? t(errors.awsAccessKeyId)
            : null
        }
        hasError={
          Boolean(errors?.awsAccessKeyId) && Boolean(touched.awsAccessKeyId)
        }
        required
      />
    </div>
  )
}
