import { useTranslation } from 'react-i18next'

import { Table as T, Typography } from '@matillion/component-library'

import type { GETEnvironmentMembersResponse } from 'api/types'

import classes from 'modules/Projects/EnvironmentsListing/components/editUsers/MembersList/MemberListItem.module.scss'

import { MemberListItem } from './MemberListItem'

const { Table, TableHead, TableRow, TableCell, TableBody } = T

export interface Member {
  id: string
  email: string
  firstName?: string
  lastName?: string
  icon?: string
}
interface Props {
  members: GETEnvironmentMembersResponse
  isLoading: boolean
  search?: string
}

export const MembersList = ({ members, isLoading, search }: Props) => {
  const { t } = useTranslation()
  const sortedMembers = members.slice().sort((a, b) => {
    if (a.lastName && b.lastName) {
      return a.lastName.localeCompare(b.lastName)
    }
    if (a.lastName) {
      return -1
    }
    if (b.lastName) {
      return 1
    }
    return a.email.localeCompare(b.email)
  })

  const filteredMember = sortedMembers.filter((member) => {
    if (!search) {
      return true
    }
    const searchLower = search.toLowerCase()
    return (
      member.email.toLowerCase().includes(searchLower) ||
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      `${member.firstName ?? ''} ${member.lastName ?? ''}`
        .toLowerCase()
        .includes(searchLower)
    )
  })

  return (
    <Table alt="light">
      <TableHead>
        <TableRow className={classes.MemberListItem__TableRow}>
          <TableCell width="8" className={classes.MemberListItem__TableCell}>
            <Typography format="bcs" weight="bold">
              {t('editEnvironmentMembers.table.members')}
            </Typography>
          </TableCell>
          <TableCell width="4" className={classes.MemberListItem__TableCell}>
            <Typography format="bcs" weight="bold">
              {t('editEnvironmentMembers.table.accessLevel')}
            </Typography>
          </TableCell>
        </TableRow>
      </TableHead>

      <TableBody>
        {isLoading ? (
          <TableRow>
            <TableCell width="12" data-testid="membersList-loader">
              {t('editEnvironmentMembers.loadingPlaceholder')}
            </TableCell>
          </TableRow>
        ) : (
          <>
            {filteredMember.map((member) => (
              <MemberListItem key={member.id} {...member} />
            ))}
          </>
        )}
      </TableBody>
    </Table>
  )
}
