import { type FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams, type To } from 'react-router-dom'

import { type FormikHelpers } from 'formik'

import { LoadingSpinner, Typography } from '@matillion/component-library'

import { useGetStreamingPipelines } from 'api/hooks'
import { useGetStreamingAgents } from 'api/hooks/streaming'
import { useGetStreamingPipeline } from 'api/hooks/streaming/useGetStreamingPipeline'
import { usePostStreamingPipeline } from 'api/hooks/streaming/usePostStreamingPipeline'
import { usePutStreamingPipeline } from 'api/hooks/streaming/usePutStreamingPipeline'
import { AgentStatus } from 'api/types'

import Form from 'components/Form'

import { CREATE_STREAMING_PIPELINE } from 'constants/persistance'
import { AppRoutes } from 'constants/route'

import { useFormNavigation } from 'hooks'

import ConfigureRoutes from './ConfigureRoutes'
import classes from './CreateStreamingPipeline.module.scss'
import {
  createFormSchema,
  FormMetadataProvider,
  initialValues,
  mapFormToPipelineDefinition,
  mapFormToUpdatePipelineDefinition,
  mapPipelineDefinitionToForm,
  type FormValues
} from './FormContent'
import { steps } from './index'

const CreateStreamingPipeline: FunctionComponent = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const stepInfo = useFormNavigation(steps)
  const { isLastStep, nextStep } = stepInfo
  const { projectId, pipelineId } = useParams()
  const isEditing = !!pipelineId
  const { data: streamingPipelinesData = [] } =
    useGetStreamingPipelines(projectId)
  const {
    data: existingStreamingPipelineData,
    isLoading: isExistingPipelineLoading,
    isError: isExistingPipelineError
  } = useGetStreamingPipeline(
    projectId!,
    // @ts-expect-error request is disabled if pipelineId not given
    pipelineId,
    { enabled: !!pipelineId }
  )
  const { data: agents = [], isLoading: isAgentsLoading } =
    useGetStreamingAgents(AgentStatus.RUNNING, { enabled: isEditing })
  const { mutateAsync: mutateCreate } = usePostStreamingPipeline(projectId!)
  const { mutateAsync: mutateUpdate } = usePutStreamingPipeline(projectId!)

  const afterSubmit = () => {
    navigate(AppRoutes.getStreamingPipelinesListing(projectId!))
  }

  const submitForm = (
    values: FormValues,
    { setSubmitting }: FormikHelpers<FormValues>
  ) => {
    if (isLastStep) {
      if (isEditing) {
        const pipelineDefinition = mapFormToUpdatePipelineDefinition(
          values,
          pipelineId
        )
        mutateUpdate({ pipelineDefinition })
          .then(afterSubmit)
          .finally(() => {
            setSubmitting(false)
          })
      } else {
        const pipelineDefinition = mapFormToPipelineDefinition(values)
        mutateCreate({ pipelineDefinition })
          .then(afterSubmit)
          .finally(() => {
            setSubmitting(false)
          })
      }
    } else {
      setSubmitting(false)
      navigate(nextStep as To, { replace: true })
    }
  }

  if (isEditing) {
    if (isExistingPipelineLoading || isAgentsLoading) {
      return (
        <div className={classes.CreateStreamingPipeline__LoadingSpinnerWrapper}>
          <LoadingSpinner />
        </div>
      )
    } else if (isExistingPipelineError || !existingStreamingPipelineData) {
      return (
        <Typography
          className={classes.CreateStreamingPipeline__Error}
          data-testid="load-existing-pipeline-error"
        >
          {t('editStreamingPipeline.error')}
        </Typography>
      )
    }
  }

  const initialFormValues = isEditing
    ? mapPipelineDefinitionToForm(existingStreamingPipelineData!, agents)
    : initialValues

  return (
    <FormMetadataProvider editing={isEditing}>
      <Form
        formikValues={{
          initialValues: initialFormValues,
          validationSchema: createFormSchema(
            streamingPipelinesData,
            t,
            pipelineId
          ),
          onSubmit: submitForm,
          validateOnMount: true,
          initialTouched: false
        }}
        translationPrefix="createStreamingPipeline"
        persistingStorageId={CREATE_STREAMING_PIPELINE}
        data-testid="configure-streaming-pipeline-form"
        stepInfo={stepInfo}
      >
        <ConfigureRoutes />
      </Form>
    </FormMetadataProvider>
  )
}

export default CreateStreamingPipeline
