import { useEffect } from 'react'

interface StorageDataProps {
  [storageParameterId: string]: unknown
}

interface UseCompareAndClearSessionStorageProps<T> {
  comparisonValue?: string | number
  storageData?: T
  storageName: string
  storageParameterId: keyof T
}

/**
 * Hook to compare a value in sessionStorage and clear it if it doesn't match the specified comparison ID.
 * Allows the form application to clear the session storage if a certain value doesn't pass a condition.
 * Useful to prevent pre-loading the form with wrong data if users jump from one form to the other.
 *
 * @template T, StorageDataProps
 * @param {Object} props - The properties object.
 * @param {T} props.storageData - The data stored in sessionStorage.
 * @param {string} props.storageName - The key under which the data is stored in sessionStorage.
 * @param {string} props.storageParameterId - The property ID within the storage data used for comparison.
 * @param {string | number} props.comparisonValue - The value to compare against the stored data in sessionStorage.
 */
export const useCompareAndClearSessionStorage = <T extends StorageDataProps>({
  storageData,
  storageName,
  storageParameterId,
  comparisonValue
}: UseCompareAndClearSessionStorageProps<T>) => {
  useEffect(() => {
    if (
      comparisonValue &&
      storageData?.[storageParameterId] !== comparisonValue
    ) {
      window.sessionStorage.removeItem(storageName)
    }
  }, [comparisonValue, storageData, storageName, storageParameterId])
}
