/**
 * This Component follows the Google Guidelines
 * See {@link https://developers.google.com/identity/branding-guidelines} for more information
 */
import type { ButtonHTMLAttributes } from 'react'
import { useTranslation } from 'react-i18next'

import { ReactComponent as GoogleIcon } from 'assets/GoogleIcon.svg'

import classes from './GoogleButton.module.scss'

export const GoogleButton = ({
  ...rest
}: ButtonHTMLAttributes<HTMLButtonElement>) => {
  const { t } = useTranslation()

  return (
    <button
      className={classes.GoogleButton}
      type="button"
      data-testid="sign-in-with-google-btn"
      {...rest}
    >
      <div className={classes['GoogleButton__gsi-material-button-state']} />
      <div
        className={classes['GoogleButton__gsi-material-button-content-wrapper']}
      >
        <div className={classes['GoogleButton__gsi-material-button-icon']}>
          <GoogleIcon />
        </div>
        <span className={classes['GoogleButton__gsi-material-button-contents']}>
          {t('app.googleButton.title')}
        </span>
        <span style={{ display: 'none' }}>{t('app.googleButton.title')}</span>
      </div>
    </button>
  )
}
