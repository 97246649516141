import { useTranslation } from 'react-i18next'

import classNames from 'classnames'
import { useFormikContext } from 'formik'

import { Button, Label, Typography } from '@matillion/component-library'

import classes from 'modules/FormsContent/Form.module.scss'
import scheduleClasses from 'modules/Projects/CreateSchedule/FormContent/CreateSchedule.module.scss'
import type { ScheduleFormValues } from 'modules/Projects/CreateSchedule/types'

import type { ValidDays } from 'types'

import { days } from './DaysOfTheWeek.util'

export const DaysOfTheWeek = () => {
  const { values, handleChange } = useFormikContext<ScheduleFormValues>()
  const { t } = useTranslation()

  const handleButtonToggle = (day: ValidDays) => {
    handleChange({
      target: {
        name: 'schedule.repeatOn',
        value: values.schedule.repeatOn?.includes(day)
          ? values.schedule.repeatOn.filter((d) => d !== day)
          : [...(values.schedule.repeatOn ?? []), day]
      }
    })
  }

  return (
    <div
      className={classNames(classes.Form__Field, scheduleClasses.DaysOfTheWeek)}
    >
      <Label
        role="h4"
        className={scheduleClasses.DaysOfTheWeek__Title}
        data-testid="schedule-repeat-on"
      >
        <Typography as="span" format="bcs" weight="bold">
          {t('createSchedule.fields.repeatOn.title')}
        </Typography>
      </Label>
      <div className={scheduleClasses.DaysOfTheWeek__Container}>
        {days.map((day) => {
          const selected = values.schedule.repeatOn?.includes(day.name)
          return (
            <Button
              key={day.name}
              alt="ghost"
              type="button"
              text={day.name}
              className={classNames(scheduleClasses.DaysOfTheWeek__Button, {
                [scheduleClasses['DaysOfTheWeek__Button--active']]: selected
              })}
              onClick={() => {
                handleButtonToggle(day.name)
              }}
              aria-pressed={selected}
              data-testid={`schedule-repeat-on-${day.name.toLocaleLowerCase()}`}
            />
          )
        })}
      </div>
    </div>
  )
}
