import { type FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'

import { SearchBar } from 'components/SearchBar'

import { HeadingWithContent } from 'modules/Projects/StreamingPipelineDashboard/Common/components/HeadingWithContent/HeadingWithContent'
import { DatabaseSchemaBreadcrumbs } from 'modules/Projects/StreamingPipelineDashboard/Metrics/components/DatabaseSchemaBreadcrumbs/DatabaseSchemaBreadcrumbs'
import { type SummaryField } from 'modules/Projects/StreamingPipelineDashboard/Summary/components/StreamingSummaryBlock/StreamingSummaryBlock'

import classes from './MetricsHeader.module.scss'

interface MetricsHeaderProps {
  onDatabaseClick: () => void
  database?: string
  selectedSchema?: string
  fields: SummaryField[]
  filter: string
  setFilter: (value: string) => void
}

const MetricsHeader: FunctionComponent<MetricsHeaderProps> = ({
  onDatabaseClick,
  database,
  selectedSchema,
  fields,
  filter,
  setFilter
}) => {
  const { t } = useTranslation()

  return (
    <div className={classes.MetricsHeader__Header}>
      <DatabaseSchemaBreadcrumbs
        database={
          database ?? t('streamingPipelineDashboard.metrics.common.root')
        }
        onDatabaseClick={onDatabaseClick}
        schema={selectedSchema}
      />
      <div className={classes.MetricsHeader__RightAlign}>
        {fields.map((field) => (
          <HeadingWithContent
            key={field.heading}
            heading={field.heading}
            content={field.content}
            headingFormat="mc"
            contentFormat="bcm"
            className={classes.MetricsHeader__HeadingWithContent}
            data-testid={field.testId}
            alignContent="right"
          />
        ))}
        <SearchBar value={filter} onChange={setFilter} />
      </div>
    </div>
  )
}

export default MetricsHeader
