import {
  useCallback,
  useEffect,
  useMemo,
  type ChangeEvent,
  type FunctionComponent,
  type SetStateAction
} from 'react'
import { useTranslation } from 'react-i18next'

import classNames from 'classnames'
import { useFormikContext } from 'formik'

import {
  AutoComplete,
  Field,
  type AutoCompleteItemId
} from '@matillion/component-library'

import type { OAuthParameter, OAuthSchema, OAuthType } from 'api/types'

import classes from 'components/Form/Form.module.scss'

import { useFlags } from 'hooks/flags'

import { getOAuthTypeList } from 'modules/FormsContent/CreateOAuth/createOAuthHelper'
import { renderFormikError } from 'modules/utils'

import type { OAuthFormI } from 'types/FormTypes'

export interface AuthenticationTypeProps {
  setOAuthParameter: React.Dispatch<
    SetStateAction<OAuthParameter[] | undefined>
  >
  selectedOAuth: OAuthSchema | undefined
  oAuthParameter: OAuthParameter[] | undefined
  setOAuthType: React.Dispatch<SetStateAction<OAuthType | undefined>>
}

export const AuthenticationType: FunctionComponent<AuthenticationTypeProps> = ({
  setOAuthParameter,
  selectedOAuth,
  oAuthParameter,
  setOAuthType
}) => {
  const { oauthAllowedAuthTypes } = useFlags()

  const { errors, handleBlur, handleChange, touched, values, setFieldValue } =
    useFormikContext<OAuthFormI>()
  const { i18n, t } = useTranslation()

  const formikError = renderFormikError(
    errors.authType,
    Boolean(touched.authType)
  )

  const getErrorMessage = useMemo(() => {
    if (formikError) {
      return t(formikError)
    }

    return ''
  }, [formikError, t])

  const listOfAuthenticationTypes = useMemo(() => {
    return selectedOAuth
      ? getOAuthTypeList(
          selectedOAuth,
          oauthAllowedAuthTypes as unknown as string[],
          t,
          i18n
        )
      : []
  }, [i18n, oauthAllowedAuthTypes, selectedOAuth, t])

  const setParametersAndAuthType = useCallback(
    (oauthType: AutoCompleteItemId) => {
      const selectedOauthType = selectedOAuth?.authTypes.find(
        (x) => x.type === oauthType.id
      )
      setOAuthType(selectedOauthType)

      const parameters = selectedOauthType?.parameters
      setOAuthParameter(parameters)
    },
    [selectedOAuth?.authTypes, setOAuthParameter, setOAuthType]
  )

  const handleOnAuthTypeChange = useCallback(
    (value: AutoCompleteItemId) => {
      oAuthParameter?.forEach((x) => {
        setFieldValue(x.id, '')
      })
      setOAuthParameter(undefined)
      setOAuthType(undefined)
      if (!value || !selectedOAuth) {
        return
      }

      setParametersAndAuthType(value)
    },
    [
      oAuthParameter,
      selectedOAuth,
      setFieldValue,
      setOAuthParameter,
      setOAuthType,
      setParametersAndAuthType
    ]
  )

  useEffect(() => {
    if (listOfAuthenticationTypes.length === 1) {
      const value = listOfAuthenticationTypes[0]
      setParametersAndAuthType(value)
      setFieldValue('authType', listOfAuthenticationTypes[0])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // eslint-disable-next-line react-hooks/exhaustive-deps
    JSON.stringify(listOfAuthenticationTypes),
    setFieldValue,
    setParametersAndAuthType
  ])

  return (
    <div data-testid="create-oauth-authtype">
      <Field
        inputComponent={AutoComplete}
        availableItems={listOfAuthenticationTypes}
        title={t('secrets.createOAuth.fields.authType.title')}
        name="authType"
        data-testid="create-oauth-authtype-input"
        value={values.authType}
        placeholder={t('secrets.createOAuth.fields.authType.placeholderText')}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          const { value } = e.target
          handleOnAuthTypeChange(value as unknown as AutoCompleteItemId)
          handleChange(e)
        }}
        onBlur={handleBlur}
        className={classNames(classes.Form__SpacingStyles)}
        supportText={t('secrets.createOAuth.fields.authType.supportText')}
        errorText={getErrorMessage}
        hasError={Boolean(errors?.authType?.id) && Boolean(touched.authType)}
        disabled={!listOfAuthenticationTypes.length}
        required
      />
    </div>
  )
}
