export const ROOT = '/'
export const LOGOUT = 'logout'
export const ONBOARDING = 'onboarding'
export const ADD = 'add'
export const PROJECTS_ADD = 'add'
export const WAREHOUSE = 'warehouse'
export const PROJECTS_EDIT = 'edit'
export const EDIT = 'edit'
export const PROJECTS_NEW_PROJECT = 'new-project'
export const PROJECTS_CONFIGURATION = 'configuration'
export const PROJECTS_REPOSITORY_SELECT = 'select'
export const PROVIDER_SELECTION = 'provider'
export const REPOSITORY_SELECTION = 'repositories'

export const PROJECTS_AGENT_DEPLOYMENT = 'agent-deployment'
export const PROJECTS_CREATE_AGENT = 'create-agent'
export const PROVISIONING_TRIAL_WAREHOUSE = 'provisioning-trial-warehouse'

export const PROJECTS_CREATE_ENVIRONMENT = 'create-environment'
export const PROJECTS_CREDENTIALS = 'credentials'
export const PROJECTS_CLOUD_CREDENTIALS = 'cloud-credentials'
export const PROJECTS_DEFAULTS = 'defaults'
export const OAUTH_REDIRECT = 'oauth-redirect'

export const GENERIC_PROJECT_DETAILS = ':projectId'
export const GENERIC_PROJECT_DETAILS_TAB = ':activeTab'

export const PROJECT_DETAILS_BRANCHES = 'branches'
export const PROJECT_DETAILS_ENVIRONMENTS = 'environments'
export const PROJECT_DETAILS_SECRET_DEFINITIONS = 'secret-definitions'
export const PROJECT_DETAILS_CLOUD_CREDENTIALS = 'cloud-provider-credentials'
export const PROJECT_DETAILS_SCHEDULES = 'schedules'
export const PROJECT_DETAILS_OAUTH = 'oauth'
export const PROJECT_DETAILS_ACCESS = 'access'
export const CREATE_SECRET_DEFINITION = 'create-secret-definition'
export const ASSOCIATE_CREDENTIALS = 'associate-credentials'
export const ASSOCIATE_ENVIRONMENT = 'associate-environment'

export const LIST_STREAMING_PIPELINES = 'streaming'
export const CREATE_STREAMING_PIPELINE = 'streaming-create'
export const EDIT_STREAMING_PIPELINE = 'streaming-edit'
export const STREAMING_PIPELINE_DASHBOARD = 'streaming-dashboard'
export const CREATE_STREAMING_PIPELINE_START = 'start'
export const CREATE_STREAMING_PIPELINE_DETAILS = 'details'

export const AppRoutes = {
  getDashboard: () => ROOT,
  getLogout: () => `${ROOT}${LOGOUT}`,
  getOnboarding: () => `${ROOT}${ONBOARDING}`,
  getOnboardingAdd: () => `${ROOT}${ONBOARDING}/${ADD}`,
  getProjectsAdd: () => `${ROOT}${PROJECTS_ADD}`,
  getProjectsAddAgentDeployment: () =>
    `${ROOT}${PROJECTS_ADD}/${PROJECTS_AGENT_DEPLOYMENT}`,
  getProjectsAddGitProvider: () =>
    `${ROOT}${PROJECTS_ADD}/${PROVIDER_SELECTION}`,
  getBranchListing: (projectId: string) => `${ROOT}${projectId}`,
  getEnvironmentAdd: (projectId: string) =>
    `${ROOT}${projectId}/${PROJECT_DETAILS_ENVIRONMENTS}/${PROJECTS_ADD}`,
  getBranchAdd: (projectId: string) =>
    `${ROOT}${projectId}/${PROJECT_DETAILS_BRANCHES}/${ADD}`,
  getBranchEdit: (projectId: string, branchId: string) =>
    `${ROOT}${projectId}/${PROJECT_DETAILS_BRANCHES}/${EDIT}/${branchId}`,
  getStreamingPipelinesListing: (projectId: string) =>
    `${ROOT}${projectId}/${LIST_STREAMING_PIPELINES}`,
  getStreamingPipelineCreate: (projectId: string) =>
    `${ROOT}${projectId}/${CREATE_STREAMING_PIPELINE}`,
  getStreamingPipelineEdit: (projectId: string, pipelineId: string) =>
    `${ROOT}${projectId}/${EDIT_STREAMING_PIPELINE}/${pipelineId}`,
  getStreamingPipelineDashboard: (projectId: string, pipelineId: string) =>
    `${ROOT}${projectId}/${STREAMING_PIPELINE_DASHBOARD}/${pipelineId}`,
  getEnvironmentEdit: (projectId: string, envId: string) =>
    `${ROOT}${projectId}/${PROJECT_DETAILS_ENVIRONMENTS}/${PROJECTS_EDIT}/${envId}`,
  getPartialEnvironmentEdit: () =>
    `/${PROJECT_DETAILS_ENVIRONMENTS}/${PROJECTS_EDIT}`,
  getScheduleAdd: (projectId: string) =>
    `${ROOT}${projectId}/${PROJECT_DETAILS_SCHEDULES}/${PROJECTS_ADD}`,
  getScheduleEdit: (projectId: string, scheduleId: string) =>
    `${ROOT}${projectId}/${PROJECT_DETAILS_SCHEDULES}/${PROJECTS_EDIT}/${scheduleId}`,
  getProjectDetails: (projectId: string, tab?: string) =>
    `${ROOT}${projectId}` + (tab ? `/${tab}` : ''),
  getCreateSecretDefinition: (projectId: string) =>
    `${ROOT}${projectId}/${CREATE_SECRET_DEFINITION}`,
  getCloudProviderCredentialListing: (projectId: string) =>
    `${ROOT}${projectId}/${PROJECT_DETAILS_CLOUD_CREDENTIALS}`,
  getCreateCloudProviderCredential: (projectId: string) =>
    `${ROOT}${projectId}/${PROJECT_DETAILS_CLOUD_CREDENTIALS}/${ADD}`,
  getCreateOAuthConnection: (projectId: string) =>
    `${ROOT}${projectId}/${PROJECT_DETAILS_OAUTH}/${ADD}`,
  getAssociateCredentialsToEnvironment: (projectId: string, envId: string) =>
    `${ROOT}${projectId}/${PROJECT_DETAILS_ENVIRONMENTS}/${ASSOCIATE_CREDENTIALS}/${envId}`,
  getAssociateEnvironmentToCredentials: (projectId: string, secretId: string) =>
    `${ROOT}${projectId}/${PROJECT_DETAILS_ENVIRONMENTS}/${ASSOCIATE_ENVIRONMENT}/${secretId}`
}
