import { type FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'

import { NoResourcesAvailable } from 'components/NoResourcesAvailable'

interface NoDataProps {
  onAddOAuth: VoidFunction
}

const NoData: FunctionComponent<NoDataProps> = ({ onAddOAuth }) => {
  const { t } = useTranslation()

  return (
    <NoResourcesAvailable
      title={t('secrets.createFirstOAuth.title')}
      supportText={t('secrets.createFirstOAuth.supportText')}
      buttonText={t('secrets.createFirstOAuth.buttonText')}
      onButtonClick={onAddOAuth}
      data-testid="add-first-oauth"
    />
  )
}

export default NoData
