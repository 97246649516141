import { useMemo } from 'react'

import { useOnboardingContext } from 'context'

import { useFlags } from 'hooks/flags'

import {
  steps,
  type OnboardingFormNavigationStepsType
} from 'modules/Onboarding/OnboardingForm/utils/OnboardingForm.util'

export const useOnboardingSteps = (
  isTrialWarehouse = false
): OnboardingFormNavigationStepsType => {
  const { enableRedshiftLocationDefaults } = useFlags()
  const { projectType } = useOnboardingContext()
  return useMemo(() => {
    return steps(
      isTrialWarehouse,
      projectType !== '' ? projectType : undefined,
      !!enableRedshiftLocationDefaults
    )
  }, [isTrialWarehouse, projectType, enableRedshiftLocationDefaults])
}
