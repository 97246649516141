import {
  createRef,
  isValidElement,
  useCallback,
  useEffect,
  useRef,
  useState,
  type FunctionComponent
} from 'react'
import { useTranslation } from 'react-i18next'

import classNames from 'classnames'
import {
  Field as FormikField,
  useFormikContext,
  type FieldProps,
  type FormikValues
} from 'formik'
import { get, isEqual } from 'lodash'

import {
  AutoComplete,
  Checkbox,
  CollapsiblePanel,
  Field,
  Toggle
} from '@matillion/component-library'

import { hasError } from 'components/PanelGroup/PanelGroup.util'

import { resolveErrorText } from 'modules/Projects/CreateStreamingPipeline'
import { SupportText } from 'modules/Projects/CreateStreamingPipeline/FormContent/ConfigurePipelineDetailsPage/Components/SupportText/SupportText'

import classes from './PanelGroup.module.scss'
import {
  type PanelGroupingConfig,
  type PanelGroupings,
  type PanelOpenStatuses,
  type StringGetter
} from './types'

interface PanelGroupProps {
  groupingConfigs: PanelGroupingConfig[]
  dataTestId?: string
  className?: string
}

export const PanelGroup: FunctionComponent<PanelGroupProps> = ({
  groupingConfigs,
  dataTestId = 'panel-group',
  className
}) => {
  const [allOpen, setAllOpen] = useState<boolean>(false)
  const [openPanels, setOpenPanels] = useState<PanelOpenStatuses>(
    groupingConfigs.reduce((acc: PanelOpenStatuses, stepConfig, index) => {
      acc[stepConfig.stepId] = {
        isOpen: stepConfig.openByDefault ?? index === 0
      }
      return acc
    }, {})
  )
  const [groupings, setGroupings] = useState<PanelGroupings>({})
  const { t } = useTranslation()
  const { values } = useFormikContext<FormikValues>()
  const prevGroupingsRef = useRef<PanelGroupingConfig[]>()
  const panelRefs = Array.from({ length: groupingConfigs.length }).map(() =>
    createRef<HTMLDivElement>()
  )

  const handleValidationChanges = useCallback(
    (newGroupingConfigs: PanelGroupingConfig[]) => {
      if (prevGroupingsRef.current) {
        // for each grouping, if we are setting validation to true, collapse it and open next one
        newGroupingConfigs.forEach((groupingConfig, index) => {
          if (
            groupingConfig.isError === false &&
            prevGroupingsRef.current?.[index]?.isError !== false
          ) {
            let newOpenState = {
              ...openPanels,
              [groupingConfig.stepId]: {
                ...openPanels[groupingConfig.stepId],
                isOpen: false
              }
            }
            const nextGrouping = groupingConfigs[index + 1]
            if (nextGrouping && !openPanels[nextGrouping.stepId].isOpen) {
              newOpenState = {
                ...newOpenState,
                [nextGrouping.stepId]: {
                  ...newOpenState[nextGrouping.stepId],
                  isOpen: true
                }
              }
            }
            setOpenPanels(newOpenState)
            panelRefs[index].current?.scrollIntoView({ behavior: 'smooth' })
          }
        })
      }
      prevGroupingsRef.current = newGroupingConfigs
    },
    [prevGroupingsRef, groupingConfigs, openPanels, panelRefs]
  )

  useEffect(() => {
    // we only act if _deep_ equality is false, to reduce renders
    if (!isEqual(groupingConfigs, prevGroupingsRef.current)) {
      setGroupings(
        groupingConfigs.reduce((acc: PanelGroupings, stepConfig) => {
          acc[stepConfig.stepId] = {
            stepId: stepConfig.stepId,
            config: stepConfig
          }
          return acc
        }, {})
      )

      // check to see if we need to open/collapse panels
      handleValidationChanges(groupingConfigs)
    }
  }, [groupingConfigs, setGroupings, handleValidationChanges])

  const getTranslatedString: (
    property?: string | StringGetter
  ) => string | undefined = (property) => {
    if (property === undefined) {
      return undefined
    }
    if (property instanceof Function) {
      const translationKey = property(values)
      return translationKey && t(translationKey)
    } else {
      return t(property)
    }
  }

  const isAllOpen = (panelOpenStatuses: PanelOpenStatuses) => {
    return Object.values(panelOpenStatuses).every(({ isOpen }) => isOpen)
  }

  return (
    <div
      data-testid={dataTestId}
      className={classNames(classes.PanelGroup, className)}
    >
      <Field
        checked={allOpen}
        onChange={() => {
          const newPanelOpenStatuses: PanelOpenStatuses = {}
          for (const key in openPanels) {
            newPanelOpenStatuses[key] = { isOpen: !allOpen }
          }
          setOpenPanels(newPanelOpenStatuses)
          setAllOpen(!allOpen)
        }}
        inputComponent={Toggle}
        data-testid="expand-all-toggle"
        text={t('createStreamingPipeline.openAllPanels')}
        small
        width=""
        className={classes.PanelGroup__Toggle}
      />
      {Object.entries(groupings).map(([stepId, { config }], index) => {
        const {
          title,
          subtitle,
          fields,
          showValidationStatus,
          isError,
          'data-testid': panelTestId
        } = config

        return (
          <div ref={panelRefs[index]} key={stepId} data-testid={panelTestId}>
            <CollapsiblePanel
              key={stepId}
              theme="grey-white"
              contentClassName={classes.PanelGroup__CollapsiblePanelContent}
              title={t(title)}
              subtitle={subtitle ? subtitle(t, values) : undefined}
              open={openPanels[stepId].isOpen}
              showValidationStatus={showValidationStatus}
              error={isError}
              onClick={() => {
                setOpenPanels((currentState) => {
                  const newState = {
                    ...currentState,
                    [stepId]: {
                      ...currentState[stepId],
                      isOpen: !currentState[stepId].isOpen
                    }
                  }

                  setAllOpen(isAllOpen(newState))

                  return newState
                })
              }}
            >
              {fields?.map((fieldConfigOrElement) => {
                if (isValidElement(fieldConfigOrElement)) {
                  // if we are passed a ReactElement we return it as-is
                  return fieldConfigOrElement
                } else if (fieldConfigOrElement.wrapInField === false) {
                  // this is a class that we render with given properties
                  const {
                    type: ElementType,
                    dataTestId: fieldDataTestId,
                    isVisible = () => true,
                    ...rest
                  } = fieldConfigOrElement
                  return isVisible(values) ? (
                    <ElementType
                      dataTestId={fieldDataTestId}
                      key={fieldDataTestId}
                      {...rest}
                    />
                  ) : null
                }
                const {
                  type,
                  name,
                  title: fieldTitle,
                  optionalLabelText,
                  text,
                  placeholder,
                  dataTestId: fieldDataTestId,
                  supportText,
                  supportDocs,
                  availableItems,
                  isVisible = () => true,
                  disabled = false,
                  highlightIfEmpty = true
                } = fieldConfigOrElement

                let typeSpecificProperties = {}
                switch (type) {
                  case AutoComplete:
                    typeSpecificProperties = {
                      availableItems
                    }
                    break
                  case Checkbox:
                    typeSpecificProperties = {
                      text: getTranslatedString(text),
                      checked: name && get(values, name)
                    }
                    break
                  case Toggle:
                    typeSpecificProperties = {
                      text: getTranslatedString(text)
                    }
                }

                return (
                  isVisible(values) && (
                    <FormikField name={name} key={name}>
                      {({
                        field,
                        meta,
                        form: { isSubmitting }
                      }: FieldProps<string>) => (
                        <div data-testid={fieldDataTestId}>
                          <Field
                            {...field}
                            {...typeSpecificProperties}
                            inputComponent={type}
                            name={name}
                            title={getTranslatedString(fieldTitle)}
                            optionalLabelText={getTranslatedString(
                              optionalLabelText
                            )}
                            data-testid={`${fieldDataTestId}-input`}
                            supportText={
                              <SupportText
                                supportText={getTranslatedString(supportText)}
                                supportDocs={getTranslatedString(supportDocs)}
                              />
                            }
                            placeholder={getTranslatedString(placeholder)}
                            errorText={resolveErrorText(t, meta)}
                            hasError={hasError(
                              values,
                              meta,
                              highlightIfEmpty,
                              name
                            )}
                            disabled={isSubmitting || disabled}
                          />
                        </div>
                      )}
                    </FormikField>
                  )
                )
              })}
            </CollapsiblePanel>
          </div>
        )
      })}
    </div>
  )
}
