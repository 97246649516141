export enum AgentStatus {
  PENDING = 'PENDING',
  RUNNING = 'RUNNING',
  STOPPED = 'STOPPED',
  UNKNOWN = 'UNKNOWN'
}

export interface EnvironmentAgent {
  id: string
  accountId: string
  agentId: string
  name: string
  // Unfortunately the BE doesn't know exactly what this prop looks like
  attributes: { region: string } & Record<string, unknown>
  runtimePlatformId: number
  cloudProviderId: number
  provisioningTypeId: number
  agentTypeId: number
  agentVersionId: number
  agentParameters: {
    agentGatewayURI: string
    oauthDomain: string
    oauthAudience: string
    accountId: string
  }
  status: AgentStatus
}

export type GETEnvironmentAgentsResponse = EnvironmentAgent[]
