import { type FunctionComponent } from 'react'

import classNames from 'classnames'

import { CircledImage } from 'components/CircledImageContent/CircledImage'
import { TypographyWithToolTip } from 'components/TypographyWithTooltip'

import { getDataOrPlaceholder } from 'modules/Projects/StreamingListing/components/Cells/Cells.util'
import { StreamingIcon } from 'modules/Projects/StreamingListing/components/Cells/StreamingIcon'

import {
  StreamingDestinationId,
  type AzureBlobDestinationConfigType,
  type DestinationConfigType,
  type S3DestinationConfigType,
  type SnowflakeDestinationConfigType
} from 'types/Streaming'

import classes from './DestinationCell.module.scss'

const getCellProperties = (
  destination: DestinationConfigType
): {
  primaryProperty: string
  secondaryProperty: string
} => {
  switch (destination.type) {
    case StreamingDestinationId.Snowflake: {
      return {
        primaryProperty: getDataOrPlaceholder(
          (destination as SnowflakeDestinationConfigType).database
        ),
        secondaryProperty: getDataOrPlaceholder(
          (destination as SnowflakeDestinationConfigType).warehouse
        )
      }
    }
    case StreamingDestinationId.S3: {
      return {
        primaryProperty: getDataOrPlaceholder(
          (destination as S3DestinationConfigType).bucket
        ),
        secondaryProperty: getDataOrPlaceholder(
          (destination as S3DestinationConfigType).prefix
        )
      }
    }
    case StreamingDestinationId.ABS: {
      return {
        primaryProperty: getDataOrPlaceholder(
          (destination as AzureBlobDestinationConfigType).container
        ),
        secondaryProperty: getDataOrPlaceholder(
          (destination as AzureBlobDestinationConfigType).prefix
        )
      }
    }
  }
}

export interface DestinationCellProps {
  destination: DestinationConfigType
}

const DestinationCell: FunctionComponent<DestinationCellProps> = ({
  destination
}) => {
  const { primaryProperty, secondaryProperty } = getCellProperties(destination)

  return (
    <div className={classes.DestinationCell__Container}>
      <CircledImage
        Image={() => <StreamingIcon type={destination.type} />}
        logoName={destination.type}
        imageContainerSize={48}
        imageSize={32}
      />
      <div className={classes.DestinationCell__DetailsContainer}>
        <TypographyWithToolTip
          tooltip={primaryProperty}
          className={classes.DestinationCell__Text}
          data-testid="destination-primary-property"
        >
          {primaryProperty}
        </TypographyWithToolTip>
        <TypographyWithToolTip
          tooltip={secondaryProperty}
          className={classNames(
            classes.DestinationCell__Text,
            classes.DestinationCell__WarehouseText
          )}
          data-testid="destination-secondary-property"
        >
          {secondaryProperty}
        </TypographyWithToolTip>
      </div>
    </div>
  )
}

export default DestinationCell
