import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { useNavigate, useParams, type To } from 'react-router-dom'

import { type FormikErrors } from 'formik'

import { Toaster } from '@matillion/component-library'

import { createGetWarehouseComputeResourceMutationData } from 'api/createProjectForm/mutation'
import { WarehouseLookupTypes } from 'api/createProjectForm/types'
import {
  useMutateDatabricksComputeResources,
  useMutateWarehouseDefaults,
  usePostSecretValueV2,
  usePutEnvironment
} from 'api/hooks'
import { usePutEnvironmentV2 } from 'api/hooks/usePutEnvironmentV2'
import {
  createSecretValueV2MutationData,
  createWarehouseDefaultsMutation,
  updateEnvironmentMutationData
} from 'api/mutation'

import { QueryKey } from 'constants/endpoint'
import { ENVIRONMENT_STORAGE_ITEM } from 'constants/persistance'
import {
  AppRoutes,
  PROJECT_DETAILS_ENVIRONMENTS,
  PROJECTS_DEFAULTS
} from 'constants/route'

import { useFormNavigation } from 'hooks'
import { useFlags } from 'hooks/flags'

import { steps } from 'modules/Projects/EditEnvironment/EditEnvironmentForm.steps'
import { type EditEnvironmentFormikValueTypes } from 'modules/Projects/EditEnvironment/types'

import { AgentsSecretsHost, Warehouse } from 'types'

import { useHandleCHASecretReferences } from './useHandleCHASecretReferences'

interface FormikSubmitProps {
  values: EditEnvironmentFormikValueTypes
  setValues: (
    values: React.SetStateAction<EditEnvironmentFormikValueTypes>,
    shouldValidate?: boolean
    // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
  ) => Promise<void | FormikErrors<EditEnvironmentFormikValueTypes>>
  skipSecretValueCreation?: boolean
}

export const useEditEnvironmentFormSubmit = () => {
  const navigate = useNavigate()
  const { projectId, envId } = useParams()
  const queryClient = useQueryClient()
  const { t } = useTranslation()
  const { makeToast, clearToasts } = Toaster.useToaster()
  const { enableKeyPairAuthentication } = useFlags()

  const { isLastStep, nextStep } = useFormNavigation(steps)
  const { mutateAsync: mutateAsyncSecretValueV2 } = usePostSecretValueV2()
  const { mutateAsync: mutateAsyncWarehouseRoleLookup } =
    useMutateWarehouseDefaults(WarehouseLookupTypes.ROLE, undefined, true)
  const { mutateAsync: mutateAsyncWarehouseDatabaseLookup } =
    useMutateWarehouseDefaults(WarehouseLookupTypes.DATABASE, undefined, true)
  const { mutateAsync: mutateEnvironment } = usePutEnvironment(projectId!)
  const { mutateAsync: mutateEnvironmentV2 } = usePutEnvironmentV2()
  const { mutateAsync: mutateAsyncWarehouseComputeResourceLookup } =
    useMutateDatabricksComputeResources({ showErrorDetailMessage: true })
  const handleCHASecretReferences = useHandleCHASecretReferences()

  const navigateToEnvironments = () => {
    window.sessionStorage.removeItem(ENVIRONMENT_STORAGE_ITEM)
    navigate(
      AppRoutes.getProjectDetails(projectId!, PROJECT_DETAILS_ENVIRONMENTS)
    )
  }

  const onSubmit = async ({
    setValues,
    values,
    skipSecretValueCreation = false
  }: FormikSubmitProps) => {
    const mutateFunction =
      enableKeyPairAuthentication && values.type === Warehouse.Snowflake
        ? mutateEnvironmentV2
        : mutateEnvironment
    clearToasts()
    if (isLastStep) {
      await mutateFunction({
        values: updateEnvironmentMutationData(
          values,
          !!enableKeyPairAuthentication && values.type === Warehouse.Snowflake
        ),
        environmentId: envId!,
        projectId: projectId!
      })
        .then((_res) => {
          makeToast({
            title: t('editEnvironment.responseMessage.success', {
              environmentName: values.environmentName
            }),
            message: '',
            type: 'success'
          })
          queryClient.invalidateQueries([QueryKey.SECRET_REFERENCES, projectId])
          queryClient.invalidateQueries([QueryKey.ENVIRONMENTS_LIST, projectId])
          navigateToEnvironments()
        })
        .catch((_err) => {
          makeToast({
            title: t('editEnvironment.responseMessage.error'),
            message: '',
            type: 'error'
          })
        })
    } else {
      try {
        if (nextStep === PROJECTS_DEFAULTS && !skipSecretValueCreation) {
          let response
          let passphraseResponse
          if (
            values.agentsSecretsManagement === AgentsSecretsHost.MatillionHosted
          ) {
            if (values.secretValue) {
              response = await mutateAsyncSecretValueV2({
                values: createSecretValueV2MutationData({
                  ...values,
                  name: values.environmentName,
                  secretValue: {
                    password: values.secretValue
                  }
                })
              })
            }

            if (values.passphrase) {
              passphraseResponse = await mutateAsyncSecretValueV2({
                values: createSecretValueV2MutationData({
                  ...values,
                  name: values.environmentName,
                  secretValue: {
                    password: values.passphrase
                  }
                })
              })
            }
          } else {
            const {
              createSecretRefResponse,
              createPassphraseSecretRefResponse
            } = await handleCHASecretReferences(
              values,
              !!enableKeyPairAuthentication
            )

            response = createSecretRefResponse
            passphraseResponse = createPassphraseSecretRefResponse
          }
          values = {
            ...values,
            ...(response && { secretReferenceId: response.secretId }),
            ...(passphraseResponse && {
              passphraseSecretReferenceId: passphraseResponse.secretId
            })
          }

          // Ensure that there is a connection to warehouse defaults role / database / compute in order to continue
          // Otherwise it throws an error which is caught below and does not proceed to the next page
          switch (values.type) {
            case Warehouse.Snowflake:
              await mutateAsyncWarehouseRoleLookup({
                values: createWarehouseDefaultsMutation(
                  values,
                  !!enableKeyPairAuthentication
                )
              })
              break
            case Warehouse.Redshift:
              await mutateAsyncWarehouseDatabaseLookup({
                values: createWarehouseDefaultsMutation(values)
              })
              break
            case Warehouse.Databricks:
              await mutateAsyncWarehouseComputeResourceLookup({
                values: createGetWarehouseComputeResourceMutationData(values)
              })
              break
          }

          setValues(values)
        }

        navigate(nextStep as To, { replace: true })
      } catch (_e) {
        // handled by axios config
      }
    }
  }

  return onSubmit
}
