import { DefaultStorageLocation } from 'modules/Common/Fields/DefaultStorageLocation/DefaultStorageLocation'
import type { CreateProjectFormikValueTypes } from 'modules/Projects/CreateProject/CreateProjectForm'

const RedshiftDefaultLocation = () => {
  const listenerFields: Array<keyof CreateProjectFormikValueTypes> = [
    'awsSecretReferenceId'
  ]
  return <DefaultStorageLocation listenerFields={listenerFields} />
}

export default RedshiftDefaultLocation
