import classNames from 'classnames'

import { Tooltip, Typography } from '@matillion/component-library'

import { ReactComponent as AWSLogo } from 'assets/AWSLogo.svg'
import { ReactComponent as AzureLogo } from 'assets/MicrosoftAzureLogo.svg'

import {
  AgentCloudProvider,
  agentCloudProviderName
} from 'types/AgentCloudProvider'

import classes from './AgentIcon.module.scss'
import { runtimePlatforms } from './runtimePlatformUtils'
import { RuntimePlatform } from './types'

interface AgentIconProps {
  cloudProviderId: number
  runtimePlatformId: number
  text: string
}

export const AgentIcon = ({
  cloudProviderId,
  runtimePlatformId,
  text
}: AgentIconProps) => {
  const agentCloudProvider = cloudProviderId.toString()
  const cloudProviders: string[] = [
    AgentCloudProvider.AWS,
    AgentCloudProvider.AZURE
  ]

  if (!cloudProviders.includes(agentCloudProvider)) {
    return <Typography data-testid="agent-name-without-icon">{text}</Typography>
  }
  const cloudProviderName: string =
    agentCloudProviderName[agentCloudProvider as AgentCloudProvider]

  const getTooltipContent = () => (
    <>
      <Typography format="bcs">Platform:</Typography>
      <Typography format="bcs">{cloudProviderName}</Typography>
      <Typography format="bcs">Deployment:</Typography>
      <Typography format="bcs">{RuntimePlatform[runtimePlatformId]}</Typography>
    </>
  )
  const getIconByRuntimePlatformId = () => {
    const runtimePlatform = runtimePlatforms.find(
      (rp) => rp.id === runtimePlatformId
    )
    return <img src={runtimePlatform?.icon} alt={runtimePlatform?.name} />
  }

  const getIconByCloudProviderId = () => {
    if (agentCloudProvider === AgentCloudProvider.AWS) {
      return <AWSLogo />
    } else {
      return <AzureLogo />
    }
  }

  return (
    <Tooltip
      content={getTooltipContent()}
      className={classNames(classes.Tooltip)}
    >
      <span className={classes.AgentItem}>
        <Typography>{text}</Typography>
        <span
          className={classes.IconContainer}
          data-testid={`${cloudProviderName.toLowerCase()}-agent-icon`}
        >
          <span className={classes.AgentProviderIcon}>
            {getIconByCloudProviderId()}
          </span>
          <span className={classes.AgentRuntimePlatformIcon}>
            {getIconByRuntimePlatformId()}
          </span>
        </span>
      </span>
    </Tooltip>
  )
}

export default AgentIcon
