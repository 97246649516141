import { type FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'

import { Formik } from 'formik'

import { Toaster } from '@matillion/component-library'

import {
  useGetEnvironmentMembers,
  useGetProjectMembers,
  usePutEnvironmentMembers
} from 'api/hooks'

import { EditEnvironmentMembersModal } from 'modules/Projects/EnvironmentsListing/components/editUsers/EditEnvironmentMembersModal'

export interface EnvContext {
  id: string
  name: string
  defaultRole: string
}
interface Props {
  setEnvContext: (state?: EnvContext) => void
  envContext: EnvContext
  projectId: string
}

const mapToRole = (role: string) => {
  switch (role) {
    case 'explicit_write':
      return 'admin'
    case 'explicit_read':
      return 'readOnly'
    case 'explicit_no_access':
      return 'noAccess'
    default:
      return 'default'
  }
}

export const EditEnvironmentMembersForm: FunctionComponent<Props> = ({
  setEnvContext,
  envContext,
  projectId
}) => {
  const { t } = useTranslation()
  const { data: projectMembers = [], isLoading: projectIsLoading } =
    useGetProjectMembers(projectId)
  const {
    data: envMembers = [],
    isLoading: envIsLoading,
    refetch
  } = useGetEnvironmentMembers(envContext.id)
  const { mutateAsync: putEnvironmentUsers } = usePutEnvironmentMembers(
    envContext.id
  )
  const toaster = Toaster.useToaster()
  const usersWithRoles = Object.fromEntries(
    envMembers.map((member) => [member.id, mapToRole(member.relation.relation)])
  )
  const members = projectMembers.map((member) => ({
    ...member,
    access: usersWithRoles[member.id] ?? 'default'
  }))
  const initialValues = Object.fromEntries(
    members.map((member) => [member.id, member.access])
  )

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      initialTouched={{}}
      onSubmit={async (values) => {
        toaster.clearToasts()
        const changedValues = Object.fromEntries(
          Object.keys(values)
            .filter((key) => values[key] !== initialValues[key])
            .map((key) => [key, values[key]])
        )
        if (Object.keys(changedValues).length === 0) {
          toaster.makeToast({
            title: t('editEnvironmentMembers.responseMessage.noChanges'),
            message: '',
            type: 'warning'
          })
        }
        try {
          await putEnvironmentUsers({ values: changedValues })
          await refetch()
          toaster.makeToast({
            title: t('editEnvironmentMembers.responseMessage.success'),
            message: '',
            type: 'success'
          })
        } catch (error) {
          toaster.makeToast({
            title: t('editEnvironmentMembers.responseMessage.error'),
            message: '',
            type: 'error'
          })
        }
        setEnvContext(undefined)
      }}
      validateOnMount
    >
      <EditEnvironmentMembersModal
        setEnvContext={setEnvContext}
        envContext={envContext}
        members={members}
        isLoading={projectIsLoading || envIsLoading}
      />
    </Formik>
  )
}
