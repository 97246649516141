import { type FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'

import classNames from 'classnames'

import classes from 'components/Form/Form.module.scss'
import { Header, HeaderTitle } from 'components/Header'

import { AgentsSecretsManagement } from './Fields'

interface AgentDeploymentProps {
  onCancel?: () => void
  onPrevious?: () => void
  showCancel?: boolean
  showPrevious?: boolean
  modalContent?: string
}
const AgentDeployment: FunctionComponent<AgentDeploymentProps> = ({
  onCancel,
  onPrevious,
  showCancel = false,
  showPrevious = false,
  modalContent
}) => {
  const { t } = useTranslation()
  return (
    <>
      <Header
        onCancel={onCancel}
        showCancel={showCancel}
        onPrevious={onPrevious}
        showPrevious={showPrevious}
        modalContent={modalContent}
      >
        <HeaderTitle data-testid="agent-deployment-title">
          {t('agentDeployment.title')}
        </HeaderTitle>
      </Header>
      <div
        className={classNames(
          classes.Form__InnerWrap,
          classes['Form__InnerWrap--space-top']
        )}
      >
        <AgentsSecretsManagement />
      </div>
    </>
  )
}

export default AgentDeployment
