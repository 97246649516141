import { useEffect, useMemo, type FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'

import { useFormikContext } from 'formik'

import { AutoComplete, Field } from '@matillion/component-library'

import { WarehouseLookupTypes } from 'api/createProjectForm/types/WarehouseDefaultsRequest'
import { useMutateWarehouseDefaults } from 'api/hooks'
import { createWarehouseDefaultsMutation } from 'api/mutation'
import { type ErrorResponse } from 'api/types'

import classes from 'components/Form/Form.module.scss'

import { StatusCodes } from 'constants/statusCodes'

import { useFlags } from 'hooks/flags'

import { type CreateProjectFormikValueTypes } from 'modules/Projects/CreateProject/CreateProjectForm'
import { renderFormikError } from 'modules/utils'

export interface WarehouseProps {
  isFieldEnabled?: boolean
  listenerField?: keyof CreateProjectFormikValueTypes
}

export const Warehouse: FunctionComponent<WarehouseProps> = ({
  isFieldEnabled = true,
  listenerField
}) => {
  const {
    errors,
    handleBlur,
    handleChange,
    touched,
    values,
    initialValues,
    setFieldTouched,
    setFieldValue
  } = useFormikContext<CreateProjectFormikValueTypes>()
  const { t } = useTranslation()
  const projectType = values.type || 'common'
  const { enableKeyPairAuthentication } = useFlags()

  const {
    data: warehouseDefaultData = [],
    isError,
    isLoading,
    error,
    mutate: mutateWarehouse
  } = useMutateWarehouseDefaults(WarehouseLookupTypes.WAREHOUSE)

  const defaultWarehouseError = renderFormikError(
    errors.defaultWarehouse,
    Boolean(touched.defaultWarehouse)
  )

  const getErrorMessage = useMemo(() => {
    if (isError && isFieldEnabled) {
      const errorDetail = (error?.response?.data as ErrorResponse)?.detail
      const errorResponseStatus = error?.response?.status
      if (
        errorResponseStatus === StatusCodes.BAD_REQUEST &&
        errorDetail !== undefined
      ) {
        return errorDetail
      }
      return t('fields.defaultWarehouse.error.loadingError')
    }
    if (!warehouseDefaultData.length && isFieldEnabled) {
      return t('fields.defaultWarehouse.error.noItemsFound')
    }
    if (defaultWarehouseError) return t(defaultWarehouseError)
    return null
  }, [
    defaultWarehouseError,
    isFieldEnabled,
    isError,
    t,
    warehouseDefaultData.length,
    error
  ])

  const listenerFieldValue = listenerField ? values[listenerField] : ''
  const initialListenerFieldValue = listenerField
    ? initialValues[listenerField]
    : ''

  useEffect(() => {
    if (
      listenerFieldValue?.id !== initialListenerFieldValue?.id ||
      !isFieldEnabled
    ) {
      setFieldTouched('defaultWarehouse', false)
      setFieldValue('defaultWarehouse', { id: '', name: '' })
    }
    if (isFieldEnabled) {
      mutateWarehouse({
        values: createWarehouseDefaultsMutation(
          values,
          !!enableKeyPairAuthentication
        )
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isFieldEnabled,
    mutateWarehouse,
    setFieldTouched,
    values.compute,
    listenerFieldValue
  ])

  return (
    <div data-testid={`${projectType}-default-default-warehouse`}>
      <Field
        inputComponent={AutoComplete}
        availableItems={warehouseDefaultData.map((item) => ({
          id: item,
          name: item
        }))}
        loading={isLoading}
        title={t('fields.defaultWarehouse.title')}
        name="defaultWarehouse"
        data-testid={`${projectType}-default-default-warehouse-input`}
        value={values.defaultWarehouse}
        placeholder={t('fields.defaultWarehouse.placeholderText')}
        onChange={handleChange}
        onBlur={handleBlur}
        className={classes.Form__SpacingStyles}
        supportText={t('fields.defaultWarehouse.supportText')}
        errorText={isLoading ? undefined : getErrorMessage}
        hasError={
          Boolean(errors?.defaultWarehouse?.id) &&
          Boolean(touched.defaultWarehouse) &&
          !isLoading
        }
        required
        disabled={isError || !isFieldEnabled}
      />
    </div>
  )
}
