import { type FunctionComponent } from 'react'

import { Table as T, Typography } from '@matillion/component-library'

import { PropertyIcon } from 'modules/Projects/StreamingPipelineDashboard/Summary/components/PropertyIcon/PropertyIcon'

import type { StreamingDestinationId, StreamingSourceId } from 'types/Streaming'

import classes from './PropertyRowBlock.module.scss'

const { TableRow, TableCell } = T

interface PropertyRowBlockProps {
  properties?: Record<string, string | boolean>
  subject?: StreamingDestinationId | StreamingSourceId
}

export const PropertyRowBlock: FunctionComponent<PropertyRowBlockProps> = ({
  properties,
  subject
}) => {
  if (!properties) {
    return null
  }

  return (
    <>
      {Object.entries(properties).map(([key, value]) => (
        <TableRow
          key={subject ?? 'advanced' + '-' + key}
          className={classes.PropertyRowBlock__TableRow}
          data-testid="property-value-row"
        >
          <TableCell width="6" align="left">
            <PropertyIcon subject={subject} />
            <Typography format="bcs" data-testid="property-key">
              {key}
            </Typography>
          </TableCell>
          <TableCell width="6" align="left">
            <Typography format="bcs" data-testid="property-value">
              {value.toString()}
            </Typography>
          </TableCell>
        </TableRow>
      ))}
    </>
  )
}
