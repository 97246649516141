import { type FunctionComponent } from 'react'

import classes from './VideoFrame.module.scss'

interface VideoFrameProps {
  src: string
  title: string
  'data-testid'?: string
}

export const VideoFrame: FunctionComponent<VideoFrameProps> = ({
  src,
  title,
  'data-testid': dataTestId
}) => {
  return (
    <div className={classes.Container} data-testid={dataTestId}>
      <iframe
        className={classes.ResponsiveFrame}
        title={title}
        allowFullScreen
        src={src}
      />
    </div>
  )
}
