import { type FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'

import { Formik } from 'formik'

import { Toaster } from '@matillion/component-library'

import { useDeleteProject, useNetworkErrorToast } from 'api/hooks'
import { type ErrorResponse } from 'api/types'

import { QueryKey } from 'constants/endpoint'

import { getInitialValues, getValidationSchema } from './DeleteProjectForm.util'
import { DeleteProjectModal } from './Modal'

interface DeleteProjectFormProps {
  handleDeleteWrapup: () => void
  resetModals: () => void
  projectId: string
  projectName: string
}

const DeleteProjectForm: FunctionComponent<DeleteProjectFormProps> = ({
  handleDeleteWrapup,
  resetModals,
  projectId,
  projectName
}) => {
  const { t } = useTranslation()
  const { mutateAsync: deleteProjectMutation, isLoading } = useDeleteProject()
  const makeErrorToast = useNetworkErrorToast()
  const { makeToast } = Toaster.useToaster()
  const queryClient = useQueryClient()

  const onSubmit = () => {
    deleteProjectMutation({
      values: { projectId: projectId }
    })
      .then((_r) => {
        handleDeleteWrapup()
        queryClient.invalidateQueries([QueryKey.PROJECTS_LIST])
        makeToast({
          title: t('deleteProject.success', {
            projectName: projectName
          }),
          message: '',
          type: 'success'
        })
      })
      .catch((error) => {
        const errorDetail = (error?.response?.data as ErrorResponse)?.detail
        makeErrorToast({
          title: errorDetail,
          message: errorDetail ? '' : t('deleteProject.error')
        })
      })
  }

  return (
    <Formik
      initialValues={getInitialValues(projectName)}
      initialTouched={false}
      onSubmit={onSubmit}
      validationSchema={getValidationSchema(t)}
      validateOnMount
    >
      <DeleteProjectModal
        isLoading={isLoading}
        resetModals={resetModals}
        projectName={projectName}
      />
    </Formik>
  )
}

export default DeleteProjectForm
