import { type FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'

import { Button, Typography } from '@matillion/component-library'

import { HeadingWithContent } from 'modules/Projects/StreamingPipelineDashboard/Common/components/HeadingWithContent/HeadingWithContent'

import classes from './DatabaseSchemaBreadcrumbs.module.scss'

interface DatabaseSchemaBreadcrumbsProps {
  database: string
  schema?: string
  onDatabaseClick: () => void
}

export const DatabaseSchemaBreadcrumbs: FunctionComponent<
  DatabaseSchemaBreadcrumbsProps
> = ({ database, schema, onDatabaseClick }) => {
  const { t } = useTranslation()

  if (!schema) {
    return (
      <HeadingWithContent
        heading={t('streamingPipelineDashboard.metrics.common.database')}
        content={database}
        headingFormat="mc"
        contentFormat="bcs"
        data-testid="database-without-schema"
      />
    )
  }

  return (
    <div className={classes.DatabaseSchemaBreadcrumbs__HeadingWithContent}>
      <Typography
        format="mc"
        className={classes.DatabaseSchemaBreadcrumbs__Heading}
      >
        {`${t('streamingPipelineDashboard.metrics.common.database')} / ${t('streamingPipelineDashboard.metrics.common.schema')}`}
      </Typography>
      <div className={classes.DatabaseSchemaBreadcrumbs__SchemaPath}>
        <Button
          alt="text"
          className={classes.DatabaseSchemaBreadcrumbs__Button}
          onClick={() => {
            onDatabaseClick()
          }}
          data-testid="database-breadcrumb"
        >
          <Typography format="bcs">{database}</Typography>
        </Button>
        <Typography
          format="bcs"
          className={classes.DatabaseSchemaBreadcrumbs__Schema}
        >{`/ ${schema}`}</Typography>
      </div>
    </div>
  )
}
