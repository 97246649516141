import type { AxiosInstance } from 'axios'

import { mockAxios } from '__mocks__/axiosMock'

import {
  type DestinationConfigType,
  type SnowflakeConnectionType,
  type SourceConnectionType,
  type StreamingPipelineDefinition
} from 'types/Streaming'

import type {
  DELETEBranchParams,
  DELETEProjectMembersParams,
  DELETEProjectMembersRequest,
  DELETEProjectRequest,
  DELETEScheduleValues,
  DELETESecretReferenceParams,
  DELETESecretReferenceValues,
  DELETEStreamingPipelineRequest,
  FetchStreamingSourceSchemasRequest,
  FetchStreamingSourceSchemasResponse,
  FetchStreamingSourceTablesRequest,
  FetchStreamingSourceTablesResponse,
  GETAccountMembersResponse,
  GETAssignedStreamingAgentIdsRequest,
  GETAssignedStreamingAgentIdsResponse,
  GETBranchesRequest,
  GETBranchesResponse,
  GETBranchRequest,
  GETBranchResponse,
  GETEnvironmentAgentsRequest,
  GETEnvironmentAgentsResponse,
  GETEnvironmentMembersParams,
  GETEnvironmentMembersResponse,
  GETEnvironmentsRequest,
  GETEnvironmentsResponse,
  GETJobsQueryStringParams,
  GETJobsResponse,
  GETMhaResponse,
  GETOAuthSchemaQueryStringParams,
  GETOAuthSchemaResponse,
  GETPermissionsParams,
  GETPermissionsResponse,
  GETProjectMembersParams,
  GETProjectMembersResponse,
  GETProjectRequest,
  GETProjectResponse,
  GETProjectsStatusResponse,
  GETProjectWithGitResponse,
  GETSchedulesRequest,
  GETSchedulesResponseParams,
  GETSecretReferenceQueryStringParams,
  GETSecretReferencesQueryStringParams,
  GETSecretReferencesResponse,
  GETSecretReferencesResponseParams,
  GETStreamingPipelineAlertPreferenceRequest,
  GETStreamingPipelineEventsRequest,
  GETStreamingPipelineEventsResponse,
  GETStreamingPipelineMetricsRequest,
  GETStreamingPipelineMetricsResponse,
  GETStreamingPipelineRequest,
  GETStreamingPipelineResponseParams,
  GETStreamingPipelinesRequest,
  GETStreamingPipelinesResponse,
  GETStreamingPipelineStatusRequest,
  GETStreamingPipelineStatusResponse,
  GETWarehouseProvisioningResponse,
  PATCHBranchParams,
  PATCHBranchRequest,
  PATCHProjectParams,
  PATCHProjectRequest,
  PATCHSecretValueParams,
  PATCHSecretValueRequest,
  PATCHSecretValueV2Body,
  PATCHSecretValueV2Params,
  POSTBranchQueryStringParams,
  POSTBranchRequest,
  POSTBranchResponse,
  POSTCompleteOAuthRequestV2,
  POSTEnvironmentQueryStringParams,
  POSTEnvironmentRequest,
  POSTEnvironmentResponse,
  POSTOAuthQueryStringParams,
  POSTRolesParams,
  POSTRolesRequest,
  POSTScheduleRequest,
  POSTSecretReferenceQueryStringParams,
  POSTSecretReferenceRequest,
  POSTSecretReferenceResponse,
  POSTSecretValueQueryStringParams,
  POSTSecretValueResponse,
  POSTSecretValueV2Request,
  POSTSetupOAuthV2Request,
  POSTSetupOAuthV2Response,
  POSTSnowflakeListDatabasesRequest,
  POSTSnowflakeListDatabasesResponse,
  POSTSnowflakeListRolesRequest,
  POSTSnowflakeListRolesResponse,
  POSTSnowflakeListSchemasRequest,
  POSTSnowflakeListSchemasResponse,
  POSTSnowflakeListStagesRequest,
  POSTSnowflakeListStagesResponse,
  POSTSnowflakeListWarehousesRequest,
  POSTSnowflakeListWarehousesResponse,
  POSTStreamingPipelineRequest,
  POSTStreamingPipelineRequestValues,
  POSTStreamingPipelineStartRequest,
  POSTStreamingPipelineStopRequest,
  POSTValidateDestinationConfigurationRequest,
  POSTValidateDestinationConfigurationResponse,
  POSTValidateStreamingConnectionRequest,
  POSTValidateStreamingConnectionResponse,
  POSTValidateStreamingSignalTableRequestParams,
  POSTValidateStreamingSignalTableRequestValues,
  POSTValidateStreamingSignalTableResponse,
  POSTWarehouseProvisioningRequest,
  PUTEnvironmentMembersRequestBody,
  PUTEnvironmentMembersRequestPathVariables,
  PUTEnvironmentRequestBody,
  PUTEnvironmentRequestPathVariables,
  PUTScheduleRequest,
  PUTScheduleValues,
  PUTStreamingPipelineAlertPreferenceRequestParams,
  PUTStreamingPipelineAlertPreferenceRequestValues,
  PUTStreamingPipelineRequest,
  StreamingPipelineAlertPreferenceResponse,
  StreamingPipelineResponse
} from './types'

export const getProjects = async (
  /* istanbul ignore next */
  client: AxiosInstance = mockAxios
): Promise<GETProjectResponse[]> => {
  const path = '/v1/projects'
  const { data } = await client.get(path)

  return data
}

export const getProjectsStatus = async (
  /* istanbul ignore next */
  client: AxiosInstance = mockAxios
): Promise<GETProjectsStatusResponse> => {
  const path = '/v1/projects/status'
  const { data } = await client.get(path)

  return data
}

export const getProject = async (
  params: GETProjectRequest,
  /* istanbul ignore next */
  client: AxiosInstance = mockAxios
): Promise<GETProjectWithGitResponse> => {
  const { projectId } = params

  const path = `/v1/projects/${encodeURIComponent(projectId)}`
  const { data } = await client.get(path)
  return data
}

export const patchProject = async (
  values: PATCHProjectRequest,
  params: PATCHProjectParams,
  /* istanbul ignore next */
  client: AxiosInstance = mockAxios
): Promise<void> => {
  const { projectId } = params

  const path = `/v1/projects/${encodeURIComponent(projectId)}`
  const { data } = await client.patch(path, values)

  return data
}

export const getBranches = async (
  params: GETBranchesRequest,
  /* istanbul ignore next */
  client: AxiosInstance = mockAxios
): Promise<GETBranchesResponse> => {
  const { projectId } = params

  const path = `/v1/projects/${encodeURIComponent(projectId)}/branches`
  const { data } = await client.get(path)

  return data
}

export const getBranch = async (
  params: GETBranchRequest,
  /* istanbul ignore next */
  client: AxiosInstance = mockAxios
): Promise<GETBranchResponse> => {
  const { branchId, projectId } = params

  const path = `/v1/projects/${encodeURIComponent(
    projectId
  )}/branches/${encodeURIComponent(branchId)}`
  const { data } = await client.get(path)

  return data
}

export const postBranch = async (
  values: POSTBranchRequest,
  params: POSTBranchQueryStringParams,
  /* istanbul ignore next */
  client: AxiosInstance = mockAxios
): Promise<POSTBranchResponse> => {
  const { projectId } = params

  const path = `/v1/projects/${encodeURIComponent(projectId)}/branches`
  const { data } = await client.post(path, values)

  return data
}

export const patchBranch = async (
  values: PATCHBranchRequest,
  params: PATCHBranchParams,
  /* istanbul ignore next */
  client: AxiosInstance = mockAxios
): Promise<void> => {
  const { projectId, branchId } = params

  const path = `/v1/projects/${encodeURIComponent(
    projectId
  )}/branches/${encodeURIComponent(branchId)}`
  const { data } = await client.patch(path, values)

  return data
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const processStreamingPipelineId = (pipeline: any) => {
  const processed = {
    ...pipeline,
    id: pipeline.pipelineId
  }
  delete processed.pipelineId
  return processed
}

export const getStreamingPipelines = async (
  params: GETStreamingPipelinesRequest,
  /* istanbul ignore next */
  client: AxiosInstance = mockAxios
): Promise<GETStreamingPipelinesResponse> => {
  const { projectId } = params

  const path = `/v1/pipelines?projectId=${encodeURIComponent(projectId)}`
  const { data } = await client.get(path)

  return data.map((pipeline: GETStreamingPipelineResponseParams) =>
    processStreamingPipelineId(pipeline)
  )
}

export const getStreamingPipeline = async (
  params: GETStreamingPipelineRequest,
  /* istanbul ignore next */
  client: AxiosInstance = mockAxios
): Promise<StreamingPipelineResponse> => {
  const { projectId, pipelineId } = params

  const path = `/v1/pipelines/${encodeURIComponent(pipelineId)}?projectId=${encodeURIComponent(projectId)}`
  const { data } = await client.get(path)
  return processStreamingPipelineId(data)
}

export const getStreamingPipelineMetrics = async (
  params: GETStreamingPipelineMetricsRequest,
  /* istanbul ignore next */
  client: AxiosInstance = mockAxios
): Promise<GETStreamingPipelineMetricsResponse> => {
  const { projectId, pipelineId } = params

  const path = `/v1/pipelines/${encodeURIComponent(pipelineId)}/metrics?projectId=${encodeURIComponent(projectId)}`
  const { data } = await client.get(path)
  return data
}

export const postStreamingPipeline = async (
  values: POSTStreamingPipelineRequestValues,
  params: POSTStreamingPipelineRequest,
  /* istanbul ignore next */
  client: AxiosInstance = mockAxios
): Promise<StreamingPipelineResponse> => {
  const { projectId } = params

  const path = `/v1/pipelines?projectId=${encodeURIComponent(projectId)}`

  const { data } = await client.post(path, values)
  return processStreamingPipelineId(data)
}

export const putStreamingPipeline = async (
  values: StreamingPipelineDefinition,
  params: PUTStreamingPipelineRequest,
  /* istanbul ignore next */
  client: AxiosInstance = mockAxios
): Promise<StreamingPipelineResponse> => {
  const { projectId } = params

  const path = `/v1/pipelines/${values.id}?projectId=${encodeURIComponent(projectId)}`
  const { id, ...requestBody } = values

  const { data } = await client.put(path, { ...requestBody, pipelineId: id })
  return processStreamingPipelineId(data)
}

export const getStreamingPipelineStatus = async (
  params: GETStreamingPipelineStatusRequest,
  /* istanbul ignore next */
  client: AxiosInstance = mockAxios
): Promise<GETStreamingPipelineStatusResponse> => {
  const { projectId, pipelineId } = params

  const path = `/v1/pipelines/${encodeURIComponent(pipelineId)}/status?projectId=${encodeURIComponent(projectId)}`
  const { data } = await client.get(path)
  return data
}

export const getStreamingPipelineEvents = async (
  params: GETStreamingPipelineEventsRequest,
  /* istanbul ignore next */
  client: AxiosInstance = mockAxios
): Promise<GETStreamingPipelineEventsResponse> => {
  const { projectId, pipelineId } = params

  const path = `/v1/pipelines/${encodeURIComponent(pipelineId)}/events?projectId=${encodeURIComponent(projectId)}`
  const { data } = await client.get(path)
  return data
}

export const getStreamingPipelineAlertPreference = async (
  params: GETStreamingPipelineAlertPreferenceRequest,
  /* istanbul ignore next */
  client: AxiosInstance = mockAxios
): Promise<StreamingPipelineAlertPreferenceResponse> => {
  const { projectId, pipelineId } = params

  const path = `/v1/pipelines/${encodeURIComponent(pipelineId)}/alerts?projectId=${encodeURIComponent(projectId)}`
  const { data } = await client.get(path)
  return data
}

export const putStreamingPipelineAlertPreference = async (
  values: PUTStreamingPipelineAlertPreferenceRequestValues,
  params: PUTStreamingPipelineAlertPreferenceRequestParams,
  /* istanbul ignore next */
  client: AxiosInstance = mockAxios
): Promise<StreamingPipelineAlertPreferenceResponse> => {
  const { pipelineId, projectId } = params

  const path = `/v1/pipelines/${encodeURIComponent(pipelineId)}/alerts?projectId=${encodeURIComponent(projectId)}`
  const { data } = await client.put(path, values)
  return data
}

export const startStreamingPipeline = async (
  params: POSTStreamingPipelineStartRequest,
  /* istanbul ignore next */
  client: AxiosInstance = mockAxios
): Promise<void> => {
  const { projectId, pipelineId } = params

  const path = `/v1/pipelines/${encodeURIComponent(pipelineId)}/start?projectId=${encodeURIComponent(projectId)}`
  const { data } = await client.post(path)
  return data
}

export const stopStreamingPipeline = async (
  params: POSTStreamingPipelineStopRequest,
  /* istanbul ignore next */
  client: AxiosInstance = mockAxios
): Promise<void> => {
  const { projectId, pipelineId } = params

  const path = `/v1/pipelines/${encodeURIComponent(pipelineId)}/stop?projectId=${encodeURIComponent(projectId)}`
  const { data } = await client.post(path)
  return data
}

export const deleteStreamingPipeline = async (
  params: DELETEStreamingPipelineRequest,
  /* istanbul ignore next */
  client: AxiosInstance = mockAxios
): Promise<void> => {
  const { projectId, pipelineId } = params

  const path = `/v1/pipelines/${encodeURIComponent(pipelineId)}?projectId=${encodeURIComponent(projectId)}`
  const { data } = await client.delete(path)
  return data
}

export const getAssignedStreamingAgentIds = async (
  params: GETAssignedStreamingAgentIdsRequest,
  /* istanbul ignore next */
  client: AxiosInstance = mockAxios
): Promise<GETAssignedStreamingAgentIdsResponse> => {
  const { projectId } = params

  const path = `/v1/agents/assigned?projectId=${encodeURIComponent(projectId)}`
  const { data } = await client.get(path)
  return data
}

export const postValidateStreamingConnection = async (
  params: POSTValidateStreamingConnectionRequest,
  values: SourceConnectionType,
  /* istanbul ignore next */
  client: AxiosInstance = mockAxios
): Promise<POSTValidateStreamingConnectionResponse> => {
  const { type, agentId } = params

  const path = `/v1/validate/${encodeURIComponent(type)}/connection`
  const { data } = await client.post(path, values, {
    headers: {
      'agent-id': agentId
    }
  })
  return data
}

export const postValidateDestinationConfiguration = async (
  params: POSTValidateDestinationConfigurationRequest,
  values: DestinationConfigType,
  /* istanbul ignore next */
  client: AxiosInstance = mockAxios
): Promise<POSTValidateDestinationConfigurationResponse> => {
  const { agentId } = params

  const path = `/v1/validate/${values.type}/target`

  const { data } = await client.post(path, values, {
    headers: {
      'agent-id': agentId
    }
  })
  return data
}

export const postValidateStreamingSignalTable = async (
  params: POSTValidateStreamingSignalTableRequestParams,
  values: POSTValidateStreamingSignalTableRequestValues,
  /* istanbul ignore next */
  client: AxiosInstance = mockAxios
): Promise<POSTValidateStreamingSignalTableResponse> => {
  const { type, agentId } = params

  const path = `/v1/validate/${encodeURIComponent(type)}/signal-table`
  const { data } = await client.post(path, values, {
    headers: {
      'agent-id': agentId
    }
  })
  return data
}

export const fetchStreamingSourceSchemas = async (
  params: FetchStreamingSourceSchemasRequest,
  /* istanbul ignore next */
  client: AxiosInstance = mockAxios
): Promise<FetchStreamingSourceSchemasResponse> => {
  const { type, agentId, connection } = params

  const path = `/v1/metadata/${encodeURIComponent(type)}/schemas`
  const { data } = await client.post(
    path,
    { connection },
    {
      headers: {
        'agent-id': agentId
      }
    }
  )
  return data
}

export const fetchStreamingSourceTables = async (
  params: FetchStreamingSourceTablesRequest,
  /* istanbul ignore next */
  client: AxiosInstance = mockAxios
): Promise<FetchStreamingSourceTablesResponse> => {
  const { type, agentId, connection, schema } = params

  const path = `/v1/metadata/${encodeURIComponent(type)}/tables`
  const { data } = await client.post(
    path,
    { connection, schema },
    {
      headers: {
        'agent-id': agentId
      }
    }
  )
  return data
}

export const postSnowflakeListRolesRequest = async (
  params: POSTSnowflakeListRolesRequest,
  values: SnowflakeConnectionType,
  /* istanbul ignore next */
  client: AxiosInstance = mockAxios
): Promise<POSTSnowflakeListRolesResponse> => {
  const { agentId } = params

  const path = '/v1/metadata/snowflake/roles'
  const { data } = await client.post(
    path,
    { connection: values },
    {
      headers: {
        'agent-id': agentId
      }
    }
  )
  return data
}

export const postSnowflakeListWarehousesRequest = async (
  params: POSTSnowflakeListWarehousesRequest,
  values: SnowflakeConnectionType,
  /* istanbul ignore next */
  client: AxiosInstance = mockAxios
): Promise<POSTSnowflakeListDatabasesResponse> => {
  const { agentId, role } = params

  const path = '/v1/metadata/snowflake/warehouses'
  const { data } = await client.post(
    path,
    { connection: values, role },
    {
      headers: {
        'agent-id': agentId
      }
    }
  )
  return data
}

export const postSnowflakeListDatabasesRequest = async (
  params: POSTSnowflakeListDatabasesRequest,
  values: SnowflakeConnectionType,
  /* istanbul ignore next */
  client: AxiosInstance = mockAxios
): Promise<POSTSnowflakeListWarehousesResponse> => {
  const { agentId, role } = params

  const path = '/v1/metadata/snowflake/databases'
  const { data } = await client.post(
    path,
    { connection: values, role },
    {
      headers: {
        'agent-id': agentId
      }
    }
  )
  return data
}

export const postSnowflakeListSchemasRequest = async (
  params: POSTSnowflakeListSchemasRequest,
  values: SnowflakeConnectionType,
  /* istanbul ignore next */
  client: AxiosInstance = mockAxios
): Promise<POSTSnowflakeListSchemasResponse> => {
  const { agentId, role, database } = params

  const path = '/v1/metadata/snowflake/schemas'
  const { data } = await client.post(
    path,
    { connection: values, role, database },
    {
      headers: {
        'agent-id': agentId
      }
    }
  )
  return data
}

export const postSnowflakeListStagesRequest = async (
  params: POSTSnowflakeListStagesRequest,
  values: SnowflakeConnectionType,
  /* istanbul ignore next */
  client: AxiosInstance = mockAxios
): Promise<POSTSnowflakeListStagesResponse> => {
  const { agentId, role, database, schema } = params

  const path = '/v1/metadata/snowflake/stages'
  const { data } = await client.post(
    path,
    { connection: values, role, database, schema },
    {
      headers: {
        'agent-id': agentId
      }
    }
  )
  return data
}

export const getEnvironments = async (
  params: GETEnvironmentsRequest,
  /* istanbul ignore next */
  client: AxiosInstance = mockAxios
): Promise<GETEnvironmentsResponse> => {
  const { projectId } = params

  const path = `/v1/projects/${encodeURIComponent(projectId)}/environments`
  const { data } = await client.get(path)

  return data
}

export const postEnvironment = async (
  values: POSTEnvironmentRequest,
  params: POSTEnvironmentQueryStringParams,
  /* istanbul ignore next */
  client: AxiosInstance = mockAxios
): Promise<POSTEnvironmentResponse> => {
  const { projectId } = params
  const path = `/v1/environments?projectId=${encodeURIComponent(projectId)}`
  const { data } = await client.post(path, values)

  return data
}

export const postEnvironmentV2 = async (
  values: POSTEnvironmentRequest,
  params: POSTEnvironmentQueryStringParams,
  /* istanbul ignore next */
  client: AxiosInstance = mockAxios
): Promise<POSTEnvironmentResponse> => {
  const { projectId } = params
  const path = `/v2/environments?projectId=${encodeURIComponent(projectId)}`
  const { data } = await client.post(path, values)

  return data
}

export const updateEnvironment = async (
  pathParams: PUTEnvironmentRequestPathVariables,
  bodyValues: PUTEnvironmentRequestBody,
  /* istanbul ignore next */
  client: AxiosInstance = mockAxios
): Promise<void> => {
  const { environmentId, projectId } = pathParams

  const path = `/v1/environments/${encodeURIComponent(
    environmentId
  )}?projectId=${encodeURIComponent(projectId)}`
  const { data } = await client.put(path, bodyValues)

  return data
}

export const updateEnvironmentV2 = async (
  pathParams: PUTEnvironmentRequestPathVariables,
  bodyValues: PUTEnvironmentRequestBody,
  /* istanbul ignore next */
  client: AxiosInstance = mockAxios
): Promise<void> => {
  const { projectId, environmentId } = pathParams

  const path = `/v2/environments/${encodeURIComponent(
    environmentId
  )}?projectId=${encodeURIComponent(projectId)}`
  const { data } = await client.put(path, bodyValues)

  return data
}

export const updateEnvironmentMembers = async (
  pathParams: PUTEnvironmentMembersRequestPathVariables,
  bodyValues: PUTEnvironmentMembersRequestBody,
  /* istanbul ignore next */
  client: AxiosInstance = mockAxios
): Promise<void> => {
  const { environmentId } = pathParams

  const path = `/v1/environments/${encodeURIComponent(environmentId)}/members`
  const { data } = await client.put(path, bodyValues)

  return data
}

export const getJobs = async (
  params: GETJobsQueryStringParams,
  /* istanbul ignore next */
  client: AxiosInstance = mockAxios
): Promise<GETJobsResponse> => {
  const { environmentId, projectId } = params

  const path = `/v1/gateway/workflow/jobs?projectId=${encodeURIComponent(
    projectId
  )}&environmentId=${encodeURIComponent(environmentId)}`
  const { data } = await client.get(path)

  return data.jobs
}

export const getSecretReferences = async (
  params: GETSecretReferencesQueryStringParams,
  /* istanbul ignore next */
  client: AxiosInstance = mockAxios
): Promise<GETSecretReferencesResponse> => {
  const { projectId, secretType, providers } = params

  const path = '/v1/gateway/srs/secret-references'
  const { data } = await client.get(path, {
    params: {
      projectId: encodeURIComponent(projectId),
      secretType: Array.isArray(secretType)
        ? secretType.map(encodeURIComponent).join(',')
        : encodeURIComponent(secretType),
      providers: providers?.map(encodeURIComponent).join(',')
    }
  })

  return data.secrets
}

export const getSecretReference = async (
  params: GETSecretReferenceQueryStringParams,
  /* istanbul ignore next */
  client: AxiosInstance = mockAxios
): Promise<GETSecretReferencesResponseParams> => {
  const { projectId, secretReferenceId } = params

  const path = `/v1/gateway/srs/secret-reference/${encodeURIComponent(secretReferenceId)}`
  const { data } = await client.get(path, {
    params: {
      projectId: encodeURIComponent(projectId)
    }
  })
  return data
}

export const postSecretReference = async (
  values: POSTSecretReferenceRequest,
  params: POSTSecretReferenceQueryStringParams,
  /* istanbul ignore next */
  client: AxiosInstance = mockAxios
): Promise<POSTSecretReferenceResponse> => {
  const { projectId } = params
  let path = '/v1/gateway/srs/secret-reference'
  path = projectId ? `${path}?projectId=${encodeURIComponent(projectId)}` : path
  const { data } = await client.post(path, values)

  return data
}

export const deleteSecretReference = async (
  { id }: DELETESecretReferenceValues,
  { projectId }: DELETESecretReferenceParams,
  /* istanbul ignore next */
  client: AxiosInstance = mockAxios
): Promise<void> => {
  const path = `/v1/gateway/srs/secret-references/${encodeURIComponent(id)}`
  const { data } = await client.delete(path, {
    params: {
      projectId
    }
  })

  return data
}

export const postSchedule = async (
  values: POSTScheduleRequest,
  /* istanbul ignore next */
  client: AxiosInstance = mockAxios
): Promise<void> => {
  const path = '/v1/gateway/scheduler/schedules'
  const { data } = await client.post(path, values)

  return data
}

export const deleteSchedule = async (
  params: DELETEScheduleValues,
  /* istanbul ignore next */
  client: AxiosInstance = mockAxios
): Promise<void> => {
  const { id, projectId } = params
  const path = `/v1/gateway/scheduler/schedules/${encodeURIComponent(
    id
  )}?projectId=${encodeURIComponent(projectId)}`
  const { data } = await client.delete(path)

  return data
}

export const getSchedules = async (
  params: GETSchedulesRequest,
  /* istanbul ignore next */
  client: AxiosInstance = mockAxios
): Promise<GETSchedulesResponseParams> => {
  const { projectId } = params

  const path = `/v1/gateway/scheduler/schedules?projectId=${encodeURIComponent(
    projectId
  )}`

  const { data } = await client.get(path)
  return data
}

export const updateSchedule = async (
  scheduleId: string,
  values: PUTScheduleValues,
  /* istanbul ignore next */
  client: AxiosInstance = mockAxios
): Promise<PUTScheduleRequest> => {
  const path = `/v1/gateway/scheduler/schedules/${encodeURIComponent(
    scheduleId
  )}`
  const { data } = await client.put(path, values)
  return data
}

export const getMha = async (
  /* istanbul ignore next */
  client: AxiosInstance = mockAxios
): Promise<GETMhaResponse> => {
  const { data } = await client.get('/v1/mha')

  return data
}

export const postMha = async (
  /* istanbul ignore next */
  client: AxiosInstance = mockAxios
): Promise<void> => {
  const { data } = await client.post('/v1/mha')
  return data
}

export const postSecretValueV2 = async (
  values: POSTSecretValueV2Request,
  params: POSTSecretValueQueryStringParams,
  /* istanbul ignore next */
  client: AxiosInstance = mockAxios
): Promise<POSTSecretValueResponse> => {
  const { projectId } = params
  const path = '/v2/gateway/srs/secret-value'

  const { data } = await client.post(path, values, {
    params: {
      projectId: projectId && encodeURIComponent(projectId)
    }
  })
  return data
}

export const patchSecretValueV2 = async (
  secretReferenceId: string,
  values: PATCHSecretValueV2Body,
  params: PATCHSecretValueV2Params,
  /* istanbul ignore next */
  client: AxiosInstance = mockAxios
): Promise<void> => {
  const { projectId } = params
  const path = `/v2/gateway/srs/secret-value/${encodeURIComponent(
    secretReferenceId
  )}`
  const { data } = await client.patch(path, values, {
    params: {
      projectId: projectId && encodeURIComponent(projectId)
    }
  })
  return data
}

export const patchSecretValue = async (
  values: PATCHSecretValueRequest,
  params: PATCHSecretValueParams,
  /* istanbul ignore next */
  client: AxiosInstance = mockAxios
): Promise<void> => {
  const { projectId, secretReferenceId } = params
  const path = `/v1/gateway/srs/secret-value/${encodeURIComponent(
    secretReferenceId
  )}`

  const { data } = await client.patch(path, values, {
    params: {
      projectId: encodeURIComponent(projectId)
    }
  })

  return data
}

export const getAccountMembers = async (
  /* istanbul ignore next */
  client: AxiosInstance = mockAxios
): Promise<GETAccountMembersResponse> => {
  const path = '/members/v1'

  const { data: members } = await client.get(path)

  return members
}

export const getProjectMembers = async (
  params: GETProjectMembersParams,
  /* istanbul ignore next */
  client: AxiosInstance = mockAxios
): Promise<GETProjectMembersResponse> => {
  const path = '/members/v1'

  const { data: members } = await client.get(path, {
    params: {
      'resource-type': 'project',
      'resource-id': encodeURIComponent(params.projectId)
    }
  })

  return members
}
export const getEnvironmentMembers = async (
  params: GETEnvironmentMembersParams,
  /* istanbul ignore next */
  client: AxiosInstance = mockAxios
): Promise<GETEnvironmentMembersResponse> => {
  const path = '/members/v1'

  const { data: members } = await client.get(path, {
    params: {
      'resource-type': 'environment',
      'resource-id': encodeURIComponent(params.environmentId)
    }
  })

  return members
}

export const postRoles = async (
  params: POSTRolesParams,
  values: POSTRolesRequest,
  /* istanbul ignore next */
  client: AxiosInstance = mockAxios
): Promise<void> => {
  const { projectId } = params

  const path = `/v1/projects/${encodeURIComponent(projectId)}/roles`

  const { data } = await client.post(path, values)

  return data
}

export const deleteProjectMembers = async (
  params: DELETEProjectMembersParams,
  values: DELETEProjectMembersRequest,
  /* istanbul ignore next */
  client: AxiosInstance = mockAxios
): Promise<void> => {
  const { projectId } = params
  const path = `/v1/projects/${encodeURIComponent(projectId)}/roles`
  const { data } = await client.delete(path, { data: { ...values } })
  return data
}

export const deleteBranch = async (
  params: DELETEBranchParams,
  /* istanbul ignore next */
  client: AxiosInstance = mockAxios
): Promise<void> => {
  const { branchId, projectId } = params
  const path = `/v1/projects/${encodeURIComponent(
    projectId
  )}/branches/${encodeURIComponent(branchId)}`
  const { data } = await client.delete(path)

  return data
}

export const deleteProject = async (
  params: DELETEProjectRequest,
  /* istanbul ignore next */
  client: AxiosInstance = mockAxios
): Promise<void> => {
  const { projectId } = params
  const path = `/v1/projects/${encodeURIComponent(projectId)}`
  const { data } = await client.delete(path)

  return data
}

export const createOAuthV2 = async (
  values: POSTSetupOAuthV2Request,
  params: POSTOAuthQueryStringParams,
  /* istanbul ignore next */
  client: AxiosInstance = mockAxios
): Promise<POSTSetupOAuthV2Response> => {
  const { projectId } = params
  const { data } = await client.post('/v2/gateway/oauth/setup', values, {
    params: {
      projectId: encodeURIComponent(projectId)
    }
  })

  return data
}

export const getOAuthSchema = async (
  params: GETOAuthSchemaQueryStringParams,
  /* istanbul ignore next */
  client: AxiosInstance = mockAxios
): Promise<GETOAuthSchemaResponse> => {
  const { projectId, agentId } = params
  const { data } = await client.get('/v1/gateway/oauth/schema', {
    params: {
      projectId: encodeURIComponent(projectId),
      agentId: encodeURIComponent(agentId)
    }
  })

  return data
}

export const postWarehouseProvisioning = async (
  values: POSTWarehouseProvisioningRequest,
  /* istanbul ignore next */
  client: AxiosInstance = mockAxios
): Promise<void> => {
  const { data } = await client.post('/v1/warehouse', values)

  return data
}

export const getWarehouseProvisioning = async (
  /* istanbul ignore next */
  client: AxiosInstance = mockAxios
): Promise<GETWarehouseProvisioningResponse> => {
  const { data } = await client.get('/v1/warehouse')

  return data
}

export const getPermissions = async (
  params: GETPermissionsParams,
  /* istanbul ignore next */
  client: AxiosInstance = mockAxios
): Promise<GETPermissionsResponse> => {
  const { projectId, permission } = params
  const path = `/v1/projects/${projectId}/permissions/${permission}`
  const { data } = await client.get(path)

  return data
}

export const getEnvironmentAgents = async (
  /* istanbul ignore next */
  client: AxiosInstance = mockAxios,
  params?: GETEnvironmentAgentsRequest
): Promise<GETEnvironmentAgentsResponse> => {
  const { data } = await client.get('/v1/gateway/agents', { params })

  return data
}

export const completeOAuthV2 = async (
  values: POSTCompleteOAuthRequestV2,
  params: POSTOAuthQueryStringParams,
  /* istanbul ignore next */
  client: AxiosInstance = mockAxios
): Promise<void> => {
  const { projectId } = params
  const { data } = await client.post('/v2/gateway/oauth/complete', values, {
    params: {
      projectId: encodeURIComponent(projectId)
    }
  })

  return data
}
