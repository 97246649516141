import { type FC } from 'react'

import { Button, Icon, Typography } from '@matillion/component-library'

import classes from 'modules/Projects/StreamingPipelineDashboard/Metrics/components/SchemaButton/SchemaButton.module.scss'

interface SchemaButtonProps {
  text: string
  onClick: () => void
}

const SchemaButton: FC<SchemaButtonProps> = ({ text, onClick }) => {
  return (
    <Button
      alt="text"
      onClick={onClick}
      className={classes.SchemaButton__Button}
    >
      <Icon.Schema className={classes.SchemaButton__Icon} />
      <Typography format="bcs" data-testid="schema-button-text">
        {text}
      </Typography>
    </Button>
  )
}

export default SchemaButton
