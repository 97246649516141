import { type FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'

import { useFormikContext } from 'formik'

import { Icon, MatillionLogo } from '@matillion/component-library'

import { Header, HeaderTitle } from 'components/Header'
import { Databricks, Redshift } from 'components/Logo'

import { useOnboardingContext } from 'context'

import { useFlags } from 'hooks/flags'

import { WarehouseProvisioningCard } from 'modules/FormsContent/WarehouseProvisioningSelection'
import { type WarehouseProvisioningI } from 'modules/FormsContent/WarehouseProvisioningSelection/types'

import { ProvisionType, Warehouse } from 'types'

import classes from './WarehouseProvisioningSelection.module.scss'

const WarehouseProvisioningSelection: FunctionComponent = () => {
  const { t } = useTranslation()
  const {
    setFieldValue,
    values: { type, warehouseProvisioning }
  } = useFormikContext<WarehouseProvisioningI>()
  const { setIsTrialWarehouse } = useOnboardingContext()
  const {
    enableDatabricksProjects,
    enableRedshiftProjects,
    enableDataWarehouseCardDetailExperiment
  } = useFlags()

  return (
    <>
      <Header>
        <HeaderTitle data-testid="warehouse-provision-title">
          {t('warehouseProvisioning.title')}
        </HeaderTitle>
      </Header>

      <div className={classes.WarehouseProvisioningSelection__Container}>
        <WarehouseProvisioningCard
          icon={
            <MatillionLogo
              height={50}
              layout="compact"
              aria-label="matillion-logo"
              alt="Matillion Logo"
            />
          }
          bannerText={t('warehouseProvisioning.trial.bannerText')}
          title={t('warehouseProvisioning.trial.title')}
          supportText={t('warehouseProvisioning.trial.supportText')}
          minutes={t('warehouseProvisioning.trial.minutes')}
          poweredByIcon={<Icon.SnowflakeText height={27} width={100} />}
          isSelected={warehouseProvisioning === ProvisionType.Trial}
          onSelect={() => {
            setFieldValue('warehouseProvisioning', ProvisionType.Trial)
            setFieldValue('type', Warehouse.Snowflake)
            setIsTrialWarehouse(true)
          }}
          provisionType={ProvisionType.Trial}
          warehouseType={Warehouse.Snowflake}
          showDetail={!!enableDataWarehouseCardDetailExperiment}
        />
        <WarehouseProvisioningCard
          icon={<Icon.Snowflake height={62} />}
          title={t('warehouseProvisioning.snowflake.title')}
          supportText={t('warehouseProvisioning.snowflake.supportText')}
          supportBullets={t('warehouseProvisioning.snowflake.supportBullets', {
            returnObjects: true
          })}
          minutes={t('warehouseProvisioning.snowflake.minutes')}
          isSelected={
            warehouseProvisioning === ProvisionType.Customer &&
            type === Warehouse.Snowflake
          }
          onSelect={() => {
            setFieldValue('warehouseProvisioning', ProvisionType.Customer)
            setFieldValue('type', Warehouse.Snowflake)
            setIsTrialWarehouse(false)
          }}
          provisionType={ProvisionType.Customer}
          warehouseType={Warehouse.Snowflake}
          showDetail={!!enableDataWarehouseCardDetailExperiment}
        />
        <WarehouseProvisioningCard
          icon={<Redshift height={60} />}
          title={t('warehouseProvisioning.redshift.title')}
          supportText={t('warehouseProvisioning.redshift.supportText')}
          supportBullets={t('warehouseProvisioning.redshift.supportBullets', {
            returnObjects: true
          })}
          minutes={t('warehouseProvisioning.redshift.minutes')}
          isSelected={
            warehouseProvisioning === ProvisionType.Customer &&
            type === Warehouse.Redshift
          }
          onSelect={() => {
            setFieldValue('warehouseProvisioning', ProvisionType.Customer)
            setFieldValue('type', Warehouse.Redshift)
            setIsTrialWarehouse(false)
          }}
          provisionType={ProvisionType.Customer}
          warehouseType={Warehouse.Redshift}
          showDetail={!!enableDataWarehouseCardDetailExperiment}
          unavailable={!enableRedshiftProjects}
        />
        <WarehouseProvisioningCard
          icon={<Databricks height={50} />}
          title={t('warehouseProvisioning.databricks.title')}
          supportText={t('warehouseProvisioning.databricks.supportText')}
          supportBullets={t('warehouseProvisioning.databricks.supportBullets', {
            returnObjects: true
          })}
          minutes={t('warehouseProvisioning.databricks.minutes')}
          isSelected={type === Warehouse.Databricks}
          onSelect={() => {
            setFieldValue('warehouseProvisioning', ProvisionType.Customer)
            setFieldValue('type', Warehouse.Databricks)
            setIsTrialWarehouse(false)
          }}
          provisionType={ProvisionType.Customer}
          warehouseType={Warehouse.Databricks}
          showDetail={!!enableDataWarehouseCardDetailExperiment}
          unavailable={!enableDatabricksProjects}
        />
      </div>
    </>
  )
}

export default WarehouseProvisioningSelection
