import { useEffect } from 'react'
import ReactDatePicker from 'react-date-picker'
import { type Value } from 'react-date-picker/dist/cjs/shared/types'

import classNames from 'classnames'
import { format } from 'date-fns'

import { Icon } from '@matillion/component-library'

import 'react-date-picker/dist/DatePicker.css'
import 'react-calendar/dist/Calendar.css'

import classes from './DatePicker.module.scss'

// Unfortunately the 'react-date-picker' library has stopped exporting it's types from 'react-date-picker/dist/cjs/DatePicker',
// so just manually specifying some for the time being
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const DatePicker = (props: {
  className?: string
  onChange?: (value: Value) => void
  onCalendarClose?: () => void
  minDate?: Date
  value?: Date
  'data-testid'?: string
}) => {
  const { className, 'data-testid': dataTestId, value, ...rest } = props
  // Firefox has default functionality and showing a built in calendar on type=date for the input
  // we want to prevent this by forcing the type to be text
  useEffect(() => {
    const calendarInput = document.querySelector('input[type="date"]')
    calendarInput?.setAttribute('type', 'text')
  })

  return (
    <div data-testid={dataTestId}>
      <ReactDatePicker
        className={classNames(
          classes.DatePicker,
          Boolean(value) && classes['DatePicker--filled'],
          className
        )}
        calendarIcon={<Icon.Calendar />}
        clearIcon={null}
        formatShortWeekday={(_locale, date) => format(new Date(date), 'eeeee')}
        minDetail="month"
        format="dd-MM-yyyy"
        value={value}
        {...rest}
      />
    </div>
  )
}

export default DatePicker
