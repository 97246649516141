import { useCallback, useEffect, useMemo, type FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'

import { useFormikContext } from 'formik'

import { AutoComplete, Field } from '@matillion/component-library'

import { WarehouseLookupTypes } from 'api/createProjectForm/types/WarehouseDefaultsRequest'
import { useMutateWarehouseDefaults } from 'api/hooks'
import { createWarehouseDefaultsMutation } from 'api/mutation'
import { type ErrorResponse } from 'api/types'

import classes from 'components/Form/Form.module.scss'

import { StatusCodes } from 'constants/statusCodes'

import { type CreateProjectFormikValueTypes } from 'modules/Projects/CreateProject/CreateProjectForm'
import { renderFormikError } from 'modules/utils'

export interface LocationProps {
  isFieldEnabled?: boolean
  listenerFields?: Array<keyof CreateProjectFormikValueTypes>
}
export const DefaultStorageLocation: FunctionComponent<LocationProps> = ({
  isFieldEnabled = true,
  listenerFields = []
}) => {
  const {
    errors,
    handleBlur,
    handleChange,
    touched,
    values,
    setFieldTouched,
    setFieldValue,
    initialValues
  } = useFormikContext<CreateProjectFormikValueTypes>()
  const { t } = useTranslation()

  const projectType = values.type || 'common'

  const {
    data: locationDefaultData = [],
    isError,
    isLoading,
    error,
    mutate
  } = useMutateWarehouseDefaults(WarehouseLookupTypes.LOCATION)

  const defaultStorageLocationError = renderFormikError(
    errors.defaultStorageLocation,
    Boolean(touched.defaultStorageLocation)
  )

  const getErrorMessage = useMemo(() => {
    if (isError && isFieldEnabled) {
      const errorDetail = (error?.response?.data as ErrorResponse)?.detail
      const errorResponseStatus = error?.response?.status
      if (
        errorResponseStatus === StatusCodes.BAD_REQUEST &&
        errorDetail !== undefined
      ) {
        return errorDetail
      }
      return t('fields.defaultStorageLocation.error.loadingError')
    }
    if (!locationDefaultData.length && isFieldEnabled) {
      return t('fields.defaultStorageLocation.error.noItemsFound')
    }
    if (defaultStorageLocationError) return t(defaultStorageLocationError)
    return null
  }, [
    defaultStorageLocationError,
    isFieldEnabled,
    isError,
    locationDefaultData.length,
    t,
    error
  ])

  const listenerFieldValues = listenerFields.map((field) => values[field])
  const resetDefaultStorageLocation = useCallback(() => {
    setFieldTouched('defaultStorageLocation', false)
    setFieldValue('defaultStorageLocation', { id: '', name: '' })
  }, [setFieldTouched, setFieldValue])

  useEffect(() => {
    if (!isFieldEnabled) {
      resetDefaultStorageLocation()
    }

    listenerFields.forEach((field) => {
      const listenerFieldInitialValue = initialValues[field]
      const listenerFieldCurrentValue = values[field]
      if (listenerFieldCurrentValue !== listenerFieldInitialValue) {
        resetDefaultStorageLocation()
      }
    })
    if (isFieldEnabled) {
      mutate({
        values: createWarehouseDefaultsMutation(values)
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // eslint-disable-next-line react-hooks/exhaustive-deps
    ...listenerFieldValues,
    isFieldEnabled,
    mutate,
    resetDefaultStorageLocation,
    setFieldTouched,
    setFieldValue
  ])

  return (
    <div data-testid={`${projectType}-default-storage-location`}>
      <Field
        inputComponent={AutoComplete}
        availableItems={locationDefaultData.map((item) => ({
          id: item,
          name: item
        }))}
        loading={isLoading}
        title={t('fields.defaultStorageLocation.title')}
        name="defaultStorageLocation"
        data-testid={`${projectType}-default-storage-location-input`}
        value={values.defaultStorageLocation}
        placeholder={
          isError
            ? t('fields.defaultStorageLocation.error.placeholderText')
            : t('fields.defaultStorageLocation.placeholderText')
        }
        onChange={handleChange}
        onBlur={handleBlur}
        className={classes.Form__SpacingStyles}
        errorText={isLoading ? undefined : getErrorMessage}
        hasError={
          Boolean(errors?.defaultStorageLocation?.id) &&
          Boolean(touched.defaultStorageLocation) &&
          !isLoading
        }
        required
        disabled={(isError && !isLoading) || !isFieldEnabled}
      />
    </div>
  )
}
