import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import classNames from 'classnames'

import Form from 'components/Form'

import { ONBOARDING_STORAGE_ITEM } from 'constants/persistance'
import {
  AppRoutes,
  PROJECTS_CREATE_AGENT,
  PROVISIONING_TRIAL_WAREHOUSE,
  WAREHOUSE
} from 'constants/route'

import { useOnboardingContext } from 'context'

import classes from 'modules/FormsContent/WarehouseProvisioningSelection/WarehouseProvisioningSelection.module.scss'
import OnboardingRoutes from 'modules/Onboarding/OnboardingRoutes'

import { initialValues, type OnboardingFormikValueTypes } from './'
import { useOnboardingSubmit, useValidationSchema } from './hooks'

const OnboardingForm = () => {
  const location = useLocation()
  const { submitForm, isLastStep, nextStep, progress, stepIndex, steps } =
    useOnboardingSubmit()

  const navigate = useNavigate()
  const { projectType } = useOnboardingContext()

  const onCancel = () => {
    navigate(`${AppRoutes.getOnboardingAdd()}`)
  }

  const validationSchema = useValidationSchema(steps)

  useEffect(() => {
    const handleBackButton = (event: PopStateEvent) => {
      onCancel()
    }

    window.addEventListener('popstate', handleBackButton)

    return () => {
      window.removeEventListener('popstate', handleBackButton)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, projectType])

  return (
    <Form<OnboardingFormikValueTypes>
      formikValues={{
        onSubmit: submitForm,
        initialValues: initialValues(),
        validationSchema,
        initialTouched: false
      }}
      translationPrefix="createProject"
      stepInfo={{ isLastStep, stepIndex, nextStep, progress }}
      persistingStorageId={ONBOARDING_STORAGE_ITEM}
      persistenceExclusions={[
        'secretValue',
        'awsSecretAccessKey',
        'awsAccessKeyId'
      ]}
      removeSubmitButtonOnRoutes={[
        PROJECTS_CREATE_AGENT,
        PROVISIONING_TRIAL_WAREHOUSE
      ]}
      buttonsClassName={classNames({
        [classes.WarehouseProvisioningSelection__ButtonContainer]:
          location.pathname.includes(
            `${AppRoutes.getOnboardingAdd()}/${WAREHOUSE}`
          )
      })}
    >
      <OnboardingRoutes />
    </Form>
  )
}

export default OnboardingForm
