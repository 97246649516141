import { type ChangeEvent, type FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'

import classNames from 'classnames'
import { useFormikContext } from 'formik'

import { Field, Toggle } from '@matillion/component-library'

import classes from 'components/Form/Form.module.scss'

import { type CreateProjectFormikValueTypes } from 'modules/Projects/CreateProject/CreateProjectForm'

const Ssl: FunctionComponent = () => {
  const { values, setFieldValue } =
    useFormikContext<CreateProjectFormikValueTypes>()
  const { t } = useTranslation()

  return (
    <div data-testid={`${values.type}-credentials-ssl`}>
      <Field
        title={t('fields.ssl.title')}
        name="ssl"
        inputComponent={Toggle}
        data-testid={`${values.type}-credentials-ssl-input-checkbox`}
        labelDataTestId={`${values.type}-credentials-ssl-input-label`}
        overlayText={true}
        value={values.ssl}
        checked={values.ssl}
        placeholder={t('fields.ssl.placeholderText')}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          setFieldValue('ssl', e.target.checked)
        }}
        className={classNames(classes.Form__SpacingStyles)}
        supportText={t('fields.ssl.supportText')}
      />
    </div>
  )
}

export default Ssl
