import { type FC } from 'react'
import { useTranslation } from 'react-i18next'

import { Alert, Typography } from '@matillion/component-library'

export const DestinationDisconnectedAlert: FC = () => {
  const { t } = useTranslation()

  return (
    <Alert
      type="warning"
      title={t(
        'createStreamingPipeline.fields.destinationConfig.snowflake.destinationDisconnectedAlert.title'
      )}
      data-testid="destination-disconnected-alert"
      message={
        <Typography format="bcs">
          {t(
            'createStreamingPipeline.fields.destinationConfig.snowflake.destinationDisconnectedAlert.body'
          )}
        </Typography>
      }
    />
  )
}
