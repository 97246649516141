import { type FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'

import { Field as FormikField, useFormikContext, type FieldProps } from 'formik'
import { isEmpty } from 'lodash'

import { AutoComplete, Field } from '@matillion/component-library'
import { type AutoCompleteItem } from '@matillion/component-library/dist/components/AutoComplete/types'

import { useFlags } from 'hooks/flags'

import {
  getDefaultDestinationFormValues,
  resolveErrorText,
  type FormValues
} from 'modules/Projects/CreateStreamingPipeline/FormContent'
import { FIELD_NAMES_COMPLETE_TARGET } from 'modules/Projects/CreateStreamingPipeline/FormContent/ConfigurePipelineDetailsPage/types'

import { STREAMING_DESTINATIONS, StreamingDestinationId } from 'types/Streaming'

import classes from './Fields.module.scss'
import {
  FIELD_NAMES_DESTINATION,
  type DestinationAutocompleteType
} from './types'

export const Destination: FunctionComponent = () => {
  const { enableStreamingStorageAsADestination } = useFlags()
  const { t } = useTranslation()
  const { setFieldValue } = useFormikContext<FormValues>()

  const dropdownOptions = Object.values(STREAMING_DESTINATIONS).filter(
    (destination) =>
      enableStreamingStorageAsADestination ||
      (destination.id !== StreamingDestinationId.S3 &&
        destination.id !== StreamingDestinationId.ABS)
  )

  return (
    <FormikField name={FIELD_NAMES_DESTINATION}>
      {({
        field,
        meta,
        form: { isSubmitting }
      }: FieldProps<DestinationAutocompleteType>) => (
        <div data-testid="configure-streaming-pipeline-destination">
          <Field
            {...field}
            data-testid="configure-streaming-pipeline-destination-input"
            title={t('createStreamingPipeline.fields.destination.title')}
            name={FIELD_NAMES_DESTINATION}
            inputComponent={AutoComplete}
            onChange={(newValue: { target: AutoCompleteItem }) => {
              if (newValue.target.value && !isEmpty(newValue.target.value.id)) {
                setFieldValue(
                  FIELD_NAMES_COMPLETE_TARGET,
                  getDefaultDestinationFormValues(
                    newValue.target.value.id as StreamingDestinationId
                  )
                )
              }
            }}
            availableItems={dropdownOptions}
            errorText={resolveErrorText(t, meta)}
            disabled={isSubmitting}
            iconBefore={{
              icon: (
                <span className={classes.Fields__FieldIcon}>
                  {field.value && (
                    <img
                      alt={field.value.name}
                      src={STREAMING_DESTINATIONS[field.value.id]?.icon}
                    />
                  )}
                </span>
              )
            }}
          />
        </div>
      )}
    </FormikField>
  )
}
