import { useTranslation } from 'react-i18next'

import { useFormikContext } from 'formik'

import { Typography } from '@matillion/component-library'

import ProviderCard from 'components/ProviderCard/ProviderCard'

import { useFlags } from 'hooks/flags'

import { useAvailableProviders } from 'modules/FormsContent/CloudProviderCredentials/Fields/hooks'
import classes from 'modules/FormsContent/Form.module.scss'
import { type FormValues } from 'modules/Projects/CloudProviderCredentials'

import { type CloudProviders } from 'types/CloudProviders'

import ProviderSelectionClasses from './ProviderSelection.module.scss'

const ProviderSelection = () => {
  const { values, setFieldValue } = useFormikContext<FormValues>()
  const availableProviders = useAvailableProviders()
  const { enableGcpCloudProviderCredentials } = useFlags()
  const { t } = useTranslation()

  const providers = Object.keys(availableProviders).sort((a, b) => {
    const keyA = a as keyof typeof availableProviders
    const keyB = b as keyof typeof availableProviders

    // sort by enabled state (true first)
    if (availableProviders[keyA].enabled > availableProviders[keyB].enabled) {
      return -1
    }
    if (availableProviders[keyA].enabled < availableProviders[keyB].enabled) {
      return 1
    }
    return 0
  })

  return (
    <div className={classes.Form__Field}>
      <div className={ProviderSelectionClasses.ProviderSelection__Container}>
        {providers.map((providerKey) => {
          const provider = providerKey as CloudProviders

          return (
            <div key={provider}>
              <ProviderCard
                logo={availableProviders[provider].providerLogo}
                providerName={availableProviders[provider].providerName}
                selected={values.provider === provider}
                handleClick={
                  availableProviders[provider].enabled
                    ? () => {
                        setFieldValue('provider', provider)
                      }
                    : undefined
                }
              />
            </div>
          )
        })}
      </div>
      {!enableGcpCloudProviderCredentials && (
        <div
          className={ProviderSelectionClasses.ProviderSelection__TextContainer}
        >
          <Typography format="bcs" data-testid="more-providers-text">
            {t('cloudProviderCredentials.fields.common.provider.supportText')}
          </Typography>
        </div>
      )}
    </div>
  )
}

export default ProviderSelection
