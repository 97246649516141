import { type FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import classNames from 'classnames'

import {
  Button,
  Icon,
  LoadingSpinner,
  Typography
} from '@matillion/component-library'

import { useCheckPermissions } from 'api/hooks'

import { AppRoutes } from 'constants/route'

import StartStopButton from 'modules/Projects/StreamingPipelineDashboard/Header/components/StartStopButton/StartStopButton'
import classes from 'modules/Projects/StreamingPipelineDashboard/Header/components/StreamingDashboardActions/StreamingDashboardActions.module.scss'

import { PermissionsType } from 'types'
import { ResourceType } from 'types/ResourceTypes'
import { isEditable, type StreamingPipelineStatus } from 'types/Streaming'

interface StreamingDashboardActionsProps {
  projectId: string
  pipelineId: string
  pipelineName: string
  status: StreamingPipelineStatus
}

const StreamingDashboardActions: FunctionComponent<
  StreamingDashboardActionsProps
> = ({ projectId, pipelineId, pipelineName, status }) => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  const { data: editPermission, isLoading: isEditPermissionLoading } =
    useCheckPermissions({
      resourceType: ResourceType.Project,
      resourceId: projectId,
      permission: PermissionsType.EditStreamingPipelines
    })
  const { data: runPermission, isLoading: isRunPermissionLoading } =
    useCheckPermissions({
      resourceType: ResourceType.Project,
      resourceId: projectId,
      permission: PermissionsType.RunStreamingPipelines
    })

  if (isEditPermissionLoading || isRunPermissionLoading) {
    return <LoadingSpinner data-testid="loading-spinner" />
  }

  return (
    <div className={classes.StreamingDashboardActions__Buttons}>
      {editPermission && (
        <Button
          onClick={() => {
            navigate(AppRoutes.getStreamingPipelineEdit(projectId, pipelineId))
          }}
          data-testid="pipeline-edit-button"
          className={classes.StreamingDashboardActions__EditButton}
          alt="secondary"
          disabled={!isEditable(status)}
        >
          <Typography
            format="bcs"
            className={classes.StreamingDashboardActions__EditText}
          >
            {t('streamingPipelineDashboard.actions.edit')}
          </Typography>
          <Icon.Cog
            className={classNames(
              classes.StreamingDashboardActions__Icon,
              classes.StreamingDashboardActions__EditIcon
            )}
          />
        </Button>
      )}
      {runPermission && (
        <StartStopButton
          projectId={projectId}
          pipelineId={pipelineId}
          pipelineName={pipelineName}
          status={status}
        />
      )}
    </div>
  )
}

export default StreamingDashboardActions
