import { format } from 'date-fns'
import { object, string } from 'yup'

import { defaultDateFormat, defaultTimeFormat } from 'constants/dateTimeFormats'

import { type ScheduleFormValues } from 'modules/Projects/CreateSchedule/types'

import { ScheduleDisplayMode, ScheduleMethod } from 'types'

import { isCronValid } from 'utils/cron'
import { nowPlus2Minutes } from 'utils/nowPlus2Minutes'

export const getInitialValues = (props?: {
  preloadedValues?: ScheduleFormValues
  enableStandardMode?: boolean
}): ScheduleFormValues => ({
  name: '',
  environment: {
    id: '',
    name: ''
  },
  pipeline: {
    id: '',
    name: ''
  },
  agent: {
    id: '',
    name: ''
  },
  schedule: {
    displayMode: props?.enableStandardMode
      ? ScheduleDisplayMode.BASIC
      : ScheduleDisplayMode.ADVANCED,
    timezone: {
      id: '',
      name: '',
      utc: '+00:00'
    },
    startDate: format(nowPlus2Minutes(), defaultDateFormat),
    startTime: format(nowPlus2Minutes(), defaultTimeFormat),
    cronExpression: '0 0 * ? * * *',
    repeatEvery: 1,
    unit: ScheduleMethod.DAY,
    repeatOn: [],
    hour: 8,
    minute: 0
  },
  ...props?.preloadedValues
})

export const validationSchema = object({
  name: string().required('createSchedule.fields.scheduleName.error.required'),
  environment: object({
    id: string().required('commonForm.fields.environment.error.required'),
    name: string().required('commonForm.fields.environment.error.required')
  })
    .required('commonForm.fields.environment.error.unmatched')
    .nullable(),
  pipeline: object({
    id: string().required('commonForm.fields.pipeline.error.required'),
    name: string().required('commonForm.fields.pipeline.error.required')
  })
    .required('commonForm.fields.pipeline.error.unmatched')
    .nullable(),
  agent: object({
    id: string().required('commonForm.fields.agent.error.required'),
    name: string().required('commonForm.fields.agent.error.required')
  })
    .required('commonForm.fields.agent.error.unmatched')
    .nullable(),
  schedule: object({
    timezone: object({
      id: string().required('createSchedule.fields.timezone.error.required'),
      name: string().required('createSchedule.fields.timezone.error.required')
    })
      .required('createSchedule.fields.timezone.error.unmatched')
      .nullable(),
    startDate: string().required(
      'createSchedule.fields.startDate.error.required'
    ),
    startTime: string().required(
      'createSchedule.fields.startTime.error.required'
    ),
    cronExpression: string()
      .test(
        'Cron Validation',
        'createSchedule.fields.cronExpression.error.unmatched',
        (value) => {
          return isCronValid(value!)
        }
      )
      .required('createSchedule.fields.cronExpression.error.required')
  })
})

export const generateCronFromInputs = (
  values: Pick<ScheduleFormValues, 'schedule'>
) => {
  const {
    schedule: { hour, minute, repeatEvery, repeatOn, unit }
  } = values

  if (unit === ScheduleMethod.DAY) {
    return `0 ${minute as number} ${hour as number} */${
      repeatEvery as number
    } * ?`
  }

  if (unit === ScheduleMethod.WEEK) {
    const daysOfTheWeek =
      repeatOn && repeatOn.length > 0
        ? repeatOn.map((day) => day.toLocaleUpperCase()).join(',')
        : '*'
    return `0 ${minute as number} ${hour as number} ? * ${daysOfTheWeek}`
  }

  if (unit === ScheduleMethod.HOUR) {
    return `0 ${minute as number} */${repeatEvery as number} ? * *`
  }

  if (unit === ScheduleMethod.MINUTE) {
    return `0 */${repeatEvery as number} * ? * *`
  }

  return ''
}
