import { type FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { Icon, LoadingSpinner } from '@matillion/component-library'

import { useCheckPermissions } from 'api/hooks'
import { type GETStreamingPipelineSummaryResponse } from 'api/types'

import EllipsesActionItem from 'components/EllipsesActionItem/EllipsesActionItem'

import { PermissionsType } from 'types'
import { ResourceType } from 'types/ResourceTypes'
import {
  isDeletable,
  isEditable,
  isStartable,
  isStoppable
} from 'types/Streaming'

type ActionCallback = (item: GETStreamingPipelineSummaryResponse) => void

export interface ActionCellProps {
  onStartClick: ActionCallback
  onStopClick: ActionCallback
  onEditClick: ActionCallback
  onDeleteClick: ActionCallback
  item: GETStreamingPipelineSummaryResponse
}

const ActionCell: FunctionComponent<ActionCellProps> = ({
  onStartClick,
  onStopClick,
  onEditClick,
  onDeleteClick,
  item
}) => {
  const { t } = useTranslation()
  const { projectId } = useParams()
  const { data: editPermission, isLoading: isEditPermissionLoading } =
    useCheckPermissions({
      resourceType: ResourceType.Project,
      resourceId: projectId!,
      permission: PermissionsType.EditStreamingPipelines
    })
  const { data: deletePermission, isLoading: isDeletePermissionLoading } =
    useCheckPermissions({
      resourceType: ResourceType.Project,
      resourceId: projectId!,
      permission: PermissionsType.DeleteStreamingPipelines
    })
  const { data: runPermission, isLoading: isRunPermissionLoading } =
    useCheckPermissions({
      resourceType: ResourceType.Project,
      resourceId: projectId!,
      permission: PermissionsType.RunStreamingPipelines
    })

  if (
    !projectId ||
    isEditPermissionLoading ||
    isDeletePermissionLoading ||
    isRunPermissionLoading
  ) {
    return <LoadingSpinner />
  }

  let showStart = false
  let showStop = false
  if (runPermission && isStartable(item.status)) {
    showStart = true
  } else if (runPermission && isStoppable(item.status)) {
    showStop = true
  }

  const actions = [
    ...(showStart
      ? [
          {
            title: t('streamingListing.actions.start'),
            action: () => {
              onStartClick(item)
            },
            icon: <Icon.Play />,
            dataTestId: 'ellipses-action-start-streaming-pipeline',
            closeMenuOnClick: true
          }
        ]
      : []),
    ...(showStop
      ? [
          {
            title: t('streamingListing.actions.stop'),
            action: () => {
              onStopClick(item)
            },
            icon: <Icon.Stop />,
            dataTestId: 'ellipses-action-stop-streaming-pipeline',
            closeMenuOnClick: true
          }
        ]
      : []),
    ...(editPermission
      ? [
          {
            title: t('streamingListing.actions.edit'),
            action: () => {
              onEditClick(item)
            },
            icon: <Icon.Pencil />,
            dataTestId: 'ellipses-action-edit-streaming-pipeline',
            disabled: !isEditable(item.status)
          }
        ]
      : []),
    ...(deletePermission
      ? [
          {
            title: t('streamingListing.actions.delete'),
            action: () => {
              onDeleteClick(item)
            },
            icon: <Icon.Trash />,
            dataTestId: 'ellipses-action-delete-streaming-pipeline',
            disabled: !isDeletable(item.status)
          }
        ]
      : [])
  ]

  return <EllipsesActionItem actions={actions} data-testid="action-item" />
}

export default ActionCell
