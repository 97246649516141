import { useTranslation } from 'react-i18next'

import { Toaster } from '@matillion/component-library'

import { useFlags } from 'hooks/flags'

import { checkIfDateTimeInThePast } from 'utils/checkIfDateTimeInThePast/checkIfDateTimeInThePast'
import { nowPlus2Minutes } from 'utils/nowPlus2Minutes'

interface CheckScheduleProps {
  startDate: string
  startTime: string
  offset?: string
}

export const useCheckScheduleSubmitDateTime = () => {
  const { enableStartTimeInputScheduleOptions } = useFlags()
  const { makeToast } = Toaster.useToaster()
  const { t } = useTranslation()

  const isScheduleDateTimeStale = ({
    startDate,
    startTime,
    offset
  }: CheckScheduleProps) => {
    if (enableStartTimeInputScheduleOptions) {
      const dateIsInPast = checkIfDateTimeInThePast({
        customDate: startDate,
        customTime: startTime,
        dateToCompare: nowPlus2Minutes(),
        offset
      })
      if (dateIsInPast) {
        makeToast({
          title: t('createSchedule.error.pastDateError.title'),
          message: t('createSchedule.error.pastDateError.message'),
          type: 'warning'
        })
        return true
      }
    }
    return false
  }

  return [isScheduleDateTimeStale]
}
