import { useUser } from '@matillion/hub-client'

import { useClient } from 'api/hooks/useClient'

import config from 'config'

export interface UseStreamingClientProps {
  showErrorDetailMessage?: boolean
}

export const useStreamingClient = (params?: UseStreamingClientProps) => {
  const { organisation } = useUser()

  const baseURL = config.streaming.apiUrl.replace(
    '{{region}}',
    organisation.region!
  )
  return useClient({
    tokenOptions: {
      audience: config.streaming.apiAudience
    },
    axiosConfig: {
      baseURL
    },
    showErrorDetailMessage: params?.showErrorDetailMessage
  })
}
