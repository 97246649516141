import { useMutation } from 'react-query'

import { type AxiosError } from 'axios'

import { fetchStreamingSourceTables } from 'api/clients'
import { useNetworkErrorToast, useStreamingClient } from 'api/hooks/index'
import {
  type ErrorResponse,
  type FetchStreamingSourceTablesResponse
} from 'api/types'

import { QueryKey } from 'constants/endpoint'

import {
  type SourceConnectionType,
  type StreamingSourceId
} from 'types/Streaming'

type TError = AxiosError<ErrorResponse>
interface TVariables {
  agentId: string
  type: StreamingSourceId
  connection: SourceConnectionType
  schema: string
}

export const useFetchStreamingSourceTables = () => {
  const streamingClient = useStreamingClient()
  const makeErrorToast = useNetworkErrorToast()

  return useMutation<FetchStreamingSourceTablesResponse, TError, TVariables>({
    mutationKey: [QueryKey.STREAMING_LIST_SOURCE_TABLES],
    mutationFn: async ({ agentId, type, connection, schema }) => {
      return fetchStreamingSourceTables(
        { agentId, connection, type, schema },
        streamingClient
      )
    },
    onError: (error) => {
      makeErrorToast({
        message: error.response?.data.detail ?? error.message
      })
    }
  })
}
