import { type GETDatabricksComputeResourceResponseParams } from 'api/createProjectForm/types/DatabricksComputeResourcesResponse'
import { type POSTProjectRequest } from 'api/types'

import { AgentsSecretsHost, type JSONValue, type Warehouse } from 'types'
import { type AutoCompleteProps } from 'types/FormTypes'

import { getCredentials } from './createWarehouseDefaultsMutation'

export interface CreateProjectFormValuesTypes {
  projectName: string
  description?: string
  type: Warehouse | ''
  agentsSecretsManagement: AgentsSecretsHost
  provider: string
  repository?: string
  providerParameters?: JSONValue
  environmentName: string
  etlAgent: AutoCompleteProps
  environmentDefaultAccess: AutoCompleteProps
  matillionHostedAgentId: string
  account: string
  port?: number | null
  ssl?: boolean | null
  username: string
  secretLocationId: string
  secretReferenceId: string
  defaultRole: AutoCompleteProps
  defaultDatabase: AutoCompleteProps
  defaultWarehouse: AutoCompleteProps
  defaultSchema: AutoCompleteProps
  defaultStorageLocation: AutoCompleteProps
  awsSecretReferenceId?: string
  compute: GETDatabricksComputeResourceResponseParams & AutoCompleteProps
  catalog: AutoCompleteProps
  credentialsType: AutoCompleteProps
  passphrase: string
}

export const createProjectMutationData = (
  formValues: CreateProjectFormValuesTypes,
  defaultSecretLocation?: string,
  keyPairEnabled = false
): POSTProjectRequest => {
  const authentication = keyPairEnabled
    ? {
        type: formValues.credentialsType.id,
        ...getCredentials(formValues)
      }
    : undefined

  return {
    project: {
      name: formValues.projectName.trim(),
      description: formValues.description?.trim() || '',
      warehouse: formValues.type,
      agentAndSecretHostLocation: formValues.agentsSecretsManagement,
      gitProvider: formValues.provider,
      repositoryName: formValues.repository || null,
      providerParameters: formValues.providerParameters || null
    },
    environment: {
      defaultRole: formValues.environmentDefaultAccess.id,
      name: `${formValues.projectName.trim()}-${formValues.environmentName.trim()}`,
      agent:
        formValues.agentsSecretsManagement === AgentsSecretsHost.MatillionHosted
          ? formValues.matillionHostedAgentId
          : formValues.etlAgent.id,
      warehouseConnection: {
        type: formValues.type,
        url: formValues.account.trim(),
        username: formValues.username.trim(),
        authentication,
        secretId: formValues.secretReferenceId,
        defaultRole: formValues.defaultRole.id,
        defaultDatabase: formValues.defaultDatabase.id,
        defaultWarehouse: formValues.defaultWarehouse.id,
        defaultSchema: formValues.defaultSchema.id,
        ...(formValues.defaultStorageLocation?.id && {
          defaultStorageLocation: formValues.defaultStorageLocation.id
        }),
        clusterId: formValues.compute.clusterId,
        clusterName: formValues.compute.clusterName,
        clusterType: formValues.compute.clusterType,
        defaultCatalog: formValues.catalog?.id,
        port: formValues.port,
        ssl: formValues.ssl
      }
    },
    secretLocation: {
      id: defaultSecretLocation ?? formValues.secretLocationId
    },
    ...(formValues.awsSecretReferenceId && {
      cloudCredentialsMapping: {
        awsCredentialsSecret: formValues.awsSecretReferenceId
      }
    })
  }
}
