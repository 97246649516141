import { type ChangeEvent, type FC } from 'react'

import { useFormikContext } from 'formik'

import { Checkbox, Field } from '@matillion/component-library'

import { type FormValues } from 'modules/Projects/CreateStreamingPipeline/FormContent/index'

import { type SourceTable } from 'types/Streaming'

export interface TableSelectorCheckboxProps {
  schema: string
  tableName: string
  selectedTables: SourceTable[]
  selectedTablesInSchema: SourceTable[]
}

export const TableSelectorCheckbox: FC<TableSelectorCheckboxProps> = ({
  schema,
  tableName,
  selectedTables,
  selectedTablesInSchema
}) => {
  const { setFieldValue } = useFormikContext<FormValues>()

  return (
    <Field>
      <Checkbox
        checked={selectedTablesInSchema.some(
          (sourceTable) => sourceTable.table === tableName
        )}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          setFieldValue(
            'source.tables',
            e.target.checked
              ? [...selectedTables, { schema, table: tableName }]
              : selectedTables.filter(
                  (sourceTable) =>
                    sourceTable.schema !== schema ||
                    sourceTable.table !== tableName
                )
          )
        }}
        data-testid={`table-selector-checkbox-${tableName}`}
      />
    </Field>
  )
}
