import { useMemo, type FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'

import { useFormikContext } from 'formik'

import { Field, Textarea } from '@matillion/component-library'

import { useResetFieldOnProviderChange } from 'modules/FormsContent/CloudProviderCredentials/Fields/hooks'
import classes from 'modules/FormsContent/Form.module.scss'
import { type FormValues } from 'modules/Projects/CloudProviderCredentials'

const ServiceAccount: FunctionComponent = () => {
  const {
    errors,
    handleBlur,
    handleChange,
    setFieldTouched,
    setFieldValue,
    touched,
    values
  } = useFormikContext<FormValues>()
  const { t } = useTranslation()
  useResetFieldOnProviderChange('serviceAccount', {
    setFieldTouched,
    setFieldValue
  })

  const getErrorMessage = useMemo(() => {
    if (touched?.serviceAccount && errors.serviceAccount) {
      return t(errors.serviceAccount)
    }

    return null
  }, [errors?.serviceAccount, t, touched?.serviceAccount])

  return (
    <div data-testid="cloud-provider-credentials-gcp-service-account">
      <Field
        inputComponent={Textarea}
        title={t('cloudProviderCredentials.fields.gcp.serviceAccount.title')}
        name="serviceAccount"
        data-testid="cloud-provider-credentials-gcp-service-account-input"
        value={values.serviceAccount}
        placeholder={t(
          'cloudProviderCredentials.fields.gcp.serviceAccount.placeholderText'
        )}
        supportText={t(
          'cloudProviderCredentials.fields.gcp.serviceAccount.supportText'
        )}
        onChange={handleChange}
        onBlur={handleBlur}
        className={classes.Form__Field}
        errorText={getErrorMessage}
        hasError={!!errors?.serviceAccount && touched.serviceAccount}
        required
      />
    </div>
  )
}

export default ServiceAccount
