import {
  useEffect,
  useState,
  type ChangeEvent,
  type FocusEvent,
  type FunctionComponent
} from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import classNames from 'classnames'
import { useFormikContext } from 'formik'

import { Field } from '@matillion/component-library'
import { useLDClient } from '@matillion/hub-client'

import classes from 'components/Form/Form.module.scss'

import { AppRoutes, PROJECTS_CREDENTIALS } from 'constants/route'

import { useOnboardingContext } from 'context'

import { type CreateProjectFormikValueTypes } from 'modules/Projects/CreateProject/CreateProjectForm'

import { Warehouse } from 'types'

interface AccountProps {
  disableOnBlur?: boolean
  translationProxy?: string
}

export const Account: FunctionComponent<AccountProps> = ({
  disableOnBlur = false,
  translationProxy = 'account'
}) => {
  const location = useLocation()
  const { errors, handleBlur, validateField, touched, values, setFieldValue } =
    useFormikContext<CreateProjectFormikValueTypes>()
  const { t } = useTranslation()
  const { projectType } = useOnboardingContext()
  const isOnboardingSnowflakeCredentialsScreen =
    location.pathname.includes(
      `${AppRoutes.getOnboardingAdd()}/${PROJECTS_CREDENTIALS}`
    ) && projectType === Warehouse.Snowflake
  const { track: sendLDMetric } = useLDClient()

  const [currentValue, setCurrentValue] = useState(values.account)

  const handleFieldChange = (e: ChangeEvent<HTMLInputElement>) => {
    setCurrentValue(e.target.value)
    if (isOnboardingSnowflakeCredentialsScreen) {
      sendLDMetric('snowflake-credentials-form-q-1-account-url-changed')
    }
  }

  const handleFieldBlur = async (e: FocusEvent<HTMLInputElement>) => {
    handleBlur(e)
    await setFieldValue('account', currentValue.trim())
    setCurrentValue(currentValue.trim())
    await validateField('account')
  }

  useEffect(() => {
    setCurrentValue(values.account)
  }, [values.account])

  return (
    <div data-testid={`${values.type}-credentials-account`}>
      <Field
        title={t(`fields.${translationProxy}.title`)}
        name="account"
        data-testid={`${values.type}-credentials-account-input`}
        data-tracker-id={`${values.type}-credentials-account-input`}
        value={currentValue}
        placeholder={t(`fields.${translationProxy}.placeholderText`)}
        onChange={handleFieldChange}
        onBlur={handleFieldBlur}
        className={classNames(classes.Form__SpacingStyles)}
        supportText={t(`fields.${translationProxy}.supportText`)}
        errorText={
          errors?.account && touched.account ? t(errors.account) : null
        }
        hasError={Boolean(errors?.account) && Boolean(touched.account)}
        required
      />
    </div>
  )
}
