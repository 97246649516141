import { type PropsWithChildren } from 'react'
import { QueryClientProvider } from 'react-query'

import useProjectExplorerQueryClient from './useProjectExplorerQueryClient'

const ProjectExplorerQueryClientProvider = ({
  children
}: PropsWithChildren) => {
  const client = useProjectExplorerQueryClient()

  return <QueryClientProvider client={client}>{children}</QueryClientProvider>
}

export default ProjectExplorerQueryClientProvider
