import { useCallback, useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import classNames from 'classnames'
import { Form, useFormikContext, type FormikFormProps } from 'formik'

import {
  Button,
  Icon,
  MatillionLink,
  Tooltip,
  Typography
} from '@matillion/component-library'

import classes from 'components/Form/Form.module.scss'
import { type ConfigureFormProps } from 'components/Form/types'

import { AppRoutes, PROJECTS_CREDENTIALS } from 'constants/route'

import { useOnboardingContext } from 'context'

import { Warehouse } from 'types'

export const FORM_DEBOUNCE_DELAY = 300

const ConfigureForm = <T,>(props: FormikFormProps & ConfigureFormProps<T>) => {
  const {
    isLastStep = true,
    validationSchema,
    translationPrefix,
    persistingStorageId,
    persistenceActions,
    persistenceExclusions = [],
    children,
    formValues,
    removeSubmitButtonOnRoutes = [],
    onHoverButtonEnabledText,
    onHoverButtonDisabledText,
    onCancel,
    buttonsClassName
  } = props
  const { handleSubmit, isSubmitting, values, setValues } =
    useFormikContext<T>()

  const { t } = useTranslation()
  const [initialUpdate, setInitialUpdate] = useState(false)
  const [hideButton, setHideButton] = useState(false)
  const { pathname } = useLocation()
  const buttonDisabled = !validationSchema?.isValidSync(values)

  const { projectType } = useOnboardingContext()

  const deletedExclusionFromPersistance = useCallback(
    (vals: T) => {
      const clonedValues = { ...vals }
      persistenceExclusions.forEach(
        // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
        (exclusion) => delete clonedValues[exclusion as keyof T]
      )

      return clonedValues
    },
    [persistenceExclusions]
  )

  // Initialise persistance storage item onMount using initial values
  useEffect(() => {
    if (persistingStorageId) {
      const savedForm = window.sessionStorage.getItem(persistingStorageId)
      if (savedForm) {
        const parsedValues = JSON.parse(savedForm) as T
        setValues(deletedExclusionFromPersistance(parsedValues))
      } else {
        window.sessionStorage.setItem(
          persistingStorageId,
          JSON.stringify(deletedExclusionFromPersistance(values))
        )
      }
      setInitialUpdate(true)
    }

    // Removing session storage on unmount
    return () => {
      window.sessionStorage.removeItem(persistingStorageId ?? '')
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Once initialised allow to update the persistance layer
  useEffect(() => {
    let persist: NodeJS.Timeout
    if (persistingStorageId && initialUpdate) {
      persist = setTimeout(() => {
        const valuesWithExclusions = deletedExclusionFromPersistance(values)
        window.sessionStorage.setItem(
          persistingStorageId,
          JSON.stringify(valuesWithExclusions)
        )
        if (persistenceActions) {
          persistenceActions(valuesWithExclusions)
        }
      }, FORM_DEBOUNCE_DELAY)
    }

    return () => {
      clearTimeout(persist)
    }
  }, [
    deletedExclusionFromPersistance,
    initialUpdate,
    persistenceActions,
    persistingStorageId,
    values
  ])

  // Preventing the form's Continue / Submit button from appearing on page
  useEffect(() => {
    setHideButton(false)
    for (const route of removeSubmitButtonOnRoutes) {
      if (pathname.includes(route)) {
        setHideButton(true)
      }
    }
  }, [removeSubmitButtonOnRoutes, pathname])

  const btnText = isLastStep
    ? t(`${translationPrefix}.endButtonText`)
    : t(`${translationPrefix}.nextButtonText`)

  const isOnboardingSnowflakeCredentialsScreen =
    pathname.includes(
      `${AppRoutes.getOnboardingAdd()}/${PROJECTS_CREDENTIALS}`
    ) && projectType === Warehouse.Snowflake

  const getButtonWithOptionalTooltip = (withTooltip: boolean) => {
    const button = (
      <Button
        type="submit"
        className={classes.Form__Button}
        size="lg"
        waiting={isSubmitting}
        disabled={buttonDisabled}
        data-testid="continue-button"
        text={btnText}
        alt={isLastStep ? 'positive' : 'primary'}
      />
    )
    if (withTooltip) {
      return (
        <Tooltip
          content={
            buttonDisabled
              ? onHoverButtonDisabledText
              : onHoverButtonEnabledText
          }
        >
          {button}
        </Tooltip>
      )
    } else return button
  }

  const getTrustText = () => {
    return (
      <div className={classes.Trust}>
        <div className={classes.Trust__Container}>
          <Icon.Lock className={classes.Trust__Icon} />
          <Typography format="bcs">
            <Trans
              i18nKey={`${translationPrefix}.trustText`}
              components={{
                Link: (
                  <MatillionLink
                    className={classes.Trust__Link}
                    as="a"
                    href="https://www.matillion.com/trust-center"
                    target="_blank"
                    rel="noreferrer"
                    data-tracker-id="credentials-screen-trust-center-link"
                  >
                    {t(`${translationPrefix}.trustLinkText`)}
                  </MatillionLink>
                )
              }}
            />
          </Typography>
        </div>
      </div>
    )
  }

  return (
    <Form
      onSubmit={handleSubmit}
      className={classes.Form__Container}
      data-testid="configure-form"
      aria-label={t('form.label')}
      {...formValues}
    >
      <>
        {children}
        {isOnboardingSnowflakeCredentialsScreen && getTrustText()}
        <div
          className={classNames(classes.Form__Buttons, buttonsClassName, {
            [classes['Form__Buttons--no-margin']]:
              isOnboardingSnowflakeCredentialsScreen
          })}
        >
          {onCancel && (
            <Button
              className={classes.Form__Button}
              size="lg"
              onClick={onCancel}
              alt="secondary"
              text={t(`${translationPrefix}.cancelButtonText`)}
              data-testid="cancel-button"
              data-tracker-id="cancel-button"
            />
          )}
          {!hideButton &&
            getButtonWithOptionalTooltip(
              (buttonDisabled && !!onHoverButtonDisabledText) ||
                (!buttonDisabled && !!onHoverButtonEnabledText)
            )}
        </div>
      </>
    </Form>
  )
}

export default ConfigureForm
