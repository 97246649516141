import { useMutation } from 'react-query'

import { type AxiosError } from 'axios'

import { postProject } from 'api/createProjectForm/client'
import type { POSTProjectRequest, POSTProjectResponse } from 'api/types'

import { QueryKey } from 'constants/endpoint'

import { useClient } from './useClient'

type TData = POSTProjectResponse

export type TError = AxiosError<{
  detail?: string
}>
interface TVariables {
  values: POSTProjectRequest
}

export const usePostProject = () => {
  const client = useClient()

  return useMutation<TData, TError, TVariables>(
    QueryKey.CREATE_PROJECT,
    async (data: { values: POSTProjectRequest }) => {
      const { values } = data
      return postProject(values, client)
    }
  )
}
