import { useQuery, type UseQueryOptions } from 'react-query'

import { type AxiosError } from 'axios'

import { getEnvironmentAgents } from 'api/clients'
import { useClient } from 'api/hooks'
import {
  type GETEnvironmentAgentsRequest,
  type GETEnvironmentAgentsResponse
} from 'api/types'

import { QueryKey } from 'constants/endpoint'

type TData = GETEnvironmentAgentsResponse
type TError = AxiosError<unknown>
type Options = Omit<
  UseQueryOptions<TData, TError, TData>,
  'queryKey' | 'queryFn'
>

export const useGetEnvironmentAgents = (
  options?: Options,
  params?: GETEnvironmentAgentsRequest
) => {
  const client = useClient()

  return useQuery<TData, TError>(
    [QueryKey.ENVIRONMENTS_AGENTS],
    async () => getEnvironmentAgents(client, params),
    options
  )
}
