import { useCallback, useState } from 'react'

import { type AxiosError } from 'axios'
import { useFormikContext } from 'formik'
import { t } from 'i18next'

import { Toaster } from '@matillion/component-library'

import { useGetWarehouseProvisioning } from 'api/hooks/useGetWarehouseProvisioning'
import { type ErrorResponse } from 'api/types'
import { WarehouseStatus } from 'api/types/WarehouseStatus'

import { usePolling } from 'hooks/polling/usePolling'

import { type OnboardingFormikValueTypes } from 'modules/Onboarding/OnboardingForm'

export const useWarehouseProvisioningWithPolling = (
  onRedirectPrevious: () => void,
  enabled = false
) => {
  const { makeToast, clearToasts } = Toaster.useToaster()
  const { refetch } = useGetWarehouseProvisioning({
    enabled: false
  })
  const { setValues, values } = useFormikContext<OnboardingFormikValueTypes>()
  const [isComplete, setIsComplete] = useState(false)

  /* istanbul ignore next */
  const handleErrorTimeout = () => {
    makeToast({
      title: t('onboarding.mhaAndWarehouseProvisioningError.title'),
      message: t('onboarding.mhaAndWarehouseProvisioningError.message'),
      type: 'error',
      action: {
        text: t('onboarding.mhaAndWarehouseProvisioningError.support'),
        onClick: () => {
          window.open(
            t('onboarding.mhaAndWarehouseProvisioningError.supportLink'),
            '_blank'
          )
        }
      }
    })
    onRedirectPrevious()
  }

  const handlePolling = useCallback(
    async (suspendInterval: () => void = () => null) => {
      clearToasts()
      if (!enabled) return
      await refetch()
        .then((r) => {
          const { data, error, isError } = r

          if (isError && error?.response?.status !== 404) {
            throw error
          }

          if (!data) return
          if (data.status === WarehouseStatus.ACTIVE) {
            suspendInterval()
            setValues({
              ...values,
              matillionHostedAgentId: data.agentId,
              defaultDatabase: {
                id: data.defaultDatabase,
                name: data.defaultDatabase
              },
              defaultRole: {
                id: data.defaultRole,
                name: data.defaultRole
              },
              defaultSchema: {
                id: data.defaultSchema,
                name: data.defaultSchema
              },
              defaultWarehouse: {
                id: data.defaultWarehouse,
                name: data.defaultWarehouse
              },
              secretReferenceId: data.secretId,
              username: data.username,
              account: data.url
            })
            setIsComplete(true)
          } else if (data.status === WarehouseStatus.REJECTED) {
            suspendInterval()

            // Display error and redirect to previous page
            onRedirectPrevious()
            makeToast({
              title: t('formContent.warehouse.responseMessage.error'),
              message: '',
              type: 'error'
            })
          }
        })
        .catch((error) => {
          clearToasts()
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const err = error as AxiosError<any>
          const errorDetail = (err?.response?.data as ErrorResponse)?.detail
          suspendInterval()

          // Display error and redirect to previous page
          onRedirectPrevious()
          makeToast({
            title: t('error.unexpected.title'),
            message: errorDetail ?? t('error.unexpected.message'),
            type: 'error'
          })
        })
    },
    [
      clearToasts,
      enabled,
      makeToast,
      onRedirectPrevious,
      refetch,
      setValues,
      values
    ]
  )

  usePolling(handleErrorTimeout, handlePolling, isComplete, undefined, enabled)

  return [isComplete]
}
