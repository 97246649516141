import { type FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'

import { Field as FormikField, useFormikContext, type FieldProps } from 'formik'
import { get, isEmpty } from 'lodash'

import { Field } from '@matillion/component-library'

import {
  resolveErrorText,
  type FormValues
} from 'modules/Projects/CreateStreamingPipeline/FormContent'
import {
  FIELD_NAMES_SOURCE_HOST,
  FIELD_NAMES_SOURCE_PORT
} from 'modules/Projects/CreateStreamingPipeline/FormContent/ConfigurePipelineDetailsPage/types'

import classes from './HostAndPort.module.scss'

export const HostAndPort: FunctionComponent = () => {
  const { t } = useTranslation()
  const { setFieldValue, values } = useFormikContext<FormValues>()

  return (
    <div className={classes.HostAndPort__Wrapper}>
      <FormikField name={FIELD_NAMES_SOURCE_HOST}>
        {({ field, meta, form: { isSubmitting } }: FieldProps<string>) => (
          <div
            data-testid="configure-streaming-pipeline-source-host"
            className={classes.HostAndPort__Host}
          >
            <Field
              {...field}
              title={t(
                'createStreamingPipeline.fields.sourceConfig.host.title'
              )}
              supportText={t(
                'createStreamingPipeline.fields.sourceConfig.host.supportText'
              )}
              name={FIELD_NAMES_SOURCE_HOST}
              data-testid="configure-streaming-pipeline-source-host-input"
              hasError={
                isEmpty(get(values, FIELD_NAMES_SOURCE_HOST)) || !!meta.error
              }
              errorText={resolveErrorText(t, meta)}
              disabled={isSubmitting}
            />
          </div>
        )}
      </FormikField>
      <FormikField name={FIELD_NAMES_SOURCE_PORT}>
        {({ field, meta, form: { isSubmitting } }: FieldProps<string>) => (
          <div
            data-testid="configure-streaming-pipeline-source-port"
            className={classes.HostAndPort__Port}
          >
            <Field
              {...field}
              title={t(
                'createStreamingPipeline.fields.sourceConfig.port.title'
              )}
              name={FIELD_NAMES_SOURCE_PORT}
              data-testid="configure-streaming-pipeline-source-port-input"
              errorText={resolveErrorText(t, meta)}
              disabled={isSubmitting}
              value={values.source.connection.port}
              onChange={(e: { target: { value: string } }) => {
                const value = e.target.value
                if (
                  value === '' ||
                  (Number(value) > 0 && !value.startsWith('-'))
                ) {
                  setFieldValue(FIELD_NAMES_SOURCE_PORT, Number(value))
                }
              }}
            />
          </div>
        )}
      </FormikField>
    </div>
  )
}
