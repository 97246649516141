import { useState, type FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'

import { Button, Icon, Typography } from '@matillion/component-library'

import { type StreamingPipelineResponse } from 'api/types'

import PropertyTable, {
  type PropertySet
} from 'modules/Projects/StreamingPipelineDashboard/Summary/components/PropertyTable/PropertyTable'
import StreamingAgentSummary from 'modules/Projects/StreamingPipelineDashboard/Summary/components/StreamingAgentSummary/StreamingAgentSummary'
import StreamingConfigurationSummary from 'modules/Projects/StreamingPipelineDashboard/Summary/components/StreamingConfigurationSummary/StreamingConfigurationSummary'
import classes from 'modules/Projects/StreamingPipelineDashboard/Summary/components/StreamingDashboardSummary/StreamingDashboardSummary.module.scss'
import { removeFirstClassAdvancedProperties } from 'modules/Projects/StreamingPipelineDashboard/Summary/components/StreamingDashboardSummary/StreamingDashboardSummary.util'
import StreamingDestinationSummary from 'modules/Projects/StreamingPipelineDashboard/Summary/components/StreamingDestinationSummary/StreamingDestinationSummary'
import StreamingSourceSummary from 'modules/Projects/StreamingPipelineDashboard/Summary/components/StreamingSourceSummary/StreamingSourceSummary'

import {
  isSnowflakeDestination,
  type StreamingPipelineStatus
} from 'types/Streaming'

interface StreamingDashboardSummaryProps {
  pipeline: StreamingPipelineResponse
  pipelineStatus: StreamingPipelineStatus
}

const StreamingDashboardSummary: FunctionComponent<
  StreamingDashboardSummaryProps
> = ({ pipeline, pipelineStatus }) => {
  const { t } = useTranslation()

  const [isExpanded, setIsExpanded] = useState<boolean>(false)

  const jdbcProperties: PropertySet[] = [
    {
      properties: pipeline.source.connection?.jdbcProperties,
      subject: pipeline.source.type
    },
    ...(isSnowflakeDestination(pipeline.target)
      ? [
          {
            properties: pipeline.target.connection?.jdbcProperties,
            subject: pipeline.target.type
          }
        ]
      : [])
  ]

  return (
    <div
      className={classes.StreamingDashboardSummary__Container}
      data-testid="streaming-pipeline-summary"
    >
      <div className={classes.StreamingDashboardSummary__Header}>
        <Typography data-testid="streaming-pipeline-summary-title" format="ts">
          {t('streamingPipelineDashboard.summary.title')}
        </Typography>
        <Button
          alt="text"
          data-testid="expander-button"
          onClick={() => {
            setIsExpanded((prevState) => !prevState)
          }}
        >
          <Typography
            format="bcs"
            className={classes.StreamingDashboardSummary__ExpanderText}
          >
            {isExpanded
              ? t('streamingPipelineDashboard.summary.less')
              : t('streamingPipelineDashboard.summary.more')}
          </Typography>
          <Icon.ChevronDown
            className={
              isExpanded
                ? classes.StreamingDashboardSummary__FlipChevron
                : undefined
            }
          />
        </Button>
      </div>

      <div className={classes.StreamingDashboardSummary__Grid}>
        <StreamingSourceSummary pipeline={pipeline} isExpanded={isExpanded} />
        <StreamingDestinationSummary
          destination={pipeline.target}
          isExpanded={isExpanded}
        />
        <StreamingAgentSummary
          agentId={pipeline.agentId}
          hidden={!isExpanded}
          pipelineStatus={pipelineStatus}
        />
        <StreamingConfigurationSummary
          pipeline={pipeline}
          hidden={!isExpanded}
        />
        <PropertyTable
          title={t('streamingPipelineDashboard.summary.fields.properties.jdbc')}
          propertySets={jdbcProperties}
          dataTestId="summary-jdbc"
          hidden={!isExpanded}
        />
        <PropertyTable
          title={t(
            'streamingPipelineDashboard.summary.fields.properties.advanced'
          )}
          propertySets={[
            {
              properties: removeFirstClassAdvancedProperties(
                pipeline.source.type,
                pipeline.engine?.advanced
              )
            }
          ]}
          dataTestId="summary-advanced"
          hidden={!isExpanded}
          className={classes.StreamingDashboardSummary__Advanced}
        />
      </div>
    </div>
  )
}

export default StreamingDashboardSummary
