import { useEffect, type FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import classNames from 'classnames'
import { useFormikContext } from 'formik'

import { useGetSecretReferences } from 'api/hooks'

import { Header, HeaderTitle } from 'components/Header'

import { useFlags } from 'hooks/flags'

import { Agent, VaultName } from 'modules/Common/Fields'
import {
  AWSAccessKeyID,
  AWSSecretKey
} from 'modules/FormsContent/CloudProviderCredentials/Fields/AWS'
import {
  AzureClientID,
  AzureSecretKey,
  AzureTenantID
} from 'modules/FormsContent/CloudProviderCredentials/Fields/Azure'
import {
  CredentialName,
  ProviderSelection
} from 'modules/FormsContent/CloudProviderCredentials/Fields/Common'
import { GCPServiceAccount } from 'modules/FormsContent/CloudProviderCredentials/Fields/GCP'
import classes from 'modules/FormsContent/Form.module.scss'
import {
  dynamicProvidersSchema,
  useCloudProviderCredentialsContext,
  type FormValues
} from 'modules/Projects/CloudProviderCredentials'

import { SecretReferenceTypes } from 'types'
import { AgentCloudProvider } from 'types/AgentCloudProvider'
import { CloudProviders } from 'types/CloudProviders'

export interface CloudProviderCredentialsProps {
  onCancel?: () => void
  showCancel?: boolean
  isCHA?: boolean
}

const CloudProviderCredentials: FunctionComponent<
  CloudProviderCredentialsProps
> = ({ onCancel, showCancel, isCHA }) => {
  const { values } = useFormikContext<FormValues>()
  const { t } = useTranslation()
  const { setValidationSchema } = useCloudProviderCredentialsContext()
  const { enableGcpCloudProviderCredentials } = useFlags()
  const { enableAzureMultiKeyVault } = useFlags()

  const { projectId } = useParams()
  const { data: listOfSecretReferences = [] } = useGetSecretReferences(
    projectId!,
    [
      SecretReferenceTypes.OAUTH_USER_TOKENS,
      SecretReferenceTypes.OAUTH_AUTHORIZATION_CODE,
      SecretReferenceTypes.PASSWORD,
      SecretReferenceTypes.CLOUD_PLATFORM_CREDENTIALS
    ],
    { retryOnMount: false }
  )

  useEffect(() => {
    const providerSchema = dynamicProvidersSchema(listOfSecretReferences)[
      values.provider as keyof typeof dynamicProvidersSchema
    ]
    setValidationSchema(providerSchema)
  }, [setValidationSchema, values.provider, listOfSecretReferences])

  return (
    <>
      <Header onCancel={onCancel} showCancel={showCancel}>
        <HeaderTitle data-testid="cloud-provider-credentials-title">
          {t('cloudProviderCredentials.title')}
        </HeaderTitle>
      </Header>
      <div className={classNames(classes.Form)}>
        <CredentialName />
        <ProviderSelection />
        {isCHA && <Agent />}
        {enableAzureMultiKeyVault &&
        values?.agent?.agentCloudProvider === AgentCloudProvider.AZURE ? (
          <VaultName
            projectId={projectId ?? ''}
            agentId={values.agent.id}
            className={classNames(classes.Form__Field)}
            testIdPrefix={'cloud-provider-credentials'}
          />
        ) : null}
        {values.provider === CloudProviders.AWS && (
          <>
            <AWSAccessKeyID />
            <AWSSecretKey />
          </>
        )}
        {values.provider === CloudProviders.AZURE && (
          <>
            <AzureTenantID />
            <AzureClientID />
            <AzureSecretKey />
          </>
        )}
        {!!enableGcpCloudProviderCredentials &&
          values.provider === CloudProviders.GCP && <GCPServiceAccount />}
      </div>
    </>
  )
}

export default CloudProviderCredentials
