import { type FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'

import classnames from 'classnames'

import { Button, Modal, Typography } from '@matillion/component-library'

import { StreamingFeatures } from 'modules/Projects/StreamingListing/components/StreamingFeatures/StreamingFeatures'
import { getContactSalesUrl } from 'modules/utils'

import classes from './NoEntitlementModal.module.scss'

interface NoEntitlementModalProps {
  onClose: VoidFunction
  organisationSubdomain: string
}

export const NoEntitlementModal: FunctionComponent<NoEntitlementModalProps> = ({
  onClose,
  organisationSubdomain
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'streamingListing.modal'
  })

  return (
    <Modal
      size="mid-large"
      data-testid="list-streaming-learn-more-modal"
      onCancel={onClose}
      ariaLabelledBy="modal-title"
      className={classes.NoEntitlementModal__Modal}
    >
      <Typography
        format="dts"
        weight="bold"
        className={classnames(
          classes.NoEntitlementModal__Title,
          classes['NoEntitlementModal__Title--green']
        )}
      >
        {t('noEntitlement.title1')}
      </Typography>
      <Typography
        format="dts"
        weight="bold"
        className={classnames(
          classes.NoEntitlementModal__Title,
          classes['NoEntitlementModal__Title--green']
        )}
      >
        {t('noEntitlement.title2')}
      </Typography>
      <Typography
        format="bcl"
        weight="bold"
        className={classnames(
          classes.NoEntitlementModal__SupportText,
          classes['NoEntitlementModal__SupportText--white']
        )}
      >
        {t('noEntitlement.titleSupport')}
      </Typography>
      <StreamingFeatures />
      <Typography
        format="bcm"
        className={classnames(
          classes.NoEntitlementModal__UpgradeText,
          classes['NoEntitlementModal__UpgradeText--white']
        )}
      >
        {t('noEntitlement.footer')}
      </Typography>
      <Button
        text={t('common.talkToSales')}
        alt="secondary"
        size="lg"
        onClick={() => {
          window.open(getContactSalesUrl(organisationSubdomain), '_self')
        }}
        className={classes.NoEntitlementModal__SalesButton}
        data-testid="talk-to-sales-modal-button"
      />
    </Modal>
  )
}
