import { StreamingSourceId } from 'types/Streaming/Sources'

export const DB_APPLICATION_INTENT = {
  property: 'database.applicationIntent',
  values: {
    READ_ONLY: 'ReadOnly'
  },
  supportedBy: [StreamingSourceId.SQLServer]
}

export const hasReadReplicaProperty = (properties?: Record<string, string>) => {
  return !!(
    properties &&
    properties[DB_APPLICATION_INTENT.property] ===
      DB_APPLICATION_INTENT.values.READ_ONLY
  )
}
