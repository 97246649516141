import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'

import { Toaster } from '@matillion/component-library'

import { usePutEnvironment } from 'api/hooks'
import { usePutEnvironmentV2 } from 'api/hooks/usePutEnvironmentV2'
import { type PUTEnvironmentRequestBody } from 'api/types'

import { QueryKey } from 'constants/endpoint'
import { AppRoutes, PROJECT_DETAILS_ENVIRONMENTS } from 'constants/route'

import { useFlags } from 'hooks/flags'

import { SecretReferenceTypes, Warehouse } from 'types'
import { type AutoCompleteProps } from 'types/FormTypes'

export const useSubmitAssociateCloudCredentials = () => {
  const { projectId } = useParams()
  const { makeToast } = Toaster.useToaster()
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const { mutateAsync: updateEnvironment } = usePutEnvironment(projectId!)
  const { mutateAsync: updateEnvironmentV2 } = usePutEnvironmentV2()
  const { enableKeyPairAuthentication } = useFlags()

  const navigateToEnvironmentList = () => {
    queryClient.invalidateQueries([
      QueryKey.SECRET_REFERENCES,
      projectId!,
      SecretReferenceTypes.CLOUD_PLATFORM_CREDENTIALS
    ])
    queryClient.invalidateQueries([QueryKey.ENVIRONMENTS_LIST, projectId!])

    navigate(
      AppRoutes.getProjectDetails(projectId!, PROJECT_DETAILS_ENVIRONMENTS)
    )
  }

  const handleSubmit = ({
    requestBody,
    environment,
    setSubmitting
  }: {
    requestBody: PUTEnvironmentRequestBody
    environment: AutoCompleteProps
    setSubmitting: (isSubmitting: boolean) => void
  }) => {
    const shouldUseV2 =
      enableKeyPairAuthentication &&
      requestBody?.warehouseConnection.type === Warehouse.Snowflake &&
      requestBody?.warehouseConnection.authentication
    const updateEnvironmentFn = shouldUseV2
      ? updateEnvironmentV2
      : updateEnvironment
    setSubmitting(true)
    updateEnvironmentFn({
      values: requestBody,
      environmentId: environment.id,
      projectId: projectId!
    })
      .then((_res) => {
        makeToast({
          title: t('associateCredentials.responseMessage.success', {
            environmentName: environment.name
          }),
          message: '',
          type: 'success'
        })
        navigateToEnvironmentList()
      })
      .catch((_err) => {
        makeToast({
          title: t('associateCredentials.responseMessage.error', {
            environmentName: environment.name
          }),
          message: '',
          type: 'error'
        })
      })
      .finally(() => {
        setSubmitting(false)
      })
  }

  return handleSubmit
}
