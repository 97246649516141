import { useEffect, type FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import classNames from 'classnames'
import { useFormikContext } from 'formik'

import { useGetSecretReferences } from 'api/hooks'

import formClasses from 'components/Form/Form.module.scss'
import { Header, HeaderTitle } from 'components/Header'

import { useFlags } from 'hooks/flags'

import VaultName from 'modules/Common/Fields/VaultName/VaultName'
import {
  formSchema,
  type FormValues
} from 'modules/Secrets/CreateSecretDefinitionForm/CreateSecretDefinitionForm.utils'
import { useSecretDefinitionFormContext } from 'modules/Secrets/CreateSecretDefinitionForm/SecretDefinitionFormContext'
import classes from 'modules/Secrets/SecretDefinitionFormContent/SecretDefinitionFormContent.module.scss'

import { SecretReferenceTypes } from 'types'
import { AgentCloudProvider } from 'types/AgentCloudProvider'

import {
  Agent,
  Description,
  Name,
  SecretKey,
  SecretName,
  SecretValue
} from './Fields'

interface SecretDefinitionFormContentProps {
  isMHA: boolean
  locationId: string
  onCancel?: () => void
  showCancel?: boolean
}

const SecretDefinitionFormContent: FunctionComponent<
  SecretDefinitionFormContentProps
> = ({ isMHA, locationId, onCancel, showCancel }) => {
  const { t } = useTranslation()
  const { values } = useFormikContext<FormValues>()
  const { projectId } = useParams()
  const agentCloudProvider = values.agent?.agentCloudProvider
  const selectedLocationId = values.locationId ?? locationId
  const { setValidationSchema } = useSecretDefinitionFormContext()
  const { data: listOfSecretReferences = [] } = useGetSecretReferences(
    projectId!,
    [
      SecretReferenceTypes.OAUTH_USER_TOKENS,
      SecretReferenceTypes.OAUTH_AUTHORIZATION_CODE,
      SecretReferenceTypes.PASSWORD,
      SecretReferenceTypes.CLOUD_PLATFORM_CREDENTIALS
    ],
    { retryOnMount: false }
  )
  const { enableAzureMultiKeyVault } = useFlags()

  useEffect(() => {
    setValidationSchema(
      formSchema(listOfSecretReferences, isMHA, agentCloudProvider)
    )
  }, [listOfSecretReferences, isMHA, agentCloudProvider, setValidationSchema])

  return (
    <>
      <Header onCancel={onCancel} showCancel={showCancel}>
        <HeaderTitle data-testid="create-secret-definition">
          {t('secrets.createSecretDefinition.title')}
        </HeaderTitle>
      </Header>
      <div
        className={classNames(
          formClasses.Form__InnerWrap,
          formClasses['Form__InnerWrap--space-top']
        )}
      >
        <Name />
        <Description />
        {isMHA ? (
          <SecretValue />
        ) : (
          <>
            <Agent />
            {agentCloudProvider === AgentCloudProvider.AWS ? (
              <>
                <SecretName locationId={locationId} />
                <SecretKey locationId={locationId} />
              </>
            ) : null}

            {agentCloudProvider === AgentCloudProvider.AZURE ? (
              <>
                {enableAzureMultiKeyVault && (
                  <VaultName
                    projectId={projectId ?? ''}
                    agentId={values.agent?.id}
                    className={
                      classes['SecretDefinitionFormContent__Field--normal']
                    }
                    testIdPrefix={'secret-definition'}
                  />
                )}
                <SecretName locationId={selectedLocationId} />
              </>
            ) : null}
          </>
        )}
      </div>
    </>
  )
}

export default SecretDefinitionFormContent
