import { PROJECTS_CREDENTIALS, PROJECTS_DEFAULTS } from 'constants/route'

import { type EditEnvironmentSteps } from 'modules/Projects/EditEnvironment/types'

export type EditEnvironmentStepsType = Record<
  EditEnvironmentSteps,
  {
    previousStep?: EditEnvironmentSteps
    currentStep: EditEnvironmentSteps
    nextStep?: EditEnvironmentSteps
  }
>

export const steps: EditEnvironmentStepsType = {
  [PROJECTS_CREDENTIALS]: {
    previousStep: undefined,
    currentStep: PROJECTS_CREDENTIALS,
    nextStep: PROJECTS_DEFAULTS
  },
  [PROJECTS_DEFAULTS]: {
    previousStep: PROJECTS_CREDENTIALS,
    currentStep: PROJECTS_DEFAULTS,
    nextStep: undefined
  }
}
