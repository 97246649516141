import { useMutation, type UseMutationOptions } from 'react-query'

import { type AxiosError } from 'axios'

import { deleteProjectMembers } from 'api/clients'
import { type DELETEProjectMembersRequest } from 'api/types'

import { QueryKey } from 'constants/endpoint'

import { useClient } from './useClient'

type Options = UseMutationOptions<
  void,
  AxiosError,
  {
    values: DELETEProjectMembersRequest
  }
>

export const useDeleteProjectMembers = (
  projectId: string,
  options?: Options
) => {
  const client = useClient()

  return useMutation(
    [QueryKey.REMOVE_PROJECT_MEMBERS, projectId],
    async (data: { values: DELETEProjectMembersRequest }) => {
      const { values } = data
      return deleteProjectMembers({ projectId }, values, client)
    },
    options
  )
}
