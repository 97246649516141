import { type FunctionComponent } from 'react'
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom'

import { Loader } from '@matillion/component-library'

import { useGetProjectsStatus } from 'api/hooks/useGetProjectsStatus'

import { AppRoutes, PROJECTS_ADD } from 'constants/route'

import { OnboardingContextProvider } from 'context'

import OnboardingForm from './OnboardingForm'

const Onboarding: FunctionComponent = () => {
  const navigate = useNavigate()
  const { data, isLoading } = useGetProjectsStatus()

  if (isLoading) {
    return <Loader />
  }

  if (!data?.followOnboarding) {
    navigate(AppRoutes.getDashboard())
    return null
  }

  return (
    <Routes>
      <Route path="*" element={<Navigate replace to={PROJECTS_ADD} />} />
      <Route
        path={`${PROJECTS_ADD}/*`}
        element={
          <OnboardingContextProvider>
            <OnboardingForm />
          </OnboardingContextProvider>
        }
      />
    </Routes>
  )
}

export default Onboarding
