import { useMemo, type ChangeEvent, type FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'

import classNames from 'classnames'
import { getIn, useFormikContext } from 'formik'

import {
  AutoComplete,
  Field,
  type AutoCompleteItemId
} from '@matillion/component-library'

import classes from 'components/Form/Form.module.scss'

import { useDynamicValidation } from 'hooks/dynamicValidation/useDynamicValidation'

import { useCreateOAuthContext } from 'modules/Projects/CreateOAuth/CreateOAuthContext'
import { renderFormikError } from 'modules/utils'

export interface FormInputDropdownProps {
  title: string
  formikFormName: string
  availableItems: AutoCompleteItemId[]
  placeholderText?: string
  supportText?: string
  required?: boolean
  handleOnChange?: (value?: string | number) => void
}
export const FormInputDropdown: FunctionComponent<FormInputDropdownProps> = ({
  title,
  formikFormName,
  availableItems,
  placeholderText,
  supportText,
  required = true,
  handleOnChange
}) => {
  const { errors, handleBlur, setFieldValue, touched, values } =
    useFormikContext()
  const { t } = useTranslation()
  const { validationSchema, setValidationSchema } = useCreateOAuthContext()
  useDynamicValidation(
    formikFormName,
    title,
    setValidationSchema,
    validationSchema,
    required
  )

  const formikError = renderFormikError(
    getIn(errors, formikFormName),
    Boolean(getIn(touched, formikFormName))
  )

  const getErrorMessage = useMemo(() => {
    if (formikError) {
      return t(formikError)
    }

    return ''
  }, [formikError, t])

  return (
    <div data-testid={`create-oauth-${formikFormName}`}>
      <Field
        inputComponent={AutoComplete}
        availableItems={availableItems}
        title={title}
        name={formikFormName}
        data-testid={`create-oauth-${formikFormName}-input`}
        value={getIn(values, formikFormName)}
        placeholder={placeholderText}
        onChange={(
          e: ChangeEvent<HTMLInputElement> & {
            target: { value: AutoCompleteItemId }
          }
        ) => {
          if (handleOnChange) handleOnChange(e.target.value?.id)
          setFieldValue(formikFormName, e.target.value?.id)
        }}
        onBlur={handleBlur}
        className={classNames(classes.Form__SpacingStyles)}
        supportText={supportText}
        errorText={getErrorMessage}
        hasError={
          Boolean(getIn(errors, formikFormName)) &&
          Boolean(getIn(touched, formikFormName))
        }
        required={required}
      />
    </div>
  )
}
