import type { TFunction } from 'react-i18next'

import type { FieldMetaProps } from 'formik'
import { get, isEmpty } from 'lodash'
import { array, bool, object, string } from 'yup'

import type { GETStreamingPipelinesResponse } from 'api/types'

import { StreamingDestinationId } from 'types/Streaming'

import { formHasDatabaseProperty } from './FormContent.util'
import type {
  AzureBlobDestinationFormValues,
  FormValues,
  S3DestinationFormValues,
  SnowflakeDestinationFormValues
} from './types'

export const resolveErrorText = (
  t: TFunction,
  meta: {
    touched: boolean
    error?: string | { id: string }
  }
) => {
  if (meta.touched && meta.error) {
    return typeof meta.error === 'string' ? t(meta.error) : t(meta.error.id)
  }
}

export const createFormSchema = (
  existingPipelines: GETStreamingPipelinesResponse,
  t: TFunction<'translation'>,
  pipelineId?: string
) => {
  if (pipelineId) {
    // in edit mode we remove this pipeline from the unique name check
    existingPipelines = existingPipelines.filter(
      (existingPipeline) => existingPipeline.id !== pipelineId
    )
  }
  return object({
    name: string()
      .required('createStreamingPipeline.fields.name.error.required')
      .max(255, 'createStreamingPipeline.fields.name.error.max')
      .trim()
      .notOneOf(
        existingPipelines.map((existingPipeline) => existingPipeline.name),
        ({ value }) =>
          t('createStreamingPipeline.fields.name.error.notUnique', {
            name: value
          })
      ),
    agent: object({
      id: string().required(
        'createStreamingPipeline.fields.agent.error.required'
      )
    })
      .nullable()
      .required('error.unmatched'),
    source: object({
      type: object({
        id: string().required(
          'createStreamingPipeline.fields.source.error.required'
        )
      })
        .nullable()
        .required('error.unmatched'),
      connection: object({
        host: string(),
        port: string(),
        user: string(),
        database: string().nullable(),
        pdb: string().nullable(),
        password: object({
          type: object({
            id: string().required(
              'createStreamingPipeline.fields.snowflake.secretManager.error.required'
            )
          })
            .nullable()
            .required('error.unmatched'),
          name: string()
        }),
        jdbcProperties: object()
      }),
      tables: array(object({ schema: string(), table: string() })),
      snapshot: object({
        initial: object({
          enabled: bool()
        }),
        onDemandSnapshot: object({
          enabled: bool(),
          signalTable: object({
            schema: string(),
            table: string()
          })
            .nullable()
            .test(
              'table',
              'createStreamingPipeline.fields.pipelineConfig.signalTableInput.error',
              function (tableValue) {
                // @ts-expect-error 'from' always exists but isn't in typescript
                // see https://github.com/jquense/yup/issues/225#issuecomment-1046388203 for context
                const onDemandSnapshot = this.from[1].value
                return (
                  !onDemandSnapshot.enabled ||
                  (onDemandSnapshot.enabled &&
                    tableValue?.schema &&
                    tableValue?.table)
                )
              }
            )
        })
      })
    }),
    target: object({
      type: object({
        id: string().required(
          'createStreamingPipeline.fields.destination.error.required'
        )
      })
        .nullable()
        .required('error.unmatched')
    }).when(
      'type.id',
      (destinationId: StreamingDestinationId, schema) =>
        DESTINATION_VALIDATION_SCHEMAS[destinationId] || schema
    ),
    engine: object({
      advanced: object()
    })
  })
}

const DESTINATION_VALIDATION_SCHEMAS = {
  [StreamingDestinationId.S3]: object({
    bucket: string(),
    prefix: string()
  }),
  [StreamingDestinationId.ABS]: object({
    container: string(),
    prefix: string(),
    accountName: string(),
    accountKey: object({
      type: object({
        id: string().required(
          'createStreamingPipeline.fields.destinationConfig.azureBlob.accountKey.secretManager.error.required'
        )
      })
        .nullable()
        .required('error.unmatched'),
      name: string()
    })
  }),
  [StreamingDestinationId.Snowflake]: object({
    connection: object({
      accountName: string(),
      user: string(),
      password: object({
        type: object({
          id: string().required(
            'createStreamingPipeline.fields.destinationConfig.snowflake.secretManager.error.required'
          )
        })
          .nullable()
          .required('error.unmatched'),
        name: string()
      }),
      jdbcProperties: object()
    }),
    role: object({
      id: string()
    })
      .nullable()
      .required('error.unmatched'),
    warehouse: object({
      id: string()
    })
      .nullable()
      .required('error.unmatched'),
    database: object({
      id: string()
    })
      .nullable()
      .required('error.unmatched'),
    stageSchema: object({
      id: string()
    })
      .nullable()
      .required('error.unmatched'),
    stageName: object({
      id: string()
    })
      .nullable()
      .required('error.unmatched'),
    stagePrefix: string(),
    tableSchema: object({
      id: string()
    })
      .nullable()
      .required('error.unmatched'),
    tablePrefixType: object({
      id: string().required(
        'createStreamingPipeline.fields.destinationConfig.snowflake.tablePrefixType.error.required'
      )
    })
      .nullable()
      .required('error.unmatched'),
    transformationType: object({
      id: string().required(
        'createStreamingPipeline.fields.pipelineConfig.transformationType.error.required'
      )
    })
      .nullable()
      .required('error.unmatched'),
    temporalMapping: object({
      id: string().required(
        'createStreamingPipeline.fields.pipelineConfig.temporalMapping.error.required'
      )
    })
      .nullable()
      .required('error.unmatched')
  })
}

interface HasErrorOrIsEmptyType {
  values: FormValues
  fieldName: string
  meta: FieldMetaProps<unknown>
  fieldHasId?: boolean
}

export const hasErrorOrIsEmpty = ({
  values,
  fieldName,
  meta,
  fieldHasId = false
}: HasErrorOrIsEmptyType) => {
  const property = fieldHasId
    ? get(values, fieldName)?.id
    : get(values, fieldName)
  return isEmpty(property) || (!!meta.error && meta.touched)
}

// the xIsInvalid functions determine if a panels config is definitely invalid,
// but does not cover every invalid scenario (i.e. false negatives are possible/common)

export const destinationConnectionPanelIsInvalid = (values: FormValues) => {
  switch (values.target.type.id) {
    case StreamingDestinationId.Snowflake: {
      const snowflakeConnection = (
        values.target as SnowflakeDestinationFormValues
      ).connection
      return (
        isEmpty(snowflakeConnection.accountName) ||
        isEmpty(snowflakeConnection.user) ||
        isEmpty(snowflakeConnection.password.name)
      )
    }
  }
}

export const destinationConfigPanelIsInvalid = (
  values: FormValues
): boolean => {
  switch (values.target.type.id) {
    case StreamingDestinationId.Snowflake: {
      const snowflakeDestination =
        values.target as SnowflakeDestinationFormValues
      return (
        isEmpty(snowflakeDestination.role.id) ||
        isEmpty(snowflakeDestination.warehouse.id) ||
        isEmpty(snowflakeDestination.database.id) ||
        isEmpty(snowflakeDestination.stageSchema.id) ||
        isEmpty(snowflakeDestination.stageName.id) ||
        isEmpty(snowflakeDestination.stagePrefix) ||
        isEmpty(snowflakeDestination.tableSchema.id)
      )
    }
    case StreamingDestinationId.S3: {
      const s3Destination = values.target as S3DestinationFormValues
      return isEmpty(s3Destination.bucket) || isEmpty(s3Destination.prefix)
    }
    case StreamingDestinationId.ABS: {
      const s3Destination = values.target as AzureBlobDestinationFormValues
      return (
        isEmpty(s3Destination.container) ||
        isEmpty(s3Destination.prefix) ||
        isEmpty(s3Destination.accountName) ||
        isEmpty(s3Destination.accountKey.name)
      )
    }
  }
}

export const sourcePanelIsInvalid = (values: FormValues) => {
  return (
    isEmpty(values.source.connection.host) ||
    (formHasDatabaseProperty(values.source) &&
      isEmpty(values.source.connection.database)) ||
    isEmpty(values.source.connection.user) ||
    isEmpty(values.source.connection.password.name)
  )
}

export const engineConfigPanelIsInvalid = (values: FormValues) => {
  return (
    values.source.tables?.length === 0 ||
    (values.source.snapshot.onDemandSnapshot.enabled &&
      isEmpty(values.source.snapshot.onDemandSnapshot.signalTable?.table))
  )
}
