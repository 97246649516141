import { isValidCronExpression } from 'cron-expression-validator'
import cronstrue from 'cronstrue'

export const isCronValid = (cronExpression: string) => {
  try {
    const validCron = isValidCronExpression(cronExpression)
    if (!validCron) throw Error()
    // Compulsory check with the cronstrue library to ensure that it is in a human readable format according to this lib
    cronstrue.toString(cronExpression)
    return true
  } catch (e) {
    return false
  }
}
