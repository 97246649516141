import { type FunctionComponent } from 'react'

import classNames from 'classnames'

import { Icon } from '@matillion/component-library'

import classes from 'modules/Projects/StreamingPipelineDashboard/Header/components/PipelineActionIcon/PipelineActionIcon.module.scss'

interface PipelineActionIconProps {
  isStop: boolean
}

const PipelineActionIcon: FunctionComponent<PipelineActionIconProps> = ({
  isStop
}) => {
  if (isStop) {
    return (
      <Icon.Stop
        className={classNames(
          classes.PipelineActionIcon__Icon,
          classes.PipelineActionIcon__IconColourOverride
        )}
      />
    )
  } else {
    return <Icon.Play className={classes.PipelineActionIcon__Icon} />
  }
}

export default PipelineActionIcon
