import { useTranslation } from 'react-i18next'

import classNames from 'classnames'

import { Icon, Typography } from '@matillion/component-library'

import { useFlags } from 'hooks/flags'

import formClasses from 'modules/FormsContent/Form.module.scss'
import {
  StartDate,
  StartTime
} from 'modules/Projects/CreateSchedule/FormContent/Fields/Schedule/Common'

import classes from './StartDateTime.module.scss'

export const StartDateTime = () => {
  const { t } = useTranslation()
  const { enableStartTimeInputScheduleOptions } = useFlags()

  return (
    <>
      <div
        className={classNames(
          formClasses.Form__Field,
          classes.StartDateTime__Fields
        )}
      >
        <StartDate />
        {enableStartTimeInputScheduleOptions && <StartTime />}
      </div>
      {enableStartTimeInputScheduleOptions && (
        <div
          className={classNames(
            formClasses.Form__Field,
            classes.HelpTextContainer
          )}
        >
          <div
            data-testid="create-schedule-helper-text"
            className={classes.StartDateTime__HelpText}
          >
            <Icon.Info className={classes.StartDateTime__Icon} />
            <Typography format="bcs">{t('createSchedule.helpText')}</Typography>
          </div>
        </div>
      )}
    </>
  )
}
