export enum PermissionsType {
  EditProject = 'edit_project',

  CreateBranches = 'create_branches',
  EditBranches = 'edit_branches',
  DeleteBranches = 'delete_branches',
  ViewBranches = 'view_branches',

  CreatePipelines = 'create_pipelines',
  EditPipelines = 'update_pipelines',
  DeletePipelines = 'delete_pipelines',
  ViewPipelines = 'view_pipelines',

  CreateStreamingPipelines = 'create_streaming_pipelines',
  EditStreamingPipelines = 'update_streaming_pipelines',
  DeleteStreamingPipelines = 'delete_streaming_pipelines',
  ViewStreamingPipelines = 'view_streaming_pipelines',
  RunStreamingPipelines = 'run_streaming_pipelines',

  CreateEnvironments = 'create_environments',
  EditEnvironments = 'edit_environments',
  DeleteEnvironments = 'delete_environments',
  ViewEnvironments = 'view_environments',

  CreateSecrets = 'create_secrets',
  DeleteSecrets = 'delete_secrets',
  EditSecrets = 'edit_secrets',
  ViewSecrets = 'view_secrets',

  CreateOAuths = 'create_oauths',
  EditOAuths = 'edit_oauths',
  DeleteOAuths = 'delete_oauths',
  ViewOAuths = 'view_oauths',

  CreateSchedules = 'create_schedules',
  EditSchedules = 'edit_schedules',
  DeleteSchedules = 'delete_schedules',
  ViewSchedules = 'view_schedules',
  ViewEnvironment = 'view_environment',
  AdminPermission = 'admin_permission'
}
