import { type FC } from 'react'
import { useTranslation } from 'react-i18next'

import { Field as FormikField, useFormikContext, type FieldProps } from 'formik'

import { Field } from '@matillion/component-library'

import { type FormValues } from 'modules/Projects/CreateStreamingPipeline/FormContent'
import { AdditionalConfig } from 'modules/Projects/CreateStreamingPipeline/FormContent/ConfigurePipelineDetailsPage/Components/Properties/AdditionalConfig/AdditionalConfig'
import { FIELD_NAMES_ENGINE_ADVANCED_SETTINGS } from 'modules/Projects/CreateStreamingPipeline/FormContent/ConfigurePipelineDetailsPage/types'

export const AdvancedProperties: FC = () => {
  const { t } = useTranslation()
  const { values } = useFormikContext<FormValues>()

  return (
    <FormikField name={FIELD_NAMES_ENGINE_ADVANCED_SETTINGS}>
      {({ field, meta }: FieldProps<string>) => (
        <Field
          {...field}
          inputComponent={AdditionalConfig}
          title={t(
            'createStreamingPipeline.fields.pipelineConfig.advancedSettings.title'
          )}
          optionalLabelText={t(
            'createStreamingPipeline.fields.pipelineConfig.advancedSettings.optionalLabelText'
          )}
          additionalConfig={values.engine.advanced}
          fieldName={FIELD_NAMES_ENGINE_ADVANCED_SETTINGS}
          modalHeading={t(
            'createStreamingPipeline.fields.pipelineConfig.advancedSettings.modal.heading'
          )}
          dataTestId="configure-streaming-pipeline-pipeline-config-advanced-settings"
        />
      )}
    </FormikField>
  )
}
