import { useTranslation } from 'react-i18next'

import classnames from 'classnames'

import { Typography } from '@matillion/component-library'

import { ExternalLink } from 'components/ExternalLink'

import classes from './StreamingFeatures.module.scss'

export const StreamingFeatures = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'streamingListing.modal.product'
  })

  return (
    <div className={classes.StreamingFeatures}>
      <Typography
        format="bcm"
        weight="bold"
        className={classes['StreamingFeatures__ProductHeadings--green']}
      >
        {t('title')}
      </Typography>
      <Typography
        format="bcs"
        className={classnames(
          classes.StreamingFeatures__StreamingText,
          classes['StreamingFeatures__StreamingText--white']
        )}
      >
        {t('intro')}
      </Typography>
      <Typography
        format="bcm"
        weight="bold"
        className={classes['StreamingFeatures__ProductHeadings--green']}
      >
        {t('features')}
      </Typography>
      <ul
        className={classnames(
          classes.StreamingFeatures__StreamingBullets,
          classes['StreamingFeatures__StreamingBullets--white']
        )}
      >
        {['point-1', 'point-2', 'point-3', 'point-4', 'point-5'].map(
          (point) => (
            <li key={`point-${point}`}>
              <Typography format="bcs">{t(`bullets.${point}`)}</Typography>
            </li>
          )
        )}
      </ul>
      <ExternalLink
        href={t('learnMoreLink')}
        text={t('learnMore')}
        format="bcs"
        className={classnames(
          classes.StreamingFeatures__LearnMoreLink,
          classes['StreamingFeatures__LearnMoreLink--green']
        )}
      />
    </div>
  )
}
