import { type FunctionComponent } from 'react'

import cronstrue from 'cronstrue'

import { Typography } from '@matillion/component-library'

import scheduleClasses from 'modules/Projects/CreateSchedule/FormContent/CreateSchedule.module.scss'

import { isCronValid } from 'utils/cron'

interface ReadableCronProps {
  cronExpression: string
  timezone: string
}

export const ReadableCron: FunctionComponent<ReadableCronProps> = ({
  cronExpression,
  timezone
}) => {
  if (!isCronValid(cronExpression) || !timezone) {
    return null
  }

  return (
    <div
      className={scheduleClasses.CronExpression}
      data-testid="schedule-cron-text-output"
    >
      <Typography weight="bold">
        {`"${cronstrue.toString(cronExpression, {
          use24HourTimeFormat: true,
          dayOfWeekStartIndexZero: false,
          verbose: true
        })} in ${timezone} timezone"`}
      </Typography>
    </div>
  )
}
