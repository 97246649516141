import { useNavigate, useParams } from 'react-router-dom'

import { Loader } from '@matillion/component-library'

import { useGetMha, useGetProject } from 'api/hooks'

import Form from 'components/Form/Form'

import { CLOUD_PROVIDER_CREDS_STORAGE_ITEM } from 'constants/persistance'
import { AppRoutes } from 'constants/route'

import CloudProviderCredentials from 'modules/FormsContent/CloudProviderCredentials'
import { type FormValues } from 'modules/Projects/CloudProviderCredentials'
import {
  initialValues,
  providerValues
} from 'modules/Projects/CloudProviderCredentials/CloudProviderCredentials.util'
import { useCloudProviderCredentialsContext } from 'modules/Projects/CloudProviderCredentials/CloudProviderCredentialsContext'
import { useSubmitForm } from 'modules/Projects/CloudProviderCredentials/hooks/useSubmitForm'

import { AgentsSecretsHost } from 'types'
import { AgentCloudProvider as AgentCloudProviderEnum } from 'types/AgentCloudProvider'
import { CloudProviders } from 'types/CloudProviders'
import { type AgentCloudProvider } from 'types/FormTypes'

const CloudProviderCredentialsForm = () => {
  const { projectId } = useParams()
  const navigate = useNavigate()
  const { data: projectDetailsData, isLoading: isProjectDetailsLoading } =
    useGetProject(projectId!)
  const { validationSchema } = useCloudProviderCredentialsContext()

  const isMHAProject =
    projectDetailsData?.agentAndSecretHostLocation ===
    AgentsSecretsHost.MatillionHosted
  const secretLocationId = projectDetailsData?.secretLocationIds[0] as string

  const { data: mhaData, isLoading: isMHALoading } = useGetMha({
    options: {
      enabled: isMHAProject
    }
  })
  const handleSubmit = useSubmitForm(secretLocationId, projectId!)

  const initialAgentDetails: AgentCloudProvider | undefined = isMHAProject
    ? {
        id: mhaData?.agentId ?? '',
        name: mhaData?.agentId ?? '',
        agentCloudProvider: AgentCloudProviderEnum.MATILLION.toString()
      }
    : undefined

  const handleCancel = () => {
    navigate(AppRoutes.getCloudProviderCredentialListing(projectId!))
  }

  const initiallySelectedProvider = CloudProviders.AWS

  return isMHALoading || isProjectDetailsLoading ? (
    <Loader />
  ) : (
    <Form<FormValues>
      formikValues={{
        initialTouched: false,
        initialValues: initialValues(
          initiallySelectedProvider,
          initialAgentDetails
        ),
        onSubmit: handleSubmit,
        validationSchema,
        validateOnMount: true,
        enableReinitialize: true
      }}
      persistingStorageId={CLOUD_PROVIDER_CREDS_STORAGE_ITEM}
      translationPrefix="cloudProviderCredentials"
      persistenceExclusions={Object.keys(providerValues)}
    >
      <CloudProviderCredentials
        onCancel={handleCancel}
        showCancel
        isCHA={!isMHAProject}
      />
    </Form>
  )
}

export default CloudProviderCredentialsForm
