import { type OAuthSchema } from 'api/types'

import { type CreateOAuthFormikValueTypes } from 'modules/Projects/CreateOAuth'

export const initialValues: CreateOAuthFormikValueTypes = {
  name: '',
  provider: {
    id: '',
    name: ''
  },
  environmentAgent: {
    id: '',
    name: '',
    agentCloudProvider: '1'
  },
  authType: {
    id: '',
    name: ''
  }
}

export interface AuthServerParamsAndSecretParams {
  sendToAuthServer: { [key: string]: string }
  saveToSecret: { [key: string]: string }
}

export const splitValuesSaveToSecretAndSendToAuthServer = (
  schema: OAuthSchema,
  authType: string,
  fields: Record<string, string>
): AuthServerParamsAndSecretParams => {
  const oAuthType = schema.authTypes.find((x) => x.type === authType)!
  const parameters = [
    ...(oAuthType.parameters ?? []),
    ...(oAuthType.additionalParameters ?? [])
  ]
  const saveToSecret: { [key: string]: string } = {}
  const sendToAuthServer: { [key: string]: string } = {}

  for (const entry of Object.entries(fields)) {
    const oAuthParameter = parameters.find((param) => param.id === entry[0])
    if (oAuthParameter) {
      if (oAuthParameter.saveToSecret) {
        saveToSecret[oAuthParameter.id] = entry[1]
      }
      if (oAuthParameter.sendToAuthServer) {
        sendToAuthServer[oAuthParameter.id] = entry[1]
      }
    }
  }
  return {
    sendToAuthServer,
    saveToSecret
  }
}
