import { type FunctionComponent } from 'react'

import { Button, Icon } from '@matillion/component-library'

import { StreamingEventLevel } from 'types/Streaming'

import classes from './EventExpander.module.scss'

interface EventExpanderProps {
  isExpanded: boolean
  eventLevel: StreamingEventLevel
  message?: string
  onClick: () => void
}

export const EventExpander: FunctionComponent<EventExpanderProps> = ({
  isExpanded,
  eventLevel,
  message,
  onClick
}) => {
  if (eventLevel === StreamingEventLevel.INFO || !message) {
    return null
  }

  return (
    <Button
      onClick={onClick}
      alt="text"
      className={classes.EventExpander__Button}
      data-testid="event-expander"
    >
      <Icon.ChevronDown
        className={
          isExpanded ? classes.EventExpander__Up : classes.EventExpander__Down
        }
      />
    </Button>
  )
}
