import { useMutation, type UseMutationOptions } from 'react-query'

import { type AxiosError } from 'axios'

import { createOAuthV2 } from 'api/clients'
import { useClient } from 'api/hooks'
import {
  type POSTSetupOAuthV2Request,
  type POSTSetupOAuthV2Response
} from 'api/types'

import { QueryKey } from 'constants/endpoint'

type TError = AxiosError<unknown>

interface TVariablesV2 {
  values: POSTSetupOAuthV2Request
}

type OptionsV2 = UseMutationOptions<
  POSTSetupOAuthV2Response,
  AxiosError,
  TVariablesV2
>

export const useCreateOAuthV2 = (projectId: string, options?: OptionsV2) => {
  const client = useClient()

  return useMutation<POSTSetupOAuthV2Response, TError, TVariablesV2>(
    [QueryKey.CREATE_OAUTH, projectId],
    async (data: { values: POSTSetupOAuthV2Request }) => {
      const { values } = data
      return createOAuthV2(
        values,
        {
          projectId
        },
        client
      )
    },
    options
  )
}
