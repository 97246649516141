import { useQuery, type UseQueryOptions } from 'react-query'

import { type AxiosError } from 'axios'

import { getVaults } from 'api/createProjectForm/client'
import { type GETVaultNameResponse } from 'api/createProjectForm/types/VaultNameResponse'
import { useClient } from 'api/hooks'

import { QueryKey } from 'constants/endpoint'

type TData = GETVaultNameResponse
type TError = AxiosError<unknown>

/**
 * See more at https://tanstack.com/query/v4/docs/reference/useQuery
 *
 * @param {string} agentId - The ID of the agent
 * @param {Object|undefined} options - useQuery options
 * @returns {GETVaultNameResponse} The secret names in string format
 */
export const useGetVaultNames = (
  agentId: string,
  options?: UseQueryOptions<TData, TError>
) => {
  const client = useClient()

  return useQuery({
    queryKey: [QueryKey.SECRET_NAMES, agentId],
    queryFn: async () => getVaults({ agentId }, client),
    ...options
  })
}
