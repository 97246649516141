import {
  useEffect,
  useState,
  type ChangeEvent,
  type FocusEvent,
  type FunctionComponent
} from 'react'
import { useTranslation } from 'react-i18next'

import classNames from 'classnames'
import { useFormikContext } from 'formik'
import { v4 as uuid } from 'uuid'

import { Button, Icon, Input, Typography } from '@matillion/component-library'
import { useLDClient } from '@matillion/hub-client'

import classes from 'modules/Common/Fields/Account/SnowflakeAccount/SnowflakeAccount.module.scss'
import type { CreateProjectFormikValueTypes } from 'modules/Projects/CreateProject/CreateProjectForm'

interface AccountProps {
  translationProxy?: string
  handleSnowflakeModalClick: () => void
}

export const snowflakeAccountExtractRegex =
  /^(https:\/\/|http:\/\/)?([^/.]+(?:\.[^/.]+){0,2})\.snowflakecomputing\.com.*$/i

export const SnowflakeAccount: FunctionComponent<AccountProps> = ({
  translationProxy = 'accountSnowflake',
  handleSnowflakeModalClick
}) => {
  const { errors, handleBlur, setFieldValue, touched, values, validateField } =
    useFormikContext<CreateProjectFormikValueTypes>()

  const { t } = useTranslation()
  const [currentValue, setCurrentValue] = useState(values.account)

  const { track: sendLDMetric } = useLDClient()

  useEffect(() => {
    setCurrentValue(values.account)
  }, [values.account])

  const uniqueFieldId = uuid()

  const supportText = t(`fields.${translationProxy}.supportText`)

  const errorText =
    errors?.account && touched.account ? t(errors.account) : null

  const handleChange = async (e: ChangeEvent<HTMLInputElement>) => {
    let inputValue = e.target.value
    inputValue = inputValue.replace(snowflakeAccountExtractRegex, '$2')
    await setFieldValue('account', inputValue)
    setCurrentValue(inputValue)
    sendLDMetric('snowflake-credentials-form-q-1-account-url-changed')
  }

  const handleFieldBlur = async (e: FocusEvent<HTMLInputElement>) => {
    handleBlur(e)
    await setFieldValue('account', currentValue.trim())
    setCurrentValue(currentValue.trim())
    await validateField('account')
  }

  return (
    <div
      data-testid={'snowflake-credentials-account'}
      className={classNames(classes.Field)}
    >
      <label
        className={classNames(classes.Label, {
          [classes['Label--Error']]: !!errorText
        })}
        id={uniqueFieldId}
      >
        <div className={classes.Label__Title}>
          <Typography as="span" weight="bold" format="bcs">
            {t(`fields.${translationProxy}.title`)}
          </Typography>
          <Button
            alt="text"
            fontWeight="normal"
            onClick={handleSnowflakeModalClick}
            size="md"
            as="span"
            className={classes.Label__ModalButton}
            data-testid="snowflake-account-url-modal-button"
            data-tracker-id="snowflake-account-url-modal-button"
          >
            <Icon.Help />
            <Typography as="span" weight="bold" format="bcs">
              {t(`fields.${translationProxy}.hintButtonUrlText`)}
            </Typography>
          </Button>
        </div>
        <Input
          name="account"
          data-testid={'snowflake-credentials-account-input'}
          data-tracker-id={'snowflake-credentials-onboarding-account-input'}
          value={currentValue}
          placeholder={t(`fields.${translationProxy}.placeholderText`)}
          onChange={handleChange}
          onBlur={handleFieldBlur}
          required
        />
      </label>

      <div className={classes.Field__Info}>
        <div
          className={classNames(
            classes.Field__SupportText,
            classes.SupportText
          )}
        >
          <div>
            <Typography as="span" format="bcs">
              {supportText}
            </Typography>
          </div>
        </div>
      </div>

      {errorText && (
        <div className={classes.Field__Info}>
          <div
            className={classNames(
              classes.Field__SupportText,
              classes.SupportText
            )}
          >
            <div
              className={classNames(
                classes.SupportText__IconWrapper,
                classes.IconWrapper,
                classes.Field__Error
              )}
            >
              <Icon.Error className={classes.Icon} />
            </div>
            <Typography className={classes.Field__Error} as="span" format="bcs">
              {errorText}
            </Typography>
          </div>
        </div>
      )}
    </div>
  )
}
