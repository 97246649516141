import { useQuery, type UseQueryOptions } from 'react-query'

import { type AxiosError } from 'axios'

import { getEnvironmentMembers } from 'api/clients'
import { useClient } from 'api/hooks'
import { type GETEnvironmentMembersResponse } from 'api/types'

import config from 'config'

import { QueryKey } from 'constants/endpoint'

type TData = GETEnvironmentMembersResponse
type TError = AxiosError<unknown>
type TOptions = UseQueryOptions<TData, TError, TData>

export const useGetEnvironmentMembers = (
  environmentId: string,
  options?: TOptions
) => {
  const client = useClient({
    tokenOptions: {
      audience: config.hub.apiAudience
    },
    axiosConfig: {
      baseURL: config.hub.apiUrl
    }
  })

  return useQuery<TData, TError>(
    [QueryKey.ENVIRONMENT_MEMBERS_LIST, environmentId],
    async () => {
      return getEnvironmentMembers({ environmentId }, client)
    },
    options
  )
}
