import { type FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'

import { NoResourcesAvailable } from 'components/NoResourcesAvailable'

interface NoDataProps {
  onAddHandler: VoidFunction
}

const NoData: FunctionComponent<NoDataProps> = ({ onAddHandler }) => {
  const { t } = useTranslation()

  return (
    <NoResourcesAvailable
      title={t('schedulesListing.createFirstSchedule.title')}
      supportText={t('schedulesListing.createFirstSchedule.supportText')}
      buttonText={t('schedulesListing.createFirstSchedule.buttonText')}
      onButtonClick={onAddHandler}
      data-testid="add-first-schedule"
      data-tracker="pe_start-create-schedule-cta_click"
    />
  )
}

export default NoData
