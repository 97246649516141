import { useCallback, useEffect, type FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import { Navigate, Route, Routes } from 'react-router-dom'

import { Loader, Toaster } from '@matillion/component-library'
import {
  heap,
  RoleGuard,
  useAuth,
  useEntitlements,
  usePendo,
  useSendLDExperimentAnalytics,
  useUser
} from '@matillion/hub-client'

import { useGetMha, usePostMha } from 'api/hooks'
import { MhaStatus } from 'api/types'

import AppLayout from 'components/AppLayout'

import {
  GENERIC_PROJECT_DETAILS,
  ONBOARDING,
  PROJECTS_ADD,
  ROOT
} from 'constants/route'

import { useFlags } from 'hooks/flags'

import { Login } from 'modules/Auth'
import EntitlementError from 'modules/EntitlementError'
import Onboarding from 'modules/Onboarding'
import CreateProject from 'modules/Projects/CreateProject'
import ProjectsDashboard from 'modules/Projects/ProjectDashboard'
import ProjectDetailsRoutes from 'modules/Projects/ProjectDetails'

import RouteWrapper from './RouteWrapper'

const App: FunctionComponent = () => {
  const { isLoggedIn } = useAuth()
  const { user, profile, organisation, roles } = useUser()
  const pendo = usePendo()
  const { data: entitlementData, isLoading: isEntitlementLoading } =
    useEntitlements()
  const { enableMhaUnsuspension, enableSnowflakeCredentialsScreenExperiment } =
    useFlags()
  const { data: mhaData } = useGetMha({
    options: {
      enabled:
        Boolean(enableMhaUnsuspension) &&
        Boolean(entitlementData?.canAccessDesigner) &&
        roles.has('saas-etl:user')
    }
  })
  const { mutateAsync: restartAgent } = usePostMha({})
  const { t } = useTranslation()

  const { makeToast } = Toaster.useToaster()

  useEffect(() => {
    if (isLoggedIn) pendo.initialize(user.sub, organisation.id)
  }, [isLoggedIn, user, organisation, pendo])

  useEffect(() => {
    if (isLoggedIn) {
      pendo.updateOptions(
        {
          email: user.email,
          full_name: profile.name
        },
        {
          name: organisation.name,
          subdomain: organisation.subdomain,
          region: organisation.region
        }
      )
    }
  }, [isLoggedIn, user, profile, organisation, pendo])

  useEffect(() => {
    if (!window.__MICROVERSE__) {
      heap.clearEventProperties()
    }
  }, [])

  useSendLDExperimentAnalytics([
    {
      experimentName: 'Snowflake Credentials Screen - Trial Link',
      flagKey: 'enable-snowflake-credentials-screen-experiment',
      variationName: enableSnowflakeCredentialsScreenExperiment
        ? 'Variation A - Show Link'
        : 'Control - No Link'
    }
  ])

  const unsuspendAgent = useCallback(async () => {
    await restartAgent()

    makeToast({
      title: t('agentUnsuspensionWarning.title'),
      message: t('agentUnsuspensionWarning.message'),
      type: 'warning'
    })
  }, [makeToast, restartAgent, t])

  useEffect(() => {
    if (
      mhaData?.status.toLowerCase() === MhaStatus.STOPPED.toLowerCase() &&
      Boolean(enableMhaUnsuspension)
    ) {
      unsuspendAgent()
    }
  }, [enableMhaUnsuspension, mhaData?.status, roles, unsuspendAgent])

  if (!isLoggedIn) {
    return <Login />
  }

  if (isEntitlementLoading) {
    return <Loader />
  }

  if (!entitlementData?.canAccessDesigner) {
    return (
      <AppLayout>
        <EntitlementError />
      </AppLayout>
    )
  }

  return (
    <RoleGuard role="saas-etl:user">
      <AppLayout>
        <Routes>
          <Route
            path={ROOT}
            element={
              <RouteWrapper>
                <ProjectsDashboard />
              </RouteWrapper>
            }
          />
          <Route
            path={`${ONBOARDING}/*`}
            element={
              <RouteWrapper enableTrialBanner={false}>
                <Onboarding />
              </RouteWrapper>
            }
          />
          <Route
            path={`${PROJECTS_ADD}/*`}
            element={
              <RouteWrapper>
                <CreateProject />
              </RouteWrapper>
            }
          />
          <Route
            path={`${GENERIC_PROJECT_DETAILS}/*`}
            element={
              <RouteWrapper>
                <ProjectDetailsRoutes />
              </RouteWrapper>
            }
          />
          <Route path="*" element={<Navigate to={ROOT} />} />
        </Routes>
      </AppLayout>
    </RoleGuard>
  )
}

export default App
