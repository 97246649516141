import { type FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'

import classNames from 'classnames'
import { useFormikContext } from 'formik'

import { Field } from '@matillion/component-library'

import classes from 'components/Form/Form.module.scss'

import { type CreateProjectFormikValueTypes } from 'modules/Projects/CreateProject/CreateProjectForm'

export const AwsSecretAccessKey: FunctionComponent = () => {
  const { errors, handleChange, touched, values } =
    useFormikContext<CreateProjectFormikValueTypes>()
  const { t } = useTranslation()

  return (
    <div data-testid={`${values.type}-cloud-credentials-aws-secret-access-key`}>
      <Field
        type="password"
        title={t('fields.awsSecretAccessKey.title')}
        name="awsSecretAccessKey"
        data-testid={`${values.type}-cloud-credentials-aws-secret-access-key-input`}
        value={values.awsSecretAccessKey}
        placeholder={t('fields.awsSecretAccessKey.placeholderText')}
        onChange={handleChange}
        className={classNames(classes.Form__SpacingStyles)}
        supportText={t('fields.awsSecretAccessKey.supportText')}
        errorText={
          errors?.awsSecretAccessKey && touched.awsSecretAccessKey
            ? t(errors.awsSecretAccessKey)
            : null
        }
        hasError={
          Boolean(errors?.awsSecretAccessKey) &&
          Boolean(touched.awsSecretAccessKey)
        }
        required
      />
    </div>
  )
}
