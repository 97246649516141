import { type FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'

import { Typography } from '@matillion/component-library'

import classes from 'modules/Projects/StreamingPipelineDashboard/Summary/components/StreamingDestinationSummary/StreamingDestinationSummary.module.scss'
import { getDestinationSummaryFields } from 'modules/Projects/StreamingPipelineDashboard/Summary/components/StreamingDestinationSummary/StreamingDestinationSummary.util'
import { StreamingSummaryBlock } from 'modules/Projects/StreamingPipelineDashboard/Summary/components/StreamingSummaryBlock/StreamingSummaryBlock'

import {
  STREAMING_DESTINATIONS,
  type DestinationConfigType
} from 'types/Streaming'

interface StreamingDestinationSummaryProps {
  destination: DestinationConfigType
  isExpanded: boolean
}

const StreamingDestinationSummary: FunctionComponent<
  StreamingDestinationSummaryProps
> = ({ destination, isExpanded }) => {
  const { t } = useTranslation()

  return (
    <div
      data-testid="summary-destination"
      className={classes.StreamingDestinationSummary__Container}
    >
      <div className={classes.StreamingDestinationSummary__Title}>
        <Typography format="bcl">
          {t('streamingPipelineDashboard.summary.fields.destination.title')}
        </Typography>
        <img
          alt={destination.type}
          src={STREAMING_DESTINATIONS[destination.type]?.icon}
          className={classes.StreamingDestinationSummary__Icon}
        />
      </div>
      <StreamingSummaryBlock
        fields={getDestinationSummaryFields(destination, isExpanded, t)}
      />
    </div>
  )
}

export default StreamingDestinationSummary
