import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from 'react-query'

import { type AxiosError } from 'axios'

import { Toaster } from '@matillion/component-library'

import { postStreamingPipeline } from 'api/clients'
import { useNetworkErrorToast, useStreamingClient } from 'api/hooks/index'
import {
  type ErrorResponse,
  type POSTStreamingPipelineRequestValues
} from 'api/types'

import { QueryKey } from 'constants/endpoint'

import type { StreamingPipelineDefinition } from 'types/Streaming'

type TError = AxiosError<ErrorResponse>
interface TVariables {
  pipelineDefinition: POSTStreamingPipelineRequestValues
}

export const usePostStreamingPipeline = (projectId: string) => {
  const streamingClient = useStreamingClient()
  const makeErrorToast = useNetworkErrorToast()
  const { clearToasts, makeToast } = Toaster.useToaster()
  const { t } = useTranslation()
  const queryClient = useQueryClient()

  return useMutation<StreamingPipelineDefinition, TError, TVariables>({
    mutationKey: [QueryKey.STREAMING_PIPELINE_CREATE, projectId],
    mutationFn: async ({ pipelineDefinition }) => {
      makeToast({
        title: t('streamingActions.create.sending', {
          pipelineName: pipelineDefinition.name
        }),
        message: '',
        type: 'info'
      })
      return postStreamingPipeline(
        pipelineDefinition,
        { projectId },
        streamingClient
      )
    },
    onError: (error) => {
      makeErrorToast({ message: error.response?.data.detail ?? error.message })
    },
    onSuccess: async (data) => {
      // invalidate the cache for the streaming pipelines listing so our new pipeline is included
      await queryClient.invalidateQueries({
        queryKey: [QueryKey.STREAMING_PIPELINES_LIST, projectId]
      })
      clearToasts()
      makeToast({
        title: t('streamingActions.create.success', {
          pipelineName: data.name
        }),
        message: '',
        type: 'success'
      })
    }
  })
}
