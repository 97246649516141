import { useQuery, type UseQueryOptions } from 'react-query'

import { type AxiosError } from 'axios'

import { getStreamingPipelineStatus } from 'api/clients'
import { useStreamingClient } from 'api/hooks/index'
import type { GETStreamingPipelineStatusResponse } from 'api/types'

import { QueryKey } from 'constants/endpoint'

type TData = GETStreamingPipelineStatusResponse
type TError = AxiosError<unknown>
type TOptions = UseQueryOptions<TData, TError, TData>

export const useGetStreamingPipelineStatus = (
  pipelineId: string,
  projectId?: string,
  options?: TOptions
) => {
  const streamingClient = useStreamingClient()

  return useQuery<TData, TError>(
    [QueryKey.STREAMING_PIPELINE_STATUS, pipelineId],
    async () =>
      // @ts-expect-error 'enabled' property below disables request if projectId is undefined
      getStreamingPipelineStatus({ projectId, pipelineId }, streamingClient),
    {
      ...options,
      enabled: !!projectId
    }
  )
}
