import { useQuery, type UseQueryOptions } from 'react-query'

import { type AxiosError } from 'axios'

import { getProjects } from 'api/clients'
import { type GETProjectResponse } from 'api/types'

import { QueryKey } from 'constants/endpoint'

import { useClient } from './useClient'
import { useNetworkErrorToast } from './useNetworkErrorToast'

type TData = GETProjectResponse[]
type TError = AxiosError<unknown>

export const useGetProjects = (options?: UseQueryOptions<TData, TError>) => {
  const client = useClient()
  const makeErrorToast = useNetworkErrorToast()
  // work around for the region feature flag timing and setting the baseURL. Remove the baseURL key when region FF no longer required
  return useQuery<TData, TError>(
    [QueryKey.PROJECTS_LIST],
    async () => getProjects(client),
    {
      onError: (err: AxiosError) => {
        makeErrorToast({ message: err.message })
      },
      ...options
    }
  )
}
