import { useMemo, type FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import { Navigate, useNavigate, useParams } from 'react-router-dom'

import { Loader } from '@matillion/component-library'

import {
  useGetEnvironmentAgents,
  useGetEnvironments,
  useGetProject,
  useGetSchedules,
  useNetworkErrorToast,
  usePutSchedule
} from 'api/hooks'
import { editScheduleMutationData } from 'api/mutation'

import Form from 'components/Form'

import { EDIT_SCHEDULE } from 'constants/persistance'
import { AppRoutes, PROJECT_DETAILS_SCHEDULES } from 'constants/route'

import {
  useCompareAndClearSessionStorage,
  useHandleScheduleSubmit
} from 'hooks'
import { useFlags } from 'hooks/flags'

import { generateCronFromInputs } from 'modules/Projects/CreateSchedule/FormContent/CreateScheduleContent.util'
import EditScheduleContent, {
  initialValues,
  validationSchema
} from 'modules/Projects/EditSchedule/FormContent'
import { type EditScheduleFormValues } from 'modules/Projects/EditSchedule/types'

import { AgentsSecretsHost, ScheduleDisplayMode } from 'types'

const EditScheduleForm: FunctionComponent = () => {
  const translationPrefix = 'editSchedule'
  const { t } = useTranslation()
  const { projectId, scheduleId } = useParams()
  const navigate = useNavigate()
  const { enableStartTimeInputScheduleOptions } = useFlags()
  const makeErrorToast = useNetworkErrorToast()
  const [submitForm] = useHandleScheduleSubmit<EditScheduleFormValues>()

  const { data: schedules, isLoading: isSchedulesLoading } = useGetSchedules(
    projectId!
  )
  const { data: environments, isLoading: isEnvironmentsLoading } =
    useGetEnvironments(projectId!)
  const { data: agents, isLoading: isAgentsLoading } = useGetEnvironmentAgents()
  const { data: projectData, isLoading: isProjectLoading } = useGetProject(
    projectId!
  )

  const { mutateAsync: mutateAsyncSchedule } = usePutSchedule()

  const browserSessionStorage = window.sessionStorage.getItem(EDIT_SCHEDULE)
  const parsedSessionStorage:
    | { [key in keyof EditScheduleFormValues]: EditScheduleFormValues }
    | undefined = browserSessionStorage && JSON.parse(browserSessionStorage)

  useCompareAndClearSessionStorage({
    storageData: parsedSessionStorage,
    comparisonValue: scheduleId,
    storageName: EDIT_SCHEDULE,
    storageParameterId: 'scheduleId'
  })

  const publishSchedule = async (
    values: EditScheduleFormValues,
    onPublishStatus: (success: boolean, scheduleName?: string) => void,
    onFinish: () => void
  ) => {
    const schedule = schedules!.find((e) => e.id === values.scheduleId)
    mutateAsyncSchedule({
      values: editScheduleMutationData(
        {
          ...values,
          schedule: {
            ...values.schedule,
            cronExpression:
              values.schedule.displayMode === ScheduleDisplayMode.BASIC
                ? generateCronFromInputs(values)
                : values.schedule.cronExpression
          }
        },
        schedule!.scheduleEnabled,
        projectId!,
        Boolean(enableStartTimeInputScheduleOptions)
      ),
      scheduleId: scheduleId!
    })
      .then((_res) => {
        onPublishStatus(true, values.name)
      })
      .catch((_res) => {
        onPublishStatus(false)
      })
      .finally(onFinish)
  }

  const handleOnCancel = () => {
    navigate(AppRoutes.getProjectDetails(projectId!, PROJECT_DETAILS_SCHEDULES))
  }
  const selectedSchedule = useMemo(
    () => schedules?.find((x) => x.id === scheduleId),
    [scheduleId, schedules]
  )

  if (
    isProjectLoading ||
    isSchedulesLoading ||
    isEnvironmentsLoading ||
    isAgentsLoading
  ) {
    return <Loader />
  }
  if (!selectedSchedule) {
    makeErrorToast({
      title: t('editSchedule.responseMessage.notFound.title'),
      message: t('editSchedule.responseMessage.notFound.message', {
        scheduleId
      })
    })
    return (
      <Navigate
        to={AppRoutes.getProjectDetails(projectId!, PROJECT_DETAILS_SCHEDULES)}
      />
    )
  }

  const scheduleInitialValues = initialValues(
    scheduleId!,
    selectedSchedule,
    environments,
    agents,
    projectData?.agentAndSecretHostLocation ===
      AgentsSecretsHost.MatillionHosted
  )

  return (
    <Form
      formikValues={{
        validationSchema: validationSchema,
        onSubmit: async (newValues) =>
          submitForm(newValues, publishSchedule, translationPrefix),
        initialValues: scheduleInitialValues,
        validateOnMount: true,
        initialTouched: false
      }}
      translationPrefix={translationPrefix}
      persistingStorageId={EDIT_SCHEDULE}
    >
      <EditScheduleContent
        showCancel
        onCancel={handleOnCancel}
        modalContent={t('editSchedule.modal.content')}
      />
    </Form>
  )
}

export default EditScheduleForm
