import { useMemo, useState } from 'react'

import { object } from 'yup'

import { type GenericSchemaType } from 'types'

import { CloudProviderCredentialsContext } from './CloudProviderCredentialsContext'
import CloudProviderCredentialsForm from './CloudProviderCredentialsForm'

const CloudProviderCredentialsFormProvider = () => {
  const [validationSchema, setValidationSchema] = useState<GenericSchemaType>(
    object({})
  )

  const values = useMemo(
    () => ({
      validationSchema,
      setValidationSchema
    }),
    [validationSchema, setValidationSchema]
  )

  return (
    <CloudProviderCredentialsContext.Provider value={values}>
      <CloudProviderCredentialsForm />
    </CloudProviderCredentialsContext.Provider>
  )
}
export default CloudProviderCredentialsFormProvider
