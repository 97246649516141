import { type FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'

import {
  Typography,
  type DataGridColumnProps,
  type DataGridRow
} from '@matillion/component-library'

import SortableDataGrid from 'modules/Projects/StreamingPipelineDashboard/Common/components/SortableDataGrid/SortableDataGrid'
import SchemaButton from 'modules/Projects/StreamingPipelineDashboard/Metrics/components/SchemaButton/SchemaButton'

import { type RowsScanned } from 'types/Streaming'

import classes from './SnapshottingTableExplorer.module.scss'

interface SchemaRowsScannedGridRow extends DataGridRow {
  id: string
  rowsScanned: number
  details: RowsScanned
}

interface TableRowsScannedGridRow extends DataGridRow {
  id: string
  rowsScanned: number
}

interface SnapshottingTableExplorerProps {
  onSchemaSelect: (schema: RowsScanned) => void
  selectedSchema?: RowsScanned
  schemas: RowsScanned[]
  filter: string
}

const SnapshottingTableExplorer: FunctionComponent<
  SnapshottingTableExplorerProps
> = ({ onSchemaSelect, selectedSchema, schemas, filter }) => {
  const { t } = useTranslation()

  if (schemas.length === 0) {
    return null
  }

  if (selectedSchema) {
    const schemaRowsScanned = selectedSchema.tables.reduce(
      (total, table) => total + table.rowsScanned,
      0
    )
    const columns: Array<DataGridColumnProps<TableRowsScannedGridRow>> = [
      {
        key: 'id',
        sortable: true,
        title: `${t('streamingPipelineDashboard.metrics.common.tables')} (${selectedSchema.tables.length})`,
        as: Typography,
        mapValues: (value) => ({
          format: 'bcs',
          children: value.id,
          'data-testid': 'selected-table-name'
        })
      },
      {
        key: 'rowsScanned',
        sortable: true,
        title: `${t('streamingPipelineDashboard.metrics.snapshot.rowsScanned')} (${schemaRowsScanned})`,
        as: Typography,
        className: classes.SnapshottingTableExplorer__RightColumn,
        mapValues: (row) => ({
          children: row.rowsScanned,
          format: 'bcs'
        })
      }
    ]

    const filteredTables = selectedSchema.tables.filter((table) =>
      table.table.toLowerCase().includes(filter.toLowerCase())
    )
    const rows = filteredTables.map(
      (table): TableRowsScannedGridRow => ({
        id: table.table,
        rowsScanned: table.rowsScanned
      })
    )

    return (
      <SortableDataGrid
        columns={columns}
        rows={rows}
        data-testid="snapshotting-tables-listing"
        defaultSort={{ id: 'ASC' }}
      />
    )
  }

  const totalRowsScanned = schemas.reduce(
    (total1, schema) =>
      total1 +
      schema.tables.reduce((total2, table) => total2 + table.rowsScanned, 0),
    0
  )
  const columns: Array<DataGridColumnProps<SchemaRowsScannedGridRow>> = [
    {
      key: 'id',
      sortable: true,
      title: `${t('streamingPipelineDashboard.metrics.common.schemas')} (${schemas.length})`,
      as: SchemaButton,
      className: classes.SnapshottingTableExplorer__LeftColumn,
      mapValues: (row) => ({
        text: row.id,
        onClick: () => {
          onSchemaSelect(row.details)
        },
        'data-testid': `schema-button-${row.id}`
      })
    },
    {
      key: 'rowsScanned',
      sortable: true,
      title: `${t('streamingPipelineDashboard.metrics.snapshot.rowsScanned')} (${totalRowsScanned})`,
      as: Typography,
      className: classes.SnapshottingTableExplorer__RightColumn,
      mapValues: (row) => ({
        children: row.rowsScanned,
        format: 'bcs'
      })
    }
  ]

  const filteredSchemas = schemas.filter((schema) =>
    schema.schema.toLowerCase().includes(filter.toLowerCase())
  )
  const rows = filteredSchemas.map(
    (schema): SchemaRowsScannedGridRow => ({
      id: schema.schema,
      rowsScanned: schema.tables.reduce(
        (total, table) => total + table.rowsScanned,
        0
      ),
      details: schema
    })
  )

  return (
    <SortableDataGrid
      columns={columns}
      rows={rows}
      data-testid="snapshotting-schemas-listing"
      defaultSort={{ id: 'ASC' }}
    />
  )
}

export default SnapshottingTableExplorer
