import {
  useCallback,
  useEffect,
  useRef,
  useState,
  type FunctionComponent
} from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, type To } from 'react-router-dom'

import classNames from 'classnames'
import { useFormikContext } from 'formik'
import { capitalize } from 'lodash'

import { MatillionLink, Typography } from '@matillion/component-library'
import { heap, useLDClient } from '@matillion/hub-client'

import classes from 'components/Form/Form.module.scss'
import { Header, HeaderTitle } from 'components/Header'

import {
  AppRoutes,
  PROJECTS_CREDENTIALS,
  PROVISIONING_TRIAL_WAREHOUSE
} from 'constants/route'

import { useFlags } from 'hooks/flags'

import {
  Account,
  SnowflakeAccount,
  Username,
  WarehouseAgentCredentials
} from 'modules/Common/Fields'
import CredentialsType from 'modules/Common/Fields/CredentialsType/CredentialsType'
import { SnowflakeAccountHelp } from 'modules/Common/WarehouseSources/Snowflake/Credentials/SnowflakeAccountHelp'
import { initialValues } from 'modules/Onboarding/OnboardingForm'
import type { CreateProjectFormikValueTypes } from 'modules/Projects/CreateProject/CreateProjectForm'

import { Warehouse } from 'types'

interface SnowflakeCredentialsProps {
  onCancel?: () => void
  onPrevious?: () => void
  showCancel?: boolean
  showPrevious?: boolean
  modalContent?: string
  excludeHeader?: boolean
  isCreateEnvironment?: boolean
  keyPairEnabled?: boolean
}

export const SnowflakeCredentials: FunctionComponent<
  SnowflakeCredentialsProps
> = ({
  onCancel,
  onPrevious,
  showCancel = false,
  showPrevious = false,
  modalContent,
  excludeHeader = false,
  isCreateEnvironment = false,
  keyPairEnabled = false
}) => {
  const location = useLocation()
  const { t } = useTranslation()
  const {
    enableSnowflakeCredentialsScreenExperiment,
    enableKeyPairAuthentication
  } = useFlags()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const { values, setFieldTouched, setFieldValue } =
    useFormikContext<CreateProjectFormikValueTypes>()

  const firstMountRef = useRef(true)

  const resetKeypairFields = useCallback(() => {
    setFieldTouched('passphrase', false)
    setFieldTouched('secretValue', false)

    setFieldValue('passphrase', '')
    setFieldValue('secretValue', '')
  }, [setFieldTouched, setFieldValue])

  const resetPasswordFields = useCallback(() => {
    setFieldTouched('secretValue', false)
    setFieldValue('secretValue', '')
  }, [setFieldTouched, setFieldValue])

  useEffect(() => {
    if (firstMountRef.current) {
      firstMountRef.current = false
      return
    }
    if (values.credentialsType?.id !== 'key_pair') {
      resetKeypairFields()
    }
    if (values.credentialsType?.id !== 'password') {
      enableKeyPairAuthentication && resetPasswordFields()
    }
  }, [
    values.credentialsType,
    enableKeyPairAuthentication,
    resetKeypairFields,
    resetPasswordFields
  ])
  const isOnboardingCredentialsScreen = location.pathname.includes(
    `${AppRoutes.getOnboardingAdd()}/${PROJECTS_CREDENTIALS}`
  )
  const { setValues } = useFormikContext()
  const navigate = useNavigate()
  const { track: sendLDMetric, flush: flushLDMetrics } = useLDClient()

  useEffect(() => {
    heap.track('Snowflake Credentials Screen', {
      onboarding: location.pathname.includes(
        `${AppRoutes.getOnboardingAdd()}/${PROJECTS_CREDENTIALS}`
      )
    })
  }, [location.pathname])

  const handleSnowflakeModalClick = () => {
    setIsModalOpen(true)
  }

  const handleCloseModal = () => {
    setIsModalOpen(false)
  }

  const AccountComp = isOnboardingCredentialsScreen ? (
    <SnowflakeAccount handleSnowflakeModalClick={handleSnowflakeModalClick} />
  ) : (
    <Account />
  )

  const fields =
    enableKeyPairAuthentication && (isCreateEnvironment || keyPairEnabled) ? (
      <>
        {AccountComp}
        <CredentialsType />
        <Username />
        <WarehouseAgentCredentials
          withPrivateKey={values.credentialsType?.id === 'key_pair'}
        />
      </>
    ) : (
      <>
        {AccountComp}
        <Username />
        <WarehouseAgentCredentials />
      </>
    )

  const switchToTrial = async () => {
    sendLDMetric('snowflake-credentials-trial-link-click')
    await flushLDMetrics()
    await setValues(initialValues())
    navigate(
      `${AppRoutes.getOnboardingAdd()}/${PROVISIONING_TRIAL_WAREHOUSE}` as To,
      { replace: true }
    )
  }

  if (excludeHeader) {
    return fields
  }

  const title =
    enableKeyPairAuthentication && (isCreateEnvironment || keyPairEnabled)
      ? t('formContent.credentials.altTitle')
      : t('formContent.credentials.title', {
          projectType: capitalize(Warehouse.Snowflake)
        })

  return (
    <>
      <Header
        onCancel={onCancel}
        showCancel={showCancel}
        onPrevious={onPrevious}
        showPrevious={showPrevious}
        modalContent={modalContent}
      >
        <HeaderTitle data-testid="specify-credentials-title">
          {title}
        </HeaderTitle>
        {enableSnowflakeCredentialsScreenExperiment &&
          isOnboardingCredentialsScreen && (
            <MatillionLink
              as="a"
              className={classes.Form__TrialLinkText}
              data-testid="snowflake-credentials-screen-trial-warehouse-link"
              data-tracker-id="snowflake-credentials-screen-trial-warehouse-link"
              onClick={switchToTrial}
            >
              <Typography format="bcs">
                {t('formContent.credentials.trialLinkText')} &gt;
              </Typography>
            </MatillionLink>
          )}
      </Header>
      <div
        className={classNames(
          classes.Form__InnerWrap,
          classes['Form__InnerWrap--space-top']
        )}
      >
        {fields}
      </div>
      {isModalOpen && (
        <SnowflakeAccountHelp handleCloseModal={handleCloseModal} />
      )}
    </>
  )
}
