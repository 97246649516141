import { type TFunction } from 'react-i18next'

import { type FieldMetaProps } from 'formik'
import { object, string, type InferType } from 'yup'

export const resolveErrorText = (
  t: TFunction,
  meta: FieldMetaProps<unknown>
) => (meta.touched && meta.error ? t(meta.error) : undefined)

export const formSchema = object({
  branchName: string()
    .required('addBranch.fields.branchName.error.required')
    .max(60, 'addBranch.fields.branchName.error.max'),
  branchFrom: string().required('addBranch.fields.branchFrom.error.required'),
  environment: object({
    id: string().required('commonForm.fields.environment.error.required'),
    name: string().required('commonForm.fields.environment.error.required')
  })
    .required('commonForm.fields.environment.error.unmatched')
    .nullable()
})

export type FormValues = InferType<typeof formSchema>

export const initialValues: FormValues = {
  branchName: '',
  branchFrom: '',
  environment: {
    id: '',
    name: ''
  }
}
