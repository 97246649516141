import { useState, type FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'

import { partial } from 'lodash'

import { DataGrid, type DataGridProps } from '@matillion/component-library'

import type {
  GETSecretReferencesResponse,
  GETSecretReferencesResponseParams
} from 'api/types/SecretReferencesResponse'

import SecretDefinitionDetailsModal from 'modules/Secrets/SecretDefinitionDetailsModal'
import classes from 'modules/Secrets/SecretsDefinitionListing/SecretDefinitionListing.module.scss'
import { ColumnKeys } from 'modules/Secrets/utils'

import ActionCell from './ActionCell'
import DescriptionCell from './DescriptionCell'
import NameCell from './NameCell'

interface SecretDefinitionDataGridProps {
  secrets: GETSecretReferencesResponse
  onSort: DataGridProps<GETSecretReferencesResponseParams>['onSort']
  onDeleteHandler: (secretName: string, isSuccess?: boolean) => void
}

const SecretDefinitionDataGrid: FunctionComponent<
  SecretDefinitionDataGridProps
> = ({ secrets, onSort, onDeleteHandler }) => {
  const { t } = useTranslation()
  const [secretDetails, setSecretDetails] =
    useState<GETSecretReferencesResponseParams | null>(null)

  return (
    <>
      <DataGrid
        className={classes.SecretDefinitionListing__Grid}
        rowClassName={classes.SecretDefinitionListing__GridRow}
        onSort={onSort}
        rows={secrets}
        defaultSort={{ name: 'ASC' }}
        columns={[
          {
            key: ColumnKeys.name,
            title: t('secrets.secretDefinitionListing.column.col1'),
            as: NameCell,
            mapValues: (secret) => ({
              name: secret.name,
              onNameClick: partial(setSecretDetails, secret)
            }),
            sortable: true,
            className: classes['SecretDefinitionListing__GridCell--name']
          },
          {
            key: ColumnKeys.description,
            title: t('secrets.secretDefinitionListing.column.col2'),
            as: DescriptionCell,
            mapValues: ({ description }) => ({ description }),
            sortable: true,
            className: classes['SecretDefinitionListing__GridCell--description']
          },
          {
            key: ColumnKeys.actions,
            title: t('secrets.secretDefinitionListing.column.col3'),
            as: ActionCell,
            mapValues: (secret) => ({
              secret,
              onSecretDetailsHandler: partial(setSecretDetails, secret),
              onDeleteHandler
            }),
            className: classes['SecretDefinitionListing__GridCell--actions']
          }
        ]}
        data-testid="secret-definition-data-grid"
      />

      {!!secretDetails && (
        <SecretDefinitionDetailsModal
          secret={secretDetails}
          onCancel={() => {
            setSecretDetails(null)
          }}
        />
      )}
    </>
  )
}

export default SecretDefinitionDataGrid
