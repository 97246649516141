import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

import { Toaster } from '@matillion/component-library'

import { useGetSchedules } from 'api/hooks'

import { AppRoutes, PROJECT_DETAILS_SCHEDULES } from 'constants/route'

import { useCheckScheduleSubmitDateTime } from 'hooks'

import type { ScheduleFormValues } from 'modules/Projects/CreateSchedule/types'
import type { EditScheduleFormValues } from 'modules/Projects/EditSchedule/types'

export const useHandleScheduleSubmit = <
  T extends EditScheduleFormValues | ScheduleFormValues
>() => {
  const { t } = useTranslation()
  const { projectId } = useParams()
  const { makeToast, clearToasts } = Toaster.useToaster()
  const navigate = useNavigate()

  const [isScheduleDateTimeStale] = useCheckScheduleSubmitDateTime()
  const { refetch } = useGetSchedules(projectId!, { enabled: false })

  const refetchAndRedirectToSchedulesListOnSuccess = () => {
    refetch()

    navigate(
      AppRoutes.getProjectDetails(projectId!, PROJECT_DETAILS_SCHEDULES),
      {
        replace: true
      }
    )
  }

  /**
   *
   * @param {string} formValues - The new values to submit the form with
   * @param {PromiseLike} publishSchedule - The call to make to create/edit the schedule
   * @returns {PromiseLike<void>}
   */

  const submitForm = async (
    formValues: T,
    publishSchedule: (
      formValues: T,
      onPublishStatus: (success: boolean, scheduleName?: string) => void,
      onFinish: () => void
    ) => Promise<void>,
    translationPrefix: string
  ) => {
    clearToasts()
    const isDateTimeStale = isScheduleDateTimeStale({
      startDate: formValues.schedule.startDate,
      startTime: formValues.schedule.startTime,
      offset: formValues.schedule.timezone.utc
    })

    if (isDateTimeStale) return

    await publishSchedule(
      formValues,
      (success, scheduleName) => {
        if (success) {
          makeToast({
            title: t(`${translationPrefix}.responseMessage.success`, {
              scheduleName
            }),
            message: '',
            type: 'success'
          })

          refetchAndRedirectToSchedulesListOnSuccess()
          return
        }

        makeToast({
          title: t(`${translationPrefix}.responseMessage.error`),
          message: '',
          type: 'error'
        })
      },
      () => null
    )
  }

  return [submitForm]
}
