import { type FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'

import classNames from 'classnames'
import { useFormikContext } from 'formik'

import classes from 'components/Form/Form.module.scss'
import { Header, HeaderTitle } from 'components/Header'

import { useFlags } from 'hooks/flags'

import {
  EnvironmentAgent,
  EnvironmentDefaultAccess,
  EnvironmentName
} from 'modules/Common/Fields'

import { AgentsSecretsHost } from 'types'
import { type CommonFormI } from 'types/FormTypes'

interface CreateEnvironmentProps {
  onCancel?: () => void
  onPrevious?: () => void
  showCancel?: boolean
  showPrevious?: boolean
  modalContent?: string
}

const CreateEnvironment: FunctionComponent<CreateEnvironmentProps> = ({
  onCancel,
  onPrevious,
  showCancel = false,
  showPrevious = false,
  modalContent
}) => {
  const { t } = useTranslation()
  const { values } = useFormikContext<CommonFormI>()
  const { agentsSecretsManagement } = values
  const { enableEnvironmentDefaultAccess } = useFlags()

  return (
    <>
      <Header
        onCancel={onCancel}
        showCancel={showCancel}
        onPrevious={onPrevious}
        showPrevious={showPrevious}
        modalContent={modalContent}
      >
        <HeaderTitle data-testid="create-environment-title">
          {t('formContent.environment.title')}
        </HeaderTitle>
      </Header>
      <div
        className={classNames(
          classes.Form__InnerWrap,
          classes['Form__InnerWrap--space-top']
        )}
      >
        <EnvironmentName data-testid="create-environment-environment-name" />
        {agentsSecretsManagement === AgentsSecretsHost.CustomerHosted && (
          <EnvironmentAgent data-testid="create-environment-etl-agent" />
        )}
        {enableEnvironmentDefaultAccess && (
          <EnvironmentDefaultAccess data-testid="create-environment-environment-default-access" />
        )}
      </div>
    </>
  )
}

export default CreateEnvironment
