import { type FunctionComponent } from 'react'

import classes from 'modules/Projects/StreamingPipelineDashboard/Events/StreamingDashboardEvents/StreamingDashboardEvents.module.scss'
import StreamingEventsHeader from 'modules/Projects/StreamingPipelineDashboard/Events/StreamingEventsHeader/StreamingEventsHeader'
import StreamingEventsListing from 'modules/Projects/StreamingPipelineDashboard/Events/StreamingEventsListing/StreamingEventsListing'

interface StreamingDashboardEventsProps {
  projectId: string
  pipelineId: string
}

const StreamingDashboardEvents: FunctionComponent<
  StreamingDashboardEventsProps
> = ({ projectId, pipelineId }) => {
  return (
    <div className={classes.StreamingDashboardEvents__Container}>
      <StreamingEventsHeader pipelineId={pipelineId} projectId={projectId} />
      <StreamingEventsListing pipelineId={pipelineId} projectId={projectId} />
    </div>
  )
}

export default StreamingDashboardEvents
