import {
  createContext,
  useContext,
  type Dispatch,
  type SetStateAction
} from 'react'

import { noop } from 'lodash'

import { type OAuthSchema } from 'api/types'

import { type GenericSchemaType } from 'types'

interface CreateOAuthContextType {
  validationSchema?: GenericSchemaType
  setValidationSchema: (schema: GenericSchemaType) => void
  selectedOAuth?: OAuthSchema
  setSelectedOAuth: Dispatch<SetStateAction<OAuthSchema | undefined>>
}

export const CreateOAuthContext = createContext<CreateOAuthContextType>({
  validationSchema: undefined,
  setValidationSchema: noop,
  selectedOAuth: undefined,
  setSelectedOAuth: noop
})

export const useCreateOAuthContext = (context = CreateOAuthContext) =>
  useContext(context)
