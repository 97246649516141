import { useEffect, useState, type FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'

import classNames from 'classnames'
import { formatInTimeZone } from 'date-fns-tz'

import {
  Button,
  DataGrid,
  LoadingSpinner,
  Toaster,
  Typography,
  type SortState
} from '@matillion/component-library'

import {
  useCheckPermissions,
  useGetSchedules,
  useNetworkErrorToast
} from 'api/hooks'

import { Header, HeaderTitle, HeaderWithCTA } from 'components/Header'

import { QueryKey } from 'constants/endpoint'
import { AppRoutes } from 'constants/route'

import { useFlags } from 'hooks/flags'

import { PermissionsType } from 'types'
import { ResourceType } from 'types/ResourceTypes'

import { ColumnKeys, type SortedScheduleDataTypes, type SortTypes } from '.'
import ActionCell from './components/ActionCell'
import { EnableScheduleToggle } from './components/EnableScheduleToggle'
import NoCreatePermission from './components/NoCreatePermission'
import NoData from './components/NoData'
import NoReadPermission from './components/NoReadPermission'
import classes from './SchedulesListing.module.scss'
import { schedulesSort } from './schedulesListing.util'

const NO_DATE_AVAILABLE = '-'
const SchedulesListing: FunctionComponent = () => {
  const [sortState, setSortState] = useState<Partial<SortState<SortTypes>>>({
    name: 'ASC'
  })

  const { projectId } = useParams()
  const navigate = useNavigate()
  const makeErrorToast = useNetworkErrorToast()
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const { enableScheduleToggle } = useFlags()

  const {
    data: schedulesData = [],
    error: schedulesError,
    isError: isSchedulesError,
    isLoading: isSchedulesLoading
  } = useGetSchedules(projectId!)

  const { data: createPermission, isLoading: isCreatePermissionLoading } =
    useCheckPermissions({
      resourceType: ResourceType.Project,
      resourceId: projectId!,
      permission: PermissionsType.CreateSchedules
    })
  const { data: viewPermission, isLoading: isViewPermissionLoading } =
    useCheckPermissions({
      resourceType: ResourceType.Project,
      resourceId: projectId!,
      permission: PermissionsType.ViewSchedules
    })

  const { clearToasts } = Toaster.useToaster()

  if (sortState) {
    const schedulesSortFunction = schedulesSort(sortState)
    schedulesData.sort(schedulesSortFunction)
  }

  const clickAddScheduleHandler: VoidFunction = () => {
    clearToasts()
    navigate(AppRoutes.getScheduleAdd(projectId!))
  }

  const dateFormat = (date: string) => {
    return date !== NO_DATE_AVAILABLE
      ? formatInTimeZone(date, 'UTC', 'HH:mm b dd/MM/yyyy')
      : date
  }

  const getDataGridContent = () => {
    return (
      <DataGrid
        className={classNames(classes.SchedulesListing__Grid)}
        rowClassName={classNames(classes.SchedulesListing__GridRow)}
        data-testid="schedules-data-grid"
        aria-label="Schedules Data Grid"
        defaultSort={{ name: 'ASC' }}
        columns={[
          {
            key: ColumnKeys.name,
            title: t('schedulesListing.column.col1'),
            as: Typography,
            className: classNames(classes.SchedulesListing__GridCell),
            mapValues: (row) => {
              return {
                children: row.name
              }
            },
            sortable: true
          },
          {
            key: ColumnKeys.jobName,
            title: t('schedulesListing.column.col2'),
            as: Typography,
            className: classNames(classes.SchedulesListing__GridCell),
            mapValues: (row) => ({
              children: row.job
            }),
            sortable: true
          },
          {
            key: ColumnKeys.schedule,
            title: t('schedulesListing.column.col3'),
            as: Typography,
            className: classNames(classes.SchedulesListing__GridCell),
            mapValues: (row) => ({
              children: row.schedule
            }),
            sortable: true
          },
          {
            key: ColumnKeys.lastRun,
            title: t('schedulesListing.column.col4'),
            as: Typography,
            className: classNames(classes.SchedulesListing__GridCell),
            mapValues: (row) => ({
              children: dateFormat(row.lastRun)
            }),
            sortable: true
          },
          {
            key: ColumnKeys.nextRun,
            title: t('schedulesListing.column.col5'),
            as: Typography,
            className: classNames(classes.SchedulesListing__GridCell),
            mapValues: (row) => ({
              children: dateFormat(row.nextRun)
            }),
            sortable: true
          },
          {
            key: ColumnKeys.environment,
            title: t('schedulesListing.column.col6'),
            as: Typography,
            className: classNames(classes.SchedulesListing__GridCell),
            mapValues: (row) => ({
              children: row.environment
            }),
            sortable: true
          },
          ...(enableScheduleToggle
            ? [
                {
                  key: 'scheduleEnabled',
                  title: t('schedulesListing.column.col7'),
                  as: Typography,
                  className: classNames(classes.SchedulesListing__GridCell),
                  mapValues: (row: SortedScheduleDataTypes) => {
                    return {
                      children: <EnableScheduleToggle row={row} />
                    }
                  },
                  sortable: false
                }
              ]
            : []),
          {
            key: ColumnKeys.action,
            title: t('schedulesListing.column.col8'),
            as: ActionCell,
            className: classNames(
              classes.SchedulesListing__GridCell,
              classes['SchedulesListing__GridCell--action']
            ),
            mapValues: (row) => ({
              projectId: projectId!,
              item: row
            }),
            sortable: false
          }
        ]}
        rows={schedulesData as SortedScheduleDataTypes[]}
        onSort={setSortState}
      />
    )
  }

  const getErrorContent = () => {
    return (
      <div className={classNames(classes.SchedulesListing__ErrorInfo)}>
        <Typography format="bcm">{t('schedulesListing.error')}</Typography>
      </div>
    )
  }

  const getInnerContent = () => {
    return isSchedulesError ? getErrorContent() : getDataGridContent()
  }

  useEffect(() => {
    if (isSchedulesError) {
      makeErrorToast({ message: schedulesError.message })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [schedulesError, isSchedulesError])

  useEffect(() => {
    const schedulesSortFunction = schedulesSort({ name: 'ASC' })
    schedulesData.sort(schedulesSortFunction)

    return () => {
      queryClient.removeQueries([
        QueryKey.USER_PERMISSIONS,
        PermissionsType.DeleteSchedules
      ])
      queryClient.removeQueries([
        QueryKey.USER_PERMISSIONS,
        PermissionsType.EditSchedules
      ])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (
    !schedulesData.length &&
    !isSchedulesError &&
    !isSchedulesLoading &&
    !isViewPermissionLoading &&
    !isCreatePermissionLoading
  ) {
    if (!createPermission) {
      return <NoCreatePermission />
    } else {
      return <NoData onAddHandler={clickAddScheduleHandler} />
    }
  }

  return (
    <>
      <Header fullWidth centerAlign={false}>
        <HeaderWithCTA>
          <HeaderTitle
            data-testid="schedules-title"
            centerAlign={false}
            headerElement="h2"
            format="tm"
            className={classes.SchedulesListing__Header}
          >
            {t('schedulesListing.title')}
          </HeaderTitle>
          {createPermission && (
            <Button
              text={t('schedulesListing.buttonText')}
              onClick={clickAddScheduleHandler}
              data-testid="add-schedule-button"
              className={classNames(classes.SchedulesListing__AddButton)}
              data-tracker="pe_start-create-schedule-cta_click"
              alt="positive"
            />
          )}
        </HeaderWithCTA>
        <Typography as="p" data-testid="schedules-support-text">
          {t('schedulesListing.supportText')}
        </Typography>
      </Header>
      {isSchedulesLoading ||
      isViewPermissionLoading ||
      (isCreatePermissionLoading && !schedulesData.length) ? (
        <LoadingSpinner />
      ) : (
        <>{!viewPermission ? <NoReadPermission /> : getInnerContent()}</>
      )}
    </>
  )
}

export default SchedulesListing
