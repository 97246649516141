/* eslint-disable @typescript-eslint/no-explicit-any */

import type { i18n as i18nI } from 'i18next'
import { upperFirst } from 'lodash'

import { type AutoCompleteItemId } from '@matillion/component-library'

import { type OAuthSchema } from 'api/types'

// remove blank property from object
export const removeFalsyStringProperties = (obj: any) => {
  return Object.fromEntries(
    Object.entries(obj).filter(
      ([, v]) =>
        !(
          (typeof v === 'string' && !v.length) ||
          v === null ||
          typeof v === 'undefined' ||
          (typeof v === 'object' && (v as { [key: string]: any }).id === '')
        )
    )
  )
}

export const getProviderList = (
  wholeSchema: OAuthSchema[],
  oauthAllowedProviders: string[],
  t: (arg: string, options?: object) => string,
  i18n: i18nI
): AutoCompleteItemId[] => {
  return wholeSchema
    .filter(
      (x) =>
        oauthAllowedProviders.includes(x.provider.toLowerCase()) ||
        x.provider.endsWith('(Custom)')
    )
    .map((o) => {
      const i18nKey = `secrets.createOAuth.providers.${o.resourceId}`
      let title = t(i18nKey)

      if (!i18n.exists(i18nKey)) {
        title = o.provider.endsWith('(Custom)')
          ? o.provider
          : upperFirst(o.provider)
      }

      return {
        id: o.provider,
        name: title
      }
    })
    .sort((a, b) => a.name.localeCompare(b.name))
}

export const getOAuthTypeList = (
  oAuthTypeSchema: OAuthSchema,
  allowedAuthTypes: string[],
  t: (arg: string, options?: object) => string,
  i18n: i18nI
): AutoCompleteItemId[] => {
  return oAuthTypeSchema.authTypes
    .filter((authType) => allowedAuthTypes.includes(authType.type))
    .map((x) => {
      const i18nKey = `secrets.createOAuth.authTypes.${x.resourceId}`
      let title = t(i18nKey)

      if (!i18n.exists(i18nKey) && x.defaultName) {
        title = upperFirst(x.defaultName)
      }

      return {
        id: x.type,
        name: title
      }
    })
}
