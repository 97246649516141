import { useMemo, type FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'

import { Field as FormikField, useFormikContext, type FieldProps } from 'formik'

import {
  AutoComplete,
  Field,
  type AutoCompleteItem
} from '@matillion/component-library'
import { useUser } from '@matillion/hub-client'

import { type GETAccountMembersResponseParams } from 'api/types'

import classes from 'modules/Projects/AccessListing/AddUser/AddUser.module.scss'
import { resolveErrorText } from 'modules/Projects/AccessListing/AddUser/AddUser.util'
import { type UserFieldProps } from 'modules/Projects/AccessListing/AddUser/types'

const User: FunctionComponent<UserFieldProps> = ({
  accountMembers,
  projectMembers,
  isError,
  isLoading
}) => {
  const { user: currentUser } = useUser()
  const { t } = useTranslation()

  const formatNameAndEmail = (x: GETAccountMembersResponseParams) => {
    const firstName = x.firstName ? `${x.firstName.trim()} ` : ''
    const lastName = x.lastName ? `${x.lastName.trim()} ` : ''
    const email = x.email

    if (firstName || lastName) {
      return `${firstName}${lastName}(${email})`
    } else {
      return email
    }
  }

  const availableMembers = accountMembers
    .filter(
      (accountMember) =>
        accountMember.email !== currentUser.email &&
        !projectMembers.some(
          (projectMember) => projectMember.id === accountMember.id
        )
    )
    .map((x: GETAccountMembersResponseParams) => ({
      id: x.id,
      name: formatNameAndEmail(x)
    }))
    .sort((a, b) => a.name.localeCompare(b.name))

  const { getFieldMeta } = useFormikContext()
  const fieldMeta = getFieldMeta('user')
  const noAvailableMembers = availableMembers.length === 0

  const getErrorMessage = useMemo(() => {
    if (isError) {
      return t('accessListing.addUser.fields.user.error.loadingError')
    }

    if (!accountMembers.length && !isLoading) {
      return t('accessListing.addUser.fields.user.error.noItemsFound')
    }

    if (noAvailableMembers && !isLoading) {
      return t('accessListing.addUser.fields.user.error.allAccountMembersAdded')
    }

    return resolveErrorText(t, fieldMeta)
  }, [accountMembers, fieldMeta, t, isError, isLoading, noAvailableMembers])

  return (
    <FormikField name="user">
      {({ field, meta, form: { setFieldValue } }: FieldProps<string>) => (
        <div data-testid="select-user">
          <Field
            {...field}
            data-testid="select-user-input"
            className={classes['AddUser__Field--normal']}
            title={t('accessListing.addUser.fields.user.title')}
            name="user"
            placeholder={t('accessListing.addUser.fields.user.placeholderText')}
            inputComponent={AutoComplete}
            loading={isLoading}
            availableItems={availableMembers}
            errorText={getErrorMessage}
            value={field.value || null}
            onChange={async (e: { target: AutoCompleteItem }) =>
              setFieldValue('user', e.target.value?.id)
            }
            disabled={noAvailableMembers && !isLoading}
          />
        </div>
      )}
    </FormikField>
  )
}

export default User
