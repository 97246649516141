import { useMemo, type ChangeEvent, type FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'

import classNames from 'classnames'
import { useFormikContext } from 'formik'

import { Field } from '@matillion/component-library'

import classes from 'components/Form/Form.module.scss'

import { useFormikFieldSpacesToHyphens } from 'hooks'

import { renderFormikError } from 'modules/utils'

import { type OAuthFormI } from 'types/FormTypes'

export const Name: FunctionComponent = () => {
  const { errors, handleBlur, handleChange, touched, values } =
    useFormikContext<OAuthFormI>()
  const { t } = useTranslation()
  const fieldName = 'name'
  const spacesToHyphens = useFormikFieldSpacesToHyphens({ fieldName })

  const formikError = renderFormikError(errors.name, Boolean(touched.name))

  const getErrorMessage = useMemo(() => {
    if (formikError) {
      return t(formikError, { oauthName: values.name })
    }

    return ''
  }, [formikError, t, values.name])

  const handleFieldChange = (e: ChangeEvent<HTMLInputElement>) => {
    handleChange(e)
    spacesToHyphens(e)
  }

  return (
    <div data-testid="create-oauth-name">
      <Field
        title={t('secrets.createOAuth.fields.name.title')}
        name={fieldName}
        data-testid="create-oauth-name-input"
        value={values.name}
        placeholder={t('secrets.createOAuth.fields.name.placeholderText')}
        onChange={handleFieldChange}
        onBlur={handleBlur}
        className={classNames(classes.Form__SpacingStyles)}
        supportText={t('secrets.createOAuth.fields.name.supportText')}
        errorText={getErrorMessage}
        hasError={Boolean(errors.name) && Boolean(touched.name)}
        required
      />
    </div>
  )
}
