import type { FieldMetaProps, FormikValues } from 'formik'
import { get, isEmpty } from 'lodash'

export const hasError = (
  values: FormikValues,
  meta: FieldMetaProps<string>,
  highlightIfEmpty: boolean,
  name?: string
) => {
  if (!!meta.error && meta.touched) {
    return true
  } else if (highlightIfEmpty && name) {
    const value = get(values, name)
    return isEmpty(value) || value.id === ''
  } else {
    return false
  }
}
