import { useState, type ChangeEvent, type FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'

import {
  Button,
  DataGrid,
  Icon,
  Input,
  Modal,
  type DataGridColumnProps,
  type DataGridRow
} from '@matillion/component-library'

import { ModalContent, ModalHeading } from 'components/Modal'

import { DataGridAddRemoveButtons } from 'modules/Projects/CreateStreamingPipeline/FormContent/ConfigurePipelineDetailsPage/Components/Properties/DataGridAddRemoveButtons/DataGridAddRemoveButtons'

import classes from './ParameterValuePicker.module.scss'

export interface PropertyGridRow extends DataGridRow {
  id: number
  parameter: string
  value: string
}

interface ParameterValuePickerProps {
  onCancel: () => void
  onSave: () => void
  headingText: string
  rows: PropertyGridRow[]
  setRows: (rows: PropertyGridRow[]) => void
}

export const ParameterValuePicker: FunctionComponent<
  ParameterValuePickerProps
> = ({ onCancel, onSave, headingText, rows, setRows }) => {
  const { t } = useTranslation()

  const [selectedRows, setSelectedRows] = useState<number[]>([])

  const columns: Array<DataGridColumnProps<PropertyGridRow>> = [
    {
      key: 'parameter',
      sortable: false,
      title: 'Parameter',
      as: Input,
      className: classes.ParameterValuePicker__Input,
      headerClassName: classes.ParameterValuePicker__Header,
      mapValues: (value) => ({
        onChange: (e: ChangeEvent<HTMLInputElement>) => {
          setRows(
            rows.map((row) => {
              if (row.id === value.id) {
                return { ...row, parameter: e.target.value }
              }
              return row
            })
          )
        },
        value: value.parameter,
        placeholder: 'Enter parameter',
        iconAfter: {
          icon: <Icon.Pencil className={classes.ParameterValuePicker__Icon} />
        },
        'data-testid': 'parameter-value-input'
      })
    },
    {
      title: 'Value',
      key: 'value',
      sortable: false,
      as: Input,
      className: classes.ParameterValuePicker__Input,
      headerClassName: classes.ParameterValuePicker__Header,
      mapValues: (value) => ({
        onChange: (e: ChangeEvent<HTMLInputElement>) => {
          setRows(
            rows.map((row) => {
              if (row.id === value.id) {
                return { ...row, value: e.target.value }
              }
              return row
            })
          )
        },
        value: value.value,
        placeholder: 'Enter value',
        iconAfter: {
          icon: <Icon.Pencil className={classes.ParameterValuePicker__Icon} />
        },
        'data-testid': 'parameter-value-input'
      })
    }
  ]

  return (
    <Modal
      onCancel={onCancel}
      ariaLabelledBy="modal-title"
      size="large"
      data-testid="parameter-value-modal"
      className={classes.ParameterValuePicker__Modal}
    >
      <ModalHeading>{headingText}</ModalHeading>
      <ModalContent>
        <div className={classes.ParameterValuePicker__Wrapper}>
          <DataGrid
            data-testid="parameter-value-table"
            columns={columns}
            rows={rows}
            className={classes.ParameterValuePicker}
            selectedRows={selectedRows}
            rowClassName={classes.ParameterValuePicker__Row}
            isSelectAll={selectedRows.length === rows.length && rows.length > 0}
            isSelectable
            allSelectable
            compact
            hasFixedHeader
            onSelectedChange={(rowId, isSelected) => {
              if (isSelected) {
                setSelectedRows([...selectedRows, +rowId])
              } else {
                setSelectedRows(selectedRows.filter((id) => id !== rowId))
              }
            }}
            onSelectAllChange={(selectAllChecked: boolean) => {
              if (selectAllChecked) {
                setSelectedRows(rows.map((row) => row.id))
              } else {
                setSelectedRows([])
              }
            }}
          />
        </div>
        <DataGridAddRemoveButtons
          add={{
            onClick: () => {
              setRows([
                ...rows,
                {
                  id: Math.max(...rows.map((row) => row.id)) + 1,
                  parameter: '',
                  value: ''
                }
              ])
            }
          }}
          remove={{
            disabled: rows.length === 0 || selectedRows.length === 0,
            onClick: () => {
              setRows(rows.filter((row) => !selectedRows.includes(row.id)))
              setSelectedRows([])
            }
          }}
        />
        <div className={classes.ParameterValuePicker__ButtonWrapper}>
          <Button
            alt="secondary"
            onClick={() => {
              onCancel()
            }}
            text={t('createStreamingPipeline.parameterValuePicker.cancel')}
            data-testid="cancel-parameters-button"
          />
          <Button
            onClick={() => {
              onSave()
            }}
            text={t('createStreamingPipeline.parameterValuePicker.save')}
            data-testid="save-parameters-button"
          />
        </div>
      </ModalContent>
    </Modal>
  )
}
