import { type FormikValues } from 'formik'
import { isEmpty } from 'lodash'

import { AutoComplete, Checkbox, Input } from '@matillion/component-library'
import { type AutoCompleteItemId } from '@matillion/component-library/dist/components/AutoComplete/types'

import type { PanelGroupingConfig } from 'components/PanelGroup'

import {
  formHasDatabaseProperty,
  formHasPdbProperty,
  SnowflakeMetadataLoader,
  type FormMetadata,
  type FormValues
} from 'modules/Projects/CreateStreamingPipeline/FormContent'
import { AdvancedProperties } from 'modules/Projects/CreateStreamingPipeline/FormContent/ConfigurePipelineDetailsPage/Components/Properties/AdvancedProperties/AdvancedProperties'
import { SnowflakeJdbcParameters } from 'modules/Projects/CreateStreamingPipeline/FormContent/ConfigurePipelineDetailsPage/Components/Properties/SnowflakeJdbcParameters/SnowflakeJdbcParameters'
import { SourceJdbcParameters } from 'modules/Projects/CreateStreamingPipeline/FormContent/ConfigurePipelineDetailsPage/Components/Properties/SourceJdbcParameters/SourceJdbcParameters'
import { RecommendedDocumentation } from 'modules/Projects/CreateStreamingPipeline/FormContent/ConfigurePipelineDetailsPage/Components/RecommendedDocumentation/RecommendedDocumentation'
import { SnapshotConfigHeader } from 'modules/Projects/CreateStreamingPipeline/FormContent/ConfigurePipelineDetailsPage/Components/SnapshotConfigHeader/SnapshotConfigHeader'
import { SnowflakeAccount } from 'modules/Projects/CreateStreamingPipeline/FormContent/ConfigurePipelineDetailsPage/Components/SnowflakeAccount'
import { StreamingDropdown } from 'modules/Projects/CreateStreamingPipeline/FormContent/StreamingDropdown'

import {
  SECRET_MANAGERS,
  STREAMING_DESTINATIONS,
  STREAMING_SOURCES,
  StreamingDestinationId,
  StreamingSourceId,
  TABLE_PREFIX_TYPES,
  TEMPORAL_MAPPINGS,
  TRANSFORMATION_TYPES
} from 'types/Streaming'

import {
  DestinationConnectButton,
  HostAndPort,
  SourceConnectButton,
  ValidationErrors
} from './Components'
import {
  DestinationDisconnectedAlert,
  JdbcParametersAlert,
  SourceDisconnectedAlert,
  StageConfigAlert
} from './Components/Alerts'
import { DestinationConfigContinueButton } from './Components/DestinationConfigContinueButton'
import { PipelineConfigContinueButton } from './Components/PipelineConfigContinueButton'
import {
  SignalTableInput,
  TableSelectionList
} from './Components/TableSelection'
import type { MetadataPropertyValue } from './ConfigurePipelineDetailsPage'
import {
  FIELD_NAMES_AZURE_BLOB_ACCOUNT_KEY_SECRET_MANAGER,
  FIELD_NAMES_AZURE_BLOB_ACCOUNT_KEY_SECRET_NAME,
  FIELD_NAMES_AZURE_BLOB_ACCOUNT_NAME,
  FIELD_NAMES_AZURE_BLOB_CONTAINER,
  FIELD_NAMES_INITIAL_SNAPSHOT,
  FIELD_NAMES_ON_DEMAND_SNAPSHOT,
  FIELD_NAMES_S3_BUCKET,
  FIELD_NAMES_SNOWFLAKE_DATABASE,
  FIELD_NAMES_SNOWFLAKE_ROLE,
  FIELD_NAMES_SNOWFLAKE_SECRET_MANAGER,
  FIELD_NAMES_SNOWFLAKE_SECRET_NAME,
  FIELD_NAMES_SNOWFLAKE_STAGE_NAME,
  FIELD_NAMES_SNOWFLAKE_STAGE_SCHEMA,
  FIELD_NAMES_SNOWFLAKE_STAGING_PREFIX,
  FIELD_NAMES_SNOWFLAKE_TABLE_PREFIX_TYPE,
  FIELD_NAMES_SNOWFLAKE_TABLE_SCHEMA,
  FIELD_NAMES_SNOWFLAKE_USER,
  FIELD_NAMES_SNOWFLAKE_WAREHOUSE,
  FIELD_NAMES_SOURCE_DATABASE_NAME,
  FIELD_NAMES_SOURCE_PDB,
  FIELD_NAMES_SOURCE_READ_REPLICA,
  FIELD_NAMES_SOURCE_SECRET_MANAGER,
  FIELD_NAMES_SOURCE_SECRET_NAME,
  FIELD_NAMES_SOURCE_USER,
  FIELD_NAMES_TARGET_PREFIX,
  FIELD_NAMES_TEMPORAL_MAPPING,
  FIELD_NAMES_TRANSFORMATION_TYPE
} from './types'

const getDropdownPlaceholder = ({
  loading,
  data
}: {
  loading: boolean
  data: AutoCompleteItemId[]
}) => {
  if (loading) {
    return 'createStreamingPipeline.fields.dropdownLoadingPlaceholder'
  } else if (data.length === 0) {
    return undefined
  } else {
    return 'createStreamingPipeline.fields.dropdownPlaceholder'
  }
}

const getSnowflakeConnectionFields = (
  formValues: FormValues,
  metadata: FormMetadata,
  setMetadataProperty: (
    propertyPath: string,
    value: MetadataPropertyValue
  ) => void
) => {
  return [
    {
      type: RecommendedDocumentation,
      wrapInField: false,
      dataTestId: 'configure-streaming-pipeline-target-documentation',
      subject: formValues.target.type.name,
      documentationLink:
        STREAMING_DESTINATIONS[formValues.target.type.id].docs.overview
    },
    {
      type: SnowflakeAccount,
      dataTestId: 'configure-streaming-pipeline-snowflake-account',
      wrapInField: false
    },
    {
      type: Input,
      name: FIELD_NAMES_SNOWFLAKE_USER,
      title:
        'createStreamingPipeline.fields.destinationConfig.snowflake.user.title',
      dataTestId: 'configure-streaming-pipeline-snowflake-user'
    },
    {
      type: StreamingDropdown,
      wrapInField: false,
      name: FIELD_NAMES_SNOWFLAKE_SECRET_MANAGER,
      title:
        'createStreamingPipeline.fields.destinationConfig.snowflake.secretManager.title',
      supportText:
        'createStreamingPipeline.fields.destinationConfig.snowflake.secretManager.supportText',
      dataTestId: 'configure-streaming-pipeline-snowflake-secret-manager',
      items: Object.values(SECRET_MANAGERS)
    },
    {
      type: Input,
      name: FIELD_NAMES_SNOWFLAKE_SECRET_NAME,
      title:
        'createStreamingPipeline.fields.destinationConfig.snowflake.secretName.title',
      supportText:
        'createStreamingPipeline.fields.destinationConfig.snowflake.secretName.supportText',
      dataTestId: 'configure-streaming-pipeline-snowflake-secret-name'
    },
    {
      type: SnowflakeJdbcParameters,
      wrapInField: false,
      dataTestId: 'configure-streaming-pipeline-snowflake-jdbc-properties'
    },
    {
      type: DestinationConnectButton,
      wrapInField: false,
      dataTestId: 'destination-connect-button',
      setIsError: (isError: boolean) => {
        setMetadataProperty('validation.targetConnection.isError', isError)
      },
      setErrorMessage: (message: string | null) => {
        setMetadataProperty('validation.targetConnection.errorMessage', message)
      }
    },
    {
      type: ValidationErrors,
      wrapInField: false,
      dataTestId: 'destination-connection-validation-errors',
      errorMessage: metadata.validation.targetConnection.errorMessage,
      titleTranslationKey: 'streamingActions.validation.connectionFailed'
    }
  ]
}

const getSnowflakeConfigFields = (
  metadata: FormMetadata,
  setMetadataProperty: (
    propertyPath: string,
    value: MetadataPropertyValue
  ) => void
) => {
  return [
    {
      type: SnowflakeMetadataLoader,
      wrapInField: false,
      dataTestId: 'snowflake-metadata-loader'
    },
    {
      type: DestinationDisconnectedAlert,
      wrapInField: false,
      dataTestId: 'destination-disconnected-alert',
      isVisible: () => metadata.validation.targetConnection.isError !== false
    },
    {
      type: AutoComplete,
      name: FIELD_NAMES_SNOWFLAKE_ROLE,
      title:
        'createStreamingPipeline.fields.destinationConfig.snowflake.role.title',
      dataTestId: 'configure-streaming-pipeline-snowflake-role',
      availableItems: metadata.snowflakeOptions.roles.data,
      disabled: metadata.snowflakeOptions.roles.data.length === 0,
      placeholder: getDropdownPlaceholder(metadata.snowflakeOptions.roles)
    },
    {
      type: AutoComplete,
      name: FIELD_NAMES_SNOWFLAKE_WAREHOUSE,
      title:
        'createStreamingPipeline.fields.destinationConfig.snowflake.warehouse.title',
      dataTestId: 'configure-streaming-pipeline-snowflake-warehouse',
      availableItems: metadata.snowflakeOptions.warehouses.data,
      disabled: metadata.snowflakeOptions.warehouses.data.length === 0,
      placeholder: getDropdownPlaceholder(metadata.snowflakeOptions.warehouses)
    },
    {
      type: AutoComplete,
      name: FIELD_NAMES_SNOWFLAKE_DATABASE,
      title:
        'createStreamingPipeline.fields.destinationConfig.snowflake.database.title',
      dataTestId: 'configure-streaming-pipeline-snowflake-database',
      availableItems: metadata.snowflakeOptions.databases.data,
      disabled: metadata.snowflakeOptions.databases.data.length === 0,
      placeholder: getDropdownPlaceholder(metadata.snowflakeOptions.databases)
    },
    {
      type: AutoComplete,
      name: FIELD_NAMES_SNOWFLAKE_STAGE_SCHEMA,
      title:
        'createStreamingPipeline.fields.destinationConfig.snowflake.stageSchema.title',
      dataTestId: 'configure-streaming-pipeline-snowflake-stage-schema',
      availableItems: metadata.snowflakeOptions.schemas.data,
      disabled: metadata.snowflakeOptions.schemas.data.length === 0,
      placeholder: getDropdownPlaceholder(metadata.snowflakeOptions.schemas)
    },
    {
      type: AutoComplete,
      name: FIELD_NAMES_SNOWFLAKE_STAGE_NAME,
      title:
        'createStreamingPipeline.fields.destinationConfig.snowflake.stageName.title',
      supportText:
        'createStreamingPipeline.fields.destinationConfig.snowflake.stageName.supportText',
      supportDocs:
        'createStreamingPipeline.fields.destinationConfig.snowflake.stageName.supportDocs',
      dataTestId: 'configure-streaming-pipeline-snowflake-stage-name',
      availableItems: metadata.snowflakeOptions.stages.data,
      disabled: metadata.snowflakeOptions.stages.data.length === 0,
      placeholder: getDropdownPlaceholder(metadata.snowflakeOptions.stages)
    },
    {
      type: StageConfigAlert,
      wrapInField: false,
      dataTestId: 'stage-config-alert',
      editing: metadata.editing,
      isVisible: (values: FormikValues) => !isEmpty(values.target.stageName?.id)
    },
    {
      type: Input,
      name: FIELD_NAMES_SNOWFLAKE_STAGING_PREFIX,
      title:
        'createStreamingPipeline.fields.destinationConfig.snowflake.stagePrefix.title',
      supportText:
        'createStreamingPipeline.fields.destinationConfig.snowflake.stagePrefix.supportText',
      dataTestId: 'configure-streaming-pipeline-snowflake-stage-prefix'
    },
    {
      type: AutoComplete,
      name: FIELD_NAMES_SNOWFLAKE_TABLE_SCHEMA,
      title:
        'createStreamingPipeline.fields.destinationConfig.snowflake.tableSchema.title',
      dataTestId: 'configure-streaming-pipeline-snowflake-table-schema',
      availableItems: metadata.snowflakeOptions.schemas.data,
      disabled: metadata.snowflakeOptions.schemas.data.length === 0,
      placeholder: getDropdownPlaceholder(metadata.snowflakeOptions.schemas)
    },
    {
      type: StreamingDropdown,
      wrapInField: false,
      name: FIELD_NAMES_SNOWFLAKE_TABLE_PREFIX_TYPE,
      title:
        'createStreamingPipeline.fields.destinationConfig.snowflake.tablePrefixType.title',
      supportText:
        'createStreamingPipeline.fields.destinationConfig.snowflake.tablePrefixType.supportText',
      dataTestId: 'configure-streaming-pipeline-snowflake-table-prefix-type',
      items: Object.values(TABLE_PREFIX_TYPES)
    },
    {
      type: DestinationConfigContinueButton,
      wrapInField: false,
      dataTestId: 'target-configuration-submit-button',
      setIsError: (isError: boolean) => {
        setMetadataProperty('validation.targetConfiguration.isError', isError)
      },
      setErrorMessage: (message: string | null) => {
        setMetadataProperty(
          'validation.targetConfiguration.errorMessage',
          message
        )
      }
    },
    {
      type: ValidationErrors,
      wrapInField: false,
      dataTestId: 'destination-configuration-validation-errors',
      errorMessage: metadata.validation.targetConfiguration.errorMessage,
      titleTranslationKey: 'streamingActions.validation.validationFailed'
    }
  ]
}

const getS3ConfigFields = (
  formValues: FormValues,
  metadata: FormMetadata,
  setMetadataProperty: (
    propertyPath: string,
    value: MetadataPropertyValue
  ) => void
) => {
  return [
    {
      type: RecommendedDocumentation,
      wrapInField: false,
      dataTestId: 'configure-streaming-pipeline-target-documentation',
      subject: formValues.target.type.name,
      documentationLink:
        STREAMING_DESTINATIONS[formValues.target.type.id].docs.overview
    },
    {
      type: Input,
      name: FIELD_NAMES_S3_BUCKET,
      title: 'createStreamingPipeline.fields.destinationConfig.s3.bucket.title',
      dataTestId: 'configure-streaming-pipeline-s3-bucket'
    },
    {
      type: Input,
      name: FIELD_NAMES_TARGET_PREFIX,
      title:
        'createStreamingPipeline.fields.destinationConfig.common.prefix.title',
      dataTestId: 'configure-streaming-pipeline-s3-prefix'
    },
    {
      type: DestinationConfigContinueButton,
      wrapInField: false,
      dataTestId: 'target-configuration-submit-button',
      setIsError: (isError: boolean) => {
        setMetadataProperty('validation.targetConfiguration.isError', isError)
      },
      setErrorMessage: (message: string | null) => {
        setMetadataProperty(
          'validation.targetConfiguration.errorMessage',
          message
        )
      }
    },
    {
      type: ValidationErrors,
      wrapInField: false,
      dataTestId: 'destination-configuration-validation-errors',
      errorMessage: metadata.validation.targetConfiguration.errorMessage,
      titleTranslationKey: 'streamingActions.validation.validationFailed'
    }
  ]
}

const getAzureBlobConfigFields = (
  formValues: FormValues,
  metadata: FormMetadata,
  setMetadataProperty: (
    propertyPath: string,
    value: MetadataPropertyValue
  ) => void
) => {
  return [
    {
      type: RecommendedDocumentation,
      wrapInField: false,
      dataTestId: 'configure-streaming-pipeline-target-documentation',
      subject: formValues.target.type.name,
      documentationLink:
        STREAMING_DESTINATIONS[formValues.target.type.id].docs.overview
    },
    {
      type: Input,
      name: FIELD_NAMES_AZURE_BLOB_CONTAINER,
      title:
        'createStreamingPipeline.fields.destinationConfig.azureBlob.container.title',
      dataTestId: 'configure-streaming-pipeline-azure-blob-container'
    },
    {
      type: Input,
      name: FIELD_NAMES_TARGET_PREFIX,
      title:
        'createStreamingPipeline.fields.destinationConfig.common.prefix.title',
      dataTestId: 'configure-streaming-pipeline-azure-blob-prefix'
    },
    {
      type: Input,
      name: FIELD_NAMES_AZURE_BLOB_ACCOUNT_NAME,
      title:
        'createStreamingPipeline.fields.destinationConfig.azureBlob.accountName.title',
      dataTestId: 'configure-streaming-pipeline-azure-blob-account-name'
    },
    {
      type: StreamingDropdown,
      wrapInField: false,
      name: FIELD_NAMES_AZURE_BLOB_ACCOUNT_KEY_SECRET_MANAGER,
      title:
        'createStreamingPipeline.fields.destinationConfig.azureBlob.accountKey.secretManager.title',
      dataTestId:
        'configure-streaming-pipeline-azure-blob-account-key-secret-manager',
      items: Object.values(SECRET_MANAGERS)
    },
    {
      type: Input,
      name: FIELD_NAMES_AZURE_BLOB_ACCOUNT_KEY_SECRET_NAME,
      title:
        'createStreamingPipeline.fields.destinationConfig.azureBlob.accountKey.secretName.title',
      supportText:
        'createStreamingPipeline.fields.destinationConfig.azureBlob.accountKey.secretName.supportText',
      dataTestId:
        'configure-streaming-pipeline-azure-blob-account-key-secret-name'
    },
    {
      type: DestinationConfigContinueButton,
      wrapInField: false,
      dataTestId: 'target-configuration-submit-button',
      setIsError: (isError: boolean) => {
        setMetadataProperty('validation.targetConfiguration.isError', isError)
      },
      setErrorMessage: (message: string | null) => {
        setMetadataProperty(
          'validation.targetConfiguration.errorMessage',
          message
        )
      }
    },
    {
      type: ValidationErrors,
      wrapInField: false,
      dataTestId: 'destination-configuration-validation-errors',
      errorMessage: metadata.validation.targetConfiguration.errorMessage,
      titleTranslationKey: 'streamingActions.validation.validationFailed'
    }
  ]
}

const getSourceFields = (
  formValues: FormValues,
  metadata: FormMetadata,
  setMetadataProperty: (
    propertyPath: string,
    value: MetadataPropertyValue
  ) => void
) => {
  return [
    {
      type: RecommendedDocumentation,
      wrapInField: false,
      dataTestId: 'configure-streaming-pipeline-source-documentation',
      subject: formValues.source.type.name,
      documentationLink:
        STREAMING_SOURCES[formValues.source.type.id].docs.overview
    },
    {
      type: HostAndPort,
      wrapInField: false,
      dataTestId: 'host-and-port-field'
    },
    {
      type: Input,
      name: FIELD_NAMES_SOURCE_DATABASE_NAME,
      title: (values: FormikValues) =>
        formHasPdbProperty(values.source)
          ? 'createStreamingPipeline.fields.sourceConfig.databaseName.cdbTitle'
          : 'createStreamingPipeline.fields.sourceConfig.databaseName.title',
      supportText: (values: FormikValues) =>
        formHasPdbProperty(values.source)
          ? 'createStreamingPipeline.fields.sourceConfig.databaseName.cdbSupportText'
          : undefined,
      dataTestId: 'configure-streaming-pipeline-source-database-name',
      isVisible: (values: FormikValues) =>
        formHasDatabaseProperty(values.source)
    },
    {
      type: Input,
      name: FIELD_NAMES_SOURCE_PDB,
      highlightIfEmpty: false,
      title: 'createStreamingPipeline.fields.sourceConfig.pdb.title',
      optionalLabelText:
        'createStreamingPipeline.fields.sourceConfig.pdb.optionalLabelText',
      supportText:
        'createStreamingPipeline.fields.sourceConfig.pdb.supportText',
      dataTestId: 'configure-streaming-pipeline-source-pdb',
      isVisible: (values: FormikValues) => formHasPdbProperty(values.source)
    },
    {
      type: Checkbox,
      name: FIELD_NAMES_SOURCE_READ_REPLICA,
      highlightIfEmpty: false,
      title: 'createStreamingPipeline.fields.sourceConfig.readReplica.title',
      text: 'createStreamingPipeline.fields.sourceConfig.readReplica.text',
      supportText:
        'createStreamingPipeline.fields.sourceConfig.readReplica.supportText',
      supportDocs:
        'createStreamingPipeline.fields.sourceConfig.readReplica.supportDocs',
      dataTestId: 'configure-streaming-pipeline-source-readReplica',
      isVisible: (values: FormikValues) =>
        values.source.type.id === StreamingSourceId.SQLServer
    },
    {
      type: Input,
      name: FIELD_NAMES_SOURCE_USER,
      title: 'createStreamingPipeline.fields.sourceConfig.user.title',
      dataTestId: 'configure-streaming-pipeline-source-user'
    },
    {
      type: StreamingDropdown,
      wrapInField: false,
      name: FIELD_NAMES_SOURCE_SECRET_MANAGER,
      title: 'createStreamingPipeline.fields.sourceConfig.secretManager.title',
      dataTestId: 'configure-streaming-pipeline-source-secret-manager',
      items: Object.values(SECRET_MANAGERS)
    },
    {
      type: Input,
      name: FIELD_NAMES_SOURCE_SECRET_NAME,
      title: 'createStreamingPipeline.fields.sourceConfig.secretName.title',
      dataTestId: 'configure-streaming-pipeline-source-secret-name'
    },
    {
      type: SourceJdbcParameters,
      wrapInField: false,
      dataTestId: 'configure-streaming-pipeline-source-jdbc-properties'
    },
    {
      type: JdbcParametersAlert,
      wrapInField: false,
      dataTestId: 'source-jdbc-parameters-info'
    },
    {
      type: SourceConnectButton,
      wrapInField: false,
      dataTestId: 'source-connect-button',
      setIsError: (isError: boolean) => {
        setMetadataProperty('validation.sourceConnection.isError', isError)
      },
      setErrorMessage: (message: string | null) => {
        setMetadataProperty('validation.sourceConnection.errorMessage', message)
      }
    },
    {
      type: ValidationErrors,
      wrapInField: false,
      dataTestId: 'source-connection-validation-errors',
      errorMessage: metadata.validation.sourceConnection.errorMessage,
      titleTranslationKey: 'streamingActions.validation.connectionFailed'
    }
  ]
}

const getDestinationPanelGroupingConfigs = (
  formValues: FormValues,
  metadata: FormMetadata,
  setMetadataProperty: (
    propertyPath: string,
    value: MetadataPropertyValue
  ) => void
): PanelGroupingConfig[] => {
  switch (formValues.target.type.id) {
    case StreamingDestinationId.Snowflake: {
      return [
        {
          stepId: 'destination-connection',
          title: 'createStreamingPipeline.panels.destinationConnectionTitle',
          subtitle: (t, values) => values.target.type.name,
          fields: getSnowflakeConnectionFields(
            formValues,
            metadata,
            setMetadataProperty
          ),
          showValidationStatus:
            metadata.validation.targetConnection.isError !== undefined,
          isError: metadata.validation.targetConnection.isError,
          'data-testid': 'destination-connection-panel'
        },
        {
          stepId: 'destination-configuration',
          title: 'createStreamingPipeline.panels.destinationConfigTitle',
          subtitle: (t, v) => {
            const selectedWarehouse = v.target.warehouse?.name
            const selectedDatabase = v.target.database?.name
            const warehouseText: string = isEmpty(selectedWarehouse)
              ? t(
                  'createStreamingPipeline.fields.destinationConfig.snowflake.warehouse.noSelection'
                )
              : selectedWarehouse
            const databaseText: string = isEmpty(selectedDatabase)
              ? t(
                  'createStreamingPipeline.fields.destinationConfig.snowflake.database.noSelection'
                )
              : selectedDatabase
            return `${warehouseText} - ${databaseText}`
          },
          fields: getSnowflakeConfigFields(metadata, setMetadataProperty),
          showValidationStatus:
            metadata.validation.targetConfiguration.isError !== undefined,
          isError: metadata.validation.targetConfiguration.isError,
          'data-testid': 'destination-configuration-panel'
        }
      ]
    }
    case StreamingDestinationId.S3: {
      return [
        {
          stepId: 'destination-configuration',
          title: 'createStreamingPipeline.panels.destinationConfigTitle',
          subtitle: (t, values) => values.target.type.name,
          fields: getS3ConfigFields(formValues, metadata, setMetadataProperty),
          showValidationStatus:
            metadata.validation.targetConfiguration.isError !== undefined,
          isError: metadata.validation.targetConfiguration.isError,
          'data-testid': 'destination-configuration-panel'
        }
      ]
    }
    case StreamingDestinationId.ABS: {
      return [
        {
          stepId: 'destination-configuration',
          title: 'createStreamingPipeline.panels.destinationConfigTitle',
          subtitle: (t, values) => values.target.type.name,
          fields: getAzureBlobConfigFields(
            formValues,
            metadata,
            setMetadataProperty
          ),
          showValidationStatus:
            metadata.validation.targetConfiguration.isError !== undefined,
          isError: metadata.validation.targetConfiguration.isError,
          'data-testid': 'destination-configuration-panel'
        }
      ]
    }
  }
}

const getPipelineConfigFields = (
  metadata: FormMetadata,
  setMetadataProperty: (
    propertyPath: string,
    value: MetadataPropertyValue
  ) => void
) => {
  return [
    {
      type: SourceDisconnectedAlert,
      wrapInField: false,
      dataTestId: 'source-disconnected-alert',
      isVisible: () => metadata.validation.sourceConnection.isError !== false
    },
    {
      type: TableSelectionList,
      wrapInField: false,
      dataTestId: 'table-selection-list'
    },
    {
      type: SnapshotConfigHeader,
      wrapInField: false,
      dataTestId: 'configure-streaming-pipeline-pipeline-config-snapshot-header'
    },
    {
      type: Checkbox,
      name: FIELD_NAMES_INITIAL_SNAPSHOT,
      highlightIfEmpty: false,
      text: 'createStreamingPipeline.fields.pipelineConfig.initialSnapshot.text',
      supportText:
        'createStreamingPipeline.fields.pipelineConfig.initialSnapshot.supportText',
      dataTestId:
        'configure-streaming-pipeline-pipeline-config-initial-snapshot'
    },
    {
      type: Checkbox,
      name: FIELD_NAMES_ON_DEMAND_SNAPSHOT,
      highlightIfEmpty: false,
      text: 'createStreamingPipeline.fields.pipelineConfig.onDemandSnapshot.text',
      supportText:
        'createStreamingPipeline.fields.pipelineConfig.onDemandSnapshot.supportText',
      dataTestId:
        'configure-streaming-pipeline-pipeline-config-on-demand-snapshot'
    },
    {
      type: SignalTableInput,
      wrapInField: false,
      dataTestId: 'signal-table-input',
      isVisible: (values: FormikValues) =>
        values.source.snapshot.onDemandSnapshot.enabled
    },
    {
      type: StreamingDropdown,
      wrapInField: false,
      name: FIELD_NAMES_TRANSFORMATION_TYPE,
      title:
        'createStreamingPipeline.fields.pipelineConfig.transformationType.title',
      supportText:
        'createStreamingPipeline.fields.pipelineConfig.transformationType.supportText',
      supportDocs:
        'createStreamingPipeline.fields.pipelineConfig.transformationType.supportDocs',
      dataTestId: 'configure-streaming-pipeline-transformation-type',
      items: Object.values(TRANSFORMATION_TYPES),
      isVisible: (values: FormikValues) =>
        values.target.type.id === StreamingDestinationId.Snowflake
    },
    {
      type: StreamingDropdown,
      wrapInField: false,
      name: FIELD_NAMES_TEMPORAL_MAPPING,
      title:
        'createStreamingPipeline.fields.pipelineConfig.temporalMapping.title',
      dataTestId:
        'create-streaming-pipeline-snowflake-temporal-mapping-strategy',
      items: Object.values(TEMPORAL_MAPPINGS),
      supportText:
        'createStreamingPipeline.fields.pipelineConfig.temporalMapping.supportText',
      supportDocs:
        'createStreamingPipeline.fields.pipelineConfig.temporalMapping.supportDocs',
      isVisible: (values: FormikValues) =>
        values.target.type.id === StreamingDestinationId.Snowflake
    },
    {
      type: AdvancedProperties,
      wrapInField: false,
      dataTestId:
        'configure-streaming-pipeline-pipeline-config-advanced-settings'
    },
    {
      type: PipelineConfigContinueButton,
      wrapInField: false,
      key: 'pipeline-configuration-submit-button',
      dataTestId: 'pipeline-configuration-submit-button',
      setIsError: (isError: boolean) => {
        setMetadataProperty('validation.pipelineConfiguration.isError', isError)
      },
      setErrorMessage: (message: string | null) => {
        setMetadataProperty(
          'validation.pipelineConfiguration.errorMessage',
          message
        )
      }
    },
    {
      type: ValidationErrors,
      wrapInField: false,
      dataTestId: 'pipeline-configuration-validation-errors',
      errorMessage: metadata.validation.pipelineConfiguration.errorMessage,
      titleTranslationKey: 'streamingActions.validation.validationFailed'
    }
  ]
}

export const getPanelGroupingConfigs: (
  formValues: FormValues,
  metadata: FormMetadata,
  setMetadataProperty: (
    propertyPath: string,
    value: MetadataPropertyValue
  ) => void
) => PanelGroupingConfig[] = (formValues, metadata, setMetadataProperty) => {
  return [
    ...getDestinationPanelGroupingConfigs(
      formValues,
      metadata,
      setMetadataProperty
    ),
    {
      stepId: 'source-setup',
      title: 'createStreamingPipeline.panels.sourceSetupTitle',
      subtitle: (t, values) => values.source.type.name,
      fields: getSourceFields(formValues, metadata, setMetadataProperty),
      showValidationStatus:
        metadata.validation.sourceConnection.isError === true ||
        metadata.validation.sourceConnection.isError === false,
      isError: metadata.validation.sourceConnection.isError,
      'data-testid': 'source-configuration-panel'
    },
    {
      stepId: 'pipeline-configuration',
      title: 'createStreamingPipeline.panels.pipelineConfigTitle',
      fields: getPipelineConfigFields(metadata, setMetadataProperty),
      showValidationStatus:
        metadata.validation.pipelineConfiguration.isError !== undefined,
      isError: metadata.validation.pipelineConfiguration.isError,
      'data-testid': 'pipeline-configuration-panel'
    }
  ]
}
