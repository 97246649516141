import { useQuery, type UseQueryOptions } from 'react-query'

import { type AxiosError } from 'axios'

import { getJobs } from 'api/clients'
import { type GETJobsResponse } from 'api/types'

import { QueryKey } from 'constants/endpoint'

import { useClient } from './useClient'

type TData = GETJobsResponse
type TError = AxiosError<unknown>
type Options = Omit<
  UseQueryOptions<TData, TError, TData>,
  'queryKey' | 'queryFn'
>

export const useGetJobs = (
  projectId: string,
  environmentId: string,
  options?: Options
) => {
  const client = useClient()

  return useQuery<TData, TError>(
    [QueryKey.JOBS_LIST, projectId, environmentId],
    async () =>
      getJobs(
        {
          environmentId,
          projectId
        },
        client
      ),
    options
  )
}
