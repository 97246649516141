import { Icon, Typography } from '@matillion/component-library'

import classes from './SchemaRowContent.module.scss'

export const SchemaRowContent = ({ schemaName }: { schemaName: string }) => (
  <div className={classes.SchemaRowContent__SchemaRowContentWrapper}>
    <Icon.Schema className={classes.SchemaRowContent__SchemaIcon} />
    <Typography format="bcs" data-testid={`schema-name-${schemaName}`}>
      {schemaName}
    </Typography>
  </div>
)
