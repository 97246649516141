import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { type AxiosError } from 'axios'

import { Toaster } from '@matillion/component-library'

import { usePostMha } from 'api/hooks'
import { type ErrorResponse } from 'api/types'

export const useCreateMha = (errorCallback?: () => void) => {
  const { t } = useTranslation()
  const { makeToast } = Toaster.useToaster()

  const { mutateAsync: mutateAsyncMHA } = usePostMha({})

  const createMHA = useCallback(async () => {
    try {
      await mutateAsyncMHA()
    } catch (error: unknown) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const err = error as AxiosError<any>

      const errorDetail = (err?.response?.data as ErrorResponse)?.detail

      makeToast({
        title: t('error.unexpected.title'),
        message: errorDetail ?? t('error.unexpected.message'),
        type: 'error'
      })
      if (errorCallback) {
        errorCallback()
      }
    }
  }, [errorCallback, makeToast, mutateAsyncMHA, t])

  return createMHA
}
