export enum SourceName {
  AmazonRedshift = 'AmazonRedshift',
  Bing = 'Bing',
  Cassandra = 'Cassandra',
  Couchbase = 'Couchbase',
  Dynamics365Sales = 'Dynamics365Sales',
  Elasticsearch = 'Elasticsearch',
  Eloqua = 'Eloqua',
  Excel = 'Excel',
  FacebookAdAccounts = 'FacebookAdAccounts',
  FacebookAdInsights = 'FacebookAdInsights',
  FacebookContentInsights = 'FacebookContentInsights',
  Gmail = 'Gmail',
  GoogleAdWords = 'GoogleAdWords',
  GoogleAnalytics = 'GoogleAnalytics',
  GoogleBigQuery = 'GoogleBigQuery',
  GoogleCustomSearch = 'GoogleCustomSearch',
  GoogleSheets = 'GoogleSheets',
  Greenplum = 'Greenplum',
  HubSpot = 'HubSpot',
  IbmDB2 = 'IbmDB2',
  Jira = 'Jira',
  Magento = 'Magento',
  Mailchimp = 'Mailchimp',
  MariaDB = 'MariaDB',
  Marketo = 'Marketo',
  MicrosoftSQLServer = 'MicrosoftSQLServer',
  MongoDB = 'MongoDB',
  MySQL = 'MySQL',
  NetSuite = 'NetSuite',
  OData = 'OData',
  OpenExchangeRates = 'OpenExchangeRates',
  Oracle = 'Oracle',
  PostgreSQL = 'PostgreSQL',
  Quickbooks = 'Quickbooks',
  SageIntacct = 'SageIntacct',
  Salesforce = 'Salesforce',
  Shopify = 'Shopify',
  Snowflake = 'Snowflake',
  Stripe = 'Stripe',
  SugarCRM = 'SugarCRM',
  TwitterTweets = 'TwitterTweets',
  Xero = 'Xero',
  Zendesk = 'Zendesk',
  ZendeskTalk = 'ZendeskTalk'
}
