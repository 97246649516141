import { useTranslation } from 'react-i18next'

import { useFormikContext } from 'formik'

import { Icon, LoadingSpinner } from '@matillion/component-library'

import {
  enabledStatus,
  startingStatus,
  stoppedStatus
} from 'api/createProjectForm/types'

import { DefaultSchema } from 'modules/Common/Fields'
import { type CreateProjectFormikValueTypes } from 'modules/Projects/CreateProject/CreateProjectForm'

const { Error: ErrorIcon } = Icon

const DatabricksSchema = () => {
  const { values } = useFormikContext<CreateProjectFormikValueTypes>()
  const { compute } = values

  const computePopulated = !!compute && !!compute.id
  const stoppedCompute =
    !!compute?.state && stoppedStatus.includes(compute?.state)
  const isStoppedCompute =
    computePopulated && (!compute?.state || stoppedCompute)
  const isStartingCompute =
    !!compute?.state && startingStatus.includes(compute.state)

  const isFieldEnabled =
    !!values.catalog &&
    !!values.catalog.id &&
    !!compute?.state &&
    !!compute?.state &&
    enabledStatus.includes(compute.state)
  const colorTheme = !isFieldEnabled ? 'grey' : 'white'

  return (
    <DefaultSchema
      listenerField={'catalog'}
      isStartingCompute={isStartingCompute}
      isFieldEnabled={isFieldEnabled}
      placeholder={usePlaceholder(isStartingCompute, isStoppedCompute)}
      iconBefore={getIconBefore(isStartingCompute, isStoppedCompute)}
      colorTheme={colorTheme}
    />
  )
}

const getIconBefore = (
  isStartingCompute: boolean,
  isStoppedCompute: boolean
) => {
  const startingUpIcon = {
    icon: <LoadingSpinner small={true} />,
    clickable: false
  }
  const errorIcon = {
    icon: <ErrorIcon />,
    clickable: false
  }
  const includeStartingUpIcon = isStartingCompute ? startingUpIcon : undefined
  const iconBefore = isStoppedCompute ? errorIcon : includeStartingUpIcon

  return iconBefore
}

const usePlaceholder = (
  isStartingCompute: boolean,
  isStoppedCompute: boolean
) => {
  const { t } = useTranslation()

  const includeStartingUpPlaceholder = isStartingCompute
    ? t('fields.defaultSchema.startingUpText')
    : undefined
  const placeholder = isStoppedCompute
    ? t('fields.defaultSchema.stoppedText')
    : includeStartingUpPlaceholder

  return placeholder
}

export default DatabricksSchema
