import { type FunctionComponent } from 'react'

import classNames from 'classnames'
import { useFormikContext } from 'formik'
import { isEmpty } from 'lodash'

import formClasses from 'components/Form/Form.module.scss'
import { PanelGroup } from 'components/PanelGroup'

import { useFormNavigation } from 'hooks'

import {
  CreateHeader,
  steps,
  useFormMetadata,
  type FormValues
} from 'modules/Projects/CreateStreamingPipeline/FormContent'

import { useSourceMetadataLoader } from './Components/TableSelection'
import { getPanelGroupingConfigs } from './panelGroupConfig'

interface ConfigurePageProps {
  onPrevious: (page?: string) => void
}

export type MetadataPropertyValue = string | boolean | null

const ConfigurePipelineDetailsPage: FunctionComponent<ConfigurePageProps> = ({
  onPrevious
}) => {
  const { previousStep } = useFormNavigation(steps)
  const { isSubmitting, isValid, values } = useFormikContext<FormValues>()
  const onPreviousCallback = () => {
    onPrevious(previousStep)
  }
  const { metadata, setMetadataProperty } = useFormMetadata()
  useSourceMetadataLoader()

  // if agent-id isn't set, go back to first page
  if (isEmpty(values.agent.id) || isEmpty(values.name)) {
    onPreviousCallback()
  }

  return (
    <div data-testid="configure-streaming-pipeline-configure-page">
      <CreateHeader
        onPrevious={!isSubmitting && isValid ? onPreviousCallback : undefined}
      />

      <div
        className={classNames(
          formClasses.Form__InnerWrap,
          formClasses['Form__InnerWrap--space-top']
        )}
      >
        <PanelGroup
          groupingConfigs={getPanelGroupingConfigs(
            values,
            metadata,
            setMetadataProperty
          )}
        />
      </div>
    </div>
  )
}

export default ConfigurePipelineDetailsPage
