import { useQuery, type UseQueryOptions } from 'react-query'

import { type AxiosError } from 'axios'

import { getSecretName } from 'api/createProjectForm/client'
import { type GETSecretNameResponse } from 'api/createProjectForm/types'
import { useClient } from 'api/hooks'

import { QueryKey } from 'constants/endpoint'

type TData = GETSecretNameResponse
type TError = AxiosError<unknown>
type Options = Omit<
  UseQueryOptions<TData, TError, TData>,
  'queryKey' | 'queryFn'
>

/**
 * See more at https://tanstack.com/query/v4/docs/reference/useQuery
 *
 * @param {string} secretLocationId - The ID of the secret location
 * @param {string} agentId - The ID of the agent
 * @param {Object|undefined} options - useQuery options
 * @returns {Array} The secret names in string format
 */
export const useGetSecretNames = (
  secretLocationId: string,
  agentId: string,
  options?: Options
) => {
  const client = useClient()

  return useQuery<TData, TError>(
    [QueryKey.SECRET_NAMES, secretLocationId, agentId],
    async () => getSecretName({ agentId, secretLocationId }, client),
    options
  )
}
