import { type FunctionComponent } from 'react'

import { GenericCredentials } from 'modules/Projects/AssociateCloudCredentials/AssociateCloudCredentialsToEnvironment/FormContent/Fields'

import { CloudProviders } from 'types/CloudProviders'

export const GCPCredentials: FunctionComponent = () => {
  return (
    <GenericCredentials
      cloudProvider={CloudProviders.GCP}
      fieldId="gcpCredentials"
      fieldName="GCP"
    />
  )
}
