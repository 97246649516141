import { type FC } from 'react'
import { useTranslation } from 'react-i18next'

import { Field as FormikField, useFormikContext, type FieldProps } from 'formik'

import { Field } from '@matillion/component-library'

import { type FormValues } from 'modules/Projects/CreateStreamingPipeline/FormContent'
import { AdditionalConfig } from 'modules/Projects/CreateStreamingPipeline/FormContent/ConfigurePipelineDetailsPage/Components/Properties/AdditionalConfig/AdditionalConfig'
import { FIELD_NAMES_SOURCE_JDBC_PROPERTIES } from 'modules/Projects/CreateStreamingPipeline/FormContent/ConfigurePipelineDetailsPage/types'

export const SourceJdbcParameters: FC = () => {
  const { t } = useTranslation()
  const { values } = useFormikContext<FormValues>()

  return (
    <FormikField name={FIELD_NAMES_SOURCE_JDBC_PROPERTIES}>
      {({ field, meta }: FieldProps<string>) => (
        <Field
          {...field}
          inputComponent={AdditionalConfig}
          title={t(
            'createStreamingPipeline.fields.sourceConfig.jdbcProperties.title'
          )}
          optionalLabelText={t(
            'createStreamingPipeline.fields.sourceConfig.jdbcProperties.optionalLabelText'
          )}
          fieldName={FIELD_NAMES_SOURCE_JDBC_PROPERTIES}
          additionalConfig={values.source.connection.jdbcProperties}
          modalHeading={t(
            'createStreamingPipeline.fields.sourceConfig.jdbcProperties.modal.heading'
          )}
          dataTestId="configure-streaming-pipeline-source-jdbc-properties"
        />
      )}
    </FormikField>
  )
}
