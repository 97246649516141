import { useQuery } from 'react-query'

import { type AxiosError } from 'axios'

import { getAccountMembers } from 'api/clients'
import { useClient } from 'api/hooks'
import { type GETAccountMembersResponse } from 'api/types'

import config from 'config'

import { QueryKey } from 'constants/endpoint'

type TData = GETAccountMembersResponse
type TError = AxiosError<unknown>

export const useGetAccountMembers = () => {
  const client = useClient({
    tokenOptions: {
      audience: config.hub.apiAudience
    },
    axiosConfig: {
      baseURL: config.hub.apiUrl
    }
  })

  return useQuery<TData, TError>(QueryKey.ACCOUNT_MEMBERS_LIST, async () =>
    getAccountMembers(client)
  )
}
