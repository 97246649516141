import { type FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'

import classNames from 'classnames'

import classes from 'components/Form/Form.module.scss'
import { Header, HeaderTitle } from 'components/Header'

import { WarehouseLabels } from 'types'

import { AwsAccessKeyId, AwsSecretAccessKey } from './Fields'

export interface RedshiftCloudCredentialsProps {
  onCancel?: () => void
  onPrevious?: () => void
  showCancel?: boolean
  showPrevious?: boolean
  modalContent?: string
  excludeHeader?: boolean
}

export const RedshiftCloudCredentials: FunctionComponent<
  RedshiftCloudCredentialsProps
> = ({
  onCancel,
  onPrevious,
  showCancel = true,
  showPrevious = false,
  modalContent,
  excludeHeader = false
}) => {
  const { t } = useTranslation()

  const fields = (
    <>
      <AwsAccessKeyId />
      <AwsSecretAccessKey />
    </>
  )

  if (excludeHeader) {
    return fields
  }
  return (
    <>
      <Header
        onCancel={onCancel}
        showCancel={showCancel}
        onPrevious={onPrevious}
        showPrevious={showPrevious}
        modalContent={modalContent}
      >
        <HeaderTitle data-testid="specify-cloud-credentials-title">
          {t('formContent.cloudCredentials.title', {
            projectType: WarehouseLabels.Redshift
          })}
        </HeaderTitle>
      </Header>
      <div
        className={classNames(
          classes.Form__InnerWrap,
          classes['Form__InnerWrap--space-top']
        )}
      >
        {fields}
      </div>
    </>
  )
}
