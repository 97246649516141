import { useState, type FC } from 'react'
import { useTranslation } from 'react-i18next'

import classNames from 'classnames'
import { Field as FormikField, useFormikContext, type FieldProps } from 'formik'

import { Button, Icon, Input, Typography } from '@matillion/component-library'

import { hasError } from 'components/PanelGroup/PanelGroup.util'

import { SnowflakeAccountHelp } from 'modules/Common/WarehouseSources/Snowflake/Credentials/SnowflakeAccountHelp'
import { FIELD_NAMES_SNOWFLAKE_ACCOUNT } from 'modules/Projects/CreateStreamingPipeline/FormContent/ConfigurePipelineDetailsPage/types'
import { type FormValues } from 'modules/Projects/CreateStreamingPipeline/FormContent/types'

import classes from './SnowflakeAccount.module.scss'

export const SnowflakeAccount: FC = () => {
  const { t } = useTranslation('translation', {
    keyPrefix:
      'createStreamingPipeline.fields.destinationConfig.snowflake.account'
  })
  const { values } = useFormikContext<FormValues>()

  const [isModalOpen, setIsModalOpen] = useState(false)

  return (
    <>
      <FormikField name={FIELD_NAMES_SNOWFLAKE_ACCOUNT}>
        {({ field, meta, form: { isSubmitting } }: FieldProps<string>) => {
          const isError = hasError(
            values,
            meta,
            true,
            FIELD_NAMES_SNOWFLAKE_ACCOUNT
          )
          return (
            <label
              id={FIELD_NAMES_SNOWFLAKE_ACCOUNT}
              data-testid="configure-streaming-pipeline-snowflake-account"
            >
              <div className={classes.SnowflakeAccount__Title}>
                <Typography
                  as="span"
                  weight="bold"
                  format="bcs"
                  className={classNames({
                    [classes.SnowflakeAccount__HeadingError]: isError
                  })}
                >
                  {t('title')}
                </Typography>
                <Button
                  alt="text"
                  fontWeight="normal"
                  onClick={() => {
                    setIsModalOpen(true)
                  }}
                  size="md"
                  as="span"
                  className={classes.SnowflakeAccount__ModalButton}
                  data-testid="configure-streaming-pipeline-snowflake-account-help"
                >
                  <Icon.Help />
                  <Typography as="span" weight="bold" format="bcs">
                    {t('help')}
                  </Typography>
                </Button>
              </div>
              <Input
                {...field}
                disabled={isSubmitting}
                error={isError}
                data-testid="configure-streaming-pipeline-snowflake-account-input"
              />
              <div className={classes.SnowflakeAccount__SupportTextWrapper}>
                <Typography
                  as="span"
                  format="bcs"
                  className={classes.SnowflakeAccount__SupportText}
                >
                  {t('supportText')}
                </Typography>
              </div>
            </label>
          )
        }}
      </FormikField>
      {isModalOpen && (
        <SnowflakeAccountHelp
          handleCloseModal={() => {
            setIsModalOpen(false)
          }}
        />
      )}
    </>
  )
}
