import { type FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'

import classNames from 'classnames'

import classes from 'components/Form/Form.module.scss'
import { Header, HeaderTitle } from 'components/Header'

import { useFlags } from 'hooks/flags'

import {
  RedshiftDatabase,
  RedshiftSchema
} from 'modules/Common/WarehouseSources/Redshift/Defaults/Fields'

import { WarehouseLabels } from 'types'

import RedshiftDefaultLocation from './Fields/RedshiftDefaultLocation'

interface RedshiftDefaultsProps {
  onCancel?: () => void
  onPrevious?: () => void
  showCancel?: boolean
  showPrevious?: boolean
  modalContent?: string
  isEditEnvironment?: boolean
  isCreateEnvironment?: boolean
}

export const RedshiftDefaults: FunctionComponent<RedshiftDefaultsProps> = ({
  onCancel,
  onPrevious,
  showCancel = false,
  showPrevious = false,
  modalContent,
  isEditEnvironment = false,
  isCreateEnvironment = false
}) => {
  const { t } = useTranslation()
  const { enableRedshiftLocationDefaults } = useFlags()

  return (
    <>
      <Header
        onCancel={onCancel}
        showCancel={showCancel}
        onPrevious={onPrevious}
        showPrevious={showPrevious}
        modalContent={modalContent}
      >
        <HeaderTitle data-testid="redshift-default-title">
          {t(
            isEditEnvironment
              ? 'formContent.editEnvironmentDefaults.title'
              : 'formContent.defaults.title',
            {
              projectType: WarehouseLabels.Redshift
            }
          )}
        </HeaderTitle>
      </Header>
      <div
        className={classNames(
          classes.Form__InnerWrap,
          classes['Form__InnerWrap--space-top']
        )}
      >
        <RedshiftDatabase />
        <RedshiftSchema />
        {enableRedshiftLocationDefaults &&
        !isEditEnvironment &&
        !isCreateEnvironment ? (
          <RedshiftDefaultLocation />
        ) : null}
      </div>
    </>
  )
}
