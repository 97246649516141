import { type ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'

import { useFormikContext } from 'formik'

import { AutoComplete, Field } from '@matillion/component-library'

import { ExternalLink } from 'components/ExternalLink'
import classes from 'components/Form/Form.module.scss'

import config from 'config'

import type { CommonFieldsTypes } from 'modules/Common/Fields/types'

import { type EnvironmentFormI } from 'types/FormTypes'

export const EnvironmentDefaultAccess = ({
  'data-testid': dataTestId = 'environment-default_access'
}: CommonFieldsTypes) => {
  const { t } = useTranslation()
  const { handleBlur, handleChange, touched, values, setTouched, setValues } =
    useFormikContext<EnvironmentFormI>()

  const handleValueUpdates = (e: ChangeEvent<HTMLInputElement>) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value
    })
    setTouched({
      ...touched,
      [e.target.name]: true
    })
  }

  return (
    <div data-testid={dataTestId}>
      <Field
        inputComponent={AutoComplete}
        availableItems={[
          {
            id: 'admin',
            name: t('fields.environmentDefaultAccess.options.admin')
          },
          {
            id: 'readOnly',
            name: t('fields.environmentDefaultAccess.options.readOnly')
          },
          {
            id: 'noAccess',
            name: t('fields.environmentDefaultAccess.options.noAccess')
          }
        ]}
        title={t('fields.environmentDefaultAccess.title')}
        name="environmentDefaultAccess"
        data-testid={`${dataTestId}-input`}
        value={values.environmentDefaultAccess}
        placeholder={t('fields.environmentDefaultAccess.placeholderText')}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          handleChange(e)
          handleValueUpdates(e)
        }}
        onBlur={handleBlur}
        className={classes.Form__SpacingStyles}
        supportText={
          <ExternalLink
            format={'bcs'}
            href={config.projectExplorer.accessLevelsHelpDocs}
            text={t('fields.environmentDefaultAccess.supportText')}
            data-testid={`${dataTestId}-external-link`}
          />
        }
        required
      />
    </div>
  )
}
