import { useTranslation } from 'react-i18next'

import classes from 'components/Form/Form.module.scss'
import { Header, HeaderTitle } from 'components/Header'

import { GitRepositorySelectionContent } from 'modules/FormsContent/GitRepositorySelection/GitRepositorySelectionContent'

interface GitRepositorySelectionProps {
  onCancel?: () => void
  modalContent?: string
  onPrevious?: () => void
}

const GitRepositorySelection = ({
  onCancel,
  modalContent,
  onPrevious
}: GitRepositorySelectionProps) => {
  const { t } = useTranslation()

  return (
    <>
      <Header
        onCancel={onCancel}
        showCancel
        showPrevious
        modalContent={modalContent}
        onPrevious={onPrevious}
      >
        <HeaderTitle data-testid="git-repositories-selection-title">
          {t('formContent.gitRepositorySelection.title')}
        </HeaderTitle>
      </Header>
      <div className={classes.Form__InnerWrap}>
        <GitRepositorySelectionContent />
      </div>
    </>
  )
}

export default GitRepositorySelection
