import { type FunctionComponent, type PropsWithChildren } from 'react'

import {
  TrialBanner,
  type TrialBannerEnvironment
} from '@matillion/billing-frontend-sdk'

import config from 'config'

interface RouteWrapperProps extends PropsWithChildren {
  enableTrialBanner?: boolean
}

const RouteWrapper: FunctionComponent<RouteWrapperProps> = ({
  enableTrialBanner = true,
  children
}) => {
  if (enableTrialBanner) {
    return (
      <>
        <div data-testid="trial-banner">
          <TrialBanner
            environment={config.environment as TrialBannerEnvironment}
          />
        </div>
        {children}
      </>
    )
  }

  return <>{children}</>
}

export default RouteWrapper
