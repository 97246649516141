import { useQuery, type UseQueryResult } from 'react-query'

import { type AxiosError } from 'axios'

import { checkPermission } from 'api/permissions'

import { QueryKey } from 'constants/endpoint'

import { type PermissionsType } from 'types'

import { usePermissionsClient } from './usePermissionsClient'

type TError = AxiosError<unknown>

export const HAS_PERMISSION = 'HAS_PERMISSION'

export interface UsePermissionsParams {
  resourceType: string
  resourceId: string
  permission: PermissionsType
}

export const useCheckPermissions = (
  params: UsePermissionsParams
): UseQueryResult<boolean, TError> => {
  const permissionsClient = usePermissionsClient()
  return useQuery<boolean, TError>(
    [QueryKey.USER_PERMISSIONS, params.permission, params.resourceId],
    async () => {
      const response = await checkPermission(
        {
          resource: {
            id: params.resourceId,
            type: params.resourceType
          },
          permission: params.permission
        },
        permissionsClient
      )
      return response.permission === HAS_PERMISSION
    }
  )
}
