import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'

import { type FormikHelpers } from 'formik'

import { Toaster } from '@matillion/component-library'

import { usePostSecretValueV2 } from 'api/hooks'
import { createCloudProviderCredentialsV2MutationData } from 'api/mutation'

import { QueryKey } from 'constants/endpoint'
import { AppRoutes } from 'constants/route'

import { type FormValues } from 'modules/Projects/CloudProviderCredentials/types'

import { SecretReferenceTypes, type JSONValue } from 'types'

const removeUnsetFields = (
  secretValue: Record<string, JSONValue>
): Record<string, JSONValue> => {
  return Object.keys(secretValue)
    .filter((key) => secretValue[key])
    .reduce((accumulator: Record<string, JSONValue>, key) => {
      accumulator[key] = secretValue[key]
      return accumulator
    }, {})
}

export const useSubmitForm = (
  projectSecretLocationId: string,
  projectId: string
) => {
  const { makeToast, clearToasts } = Toaster.useToaster()
  const { mutateAsync: createSecretValue } = usePostSecretValueV2({ projectId })
  const navigate = useNavigate()
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const handleSubmit = async (
    newValues: FormValues,
    { setSubmitting }: Pick<FormikHelpers<FormValues>, 'setSubmitting'>
  ) => {
    clearToasts()
    const {
      name,
      provider,
      agent,
      locationId: formSecretLocationId,
      ...secretValue
    } = newValues
    const secretToPersist = removeUnsetFields(secretValue)
    const createSecretValueRequest =
      createCloudProviderCredentialsV2MutationData(
        {
          name,
          matillionHostedAgentId: agent.id,
          secretLocationId: formSecretLocationId ?? projectSecretLocationId,
          secretValue: {
            ...secretToPersist,
            type: provider
          },
          metadata: {
            provider
          }
        },
        false,
        SecretReferenceTypes.CLOUD_PLATFORM_CREDENTIALS
      )
    try {
      const { secretId } = await createSecretValue({
        values: createSecretValueRequest
      })

      makeToast({
        title: t('cloudProviderCredentials.success', {
          credentialName: name
        }),
        message: '',
        type: 'success'
      })

      queryClient.removeQueries([
        QueryKey.SECRET_REFERENCES,
        projectId,
        SecretReferenceTypes.CLOUD_PLATFORM_CREDENTIALS
      ])
      navigate(
        AppRoutes.getAssociateEnvironmentToCredentials(projectId, secretId)
      )
    } catch (e) {
      // handled by axios config
    } finally {
      setSubmitting(false)
    }
  }

  return handleSubmit
}
