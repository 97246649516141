import React from 'react'
import { useTranslation } from 'react-i18next'

import { useFormikContext } from 'formik'

import { Field } from '@matillion/component-library'

import classes from 'components/Form/Form.module.scss'

import { type CreateProjectFormikValueTypes } from 'modules/Projects/CreateProject/CreateProjectForm'
import { renderFormikError } from 'modules/utils'

export const Passphrase: React.FC = () => {
  const { errors, handleBlur, handleChange, touched, values } =
    useFormikContext<CreateProjectFormikValueTypes>()
  const { t } = useTranslation()

  const projectType = values.type || 'common'

  const passphraseError = renderFormikError(
    errors.passphrase,
    Boolean(touched.passphrase)
  )

  const getErrorMessage = React.useMemo(() => {
    if (passphraseError) return t(passphraseError)
    return null
  }, [passphraseError, t])

  return (
    <div data-testid={`${projectType}-passphrase`}>
      <Field
        title={t('fields.passphrase.title')}
        name="passphrase"
        data-testid={`${projectType}-passphrase-input`}
        value={values.passphrase}
        placeholder={t('fields.passphrase.placeholderText')}
        onChange={handleChange}
        onBlur={handleBlur}
        className={classes.Form__SpacingStyles}
        supportText={t('fields.passphrase.supportText')}
        errorText={getErrorMessage}
        hasError={Boolean(errors?.passphrase) && Boolean(touched.passphrase)}
      />
    </div>
  )
}

export default Passphrase
