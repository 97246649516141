import { useEffect, useMemo, type FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'

import { useFormikContext } from 'formik'

import { AutoComplete, Field } from '@matillion/component-library'
import type { Icon } from '@matillion/component-library/dist/components/Input/types'

import { WarehouseLookupTypes } from 'api/createProjectForm/types/WarehouseDefaultsRequest'
import { useMutateWarehouseDefaults } from 'api/hooks'
import { createWarehouseDefaultsMutation } from 'api/mutation'
import { type ErrorResponse } from 'api/types'

import classes from 'components/Form/Form.module.scss'

import { StatusCodes } from 'constants/statusCodes'

import { useFlags } from 'hooks/flags'

import { type CreateProjectFormikValueTypes } from 'modules/Projects/CreateProject/CreateProjectForm'
import { renderFormikError } from 'modules/utils'

export interface SchemaProps {
  isStartingCompute?: boolean
  isFieldEnabled?: boolean
  placeholder?: string
  iconBefore?: Icon
  colorTheme?: string
  listenerField?: keyof CreateProjectFormikValueTypes
}

export const DefaultSchema: FunctionComponent<SchemaProps> = ({
  isStartingCompute = false,
  isFieldEnabled = true,
  placeholder,
  iconBefore,
  colorTheme = 'white',
  listenerField
}) => {
  const {
    errors,
    handleBlur,
    handleChange,
    touched,
    values,
    setFieldTouched,
    setFieldValue,
    initialValues
  } = useFormikContext<CreateProjectFormikValueTypes>()
  const { t } = useTranslation()
  const { enableKeyPairAuthentication } = useFlags()

  const projectType = values.type || 'common'

  const {
    data: schemaDefaultData = [],
    isError,
    isLoading,
    error,
    mutate: mutateSchema
  } = useMutateWarehouseDefaults(WarehouseLookupTypes.SCHEMA)

  const defaultSchemaError = renderFormikError(
    errors.defaultSchema,
    Boolean(touched.defaultSchema)
  )

  const getErrorMessage = useMemo(() => {
    if (isError && isFieldEnabled) {
      const errorDetail = (error?.response?.data as ErrorResponse)?.detail
      const errorResponseStatus = error?.response?.status
      if (
        errorResponseStatus === StatusCodes.BAD_REQUEST &&
        errorDetail !== undefined
      ) {
        return errorDetail
      }
      return t('fields.defaultSchema.error.loadingError')
    }
    if (!schemaDefaultData.length && isFieldEnabled) {
      return t('fields.defaultSchema.error.noItemsFound')
    }
    if (defaultSchemaError) return t(defaultSchemaError)
    return null
  }, [
    defaultSchemaError,
    isFieldEnabled,
    isError,
    schemaDefaultData.length,
    t,
    error
  ])

  const listenerFieldValue = listenerField ? values[listenerField] : ''
  const initialListenerFieldValue = listenerField
    ? initialValues[listenerField]
    : ''

  const catalogPopulated = !!values.catalog && !!values.catalog.id

  useEffect(() => {
    if (
      listenerFieldValue?.id !== initialListenerFieldValue?.id ||
      (!isFieldEnabled && !catalogPopulated)
    ) {
      setFieldTouched('defaultSchema', false)
      setFieldValue('defaultSchema', { id: '', name: '' })
    }
    if (isFieldEnabled || catalogPopulated) {
      mutateSchema({
        values: createWarehouseDefaultsMutation(
          values,
          !!enableKeyPairAuthentication
        )
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isFieldEnabled,
    mutateSchema,
    setFieldTouched,
    values.compute,
    listenerFieldValue
  ])

  const disabled = (isError && !isLoading) || !isFieldEnabled

  const defaultPlaceholder = isError
    ? t('fields.defaultSchema.error.placeholderText')
    : t('fields.defaultSchema.placeholderText')

  return (
    <div data-testid={`${projectType}-default-schema`}>
      <Field
        inputComponent={AutoComplete}
        availableItems={schemaDefaultData.map((item) => ({
          id: item,
          name: item
        }))}
        loading={isLoading}
        title={t('fields.defaultSchema.title')}
        name="defaultSchema"
        data-testid={`${projectType}-default-schema-input`}
        value={values.defaultSchema}
        placeholder={placeholder ?? defaultPlaceholder}
        iconBefore={iconBefore}
        onChange={handleChange}
        onBlur={handleBlur}
        className={classes.Form__SpacingStyles}
        supportText={t('fields.defaultSchema.supportText')}
        errorText={isLoading || isStartingCompute ? undefined : getErrorMessage}
        hasError={
          Boolean(errors?.defaultSchema?.id) &&
          Boolean(touched.defaultSchema) &&
          !isLoading
        }
        required
        disabled={disabled}
        colorTheme={colorTheme}
      />
    </div>
  )
}
