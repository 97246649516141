import { type TFunction } from 'react-i18next'

import { type SummaryField } from 'modules/Projects/StreamingPipelineDashboard/Summary/components/StreamingSummaryBlock/StreamingSummaryBlock'
import {
  formatField,
  formatSecretManager
} from 'modules/Projects/StreamingPipelineDashboard/Summary/StreamingSummary.util'

import {
  STREAMING_DESTINATIONS,
  StreamingDestinationId,
  TABLE_PREFIX_TYPES,
  type AzureBlobDestinationConfigType,
  type DestinationConfigType,
  type S3DestinationConfigType,
  type SnowflakeDestinationConfigType
} from 'types/Streaming'

export const getDestinationSummaryFields = (
  destination: DestinationConfigType,
  isExpanded: boolean,
  t: TFunction
) => {
  switch (destination.type) {
    case StreamingDestinationId.Snowflake:
      return getSnowflakeSummaryFields(destination, isExpanded, t)
    case StreamingDestinationId.S3:
      return getS3SummaryFields(destination, t)
    case StreamingDestinationId.ABS:
      return getAzureBlobSummaryFields(destination, isExpanded, t)
  }
}

const getSnowflakeSummaryFields = (
  destination: SnowflakeDestinationConfigType,
  isExpanded: boolean,
  t: TFunction
): SummaryField[] => {
  const fields: SummaryField[] = [
    {
      heading: t('streamingPipelineDashboard.summary.fields.common.platform'),
      content: formatField(STREAMING_DESTINATIONS[destination.type]?.name)
    },
    {
      heading: t('streamingPipelineDashboard.summary.fields.common.database'),
      content: formatField(destination.database)
    },
    {
      heading: t(
        'streamingPipelineDashboard.summary.fields.destination.warehouse'
      ),
      content: formatField(destination.warehouse)
    }
  ]
  if (isExpanded) {
    fields.push(
      ...[
        {
          heading: t(
            'streamingPipelineDashboard.summary.fields.destination.role'
          ),
          content: formatField(destination.role)
        },
        {
          heading: t(
            'streamingPipelineDashboard.summary.fields.destination.stageSchema'
          ),
          content: formatField(destination.stageSchema)
        },
        {
          heading: t(
            'streamingPipelineDashboard.summary.fields.destination.stage'
          ),
          content: formatField(destination.stageName)
        },
        {
          heading: t(
            'streamingPipelineDashboard.summary.fields.destination.stagePrefix'
          ),
          content: formatField(destination.stagePrefix)
        },
        {
          heading: t(
            'streamingPipelineDashboard.summary.fields.destination.tableSchema'
          ),
          content: formatField(destination.tableSchema)
        },
        {
          heading: t(
            'streamingPipelineDashboard.summary.fields.destination.tableNamePrefix'
          ),
          content: formatField(
            destination.tablePrefixType
              ? TABLE_PREFIX_TYPES[destination.tablePrefixType].name
              : undefined
          )
        },
        {
          heading: t(
            'streamingPipelineDashboard.summary.fields.destination.snowflakeAccount'
          ),
          content: formatField(destination.connection?.accountName)
        },
        {
          heading: t(
            'streamingPipelineDashboard.summary.fields.destination.user'
          ),
          content: formatField(destination.connection?.user)
        },
        {
          heading: t(
            'streamingPipelineDashboard.summary.fields.common.secretManager'
          ),
          content: formatSecretManager(destination.connection?.password?.type)
        },
        {
          heading: t(
            'streamingPipelineDashboard.summary.fields.common.secretName'
          ),
          content: formatField(destination.connection?.password?.name)
        }
      ]
    )
  }
  return fields
}

const getS3SummaryFields = (
  destination: S3DestinationConfigType,
  t: TFunction
): SummaryField[] => {
  return [
    {
      heading: t('streamingPipelineDashboard.summary.fields.common.platform'),
      content: formatField(STREAMING_DESTINATIONS[destination.type]?.name)
    },
    {
      heading: t(
        'streamingPipelineDashboard.summary.fields.destination.bucket'
      ),
      content: formatField(destination.bucket)
    },
    {
      heading: t(
        'streamingPipelineDashboard.summary.fields.destination.prefix'
      ),
      content: formatField(destination.prefix)
    }
  ]
}

const getAzureBlobSummaryFields = (
  destination: AzureBlobDestinationConfigType,
  isExpanded: boolean,
  t: TFunction
): SummaryField[] => {
  const fields: SummaryField[] = [
    {
      heading: t('streamingPipelineDashboard.summary.fields.common.platform'),
      content: formatField(STREAMING_DESTINATIONS[destination.type]?.name)
    },
    {
      heading: t(
        'streamingPipelineDashboard.summary.fields.destination.container'
      ),
      content: formatField(destination.container)
    },
    {
      heading: t(
        'streamingPipelineDashboard.summary.fields.destination.prefix'
      ),
      content: formatField(destination.prefix)
    }
  ]

  if (isExpanded) {
    fields.push(
      {
        heading: t(
          'streamingPipelineDashboard.summary.fields.destination.accountName'
        ),
        content: formatField(destination.accountName)
      },
      {
        heading: t(
          'streamingPipelineDashboard.summary.fields.common.secretManager'
        ),
        content: formatSecretManager(destination.accountKey?.type)
      },
      {
        heading: t(
          'streamingPipelineDashboard.summary.fields.destination.accountKey'
        ),
        content: formatField(destination.accountKey?.name)
      }
    )
  }

  return fields
}
