import { useMutation, type UseMutationOptions } from 'react-query'

import { type AxiosError } from 'axios'

import { deleteProject } from 'api/clients'
import { type DELETEProjectRequest } from 'api/types'

import { useClient } from './useClient'

type Options = UseMutationOptions<
  void,
  AxiosError,
  {
    values: DELETEProjectRequest
  }
>

export const useDeleteProject = (options?: Options) => {
  const client = useClient()

  return useMutation(async (data: { values: DELETEProjectRequest }) => {
    const { values } = data

    return deleteProject(values, client)
  }, options)
}
