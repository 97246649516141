import { useEffect, type FC } from 'react'

import { useFormikContext } from 'formik'
import { isEmpty } from 'lodash'

import {
  useFetchSnowflakeDatabases,
  useFetchSnowflakeRoles,
  useFetchSnowflakeSchemas,
  useFetchSnowflakeStages,
  useFetchSnowflakeWarehouses
} from 'api/hooks/streaming'

import { mapSnowflakeConnectionFormToDefinition } from 'modules/Projects/CreateStreamingPipeline/FormContent/FormContent.util'

import {
  FIELD_NAMES_SNOWFLAKE_DATABASE,
  FIELD_NAMES_SNOWFLAKE_ROLE,
  FIELD_NAMES_SNOWFLAKE_STAGE_NAME,
  FIELD_NAMES_SNOWFLAKE_STAGE_SCHEMA,
  FIELD_NAMES_SNOWFLAKE_TABLE_SCHEMA,
  FIELD_NAMES_SNOWFLAKE_WAREHOUSE
} from './ConfigurePipelineDetailsPage/types'
import { useFormMetadata } from './FormMetadataProvider'
import { type FormValues, type SnowflakeDestinationFormValues } from './types'

export const SnowflakeMetadataLoader: FC = () => {
  const { values, setFieldValue } = useFormikContext<FormValues>()
  const { metadata, setMetadataProperty } = useFormMetadata()
  const { mutateAsync: mutateListRoles } = useFetchSnowflakeRoles()
  const { mutateAsync: mutateListWarehouses, isLoading: isLoadingWarehouses } =
    useFetchSnowflakeWarehouses()
  const { mutateAsync: mutateListDatabases, isLoading: isLoadingDatabases } =
    useFetchSnowflakeDatabases()
  const { mutateAsync: mutateListSchemas, isLoading: isLoadingSchemas } =
    useFetchSnowflakeSchemas()
  const { mutateAsync: mutateListStages, isLoading: isLoadingStages } =
    useFetchSnowflakeStages()
  const mapToAutocompleteItem = (data: string[]) =>
    data.map((item) => ({ id: item, name: item }))

  const destination = values.target as SnowflakeDestinationFormValues

  const connection = mapSnowflakeConnectionFormToDefinition(
    destination.connection
  )
  const isConnectionValid =
    metadata.validation.targetConnection.isError === false
  const selectedAgent = values.agent.id
  const selectedRole = destination.role?.id
  const selectedWarehouse = destination.warehouse?.id
  const selectedDatabase = destination.database?.id
  const selectedStageSchema = destination.stageSchema?.id
  const selectedStageName = destination.stageName?.id
  const selectedTableSchema = destination.tableSchema?.id

  const emptySelection = { id: '', name: '' }

  const clearRoles = () => {
    setMetadataProperty('snowflakeOptions.roles.data', [])
  }

  const clearWarehouses = () => {
    setMetadataProperty('snowflakeOptions.warehouses.data', [])
  }

  const clearDatabases = () => {
    setMetadataProperty('snowflakeOptions.databases.data', [])
  }

  const clearSchemas = () => {
    setMetadataProperty('snowflakeOptions.schemas.data', [])
  }

  const clearStages = () => {
    setMetadataProperty('snowflakeOptions.stages.data', [])
  }

  const clearSelectionIfNotPresentInOptions = (
    options: string[],
    selection: string,
    fieldName: string
  ) => {
    if (selection && !options.includes(selection)) {
      // currently selected option isn't in the option list, so clear it
      setFieldValue(fieldName, emptySelection)
    }
  }

  const loadRoles = () => {
    setMetadataProperty('snowflakeOptions.roles.loading', true)
    mutateListRoles({
      agentId: selectedAgent,
      connection
    }).then((result: string[]) => {
      setMetadataProperty(
        'snowflakeOptions.roles.data',
        mapToAutocompleteItem(result)
      )
      setMetadataProperty('snowflakeOptions.roles.loading', false)
      clearSelectionIfNotPresentInOptions(
        result,
        selectedRole,
        FIELD_NAMES_SNOWFLAKE_ROLE
      )
    })
  }

  const loadWarehouses = () => {
    setMetadataProperty('snowflakeOptions.warehouses.loading', true)
    mutateListWarehouses({
      agentId: selectedAgent,
      connection,
      role: selectedRole
    }).then((result) => {
      setMetadataProperty(
        'snowflakeOptions.warehouses.data',
        mapToAutocompleteItem(result)
      )
      setMetadataProperty('snowflakeOptions.warehouses.loading', false)
      clearSelectionIfNotPresentInOptions(
        result,
        selectedWarehouse,
        FIELD_NAMES_SNOWFLAKE_WAREHOUSE
      )
    })
  }

  const loadDatabases = () => {
    setMetadataProperty('snowflakeOptions.databases.loading', true)
    mutateListDatabases({
      agentId: selectedAgent,
      connection,
      role: selectedRole
    }).then((result) => {
      setMetadataProperty(
        'snowflakeOptions.databases.data',
        mapToAutocompleteItem(result)
      )
      setMetadataProperty('snowflakeOptions.databases.loading', false)
      clearSelectionIfNotPresentInOptions(
        result,
        selectedDatabase,
        FIELD_NAMES_SNOWFLAKE_DATABASE
      )
    })
  }

  const loadSchemas = () => {
    setMetadataProperty('snowflakeOptions.schemas.loading', true)
    mutateListSchemas({
      agentId: selectedAgent,
      connection,
      role: selectedRole,
      database: selectedDatabase
    }).then((result) => {
      setMetadataProperty(
        'snowflakeOptions.schemas.data',
        mapToAutocompleteItem(result)
      )
      setMetadataProperty('snowflakeOptions.schemas.loading', false)
      clearSelectionIfNotPresentInOptions(
        result,
        selectedStageSchema,
        FIELD_NAMES_SNOWFLAKE_STAGE_SCHEMA
      )
      clearSelectionIfNotPresentInOptions(
        result,
        selectedTableSchema,
        FIELD_NAMES_SNOWFLAKE_TABLE_SCHEMA
      )
    })
  }

  const loadStages = () => {
    setMetadataProperty('snowflakeOptions.stages.loading', true)
    mutateListStages({
      agentId: selectedAgent,
      connection,
      role: selectedRole,
      database: selectedDatabase,
      schema: selectedStageSchema
    }).then((result) => {
      setMetadataProperty(
        'snowflakeOptions.stages.data',
        mapToAutocompleteItem(result)
      )
      setMetadataProperty('snowflakeOptions.stages.loading', false)
      clearSelectionIfNotPresentInOptions(
        result,
        selectedStageName,
        FIELD_NAMES_SNOWFLAKE_STAGE_NAME
      )
    })
  }

  useEffect(() => {
    // when connection details change, we should clear downstream metadata
    clearRoles()

    if (isConnectionValid) {
      // our connection details are valid so we can load roles
      loadRoles()
    }
    // use minimal dependencies to limit unnecessary re-rendering
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConnectionValid])

  useEffect(() => {
    // when role changes, we should clear downstream metadata
    clearWarehouses()
    clearDatabases()

    if (isConnectionValid && !isEmpty(selectedRole)) {
      // our connection details are valid, and we have a role selected,
      // so we can load warehouses and databases
      if (!isLoadingWarehouses) {
        loadWarehouses()
      }
      if (!isLoadingDatabases) {
        loadDatabases()
      }
    }
    // use minimal dependencies to limit unnecessary re-rendering
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConnectionValid, selectedRole])

  useEffect(() => {
    // when database changes, we should clear downstream metadata
    clearSchemas()

    if (isConnectionValid && !isEmpty(selectedDatabase)) {
      // our connection details are valid, and we have a database selected,
      // so we can load schemas
      if (!isLoadingSchemas) {
        loadSchemas()
      }
    }
    // use minimal dependencies to limit unnecessary re-rendering
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConnectionValid, selectedDatabase])

  useEffect(() => {
    // when stage schema changes, we should clear downstream metadata
    clearStages()

    if (isConnectionValid && !isEmpty(selectedStageSchema)) {
      // our connection details are valid, and we have a stage schema selected,
      // so we can load stages
      if (!isLoadingStages) {
        loadStages()
      }
    }
    // use minimal dependencies to limit unnecessary re-rendering
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConnectionValid, selectedStageSchema])

  return null
}
