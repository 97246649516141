export enum ColumnKeys {
  branchName = 'name',
  defaultEnvironment = 'defaultEnvironmentName',
  action = 'action'
}

export type BranchTypes = Exclude<ColumnKeys, 'action'>

export type SortTypes = { [key in ColumnKeys]: ColumnKeys }

export interface BranchDataTypes {
  name: string
  defaultEnvironmentName: string
}

export interface SortedBranchDataTypes extends BranchDataTypes {
  id: string
}

export interface ActionCellTypes {
  onEditClick: (item: SortedBranchDataTypes) => void
  onDeleteClick?: (item: SortedBranchDataTypes) => void
  item: SortedBranchDataTypes
}
