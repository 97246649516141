import { type FunctionComponent } from 'react'

import classNames from 'classnames'
import { useFormikContext } from 'formik'

import {
  useGetAssignableStreamingAgents,
  useGetStreamingAgents
} from 'api/hooks/streaming'
import { AgentStatus } from 'api/types'

import formClasses from 'components/Form/Form.module.scss'

import {
  CreateHeader,
  useFormMetadata,
  type FormValues
} from 'modules/Projects/CreateStreamingPipeline/FormContent'

import classes from './CreatePipelinePage.module.scss'
import { Agent, Destination, Name, Source } from './Fields'

const CreatePipelinePage: FunctionComponent = () => {
  const { values } = useFormikContext<FormValues>()
  const {
    metadata: { editing: isEditing }
  } = useFormMetadata()

  const { data: assignableAgents, isLoading: isAssignableAgentsLoading } =
    useGetAssignableStreamingAgents(
      { enabled: !isEditing },
      { enabled: !isEditing }
    )
  const { data: runningAgents, isLoading: isRunningAgentsLoading } =
    useGetStreamingAgents(AgentStatus.RUNNING, { enabled: isEditing })

  const selectedAgent = runningAgents?.find(
    (agent) => agent.agentId === values.agent?.id
  )
  const agents =
    isEditing && selectedAgent
      ? [...assignableAgents, selectedAgent]
      : assignableAgents

  return (
    <div data-testid="configure-streaming-pipeline-start-page">
      <CreateHeader />

      <div
        className={classNames(
          formClasses.Form__InnerWrap,
          formClasses['Form__InnerWrap--space-top'],
          classes.CreatePipelinePage__FormContentContainer
        )}
      >
        <Name />
        <Agent
          agents={agents}
          isAgentsLoading={isAssignableAgentsLoading || isRunningAgentsLoading}
          disabled={isEditing}
        />
        <Source />
        <Destination />
      </div>
    </div>
  )
}

export default CreatePipelinePage
