import { type FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

import classNames from 'classnames'
import { useFormikContext } from 'formik'
import { capitalize } from 'lodash'

import classes from 'components/Form/Form.module.scss'
import { Header, HeaderTitle } from 'components/Header'

import { AppRoutes, PROJECT_DETAILS_ENVIRONMENTS } from 'constants/route'

import { useFlags } from 'hooks/flags'

import { EnvironmentDefaultAccess } from 'modules/Common/Fields'
import * as Sources from 'modules/Common/WarehouseSources'
import {
  EditEnvironmentEnvironmentAgent,
  EditEnvironmentEnvironmentName
} from 'modules/Projects/EditEnvironment/FormContent/Fields'

import { AgentsSecretsHost, WarehouseLabels } from 'types'
import { type CommonFormI } from 'types/FormTypes'

const EditEnvironmentAgentAndCreds: FunctionComponent = () => {
  const { t } = useTranslation()
  const { values } = useFormikContext<CommonFormI>()
  const { agentsSecretsManagement, type } = values
  const { projectId } = useParams()
  const navigate = useNavigate()
  const { enableEnvironmentDefaultAccess } = useFlags()

  const isMatillionHosted =
    agentsSecretsManagement === AgentsSecretsHost.MatillionHosted

  const onCancel = () => {
    navigate(
      AppRoutes.getProjectDetails(projectId!, PROJECT_DETAILS_ENVIRONMENTS)
    )
  }

  const warehouseCredentials = Object.entries(Sources).reduce<JSX.Element[]>(
    (result, [label, Component]) => {
      if (label === capitalize(type)) {
        result.push(
          <Component
            key={label}
            showCancel
            onCancel={onCancel}
            excludeHeader
            isCreateEnvironment
          />
        )
      }
      return result
    },
    []
  )

  return (
    <>
      <Header
        onCancel={onCancel}
        showCancel
        modalContent={t('editEnvironment.modal.content')}
      >
        <HeaderTitle data-testid="edit-environment-title">
          {t('formContent.editEnvironmentCredentials.title', {
            projectType:
              WarehouseLabels[capitalize(type) as keyof typeof WarehouseLabels]
          })}
        </HeaderTitle>
      </Header>
      <div
        className={classNames(
          classes.Form__InnerWrap,
          classes['Form__InnerWrap--space-top']
        )}
      >
        <EditEnvironmentEnvironmentName />
        {!isMatillionHosted && <EditEnvironmentEnvironmentAgent />}
        {enableEnvironmentDefaultAccess && <EnvironmentDefaultAccess />}
        {warehouseCredentials}
      </div>
    </>
  )
}

export default EditEnvironmentAgentAndCreds
