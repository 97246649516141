import { useTranslation } from 'react-i18next'
import { useQueries, type UseQueryResult } from 'react-query'

import type { AxiosError } from 'axios'

import { getStreamingPipelineStatus } from 'api/clients'
import {
  useGetStreamingPipelines,
  useNetworkErrorToast,
  useStreamingClient
} from 'api/hooks/index'
import {
  type ErrorResponse,
  type GETStreamingPipelineStatusResponse,
  type GETStreamingPipelineSummariesResponse
} from 'api/types'

import { QueryKey } from 'constants/endpoint'

import { StreamingPipelineStatus } from 'types/Streaming'

interface WrappedErrorResponse {
  response: {
    data: ErrorResponse
  }
}

const getStatusFromStatusQuery = (
  statusQuery: UseQueryResult<
    GETStreamingPipelineStatusResponse,
    WrappedErrorResponse
  >
) => {
  if (statusQuery.isError) {
    const unregisteredAgentRegex = /^Agent \[[0-9a-fA-F-]{36}] not registered$/
    if (
      unregisteredAgentRegex.test(statusQuery.error?.response?.data?.detail)
    ) {
      return StreamingPipelineStatus.UNKNOWN
    }
    return StreamingPipelineStatus.ERROR
  }
  return statusQuery?.data?.status ?? StreamingPipelineStatus.UNKNOWN
}

export const useGetStreamingPipelineSummaries = (projectId: string) => {
  const streamingClient = useStreamingClient()
  const makeErrorToast = useNetworkErrorToast()
  const { t } = useTranslation()

  const {
    data: pipelines = [],
    isLoading: isLoadingPipelines,
    error: pipelinesError,
    isSuccess: pipelinesSuccess
  } = useGetStreamingPipelines(projectId)

  const statusQueries = useQueries(
    pipelines.map((pipeline) => ({
      queryKey: [QueryKey.STREAMING_PIPELINE_STATUS, pipeline.id],
      queryFn: async () =>
        getStreamingPipelineStatus(
          { projectId, pipelineId: pipeline.id },
          streamingClient
        ),
      enabled: !!pipeline.id
    }))
  ) as Array<
    UseQueryResult<GETStreamingPipelineStatusResponse, WrappedErrorResponse>
  >

  const isLoadingStatuses = statusQueries.some((query) => query.isLoading)

  const pipelinesWithStatuses = pipelines.map((pipeline, index) => ({
    ...pipeline,
    status: getStatusFromStatusQuery(statusQueries[index])
  })) as GETStreamingPipelineSummariesResponse

  const statusSuccess = statusQueries.every((query) => query.isSuccess)
  const statusError = pipelinesWithStatuses.some(
    (pipeline) => pipeline.status === StreamingPipelineStatus.ERROR
  )

  const errors: AxiosError[] = pipelinesError ? [pipelinesError] : []

  if (!isLoadingStatuses && statusError) {
    makeErrorToast({ message: t('streamingListing.statusError') })
    statusQueries.forEach((query) => {
      if (query.error) {
        errors.push(query.error as AxiosError)
      }
    })
  }

  return {
    data: pipelinesWithStatuses,
    isLoading: isLoadingPipelines || isLoadingStatuses,
    isError: pipelinesError,
    isStatusError: statusError,
    errors: errors,
    isSuccess: pipelinesSuccess && statusSuccess
  }
}
