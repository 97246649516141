import classNames from 'classnames'
import { Formik, type FormikValues } from 'formik'

import { ProgressBar } from '@matillion/component-library'

import { ConfigureForm } from 'components/Form'
import classes from 'components/Form/Form.module.scss'
import { type FormWizardProps } from 'components/Form/types'
import { GridLayout } from 'components/GridLayout'

const Form = <T extends FormikValues>({
  stepInfo,
  formikValues,
  formValues,
  translationPrefix,
  children,
  onCancel,
  containerClassName,
  ...rest
}: FormWizardProps<T>) => {
  return (
    <>
      {stepInfo?.progress ? (
        <ProgressBar
          data-testid="form-progress-bar"
          progress={stepInfo.progress}
        />
      ) : null}
      <GridLayout className={containerClassName}>
        <div className={classes.Form}>
          <Formik<T> {...formikValues}>
            <ConfigureForm
              isLastStep={stepInfo?.isLastStep}
              translationPrefix={translationPrefix}
              validationSchema={formikValues.validationSchema}
              formValues={formValues}
              onCancel={onCancel}
              {...rest}
            >
              <div
                className={classNames(
                  classes.Form__OuterWrap,
                  classes['Form__OuterWrap--space-top']
                )}
              >
                {children}
              </div>
            </ConfigureForm>
          </Formik>
        </div>
      </GridLayout>
    </>
  )
}
export default Form
