import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from 'react-query'

import { type AxiosError } from 'axios'

import { Toaster } from '@matillion/component-library'

import { putStreamingPipeline } from 'api/clients'
import { useNetworkErrorToast, useStreamingClient } from 'api/hooks/index'
import { type ErrorResponse } from 'api/types'

import { QueryKey } from 'constants/endpoint'

import type { StreamingPipelineDefinition } from 'types/Streaming'

type TError = AxiosError<ErrorResponse>
interface TVariables {
  pipelineDefinition: StreamingPipelineDefinition
}

export const usePutStreamingPipeline = (projectId: string) => {
  const streamingClient = useStreamingClient()
  const makeErrorToast = useNetworkErrorToast()
  const { clearToasts, makeToast } = Toaster.useToaster()
  const { t } = useTranslation()
  const queryClient = useQueryClient()

  return useMutation<StreamingPipelineDefinition, TError, TVariables>({
    mutationKey: [QueryKey.STREAMING_PIPELINE_UPDATE, projectId],
    mutationFn: async ({ pipelineDefinition }) => {
      makeToast({
        title: t('streamingActions.update.sending', {
          pipelineName: pipelineDefinition.name
        }),
        message: '',
        type: 'info'
      })
      return putStreamingPipeline(
        pipelineDefinition,
        { projectId },
        streamingClient
      )
    },
    onError: (error) => {
      makeErrorToast({ message: error.response?.data.detail ?? error.message })
    },
    onSuccess: async (data, { pipelineDefinition }) => {
      const invalidatePipelineList = queryClient.invalidateQueries({
        queryKey: [QueryKey.STREAMING_PIPELINES_LIST, projectId]
      })
      const invalidatePipeline = queryClient.invalidateQueries({
        queryKey: [
          QueryKey.STREAMING_PIPELINE,
          projectId,
          pipelineDefinition.id
        ]
      })
      await Promise.all([invalidatePipelineList, invalidatePipeline])

      clearToasts()
      makeToast({
        title: t('streamingActions.update.success', {
          pipelineName: data.name
        }),
        message: '',
        type: 'success'
      })
    }
  })
}
