import { type FunctionComponent, type ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { useFormikContext } from 'formik'
import { v4 as uuidv4 } from 'uuid'

import { RedWarning } from 'components/Icon'
import ConfirmCancelModal from 'components/Modal/ProjectExplorerModal'

import classes from 'modules/Projects/ProjectsListing/DeleteProject/DeleteProjectForm.module.scss'
import {
  getValidationSchema,
  type FormValues
} from 'modules/Projects/ProjectsListing/DeleteProject/DeleteProjectForm.util'
import { ConfirmProjectName } from 'modules/Projects/ProjectsListing/DeleteProject/Fields'

interface DeleteProjectModalProps {
  resetModals: () => void
  isLoading: boolean
  projectName: string
}

const DeleteProjectModal: FunctionComponent<DeleteProjectModalProps> = ({
  resetModals,
  isLoading,
  projectName
}) => {
  const { t } = useTranslation()
  const { handleSubmit, values } = useFormikContext<FormValues>()

  const content: ReactElement = (
    <span className={classes.DeleteProjectContent}>
      {t('deleteProject.supportText', { projectName })
        .split('|||')
        .map((element) => (
          <span
            key={uuidv4()}
            className={classes.DeleteProjectContent__Paragraph}
          >
            {element}
          </span>
        ))}
    </span>
  )

  return (
    <ConfirmCancelModal
      heading={t('deleteProject.title')}
      content={content}
      additionalContent={<ConfirmProjectName />}
      validate={t('deleteProject.buttons.submit')}
      onCancel={resetModals}
      onValidate={handleSubmit}
      icon={<RedWarning />}
      waiting={isLoading}
      isValidateDisabled={!getValidationSchema().isValidSync(values)}
    />
  )
}

export default DeleteProjectModal
