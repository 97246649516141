import { useMemo, type FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'

import classNames from 'classnames'
import { useFormikContext } from 'formik'

import { AutoComplete, Field } from '@matillion/component-library'

import { useGetEnvironmentAgents } from 'api/hooks'

import { useAgentCloudProviderFieldWidget } from 'hooks/useAgentCloudProviderFieldWidget'

import { type FormValues } from 'modules/Secrets/CreateSecretDefinitionForm/CreateSecretDefinitionForm.utils'
import classes from 'modules/Secrets/SecretDefinitionFormContent/SecretDefinitionFormContent.module.scss'
import { renderFormikError } from 'modules/utils'

const Agent: FunctionComponent = () => {
  const { errors, handleBlur, handleChange, touched, values } =
    useFormikContext<FormValues>()
  const { t } = useTranslation()
  const {
    data: listOfAgents = [],
    isError: agentIsError,
    isLoading: agentIsLoading
  } = useGetEnvironmentAgents()

  const { agentCloudProviderFieldWidget } = useAgentCloudProviderFieldWidget()
  const agentError = renderFormikError(errors.agent, Boolean(touched.agent))

  const getErrorMessage = useMemo(() => {
    if (agentIsError) {
      return t('secrets.definitionForm.fields.agent.error.loadingError')
    }

    if (!listOfAgents.length) {
      return t('secrets.definitionForm.fields.agent.error.noItemsFound')
    }

    if (agentError) {
      return t(agentError)
    }

    return ''
  }, [agentError, agentIsError, listOfAgents.length, t])

  return (
    <div data-testid="secret-definition-agent">
      <Field
        inputComponent={AutoComplete}
        loading={agentIsLoading}
        availableItems={listOfAgents.map((item) => ({
          id: item.agentId,
          name: item.name,
          agentCloudProvider: item.cloudProviderId.toString()
        }))}
        givenWidgets={agentCloudProviderFieldWidget()}
        title={t('secrets.definitionForm.fields.agent.title')}
        name="agent"
        data-testid="secret-definition-agent-input"
        value={values.agent}
        placeholder={
          agentIsError
            ? t('secrets.definitionForm.fields.agent.error.placeholderText')
            : t('secrets.definitionForm.fields.agent.placeholderText')
        }
        onChange={handleChange}
        onBlur={handleBlur}
        className={classNames(
          classes.SecretDefinitionFormContent__Field,
          classes['SecretDefinitionFormContent__Field--normal']
        )}
        supportText={t('secrets.definitionForm.fields.agent.supportText')}
        errorText={agentIsLoading ? undefined : getErrorMessage}
        hasError={
          Boolean(errors?.agent?.id) &&
          Boolean(touched.agent) &&
          !agentIsLoading
        }
        disabled={agentIsError || !listOfAgents.length}
      />
    </div>
  )
}

export default Agent
