import { type FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'

import { Field as FormikField, type FieldProps } from 'formik'

import {
  AutoComplete,
  Field,
  type AutoCompleteItem
} from '@matillion/component-library'

import { availableRoleItems } from 'modules/Projects/AccessListing/AccessListing.util'
import classes from 'modules/Projects/AccessListing/AddUser/AddUser.module.scss'
import { resolveErrorText } from 'modules/Projects/AccessListing/AddUser/AddUser.util'

const Role: FunctionComponent = () => {
  const { t } = useTranslation()

  return (
    <FormikField name="role">
      {({ field, meta, form: { setFieldValue } }: FieldProps<string>) => (
        <div data-testid="select-role">
          <Field
            {...field}
            data-testid="select-role-input"
            className={classes['AddUser__Field--spaced']}
            title={t('accessListing.addUser.fields.role.title')}
            name="role"
            placeholder={t('accessListing.addUser.fields.role.placeholderText')}
            inputComponent={AutoComplete}
            availableItems={availableRoleItems}
            errorText={resolveErrorText(t, meta)}
            value={field.value || null}
            onChange={async (e: { target: AutoCompleteItem }) =>
              setFieldValue('role', e.target.value?.id)
            }
          />
        </div>
      )}
    </FormikField>
  )
}

export default Role
