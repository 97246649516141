import { useCallback, useEffect, useMemo, type FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'

import { useFormikContext } from 'formik'

import {
  AutoComplete,
  Field,
  type AutoCompleteItem
} from '@matillion/component-library'

import { useGetSecretNames } from 'api/createProjectForm/hooks'

import {
  createAutoCompleteItems,
  FormFields,
  resolveFormikError,
  type FormValues
} from 'modules/Secrets/CreateSecretDefinitionForm/CreateSecretDefinitionForm.utils'
import classes from 'modules/Secrets/SecretDefinitionFormContent/SecretDefinitionFormContent.module.scss'

import { AgentCloudProvider } from 'types/AgentCloudProvider'

interface SecretNameProps {
  locationId: string
}

const SecretName: FunctionComponent<SecretNameProps> = ({ locationId }) => {
  const { t } = useTranslation()

  const { getFieldMeta, getFieldProps, getFieldHelpers, values } =
    useFormikContext<FormValues>()
  const fieldProps = getFieldProps(FormFields.secretName)
  const fieldMeta = getFieldMeta(FormFields.secretName)
  const { setTouched, setValue } = getFieldHelpers(FormFields.secretName)
  const agentCloudProvider = values.agent?.agentCloudProvider

  const agentId = values.agent?.id
  const placeholder =
    agentCloudProvider === AgentCloudProvider.AZURE
      ? t('secrets.definitionForm.fields.azureSecretName.placeholder')
      : t('secrets.definitionForm.fields.secretName.placeholder')
  const isEnabled = Boolean(agentId) && Boolean(locationId)
  const {
    data: secretNamesData,
    isError,
    isLoading,
    refetch
  } = useGetSecretNames(locationId, agentId, {
    enabled: isEnabled
  })

  const refetchOnOpen = useCallback(async () => {
    await refetch()
  }, [refetch])

  const errorText = useMemo(() => {
    if (isError) {
      return t('secrets.definitionForm.fields.secretName.error.loadingError')
    }

    if (secretNamesData?.length === 0) {
      return t('secrets.definitionForm.fields.secretName.error.noItemsFound')
    }

    return resolveFormikError(t, fieldMeta)
  }, [isError, secretNamesData, t, fieldMeta])

  useEffect(() => {
    setValue('')
    setTouched(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agentId, locationId])

  return (
    <div data-testid="secret-definition-secret-name">
      <Field
        {...fieldProps}
        disabled={isError || !isEnabled}
        loading={isLoading}
        data-testid="secret-definition-secret-name-input"
        className={classes['SecretDefinitionFormContent__Field--normal']}
        title={t('secrets.definitionForm.fields.secretName.title')}
        placeholder={placeholder}
        inputComponent={AutoComplete}
        availableItems={createAutoCompleteItems(secretNamesData ?? [])}
        errorText={errorText}
        value={fieldProps.value || null}
        onChange={useCallback(
          async (e: { target: AutoCompleteItem }) =>
            setValue(e.target.value?.id),
          [setValue]
        )}
        onOpen={refetchOnOpen}
      />
    </div>
  )
}

export default SecretName
