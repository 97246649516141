import { useMutation, type UseMutationOptions } from 'react-query'

import { type AxiosError } from 'axios'

import { patchSecretValueV2 } from 'api/clients'
import { useClient } from 'api/hooks'
import { type PATCHSecretValueV2Request } from 'api/types'

import { QueryKey } from 'constants/endpoint'

type TError = AxiosError<unknown>
interface TVariables {
  values: PATCHSecretValueV2Request
}

type Options = UseMutationOptions<unknown, AxiosError, TVariables>

/**
 * Modifies a secret value for users with or without existing projects.
 *
 * @param {string|undefined} projectId - The ID of a project you wish to
 *          associate the secret value to. If the secret was previously unassociated with a project
 *          passing this value will associate the secret with the project.
 * @param {string} secretReferenceId - The ID of a secret you wish to
 *          to modify. If the secret was previously unassociated with a project
 *          passing this value will associate the secret with the project.
 * @param {Object|undefined} options - useQuery options
 * @version 2
 */
export const usePatchSecretValueV2 = (options?: Options) => {
  const client = useClient({ showErrorDetailMessage: false })

  return useMutation<unknown, TError, TVariables>(
    [QueryKey.PATCH_SECRET_VALUE],
    async (data: { values: PATCHSecretValueV2Request }) => {
      const { values } = data
      const { projectId, secretValue, agentId, secretReferenceId } = values
      return patchSecretValueV2(
        secretReferenceId,
        { secretValue, agentId },
        { projectId },
        client
      )
    },
    options
  )
}
