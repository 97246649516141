import { type FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { Icon } from '@matillion/component-library'

import { useCheckPermissions } from 'api/hooks'

import EllipsesActionItem, {
  type ThemeType
} from 'components/EllipsesActionItem/EllipsesActionItem'

import { PermissionsType } from 'types'
import { ResourceType } from 'types/ResourceTypes'

import { type ActionCellTypes } from '.'

const ActionCell: FunctionComponent<ActionCellTypes> = ({
  onEditClick,
  onDeleteClick,
  item
}) => {
  const { t } = useTranslation()
  const { projectId } = useParams()
  const { data: editPermission } = useCheckPermissions({
    resourceType: ResourceType.Project,
    resourceId: projectId!,
    permission: PermissionsType.EditBranches
  })
  const { data: deletePermission } = useCheckPermissions({
    resourceType: ResourceType.Project,
    resourceId: projectId!,
    permission: PermissionsType.DeleteBranches
  })

  const handleEditClick = () => {
    onEditClick(item)
  }

  const handleDeleteClick = () => {
    if (onDeleteClick) {
      onDeleteClick(item)
    }
  }

  return editPermission || deletePermission ? (
    <EllipsesActionItem
      actions={[
        ...(editPermission
          ? [
              {
                title: t('branchesListing.actions.edit'),
                action: handleEditClick,
                icon: <Icon.Pencil />,
                dataTestId: 'ellipses-action-edit-branch'
              }
            ]
          : []),
        ...(onDeleteClick !== undefined && deletePermission
          ? [
              {
                title: t('branchesListing.actions.delete'),
                action: handleDeleteClick,
                icon: <Icon.Trash />,
                type: 'danger' as ThemeType,
                dataTestId: 'ellipses-action-delete-branch'
              }
            ]
          : [])
      ]}
    />
  ) : null
}

export default ActionCell
