import { useCallback, type FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'

import { type AutoCompleteItemId } from '@matillion/component-library'

import { OAuthParameterType, type OAuthParameter } from 'api/types'

import { FormInputDropdown } from 'modules/FormsContent/CreateOAuth/Fields/FormInputDropdown'
import { FormInputString } from 'modules/FormsContent/CreateOAuth/Fields/FormInputString'

import capitaliseSentenceFirstLetter from 'utils/capitaliseSentenceFirstLetter/capitaliseSentenceFirstLetter'

export interface ParametersProps {
  oAuthParameter?: OAuthParameter[]
}
export const Parameters: FunctionComponent<ParametersProps> = ({
  oAuthParameter
}) => {
  const { i18n, t } = useTranslation()

  const getParameterInputs = useCallback(() => {
    if (!oAuthParameter) {
      return false
    }

    const paramInputs = oAuthParameter.map((x) => {
      const i18nKey = `secrets.createOAuth.parameters.${x.resourceId}`
      let title = t(i18nKey)

      if (!i18n.exists(i18nKey)) {
        if (x.defaultName) {
          title = x.defaultName
        } else {
          title = x.id
        }
      }

      if (x.type === OAuthParameterType.Textbox) {
        return (
          <FormInputString
            key={x.id}
            title={capitaliseSentenceFirstLetter(title)}
            formikFormName={x.id}
            placeholderText={t('genericField.textbox.placeholderText', {
              fieldName: title
            })}
            inputType={x?.password ? 'password' : 'text'}
          />
        )
      } else if (
        x.type === OAuthParameterType.Dropdown &&
        x.options &&
        x.options?.length > 0
      ) {
        const list: AutoCompleteItemId[] = x.options.map((o) => ({
          id: o.id,
          name: t(`secrets.createOAuth.options.${o.resourceId}`)
        }))

        return (
          <FormInputDropdown
            key={x.id}
            title={capitaliseSentenceFirstLetter(title)}
            formikFormName={x.id}
            availableItems={list}
            placeholderText={t('genericField.dropdown.placeholderText', {
              fieldName: title
            })}
          />
        )
      } else {
        // if the schema gives us a type that we cannot handle, or dropdown doesn't have options defined, then it will not display anything
        return false
      }
    })

    return paramInputs
  }, [i18n, oAuthParameter, t])

  return <>{getParameterInputs()}</>
}
