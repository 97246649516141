import { useQuery, type UseQueryOptions } from 'react-query'

import { type AxiosError } from 'axios'

import { getSecretReferences } from 'api/clients'
import { type GETSecretReferencesResponse } from 'api/types'

import { QueryKey } from 'constants/endpoint'

import { SecretReferenceTypes } from 'types'

import { useClient } from './useClient'

type TData = GETSecretReferencesResponse
type TError = AxiosError<unknown>
type Options = Omit<
  UseQueryOptions<TData, TError, TData>,
  'queryKey' | 'queryFn'
>

export const useGetSecretReferences = (
  projectId: string,
  secretType:
    | SecretReferenceTypes
    | SecretReferenceTypes[] = SecretReferenceTypes.PASSWORD,
  options?: Options
) => {
  const client = useClient()

  return useQuery<TData, TError>(
    [QueryKey.SECRET_REFERENCES, projectId, secretType],
    async () =>
      getSecretReferences(
        {
          projectId,
          secretType
        },
        client
      ),
    options
  )
}
