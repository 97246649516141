import { type TFunction } from 'react-i18next'

import { isEmpty } from 'lodash'

import {
  type EnvironmentAgent,
  type POSTStreamingPipelineRequestValues
} from 'api/types'

import {
  CREATE_STREAMING_PIPELINE_DETAILS,
  CREATE_STREAMING_PIPELINE_START
} from 'constants/route'
import { NO_DATA } from 'constants/strings'

import {
  DEFAULT_SOURCE_PORTS,
  hasDatabaseProperty,
  hasPdbProperty,
  SECRET_MANAGERS,
  SecretManagerId,
  STREAMING_DESTINATIONS,
  STREAMING_SOURCES,
  StreamingDestinationId,
  StreamingSourceId,
  TABLE_PREFIX_TYPES,
  TablePrefixTypeId,
  TEMPORAL_MAPPINGS,
  TemporalMappingId,
  TRANSFORMATION_TYPES,
  TransformationTypeId,
  type AzureBlobDestinationConfigType,
  type DestinationConfigType,
  type ExcludedSecretManagerId,
  type S3DestinationConfigType,
  type SnapshotType,
  type SnowflakeConnectionType,
  type SnowflakeDestinationConfigType,
  type SourceConfigType,
  type StreamingPipelineDefinition
} from 'types/Streaming'
import {
  DB_APPLICATION_INTENT,
  hasReadReplicaProperty
} from 'types/Streaming/engine'

import {
  type AzureBlobDestinationFormValues,
  type CreateStreamingPipelineFormStepsType,
  type DestinationFormValues,
  type FormValues,
  type S3DestinationFormValues,
  type SnowflakeConnectionFormValues,
  type SnowflakeDestinationFormValues,
  type SourceConnectionFormValues,
  type SourceFormValues
} from './types'

export const steps: CreateStreamingPipelineFormStepsType = {
  [CREATE_STREAMING_PIPELINE_START]: {
    previousStep: undefined,
    currentStep: CREATE_STREAMING_PIPELINE_START,
    nextStep: CREATE_STREAMING_PIPELINE_DETAILS
  },
  [CREATE_STREAMING_PIPELINE_DETAILS]: {
    previousStep: CREATE_STREAMING_PIPELINE_START,
    currentStep: CREATE_STREAMING_PIPELINE_DETAILS,
    nextStep: undefined
  }
}

const supportsDatabaseProperty = (sourceId: StreamingSourceId) => {
  return (
    sourceId === StreamingSourceId.Postgres ||
    sourceId === StreamingSourceId.SQLServer ||
    sourceId === StreamingSourceId.Oracle
  )
}

const supportsPdbProperty = (sourceId: StreamingSourceId) => {
  return sourceId === StreamingSourceId.Oracle
}

const defaultSnapshotConfig: SnapshotType = {
  initial: {
    enabled: false
  },
  onDemandSnapshot: {
    enabled: false
  }
}

export const getDefaultSourceFormValues = (
  sourceId: StreamingSourceId
): SourceFormValues => {
  return {
    type: {
      id: sourceId,
      name: STREAMING_SOURCES[sourceId].name
    },
    connection: {
      host: '',
      port: DEFAULT_SOURCE_PORTS[sourceId],
      user: '',
      database: supportsDatabaseProperty(sourceId) ? '' : undefined,
      pdb: supportsPdbProperty(sourceId) ? '' : undefined,
      password: {
        type: SECRET_MANAGERS[SecretManagerId.AWS_SECRETS_MANAGER],
        name: ''
      },
      jdbcProperties: {}
    },
    tables: [],
    snapshot: defaultSnapshotConfig,
    readReplica: false
  }
}

export const getDefaultDestinationFormValues = (
  destinationId: StreamingDestinationId
) => {
  switch (destinationId) {
    case StreamingDestinationId.Snowflake:
      return {
        type: STREAMING_DESTINATIONS[StreamingDestinationId.Snowflake],
        connection: {
          accountName: '',
          user: '',
          password: {
            type: SECRET_MANAGERS[SecretManagerId.AWS_SECRETS_MANAGER],
            name: ''
          },
          jdbcProperties: {}
        },
        role: {
          id: '',
          name: ''
        },
        warehouse: {
          id: '',
          name: ''
        },
        database: {
          id: '',
          name: ''
        },
        stageSchema: {
          id: '',
          name: ''
        },
        stageName: {
          id: '',
          name: ''
        },
        stagePrefix: '',
        tableSchema: {
          id: '',
          name: ''
        },
        tablePrefixType:
          TABLE_PREFIX_TYPES[TablePrefixTypeId.SOURCE_DATABASE_AND_SCHEMA],
        transformationType:
          TRANSFORMATION_TYPES[TransformationTypeId.COPY_TABLE],
        temporalMapping: TEMPORAL_MAPPINGS[TemporalMappingId.NATIVE]
      }
    case StreamingDestinationId.S3:
      return {
        type: STREAMING_DESTINATIONS[StreamingDestinationId.S3],
        bucket: '',
        prefix: ''
      }
    case StreamingDestinationId.ABS:
      return {
        type: STREAMING_DESTINATIONS[StreamingDestinationId.ABS],
        container: '',
        prefix: '',
        accountName: '',
        accountKey: {
          type: SECRET_MANAGERS[SecretManagerId.AZURE_KEY_VAULT],
          name: ''
        }
      }
  }
}

export const initialValues: FormValues = {
  name: '',
  agent: {
    id: '',
    name: ''
  },
  target: getDefaultDestinationFormValues(StreamingDestinationId.Snowflake),
  source: getDefaultSourceFormValues(StreamingSourceId.Postgres),
  engine: {
    advanced: {}
  }
}

const mapSourceFormToDefinition = (source: SourceFormValues) => {
  return {
    type: source.type.id,
    connection: mapSourceConnectionFormToDefinition(source.connection),
    tables: source.tables,
    snapshot: mapSourceSnapshotFormToDefinition(source.snapshot)
  }
}

const mapSourceSnapshotFormToDefinition = (
  snapshot: SnapshotType
): SnapshotType => {
  return {
    ...snapshot,
    onDemandSnapshot: {
      ...snapshot.onDemandSnapshot,
      signalTable: snapshot.onDemandSnapshot.enabled
        ? snapshot.onDemandSnapshot.signalTable
        : undefined
    }
  }
}

export const mapSourceConnectionFormToDefinition = (
  connection: SourceConnectionFormValues
) => {
  return {
    host: getStringIfNotEmpty(connection.host),
    port: connection.port,
    database: getStringIfNotEmpty(connection.database),
    pdb: getStringIfNotEmpty(connection.pdb),
    user: getStringIfNotEmpty(connection.user),
    password: {
      type: connection.password.type.id,
      name: getStringIfNotEmpty(connection.password.name)
    },
    jdbcProperties: connection.jdbcProperties
  }
}

const mapSourceToForm = (source: SourceConfigType, isReadReplica: boolean) => {
  const sourceSecretManager = getMappedSecretManager(
    source.connection?.password?.type
  )
  return {
    type: STREAMING_SOURCES[source.type],
    connection: {
      host: getString(source.connection?.host),
      port: source.connection?.port ?? DEFAULT_SOURCE_PORTS[source.type],
      database: hasDatabaseProperty(source)
        ? getString(source.connection?.database)
        : undefined,
      pdb: hasPdbProperty(source)
        ? getString(source.connection?.pdb)
        : undefined,
      user: getString(source.connection?.user),
      password: {
        type: {
          id: sourceSecretManager.id,
          name: sourceSecretManager.name
        },
        name: getString(source.connection?.password?.name)
      },
      jdbcProperties: source.connection?.jdbcProperties ?? {}
    },
    tables: source.tables ?? [],
    snapshot: source.snapshot ?? defaultSnapshotConfig,
    readReplica: isReadReplica
  }
}

export const mapSnowflakeConnectionFormToDefinition = (
  snowflakeConnectionForm: SnowflakeConnectionFormValues
): SnowflakeConnectionType => {
  return {
    accountName: getStringIfNotEmpty(snowflakeConnectionForm.accountName),
    user: getStringIfNotEmpty(snowflakeConnectionForm.user),
    password: {
      type: snowflakeConnectionForm.password.type.id,
      name: getStringIfNotEmpty(snowflakeConnectionForm.password.name)
    },
    jdbcProperties: snowflakeConnectionForm.jdbcProperties
  }
}

export const mapDestinationFormToDefinition = (
  destination: DestinationFormValues
) => {
  switch (destination.type.id) {
    case StreamingDestinationId.Snowflake: {
      const snowflakeDestination = destination as SnowflakeDestinationFormValues
      return {
        type: snowflakeDestination.type.id,
        connection: mapSnowflakeConnectionFormToDefinition(
          snowflakeDestination.connection
        ),
        role: getStringIfNotEmpty(snowflakeDestination.role.id),
        warehouse: getStringIfNotEmpty(snowflakeDestination.warehouse.id),
        database: getStringIfNotEmpty(snowflakeDestination.database.id),
        stageSchema: getStringIfNotEmpty(snowflakeDestination.stageSchema.id),
        stageName: getStringIfNotEmpty(snowflakeDestination.stageName.id),
        stagePrefix: getStringIfNotEmpty(snowflakeDestination.stagePrefix),
        tablePrefixType: snowflakeDestination.tablePrefixType.id,
        tableSchema: getStringIfNotEmpty(snowflakeDestination.tableSchema.id),
        transformationType: snowflakeDestination.transformationType.id,
        temporalMapping: snowflakeDestination.temporalMapping.id
      }
    }
    case StreamingDestinationId.S3: {
      const s3Destination = destination as S3DestinationFormValues
      return {
        type: s3Destination.type.id,
        bucket: getStringIfNotEmpty(s3Destination.bucket),
        prefix: getStringIfNotEmpty(s3Destination.prefix)
      }
    }
    case StreamingDestinationId.ABS: {
      const azureBlobDestination = destination as AzureBlobDestinationFormValues
      return {
        type: azureBlobDestination.type.id,
        container: getStringIfNotEmpty(azureBlobDestination.container),
        prefix: getStringIfNotEmpty(azureBlobDestination.prefix),
        accountName: getStringIfNotEmpty(azureBlobDestination.accountName),
        accountKey: {
          type: azureBlobDestination.accountKey.type.id,
          name: getStringIfNotEmpty(azureBlobDestination.accountKey.name)
        }
      }
    }
  }
}

const mapSnowflakeDestinationToForm = (
  destination: SnowflakeDestinationConfigType
) => {
  const destinationSecretManager = getMappedSecretManager(
    destination.connection?.password?.type
  )
  const tablePrefixType = destination.tablePrefixType
    ? TABLE_PREFIX_TYPES[destination.tablePrefixType]
    : TABLE_PREFIX_TYPES[TablePrefixTypeId.SOURCE_DATABASE_AND_SCHEMA]
  const transformationType = destination.transformationType
    ? TRANSFORMATION_TYPES[destination.transformationType]
    : TRANSFORMATION_TYPES[TransformationTypeId.COPY_TABLE]
  const temporalMapping = destination.temporalMapping
    ? TEMPORAL_MAPPINGS[destination.temporalMapping]
    : TEMPORAL_MAPPINGS[TemporalMappingId.NATIVE]

  return {
    type: STREAMING_DESTINATIONS[destination.type],
    connection: {
      accountName: getString(destination.connection?.accountName),
      user: getString(destination.connection?.user),
      password: {
        type: destinationSecretManager,
        name: getString(destination.connection?.password?.name)
      },
      jdbcProperties: destination.connection?.jdbcProperties ?? {}
    },
    role: {
      id: getString(destination.role),
      name: getString(destination.role)
    },
    warehouse: {
      id: getString(destination.warehouse),
      name: getString(destination.warehouse)
    },
    database: {
      id: getString(destination.database),
      name: getString(destination.database)
    },
    stageSchema: {
      id: getString(destination.stageSchema),
      name: getString(destination.stageSchema)
    },
    stageName: {
      id: getString(destination.stageName),
      name: getString(destination.stageName)
    },
    stagePrefix: getString(destination.stagePrefix),
    tablePrefixType,
    tableSchema: {
      id: getString(destination.tableSchema),
      name: getString(destination.tableSchema)
    },
    transformationType,
    temporalMapping
  }
}

const mapS3DestinationToForm = (destination: S3DestinationConfigType) => ({
  type: STREAMING_DESTINATIONS[destination.type],
  bucket: getString(destination.bucket),
  prefix: getString(destination.prefix)
})

const mapAzureBlobDestinationToForm = (
  destination: AzureBlobDestinationConfigType
) => ({
  type: STREAMING_DESTINATIONS[destination.type],
  container: getString(destination.container),
  prefix: getString(destination.prefix),
  accountName: getString(destination.accountName),
  accountKey: {
    type: getMappedSecretManager(destination.accountKey?.type),
    name: getString(destination.accountKey?.name)
  }
})

const mapDestinationToForm = (
  destination: DestinationConfigType
): DestinationFormValues => {
  switch (destination.type) {
    case StreamingDestinationId.Snowflake:
      return mapSnowflakeDestinationToForm(destination)
    case StreamingDestinationId.S3:
      return mapS3DestinationToForm(destination)
    case StreamingDestinationId.ABS:
      return mapAzureBlobDestinationToForm(destination)
  }
}

export const mapFormToPipelineDefinition: (
  formValues: FormValues
) => POSTStreamingPipelineRequestValues = (formValues) => {
  const engineProperties = formValues.engine.advanced
  if (formValues.source.readReplica) {
    engineProperties[DB_APPLICATION_INTENT.property] =
      DB_APPLICATION_INTENT.values.READ_ONLY
  }

  const pipelineDefinition: POSTStreamingPipelineRequestValues = {
    name: formValues.name,
    agentId: formValues.agent.id,
    source: mapSourceFormToDefinition(formValues.source),
    target: mapDestinationFormToDefinition(formValues.target),
    engine: { advanced: engineProperties }
  }
  return pipelineDefinition
}

export const mapFormToUpdatePipelineDefinition: (
  formValues: FormValues,
  pipelineId: string
) => StreamingPipelineDefinition = (formValues, pipelineId) => {
  return {
    ...mapFormToPipelineDefinition(formValues),
    id: pipelineId
  }
}

const getString = (value: string | undefined) => value ?? ''

const getStringIfNotEmpty = (value?: string) =>
  !isEmpty(value) ? value : undefined

export const getMappedSecretManager = (secretManagerId?: SecretManagerId) => {
  if (secretManagerId && secretManagerId in SECRET_MANAGERS) {
    return SECRET_MANAGERS[secretManagerId as ExcludedSecretManagerId]
  } else {
    return SECRET_MANAGERS[SecretManagerId.AWS_SECRETS_MANAGER]
  }
}

export const mapPipelineDefinitionToForm: (
  pipelineDefinition: StreamingPipelineDefinition,
  agents: EnvironmentAgent[]
) => FormValues = (pipelineDefinition, agents) => {
  const advanced = { ...pipelineDefinition.engine?.advanced }
  const isReadReplica =
    pipelineDefinition.source.type === StreamingSourceId.SQLServer &&
    hasReadReplicaProperty(advanced)
  if (isReadReplica) {
    // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
    delete advanced[DB_APPLICATION_INTENT.property]
  }

  return {
    name: pipelineDefinition.name,
    agent: {
      id: pipelineDefinition.agentId,
      name:
        agents.find((agent) => agent.agentId === pipelineDefinition.agentId)
          ?.name ?? NO_DATA
    },
    source: mapSourceToForm(pipelineDefinition.source, isReadReplica),
    target: mapDestinationToForm(pipelineDefinition.target),
    engine: {
      advanced
    }
  }
}

export const getDatabaseString: (values: FormValues, t: TFunction) => string = (
  values: FormValues,
  t: TFunction
) => {
  if (formHasDatabaseProperty(values.source)) {
    return isEmpty(values.source.connection.database)
      ? NO_DATA
      : (values.source.connection.database ?? NO_DATA)
  } else {
    return t('createStreamingPipeline.fields.pipelineConfig.root')
  }
}

export const formHasDatabaseProperty = (source: SourceFormValues) =>
  hasDatabaseProperty(mapSourceFormToDefinition(source))

export const formHasPdbProperty = (source: SourceFormValues) =>
  hasPdbProperty(mapSourceFormToDefinition(source))
