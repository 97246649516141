import { type FunctionComponent } from 'react'

import { EnvironmentName } from 'modules/Common/Fields'

export const AssociateCloudCredsToEnvironmentEnvironmentName: FunctionComponent =
  () => {
    return (
      <EnvironmentName
        data-testid="associate-cloud-credentials-environment-name"
        disabled
      />
    )
  }
