import { Fragment, useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate
} from 'react-router-dom'

import { type AxiosError } from 'axios'
import { useFormikContext } from 'formik'
import { capitalize } from 'lodash'

import { Toaster } from '@matillion/component-library'

import { usePostSecretLocation } from 'api/createProjectForm/hooks'
import { createPostSecretLocationMutationData } from 'api/createProjectForm/mutation'
import { type ErrorResponse } from 'api/types'

import { ONBOARDING_STORAGE_ITEM } from 'constants/persistance'
import {
  AppRoutes,
  PROJECTS_CLOUD_CREDENTIALS,
  PROJECTS_CREATE_AGENT,
  PROJECTS_CREDENTIALS,
  PROJECTS_DEFAULTS,
  PROVISIONING_TRIAL_WAREHOUSE,
  ROOT,
  WAREHOUSE
} from 'constants/route'

import { useOnboardingContext } from 'context'

import * as Sources from 'modules/Common/WarehouseSources'
import CreateAgent from 'modules/FormsContent/CreateAgent'
import { WarehouseProvisioning } from 'modules/FormsContent/WarehouseProvisioning'
import { WarehouseProvisioningSelection } from 'modules/FormsContent/WarehouseProvisioningSelection'
import {
  initialValues,
  type OnboardingFormikValueTypes,
  type OnboardingFormNavigationSteps
} from 'modules/Onboarding/OnboardingForm'
import { useOnboardingSteps } from 'modules/Onboarding/OnboardingForm/hooks'

const OnboardingRoutes = () => {
  const navigate = useNavigate()
  const { setValues: setFormikValues, values } =
    useFormikContext<OnboardingFormikValueTypes>()
  const { t } = useTranslation()
  const { setSecretLocationId, isTrialWarehouse } = useOnboardingContext()
  const { mutateAsync: mutateAsyncSecretLocation } = usePostSecretLocation()
  const { clearToasts, makeToast } = Toaster.useToaster()
  const { pathname } = useLocation()
  const startingPage = WAREHOUSE
  const persistedFormValues = sessionStorage.getItem(ONBOARDING_STORAGE_ITEM)
  const projectType = persistedFormValues
    ? JSON.parse(persistedFormValues).type
    : ''
  const steps = useOnboardingSteps(isTrialWarehouse)

  const previousStepOrDefault = (step: OnboardingFormNavigationSteps) =>
    steps[step]?.previousStep ?? ROOT

  const warehouseRouteConfigs: Array<{
    route: OnboardingFormNavigationSteps
    showPrevious: boolean
  }> = [
    { route: PROJECTS_CREDENTIALS, showPrevious: false },
    { route: PROJECTS_DEFAULTS, showPrevious: true },
    { route: PROJECTS_CLOUD_CREDENTIALS, showPrevious: true }
  ]

  const warehouseRoutes = Object.entries(Sources).reduce<JSX.Element[]>(
    (result, [label, Component]) => {
      if (label === capitalize(projectType)) {
        result.push(
          <Fragment key={`${label}-route`}>
            {warehouseRouteConfigs.map(({ route, showPrevious }) => (
              <Route
                key={`${label}-${route}`}
                path={`${route}/*`}
                element={
                  <Component
                    onPrevious={() => {
                      navigate(previousStepOrDefault(route), {
                        replace: true
                      })
                    }}
                    showPrevious={showPrevious}
                    keyPairEnabled={true}
                  />
                }
              />
            ))}
          </Fragment>
        )
      }
      return result
    },
    []
  )

  const createSecretLocation = useCallback(async () => {
    try {
      const response = await mutateAsyncSecretLocation({
        values: createPostSecretLocationMutationData({
          ...values,
          etlAgent: { id: '', name: '' }
        })
      })
      setSecretLocationId(response.secretLocationId)
      setFormikValues({
        ...values,
        secretLocationId: response.secretLocationId
      })
    } catch (error) {
      clearToasts()
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const err = error as AxiosError<any>

      const errorDetail = (err?.response?.data as ErrorResponse)?.detail

      makeToast({
        title: t('error.unexpected.title'),
        message: errorDetail ?? t('error.unexpected.message'),
        type: 'error'
      })
      navigate(ROOT, { replace: true })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mutateAsyncSecretLocation, values])

  // If session storage gets deleted then redirect to the start of the form
  useEffect(() => {
    if (
      !persistedFormValues &&
      window.location.pathname !==
        `${AppRoutes.getOnboardingAdd()}/${startingPage}`
    ) {
      navigate(startingPage, { replace: true })
      setFormikValues(initialValues())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate])

  useEffect(() => {
    if (values.matillionHostedAgentId && !values.secretLocationId) {
      createSecretLocation()
    }
  }, [createSecretLocation, pathname, values])

  return (
    <Routes>
      <Route path="*" element={<Navigate replace to={startingPage} />} />
      <>
        <Route
          path={`${WAREHOUSE}/*`}
          element={<WarehouseProvisioningSelection />}
        />
        {values.warehouseProvisioning === 'trial' && (
          <Route
            path={`${PROVISIONING_TRIAL_WAREHOUSE}/*`}
            element={
              <WarehouseProvisioning
                onRedirectPrevious={() =>
                  // Delay for animation to finish
                  setTimeout(() => {
                    navigate(WAREHOUSE, { replace: true })
                  }, 1000)
                }
              />
            }
          />
        )}
      </>
      {values.warehouseProvisioning === 'customer' && (
        <>
          <Route
            path={`${PROJECTS_CREATE_AGENT}/*`}
            element={
              <CreateAgent
                onRedirectPrevious={() => {
                  navigate(ROOT, { replace: true })
                }}
                onRedirectNext={() => {
                  navigate(PROJECTS_CREDENTIALS, { replace: true })
                }}
              />
            }
          />
          {warehouseRoutes}
        </>
      )}
    </Routes>
  )
}

export default OnboardingRoutes
