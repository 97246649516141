import { type TFunction } from 'react-i18next'

import { type FieldMetaProps } from 'formik'
import { object, string, type InferType } from 'yup'

export const resolveErrorText = (
  t: TFunction,
  meta: FieldMetaProps<unknown>
) => (meta.touched && meta.error ? t(meta.error) : undefined)

export const formSchema = object({
  user: string().required('accessListing.addUser.fields.user.error.required'),
  role: string().required('accessListing.addUser.fields.role.error.required')
})

export type FormValues = InferType<typeof formSchema>

export const initialValues: FormValues = {
  user: '',
  role: ''
}
