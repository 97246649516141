import { type FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'

import { Typography } from '@matillion/component-library'

import { type StreamingPipelineResponse } from 'api/types'

import classes from 'modules/Projects/StreamingPipelineDashboard/Summary/components/StreamingConfigurationSummary/StreamingConfigurationSummary.module.scss'
import { mapEnabledDisabled } from 'modules/Projects/StreamingPipelineDashboard/Summary/components/StreamingDashboardSummary/StreamingDashboardSummary.util'
import {
  StreamingSummaryBlock,
  type SummaryField
} from 'modules/Projects/StreamingPipelineDashboard/Summary/components/StreamingSummaryBlock/StreamingSummaryBlock'
import { formatField } from 'modules/Projects/StreamingPipelineDashboard/Summary/StreamingSummary.util'

import {
  isSnowflakeDestination,
  TEMPORAL_MAPPINGS,
  TRANSFORMATION_TYPES
} from 'types/Streaming'

interface StreamingConfigurationSummaryProps {
  pipeline: StreamingPipelineResponse
  hidden: boolean
}

const StreamingConfigurationSummary: FunctionComponent<
  StreamingConfigurationSummaryProps
> = ({ pipeline, hidden }) => {
  const { t } = useTranslation()

  if (hidden) {
    return null
  }

  const fields: SummaryField[] = []
  if (isSnowflakeDestination(pipeline.target)) {
    fields.push(
      ...[
        {
          heading: t(
            'streamingPipelineDashboard.summary.fields.config.replicationType'
          ),
          content: formatField(
            pipeline.target.transformationType
              ? TRANSFORMATION_TYPES[pipeline.target.transformationType].name
              : undefined
          )
        },
        {
          heading: t(
            'streamingPipelineDashboard.summary.fields.config.temporalMapping'
          ),
          content: formatField(
            pipeline.target.temporalMapping
              ? TEMPORAL_MAPPINGS[pipeline.target.temporalMapping].name
              : undefined
          )
        }
      ]
    )
  }
  fields.push(
    ...[
      {
        heading: t(
          'streamingPipelineDashboard.summary.fields.config.initialSnapshot'
        ),
        content: formatField(
          pipeline.source.snapshot?.initial.enabled !== undefined
            ? mapEnabledDisabled(pipeline.source.snapshot.initial.enabled, t)
            : undefined
        )
      },
      {
        heading: t(
          'streamingPipelineDashboard.summary.fields.config.onDemandSnapshot'
        ),
        content: formatField(
          pipeline.source.snapshot?.onDemandSnapshot.enabled !== undefined
            ? mapEnabledDisabled(
                pipeline.source.snapshot.onDemandSnapshot.enabled,
                t
              )
            : undefined
        )
      }
    ]
  )

  if (pipeline.source.snapshot?.onDemandSnapshot.enabled) {
    fields.push({
      heading: t(
        'streamingPipelineDashboard.summary.fields.config.signalTable'
      ),
      content: formatField(
        pipeline.source.snapshot.onDemandSnapshot.signalTable !== undefined
          ? `${pipeline.source.snapshot.onDemandSnapshot.signalTable.schema}/${pipeline.source.snapshot.onDemandSnapshot.signalTable.table}`
          : undefined
      )
    })
  }

  return (
    <div
      data-testid="summary-config"
      className={classes.StreamingConfigurationSummary__Container}
    >
      <Typography
        format="bcl"
        className={classes.StreamingConfigurationSummary__Title}
      >
        {t('streamingPipelineDashboard.summary.fields.config.title')}
      </Typography>
      <StreamingSummaryBlock fields={fields} />
    </div>
  )
}

export default StreamingConfigurationSummary
