import { useParams } from 'react-router-dom'

import { object } from 'yup'
import { type OptionalObjectSchema } from 'yup/lib/object'
import { type AnyObject } from 'yup/lib/types'

import { useGetProject } from 'api/hooks'

import { useFormNavigation } from 'hooks'

import { steps } from 'modules/Projects/EditEnvironment/EditEnvironmentForm.steps'
import { editEnvironmentSchema } from 'modules/Projects/EditEnvironment/EditEnvironmentForm.util'
import { type EditEnvironmentSteps } from 'modules/Projects/EditEnvironment/types'

export const useValidationSchema = (): OptionalObjectSchema<AnyObject> => {
  const { currentStep } = useFormNavigation<EditEnvironmentSteps>(steps)
  const { projectId } = useParams()
  const { data: projectData } = useGetProject(projectId!)
  const projectType = projectData?.warehouse ?? ''

  return currentStep
    ? editEnvironmentSchema(projectType)[currentStep]
    : object({})
}
