import type { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'

import { useFormikContext } from 'formik'

import { AutoComplete, Table as T } from '@matillion/component-library'

import classes from './MemberListItem.module.scss'
import { MemberProfile } from './MemberProfile'
import type { Member } from './MembersList'

const { TableRow, TableCell } = T

export const MemberListItem: FunctionComponent<Member> = ({
  id,
  email,
  firstName,
  lastName,
  icon
}) => {
  const { t } = useTranslation()
  const userCanEdit = true
  const { handleBlur, getFieldHelpers, getFieldMeta } = useFormikContext()
  const { setTouched, setValue } = getFieldHelpers(id)
  const { value, initialValue = 'default' } = getFieldMeta<string>(id)

  return (
    <TableRow
      className={classes.TableRow}
      data-testid="environment-membersList-memberItem"
    >
      <TableCell width="8" className={classes.NoPad}>
        <MemberProfile
          email={email}
          firstName={firstName}
          lastName={lastName}
          icon={icon}
        />
      </TableCell>
      <TableCell width="4" className={classes.MemberListItem__TableCell}>
        <AutoComplete
          colorTheme="grey"
          inputId="environment-membersList-access"
          data-testid="environment-membersList-access-dropdown"
          name={id}
          onChange={(item) => {
            setValue(item.target.value?.id).catch()
            setTouched(item.target.value?.id !== initialValue).catch()
          }}
          value={value}
          disabled={!userCanEdit}
          className={classes.MemberListItem__Dropdown}
          availableItems={[
            {
              id: 'admin',
              name: t('fields.environmentDefaultAccess.options.admin')
            },
            {
              id: 'readOnly',
              name: t('fields.environmentDefaultAccess.options.readOnly')
            },
            {
              id: 'noAccess',
              name: t('fields.environmentDefaultAccess.options.noAccess')
            },
            {
              id: 'default',
              name: t('fields.environmentDefaultAccess.options.default')
            }
          ]}
          onBlur={handleBlur}
        />
      </TableCell>
    </TableRow>
  )
}
