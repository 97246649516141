import { useMutation } from 'react-query'

import { type AxiosError } from 'axios'

import { postSnowflakeListStagesRequest } from 'api/clients'
import { useNetworkErrorToast, useStreamingClient } from 'api/hooks/index'
import {
  type ErrorResponse,
  type POSTSnowflakeListStagesResponse
} from 'api/types'

import { QueryKey } from 'constants/endpoint'

import { type SnowflakeConnectionType } from 'types/Streaming'

type TError = AxiosError<ErrorResponse>
interface TVariables {
  agentId: string
  connection: SnowflakeConnectionType
  role: string
  database: string
  schema: string
}

export const useFetchSnowflakeStages = () => {
  const streamingClient = useStreamingClient()
  const makeErrorToast = useNetworkErrorToast()

  return useMutation<POSTSnowflakeListStagesResponse, TError, TVariables>({
    mutationKey: [QueryKey.STREAMING_LIST_SNOWFLAKE_STAGES],
    mutationFn: async ({ connection, agentId, role, database, schema }) => {
      return postSnowflakeListStagesRequest(
        { agentId, role, database, schema },
        connection,
        streamingClient
      )
    },
    onError: (error) => {
      makeErrorToast({
        message: error.response?.data.detail ?? error.message
      })
    }
  })
}
