import { useState, type FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

import classNames from 'classnames'

import {
  DataGrid,
  LoadingSpinner,
  Toaster,
  Typography,
  type SortState
} from '@matillion/component-library'
import { useEntitlements } from '@matillion/hub-client'

import {
  useCheckPermissions,
  useGetStreamingPipelineSummaries,
  usePostStreamingPipelineStart,
  usePostStreamingPipelineStop
} from 'api/hooks'
import { useDeleteStreamingPipeline } from 'api/hooks/streaming'
import { type GETStreamingPipelineSummaryResponse } from 'api/types'

import { RedWarning } from 'components/Icon'
import ConfirmCancelModal from 'components/Modal/ProjectExplorerModal/ProjectExplorerModal'
import { SearchBar } from 'components/SearchBar'
import { StatusBadge } from 'components/Streaming/StatusBadge/StatusBadge'

import { AppRoutes } from 'constants/route'

import NoData from 'modules/Projects/StreamingListing/components/NoData/NoData'
import { NoEntitlement } from 'modules/Projects/StreamingListing/components/NoEntitlement/NoEntitlement'

import { PermissionsType } from 'types'
import { ResourceType } from 'types/ResourceTypes'

import {
  NoCreatePermission,
  NoReadPermission,
  StreamingHeader
} from './components'
import {
  ActionCell,
  DestinationCell,
  PipelineNameCell,
  SourceCell
} from './components/Cells'
import classes from './StreamingListing.module.scss'
import { streamingPipelineSort } from './streamingListing.util'
import { ColumnKeys, type SortableColumnsType } from './types'

export const StreamingListing: FunctionComponent = () => {
  const { t } = useTranslation()
  const { data: entitlements, isLoading: isEntitlementsLoading } =
    useEntitlements()
  const { clearToasts } = Toaster.useToaster()
  const navigate = useNavigate()
  const { projectId } = useParams()
  const [sortState, setSortState] = useState<
    Partial<SortState<SortableColumnsType>>
  >({
    name: 'ASC'
  })
  const [pipelineToDelete, setPipelineToDelete] =
    useState<GETStreamingPipelineSummaryResponse>()
  const [filter, setFilter] = useState<string>('')

  const {
    data: streamingPipelines = [],
    isError: isStreamingPipelinesError,
    isLoading: isStreamingPipelinesLoading
  } = useGetStreamingPipelineSummaries(projectId!)

  const { data: createPermission, isLoading: isCreatePermissionLoading } =
    useCheckPermissions({
      resourceType: ResourceType.Project,
      resourceId: projectId!,
      permission: PermissionsType.CreatePipelines
    })
  const { data: viewPermission, isLoading: isViewPermissionLoading } =
    useCheckPermissions({
      resourceType: ResourceType.Project,
      resourceId: projectId!,
      permission: PermissionsType.ViewPipelines
    })

  const { mutateAsync: mutateStart } = usePostStreamingPipelineStart(projectId!)
  const { mutateAsync: mutateStop } = usePostStreamingPipelineStop(projectId!)
  const { mutateAsync: mutateDelete } = useDeleteStreamingPipeline(projectId!)

  const isLoading =
    isStreamingPipelinesLoading ||
    isCreatePermissionLoading ||
    isViewPermissionLoading ||
    isEntitlementsLoading

  if (isLoading) {
    return <LoadingSpinner />
  }

  if (isStreamingPipelinesError) {
    return (
      <>
        <StreamingHeader />
        <Typography data-testid="streaming-pipelines-load-error" format="bcm">
          {t('streamingListing.error')}
        </Typography>
      </>
    )
  }

  if (!isViewPermissionLoading && !viewPermission) {
    return <NoReadPermission />
  }

  const onAddClickHandler: VoidFunction = () => {
    clearToasts()
    navigate(AppRoutes.getStreamingPipelineCreate(projectId!))
  }

  if (!entitlements?.canAccessStreaming) {
    return <NoEntitlement />
  }

  if (!streamingPipelines.length) {
    return createPermission ? (
      <NoData onAddClick={onAddClickHandler} />
    ) : (
      <NoCreatePermission />
    )
  }

  const filteredPipelines = filter
    ? streamingPipelines.filter((pipeline) =>
        pipeline.name.toLowerCase().includes(filter.toLowerCase())
      )
    : streamingPipelines

  const sortedPipelines = [...filteredPipelines].sort(
    streamingPipelineSort(sortState)
  )

  return (
    <>
      <StreamingHeader onAddClick={onAddClickHandler} />
      <SearchBar
        wrapperClassName={classes.StreamingListing__SearchBar}
        value={filter}
        onChange={setFilter}
      />
      <DataGrid
        className={classes.StreamingListing__Grid}
        rowClassName={classes.StreamingListing__GridRow}
        data-testid="streaming-pipelines-table"
        aria-label="Streaming Pipelines Table"
        defaultSort={{ name: 'ASC' }}
        columns={[
          {
            key: ColumnKeys.name,
            title: t('streamingListing.column.name'),
            'data-testid': 'name-column',
            as: PipelineNameCell,
            className: classNames(
              classes.StreamingListing__GridCell,
              classes.StreamingListing__NameText
            ),
            mapValues: (row) => ({
              name: row.name,
              projectId: projectId,
              pipelineId: row.id
            }),
            sortable: true
          },
          {
            key: ColumnKeys.status,
            title: t('streamingListing.column.status'),
            'data-testid': 'status-column',
            as: StatusBadge,
            className: classes.StreamingListing__GridCell,
            mapValues: (row) => ({
              status: row.status
            }),
            sortable: true
          },
          {
            key: ColumnKeys.source,
            title: t('streamingListing.column.source'),
            'data-testid': 'source-column',
            as: SourceCell,
            className: classes.StreamingListing__GridCell,
            mapValues: (row) => ({
              source: row.source
            }),
            sortable: true
          },
          {
            key: ColumnKeys.destination,
            title: t('streamingListing.column.destination'),
            'data-testid': 'destination-column',
            as: DestinationCell,
            className: classes.StreamingListing__GridCell,
            mapValues: (row) => ({
              destination: row.target
            }),
            sortable: true
          },
          {
            key: ColumnKeys.action,
            title: t('streamingListing.column.action'),
            'data-testid': 'action-column',
            as: ActionCell,
            className: classes['StreamingListing__GridCell--action'],
            mapValues: (row) => ({
              onStartClick: async () => {
                await mutateStart({
                  pipelineId: row.id,
                  pipelineName: row.name
                })
              },
              onStopClick: async () => {
                await mutateStop({ pipelineId: row.id, pipelineName: row.name })
              },
              onEditClick: () => {
                navigate(AppRoutes.getStreamingPipelineEdit(projectId!, row.id))
              },
              onDeleteClick: () => {
                setPipelineToDelete(row)
              },
              item: row
            }),
            sortable: false
          }
        ]}
        rows={sortedPipelines}
        onSort={setSortState}
      />
      {pipelineToDelete && (
        <ConfirmCancelModal
          heading={t('streamingListing.deleteModal.title')}
          content={t('streamingListing.deleteModal.supportText', {
            pipelineName: pipelineToDelete.name
          })}
          validate={t('streamingListing.deleteModal.submit')}
          onCancel={() => {
            setPipelineToDelete(undefined)
          }}
          onValidate={() => {
            mutateDelete({
              pipelineId: pipelineToDelete.id,
              pipelineName: pipelineToDelete.name
            })
            setPipelineToDelete(undefined)
          }}
          icon={<RedWarning />}
          waiting={isLoading}
        />
      )}
    </>
  )
}

export default StreamingListing
