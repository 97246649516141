import { type FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'

import { Alert, Typography } from '@matillion/component-library'

import classes from './JdbcParametersAlert.module.scss'

export const JdbcParametersAlert: FunctionComponent = () => {
  const { t } = useTranslation('translation', {
    keyPrefix:
      'createStreamingPipeline.fields.sourceConfig.jdbcProperties.infoAlert'
  })

  return (
    <Alert
      type="info"
      title={t('title')}
      className={classes.JdbcParameters__Alert}
      data-testid="jdbc-parameters-info-alert"
      message={<Typography format="bcs">{t('body')}</Typography>}
    />
  )
}
