import { type FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'

import { Button, Typography } from '@matillion/component-library'

import {
  usePostStreamingPipelineStart,
  usePostStreamingPipelineStop
} from 'api/hooks'

import PipelineActionIcon from 'modules/Projects/StreamingPipelineDashboard/Header/components/PipelineActionIcon/PipelineActionIcon'
import classes from 'modules/Projects/StreamingPipelineDashboard/Header/components/StartStopButton/StartStopButton.module.scss'

import {
  isStartable,
  isStoppable,
  type StreamingPipelineStatus
} from 'types/Streaming'

interface StartStopButtonProps {
  projectId: string
  pipelineId: string
  pipelineName: string
  status: StreamingPipelineStatus
}

const StartStopButton: FunctionComponent<StartStopButtonProps> = ({
  projectId,
  pipelineId,
  pipelineName,
  status
}) => {
  const { t } = useTranslation()

  const { mutateAsync: mutateStart } = usePostStreamingPipelineStart(projectId)
  const { mutateAsync: mutateStop } = usePostStreamingPipelineStop(projectId)

  const canBeStarted = isStartable(status)
  const canBeStopped = isStoppable(status)

  const handleClick = canBeStopped
    ? () => {
        mutateStop({ pipelineId, pipelineName })
      }
    : () => {
        mutateStart({ pipelineId, pipelineName })
      }
  const text = canBeStopped
    ? t('streamingPipelineDashboard.actions.stop')
    : t('streamingPipelineDashboard.actions.start')

  return (
    <Button
      onClick={handleClick}
      data-testid="pipeline-action-button"
      className={classes.StartStopButton__Button}
      disabled={!canBeStopped && !canBeStarted}
    >
      <Typography format="bcs">{text}</Typography>
      <PipelineActionIcon isStop={canBeStopped} />
    </Button>
  )
}

export default StartStopButton
