import { useMemo, type FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'

import { Field as FormikField, useFormikContext, type FieldProps } from 'formik'

import { Field, Textarea } from '@matillion/component-library'

import classes from 'components/Form/Form.module.scss'

import { type CreateProjectFormikValueTypes } from 'modules/Projects/CreateProject/CreateProjectForm'
import { FormFields } from 'modules/Secrets/CreateSecretDefinitionForm/CreateSecretDefinitionForm.utils'
import { renderFormikError } from 'modules/utils'

export const PrivateKey: FunctionComponent = () => {
  const { errors, handleChange, touched, values } =
    useFormikContext<CreateProjectFormikValueTypes>()
  const { t } = useTranslation()

  const projectType = values.type || 'common'

  const privateKeyError = renderFormikError(
    errors.secretValue,
    Boolean(touched.secretValue)
  )

  const getErrorMessage = useMemo(() => {
    if (privateKeyError) return t(privateKeyError)
    return null
  }, [privateKeyError, t])

  return (
    <div data-testid={`${projectType}-private-key`}>
      <FormikField name={FormFields.secretValue}>
        {({ field, form: { handleBlur } }: FieldProps<string>) => (
          <Field
            {...field}
            inputComponent={Textarea}
            title={t('fields.privateKey.title')}
            data-testid={`${projectType}-private-key-input`}
            value={values.secretValue}
            placeholder={t('fields.privateKey.placeholderText')}
            onChange={handleChange}
            onBlur={handleBlur}
            className={classes.Form__SpacingStyles}
            supportText={t('fields.privateKey.supportText')}
            errorText={getErrorMessage}
            autoComplete="off"
            type="text"
            hasError={
              Boolean(errors?.secretValue) && Boolean(touched.secretValue)
            }
            rows={5}
          />
        )}
      </FormikField>
    </div>
  )
}

export default PrivateKey
