import { type FocusEvent, type FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'

import { Field as FormikField, type FieldProps } from 'formik'

import { Field } from '@matillion/component-library'

import { type GETBranchesResponse } from 'api/types'

import { useFormikFieldSpacesToHyphens } from 'hooks'

import classes from 'modules/Projects/CreateBranch/CreateBranch.module.scss'
import { resolveErrorText } from 'modules/Projects/CreateBranch/CreateBranch.util'

export const Name: FunctionComponent<{
  availableBranches: GETBranchesResponse
}> = ({ availableBranches }) => {
  const { t } = useTranslation()
  const fieldName = 'branchName'
  const spacesToHyphens = useFormikFieldSpacesToHyphens({ fieldName })
  const handleValidate = (value: string) => {
    return availableBranches.findIndex((b) => b.name === value) === -1
      ? ''
      : t('addBranch.responseMessage.notUnique', {
          branchName: value
        })
  }

  return (
    <FormikField name={fieldName} validate={handleValidate}>
      {({
        field,
        meta,
        form: { setFieldValue, handleBlur }
      }: FieldProps<string>) => (
        <div data-testid="create-branch-name">
          <Field
            {...field}
            className={classes['CreateBranch__Field--normal']}
            title={t('addBranch.fields.branchName.title')}
            name={fieldName}
            data-testid="create-branch-name-input"
            placeholder={t('addBranch.fields.branchName.placeholderText')}
            supportText={t('addBranch.fields.branchName.supportText')}
            errorText={resolveErrorText(t, meta)}
            hasError={!!meta.error && meta.touched}
            onBlur={(e: FocusEvent<HTMLInputElement>) => {
              setFieldValue(fieldName, e.target.value.trim())
              handleBlur(e)
            }}
            onChange={spacesToHyphens}
            required
          />
        </div>
      )}
    </FormikField>
  )
}
