import { useEffect, useState, type FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { LoadingSpinner, Typography } from '@matillion/component-library'

import { useGetStreamingPipelineStatus } from 'api/hooks'
import { useGetStreamingPipeline } from 'api/hooks/streaming/useGetStreamingPipeline'
import { useGetStreamingPipelineMetrics } from 'api/hooks/streaming/useGetStreamingPipelineMetrics'

import StreamingDashboardEvents from 'modules/Projects/StreamingPipelineDashboard/Events/StreamingDashboardEvents/StreamingDashboardEvents'
import { StreamingDashboardHeader } from 'modules/Projects/StreamingPipelineDashboard/Header/components'
import StreamingDashboardMetrics from 'modules/Projects/StreamingPipelineDashboard/Metrics/components/StreamingDashboardMetrics/StreamingDashboardMetrics'
import classes from 'modules/Projects/StreamingPipelineDashboard/StreamingPipelineDashboard.module.scss'
import StreamingDashboardSummary from 'modules/Projects/StreamingPipelineDashboard/Summary/components/StreamingDashboardSummary/StreamingDashboardSummary'

import {
  hasDatabaseProperty,
  isStoppable,
  StreamingPipelineStatus
} from 'types/Streaming'

const StreamingPipelineDashboard: FunctionComponent = () => {
  const { t } = useTranslation()

  const { projectId, pipelineId } = useParams()

  const [isFirstStatusLoad, setIsFirstStatusLoad] = useState(true)

  const {
    data: pipelineData,
    isLoading: isPipelineLoading,
    isError: isPipelineError
  } = useGetStreamingPipeline(projectId!, pipelineId!)

  const {
    data: statusData,
    isLoading: isStatusLoading,
    isSuccess: isStatusSuccess
  } = useGetStreamingPipelineStatus(pipelineId!, projectId, {
    refetchInterval: 10000
  })

  const canGetMetrics = !!statusData && isStoppable(statusData?.status)

  const { data: metricsData } = useGetStreamingPipelineMetrics(
    projectId!,
    pipelineId!,
    {
      enabled: canGetMetrics,
      refetchInterval:
        statusData?.status === StreamingPipelineStatus.SNAPSHOTTING
          ? 2000
          : 60000
    }
  )

  useEffect(() => {
    if (!isStatusLoading) {
      setIsFirstStatusLoad(false)
    }
  }, [isStatusLoading])

  // We want to avoid making an unnecessary call to get all agents with an UNKNOWN status.
  // If the status is slower to load than the pipeline data, we want to avoid the situation where we default to
  // an UNKNOWN status, before quickly correcting to the real status once the request has been completed.
  if (isPipelineLoading || (isStatusLoading && isFirstStatusLoad)) {
    return <LoadingSpinner data-testid="loading-spinner" />
  }

  if (isPipelineError || !pipelineData) {
    return (
      <Typography
        data-testid="streaming-pipeline-dashboard-load-error"
        format="bcm"
      >
        {t('streamingPipelineDashboard.error')}
      </Typography>
    )
  }

  const database = hasDatabaseProperty(pipelineData.source)
    ? pipelineData.source.connection?.database
    : undefined

  const status =
    isStatusSuccess && statusData
      ? statusData.status
      : StreamingPipelineStatus.UNKNOWN

  return (
    <div className={classes.StreamingPipelineDashboard}>
      <StreamingDashboardHeader
        projectId={projectId!}
        pipelineId={pipelineId!}
        pipelineName={pipelineData.name}
        status={status}
        metrics={metricsData?.metrics}
      />
      <StreamingDashboardSummary
        pipeline={pipelineData}
        pipelineStatus={status}
      />
      <div className={classes.StreamingPipelineDashboard__MetricsEvents}>
        <StreamingDashboardMetrics
          database={database}
          tables={pipelineData.source.tables ?? []}
          metrics={metricsData?.metrics}
          status={status}
        />
        <StreamingDashboardEvents
          pipelineId={pipelineId!}
          projectId={projectId!}
        />
      </div>
    </div>
  )
}

export default StreamingPipelineDashboard
