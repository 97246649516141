import { useQuery, type UseQueryOptions } from 'react-query'

import { type AxiosError } from 'axios'

import { getStreamingPipelineMetrics } from 'api/clients'
import { useStreamingClient } from 'api/hooks/index'
import type { GETStreamingPipelineMetricsResponse } from 'api/types'

import { QueryKey } from 'constants/endpoint'

type TData = GETStreamingPipelineMetricsResponse
type TError = AxiosError<unknown>
type TOptions = UseQueryOptions<TData, TError, TData>

export const useGetStreamingPipelineMetrics = (
  projectId: string,
  pipelineId: string,
  options?: TOptions
) => {
  const streamingClient = useStreamingClient()

  return useQuery<TData, TError>(
    [QueryKey.STREAMING_PIPELINE_METRICS, projectId, pipelineId],
    async () =>
      getStreamingPipelineMetrics({ projectId, pipelineId }, streamingClient),
    {
      ...options
    }
  )
}
