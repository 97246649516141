import { useQuery, type UseQueryOptions } from 'react-query'

import { type AxiosError } from 'axios'

import { getStreamingPipeline } from 'api/clients'
import { useNetworkErrorToast, useStreamingClient } from 'api/hooks/index'
import { type StreamingPipelineResponse } from 'api/types'

import { QueryKey } from 'constants/endpoint'

type TData = StreamingPipelineResponse
type TError = AxiosError<unknown>
type TOptions = UseQueryOptions<TData, TError, TData>

export const useGetStreamingPipeline = (
  projectId: string,
  pipelineId: string,
  options?: TOptions
) => {
  const streamingClient = useStreamingClient()
  const makeErrorToast = useNetworkErrorToast()

  return useQuery<TData, TError>(
    [QueryKey.STREAMING_PIPELINE, projectId, pipelineId],
    async () =>
      getStreamingPipeline({ projectId, pipelineId }, streamingClient),
    {
      ...options,
      onError: (error) => {
        makeErrorToast({ message: error.message })
      }
    }
  )
}
