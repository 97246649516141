import { Button } from '@matillion/component-library'

import { ReactComponent as AddIcon } from 'assets/add-plus-icon.svg'
import { ReactComponent as DeleteIcon } from 'assets/delete-minus-icon.svg'
import { ReactComponent as DisabledDeleteIcon } from 'assets/disabled-delete-minus-icon.svg'

import classes from './DataGridAddRemoveButtons.module.scss'

interface DataGridAddRemoveButtonsProps {
  add: {
    onClick: () => void
  }
  remove: {
    disabled: boolean
    onClick: () => void
  }
}

export const DataGridAddRemoveButtons = ({
  add,
  remove
}: DataGridAddRemoveButtonsProps) => {
  return (
    <div className={classes.DataGridAddRemoveButtons}>
      <div className={classes.DataGridAddRemoveButtons__Wrapper}>
        <Button
          alt="text"
          type="button"
          onClick={add.onClick}
          data-testid="add-row"
        >
          <AddIcon />
        </Button>
        <Button
          alt="text"
          type="button"
          disabled={remove.disabled}
          onClick={remove.onClick}
          data-testid="delete-rows"
        >
          {remove.disabled ? <DisabledDeleteIcon /> : <DeleteIcon />}
        </Button>
      </div>
    </div>
  )
}
