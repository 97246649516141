import { Trans, useTranslation } from 'react-i18next'

import { NoResourcesAvailable } from 'components/NoResourcesAvailable'

export const EntitlementError = () => {
  const { t } = useTranslation()

  return (
    <NoResourcesAvailable
      title={t('entitlementError.title')}
      supportText={
        <Trans
          i18nKey={'entitlementError.supportText'}
          components={{ b: <b></b> }}
        />
      }
      data-testid="entitlement-error"
    />
  )
}
