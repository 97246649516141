import { type FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { type FormikHelpers } from 'formik'

import { Modal, Toaster, Typography } from '@matillion/component-library'

import { usePostRoles } from 'api/hooks/usePostRoles'

import Form from 'components/Form'
import { ModalContent, ModalHeading } from 'components/Modal'

import classes from './EditUser.module.scss'
import { formSchema, type FormValues } from './EditUser.util'
import Role from './Fields/Role'
import { type EditUserProps } from './types'

const EditUser: FunctionComponent<EditUserProps> = ({
  onCancelModal,
  onSubmit,
  user
}) => {
  const { t } = useTranslation()
  const { projectId = '' } = useParams()
  const { mutateAsync } = usePostRoles(projectId)
  const { makeToast, clearToasts } = Toaster.useToaster()

  const defaultValues = {
    role:
      user.relation.relation === 'creator' ? 'admin' : user.relation.relation
  }

  const handleSubmitForm = (
    values: FormValues,
    { setSubmitting }: FormikHelpers<FormValues>
  ) => {
    clearToasts()

    mutateAsync({
      values: {
        roles: [{ userId: user.id, role: values.role }]
      }
    })
      .then((_response) => {
        makeToast({
          title: t('accessListing.editUser.responseMessage.success', {
            user: user.formattedUsername
          }),
          message: '',
          type: 'success'
        })
      })
      .catch((_error) => {
        makeToast({
          title: t('accessListing.editUser.responseMessage.error'),
          message: '',
          type: 'error'
        })
      })
      .finally(() => {
        setSubmitting(false)
        onSubmit()
      })
  }

  return (
    <Modal
      size="mid"
      data-testid="edit-user-modal"
      onCancel={onCancelModal}
      ariaLabelledBy="modal-title"
    >
      <ModalHeading data-testid="edit-user-title">
        {t('accessListing.editUser.heading')}
      </ModalHeading>
      <ModalContent>
        <Typography as="h4" format="ts" data-testid="edit-user-name">
          {user.formattedUsername}
        </Typography>
        <Form
          formikValues={{
            initialValues: defaultValues,
            initialTouched: false,
            onSubmit: handleSubmitForm,
            validationSchema: formSchema,
            validateOnMount: true
          }}
          onCancel={onCancelModal}
          translationPrefix="accessListing.editUser"
          containerClassName={classes.EditUser__Form}
        >
          <Role />
        </Form>
      </ModalContent>
    </Modal>
  )
}

export default EditUser
