import { type FunctionComponent } from 'react'

import { Typography } from '@matillion/component-library'

import classes from './EventLog.module.scss'

interface EventLogProps {
  message: string
}
export const EventLog: FunctionComponent<EventLogProps> = ({ message }) => {
  return (
    <div
      className={classes.EventLog__Container}
      data-testid="expanded-event-log"
    >
      <Typography format="bcs" className={classes.EventLog__Message}>
        {message}
      </Typography>
    </div>
  )
}
