import { differenceInMinutes, format } from 'date-fns'

interface DateTimeProps {
  customDate: string
  customTime?: string
  dateToCompare?: Date
  offset?: string
}

export const checkIfDateTimeInThePast = ({
  customDate,
  customTime,
  dateToCompare,
  offset = '+00:00'
}: DateTimeProps): boolean => {
  const today = dateToCompare ?? new Date()
  const time = customTime ?? format(new Date(), 'HH:mm')
  const [hours, minutes] = time.split(':')
  const startsOn = new Date(`${customDate}T${hours}:${minutes}:00${offset}`)

  if (differenceInMinutes(startsOn, new Date(today.setSeconds(0))) < 0) {
    return true
  }

  return false
}
