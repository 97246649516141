import { mixed, object } from 'yup'

import {
  PROJECTS_CLOUD_CREDENTIALS,
  PROJECTS_CREATE_AGENT,
  PROJECTS_CREDENTIALS,
  PROJECTS_DEFAULTS,
  PROVISIONING_TRIAL_WAREHOUSE,
  WAREHOUSE
} from 'constants/route'

import { type ProvisioningTypes } from 'modules/FormsContent/WarehouseProvisioningSelection/types'
import {
  type OnboardingFormikValueTypes,
  type OnboardingFormNavigationSteps
} from 'modules/Onboarding/OnboardingForm'

import { AgentsSecretsHost, Warehouse } from 'types'

export type OnboardingFormNavigationStepsType = Partial<
  Record<
    OnboardingFormNavigationSteps,
    {
      previousStep?: OnboardingFormNavigationSteps
      currentStep: OnboardingFormNavigationSteps
      nextStep?: OnboardingFormNavigationSteps
      excludedFromProgress?: boolean
    }
  >
>

export const steps = (
  isTrial = false,
  warehouseType?: Warehouse,
  enableRedshiftLocationDefaults?: boolean
): OnboardingFormNavigationStepsType => ({
  [WAREHOUSE]: {
    previousStep: undefined,
    currentStep: WAREHOUSE,
    nextStep: isTrial ? PROVISIONING_TRIAL_WAREHOUSE : PROJECTS_CREATE_AGENT
  },
  [PROJECTS_CREATE_AGENT]: {
    previousStep: WAREHOUSE,
    currentStep: PROJECTS_CREATE_AGENT,
    nextStep: PROJECTS_CREDENTIALS
  },
  [PROVISIONING_TRIAL_WAREHOUSE]: {
    previousStep: WAREHOUSE,
    currentStep: PROVISIONING_TRIAL_WAREHOUSE,
    nextStep: undefined
  },
  [PROJECTS_CREDENTIALS]: {
    previousStep: PROJECTS_CREATE_AGENT,
    currentStep: PROJECTS_CREDENTIALS,
    nextStep:
      warehouseType === Warehouse.Redshift && enableRedshiftLocationDefaults
        ? PROJECTS_CLOUD_CREDENTIALS
        : PROJECTS_DEFAULTS
  },
  ...(warehouseType === Warehouse.Redshift &&
    enableRedshiftLocationDefaults && {
      [PROJECTS_CLOUD_CREDENTIALS]: {
        previousStep: PROJECTS_CREDENTIALS,
        currentStep: PROJECTS_CLOUD_CREDENTIALS,
        nextStep: PROJECTS_DEFAULTS
      }
    }),
  [PROJECTS_DEFAULTS]: {
    previousStep:
      warehouseType === Warehouse.Redshift && enableRedshiftLocationDefaults
        ? PROJECTS_CLOUD_CREDENTIALS
        : PROJECTS_CREDENTIALS,
    currentStep: PROJECTS_DEFAULTS,
    nextStep: undefined
  }
})

export const initialValues = (isTrial = true): OnboardingFormikValueTypes => ({
  projectName: 'My first project',
  type: Warehouse.Snowflake,
  environmentDefaultAccess: {
    id: 'admin',
    name: 'Admin'
  },
  agentsSecretsManagement: AgentsSecretsHost.MatillionHosted,
  environmentName: 'default',
  matillionHostedAgentId: '',
  account: '',
  username: '',
  secretValue: '',
  defaultRole: {
    id: '',
    name: ''
  },
  defaultWarehouse: {
    id: '',
    name: ''
  },
  defaultDatabase: {
    id: '',
    name: ''
  },
  defaultSchema: {
    id: '',
    name: ''
  },
  catalog: {
    id: '',
    name: ''
  },
  awsAccessKeyId: '',
  awsSecretAccessKey: '',
  awsSecretReferenceId: '',
  compute: {
    id: '',
    name: '',
    clusterId: '',
    clusterName: '',
    clusterType: ''
  },
  secretLocationId: '',
  secretReferenceId: '',
  defaultStorageLocation: { name: '', id: '' },
  warehouseProvisioning: isTrial ? 'trial' : 'customer',
  credentialsType: {
    id: 'password',
    name: 'Username and password'
  },
  passphrase: '',
  passphraseSecretName: {
    id: '',
    name: ''
  },
  passphraseSecretKey: {
    id: '',
    name: ''
  },
  privateKeySecretName: {
    id: '',
    name: ''
  }
})

export const warehouseProvisioningValidationSchema = {
  [WAREHOUSE]: object({
    warehouseProvisioning: mixed<ProvisioningTypes>()
      .oneOf(['trial', 'customer'])
      .required('fields.projectName.warehouseProvisioning.required')
  }),
  [PROJECTS_CREATE_AGENT]: object({}),
  [PROVISIONING_TRIAL_WAREHOUSE]: object({})
}
