import { type FunctionComponent } from 'react'

import { useFormikContext } from 'formik'

import { generateCronFromInputs } from 'modules/Projects/CreateSchedule/FormContent/CreateScheduleContent.util'
import {
  ReadableCron,
  Timezone
} from 'modules/Projects/CreateSchedule/FormContent/Fields/Schedule/Common'
import { StartDateTime } from 'modules/Projects/CreateSchedule/FormContent/Fields/Schedule/Common/StartDateTime'
import { type ScheduleFormValues } from 'modules/Projects/CreateSchedule/types'

import { ScheduleMethod } from 'types'

import { DaysOfTheWeek } from './DaysOfTheWeek'
import { Interval } from './Interval'
import { Time } from './Time'

export const StandardSchedule: FunctionComponent = () => {
  const { values } = useFormikContext<ScheduleFormValues>()

  const showDaysOfTheWeekField = values.schedule.unit === ScheduleMethod.WEEK

  return (
    <>
      <Timezone />
      <StartDateTime />
      <Interval />
      {showDaysOfTheWeekField && <DaysOfTheWeek />}
      <Time />
      <ReadableCron
        cronExpression={generateCronFromInputs(values)}
        timezone={values.schedule.timezone?.name}
      />
    </>
  )
}

export default StandardSchedule
