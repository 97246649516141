import { type FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'

import {
  DataGrid,
  Typography,
  type DataGridProps
} from '@matillion/component-library'

import { type GETSecretReferencesResponseParams } from 'api/types'

import classes from 'modules/Secrets/CloudProviderCredentialsListing/CloudProviderCredentialsListing.module.scss'
import { ColumnKeys, formatCloudProviderSecrets } from 'modules/Secrets/utils'

import ActionCell from './ActionCell'
import NameCell from './NameCell'

interface CloudProviderCredentialsDataGridProps {
  credentials: GETSecretReferencesResponseParams[]
  onDeleteHandler: (name: string, isSuccess?: boolean) => void
  onSort: DataGridProps<GETSecretReferencesResponseParams>['onSort']
}

const CloudProviderCredentialsDataGrid: FunctionComponent<
  CloudProviderCredentialsDataGridProps
> = ({ credentials, onDeleteHandler, onSort }) => {
  const { t } = useTranslation()

  return (
    <DataGrid
      className={classes.CloudProviderCredentialsListing__Grid}
      rows={credentials}
      onSort={onSort}
      defaultSort={{ name: 'ASC' }}
      columns={[
        {
          key: ColumnKeys.name,
          title: t('secrets.cloudProviderCredentialsListing.column.col1'),
          as: NameCell,
          mapValues: ({ name }) => ({ name }),
          sortable: true
        },
        {
          key: ColumnKeys.provider,
          title: t('secrets.cloudProviderCredentialsListing.column.col2'),
          mapValues: ({ metadata }) => ({
            children: (
              <Typography as="span">
                {formatCloudProviderSecrets(metadata?.provider)}
              </Typography>
            )
          }),
          sortable: true
        },
        {
          key: ColumnKeys.actions,
          title: t('secrets.cloudProviderCredentialsListing.column.col3'),
          as: ActionCell,
          mapValues: (credential) => ({ credential, onDeleteHandler }),
          className:
            classes['CloudProviderCredentialsListing__GridCell--actions']
        }
      ]}
      data-testid="cloud-provider-credentials-data-grid"
    />
  )
}

export default CloudProviderCredentialsDataGrid
