import { useState } from 'react'

import classNames from 'classnames'

import { Button, Typography } from '@matillion/component-library'

import classes from 'components/EllipsesActionItem/EllipsesActionItem.module.scss'
import { MorePopover } from 'components/MorePopover'

export type ThemeType = 'danger' | 'normal'

interface EllipsesActionItemProps {
  actions: Array<{
    title: string
    icon?: JSX.Element
    action: () => void
    type?: ThemeType
    dataTestId?: string
    disabled?: boolean
    closeMenuOnClick?: boolean
  }>
}

const EllipsesActionItem = (props: EllipsesActionItemProps) => {
  const [menuOpen, setMenuOpen] = useState(false)
  const { actions } = props
  return (
    <MorePopover
      className={classes.EllipsesActionItem}
      open={menuOpen}
      setOpen={setMenuOpen}
    >
      <div data-testid="ellipses-actions">
        {actions.map((action, index) => {
          return (
            <Button
              alt="text"
              key={`${action.title}-${index}`}
              onClick={() => {
                action.action()
                setMenuOpen(action.closeMenuOnClick ? false : menuOpen)
              }}
              className={classNames(
                classes.EllipsesActionItem__Button,
                action.type === 'danger' &&
                  classes['EllipsesActionItem__Button--danger']
              )}
              data-testid={action.dataTestId}
              disabled={action.disabled}
            >
              <>
                {action?.icon && (
                  <div className={classes.EllipsesActionItem__Icons}>
                    {action.icon}
                  </div>
                )}
                <Typography>{action.title}</Typography>
              </>
            </Button>
          )
        })}
      </div>
    </MorePopover>
  )
}
export default EllipsesActionItem
