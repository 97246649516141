import { useEffect, useState, type FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import classNames from 'classnames'
import { useFormikContext } from 'formik'

import { Loader, Toaster, Typography } from '@matillion/component-library'

import { useGetMha, useGetProject } from 'api/hooks'
import { type OAuthParameter, type OAuthType } from 'api/types'

import formClasses from 'components/Form/Form.module.scss'
import { Header, HeaderTitle } from 'components/Header'
import ExternalActionModal from 'components/Modal/ProjectExplorerModal'

import { useFlags } from 'hooks/flags'

import VaultName from 'modules/Common/Fields/VaultName/VaultName'
import createOAuthClasses from 'modules/FormsContent/CreateOAuth/CreateOAuth.module.scss'
import {
  AuthenticationType,
  EnvironmentAgent,
  Parameters
} from 'modules/FormsContent/CreateOAuth/Fields'
import { useCreateOAuthContext } from 'modules/Projects/CreateOAuth/CreateOAuthContext'

import { AgentsSecretsHost } from 'types'
import { AgentCloudProvider } from 'types/AgentCloudProvider'
import { type OAuthFormI } from 'types/FormTypes'

import { Name, Provider, RedirectUrl } from './Fields'

interface CreateOAuthProps {
  onFormCancel?: () => void
  onAwaitingCancel: () => void
  showFormCancel?: boolean
  showAwaitingModal?: boolean
}

const CreateOAuth: FunctionComponent<CreateOAuthProps> = ({
  onFormCancel,
  onAwaitingCancel,
  showFormCancel = true,
  showAwaitingModal = false
}) => {
  const { projectId } = useParams()
  const { t } = useTranslation()
  const { setFieldValue, values } = useFormikContext<OAuthFormI>()
  const { selectedOAuth, setSelectedOAuth } = useCreateOAuthContext()
  const { enableAzureMultiKeyVault } = useFlags()
  const agentCloudProvider = values.environmentAgent?.agentCloudProvider
  const {
    data: projectData = {
      agentAndSecretHostLocation: AgentsSecretsHost.CustomerHosted
    },
    isLoading: isProjectLoading
  } = useGetProject(projectId!)
  const {
    data: mhaData = { agentId: '' },
    isError: isMhaError,
    isLoading: isMhaLoading
  } = useGetMha({
    options: {
      enabled:
        projectData.agentAndSecretHostLocation ===
        AgentsSecretsHost.MatillionHosted
    }
  })
  const { makeToast } = Toaster.useToaster()
  const [oAuthParameter, setOAuthParameter] = useState<OAuthParameter[]>()
  const [oAuthType, setOAuthType] = useState<OAuthType>()
  const modalContent = (
    <>
      {t('secrets.createOAuth.externalActionModal.content')}
      <Typography
        weight="bold"
        as="span"
        className={classNames(createOAuthClasses.ModalContent)}
      >
        {t('secrets.createOAuth.externalActionModal.contentBold')}
      </Typography>
    </>
  )

  useEffect(() => {
    if (
      !isProjectLoading &&
      projectData.agentAndSecretHostLocation ===
        AgentsSecretsHost.MatillionHosted
    ) {
      if (isMhaError) {
        makeToast({
          title: t('secrets.createOAuth.fields.agent.error.getMhaError'),
          message: '',
          type: 'error'
        })
      } else {
        setFieldValue('environmentAgent', {
          id: mhaData.agentId,
          name: mhaData.agentId
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMhaError, mhaData.agentId, projectData.agentAndSecretHostLocation])

  if (isProjectLoading) {
    return <Loader />
  }

  if (
    projectData.agentAndSecretHostLocation ===
      AgentsSecretsHost.MatillionHosted &&
    isMhaLoading
  ) {
    return <Loader />
  }

  return (
    <>
      <Header
        onCancel={onFormCancel}
        showCancel={showFormCancel}
        modalContent={t('secrets.createOAuth.confirmCancelModal.content')}
      >
        <HeaderTitle data-testid="create-oauth-title">
          {t('secrets.createOAuth.title')}
        </HeaderTitle>
      </Header>
      <div
        className={classNames(
          formClasses.Form__InnerWrap,
          formClasses['Form__InnerWrap--space-top']
        )}
      >
        <Name />
        {projectData.agentAndSecretHostLocation ===
          AgentsSecretsHost.CustomerHosted && <EnvironmentAgent />}

        {enableAzureMultiKeyVault &&
        agentCloudProvider === AgentCloudProvider.AZURE ? (
          <VaultName
            projectId={projectId ?? ''}
            agentId={values.environmentAgent?.id}
            locationIdFieldName="secretLocationId"
            testIdPrefix="create-oauth"
            className={classNames(formClasses.Form__SpacingStyles)}
          />
        ) : null}
        <Provider
          setOAuthParameter={setOAuthParameter}
          setSelectedOAuth={setSelectedOAuth}
          oAuthParameter={oAuthParameter}
          setOAuthType={setOAuthType}
        />
        <AuthenticationType
          setOAuthParameter={setOAuthParameter}
          oAuthParameter={oAuthParameter}
          selectedOAuth={selectedOAuth}
          setOAuthType={setOAuthType}
        />
        {oAuthType?.type === 'OAUTH_AUTHORIZATION_CODE' && <RedirectUrl />}
        <Parameters oAuthParameter={oAuthParameter} />
      </div>
      {showAwaitingModal && (
        <ExternalActionModal
          onCancel={onAwaitingCancel}
          onValidate={onAwaitingCancel}
          hideCancel
          heading={t('secrets.createOAuth.externalActionModal.heading')}
          content={modalContent}
          validate={t('secrets.createOAuth.externalActionModal.cancel')}
          disableBackdropClick
        />
      )}
    </>
  )
}

export default CreateOAuth
