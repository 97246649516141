import { useEffect, useMemo, useState } from 'react'

import { type OAuthSchema } from 'api/types'

import { CreateOAuthContext } from 'modules/Projects/CreateOAuth/CreateOAuthContext'
import CreateOAuthForm from 'modules/Projects/CreateOAuth/CreateOAuthForm'
import { useValidationSchema } from 'modules/Projects/CreateOAuth/hooks'

import { type GenericSchemaType } from 'types'

const CreateOAuthFormProvider = () => {
  const originalValidationSchema = useValidationSchema()
  const [validationSchema, setValidationSchema] = useState<GenericSchemaType>()
  const [selectedOAuth, setSelectedOAuth] = useState<OAuthSchema>()

  useEffect(() => {
    setValidationSchema(originalValidationSchema)
  }, [originalValidationSchema])

  const values = useMemo(
    () => ({
      validationSchema,
      setValidationSchema,
      selectedOAuth,
      setSelectedOAuth
    }),
    [validationSchema, selectedOAuth]
  )

  return (
    <CreateOAuthContext.Provider value={values}>
      <CreateOAuthForm />
    </CreateOAuthContext.Provider>
  )
}
export default CreateOAuthFormProvider
