import { type FunctionComponent } from 'react'

import classnames from 'classnames'

import { Button, Typography } from '@matillion/component-library'

import { ExternalLink } from 'components/ExternalLink'
import { HeaderTitle } from 'components/Header'

import classes from './NoResourcesAvailable.module.scss'

interface NoResourcesAvailableProps {
  title: string
  supportText: string | JSX.Element
  linkText?: string
  buttonText?: string
  docUrl?: string
  onButtonClick?: () => void
  size?: 'large' | 'small'
  'data-testid'?: string
  'data-tracker'?: string
}

export const NoResourcesAvailable: FunctionComponent<
  NoResourcesAvailableProps
> = ({
  title,
  supportText,
  docUrl,
  linkText,
  buttonText,
  onButtonClick,
  size = 'large',
  'data-testid': dataTestId,
  'data-tracker': dataTrackerName
}) => {
  return (
    <div
      // TO-DO: Add Icon in once we know which icon to use
      className={classnames(classes.NoResourcesAvailable, {
        [classes['NoResourcesAvailable--Small']]: size === 'small'
      })}
    >
      <HeaderTitle
        data-testid={
          dataTestId ? `${dataTestId}-title` : 'no-resources-available-title'
        }
        className={classes.NoResourcesAvailable__Text}
        format={size === 'large' ? 'dts' : 'tl'}
      >
        {title}
      </HeaderTitle>
      <Typography
        format={size === 'large' ? 'bcm' : 'bcs'}
        className={classes.NoResourcesAvailable__Text}
        data-testid={
          dataTestId
            ? `${dataTestId}-body-text`
            : 'no-resources-available-body-text'
        }
      >
        {supportText}

        {linkText && docUrl && (
          <ExternalLink
            format={size === 'large' ? 'bcm' : 'bcs'}
            href={`${docUrl}`}
            text={linkText}
            data-testid={
              dataTestId
                ? `${dataTestId}-external-link`
                : 'no-resources-available-external-link'
            }
          />
        )}
      </Typography>

      {onButtonClick && buttonText && (
        <Button
          className={classes.NoResourcesAvailable__Button}
          onClick={onButtonClick}
          text={buttonText}
          size={size === 'large' ? 'lg' : 'md'}
          alt="positive"
          data-testid={
            dataTestId
              ? `${dataTestId}-button`
              : 'no-resources-available-button'
          }
          {...(dataTrackerName ? { 'data-tracker': dataTrackerName } : {})}
        />
      )}
    </div>
  )
}
