import { type FunctionComponent } from 'react'

import classNames from 'classnames'

import classes from './AppFooter.module.scss'

// import { FeedbackLink } from 'components/FeedbackLink'
// import { DocumentationLink } from 'components/DocumentationLink'

const AppFooter: FunctionComponent = () => {
  return (
    <div className={classNames(classes.AppFooter)}>
      <div className={classNames(classes.AppFooter__Links)}>
        {/* <FeedbackLink />
            <DocumentationLink /> */}
      </div>
    </div>
  )
}

export default AppFooter
