export enum AgentCloudProvider {
  AWS = '1',
  AZURE = '2',
  MATILLION = '4'
}
export const agentCloudProviderName = {
  [AgentCloudProvider.AWS]: 'AWS',
  [AgentCloudProvider.AZURE]: 'Azure',
  [AgentCloudProvider.MATILLION]: ''
}
