import type { Authentication } from 'api/types'

import { type Warehouse } from 'types'

export enum WarehouseLookupTypes {
  ROLE = 'roles',
  WAREHOUSE = 'warehouses',
  DATABASE = 'databases',
  SCHEMA = 'schemas',
  COMPUTES = 'computes',
  CATALOG = 'catalog',
  LOCATION = 'location'
}

export interface GETWarehouseDefaultsRequest {
  agentId: string
  username: string
  secretReferenceId?: string
  awsPlatformProviderSecretReferenceId?: string
  account: string
  port?: number | null
  ssl?: boolean | null
  type: '' | Warehouse
  role?: string
  database?: string
  clusterName?: string
  clusterType?: string
  clusterId?: string
  catalog?: string
  authentication?: Authentication
}
