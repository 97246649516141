import { useCallback, useEffect, useMemo, type FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'

import { useFormikContext } from 'formik'

import {
  AutoComplete,
  Field,
  type AutoCompleteItem
} from '@matillion/component-library'

import { useGetSecretKeys } from 'api/createProjectForm/hooks'

import {
  createAutoCompleteItems,
  FormFields,
  resolveFormikError,
  type FormValues
} from 'modules/Secrets/CreateSecretDefinitionForm/CreateSecretDefinitionForm.utils'
import classes from 'modules/Secrets/SecretDefinitionFormContent/SecretDefinitionFormContent.module.scss'

interface SecretKeyProps {
  locationId: string
}

const SecretKey: FunctionComponent<SecretKeyProps> = ({ locationId }) => {
  const { t } = useTranslation()

  const { values, getFieldMeta, getFieldProps, getFieldHelpers } =
    useFormikContext<FormValues>()

  const fieldProps = getFieldProps(FormFields.secretKey)
  const fieldMeta = getFieldMeta(FormFields.secretKey)
  const { setValue, setTouched } = getFieldHelpers(FormFields.secretKey)

  const agentId = values.agent?.id
  const secretName = values.secretName ?? ''
  const isEnabled =
    Boolean(agentId) && Boolean(locationId) && Boolean(secretName)
  const {
    data: secretKeysData,
    isLoading,
    isError
  } = useGetSecretKeys(secretName, locationId, agentId, {
    enabled: isEnabled
  })

  useEffect(() => {
    setValue('')
    setTouched(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agentId, secretName])

  const errorText = useMemo(() => {
    if (isError) {
      return t('secrets.definitionForm.fields.secretKey.error.loadingError')
    }

    if (secretKeysData?.length === 0) {
      return t('secrets.definitionForm.fields.secretKey.error.noItemsFound')
    }

    return resolveFormikError(t, fieldMeta)
  }, [t, isError, fieldMeta, secretKeysData])

  return (
    <div data-testid="secret-definition-secret-key">
      <Field
        {...fieldProps}
        disabled={isError || !isEnabled || !secretKeysData?.length}
        loading={isLoading}
        data-testid="secret-definition-secret-key-input"
        className={classes['SecretDefinitionFormContent__Field--spaced']}
        title={t('secrets.definitionForm.fields.secretKey.title')}
        placeholder={t('secrets.definitionForm.fields.secretKey.placeholder')}
        inputComponent={AutoComplete}
        availableItems={createAutoCompleteItems(secretKeysData ?? [])}
        errorText={errorText}
        value={fieldProps.value || null}
        onChange={useCallback(
          async (e: { target: AutoCompleteItem }) =>
            setValue(e.target.value?.id),
          [setValue]
        )}
      />
    </div>
  )
}

export default SecretKey
