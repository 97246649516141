import { useState, type FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'

import { useFormikContext } from 'formik'

import {
  Button,
  Modal,
  SearchBar,
  Typography
} from '@matillion/component-library'

import type { GETEnvironmentMembersResponse } from 'api/types'

import { ExternalLink } from 'components/ExternalLink'
import { ModalActions } from 'components/Modal'

import config from 'config'

import { MembersList } from 'modules/Projects/EnvironmentsListing/components/editUsers/MembersList/MembersList'
import { type FormValues } from 'modules/Projects/ProjectsListing/DeleteProject/DeleteProjectForm.util'

import classes from './EditEnvironmentMembersModal.module.scss'

interface Props {
  setEnvContext: (state?: {
    id: string
    name: string
    defaultRole: string
  }) => void
  envContext: {
    id: string
    name: string
    defaultRole: string
  }
  isLoading: boolean
  members: GETEnvironmentMembersResponse
}

export const EditEnvironmentMembersModal: FunctionComponent<Props> = ({
  setEnvContext,
  envContext,
  isLoading,
  members
}) => {
  const { t } = useTranslation()
  const [search, setSearch] = useState('')

  const { handleSubmit } = useFormikContext<FormValues>()
  const cancel = () => {
    setEnvContext(undefined)
  }
  return (
    <Modal
      size="mid"
      drawerModePosition="right"
      onCancel={cancel}
      ariaLabelledBy={'edit user permissions '}
      data-testid="edit-environment-members-modal"
    >
      <Typography
        format="ts"
        data-testid="edit-environment-members-modal-title"
        className={classes.EditEnvironmentUserModal__Typography__Title}
      >
        {t('editEnvironmentMembers.title', {
          environmentName: envContext.name
        })}
      </Typography>

      <Typography
        format="bcs"
        className={classes.EditEnvironmentUserModal__Typography__DefaultAccess}
      >
        {t('editEnvironmentMembers.label')}
        <Typography
          format="bcs"
          className={
            classes.EditEnvironmentUserModal__Typography__CurrentDefaultAccess
          }
          weight="bold"
        >
          {' '}
          {t(
            `fields.environmentDefaultAccess.options.${envContext.defaultRole}`
          )}
        </Typography>
      </Typography>

      <ExternalLink
        className={classes.NoMargin}
        format={'bcs'}
        href={config.projectExplorer.accessLevelsHelpDocs}
        text={t('fields.environmentDefaultAccess.supportText')}
      />

      <div className={classes.EditEnvironmentUserModal__SearchBar}>
        {
          //  - onPointerEnterCapture
          //  - onPointerLeaveCapture
          //  - crossOrigin
          <SearchBar
            onChange={(event) => {
              setSearch(event.target.value)
            }}
            placeholder={t('editEnvironmentMembers.searchPlaceholder')}
            data-testid={`filter-search-env-user-${envContext.id}`}
            value={search}
          />
        }
      </div>
      <MembersList members={members} isLoading={isLoading} search={search} />
      <ModalActions>
        <Button
          onClick={cancel}
          alt="secondary"
          fontWeight="bold"
          text={t('modal.generic.cancel')}
          data-testid="edit-environment-members-button-cancel"
        />
        <Button
          onClick={() => {
            handleSubmit()
          }}
          alt="primary"
          fontWeight="bold"
          text={t('modal.generic.save')}
          data-testid="edit-environment-members-button-save"
        />
      </ModalActions>
    </Modal>
  )
}
