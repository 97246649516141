import { useQuery, type UseQueryOptions } from 'react-query'

import { type AxiosError } from 'axios'

import { getBranches } from 'api/clients'
import { type GETBranchesResponse } from 'api/types'

import { QueryKey } from 'constants/endpoint'

import { useClient } from './useClient'

type TData = GETBranchesResponse
type TError = AxiosError<unknown>
type Options = UseQueryOptions<TData, TError, TData>

export const useGetBranches = (projectId: string, options?: Options) => {
  const client = useClient()

  return useQuery<TData, TError>(
    [QueryKey.BRANCHES_LIST, projectId],
    async () => {
      return getBranches(
        {
          projectId
        },
        client
      )
    },
    options
  )
}
