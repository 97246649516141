import { useMutation, type UseMutationOptions } from 'react-query'

import { type AxiosError } from 'axios'

import {
  getWarehouseDefaults,
  postWarehouseDefaults
} from 'api/createProjectForm/client'
import {
  type GETWarehouseDefaultsRequest,
  type GETWarehouseDefaultsResponse,
  type WarehouseLookupTypes
} from 'api/createProjectForm/types'
import { useClient } from 'api/hooks/useClient'

import { QueryKey } from 'constants/endpoint'

import { useFlags } from 'hooks/flags'

import { Warehouse } from 'types'

type TData = GETWarehouseDefaultsResponse
type TError = AxiosError<unknown>
interface TVariables {
  values: GETWarehouseDefaultsRequest
}
type Options = UseMutationOptions<unknown, AxiosError, TVariables>

export const useMutateWarehouseDefaults = (
  lookupType: WarehouseLookupTypes,
  options?: Options,
  showErrorDetailMessage = false
) => {
  const client = useClient({ showErrorDetailMessage })
  const { enableKeyPairAuthentication } = useFlags()

  return useMutation<TData, TError, TVariables>(
    [QueryKey.WAREHOUSE_DEFAULTS, lookupType],
    async (data: TVariables) => {
      const { values } = data
      if (
        enableKeyPairAuthentication &&
        values.authentication &&
        values.type === Warehouse.Snowflake
      ) {
        return postWarehouseDefaults(values, lookupType, client)
      }
      return getWarehouseDefaults(values, lookupType, client)
    },
    options
  )
}
