import {
  PROJECTS_AGENT_DEPLOYMENT,
  PROJECTS_CLOUD_CREDENTIALS,
  PROJECTS_CONFIGURATION,
  PROJECTS_CREATE_AGENT,
  PROJECTS_CREATE_ENVIRONMENT,
  PROJECTS_CREDENTIALS,
  PROJECTS_DEFAULTS,
  PROJECTS_NEW_PROJECT,
  PROVIDER_SELECTION,
  REPOSITORY_SELECTION
} from 'constants/route'

import { Warehouse } from 'types'

import { type CreateFormNavigationSteps } from './types'

export type CreateFormNavigationStepsType = Record<
  CreateFormNavigationSteps,
  {
    previousStep?: CreateFormNavigationSteps
    currentStep: CreateFormNavigationSteps
    nextStep?: CreateFormNavigationSteps
  }
>

export type WarehouseNavigationStepsOverwriteType = Record<
  Warehouse,
  Partial<CreateFormNavigationStepsType>
>

/**
 * This file includes the different steps for the CreateProject flow.
 *
 * The ordering of the steps is determined by the 'nextStep', where the order will begin from step that holds
 * undefined against 'previousStep' and finish on the step that holds undefined against the 'nextStep'.
 *
 */

/**
 * The steps that are used for the CreateProject flow when ProjectConfigurationType === MATILLION
 *
 * These steps do not have BYOG and Hybrid agent options.
 * ProjectConfiguration is defaulted to Matillion.
 *
 * NOTE: PROJECTS_CREATE_AGENT step is required because when nextStep is PROJECTS_CREATE_ENVIRONMENT logic is present in CreateProjectForm.tsx
 * to call POST MHA and if the response is not HEALTHY, user will be navigated to PROJECTS_CREATE_AGENT
 */
export const defaultSteps: Partial<CreateFormNavigationStepsType> = {
  [PROJECTS_NEW_PROJECT]: {
    previousStep: undefined,
    currentStep: PROJECTS_NEW_PROJECT,
    nextStep: PROJECTS_CONFIGURATION
  },
  [PROJECTS_CONFIGURATION]: {
    previousStep: PROJECTS_NEW_PROJECT,
    currentStep: PROJECTS_CONFIGURATION,
    nextStep: PROJECTS_CREATE_ENVIRONMENT
  },
  [PROJECTS_CREATE_AGENT]: {
    previousStep: PROJECTS_CONFIGURATION,
    currentStep: PROJECTS_CREATE_AGENT,
    nextStep: PROJECTS_CREATE_ENVIRONMENT
  },
  [PROJECTS_CREATE_ENVIRONMENT]: {
    previousStep: PROJECTS_CONFIGURATION,
    currentStep: PROJECTS_CREATE_ENVIRONMENT,
    nextStep: PROJECTS_CREDENTIALS
  },
  [PROJECTS_CREDENTIALS]: {
    previousStep: PROJECTS_CREATE_ENVIRONMENT,
    currentStep: PROJECTS_CREDENTIALS,
    nextStep: PROJECTS_DEFAULTS
  },
  [PROJECTS_DEFAULTS]: {
    previousStep: PROJECTS_CREDENTIALS,
    currentStep: PROJECTS_DEFAULTS,
    nextStep: undefined
  }
}

/**
 * The steps that is used for the CreateProject flow when 'ProjectConfigurationType' === ADVANCED
 *
 * These steps have BYOG in 2 screens (one for the provider and one for the repository selection) and Hybrid agent options.
 * These override existing steps and add a few new ones.
 *
 * NOTE: PROJECTS_CREATE_AGENT step is required because when nextStep is PROJECTS_CREATE_ENVIRONMENT logic is present in CreateProjectForm.tsx
 * to call POST MHA and if the response is not HEALTHY, user will be navigated to PROJECTS_CREATE_AGENT
 */
export const stepsWithAdvancedConfiguration: Partial<CreateFormNavigationStepsType> =
  {
    [PROJECTS_CONFIGURATION]: {
      previousStep: PROJECTS_NEW_PROJECT,
      currentStep: PROJECTS_CONFIGURATION,
      nextStep: PROVIDER_SELECTION
    },
    [PROVIDER_SELECTION]: {
      previousStep: PROJECTS_CONFIGURATION,
      currentStep: PROVIDER_SELECTION,
      nextStep: REPOSITORY_SELECTION
    },
    [REPOSITORY_SELECTION]: {
      previousStep: PROVIDER_SELECTION,
      currentStep: REPOSITORY_SELECTION,
      nextStep: PROJECTS_AGENT_DEPLOYMENT
    },
    [PROJECTS_AGENT_DEPLOYMENT]: {
      previousStep: REPOSITORY_SELECTION,
      currentStep: PROJECTS_AGENT_DEPLOYMENT,
      nextStep: PROJECTS_CREATE_ENVIRONMENT
    },
    [PROJECTS_CREATE_AGENT]: {
      previousStep: PROJECTS_AGENT_DEPLOYMENT,
      currentStep: PROJECTS_CREATE_AGENT,
      nextStep: PROJECTS_CREATE_ENVIRONMENT
    },
    [PROJECTS_CREATE_ENVIRONMENT]: {
      previousStep: PROJECTS_AGENT_DEPLOYMENT,
      currentStep: PROJECTS_CREATE_ENVIRONMENT,
      nextStep: PROJECTS_CREDENTIALS
    }
  }

/**
 * These override the last 2 steps of Warehouse Credentials and Warehouse Defaults
 *
 * Based on the Warehouse type it will override those 2 steps with the ones defined in the list
 * If none are given the original 2 steps (Warehouse Credentials and Warehouse Defaults) will be used
 */
export const redshiftLocationDefaultsOverwriteSteps: WarehouseNavigationStepsOverwriteType =
  {
    [Warehouse.Redshift]: {
      [PROJECTS_CREDENTIALS]: {
        previousStep: PROJECTS_CREATE_ENVIRONMENT,
        currentStep: PROJECTS_CREDENTIALS,
        nextStep: PROJECTS_CLOUD_CREDENTIALS
      },
      [PROJECTS_CLOUD_CREDENTIALS]: {
        previousStep: PROJECTS_CREDENTIALS,
        currentStep: PROJECTS_CLOUD_CREDENTIALS,
        nextStep: PROJECTS_DEFAULTS
      },
      [PROJECTS_DEFAULTS]: {
        previousStep: PROJECTS_CLOUD_CREDENTIALS,
        currentStep: PROJECTS_DEFAULTS,
        nextStep: undefined
      }
    },
    [Warehouse.Snowflake]: {},
    [Warehouse.Databricks]: {}
  }
