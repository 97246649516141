import { useQuery, type UseQueryOptions } from 'react-query'

import { type AxiosError } from 'axios'

import { getMha } from 'api/clients'
import { type GETMhaResponse } from 'api/types'

import { QueryKey } from 'constants/endpoint'

import { useClient } from './useClient'

type TData = GETMhaResponse
type TError = AxiosError<unknown>

interface GetMhaProps {
  options?: UseQueryOptions<TData, TError>
  showErrorDetailMessage?: boolean
}
export const useGetMha = ({ options, showErrorDetailMessage }: GetMhaProps) => {
  const client = useClient({ showErrorDetailMessage })

  return useQuery<TData, TError>(
    [QueryKey.MATILLION_HOSTED_AGENTS],
    async () => {
      return getMha(client)
    },
    options
  )
}
