import { type FunctionComponent } from 'react'

import classNames from 'classnames'

import { CircledImage } from 'components/CircledImageContent/CircledImage'
import { TypographyWithToolTip } from 'components/TypographyWithTooltip'

import { getDataOrPlaceholder } from 'modules/Projects/StreamingListing/components/Cells/Cells.util'
import { StreamingIcon } from 'modules/Projects/StreamingListing/components/Cells/StreamingIcon'

import {
  hasDatabaseProperty,
  type SourceConfigType,
  type StreamingPipelineDefinition
} from 'types/Streaming'

import classes from './SourceCell.module.scss'

export interface SourceCellProps {
  source: StreamingPipelineDefinition['source']
}

const getStreamingSourceIcon = (source: SourceConfigType) => {
  const imageProvider = () => <StreamingIcon type={source.type} />
  imageProvider.displayName = 'StreamingIcon'
  return imageProvider
}

const SourceCell: FunctionComponent<SourceCellProps> = ({ source }) => {
  return (
    <div className={classes.SourceCell__Container}>
      <CircledImage
        Image={getStreamingSourceIcon(source)}
        logoName={source.type}
        imageContainerSize={40}
        imageSize={32}
      />
      <div className={classes.SourceCell__DetailsContainer}>
        {hasDatabaseProperty(source) && (
          <TypographyWithToolTip
            tooltip={getDataOrPlaceholder(source.connection?.database)}
            className={classes.SourceCell__Text}
            data-testid="source-database-name"
          >
            {getDataOrPlaceholder(source.connection?.database)}
          </TypographyWithToolTip>
        )}
        <TypographyWithToolTip
          tooltip={getDataOrPlaceholder(source.connection?.host)}
          className={classNames(
            classes.SourceCell__Text,
            hasDatabaseProperty(source)
              ? classes.SourceCell__HostText
              : undefined
          )}
          data-testid="source-host"
        >
          {getDataOrPlaceholder(source.connection?.host)}
        </TypographyWithToolTip>
      </div>
    </div>
  )
}

export default SourceCell
