import type { FunctionComponent, PropsWithChildren, ReactNode } from 'react'

import classNames from 'classnames'

import { Typography, type TypographyProps } from '@matillion/component-library'

import classes from './ExternalLink.module.scss'

interface ExternalLinkProps {
  className?: string
  format?: TypographyProps['format']
  href: string
  text?: string
  children?: ReactNode
  'data-testid'?: string
}

export const ExternalLink: FunctionComponent<
  PropsWithChildren<ExternalLinkProps>
> = ({
  format = 'bcm',
  href,
  className,
  text,
  children,
  'data-testid': dataTestId
}) => {
  return (
    <a
      className={classNames(classes.ExternalLink, className)}
      data-testid={dataTestId}
      href={href}
      rel="noopener noreferrer"
      target="_blank"
    >
      <Typography format={format} as="span">
        {text ?? children}
      </Typography>
    </a>
  )
}
