import { type SortState } from '@matillion/component-library'

import type { GETSchedulesResponse } from 'api/types'

import { type ScheduleTypes } from '.'

export const schedulesSort =
  (sortState: Partial<SortState<GETSchedulesResponse>>) =>
  (a: GETSchedulesResponse, b: GETSchedulesResponse): number => {
    const sortStateType = Object.keys(sortState)[0] as ScheduleTypes

    const aCompare = a[sortStateType]
    const bCompare = b[sortStateType]

    if (Object.values(sortState)[0] === 'ASC') {
      return aCompare.localeCompare(bCompare)
    } else {
      return bCompare.localeCompare(aCompare)
    }
  }
