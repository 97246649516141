import getMarketoInstance from 'marketo/getMarketoInstance'

export const useMarketo = () => {
  const callToMarketo = (
    munchkinFunctionType: 'clickLink' | 'visitWebPage',
    href: string
  ) => {
    const marketoInstance = getMarketoInstance()
    if (!marketoInstance) {
      console.log(
        'Marketo instance has not been initialised please call initMarketo'
      )
      return
    }
    marketoInstance.munchkinFunction(munchkinFunctionType, {
      href
    })
  }

  return [callToMarketo]
}
