import { useState, type FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'

import { Icon, Toaster } from '@matillion/component-library'

import { useCheckPermissions, useDeleteSchedule } from 'api/hooks'

import EllipsesActionItem, {
  type ThemeType
} from 'components/EllipsesActionItem/EllipsesActionItem'
import ConfirmCancelModal from 'components/Modal/ProjectExplorerModal'

import { QueryKey } from 'constants/endpoint'
import { AppRoutes } from 'constants/route'

import { PermissionsType } from 'types'
import { ResourceType } from 'types/ResourceTypes'

import { type ActionCellTypes } from '../'

const ActionCell: FunctionComponent<ActionCellTypes> = ({
  projectId,
  item
}) => {
  const { t } = useTranslation()
  const { makeToast, clearToasts } = Toaster.useToaster()
  const { mutateAsync, isLoading } = useDeleteSchedule()
  const queryClient = useQueryClient()
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false)

  const navigate = useNavigate()

  const onSwitchModalDeleteModal = () => {
    setIsDeleteModalVisible((v) => !v)
  }

  const { data: editPermission } = useCheckPermissions({
    resourceType: ResourceType.Environment,
    resourceId: item.environmentId,
    permission: PermissionsType.EditSchedules
  })
  const { data: deletePermission } = useCheckPermissions({
    resourceType: ResourceType.Environment,
    resourceId: item.environmentId,
    permission: PermissionsType.DeleteSchedules
  })

  const handleEditClick = () => {
    clearToasts()
    navigate(AppRoutes.getScheduleEdit(projectId, item.id))
  }

  const handleDeleteClick = () => {
    clearToasts()
    mutateAsync({ values: { id: item.id, projectId: projectId } })
      .then(async () => {
        clearToasts()
        makeToast({
          title: t('schedulesListing.delete.responseMessage.success', {
            scheduleName: item.name
          }),
          message: '',
          type: 'success'
        })

        await queryClient.invalidateQueries({
          queryKey: [QueryKey.SCHEDULES_LIST, projectId]
        })
      })
      .catch((_e) => {
        makeToast({
          title: t('schedulesListing.delete.responseMessage.error', {
            scheduleName: item.name
          }),
          message: '',
          type: 'error'
        })
      })
      .finally(() => {
        onSwitchModalDeleteModal()
      })
  }

  if (!editPermission && !deletePermission) {
    return null
  }

  return (
    <>
      <EllipsesActionItem
        actions={[
          ...(editPermission
            ? [
                {
                  title: t('schedulesListing.actions.edit'),
                  action: handleEditClick,
                  icon: <Icon.Pencil />,
                  dataTestId: 'ellipses-action-edit-schedule'
                }
              ]
            : []),
          ...(deletePermission
            ? [
                {
                  title: t('schedulesListing.actions.delete'),
                  action: onSwitchModalDeleteModal,
                  icon: <Icon.Trash />,
                  type: 'danger' as ThemeType,
                  dataTestId: 'ellipses-action-delete-schedule'
                }
              ]
            : [])
        ]}
      />

      {isDeleteModalVisible && (
        <ConfirmCancelModal
          onValidate={handleDeleteClick}
          onCancel={onSwitchModalDeleteModal}
          content={t('schedulesListing.delete.modal.content')}
          validate={t('schedulesListing.delete.modal.validate')}
          waiting={isLoading}
        />
      )}
    </>
  )
}

export default ActionCell
