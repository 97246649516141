import { useNavigate, useParams } from 'react-router-dom'

import { type FormikHelpers } from 'formik'

import { useNetworkErrorToast } from 'api/hooks'
import { updateEnvironmentWithSingleCloudCredentialRequest } from 'api/mutation'
import { type PUTEnvironmentRequestBody } from 'api/types'

import Form from 'components/Form'

import { ASSOCIATE_ENVIRONMENT_TO_CREDENTIALS_STORAGE_ITEM } from 'constants/persistance'
import { AppRoutes, PROJECT_DETAILS_ENVIRONMENTS } from 'constants/route'

import {
  formSchema,
  initialValues,
  type FormValues
} from 'modules/Projects/AssociateCloudCredentials/AssociateEnvironmentToCloudCredential/AssociateEnvironmentToCloudCredential.util'
import AssociateEnvironmentToCloudCredential from 'modules/Projects/AssociateCloudCredentials/AssociateEnvironmentToCloudCredential/FormContent/AssociateEnvironmentToCloudCredential'
import { useSubmitAssociateCloudCredentials } from 'modules/Projects/AssociateCloudCredentials/hooks'

import { type CloudProviders } from 'types/CloudProviders'

const AssociateEnvironmentToCloudCredentialForm = () => {
  const navigate = useNavigate()
  const { projectId } = useParams()
  const updateEnvironment = useSubmitAssociateCloudCredentials()
  const makeErrorToast = useNetworkErrorToast()

  const submitForm = (
    { cloudCredential, environment }: FormValues,
    { setSubmitting }: FormikHelpers<FormValues>
  ) => {
    if (!environment || !cloudCredential?.metadata?.provider) {
      setSubmitting(false)
      makeErrorToast({})
      return
    }

    const requestBody: PUTEnvironmentRequestBody =
      updateEnvironmentWithSingleCloudCredentialRequest({
        cloudCredentialId: cloudCredential.id,
        cloudCredentialProviderType: cloudCredential.metadata
          .provider as CloudProviders,
        environment: environment
      })

    updateEnvironment({
      requestBody,
      environment: { id: environment.id, name: environment.name },
      setSubmitting
    })
  }

  const handleFormCancel = () => {
    navigate(
      AppRoutes.getProjectDetails(projectId!, PROJECT_DETAILS_ENVIRONMENTS)
    )
  }

  return (
    <Form<FormValues>
      formikValues={{
        onSubmit: submitForm,
        initialValues: initialValues,
        validateOnMount: true,
        enableReinitialize: true,
        validationSchema: formSchema,
        initialTouched: false
      }}
      onCancel={handleFormCancel}
      translationPrefix="associateCredentials"
      persistingStorageId={ASSOCIATE_ENVIRONMENT_TO_CREDENTIALS_STORAGE_ITEM}
    >
      <AssociateEnvironmentToCloudCredential onFormCancel={handleFormCancel} />
    </Form>
  )
}

export default AssociateEnvironmentToCloudCredentialForm
