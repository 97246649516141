import { type FunctionComponent } from 'react'
import { useLocation } from 'react-router-dom'

import {
  PROJECTS_CLOUD_CREDENTIALS,
  PROJECTS_CREDENTIALS,
  PROJECTS_DEFAULTS
} from 'constants/route'

import { RedshiftCloudCredentials } from './CloudCredentials'
import { RedshiftCredentials } from './Credentials'
import { RedshiftDefaults } from './Defaults'

interface RedshiftProps {
  onCancel?: () => void
  onPrevious?: () => void
  showCancel?: boolean
  showPrevious?: boolean
  modalContent?: string
  excludeHeader?: boolean
  isCreateEnvironment?: boolean
}

const Redshift: FunctionComponent<RedshiftProps> = ({ ...props }) => {
  const location = useLocation()

  const currentPath = location.pathname.substring(
    location.pathname.lastIndexOf('/') + 1
  )

  const paths: { [key: string]: JSX.Element } = {
    [PROJECTS_CLOUD_CREDENTIALS]: <RedshiftCloudCredentials {...props} />,
    [PROJECTS_CREDENTIALS]: <RedshiftCredentials {...props} />,
    [PROJECTS_DEFAULTS]: <RedshiftDefaults {...props} />
  }

  return paths[currentPath] ?? null
}

export default Redshift
