import { type FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'

import { NoResourcesAvailable } from 'components/NoResourcesAvailable'

const NoReadPermission: FunctionComponent = () => {
  const { t } = useTranslation()

  return (
    <NoResourcesAvailable
      title={t(
        'secrets.cloudProviderCredentialsListing.noReadPermission.title'
      )}
      supportText={t(
        'secrets.cloudProviderCredentialsListing.noReadPermission.content'
      )}
      data-testid="no-read-permission-cloud-provider-credential"
    />
  )
}

export default NoReadPermission
