import { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

import classNames from 'classnames'
import { useFormikContext } from 'formik'

import { Field, LoadingSpinner, Toaster } from '@matillion/component-library'

import { useGetSecretReferences } from 'api/hooks'

import classes from 'components/Form/Form.module.scss'

import { AppRoutes, PROJECT_DETAILS_ENVIRONMENTS } from 'constants/route'

import { type FormValues } from 'modules/Projects/AssociateCloudCredentials/AssociateEnvironmentToCloudCredential/AssociateEnvironmentToCloudCredential.util'

import { SecretReferenceTypes } from 'types'

export const CloudCredentialName = () => {
  const { t } = useTranslation()
  const { projectId, secretId } = useParams()
  const navigate = useNavigate()
  const { makeToast } = Toaster.useToaster()
  const { setFieldValue, values } = useFormikContext<FormValues>()

  const {
    data: secretReferences,
    isLoading: secretReferencesLoading,
    isError: secretReferencesError
  } = useGetSecretReferences(
    projectId!,
    SecretReferenceTypes.CLOUD_PLATFORM_CREDENTIALS
  )

  useEffect(() => {
    if (secretReferences && !secretReferencesLoading) {
      const foundCloudCredential = secretReferences?.find(
        (x) => x.id === secretId
      )
      if (!foundCloudCredential) {
        makeToast({
          title: t(
            'associateCredentials.fields.cloudCredentialName.error.notFound'
          ),
          message: '',
          type: 'error'
        })
        navigate(
          AppRoutes.getProjectDetails(projectId!, PROJECT_DETAILS_ENVIRONMENTS)
        )
      }
      setFieldValue('cloudCredential', foundCloudCredential)
    }
  }, [
    makeToast,
    navigate,
    projectId,
    secretId,
    secretReferences,
    secretReferencesLoading,
    setFieldValue,
    t
  ])
  const getErrorMessage = useMemo(() => {
    if (secretReferencesError && !secretReferencesLoading) {
      // Ensures the value will be update after the error state has been set
      // This way the rerender of the Field component doesn't update with the Formik update
      setTimeout(() => {
        setFieldValue('cloudCredential', null)
      }, 0)
      return t(
        'associateCredentials.fields.cloudCredentialName.error.loadingError'
      )
    }
    return ''
  }, [secretReferencesError, secretReferencesLoading, setFieldValue, t])

  return (
    <div data-testid="associate-environment-to-cloud-credential-name">
      {secretReferencesLoading ? (
        <LoadingSpinner />
      ) : (
        <Field
          title={t('associateCredentials.fields.cloudCredentialName.title')}
          name="cloudCredential"
          data-testid="associate-environment-to-cloud-credential-name-input"
          value={values.cloudCredential?.name}
          placeholder={
            secretReferencesError
              ? t(
                  'associateCredentials.fields.cloudCredentialName.error.placeholderText'
                )
              : undefined
          }
          className={classNames(classes.Form__SpacingStyles)}
          errorText={getErrorMessage}
          hasError={secretReferencesError && !secretReferencesLoading}
          disabled
        />
      )}
    </div>
  )
}
