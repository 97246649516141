export enum WarehouseStatus {
  ACTIVE = 'ACTIVE',
  SUSPENDED = 'SUSPENDED',
  PENDING = 'PENDING',
  CREATING = 'CREATING',
  DELETING = 'DELETING',
  TERMINATED = 'TERMINATED',
  REJECTED = 'REJECTED',
  UPDATING = 'UPDATING',
  NOTFOUND = 'NOTFOUND',
  UNKNOWN = 'UNKNOWN'
}
