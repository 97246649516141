import { type FunctionComponent } from 'react'

import { GenericCredentials } from 'modules/Projects/AssociateCloudCredentials/AssociateCloudCredentialsToEnvironment/FormContent/Fields'

import { CloudProviders } from 'types/CloudProviders'

export const AzureCredentials: FunctionComponent = () => {
  return (
    <GenericCredentials
      cloudProvider={CloudProviders.AZURE}
      fieldId="azureCredentials"
      fieldName="Azure"
    />
  )
}
