import { useEffect, useMemo, type FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'

import classNames from 'classnames'
import { useFormikContext } from 'formik'

import {
  AutoComplete,
  Field,
  Icon,
  LoadingSpinner
} from '@matillion/component-library'

import {
  enabledStatus,
  startingStatus,
  WarehouseLookupTypes
} from 'api/createProjectForm/types'
import { useMutateWarehouseDefaults } from 'api/hooks'
import { createWarehouseDefaultsMutation } from 'api/mutation'
import { type ErrorResponse } from 'api/types'

import classes from 'components/Form/Form.module.scss'

import { StatusCodes } from 'constants/statusCodes'

import { type CreateProjectFormikValueTypes } from 'modules/Projects/CreateProject/CreateProjectForm'
import { renderFormikError } from 'modules/utils'

const FIELD_NAME = 'catalog'
const { Error: ErrorIcon } = Icon

const Catalog: FunctionComponent = () => {
  const {
    errors,
    handleBlur,
    handleChange,
    touched,
    values,
    setFieldTouched,
    setFieldValue,
    initialValues
  } = useFormikContext<CreateProjectFormikValueTypes>()
  const { t } = useTranslation()
  const {
    data: listOfCatalogs = [],
    mutate: mutateCatalog,
    isLoading,
    isError: fetchingError,
    error
  } = useMutateWarehouseDefaults(WarehouseLookupTypes.CATALOG)

  const { compute } = values

  const isRunning = !!compute?.state && enabledStatus.includes(compute.state)
  const isStartingUp =
    !!compute?.state && startingStatus.includes(compute.state)

  const computePopulated =
    compute &&
    !!compute.clusterId &&
    !!compute.clusterName &&
    !!compute.clusterType

  listOfCatalogs.sort((a, b) => a.localeCompare(b))

  const computeFormikError = renderFormikError(
    errors.catalog,
    Boolean(touched.catalog)
  )

  const catalogError = useMemo(() => {
    if (!computePopulated || !isRunning) return null
    if (fetchingError) {
      const errorDetail = (error?.response?.data as ErrorResponse)?.detail
      const errorResponseStatus = error?.response?.status
      if (errorResponseStatus === StatusCodes.BAD_REQUEST && errorDetail) {
        return errorDetail
      }
      return t('fields.catalog.error.loadingError')
    }
    if (computeFormikError && listOfCatalogs.length) {
      return t(computeFormikError)
    }
    if (listOfCatalogs.length === 0 && !isLoading) {
      return t('fields.catalog.error.noItemsFound')
    }
    return null
  }, [
    computeFormikError,
    fetchingError,
    error,
    computePopulated,
    isRunning,
    isLoading,
    listOfCatalogs.length,
    t
  ])

  const listenerFieldValue = values.compute
  const initialListenerFieldValue = initialValues.compute

  useEffect(() => {
    if (
      listenerFieldValue?.id !== initialListenerFieldValue?.id ||
      !computePopulated
    ) {
      setFieldTouched(FIELD_NAME, false)
      setFieldValue(FIELD_NAME, { id: '', name: '' })
    }
    if (computePopulated) {
      mutateCatalog({
        values: createWarehouseDefaultsMutation(values)
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    computePopulated,
    mutateCatalog,
    setFieldTouched,
    setFieldValue,
    values.compute
  ])

  const disabled =
    (fetchingError && !isLoading) || !computePopulated || !isRunning
  const determineErrorState =
    !!compute && !!compute.id ? !isStartingUp && !isRunning : compute?.state

  return (
    <div data-testid="databricks-defaults-catalog">
      <Field
        title={t('fields.catalog.title')}
        name={FIELD_NAME}
        inputComponent={AutoComplete}
        availableItems={listOfCatalogs.map((catalog) => ({
          id: catalog,
          name: catalog
        }))}
        loading={isLoading}
        data-testid="databricks-defaults-catalog-input"
        value={values.catalog}
        placeholder={usePlaceholder(
          fetchingError,
          isStartingUp,
          determineErrorState
        )}
        iconBefore={useIconBefore(isStartingUp, determineErrorState)}
        onBlur={handleBlur}
        onChange={handleChange}
        className={classNames(classes.Form__SpacingStyles)}
        supportText={t('fields.catalog.supportText')}
        errorText={isLoading ? undefined : catalogError}
        hasError={
          Boolean(errors?.catalog?.id) && Boolean(touched.catalog) && !isLoading
        }
        required
        disabled={disabled}
        colorTheme={disabled ? 'grey' : 'white'}
      />
    </div>
  )
}

const useIconBefore = (
  isStartingUp: boolean,
  determineErrorState: string | boolean | undefined
) => {
  const startingUpIcon = {
    icon: <LoadingSpinner small={true} />,
    clickable: false
  }
  const errorIcon = {
    icon: <ErrorIcon />,
    clickable: false
  }
  const includeStartingUpIcon = isStartingUp ? startingUpIcon : undefined
  const iconBefore = determineErrorState ? errorIcon : includeStartingUpIcon

  return iconBefore
}

const usePlaceholder = (
  fetchingError: boolean,
  isStartingUp: boolean,
  determineErrorState: string | boolean | undefined
) => {
  const { t } = useTranslation()

  const defaultPlaceholder = fetchingError
    ? t('fields.catalog.error.placeholderText')
    : t('fields.catalog.placeholderText')
  const includeStartingUpPlaceholder = isStartingUp
    ? t('fields.catalog.startingUpText')
    : defaultPlaceholder
  const placeholder = determineErrorState
    ? t('fields.catalog.stoppedText')
    : includeStartingUpPlaceholder

  return placeholder
}

export default Catalog
