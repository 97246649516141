import { useQuery, type UseQueryResult } from 'react-query'

import { type AxiosError } from 'axios'

import { checkBulkPermission } from 'api/permissions'
import { type GETCheckBulkPermissionResponse } from 'api/types'

import { QueryKey } from 'constants/endpoint'

import type { PermissionsType } from 'types'

import { usePermissionsClient } from './usePermissionsClient'

type TError = AxiosError<unknown>

interface CheckBulkPermissionsProps {
  resourceType: string
  resourceId: string
  permission: PermissionsType
}

type params = CheckBulkPermissionsProps[]

export const useCheckBulkPermissions = (
  params: params
): UseQueryResult<GETCheckBulkPermissionResponse, TError> => {
  const permissionsClient = usePermissionsClient()
  const ids: string[] = []
  const resources = params.map((param) => {
    ids.push(param.resourceId)
    return {
      resource: {
        id: param.resourceId,
        type: param.resourceType
      },
      permission: param.permission
    }
  })
  const queryKey = [QueryKey.USER_PERMISSIONS, ids.join(',')]
  const queryFn = async () => {
    if (resources.length) {
      const bulkCheckPermission = await checkBulkPermission(
        resources,
        permissionsClient
      )
      return bulkCheckPermission
    }
  }
  return useQuery<GETCheckBulkPermissionResponse, TError>({ queryKey, queryFn })
}
