import {
  useCallback,
  useMemo,
  type ChangeEvent,
  type FunctionComponent
} from 'react'
import { useTranslation } from 'react-i18next'

import classNames from 'classnames'
import { useFormikContext, type FormikErrors } from 'formik'

import { AutoComplete, Field } from '@matillion/component-library'

import { useGetSecretNames } from 'api/createProjectForm/hooks'

import classes from 'components/Form/Form.module.scss'

import { type CreateProjectFormikValueTypes } from 'modules/Projects/CreateProject/CreateProjectForm'
import { renderFormikError, type ObjectProps } from 'modules/utils'

import { AgentCloudProvider } from 'types/AgentCloudProvider'

export interface SecretNameProps {
  fieldName?: keyof CreateProjectFormikValueTypes
  secretKeyField?: keyof CreateProjectFormikValueTypes
  testName?: string
  required?: boolean
}

export const SecretName: FunctionComponent<SecretNameProps> = ({
  fieldName = 'secretName',
  secretKeyField = 'secretKey',
  testName = 'secret-name',
  required = true
}) => {
  const {
    errors,
    handleBlur,
    handleChange,
    touched,
    values,
    setTouched,
    setValues
  } = useFormikContext<CreateProjectFormikValueTypes>()
  const { t } = useTranslation()

  const isEnabled = !!values.secretLocationId && !!values.etlAgent?.id
  const agentCloudProvider = values.etlAgent?.agentCloudProvider
  const error = errors[fieldName] as string | FormikErrors<ObjectProps>

  const {
    data: secretNamesData = [],
    isError,
    isLoading,
    refetch
  } = useGetSecretNames(values.secretLocationId, values.etlAgent?.id, {
    enabled: isEnabled
  })

  const refetchOnOpen = useCallback(async () => {
    await refetch()
  }, [refetch])

  const secretNameError = renderFormikError(error, Boolean(touched[fieldName]))

  const placeholder =
    agentCloudProvider === AgentCloudProvider.AZURE
      ? t('fields.azureSecretName.placeholder')
      : t('fields.secretName.placeholder')

  const getErrorMessage = useMemo(() => {
    if (isError) {
      return t('fields.secretName.error.loadingError')
    }

    if (!secretNamesData.length && isEnabled) {
      return t('fields.secretName.error.noItemsFound')
    }

    if (secretNameError) {
      return t(secretNameError)
    }

    return null
  }, [isError, secretNamesData.length, isEnabled, secretNameError, t])

  const updateValues = (e: ChangeEvent<HTMLInputElement>) => {
    const notTouchedAutocomplete = undefined
    const emptyAutocomplete = { name: '', id: '' }
    setValues({
      ...values,
      [e.target.name]: e.target?.value || '',
      [secretKeyField]: emptyAutocomplete
    })

    setTouched({
      ...touched,
      [fieldName]: notTouchedAutocomplete,
      [secretKeyField]: notTouchedAutocomplete
    })
  }

  const testId = `${values.type}-credentials-${testName}`

  return (
    <div data-testid={testId}>
      <Field
        inputComponent={AutoComplete}
        availableItems={secretNamesData.map((item) => ({
          id: item,
          name: item
        }))}
        loading={isLoading}
        title={t(`fields.${fieldName}.title`)}
        name={fieldName}
        data-testid={`${testId}-input`}
        value={values[fieldName]}
        placeholder={placeholder}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          handleChange(e)
          updateValues(e)
        }}
        onOpen={refetchOnOpen}
        onBlur={handleBlur}
        className={classNames(classes.Form__SpacingStyles)}
        supportText={t(`fields.${fieldName}.supportText`)}
        errorText={isLoading ? undefined : getErrorMessage}
        hasError={
          Boolean(typeof error === 'object' ? error.id : false) &&
          Boolean(touched[fieldName]) &&
          !isLoading
        }
        required={required}
        disabled={isError || !isEnabled || !secretNamesData.length}
      />
    </div>
  )
}
