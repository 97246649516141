import { useTranslation } from 'react-i18next'

import { Typography } from '@matillion/component-library'
import { useUser } from '@matillion/hub-client'

import { ExternalLink } from 'components/ExternalLink'

import { getAgentsListingUrl } from 'modules/utils'

import classes from './AgentSupportText.module.scss'

export const AgentSupportText = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'createStreamingPipeline.fields.agent.supportText'
  })

  const { organisation } = useUser()

  return (
    <Typography format="bcs">
      {t('textBeforeLink')}
      <ExternalLink
        format="bcs"
        href={getAgentsListingUrl(organisation.subdomain, organisation.region)}
        text={t('linkText')}
        className={classes.AgentSupportText__Link}
      />
      {t('textAfterLink')}
    </Typography>
  )
}
