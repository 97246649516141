import { type FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'

import classNames from 'classnames'
import { useFormikContext } from 'formik'

import { Radio, Typography } from '@matillion/component-library'

import { AgentsSecretsHost, GitProvider, ProjectConfigurationType } from 'types'
import type { ProjectDetailsI } from 'types/FormTypes'

import classes from './ProjectConfigurationCard.module.scss'

export interface ProjectConfigurationCardProps {
  title: string
  supportText: string
  desiredProjectConfiguration: ProjectConfigurationType
  typeOfSetUp: string
  bullets?: string[]
}

const ProjectConfiguration: FunctionComponent<
  ProjectConfigurationCardProps
> = ({
  title,
  supportText,
  desiredProjectConfiguration,
  typeOfSetUp,
  bullets
}) => {
  const { t } = useTranslation()
  const { setFieldValue, values } = useFormikContext<ProjectDetailsI>()
  const { projectConfiguration } = values
  const isSelected = projectConfiguration === desiredProjectConfiguration

  const onSelect = () => {
    if (desiredProjectConfiguration === ProjectConfigurationType.Matillion) {
      setFieldValue('provider', GitProvider.Matillion)
    } else {
      setFieldValue('provider', '')
    }
    setFieldValue('projectConfiguration', desiredProjectConfiguration)
    setFieldValue('agentsSecretsManagement', AgentsSecretsHost.MatillionHosted)
  }

  return (
    <button
      name="project-configuration-card-btn"
      aria-label={t('projectConfiguration.ariaLabels.button')}
      type="button"
      onClick={onSelect}
      value={projectConfiguration}
      className={classNames(
        classes.ProjectConfigurationCard__Container,
        isSelected && classes['ProjectConfigurationCard__Container--selected']
      )}
      data-testid={`${desiredProjectConfiguration}-card`}
    >
      <div className={classes.ProjectConfigurationCard__TopBanner}>
        <div
          className={classes.ProjectConfigurationCard__SetUp}
          data-testid={`${desiredProjectConfiguration}-setUp`}
        >
          {typeOfSetUp}
        </div>
        <Radio
          name="project-configuration-radio"
          className={classes.ProjectConfigurationCard__RadioBtn}
          checked={isSelected}
          onChange={onSelect}
          data-testid={`${desiredProjectConfiguration}-radio`}
        />
      </div>
      <div className={classes.ProjectConfigurationCard__Content}>
        <div className={classes.ProjectConfigurationCard__TitleContainer}>
          <Typography
            as="h3"
            format="tm"
            data-testid={`${desiredProjectConfiguration}-title`}
          >
            {title}
          </Typography>
        </div>
        <div>
          <Typography
            format="bcm"
            data-testid={`${desiredProjectConfiguration}-support-text`}
          >
            {supportText}
          </Typography>
          {!!bullets?.length && (
            <ul>
              {bullets.map((bullet, index) => (
                <li key={`${bullet}-${index}`}>
                  <Typography
                    format="bcm"
                    data-testid={`${desiredProjectConfiguration}-bullet-${index}`}
                  >
                    {bullet}
                  </Typography>
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </button>
  )
}

export default ProjectConfiguration
