import { useMutation, type UseMutationOptions } from 'react-query'

import { type AxiosError } from 'axios'

import { postSecretReference } from 'api/clients'
import { useClient } from 'api/hooks'
import type {
  POSTSecretReferenceRequest,
  POSTSecretReferenceResponse
} from 'api/types'

import { QueryKey } from 'constants/endpoint'

type TData = POSTSecretReferenceResponse
type TError = AxiosError<unknown>
interface TVariables {
  values: POSTSecretReferenceRequest
}

type Options = UseMutationOptions<
  POSTSecretReferenceResponse,
  AxiosError,
  TVariables
>

export const usePostSecretReference = (
  projectId?: string,
  options?: Options
) => {
  const client = useClient()

  return useMutation<TData, TError, TVariables>(
    [QueryKey.SECRET_REFERENCE],
    async (data: { values: POSTSecretReferenceRequest }) => {
      const { values } = data
      return postSecretReference(
        values,
        {
          projectId
        },
        client
      )
    },
    options
  )
}
