import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import {
  Field,
  LoadingSpinner,
  Toaster,
  Toggle
} from '@matillion/component-library'

import { useCheckPermissions, useGetSchedules, usePutSchedule } from 'api/hooks'
import { enableScheduleMutationData } from 'api/mutation'
import { type GETSchedulesResponse } from 'api/types'

import { PermissionsType } from 'types'
import { ResourceType } from 'types/ResourceTypes'

import { type SortedScheduleDataTypes } from '../'

export const EnableScheduleToggle = ({
  row
}: {
  row: SortedScheduleDataTypes
}) => {
  const { projectId } = useParams()
  const [isLoading, setIsLoading] = useState(false)
  const [enabled, setEnabled] = useState(row.scheduleEnabled)
  const { data: editPermission, isLoading: isEditPermissionLoading } =
    useCheckPermissions({
      resourceType: ResourceType.Environment,
      resourceId: row.environmentId,
      permission: PermissionsType.EditSchedules
    })
  const { t } = useTranslation()
  const { makeToast } = Toaster.useToaster()
  const { data: schedulesData, isLoading: isSchedulesLoading } =
    useGetSchedules(projectId!)
  const { mutateAsync: mutateAsyncSchedule } = usePutSchedule()

  const publishSchedule = async (
    values: GETSchedulesResponse,
    onPublishStatus: (success: boolean, scheduleName?: string) => void,
    onFinish: () => void
  ) => {
    return mutateAsyncSchedule({
      values: enableScheduleMutationData(values, !enabled, projectId!),
      scheduleId: values.id
    })
      .then((_res) => {
        onPublishStatus(true)
      })
      .catch((_res) => {
        onPublishStatus(false)
      })
      .finally(onFinish)
  }

  const toggleSchedule = async () => {
    const schedule = schedulesData?.find((o) => o.id === row.id)
    if (!schedule) {
      makeToast({
        title: t('editSchedule.responseMessage.error'),
        message: '',
        type: 'error'
      })
      return
    }
    setIsLoading(true)
    await publishSchedule(
      schedule,
      (success) => {
        if (success) {
          makeToast({
            title: t('editSchedule.responseMessage.success', {
              scheduleName: row.name
            }),
            message: '',
            type: 'success'
          })
          setEnabled(!enabled)
          return
        }

        makeToast({
          title: t('editSchedule.responseMessage.error'),
          message: '',
          type: 'error'
        })
      },
      () => {
        setIsLoading(false)
      }
    )
  }
  if (isEditPermissionLoading || isSchedulesLoading) {
    return <LoadingSpinner />
  }

  return (
    <Field
      name="enableSchedule"
      key={row.id}
      data-testid={`${row.id}-schedule-toggle-input-checkbox`}
      labelDataTestId={`${row.id}-schedule-toggle-input-label`}
      inputComponent={Toggle}
      overlayText={{
        on: t('schedulesListing.scheduleToggle.on'),
        off: t('schedulesListing.scheduleToggle.off')
      }}
      value={enabled}
      checked={enabled}
      disabled={editPermission ? isLoading : true}
      onChange={toggleSchedule}
    />
  )
}
