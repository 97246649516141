export interface OAuthSchema {
  provider: string
  resourceId: string
  authTypes: OAuthType[]
}

export interface OAuthType {
  type: string
  defaultName?: string
  resourceId: string
  scopes?: string[]
  applications?: OAuthApplication[]
  parameters?: OAuthParameter[]
  additionalParameters?: OAuthParameter[]
  federatedAuthProvider?: string
}

export interface OAuthApplication {
  resourceId: string
  id: string
  scopes: string[]
}

export interface OAuthParameter {
  resourceId: string
  defaultName?: string
  id: string
  type: OAuthParameterType
  saveToSecret?: boolean
  sendToAuthServer?: boolean
  nullable?: boolean
  options?: OAuthParameterOption[]
  password?: boolean
}

export interface OAuthParameterOption {
  id: string
  resourceId: string
  defaultName?: string
}
export type GETOAuthSchemaResponse = OAuthSchema[]

export enum OAuthParameterType {
  BadType = 'bad-type',
  Textbox = 'string',
  Dropdown = 'select-from-options'
}

// combine AdditionalParameters object into Parameters object
export const mutateGetOAuthSchemaResponse = (
  response: GETOAuthSchemaResponse
): GETOAuthSchemaResponse => {
  return response.map((schema) => ({
    ...schema,
    authTypes: schema.authTypes.map((authType) => {
      const authT = {
        ...authType,
        parameters: [
          ...(authType.parameters || []),
          ...(authType.additionalParameters || [])
        ]
      }

      delete authT.additionalParameters
      return authT
    })
  }))
}
