import { useFormikContext } from 'formik'

import { DefaultDatabase } from 'modules/Common/Fields'
import { type CreateProjectFormikValueTypes } from 'modules/Projects/CreateProject/CreateProjectForm'

const RedshiftDatabase = () => {
  const { values } = useFormikContext<CreateProjectFormikValueTypes>()
  const isFieldEnabled =
    !!values.secretReferenceId && !!values.account && !!values.username
  const listenerFields: Array<keyof CreateProjectFormikValueTypes> = [
    'secretReferenceId',
    'account',
    'username'
  ]
  return (
    <DefaultDatabase
      isFieldEnabled={isFieldEnabled}
      listenerFields={listenerFields}
    />
  )
}

export default RedshiftDatabase
