import { type FunctionComponent } from 'react'
import { type Value } from 'react-date-picker/dist/cjs/shared/types'
import { useTranslation } from 'react-i18next'

import classNames from 'classnames'
import { format } from 'date-fns'
import { useFormikContext } from 'formik'

import { Label } from '@matillion/component-library'

import DatePicker from 'components/DatePicker'

import { defaultDateFormat } from 'constants/dateTimeFormats'

import formClasses from 'modules/FormsContent/Form.module.scss'
import { type ScheduleFormValues } from 'modules/Projects/CreateSchedule/types'

import { nowPlus2Minutes } from 'utils/nowPlus2Minutes'

import classes from './StartDate.module.scss'

export const StartDate: FunctionComponent = () => {
  const { t } = useTranslation()

  const {
    values: {
      schedule: { startDate, timezone }
    },
    setFieldValue
  } = useFormikContext<ScheduleFormValues>()

  const today = nowPlus2Minutes(timezone?.id)
  const [year, month, day] = startDate.split('-')

  return (
    <div
      className={classNames(
        formClasses.Form__Input,
        formClasses['Form__Input--short']
      )}
      data-testid="schedule-starts-on-date"
    >
      <Label title={t('createSchedule.fields.startDate.title')} role="h4">
        <DatePicker
          onChange={(value: Value) => {
            if (value instanceof Date) {
              setFieldValue(
                'schedule.startDate',
                format(value, defaultDateFormat)
              )
            }
          }}
          minDate={today}
          // Manually setting date object to avoid incorrect locale translation
          value={
            new Date(Number(year), Number(month) - 1, Number(day), 0, 0, 0)
          }
          data-testid="schedule-date-picker"
          className={classes.StartDate}
        />
      </Label>
    </div>
  )
}
