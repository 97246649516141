import { type FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'

import { Field as FormikField, type FieldProps } from 'formik'

import { Field, Textarea } from '@matillion/component-library'

import {
  FormFields,
  resolveFormikError
} from 'modules/Secrets/CreateSecretDefinitionForm/CreateSecretDefinitionForm.utils'
import classes from 'modules/Secrets/SecretDefinitionFormContent/SecretDefinitionFormContent.module.scss'

const Description: FunctionComponent = () => {
  const { t } = useTranslation()

  return (
    <div data-testid="secret-definition-description">
      <FormikField name={FormFields.description}>
        {({ field, meta }: FieldProps<string>) => (
          <Field
            {...field}
            className={classes['SecretDefinitionFormContent__Field--normal']}
            title={t('secrets.definitionForm.fields.description.title')}
            placeholder={t(
              'secrets.definitionForm.fields.description.placeholder'
            )}
            inputComponent={Textarea}
            maxLength={256}
            errorText={resolveFormikError(t, meta)}
            data-testid="secret-definition-description-input"
          />
        )}
      </FormikField>
    </div>
  )
}

export default Description
