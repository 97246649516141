import { useEffect, useMemo } from 'react'

import { useFormikContext } from 'formik'

import {
  useFetchStreamingSourceSchemas,
  useFetchStreamingSourceTables
} from 'api/hooks/streaming'

import {
  mapSourceConnectionFormToDefinition,
  useFormMetadata,
  type FormValues
} from 'modules/Projects/CreateStreamingPipeline/FormContent'

export const useSourceMetadataLoader = () => {
  const { values } = useFormikContext<FormValues>()
  const { metadata, setMetadataProperty } = useFormMetadata()

  const { mutateAsync: mutateFetchSchemas } = useFetchStreamingSourceSchemas()
  const { mutateAsync: mutateFetchTables } = useFetchStreamingSourceTables()

  const connection = useMemo(
    () => mapSourceConnectionFormToDefinition(values.source.connection),
    [values.source.connection]
  )

  const isValid = metadata.validation.sourceConnection.isError === false
  const type = values.source.type.id
  const agentId = values.agent.id
  const schemaMetadata = metadata.source.schemas
  const selectedTables = values.source.tables

  useEffect(() => {
    // if connection details change, we lose validation status
    setMetadataProperty('validation.sourceConnection.isError', null)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connection])

  const loadTablesIfSchemaSelected = (schema: string) => {
    const isSelected = selectedTables?.some((table) => table.schema === schema)
    schemaMetadata[schema] = {
      selected: isSelected,
      tablesLoading: isSelected
    }
    if (isSelected) {
      // if the schema is selected, we load the tables
      mutateFetchTables({
        agentId,
        type,
        connection,
        schema
      }).then((tables) => {
        schemaMetadata[schema] = {
          selected: true,
          tables,
          tablesLoading: false
        }
      })
    }
  }

  useEffect(() => {
    if (isValid) {
      // source connection details are valid, so we can load schema details
      mutateFetchSchemas({
        agentId,
        type,
        connection
      }).then((schemas) => {
        schemas.forEach((schema) => {
          loadTablesIfSchemaSelected(schema)
        })
      })
    }
    // use subset of dependencies to avoid unnecessary re-renders
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValid, agentId, type])
}
