import { type FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'

import { Field as FormikField, useFormikContext, type FieldProps } from 'formik'
import { isEmpty } from 'lodash'

import { AutoComplete, Field } from '@matillion/component-library'
import { type AutoCompleteItem } from '@matillion/component-library/dist/components/AutoComplete/types'

import {
  getDefaultSourceFormValues,
  resolveErrorText,
  type FormValues
} from 'modules/Projects/CreateStreamingPipeline/FormContent'
import { FIELD_NAMES_COMPLETE_SOURCE } from 'modules/Projects/CreateStreamingPipeline/FormContent/ConfigurePipelineDetailsPage/types'

import { STREAMING_SOURCES, type StreamingSourceId } from 'types/Streaming'

import classes from './Fields.module.scss'
import { FIELD_NAMES_SOURCE, type SourceAutocompleteType } from './types'

export const Source: FunctionComponent = () => {
  const { t } = useTranslation()

  const { setFieldValue } = useFormikContext<FormValues>()

  return (
    <FormikField name={FIELD_NAMES_SOURCE}>
      {({
        field,
        meta,
        form: { isSubmitting }
      }: FieldProps<SourceAutocompleteType>) => (
        <div data-testid="configure-streaming-pipeline-source">
          <Field
            {...field}
            data-testid="configure-streaming-pipeline-source-input"
            title={t('createStreamingPipeline.fields.source.title')}
            name={FIELD_NAMES_SOURCE}
            inputComponent={AutoComplete}
            onChange={(newValue: { target: AutoCompleteItem }) => {
              if (newValue.target.value && !isEmpty(newValue.target.value.id)) {
                setFieldValue(
                  FIELD_NAMES_COMPLETE_SOURCE,
                  getDefaultSourceFormValues(
                    newValue.target.value.id as StreamingSourceId
                  )
                )
              }
            }}
            availableItems={Object.values(STREAMING_SOURCES)}
            errorText={resolveErrorText(t, meta)}
            disabled={isSubmitting}
            iconBefore={{
              icon: (
                <span className={classes.Fields__FieldIcon}>
                  {field.value && (
                    <img
                      alt={field.value.name}
                      src={STREAMING_SOURCES[field.value.id]?.icon}
                    />
                  )}
                </span>
              )
            }}
          />
        </div>
      )}
    </FormikField>
  )
}
