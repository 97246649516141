import { useMemo, type ChangeEvent, type FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'

import { useFormikContext } from 'formik'

import { AutoComplete, Field } from '@matillion/component-library'

import classes from 'components/Form/Form.module.scss'

import { type CreateProjectFormikValueTypes } from 'modules/Projects/CreateProject/CreateProjectForm'
import { renderFormikError } from 'modules/utils'

import { AgentCloudProvider } from 'types/AgentCloudProvider'
import type { AutoCompleteProps } from 'types/FormTypes'

export interface CredentialsTypeProps {
  isFieldEnabled?: boolean
}

export const CredentialsType: FunctionComponent<CredentialsTypeProps> = ({
  isFieldEnabled = true
}) => {
  const { errors, touched, values, handleBlur, setValues, setTouched } =
    useFormikContext<CreateProjectFormikValueTypes>()
  const { t } = useTranslation()
  const agentCloudProvider = values.etlAgent?.agentCloudProvider

  const projectType = values.type || 'common'

  const credentialsTypeError = renderFormikError(
    errors.credentialsType,
    Boolean(touched.credentialsType)
  )

  const getErrorMessage = useMemo(() => {
    if (!isFieldEnabled) return null
    if (credentialsTypeError) return t(credentialsTypeError)
    return null
  }, [credentialsTypeError, isFieldEnabled, t])

  const availableItems = [
    {
      id: 'password',
      name: t('fields.credentialsType.options.password')
    }
  ]

  if (agentCloudProvider !== AgentCloudProvider.AZURE) {
    availableItems.push({
      id: 'key_pair',
      name: t('fields.credentialsType.options.keypair')
    })
  }

  const handleValueUpdates = (
    e: ChangeEvent<HTMLInputElement> & {
      target: { value: AutoCompleteProps }
    }
  ) => {
    if (!e.target.value) return
    setValues({
      ...values,
      credentialsType: e.target.value
    })
    setTouched({
      ...touched,
      [e.target.name]: true
    })
  }

  return (
    <div data-testid={`${projectType}-credentials-type`}>
      <Field
        inputComponent={AutoComplete}
        availableItems={availableItems}
        title={t('fields.credentialsType.title')}
        name="credentialsType"
        data-testid={`${projectType}-credentials-type-input`}
        value={values.credentialsType}
        placeholder={t('fields.credentialsType.placeholderText')}
        onChange={(
          e: ChangeEvent<HTMLInputElement> & {
            target: { value: AutoCompleteProps }
          }
        ) => {
          handleValueUpdates(e)
        }}
        onBlur={handleBlur}
        className={classes.Form__SpacingStyles}
        supportText={t('fields.credentialsType.supportText')}
        errorText={!isFieldEnabled ? undefined : getErrorMessage}
        hasError={
          Boolean(errors?.credentialsType?.id) &&
          Boolean(touched.credentialsType) &&
          isFieldEnabled
        }
        required
        disabled={!isFieldEnabled}
      />
    </div>
  )
}

export default CredentialsType
