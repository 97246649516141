import { type TFunction } from 'react-i18next'

import type { StreamingSourceId } from 'types/Streaming'
import {
  DB_APPLICATION_INTENT,
  hasReadReplicaProperty
} from 'types/Streaming/engine'

export const removeFirstClassAdvancedProperties = (
  sourceId: StreamingSourceId,
  properties?: Record<string, string>
) => {
  const propertiesCopy = { ...properties }
  if (
    DB_APPLICATION_INTENT.supportedBy.includes(sourceId) &&
    hasReadReplicaProperty(propertiesCopy)
  ) {
    // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
    delete propertiesCopy[DB_APPLICATION_INTENT.property]
  }
  return propertiesCopy
}

export const mapEnabledDisabled = (value: boolean, t: TFunction): string => {
  return value
    ? t('streamingPipelineDashboard.summary.fields.common.enabled')
    : t('streamingPipelineDashboard.summary.fields.common.disabled')
}
