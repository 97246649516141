import {
  type GETDatabricksComputeResourceResponseParams,
  type GETWarehouseDefaultsRequest
} from 'api/createProjectForm/types'

import { AgentsSecretsHost, Warehouse } from 'types'
import { type AutoCompleteProps } from 'types/FormTypes'

interface FormValues {
  account: string
  tempAccount?: string
  matillionHostedAgentId: string
  etlAgent: AutoCompleteProps
  tempSecretReferenceId?: string
  secretReferenceId: string
  passphraseSecretReferenceId?: string
  awsSecretReferenceId?: string
  username: string
  type: '' | Warehouse
  tempUsername?: string
  role?: string
  database?: string
  catalog?: AutoCompleteProps
  compute?: GETDatabricksComputeResourceResponseParams & AutoCompleteProps
  defaultRole: AutoCompleteProps
  defaultDatabase: AutoCompleteProps
  ssl?: boolean | null
  port?: number | null
  agentsSecretsManagement: string
  credentialsType: AutoCompleteProps
  passphrase?: string
}

export const getCredentials = (formValues: FormValues) => {
  const { credentialsType, secretReferenceId, passphraseSecretReferenceId } =
    formValues

  switch (credentialsType.id) {
    case 'key_pair':
      return {
        privateKey: secretReferenceId,
        passphrase: passphraseSecretReferenceId || null
      }
    case 'password':
      return {
        password: secretReferenceId
      }
    default:
      return null
  }
}

export const createWarehouseDefaultsMutation = (
  formValues: FormValues,
  keyPairEnabled = false
): GETWarehouseDefaultsRequest => {
  const shouldUseAuth =
    keyPairEnabled && formValues.type === Warehouse.Snowflake
  const authObject = {
    type: formValues.credentialsType.id,
    ...getCredentials(formValues)
  }
  const authentication = shouldUseAuth ? authObject : undefined

  const newSecretReferenceId = shouldUseAuth
    ? undefined
    : (formValues.tempSecretReferenceId ?? formValues.secretReferenceId)

  return {
    // Using temporary first so that if the page is refreshed before the credentials have been tested
    // It will fetch the defaults based on the old values
    // This way the user can revert the changes to the initial ones and everything will remain as expected
    account: formValues.tempAccount?.trim() || formValues.account.trim(),
    agentId:
      formValues.agentsSecretsManagement === AgentsSecretsHost.MatillionHosted
        ? formValues.matillionHostedAgentId
        : formValues.etlAgent?.id,
    type: formValues.type,
    secretReferenceId: newSecretReferenceId,
    ...(formValues.awsSecretReferenceId && {
      awsPlatformProviderSecretReferenceId: formValues.awsSecretReferenceId
    }),
    username: formValues.tempUsername?.trim() || formValues.username.trim(),
    ...(formValues.role && { role: formValues.role }),
    ...(formValues.database && { database: formValues.database }),
    ...(formValues.defaultRole && {
      role: formValues.defaultRole?.id || undefined
    }),
    ...(formValues.defaultDatabase && {
      database: formValues.defaultDatabase?.id || undefined
    }),
    ...(formValues.catalog && { catalog: formValues.catalog.id || undefined }),
    ...(formValues.compute && {
      clusterId: formValues.compute.clusterId || undefined,
      clusterName: formValues.compute.clusterName || undefined,
      clusterType: formValues.compute.clusterType || undefined,
      state: formValues.compute.state || undefined
    }),
    ssl: formValues.ssl,
    port: formValues.port,
    authentication
  }
}
