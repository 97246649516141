import { useMutation, type UseMutationOptions } from 'react-query'

import { type AxiosError } from 'axios'

import { postBranch } from 'api/clients'
import type { POSTBranchRequest, POSTBranchResponse } from 'api/types'

import { QueryKey } from 'constants/endpoint'

import { useClient } from './useClient'

type TData = POSTBranchResponse
type TError = AxiosError<unknown>
interface TVariables {
  values: POSTBranchRequest
}

type Options = UseMutationOptions<POSTBranchResponse, AxiosError, TVariables>

export const usePostBranch = (projectId: string, options?: Options) => {
  const client = useClient({ showErrorDetailMessage: true })

  return useMutation<TData, TError, TVariables>(
    [QueryKey.CREATE_BRANCH, projectId],
    async (data: { values: POSTBranchRequest }) => {
      const { values } = data
      return postBranch(
        values,
        {
          projectId
        },
        client
      )
    },
    options
  )
}
