import { useEffect, useState, type FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'

import classNames from 'classnames'

import {
  Button,
  DataGrid,
  LoadingSpinner,
  Toaster,
  Typography,
  type SortState
} from '@matillion/component-library'

import {
  useCheckPermissions,
  useGetEnvironmentAgents,
  useGetEnvironments,
  useGetProject,
  useNetworkErrorToast
} from 'api/hooks'
import { type GETEnvironmentsResponseParams } from 'api/types'

import AgentIcon from 'components/AgentIcon/AgentIcon'
import { Header, HeaderTitle, HeaderWithCTA } from 'components/Header'

import { QueryKey } from 'constants/endpoint'
import { AppRoutes } from 'constants/route'

import { useFlags } from 'hooks/flags'

import {
  EditEnvironmentMembersForm,
  type EnvContext
} from 'modules/Projects/EnvironmentsListing/components/editUsers/EditEnvironmentMembersForm'

import { AgentsSecretsHost, PermissionsType } from 'types'
import { ResourceType } from 'types/ResourceTypes'

import { ColumnKeys, type SortedEnvironmentsTypes, type SortTypes } from '.'
import ActionCell from './ActionCell'
import NoCreatePermission from './components/NoCreatePermission'
import NoData from './components/NoData'
import NoReadPermission from './components/NoReadPermission'
import classes from './EnvironmentsListing.module.scss'
import { environmentsSort } from './environmentsListing.util'

const EnvironmentsListing: FunctionComponent = () => {
  const [sortState, setSortState] = useState<Partial<SortState<SortTypes>>>({
    name: 'ASC'
  })
  const { projectId } = useParams()
  const navigate = useNavigate()
  const makeErrorToast = useNetworkErrorToast()
  const { clearToasts } = Toaster.useToaster()
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const { enableAgentIconsAndText } = useFlags()
  const [editUsersEnvContext, setEditUsersEnvContext] = useState<EnvContext>()

  const {
    data: environmentsData = [],
    error: environmentsError,
    isError: isEnvironmentsError,
    isLoading: isEnvironmentsLoading
  } = useGetEnvironments(projectId!)

  const { data: projectData } = useGetProject(projectId!)
  const { data: listOfAgents = [] } = useGetEnvironmentAgents()

  const { data: createPermission, isLoading: isCreatePermissionLoading } =
    useCheckPermissions({
      resourceType: ResourceType.Project,
      resourceId: projectId!,
      permission: PermissionsType.CreateEnvironments
    })
  const { data: viewPermission, isLoading: isViewPermissionLoading } =
    useCheckPermissions({
      resourceType: ResourceType.Project,
      resourceId: projectId!,
      permission: PermissionsType.ViewEnvironments
    })

  const isCHA =
    projectData?.agentAndSecretHostLocation === AgentsSecretsHost.CustomerHosted

  if (sortState) {
    const environmentsSortFunction = environmentsSort(sortState)
    environmentsData.sort(environmentsSortFunction)
  }

  const goToEditEnvironment = (envId: string) => {
    clearToasts()
    navigate(AppRoutes.getEnvironmentEdit(projectId!, envId))
  }

  const goToAssociateCredentials = (envId: string) => {
    clearToasts()
    navigate(AppRoutes.getAssociateCredentialsToEnvironment(projectId!, envId))
  }

  useEffect(() => {
    if (isEnvironmentsError) {
      makeErrorToast({ message: environmentsError.message })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [environmentsError, isEnvironmentsError])

  useEffect(() => {
    return () => {
      queryClient.removeQueries([
        QueryKey.USER_PERMISSIONS,
        PermissionsType.CreateEnvironments
      ])
      queryClient.removeQueries([
        QueryKey.USER_PERMISSIONS,
        PermissionsType.EditEnvironments
      ])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getContent = () => {
    return !environmentsData.length ? (
      <div
        className={classNames(classes.EnvironmentsListing__EmptyInfo)}
        data-testid="empty-info-message"
      >
        {isEnvironmentsError ? (
          <Typography format="bcm">{t('environmentsListing.error')}</Typography>
        ) : (
          <>{!createPermission ? <NoCreatePermission /> : <NoData />}</>
        )}
      </div>
    ) : (
      <DataGrid
        className={classNames(classes.EnvironmentsListing__Grid)}
        rowClassName={classNames(classes.EnvironmentsListing__GridRow)}
        data-testid="environments-data-grid"
        aria-label="Environments Data Grid"
        defaultSort={{ name: 'ASC' }}
        columns={[
          {
            key: ColumnKeys.environmentName,
            title: t('environmentsListing.column.col1'),
            as: Typography,
            className: classNames(classes.EnvironmentsListing__GridCell),
            mapValues: (row) => ({
              children: row.name
            }),
            sortable: true
          },
          {
            key: ColumnKeys.defaultAgent,
            title: t('environmentsListing.column.col2'),
            as: Typography,
            className: classNames(classes.EnvironmentsListing__GridCell),
            mapValues: (row) => {
              const defaultAgent = listOfAgents.find(
                (agent) => agent.agentId === row.agentId
              )
              if (defaultAgent && isCHA && Boolean(enableAgentIconsAndText)) {
                return {
                  children: (
                    <AgentIcon
                      cloudProviderId={defaultAgent.cloudProviderId}
                      runtimePlatformId={defaultAgent.cloudProviderId}
                      text={row.agentName}
                    />
                  )
                }
              }
              return {
                children: row.agentName
              }
            },
            sortable: true
          },
          {
            key: ColumnKeys.cdwAccountName,
            title: t('environmentsListing.column.col3'),
            as: Typography,
            className: classNames(classes.EnvironmentsListing__GridCell),
            mapValues: (row) => {
              // TO-DO: May need to revisit this in the future if the BE returns the 'account name' separately and not only within the full 'warehouse connection' URL
              const accountName = row.warehouseConnection.url
                .replace(/^http(s?):\/\//, '')
                .split('.')
                .shift()
              return {
                children: accountName
              }
            },
            sortable: true
          },
          {
            key: ColumnKeys.action,
            title: t('environmentsListing.column.col4'),
            as: ActionCell,
            className: classNames(classes.EnvironmentsListing__ActionCell),
            mapValues: (row: GETEnvironmentsResponseParams) => ({
              onEditClick: () => {
                goToEditEnvironment(row.id)
              },
              onEditUsersClick: () => {
                setEditUsersEnvContext(row)
              },
              onAssociateCredsClick: () => {
                goToAssociateCredentials(row.id)
              },
              item: row
            }),
            sortable: false
          }
        ]}
        rows={environmentsData as SortedEnvironmentsTypes[]}
        onSort={setSortState}
      />
    )
  }

  return (
    <>
      <Header fullWidth centerAlign={false}>
        <HeaderWithCTA>
          <HeaderTitle
            data-testid="environments-title"
            centerAlign={false}
            headerElement="h2"
            format="tm"
            withBottomSpace={false}
          >
            {t('environmentsListing.title')}
          </HeaderTitle>
          {createPermission && (
            <Button
              text={t('environmentsListing.buttonText')}
              onClick={() => {
                navigate(AppRoutes.getEnvironmentAdd(projectId!))
              }}
              data-testid="add-environment-button"
              alt="positive"
            />
          )}
        </HeaderWithCTA>
      </Header>
      {isEnvironmentsLoading ||
      isViewPermissionLoading ||
      (isCreatePermissionLoading && !environmentsData.length) ? (
        <LoadingSpinner />
      ) : (
        <>{!viewPermission ? <NoReadPermission /> : getContent()}</>
      )}
      {editUsersEnvContext && (
        <EditEnvironmentMembersForm
          setEnvContext={setEditUsersEnvContext}
          envContext={editUsersEnvContext}
          projectId={projectId!}
        />
      )}
    </>
  )
}

export default EnvironmentsListing
