import { useCallback, useEffect, useState, type FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'

import { type AxiosError } from 'axios'
import classNames from 'classnames'
import { type FormikHelpers } from 'formik'

import { Toaster } from '@matillion/component-library'
import { useUser } from '@matillion/hub-client'

import {
  useCompleteOAuthV2,
  useCreateOAuthV2,
  useGetProject,
  usePostSecretValueV2
} from 'api/hooks'
import { createOAuthMutationDataV2 } from 'api/mutation/createOAuthMutationV2'
import type {
  ErrorResponse,
  POSTSecretValueV2Request,
  POSTSetupOAuthV2Response
} from 'api/types'

import Form from 'components/Form'
import formClasses from 'components/Form/Form.module.scss'
import { GoogleButton } from 'components/GoogleButton'

import { QueryKey } from 'constants/endpoint'
import { OAUTH_STORAGE_ITEM } from 'constants/persistance'
import { AppRoutes, PROJECT_DETAILS_OAUTH } from 'constants/route'
import { StatusCodes } from 'constants/statusCodes'

import { useFlags } from 'hooks/flags'

import CreateOAuth from 'modules/FormsContent/CreateOAuth'
import { useCreateOAuthContext } from 'modules/Projects/CreateOAuth/CreateOAuthContext'

import { SecretReferenceTypes, type JSONValue } from 'types'

import {
  initialValues,
  OAuthProvider,
  splitValuesSaveToSecretAndSendToAuthServer,
  type CreateOAuthFormikValueTypes
} from '.'

const CreateOAuthForm: FunctionComponent = () => {
  const { clearToasts, makeToast } = Toaster.useToaster()
  const [authName, setAuthName] = useState<string>('')
  const [agentId, setAgentId] = useState<string>('')
  const [secretLocationId, setSecretLocationId] = useState<string>('')
  const [additionalOAuthCompleteSecrets, setAdditionalOAuthCompleteSecrets] =
    useState<{
      [key: string]: JSONValue
    }>()
  const [currentAttemptId, setCurrentAttemptId] = useState<string>()
  const [isWaiting, setIsWaiting] = useState<boolean>(false)
  const [openModal, setOpenModal] = useState<boolean>(false)
  const navigate = useNavigate()
  const { projectId } = useParams()
  const queryClient = useQueryClient()
  const { t } = useTranslation()
  const { organisation } = useUser()
  const { validationSchema, selectedOAuth } = useCreateOAuthContext()
  const { data: projectData } = useGetProject(projectId!)
  const { mutateAsync: createSecretValue } = usePostSecretValueV2({ projectId })
  const { mutateAsync: createOAuth } = useCreateOAuthV2(projectId!)
  const { mutateAsync: wrapupOAuth } = useCompleteOAuthV2(projectId!)
  const [showGoogleButton, setShowGoogleButton] = useState(false)
  const [googleButtonDisabled, setGoogleButtonDisabled] = useState(true)
  const oauthWithSpecialButtons = [OAuthProvider.Google]
  const { enableGmailOauthForGoogleProvider } = useFlags()
  const oauthAuthCodeSecretTypes = [
    SecretReferenceTypes.OAUTH_USER_TOKENS,
    SecretReferenceTypes.OAUTH_AUTHORIZATION_CODE
  ]

  const navigateToOAuthConnections = useCallback(() => {
    queryClient.invalidateQueries([
      QueryKey.SECRET_REFERENCES,
      projectId!,
      [
        SecretReferenceTypes.OAUTH_USER_TOKENS,
        SecretReferenceTypes.OAUTH_AUTHORIZATION_CODE,
        SecretReferenceTypes.OAUTH_CLIENT_CRED,
        SecretReferenceTypes.OAUTH_CLIENT_CREDENTIALS,
        SecretReferenceTypes.COMPOSITE_TOKEN
      ]
    ])
    window.sessionStorage.removeItem(OAUTH_STORAGE_ITEM)
    navigate(AppRoutes.getProjectDetails(projectId!, PROJECT_DETAILS_OAUTH))
  }, [navigate, projectId, queryClient])

  const submitForm = (
    values: CreateOAuthFormikValueTypes,
    { setSubmitting }: FormikHelpers<CreateOAuthFormikValueTypes>
  ) => {
    clearToasts()
    const { provider, name, environmentAgent, authType, ...jsonBlob } = values
    const authServerParamsAndSecretParams =
      splitValuesSaveToSecretAndSendToAuthServer(
        selectedOAuth!,
        authType.id,
        jsonBlob
      )
    if (
      oauthAuthCodeSecretTypes.includes(
        values.authType?.id as SecretReferenceTypes
      )
    ) {
      setAdditionalOAuthCompleteSecrets(
        authServerParamsAndSecretParams.saveToSecret
      )
      submitFormOauthToken(
        values,
        authServerParamsAndSecretParams.sendToAuthServer,
        setSubmitting
      )
    } else {
      const secretValueV2Request: POSTSecretValueV2Request = {
        name: name,
        type: authType.id as SecretReferenceTypes,
        secretName: name,
        secretValue: {
          ...authServerParamsAndSecretParams.saveToSecret,
          provider: provider.id
        },
        agentId: environmentAgent.id,
        locationId:
          values.secretLocationId ?? projectData!.secretLocationIds[0],
        metadata: {
          provider: provider.id
        }
      }
      submitFormClientCred(secretValueV2Request, setSubmitting)
    }
  }

  const submitFormClientCred = (
    values: POSTSecretValueV2Request,
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    setSubmitting(true)

    createSecretValue({ values })
      .then((_response) => {
        makeToast({
          title: t('secrets.createOAuth.wrapup.responseMessage.success', {
            oauthName: values.name
          }),
          message: '',
          type: 'success'
        })
        navigateToOAuthConnections()
      })
      .finally(() => {
        setSubmitting(false)
      })
  }

  const submitFormOauthToken = (
    values: CreateOAuthFormikValueTypes,
    providerParameters: { [key: string]: string },
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    clearToasts()
    setSubmitting(true)
    setIsWaiting(true)

    const urlWithoutSubdomain =
      `${window.location.origin}/oauth-redirect`.replace(
        `${organisation.subdomain}.`,
        ''
      )

    createOAuth({
      values: createOAuthMutationDataV2(
        {
          ...values,
          providerParameters,
          callbackUrl: urlWithoutSubdomain,
          accountId: organisation.subdomain
        },
        Boolean(enableGmailOauthForGoogleProvider)
      )
    })
      .then(async (res: POSTSetupOAuthV2Response) => {
        const redirectUrl = res.redirectUrl
        const redirectUrlObject = new URL(redirectUrl)

        const { state: stateString }: Record<string, string> = [
          ...redirectUrlObject.searchParams.entries()
        ].reduce(
          (previous, [key, value]) => ({
            ...previous,
            [key]: value
          }),
          {}
        )

        const stateId = stateString.split('_')[0]
        setCurrentAttemptId(stateId)
        setAuthName(values.name)
        setAgentId(values.environmentAgent.id)
        setSecretLocationId(
          values.secretLocationId ?? projectData!.secretLocationIds[0]
        )
        setOpenModal(true)
        window.open(res.redirectUrl)

        await queryClient.invalidateQueries([QueryKey.CREATE_OAUTH, projectId])
      })
      .catch((err: AxiosError) => {
        const error = err?.response?.data as ErrorResponse
        const errorMessage = error?.detail
          ? error.detail
          : t('secrets.createOAuth.responseMessage.conflictName')
        setIsWaiting(false)
        makeToast({
          title:
            error?.status === StatusCodes.CONFLICT
              ? errorMessage
              : t('secrets.createOAuth.responseMessage.error'),
          message: '',
          type: 'error'
        })
      })
      .finally(() => {
        setSubmitting(false)
      })
  }

  const handleMessage = useCallback(
    (event: MessageEvent<Record<string, string>>) => {
      if (
        event.data?.type ===
        'matillion.project-explorer-frontend.oauth.redirect'
      ) {
        const { attemptId, code, state } = event.data

        if (attemptId !== currentAttemptId || !isWaiting) {
          return undefined
        }

        setOpenModal(false)
        wrapupOAuth({
          values: {
            code,
            state,
            name: authName,
            agentId: agentId,
            secretJson: additionalOAuthCompleteSecrets,
            secretLocationId: secretLocationId
          }
        })
          .then(async () => {
            await queryClient.invalidateQueries([QueryKey.COMPLETE_OAUTH])
            makeToast({
              title: t('secrets.createOAuth.wrapup.responseMessage.success', {
                oauthName: authName
              }),
              message: '',
              type: 'success'
            })
            navigateToOAuthConnections()
          })
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          .catch(() => {})
          .finally(() => {
            setIsWaiting(false)
          })
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      authName,
      currentAttemptId,
      isWaiting,
      wrapupOAuth,
      agentId,
      additionalOAuthCompleteSecrets
    ]
  )

  useEffect(() => {
    window.addEventListener('message', handleMessage)

    return () => {
      window.removeEventListener('message', handleMessage)
    }
  }, [handleMessage])

  const handleUserAbandonOauth = () => {
    setOpenModal(false)
    navigateToOAuthConnections()
  }

  return (
    <Form<CreateOAuthFormikValueTypes>
      formikValues={{
        onSubmit: submitForm,
        initialValues,
        validateOnMount: true,
        enableReinitialize: true,
        validationSchema,
        initialTouched: false,
        innerRef: (f) => {
          // Known bug with validation through the form's context hence checking here for the google button
          // https://stackoverflow.com/a/72999931
          if (f?.values) {
            setShowGoogleButton(
              oauthWithSpecialButtons.includes(
                f?.values.provider?.id as OAuthProvider
              )
            )
            setGoogleButtonDisabled(
              !validationSchema?.isValidSync(f?.values) || f?.isSubmitting
            )
          }
        }
      }}
      translationPrefix="secrets.createOAuth"
      stepInfo={{
        isLastStep: true,
        stepIndex: 0
      }}
      persistingStorageId={OAUTH_STORAGE_ITEM}
      removeSubmitButtonOnRoutes={
        showGoogleButton
          ? [AppRoutes.getCreateOAuthConnection(projectId!)]
          : undefined
      }
    >
      <>
        <CreateOAuth
          onFormCancel={navigateToOAuthConnections}
          onAwaitingCancel={handleUserAbandonOauth}
          showAwaitingModal={openModal}
        />
        {showGoogleButton && (
          <div
            className={classNames(
              formClasses.Form__InnerWrap,
              formClasses.Form__Buttons
            )}
          >
            <div className={formClasses.Form__Button}>
              <GoogleButton
                type="submit"
                disabled={googleButtonDisabled}
                name="authorize"
              />
            </div>
          </div>
        )}
      </>
    </Form>
  )
}

export default CreateOAuthForm
