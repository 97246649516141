import { type FC } from 'react'
import { useTranslation } from 'react-i18next'

import classNames from 'classnames'
import { isEmpty } from 'lodash'

import { Icon, Input } from '@matillion/component-library'

import classes from './SearchBar.module.scss'

export interface SearchBarProps {
  value: string
  onChange: (value: string) => void
  wrapperClassName?: string
}

export const SearchBar: FC<SearchBarProps> = ({
  value,
  onChange,
  wrapperClassName,
  ...otherProps
}) => {
  const { t } = useTranslation()
  return (
    <div className={classNames(classes.SearchBar__Wrapper, wrapperClassName)}>
      <Input
        data-testid="search-bar-input"
        value={value}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          onChange(e.target.value)
        }}
        autoFocus
        className={classes.SearchBar__Input}
        placeholder={t('searchBar.placeholderText')}
        aria-label={t('searchBar.ariaLabel')}
        iconBefore={{
          icon: <Icon.Search />
        }}
        iconAfter={
          !isEmpty(value) && {
            icon: (
              <button
                data-testid="search-bar-clear-button"
                aria-label={t('searchBar.clearButtonAriaLabel')}
                type="button"
                className={classes.SearchBar__ClearButton}
                onClick={() => {
                  onChange('')
                }}
              >
                <Icon.Clear />
              </button>
            ),
            clickable: true
          }
        }
        {...otherProps}
      />
    </div>
  )
}
