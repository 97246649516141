import { type POSTSecretLocationRequest } from 'api/createProjectForm/types'

import { AgentsSecretsHost } from 'types'

interface FormValuesTypes {
  projectName: string
  agentsSecretsManagement: AgentsSecretsHost
  etlAgent: {
    id: string
    name: string
  }
  matillionHostedAgentId: string
  vaultName?: string
}

export const createPostSecretLocationMutationData = (
  formValues: FormValuesTypes
): POSTSecretLocationRequest => {
  if (
    formValues.agentsSecretsManagement === AgentsSecretsHost.MatillionHosted
  ) {
    return {
      projectName: formValues.projectName.trim(),
      agentId: formValues.matillionHostedAgentId,
      type: 'MATILLION_CUSTOMER_VAULT'
    }
  } else if (
    formValues.agentsSecretsManagement === AgentsSecretsHost.AzureHosted
  ) {
    return {
      projectName: formValues.projectName.trim(),
      agentId: formValues.etlAgent.id,
      type: 'AZURE_SECRET_MANAGER',
      vaultName: formValues.vaultName
    }
  }

  return {
    projectName: formValues.projectName.trim(),
    agentId: formValues.etlAgent.id
  }
}
