import { useEffect, useState, type ChangeEvent, type FC } from 'react'
import { useTranslation } from 'react-i18next'

import { useFormikContext } from 'formik'

import {
  Typography,
  type DataGridColumnProps,
  type DataGridRow
} from '@matillion/component-library'

import {
  useFormMetadata,
  type FormValues
} from 'modules/Projects/CreateStreamingPipeline/FormContent'

import { TableSelector } from './TableSelector'
import classes from './TableSelector.module.scss'
import { TableSelectorCheckbox } from './TableSelectorCheckbox'

export interface MultiTableSelectorProps {
  onClose: () => void
  schema: string
}

interface TableGridRow extends DataGridRow {
  id: string
}

export const MultiTableSelector: FC<MultiTableSelectorProps> = ({
  onClose,
  schema
}) => {
  const { t } = useTranslation()
  const { values, setFieldValue } = useFormikContext<FormValues>()
  const { metadata } = useFormMetadata()
  const [isAllSelected, setIsAllSelected] = useState<boolean>(false)

  const availableTables = metadata.source.schemas[schema].tables ?? []
  const allTablesInSchema = availableTables.map((availableTable) => ({
    schema,
    table: availableTable
  }))
  const selectedTables = values.source.tables ?? []
  const selectedTablesInSchema = selectedTables.filter(
    (table) => table.schema === schema
  )

  useEffect(() => {
    checkIfAllSelected()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.source.tables, metadata.source.schemas[schema].tables])

  const selectAllTables = () => {
    const filteredSelectedTables = selectedTables.filter(
      (selectedTable) => selectedTable.schema !== schema
    )
    setFieldValue('source.tables', [
      ...filteredSelectedTables,
      ...allTablesInSchema
    ])
    setIsAllSelected(true)
  }

  const deselectAllTables = () => {
    const filteredSelectedTables = selectedTables.filter(
      (selectedTable) => selectedTable.schema !== schema
    )
    setFieldValue('source.tables', filteredSelectedTables)
    setIsAllSelected(false)
  }

  const checkIfAllSelected = () => {
    if (selectedTablesInSchema.length === allTablesInSchema.length) {
      setIsAllSelected(true)
    } else {
      setIsAllSelected(false)
    }
  }

  const columns: Array<DataGridColumnProps<TableGridRow>> = [
    {
      key: 'selected',
      sortable: false,
      className: classes.TableSelector__CheckboxColumn,
      allSelectable: true,
      isAllSelected,
      onAllSelectedChange: (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
          selectAllTables()
        } else {
          deselectAllTables()
        }
      },
      title: '',
      as: TableSelectorCheckbox,
      mapValues: (value) => ({
        schema,
        tableName: value.id,
        selectedTables,
        selectedTablesInSchema
      })
    },
    {
      key: 'id',
      'data-testid': 'table-selector-table-name',
      headerClassName: classes.TableSelector__ColumnHeader,
      sortable: true,
      title: t(
        'createStreamingPipeline.fields.pipelineConfig.tableSelector.nameColumn'
      ),
      as: Typography,
      mapValues: (value) => ({
        type: 'bcs',
        children: value.id
      })
    }
  ]

  const rows: TableGridRow[] = availableTables.map((tableName) => ({
    id: tableName
  }))

  return (
    <TableSelector
      schema={schema}
      title={t(
        'createStreamingPipeline.fields.pipelineConfig.tableSelector.title'
      )}
      columns={columns}
      rows={rows}
      backButtonText={t(
        'createStreamingPipeline.fields.pipelineConfig.tableSelector.backButton'
      )}
      selectButtonText={t(
        'createStreamingPipeline.fields.pipelineConfig.tableSelector.selectButton',
        { selectedCount: selectedTablesInSchema.length }
      )}
      onClose={onClose}
      onPrevious={onClose}
    />
  )
}
