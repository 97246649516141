import {
  useCallback,
  useEffect,
  useMemo,
  type ChangeEvent,
  type FunctionComponent,
  type SetStateAction
} from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import classNames from 'classnames'
import { useFormikContext } from 'formik'

import {
  AutoComplete,
  Field,
  type AutoCompleteItemId
} from '@matillion/component-library'

import { useGetOAuthSchema } from 'api/hooks'
import type { OAuthParameter, OAuthSchema, OAuthType } from 'api/types'

import classes from 'components/Form/Form.module.scss'

import { useFlags } from 'hooks/flags'

import { getProviderList } from 'modules/FormsContent/CreateOAuth/createOAuthHelper'
import { renderFormikError } from 'modules/utils'

import { type OAuthFormI } from 'types/FormTypes'

export interface ProviderProps {
  setSelectedOAuth: React.Dispatch<SetStateAction<OAuthSchema | undefined>>
  setOAuthParameter: React.Dispatch<
    SetStateAction<OAuthParameter[] | undefined>
  >
  oAuthParameter: OAuthParameter[] | undefined
  setOAuthType: React.Dispatch<SetStateAction<OAuthType | undefined>>
}

export const Provider: FunctionComponent<ProviderProps> = ({
  setSelectedOAuth,
  setOAuthParameter,
  oAuthParameter,
  setOAuthType
}) => {
  const { projectId } = useParams()
  const {
    errors,
    handleBlur,
    handleChange,
    touched,
    values,
    setFieldTouched,
    setFieldValue
  } = useFormikContext<OAuthFormI>()
  const { i18n, t } = useTranslation()
  const { oauthAllowedProviders } = useFlags()
  const isEnabled = !!values.environmentAgent?.id

  const {
    data: oauthSchema = [],
    isError,
    isLoading
  } = useGetOAuthSchema(
    {
      projectId: projectId!,
      agentId: values.environmentAgent?.id
    },
    { enabled: isEnabled }
  )

  const formikError = renderFormikError(
    errors.provider,
    Boolean(touched.provider)
  )

  const getErrorMessage = useMemo(() => {
    if (isError) {
      return t('secrets.createOAuth.fields.provider.error.loadingError')
    } else if (formikError) {
      return t(formikError)
    } else if (!oauthSchema.length && isEnabled) {
      return t('secrets.createOAuth.fields.provider.error.noItemsFound')
    }
    return ''
  }, [formikError, t, isEnabled, isError, oauthSchema])

  const listOfProviders = getProviderList(
    oauthSchema,
    oauthAllowedProviders as unknown as string[],
    t,
    i18n
  )

  const updateOAuthSchemaContext = useCallback(
    (providerSelected: string | number | null) => {
      const oAuth = oauthSchema.find((x) => x.provider === providerSelected)
      setSelectedOAuth(oAuth)
    },
    [oauthSchema, setSelectedOAuth]
  )

  const handleOnProviderChange = useCallback(
    (value: AutoCompleteItemId) => {
      setFieldTouched('authType', false)
      setFieldValue('authType', { id: '', name: '' })
      oAuthParameter?.forEach((x) => {
        setFieldValue(x.id, '')
      })
      setOAuthParameter(undefined)
      setOAuthType(undefined)
      if (!value) {
        setSelectedOAuth(undefined)
        return
      }
      updateOAuthSchemaContext(value.id)
    },
    [
      oAuthParameter,
      setFieldTouched,
      setFieldValue,
      setOAuthParameter,
      setSelectedOAuth,
      setOAuthType,
      updateOAuthSchemaContext
    ]
  )

  useEffect(() => {
    updateOAuthSchemaContext(values.provider?.id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateOAuthSchemaContext])

  return (
    <div data-testid="create-oauth-provider">
      <Field
        inputComponent={AutoComplete}
        availableItems={listOfProviders}
        loading={isLoading}
        title={t('secrets.createOAuth.fields.provider.title')}
        name="provider"
        data-testid="create-oauth-provider-input"
        value={values.provider}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          const { value } = e.target
          handleOnProviderChange(value as unknown as AutoCompleteItemId)
          handleChange(e)
        }}
        placeholder={
          isError
            ? t('secrets.createOAuth.fields.provider.error.placeholderText')
            : t('secrets.createOAuth.fields.provider.placeholderText')
        }
        onBlur={handleBlur}
        className={classNames(classes.Form__SpacingStyles)}
        supportText={t('secrets.createOAuth.fields.provider.supportText')}
        errorText={isLoading ? undefined : getErrorMessage}
        hasError={
          Boolean(errors?.provider?.id) &&
          Boolean(touched.provider) &&
          !isLoading
        }
        disabled={isError || !isEnabled || !oauthSchema.length}
        required
      />
    </div>
  )
}
