import { type FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'

import { Typography } from '@matillion/component-library'

import classes from 'modules/Projects/StreamingPipelineDashboard/Header/components/StatusEnhancementText/StatusEnhancementText.module.scss'

import {
  StreamingPipelineStatus,
  type StreamingPipelineMetrics
} from 'types/Streaming'

import formatSeconds from 'utils/formatSeconds/formatSeconds'

interface StatusEnhancementTextProps {
  status: StreamingPipelineStatus
  metrics?: StreamingPipelineMetrics
}

const StatusEnhancementText: FunctionComponent<StatusEnhancementTextProps> = ({
  status,
  metrics
}) => {
  const { t } = useTranslation()

  if (
    status !== StreamingPipelineStatus.SNAPSHOTTING ||
    !metrics?.snapshot?.duration
  ) {
    return null
  }

  return (
    <Typography
      format="bcm"
      data-testid="status-enhancer"
      className={classes.StatusEnhancementText__StatusMetric}
    >
      {`${t('streamingPipelineDashboard.statusEnhancement.snapshotting')}: ${formatSeconds(metrics.snapshot.duration)}`}
    </Typography>
  )
}

export default StatusEnhancementText
