import type { FunctionComponent } from 'react'

import {
  CronExpression,
  StartDateTime,
  Timezone
} from 'modules/Projects/CreateSchedule/FormContent/Fields/Schedule/Common'

export const AdvancedSchedule: FunctionComponent = () => {
  return (
    <>
      <Timezone />
      <StartDateTime />
      <CronExpression />
    </>
  )
}

export default AdvancedSchedule
