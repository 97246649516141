import { useCallback, useEffect, useMemo, type FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'

import { useFormikContext } from 'formik'

import { AutoComplete, Field } from '@matillion/component-library'

import { WarehouseLookupTypes } from 'api/createProjectForm/types/WarehouseDefaultsRequest'
import { useMutateWarehouseDefaults } from 'api/hooks'
import { createWarehouseDefaultsMutation } from 'api/mutation'
import { type ErrorResponse } from 'api/types'

import classes from 'components/Form/Form.module.scss'

import { StatusCodes } from 'constants/statusCodes'

import { useFlags } from 'hooks/flags'

import { type CreateProjectFormikValueTypes } from 'modules/Projects/CreateProject/CreateProjectForm'
import { renderFormikError } from 'modules/utils'

export interface DatabaseProps {
  isFieldEnabled?: boolean
  listenerFields?: Array<keyof CreateProjectFormikValueTypes>
}

export const DefaultDatabase: FunctionComponent<DatabaseProps> = ({
  isFieldEnabled = true,
  listenerFields = []
}) => {
  const {
    errors,
    handleBlur,
    handleChange,
    touched,
    values,
    setFieldValue,
    initialValues,
    setFieldTouched
  } = useFormikContext<CreateProjectFormikValueTypes>()
  const { t } = useTranslation()
  const { enableKeyPairAuthentication } = useFlags()

  const {
    data: databaseDefaultData = [],
    mutate: mutateWarehouseDefaults,
    isError,
    error,
    isLoading
  } = useMutateWarehouseDefaults(WarehouseLookupTypes.DATABASE)

  const projectType = values.type || 'common'

  const defaultDatabaseError = renderFormikError(
    errors.defaultDatabase,
    Boolean(touched.defaultDatabase)
  )

  const getErrorMessage = useMemo(() => {
    if (!isFieldEnabled) return null
    if (isError) {
      const errorDetail = (error?.response?.data as ErrorResponse)?.detail
      const errorResponseStatus = error?.response?.status
      if (
        errorResponseStatus === StatusCodes.BAD_REQUEST &&
        errorDetail !== undefined
      ) {
        return errorDetail
      }
      return t('fields.defaultDatabase.error.loadingError')
    }
    if (!databaseDefaultData.length) {
      return t('fields.defaultDatabase.error.noItemsFound')
    }
    if (defaultDatabaseError) return t(defaultDatabaseError)
    return null
  }, [
    databaseDefaultData.length,
    defaultDatabaseError,
    isFieldEnabled,
    isError,
    t,
    error
  ])

  const listenerFieldValues = listenerFields.map((field) => values[field])

  const resetDatabase = useCallback(() => {
    setFieldTouched('defaultDatabase', false)
    setFieldValue('defaultDatabase', { id: '', name: '' })
  }, [setFieldTouched, setFieldValue])

  useEffect(() => {
    if (!isFieldEnabled) {
      resetDatabase()
    }

    listenerFields.forEach((field) => {
      const listenerFieldInitialValue = initialValues[field]
      const listenerFieldCurrentValue = values[field]
      if (
        JSON.stringify(listenerFieldCurrentValue) !==
        JSON.stringify(listenerFieldInitialValue)
      ) {
        resetDatabase()
      }
    })
    if (isFieldEnabled) {
      mutateWarehouseDefaults({
        values: createWarehouseDefaultsMutation(
          values,
          !!enableKeyPairAuthentication
        )
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // eslint-disable-next-line react-hooks/exhaustive-deps
    ...listenerFieldValues,
    isFieldEnabled,
    mutateWarehouseDefaults,
    resetDatabase,
    setFieldTouched,
    setFieldValue
  ])

  return (
    <div data-testid={`${projectType}-default-default-database`}>
      <Field
        inputComponent={AutoComplete}
        availableItems={databaseDefaultData.map((item) => ({
          id: item,
          name: item
        }))}
        loading={isLoading}
        title={t('fields.defaultDatabase.title')}
        name="defaultDatabase"
        data-testid={`${projectType}-default-default-database-input`}
        value={values.defaultDatabase}
        placeholder={
          isError
            ? t('fields.defaultDatabase.error.placeholderText')
            : t('fields.defaultDatabase.placeholderText')
        }
        onChange={handleChange}
        onBlur={handleBlur}
        className={classes.Form__SpacingStyles}
        supportText={t('fields.defaultDatabase.supportText')}
        errorText={isLoading || !isFieldEnabled ? undefined : getErrorMessage}
        hasError={
          Boolean(errors?.defaultDatabase?.id) &&
          Boolean(touched.defaultDatabase) &&
          !isLoading &&
          isFieldEnabled
        }
        required
        disabled={isError || !isFieldEnabled}
      />
    </div>
  )
}
