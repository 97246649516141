import { createContext, useContext } from 'react'

import { noop } from 'lodash'

import { type GenericSchemaType } from 'types'

interface SecretDefinitionFormContextType {
  validationSchema?: GenericSchemaType
  setValidationSchema: (schema: GenericSchemaType) => void
}

export const SecretDefinitionFormContext =
  createContext<SecretDefinitionFormContextType>({
    validationSchema: undefined,
    setValidationSchema: noop
  })

export const useSecretDefinitionFormContext = (
  context = SecretDefinitionFormContext
) => useContext(context)
