import { type TFunction } from 'react-i18next'

import { type FieldMetaProps } from 'formik'
import { mixed, object, string, type InferType } from 'yup'

import { type AutoCompleteItemId } from '@matillion/component-library'

import { type Vaults } from 'api/createProjectForm/types'
import { type GETSecretReferencesResponse } from 'api/types'

import { alphaNumericMildCharsWithLeadEndSpacesRegEx } from 'modules/Projects/CreateProject/CreateProjectForm'

import { SecretReferenceTypes } from 'types'
import { AgentCloudProvider } from 'types/AgentCloudProvider'

export enum FormFields {
  name = 'name',
  description = 'description',
  type = 'type',
  agent = 'agent',
  secretName = 'secretName',
  secretKey = 'secretKey',
  secretValue = 'secretValue',
  secretValueToggle = 'secretValueToggle',
  locationId = 'locationId'
}

export const formSchema = (
  availableSecretReferences: GETSecretReferencesResponse,
  isMHA: boolean,
  agentCloudProvider?: AgentCloudProvider
) =>
  object({
    [FormFields.name]: string()
      .required('secrets.definitionForm.fields.name.error.required')
      .test(
        'name',
        'secrets.definitionForm.fields.name.error.notUnique',
        (value) => {
          if (availableSecretReferences.length === 0) {
            return true
          }
          return (
            availableSecretReferences.findIndex((p) => p.name === value) === -1
          )
        }
      )
      .matches(
        alphaNumericMildCharsWithLeadEndSpacesRegEx,
        'secrets.definitionForm.fields.name.error.regEx'
      ),
    [FormFields.description]: string(),
    [FormFields.type]: mixed<SecretReferenceTypes>()
      .oneOf(Object.values(SecretReferenceTypes))
      .required('secrets.definitionForm.fields.type.error.required'),
    [FormFields.secretValue]: isMHA
      ? string().required(
          'secrets.definitionForm.fields.secretValue.error.required'
        )
      : string().optional(),
    [FormFields.agent]: !isMHA
      ? object({
          id: string().required(
            'secrets.definitionForm.fields.agent.error.required'
          ),
          name: string().required(
            'secrets.definitionForm.fields.agent.error.required'
          ),
          agentCloudProvider: mixed<AgentCloudProvider>()
            .oneOf(Object.values(AgentCloudProvider))
            .required()
        })
          .required('secrets.definitionForm.fields.agent.error.unmatched')
          .nullable()
      : object().optional(),
    [FormFields.secretName]: (() => {
      if (!isMHA) {
        return string().required(
          'secrets.definitionForm.fields.secretName.error.required'
        )
      }
      return string().optional()
    })(),
    [FormFields.secretKey]:
      agentCloudProvider === AgentCloudProvider.AWS
        ? string().required(
            'secrets.definitionForm.fields.secretKey.error.required'
          )
        : string().optional(),
    [FormFields.locationId]:
      agentCloudProvider === AgentCloudProvider.AZURE
        ? string().required(
            'secrets.definitionForm.fields.vault.error.required'
          )
        : string().optional()
  })

export enum SecretValueInputType {
  SINGLE = 'SINGLE',
  MULTI = 'MULTI'
}

export type FormValues = InferType<ReturnType<typeof formSchema>> & {
  secretValueToggle?: SecretValueInputType
}

export const initialValuesCreate = (locationId: string): FormValues => ({
  name: '',
  description: '',
  type: SecretReferenceTypes.PASSWORD,
  agent: {
    id: '',
    name: '',
    agentCloudProvider: AgentCloudProvider.MATILLION
  },
  secretName: '',
  secretKey: '',
  secretValue: '',
  secretValueToggle: SecretValueInputType.SINGLE,
  locationId: locationId
})

export const resolveFormikError = (
  t: TFunction,
  meta: FieldMetaProps<unknown>
) =>
  meta.touched && meta.error ? t(meta.error, { name: meta.value }) : undefined

export const createAutoCompleteItems = (
  items: string[]
): AutoCompleteItemId[] =>
  items.map((item) => ({
    id: item,
    name: item
  }))

export const createAutoCompleteVaultItems = (
  items: Vaults[]
): AutoCompleteItemId[] =>
  items.map((vault) => ({
    id: vault.locationId || vault.vaultName,
    name: vault.vaultName
  }))
