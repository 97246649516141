import { type FunctionComponent } from 'react'

import { GenericCredentials } from 'modules/Projects/AssociateCloudCredentials/AssociateCloudCredentialsToEnvironment/FormContent/Fields'

import { CloudProviders } from 'types/CloudProviders'

export const AWSCredentials: FunctionComponent = () => {
  return (
    <GenericCredentials
      cloudProvider={CloudProviders.AWS}
      fieldId="awsCredentials"
      fieldName="AWS"
    />
  )
}
