import { type ChangeEvent } from 'react'

import { useFormikContext } from 'formik'

import { type EnvironmentFormikContext } from 'modules/Common/Fields/Environment/Environment'

export const useResetPipelineField = () => {
  const { resetForm, values } = useFormikContext<EnvironmentFormikContext>()

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const updatedValues = {
      ...values,
      [e.target.name]: e.target?.value || '',
      pipeline: { id: '', name: '' }
    }
    resetForm({ values: updatedValues })
  }

  return onChange
}
