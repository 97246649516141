import { type SortState } from '@matillion/component-library'

import { type GETBranchesResponseParams } from 'api/types'

import { type BranchTypes } from '.'

export const branchesSort =
  (sortState: Partial<SortState<GETBranchesResponseParams>>) =>
  (a: GETBranchesResponseParams, b: GETBranchesResponseParams): number => {
    const sortStateType = Object.keys(sortState)[0] as BranchTypes
    if (sortState?.[sortStateType] === 'ASC') {
      return a[sortStateType].localeCompare(b[sortStateType])
    } else {
      return b[sortStateType].localeCompare(a[sortStateType])
    }
  }
