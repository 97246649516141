import { mixed, object, string } from 'yup'

import { type ClusterType } from 'api/createProjectForm/types/DatabricksComputeResourcesResponse'
import { type GETEnvironmentsResponseParams } from 'api/types'

import { PROJECTS_CREDENTIALS, PROJECTS_DEFAULTS } from 'constants/route'

import { initialValues as emptyInitialValues } from 'modules/Projects/CreateEnvironment/CreateEnvironmentForm'
import {
  alphaNumericMildCharsWithLeadEndSpacesRegEx,
  warehouseValuesSchema
} from 'modules/Projects/CreateProject/CreateProjectForm'
import { type EditEnvironmentFormikValueTypes } from 'modules/Projects/EditEnvironment/types'

import { AgentsSecretsHost, type Warehouse } from 'types'
import { AgentCloudProvider } from 'types/AgentCloudProvider'

export const editEnvironmentSchema = (projectType: Warehouse | '') => ({
  [PROJECTS_CREDENTIALS]: object({
    environmentName: string()
      .required('fields.environmentName.error.required')
      .matches(
        alphaNumericMildCharsWithLeadEndSpacesRegEx,
        'fields.environmentName.error.regEx'
      ),
    etlAgent: object({
      id: string().required('fields.etlAgent.error.required'),
      name: string().required('fields.etlAgent.error.required'),
      agentCloudProvider: mixed<AgentCloudProvider>()
        .oneOf(Object.values(AgentCloudProvider))
        .required()
    })
      .when('agentsSecretsManagement', {
        is: AgentsSecretsHost.MatillionHosted,
        then: (_existingSchema) => {
          return object({
            id: string().optional(),
            name: string().optional(),
            agentCloudProvider: string().optional()
          })
        }
      })
      .required('fields.etlAgent.error.unmatched')
      .nullable()
  }).concat(warehouseValuesSchema(projectType, true).credentials),
  [PROJECTS_DEFAULTS]: warehouseValuesSchema(projectType).defaults
})

const getCredentialsType = (
  t: (arg: string) => string,
  authType: string | undefined
) => {
  if (authType === 'key_pair') {
    return {
      id: 'key_pair',
      name: t('fields.credentialsType.options.keypair')
    }
  }

  return {
    id: 'password',
    name: t('fields.credentialsType.options.password')
  }
}

export const initialEditEnvironmentValues = (
  environmentFromResponse: GETEnvironmentsResponseParams,
  agentName: string,
  secretLocation: string,
  agentCloudProvider: string,
  defaultRole: string,
  t: (arg: string) => string
): EditEnvironmentFormikValueTypes => {
  const { name, agentId, warehouseConnection } = environmentFromResponse
  const credentialsType = getCredentialsType(
    t,
    warehouseConnection.authentication?.type
  )

  return {
    ...emptyInitialValues,
    environmentName: name,
    etlAgent: {
      id: agentId,
      name: agentName,
      agentCloudProvider: agentCloudProvider
    },
    matillionHostedAgentId: agentId,
    agentsSecretsManagement:
      agentName === 'Matillion Hosted Agent'
        ? AgentsSecretsHost.MatillionHosted
        : AgentsSecretsHost.CustomerHosted,
    environmentDefaultAccess: {
      id: defaultRole,
      name: t(`fields.environmentDefaultAccess.options.${defaultRole}`)
    },
    account: warehouseConnection.url,
    username: warehouseConnection.username,
    secretName: {
      id: warehouseConnection.secretName,
      name: warehouseConnection.secretName
    },
    secretKey: {
      id: warehouseConnection.secretKey,
      name: warehouseConnection.secretKey
    },
    ...(warehouseConnection?.defaultRole && {
      defaultRole: {
        id: warehouseConnection.defaultRole,
        name: warehouseConnection.defaultRole
      }
    }),
    ...(warehouseConnection?.defaultDatabase && {
      defaultDatabase: {
        id: warehouseConnection.defaultDatabase,
        name: warehouseConnection.defaultDatabase
      }
    }),
    ...(warehouseConnection?.defaultSchema && {
      defaultSchema: {
        id: warehouseConnection.defaultSchema,
        name: warehouseConnection.defaultSchema
      }
    }),
    ...(warehouseConnection?.defaultWarehouse && {
      defaultWarehouse: {
        id: warehouseConnection.defaultWarehouse,
        name: warehouseConnection.defaultWarehouse
      }
    }),
    secretReferenceId: warehouseConnection.secretId,
    secretLocationId: secretLocation,
    type: warehouseConnection.type as Warehouse,
    port: warehouseConnection.port,
    ssl: warehouseConnection.ssl,
    ...(warehouseConnection?.clusterId &&
      warehouseConnection?.clusterName &&
      warehouseConnection?.clusterType && {
        compute: {
          id: warehouseConnection.clusterId,
          name: warehouseConnection.clusterName,
          clusterId: warehouseConnection.clusterId,
          clusterName: warehouseConnection.clusterName,
          clusterType: warehouseConnection.clusterType as ClusterType
        }
      }),
    ...(warehouseConnection?.defaultCatalog && {
      catalog: {
        id: warehouseConnection.defaultCatalog,
        name: warehouseConnection.defaultCatalog
      }
    }),
    credentialsType
  }
}
