import { type FC } from 'react'
import { useTranslation } from 'react-i18next'

import { Typography } from '@matillion/component-library'

import { ExternalLink } from 'components/ExternalLink'

import classes from './SupportText.module.scss'

export interface SupportTextProps {
  supportText?: string
  supportDocs?: string
  'data-testid'?: string
}

export const SupportText: FC<SupportTextProps> = ({
  supportText,
  supportDocs,
  'data-testid': dataTestId
}) => {
  const { t } = useTranslation()

  if (!supportText) {
    return null
  }

  return (
    <Typography
      format="bcs"
      data-testid={dataTestId}
      className={classes.SupportText__Text}
    >
      {supportText}{' '}
      {supportDocs && (
        <ExternalLink
          format="bcs"
          href={supportDocs}
          text={t('createStreamingPipeline.learnMore')}
          className={classes.SupportText__Link}
        />
      )}
    </Typography>
  )
}
