import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import classNames from 'classnames'
import { useFormikContext } from 'formik'

import { AutoComplete, Field } from '@matillion/component-library'

import { useGetJobs } from 'api/hooks'

import { type CommonFieldsTypes } from 'modules/Common/Fields/types'
import classes from 'modules/FormsContent/Form.module.scss'
import { renderFormikError } from 'modules/utils'

import { type AutoCompleteProps } from 'types/FormTypes'

export interface PipelineFormikContext {
  environment: AutoCompleteProps
  pipeline: AutoCompleteProps
}

export const Pipeline = ({
  'data-testid': dataTestId = 'common-field-pipeline'
}: CommonFieldsTypes) => {
  const { projectId } = useParams()
  const { errors, handleBlur, handleChange, touched, values } =
    useFormikContext<PipelineFormikContext>()
  const { t } = useTranslation()
  const environmentId = values?.environment?.id
  const isEnabled = Boolean(environmentId)
  const {
    data = [],
    isError,
    isLoading
  } = useGetJobs(projectId!, environmentId, { enabled: isEnabled })

  const fieldError = renderFormikError(
    errors.pipeline,
    Boolean(touched.pipeline)
  )

  const getErrorMessage = useMemo(() => {
    if (isError) {
      return t('commonForm.fields.pipeline.error.loadingError')
    } else if (!data.length && isEnabled) {
      return t('commonForm.fields.pipeline.error.noItemsFound')
    } else if (fieldError) {
      return t(fieldError)
    }

    return undefined
  }, [data.length, isEnabled, isError, fieldError, t])

  return (
    <div data-testid={dataTestId}>
      <Field
        inputComponent={AutoComplete}
        availableItems={data.map((item) => ({
          id: item.name,
          name: item.name
        }))}
        loading={isLoading}
        title={t('commonForm.fields.pipeline.title')}
        name="pipeline"
        data-testid={`${dataTestId}-input`}
        value={values.pipeline || null}
        placeholder={
          isError
            ? t('commonForm.fields.pipeline.error.placeholderText')
            : t('commonForm.fields.pipeline.placeholderText')
        }
        onChange={handleChange}
        onBlur={handleBlur}
        className={classNames(classes.Form__Field)}
        supportText={t('commonForm.fields.pipeline.supportText')}
        errorText={isLoading ? undefined : getErrorMessage}
        hasError={
          Boolean(errors?.pipeline?.id) &&
          Boolean(touched.pipeline) &&
          !isLoading
        }
        required
        disabled={isError || !isEnabled || !data.length}
      />
    </div>
  )
}
