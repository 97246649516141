import { type FC } from 'react'
import { useTranslation } from 'react-i18next'

import { useFormikContext } from 'formik'

import { Button } from '@matillion/component-library'

import { usePostValidateDestinationConfiguration } from 'api/hooks/streaming/usePostValidateDestinationConfiguration'
import { type POSTValidateDestinationConfigurationResponse } from 'api/types'

import {
  destinationConfigPanelIsInvalid,
  mapDestinationFormToDefinition,
  type FormValues
} from 'modules/Projects/CreateStreamingPipeline/FormContent'

import classes from './SubmitButton.module.scss'

interface DestinationConfigContinueButtonProps {
  setIsError: (isError: boolean) => void
  setErrorMessage: (message: string | null) => void
  dataTestId: string
}

export const DestinationConfigContinueButton: FC<
  DestinationConfigContinueButtonProps
> = ({ setIsError, setErrorMessage, dataTestId }) => {
  const { t } = useTranslation()
  const { mutateAsync: mutateValidateConfiguration } =
    usePostValidateDestinationConfiguration()
  const { values } = useFormikContext<FormValues>()

  const onValidation = (
    result: POSTValidateDestinationConfigurationResponse
  ) => {
    if (result.success) {
      setIsError(false)
      setErrorMessage(null)
    } else {
      setIsError(true)
      setErrorMessage(result.message)
    }
  }

  const onValidationError = (result: {
    response: { data: { detail?: string } }
    message: string
  }) => {
    setIsError(true)
    setErrorMessage(result.response.data.detail ?? result.message)
  }

  return (
    <div
      data-testid="destination-config-continue-button-wrapper"
      className={classes.SubmitButton__Wrapper}
    >
      <Button
        text={t('createStreamingPipeline.nextButtonText')}
        onClick={() => {
          if (destinationConfigPanelIsInvalid(values)) {
            setIsError(true)
            setErrorMessage(t('createStreamingPipeline.missingValues'))
          } else {
            mutateValidateConfiguration({
              target: mapDestinationFormToDefinition(values.target),
              agentId: values.agent.id
            }).then(onValidation, onValidationError)
          }
        }}
        data-testid={dataTestId}
        alt="primary"
      />
    </div>
  )
}
