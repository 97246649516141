import { useMutation, type UseMutationOptions } from 'react-query'

import { type AxiosError } from 'axios'

import { postSecretLocation } from 'api/createProjectForm/client'
import type {
  POSTSecretLocationRequest,
  POSTSecretLocationResponse
} from 'api/createProjectForm/types'
import { useClient } from 'api/hooks'

import { QueryKey } from 'constants/endpoint'

type TData = POSTSecretLocationResponse
type TError = AxiosError<unknown>
interface TVariables {
  values: POSTSecretLocationRequest
}

type Options = UseMutationOptions<
  POSTSecretLocationResponse,
  AxiosError,
  TVariables
>

export const usePostSecretLocation = (options?: Options) => {
  const client = useClient()

  return useMutation<TData, TError, TVariables>(
    [QueryKey.SECRET_LOCATION],
    async (data: { values: POSTSecretLocationRequest }) => {
      const { values } = data
      return postSecretLocation(values, client)
    },
    options
  )
}
