import { type FunctionComponent } from 'react'

import { Grid, Section } from '@matillion/component-library'

import classes from './ProvisioningContainer.module.scss'
import { ProvisioningVideo } from './ProvisioningVideo'

interface WarehouseOnboardingProps {
  children: React.ReactNode
}

const sectionSize = {
  small: 12,
  medium: 8,
  large: 12
}

const sectionOffset = {
  small: 0,
  medium: 2,
  large: 0
}

export const ProvisioningContainer: FunctionComponent<
  WarehouseOnboardingProps
> = ({ children }) => {
  return (
    <div className={classes.ProvisioningContainer}>
      <div className={classes.WarehouseOnboarding__StatusContainer}>
        {children}
      </div>
      <Grid>
        <Section size={sectionSize} offset={sectionOffset}>
          <ProvisioningVideo />
        </Section>
      </Grid>
    </div>
  )
}
