import { type DestinationConfigType } from './Destinations'
import { type SourceConfigType } from './Sources'

export enum TablePrefixTypeId {
  SOURCE_DATABASE_AND_SCHEMA = 'source-database-and-schema',
  PREFIX = 'prefix',
  NONE = 'none'
}

export const TABLE_PREFIX_TYPES = {
  [TablePrefixTypeId.SOURCE_DATABASE_AND_SCHEMA]: {
    id: TablePrefixTypeId.SOURCE_DATABASE_AND_SCHEMA,
    name: 'Source Database & Schema (e.g. MYDB_MYSCHEMA_MYTABLE)'
  },
  [TablePrefixTypeId.PREFIX]: {
    id: TablePrefixTypeId.PREFIX,
    name: 'Re-Use Staging Prefix (e.g. STAGEROOTFOLDER_MYTABLE)'
  },
  [TablePrefixTypeId.NONE]: {
    id: TablePrefixTypeId.NONE,
    name: 'No Prefix (e.g. MYTABLE)'
  }
}

export enum TransformationTypeId {
  COPY_TABLE = 'copy-table',
  COPY_TABLE_WITH_SOFT_DELETES = 'copy-table-soft-delete',
  CHANGE_LOG = 'change-log'
}

export const TRANSFORMATION_TYPES = {
  [TransformationTypeId.COPY_TABLE]: {
    id: TransformationTypeId.COPY_TABLE,
    name: 'Copy Table'
  },
  [TransformationTypeId.COPY_TABLE_WITH_SOFT_DELETES]: {
    id: TransformationTypeId.COPY_TABLE_WITH_SOFT_DELETES,
    name: 'Copy Table with Soft Deletes'
  },
  [TransformationTypeId.CHANGE_LOG]: {
    id: TransformationTypeId.CHANGE_LOG,
    name: 'Change Log'
  }
}

export enum TemporalMappingId {
  NATIVE = 'native',
  EPOCH = 'epoch'
}

export const TEMPORAL_MAPPINGS = {
  [TemporalMappingId.NATIVE]: {
    id: TemporalMappingId.NATIVE,
    name: 'Snowflake Native Types'
  },
  [TemporalMappingId.EPOCH]: {
    id: TemporalMappingId.EPOCH,
    name: 'Integers from Epoch'
  }
}

export enum SecretManagerId {
  AWS_SECRETS_MANAGER = 'aws-secrets-manager',
  AZURE_KEY_VAULT = 'azure-key-vault',
  GOOGLE_SECRET_MANAGER = 'google-secret-manager'
}

export type ExcludedSecretManagerId = Exclude<
  SecretManagerId,
  SecretManagerId.GOOGLE_SECRET_MANAGER
>

export const SECRET_MANAGERS = {
  [SecretManagerId.AWS_SECRETS_MANAGER]: {
    id: SecretManagerId.AWS_SECRETS_MANAGER,
    name: 'AWS Secrets Manager'
  },
  [SecretManagerId.AZURE_KEY_VAULT]: {
    id: SecretManagerId.AZURE_KEY_VAULT,
    name: 'Azure Key Vault'
  }
}

export interface SecretReference {
  type: SecretManagerId
  name?: string
}

export interface EngineConfigType {
  advanced?: Record<string, string>
}

export interface StreamingPipelineDefinition {
  id: string
  name: string
  agentId: string
  source: SourceConfigType
  target: DestinationConfigType
  engine?: EngineConfigType
}

export enum StreamingPipelineStatus {
  STARTING = 'STARTING',
  SNAPSHOTTING = 'SNAPSHOTTING',
  STREAMING = 'STREAMING',
  STOPPING = 'STOPPING',
  NOT_RUNNING = 'NOT_RUNNING',
  UNKNOWN = 'UNKNOWN',
  ERROR = 'ERROR'
}

export const isStartable = (status: StreamingPipelineStatus) =>
  status === StreamingPipelineStatus.NOT_RUNNING

export const isStoppable = (status: StreamingPipelineStatus) =>
  status === StreamingPipelineStatus.STARTING ||
  status === StreamingPipelineStatus.SNAPSHOTTING ||
  status === StreamingPipelineStatus.STREAMING

export const isEditable = (status: StreamingPipelineStatus) =>
  status === StreamingPipelineStatus.NOT_RUNNING

export const isDeletable = (status: StreamingPipelineStatus) =>
  status === StreamingPipelineStatus.NOT_RUNNING

export interface StreamingPipelineMetrics {
  startTime?: number
  rowsChangedLastMinute?: number
  snapshot?: StreamingPipelineSnapshotMetrics
}

export interface StreamingPipelineSnapshotMetrics {
  totalTableCount?: number
  remainingTableCount?: number
  duration?: number
  schemas?: RowsScanned[]
}

export interface RowsScanned {
  schema: string
  tables: TableCount[]
}

export interface TableCount {
  table: string
  rowsScanned: number
}

export interface StreamingPipelineEvent {
  eventId: string
  projectId: string
  pipelineId: string
  pipelineExecutionId: string
  agentVersion: string
  timestamp: string
  eventType: StreamingEventType
  eventLevel: StreamingEventLevel
  message?: string
}

export enum StreamingEventType {
  PIPELINE_STARTED = 'PIPELINE_STARTED',
  PIPELINE_STOPPED = 'PIPELINE_STOPPED'
}

export enum StreamingEventLevel {
  INFO = 'INFO',
  ERROR = 'ERROR'
}
