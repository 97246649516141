import { type FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'

import { NO_DATA } from 'constants/strings'

import MetricsHeader from 'modules/Projects/StreamingPipelineDashboard/Metrics/components/MetricsHeader/MetricsHeader'
import { type SummaryField } from 'modules/Projects/StreamingPipelineDashboard/Summary/components/StreamingSummaryBlock/StreamingSummaryBlock'

import {
  type RowsScanned,
  type StreamingPipelineSnapshotMetrics
} from 'types/Streaming'

import formatSeconds from 'utils/formatSeconds/formatSeconds'

interface SnapshottingHeaderProps {
  onDatabaseClick: () => void
  database?: string
  selectedSchema?: RowsScanned
  snapshotMetrics: StreamingPipelineSnapshotMetrics
  filter: string
  setFilter: (value: string) => void
}

const SnapshottingHeader: FunctionComponent<SnapshottingHeaderProps> = ({
  database,
  selectedSchema,
  snapshotMetrics,
  onDatabaseClick,
  filter,
  setFilter
}) => {
  const { t } = useTranslation()

  const additionalMetricFields: SummaryField[] = []
  if (!selectedSchema) {
    const totalRowsScanned = snapshotMetrics.schemas?.reduce((acc1, schema) => {
      return (
        acc1 +
        schema.tables.reduce((acc2, table) => acc2 + table.rowsScanned, 0)
      )
    }, 0)

    additionalMetricFields.push(
      ...[
        {
          heading: t(
            'streamingPipelineDashboard.metrics.snapshot.tablesRemaining'
          ),
          content: snapshotMetrics.remainingTableCount?.toString() ?? NO_DATA,
          testId: 'tables-remaining'
        },
        {
          heading: t('streamingPipelineDashboard.metrics.snapshot.timeElapsed'),
          content: snapshotMetrics.duration
            ? formatSeconds(snapshotMetrics.duration)
            : NO_DATA,
          testId: 'snapshot-duration'
        },
        {
          heading: t('streamingPipelineDashboard.metrics.snapshot.rowsScanned'),
          content: totalRowsScanned?.toString() ?? NO_DATA,
          testId: 'total-rows-scanned'
        }
      ]
    )
  } else {
    const schemaRowsScanned = selectedSchema.tables.reduce(
      (acc, table) => acc + table.rowsScanned,
      0
    )

    additionalMetricFields.push({
      heading: t('streamingPipelineDashboard.metrics.snapshot.rowsScanned'),
      content: schemaRowsScanned?.toString(),
      testId: 'schema-rows-scanned'
    })
  }

  return (
    <MetricsHeader
      onDatabaseClick={onDatabaseClick}
      fields={additionalMetricFields}
      database={database}
      selectedSchema={selectedSchema?.schema}
      filter={filter}
      setFilter={setFilter}
    />
  )
}

export default SnapshottingHeader
