import { type FunctionComponent } from 'react'

import {
  isStreamingSourceId,
  STREAMING_DESTINATIONS,
  STREAMING_SOURCES,
  type StreamingDestinationId,
  type StreamingSourceId
} from 'types/Streaming'

import classes from './PropertyIcon.module.scss'

interface PropertyIconProps {
  subject?: StreamingSourceId | StreamingDestinationId
}

export const PropertyIcon: FunctionComponent<PropertyIconProps> = ({
  subject
}) => {
  if (!subject) {
    return null
  }

  return (
    <img
      alt={subject}
      src={
        isStreamingSourceId(subject)
          ? STREAMING_SOURCES[subject].icon
          : STREAMING_DESTINATIONS[subject].icon
      }
      className={classes.PropertyIcon__Icon}
      data-testid={
        isStreamingSourceId(subject)
          ? 'source-jdbc-icon'
          : 'destination-jdbc-icon'
      }
    />
  )
}
