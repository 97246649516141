import {
  useEffect,
  useState,
  type ChangeEvent,
  type FocusEvent,
  type FunctionComponent
} from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import classNames from 'classnames'
import { useFormikContext } from 'formik'

import { Field } from '@matillion/component-library'
import { useLDClient } from '@matillion/hub-client'

import classes from 'components/Form/Form.module.scss'

import { AppRoutes, PROJECTS_CREDENTIALS } from 'constants/route'

import { useOnboardingContext } from 'context'

import { type CreateProjectFormikValueTypes } from 'modules/Projects/CreateProject/CreateProjectForm'

import { Warehouse } from 'types'

interface UsernameProps {
  disableOnBlur?: boolean
}

export const Username: FunctionComponent<UsernameProps> = ({
  disableOnBlur = false
}) => {
  const location = useLocation()
  const { errors, handleBlur, setFieldValue, touched, values, validateField } =
    useFormikContext<CreateProjectFormikValueTypes>()
  const { t } = useTranslation()

  const { track: sendLDMetric } = useLDClient()

  const { projectType } = useOnboardingContext()
  const isOnboardingSnowflakeCredentialsScreen =
    location.pathname.includes(
      `${AppRoutes.getOnboardingAdd()}/${PROJECTS_CREDENTIALS}`
    ) && projectType === Warehouse.Snowflake

  const [currentValue, setCurrentValue] = useState(values.username)

  const handleFieldChange = (e: ChangeEvent<HTMLInputElement>) => {
    setCurrentValue(e.target.value)
    if (isOnboardingSnowflakeCredentialsScreen) {
      sendLDMetric('snowflake-credentials-form-q-2-username-changed')
    }
  }

  const handleFieldBlur = async (e: FocusEvent<HTMLInputElement>) => {
    handleBlur(e)
    await setFieldValue('username', e.target.value.trim())
    setCurrentValue(e.target.value.trim())
    await validateField('username')
  }

  useEffect(() => {
    setCurrentValue(values.username)
  }, [values.username])

  return (
    <div data-testid={`${values.type}-credentials-username`}>
      <Field
        title={
          !isOnboardingSnowflakeCredentialsScreen
            ? t('fields.username.title')
            : t('fields.usernameSnowflake.title')
        }
        name="username"
        data-testid={`${values.type}-credentials-username-input`}
        data-tracker-id={`${values.type}-credentials-username-input`}
        value={currentValue}
        placeholder={
          !isOnboardingSnowflakeCredentialsScreen
            ? t('fields.username.placeholderText')
            : t('fields.usernameSnowflake.placeholderText')
        }
        onChange={handleFieldChange}
        onBlur={handleFieldBlur}
        className={classNames(classes.Form__SpacingStyles)}
        supportText={
          !isOnboardingSnowflakeCredentialsScreen
            ? t('fields.username.supportText')
            : t('fields.usernameSnowflake.supportText')
        }
        errorText={
          errors?.username && touched.username ? t(errors.username) : null
        }
        hasError={Boolean(errors?.username) && Boolean(touched.username)}
        required
      />
    </div>
  )
}
