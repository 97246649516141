import { useMemo, useState } from 'react'

import { orderBy } from 'lodash'

import {
  DataGrid,
  type DataGridColumnProps,
  type DataGridRow,
  type SortState
} from '@matillion/component-library'

interface SortableDataGridProps<T extends DataGridRow> {
  columns: Array<DataGridColumnProps<T>>
  rows: T[]
  defaultSort: Partial<SortState<T>>
  'data-testid'?: string
  rowClassName?: string
  className?: string
  compact?: boolean
}

const SortableDataGrid = <T extends DataGridRow>({
  columns,
  rows,
  defaultSort,
  'data-testid': dataTestId,
  rowClassName,
  className,
  compact
}: SortableDataGridProps<T>) => {
  const [sortedColumn, setSortedColumn] =
    useState<Partial<SortState<T>>>(defaultSort)

  const sortedRows = useMemo(() => {
    const [[selectedColumn, sortOrder]] = Object.entries(sortedColumn)
    const column = selectedColumn as keyof DataGridRow
    const order = sortOrder.toLowerCase() as 'asc' | 'desc'

    return orderBy(rows, column, order)
  }, [rows, sortedColumn])

  return (
    <DataGrid
      onSort={setSortedColumn}
      columns={columns}
      rows={sortedRows}
      rowClassName={rowClassName}
      className={className}
      data-testid={dataTestId}
      defaultSort={defaultSort}
      compact={compact}
      hasFixedHeader
    />
  )
}

export default SortableDataGrid
