import { useCallback, useMemo, type FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import { Navigate, useNavigate, useParams } from 'react-router-dom'

import classNames from 'classnames'

import {
  Badge,
  LoadingSpinner,
  Section,
  Tab,
  Tabs,
  type TabsProps
} from '@matillion/component-library'

import {
  useGetBranches,
  useGetEnvironments,
  useGetProject,
  useGetProjectMembers,
  useGetSchedules,
  useGetSecretReferences
} from 'api/hooks'

import { GridLayout } from 'components/GridLayout'

import {
  AppRoutes,
  LIST_STREAMING_PIPELINES,
  PROJECT_DETAILS_ACCESS,
  PROJECT_DETAILS_BRANCHES,
  PROJECT_DETAILS_CLOUD_CREDENTIALS,
  PROJECT_DETAILS_ENVIRONMENTS,
  PROJECT_DETAILS_OAUTH,
  PROJECT_DETAILS_SCHEDULES,
  PROJECT_DETAILS_SECRET_DEFINITIONS
} from 'constants/route'

import { useClearSessionStorageForms } from 'hooks/clearSessionStorageForms'
import { useFlags } from 'hooks/flags'

import AccessListing from 'modules/Projects/AccessListing'
import BranchesListing from 'modules/Projects/BranchesListing'
import EnvironmentsListing from 'modules/Projects/EnvironmentsListing'
import SchedulesListing from 'modules/Projects/SchedulesListing'
import StreamingListing from 'modules/Projects/StreamingListing'
import {
  CloudProviderCredentialsListing,
  OAuthListing,
  SecretDefinitionListing
} from 'modules/Secrets'

import { SecretReferenceTypes, Warehouse } from 'types'

import ProjectDetailsHeader from './components/ProjectDetailsHeader'
import classes from './ProjectDetails.module.scss'

const ProjectDetails: FunctionComponent = () => {
  const { t } = useTranslation()
  const { activeTab, projectId } = useParams()
  useClearSessionStorageForms()
  const navigate = useNavigate()
  const {
    enableProjectTabsBadgeCounter,
    enableStreamingPipelines,
    enableStreamingStorageAsADestination
  } = useFlags()

  const { data: project, isLoading: isProjectLoading } = useGetProject(
    projectId!
  )

  const { data: branchesData = [], isLoading: isBranchesLoading } =
    useGetBranches(projectId!, { enabled: !!enableProjectTabsBadgeCounter })

  const { data: streamingData = [], isLoading: isStreamingLoading } =
    useGetEnvironments(projectId!, { enabled: !!enableProjectTabsBadgeCounter })

  const { data: environmentsData = [], isLoading: isEnvironmentsLoading } =
    useGetEnvironments(projectId!, { enabled: !!enableProjectTabsBadgeCounter })

  const {
    data: secretReferenceData = [],
    isLoading: isSecretReferenceLoading
  } = useGetSecretReferences(projectId!, SecretReferenceTypes.PASSWORD, {
    enabled: !!enableProjectTabsBadgeCounter
  })

  const { data: cpcData = [], isLoading: isCPCLoading } =
    useGetSecretReferences(
      projectId!,
      SecretReferenceTypes.CLOUD_PLATFORM_CREDENTIALS,
      { enabled: !!enableProjectTabsBadgeCounter }
    )

  const { data: oAuthsData = [], isLoading: isOAuthLoading } =
    useGetSecretReferences(
      projectId!,
      [
        SecretReferenceTypes.OAUTH_USER_TOKENS,
        SecretReferenceTypes.OAUTH_AUTHORIZATION_CODE,
        SecretReferenceTypes.OAUTH_CLIENT_CRED,
        SecretReferenceTypes.OAUTH_CLIENT_CREDENTIALS,
        SecretReferenceTypes.COMPOSITE_TOKEN
      ],
      { enabled: !!enableProjectTabsBadgeCounter }
    )

  const { data: schedulesData = [], isLoading: isSchedulesLoading } =
    useGetSchedules(projectId!, { enabled: !!enableProjectTabsBadgeCounter })

  const { data: projectMembersData = [], isLoading: isProjectMembersLoading } =
    useGetProjectMembers(projectId!, {
      enabled: !!enableProjectTabsBadgeCounter
    })

  const getCounterBadge = useCallback(
    (data: unknown[], loading: boolean, label: string) => {
      if (!enableProjectTabsBadgeCounter) return null
      if (loading) {
        return <LoadingSpinner className={classes.ProjectDetail__BadgeLoader} />
      }
      if (data.length) {
        return (
          <Badge
            theme="filled"
            colour="blue"
            data-testid={`project-listing-${label}-tab-badge`}
          >
            {data.length}
          </Badge>
        )
      }
    },
    [enableProjectTabsBadgeCounter]
  )

  const isStreamingSupportedByProject =
    project?.warehouse === Warehouse.Snowflake ||
    enableStreamingStorageAsADestination
  const showStreamingTab =
    enableStreamingPipelines &&
    ((isProjectLoading && activeTab === LIST_STREAMING_PIPELINES) ||
      (!isProjectLoading && isStreamingSupportedByProject))

  const tabs = useMemo(
    () => [
      {
        tab: PROJECT_DETAILS_BRANCHES,
        title: t('projectDetail.tabs.branches.title'),
        element: <BranchesListing />,
        counterBadge: getCounterBadge(
          branchesData,
          isBranchesLoading,
          PROJECT_DETAILS_BRANCHES
        )
      },
      ...(showStreamingTab
        ? [
            {
              tab: LIST_STREAMING_PIPELINES,
              title: t('projectDetail.tabs.streaming.title'),
              element: <StreamingListing />,
              counterBadge: getCounterBadge(
                streamingData,
                isStreamingLoading,
                LIST_STREAMING_PIPELINES
              )
            }
          ]
        : []),
      {
        tab: PROJECT_DETAILS_ENVIRONMENTS,
        title: t('projectDetail.tabs.environments.title'),
        element: <EnvironmentsListing />,
        counterBadge: getCounterBadge(
          environmentsData,
          isEnvironmentsLoading,
          PROJECT_DETAILS_ENVIRONMENTS
        )
      },
      {
        tab: PROJECT_DETAILS_SECRET_DEFINITIONS,
        title: t('projectDetail.tabs.secretDefinitions.title'),
        element: <SecretDefinitionListing />,
        counterBadge: getCounterBadge(
          secretReferenceData,
          isSecretReferenceLoading,
          PROJECT_DETAILS_SECRET_DEFINITIONS
        )
      },
      {
        tab: PROJECT_DETAILS_CLOUD_CREDENTIALS,
        title: t('projectDetail.tabs.cloudProviderCredentials.title'),
        element: <CloudProviderCredentialsListing />,
        counterBadge: getCounterBadge(
          cpcData,
          isCPCLoading,
          PROJECT_DETAILS_CLOUD_CREDENTIALS
        )
      },
      {
        tab: PROJECT_DETAILS_OAUTH,
        title: t('projectDetail.tabs.oAuths.title'),
        element: <OAuthListing />,
        counterBadge: getCounterBadge(
          oAuthsData,
          isOAuthLoading,
          PROJECT_DETAILS_OAUTH
        )
      },
      {
        tab: PROJECT_DETAILS_SCHEDULES,
        title: t('projectDetail.tabs.schedules.title'),
        element: <SchedulesListing />,
        counterBadge: getCounterBadge(
          schedulesData,
          isSchedulesLoading,
          PROJECT_DETAILS_SCHEDULES
        )
      },
      {
        tab: PROJECT_DETAILS_ACCESS,
        title: t('projectDetail.tabs.access.title'),
        element: <AccessListing />,
        counterBadge: getCounterBadge(
          projectMembersData,
          isProjectMembersLoading,
          PROJECT_DETAILS_ACCESS
        )
      }
    ],
    [
      branchesData,
      streamingData,
      cpcData,
      environmentsData,
      getCounterBadge,
      isBranchesLoading,
      isStreamingLoading,
      isCPCLoading,
      isEnvironmentsLoading,
      isOAuthLoading,
      isProjectMembersLoading,
      isSchedulesLoading,
      isSecretReferenceLoading,
      oAuthsData,
      projectMembersData,
      schedulesData,
      secretReferenceData,
      showStreamingTab,
      t
    ]
  )

  const getTabPath = useCallback(
    (tab: string) => AppRoutes.getProjectDetails(projectId as string, tab),
    [projectId]
  )

  const { activeTabIndex, activeTabElement, onActiveTabChange } =
    useMemo(() => {
      const index = tabs.findIndex(({ tab }) => tab === activeTab)

      const element = tabs[index]?.element ?? (
        <Navigate to={getTabPath(PROJECT_DETAILS_BRANCHES)} />
      )

      const onChange: TabsProps['onChange'] = ({ value }) => {
        navigate(getTabPath(tabs[value].tab), { replace: true })
      }

      return {
        activeTabIndex: index,
        activeTabElement: element,
        onActiveTabChange: onChange
      }
    }, [activeTab, getTabPath, navigate, tabs])

  return (
    <GridLayout className={classNames(classes.ProjectDetail)}>
      <ProjectDetailsHeader />
      <Section className={classes.ProjectDetail__ContentSelector}>
        <div className={classes.ProjectDetail__TabContainer}>
          <Tabs
            listClassName={classNames(classes.ProjectDetail__TabList)}
            activeIndex={activeTabIndex}
            onChange={onActiveTabChange}
          >
            {tabs.map(({ title, tab, counterBadge }) => (
              <Tab
                title={title}
                key={tab}
                iconAfter={counterBadge}
                data-testid={tab + '-tab'}
              />
            ))}
          </Tabs>
        </div>
        <GridLayout className={classes.ProjectDetail__OutletContainer}>
          {activeTabElement}
        </GridLayout>
      </Section>
    </GridLayout>
  )
}

export default ProjectDetails
