import { type FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

import { Header, HeaderTitle } from 'components/Header'

import { AppRoutes } from 'constants/route'

import { useFormMetadata } from './FormMetadataProvider'

interface CreateHeaderProps {
  onPrevious?: VoidFunction
}

export const CreateHeader: FunctionComponent<CreateHeaderProps> = ({
  onPrevious
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { projectId } = useParams()
  const { metadata } = useFormMetadata()

  const redirectToStreamingPipelineListing = () => {
    navigate(AppRoutes.getStreamingPipelinesListing(projectId!))
  }

  return (
    <Header
      onCancel={redirectToStreamingPipelineListing}
      showCancel
      showPrevious={!!onPrevious}
      onPrevious={onPrevious}
    >
      <HeaderTitle data-testid="configure-streaming-pipeline-title">
        {t(
          metadata.editing
            ? 'editStreamingPipeline.heading'
            : 'createStreamingPipeline.heading'
        )}
      </HeaderTitle>
    </Header>
  )
}
