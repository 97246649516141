import { useState, type FunctionComponent } from 'react'

import { QuitButton } from '@matillion/component-library'

import ConfirmCancelModal from 'components/Modal/ProjectExplorerModal'

interface CancelActionProps {
  onConfirm: () => void
  content?: string
  cancel?: string
  validate?: string
}

export const CancelAction: FunctionComponent<CancelActionProps> = ({
  onConfirm,
  content,
  cancel,
  validate
}) => {
  const [showModal, setShowModal] = useState(false)
  return (
    <>
      <QuitButton
        data-testid="cancel-form-button"
        aria-label="Cancel"
        onClick={() => {
          setShowModal(true)
        }}
      />
      {showModal && (
        <ConfirmCancelModal
          content={content}
          cancel={cancel}
          validate={validate}
          onCancel={() => {
            setShowModal(false)
          }}
          onValidate={onConfirm}
        />
      )}
    </>
  )
}
