import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Toaster } from '@matillion/component-library'
import { useLDClient } from '@matillion/hub-client'

import { usePostProject, type TError } from 'api/hooks'
import { usePostProjectV2 } from 'api/hooks/usePostProjectV2'
import { createProjectMutationData } from 'api/mutation'

import { useFlags } from 'hooks/flags'
import { useDesignerUrl } from 'hooks/useDesignerUrl'

import { type OnboardingFormikValueTypes } from 'modules/Onboarding/OnboardingForm'
import { redirectToDesigner } from 'modules/Onboarding/OnboardingForm/utils/redirectToDesigner'

import { GitProvider, Warehouse } from 'types'

export const useFinishOnboarding = (onRedirectPrevious?: () => void) => {
  const { t } = useTranslation()
  const { clearToasts, makeToast } = Toaster.useToaster()
  const designerFrontendUrl = useDesignerUrl()
  const { mutateAsync: mutateAsyncProject } = usePostProject()
  const { mutateAsync: mutateAsyncProjectV2 } = usePostProjectV2()
  const { enableKeyPairAuthentication } = useFlags()

  const [isComplete, setIsComplete] = useState(false)
  const [isError, setIsError] = useState(false)

  const { track: sendLDMetric, flush: flushLDMetrics } = useLDClient()

  const mutateProject = useCallback(
    async (values: OnboardingFormikValueTypes) => {
      try {
        const useV2Flow =
          enableKeyPairAuthentication && values.type === Warehouse.Snowflake
        const mutateFunction =
          enableKeyPairAuthentication && values.type === Warehouse.Snowflake
            ? mutateAsyncProjectV2
            : mutateAsyncProject
        const response = await mutateFunction({
          values: createProjectMutationData(
            {
              ...values,
              etlAgent: { id: '', name: '' },
              provider: GitProvider.Matillion
            },
            undefined,
            useV2Flow
          )
        })

        const { projectId, environmentId } = response

        sendLDMetric('onboarding-redirect-to-designer')
        await flushLDMetrics()

        // Delay for animation to finish
        setTimeout(() => {
          redirectToDesigner(
            projectId,
            environmentId,
            values.matillionHostedAgentId,
            designerFrontendUrl
          )
        }, 1000)
      } catch (e) {
        clearToasts()
        setIsError(true)
        if (onRedirectPrevious) {
          onRedirectPrevious()
        }
        makeToast({
          title:
            (e as TError).response?.data?.detail ??
            t('createProject.responseMessage.error'),
          message: '',
          type: 'error'
        })
      } finally {
        setIsComplete(true)
      }
    },
    [
      mutateAsyncProject,
      mutateAsyncProjectV2,
      designerFrontendUrl,
      enableKeyPairAuthentication,
      clearToasts,
      onRedirectPrevious,
      makeToast,
      t,
      sendLDMetric,
      flushLDMetrics
    ]
  )

  return { isComplete, isError, mutateProject }
}
