// Target
export const FIELD_NAMES_COMPLETE_TARGET = 'target'
export const FIELD_NAMES_TARGET_PREFIX = 'target.prefix'

// ABS
export const FIELD_NAMES_AZURE_BLOB_CONTAINER = 'target.container'
export const FIELD_NAMES_AZURE_BLOB_ACCOUNT_NAME = 'target.accountName'
export const FIELD_NAMES_AZURE_BLOB_ACCOUNT_KEY_SECRET_MANAGER =
  'target.accountKey.type'
export const FIELD_NAMES_AZURE_BLOB_ACCOUNT_KEY_SECRET_NAME =
  'target.accountKey.name'

// S3
export const FIELD_NAMES_S3_BUCKET = 'target.bucket'

// SNOWFLAKE
export const FIELD_NAMES_SNOWFLAKE_ACCOUNT = 'target.connection.accountName'
export const FIELD_NAMES_SNOWFLAKE_USER = 'target.connection.user'
export const FIELD_NAMES_SNOWFLAKE_SECRET_MANAGER =
  'target.connection.password.type'
export const FIELD_NAMES_SNOWFLAKE_SECRET_NAME =
  'target.connection.password.name'
export const FIELD_NAMES_SNOWFLAKE_JDBC_PROPERTIES =
  'target.connection.jdbcProperties'
export const FIELD_NAMES_SNOWFLAKE_ROLE = 'target.role'
export const FIELD_NAMES_SNOWFLAKE_WAREHOUSE = 'target.warehouse'
export const FIELD_NAMES_SNOWFLAKE_DATABASE = 'target.database'
export const FIELD_NAMES_SNOWFLAKE_STAGE_SCHEMA = 'target.stageSchema'
export const FIELD_NAMES_SNOWFLAKE_STAGE_NAME = 'target.stageName'
export const FIELD_NAMES_SNOWFLAKE_STAGING_PREFIX = 'target.stagePrefix'
export const FIELD_NAMES_SNOWFLAKE_TABLE_SCHEMA = 'target.tableSchema'
export const FIELD_NAMES_SNOWFLAKE_TABLE_PREFIX_TYPE = 'target.tablePrefixType'

// SOURCES
export const FIELD_NAMES_COMPLETE_SOURCE = 'source'
export const FIELD_NAMES_SOURCE_READ_REPLICA = 'source.readReplica'
export const FIELD_NAMES_SOURCE_HOST = 'source.connection.host'
export const FIELD_NAMES_SOURCE_PORT = 'source.connection.port'
export const FIELD_NAMES_SOURCE_DATABASE_NAME = 'source.connection.database'
export const FIELD_NAMES_SOURCE_PDB = 'source.connection.pdb'
export const FIELD_NAMES_SOURCE_USER = 'source.connection.user'
export const FIELD_NAMES_SOURCE_SECRET_MANAGER =
  'source.connection.password.type'
export const FIELD_NAMES_SOURCE_SECRET_NAME = 'source.connection.password.name'
export const FIELD_NAMES_SOURCE_JDBC_PROPERTIES =
  'source.connection.jdbcProperties'

// PIPELINE CONFIG

export const FIELD_NAMES_PIPELINE_TABLES = 'source.tables'
export const FIELD_NAMES_TRANSFORMATION_TYPE = 'target.transformationType'
export const FIELD_NAMES_TEMPORAL_MAPPING = 'target.temporalMapping'
export const FIELD_NAMES_INITIAL_SNAPSHOT = 'source.snapshot.initial.enabled'
export const FIELD_NAMES_ON_DEMAND_SNAPSHOT =
  'source.snapshot.onDemandSnapshot.enabled'
export const FIELD_NAMES_SIGNAL_TABLE =
  'source.snapshot.onDemandSnapshot.signalTable'
export const FIELD_NAMES_ENGINE_ADVANCED_SETTINGS = 'engine.advanced'
