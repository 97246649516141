import { type FC } from 'react'
import { useTranslation } from 'react-i18next'

import { useFormikContext } from 'formik'

import { Button } from '@matillion/component-library'

import {
  engineConfigPanelIsInvalid,
  type FormValues
} from 'modules/Projects/CreateStreamingPipeline/FormContent'

import classes from './SubmitButton.module.scss'

interface PipelineConfigContinueButtonProps {
  setIsError: (isError: boolean) => void
  setErrorMessage: (message: string | null) => void
  dataTestId: string
}

export const PipelineConfigContinueButton: FC<
  PipelineConfigContinueButtonProps
> = ({ setIsError, setErrorMessage, dataTestId }) => {
  const { t } = useTranslation()
  const { values } = useFormikContext<FormValues>()

  return (
    <div
      data-testid="pipeline-config-continue-button-wrapper"
      className={classes.SubmitButton__Wrapper}
    >
      <Button
        text={t('createStreamingPipeline.nextButtonText')}
        onClick={() => {
          if (engineConfigPanelIsInvalid(values)) {
            setIsError(true)
            setErrorMessage(t('createStreamingPipeline.missingValues'))
          } else {
            setIsError(false)
            setErrorMessage(null)
          }
        }}
        data-testid={dataTestId}
        alt="primary"
      />
    </div>
  )
}
