import { type FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'

import classNames from 'classnames'

import { CopyableText, Field } from '@matillion/component-library'

import classes from 'components/Form/Form.module.scss'

const callbackUrl = 'https://project-explorer-dev.matillion.com/oauth-redirect' // This is a placeholder URL, will be replaced with the actual URL in the future

export const RedirectUrl: FunctionComponent = () => {
  const { t } = useTranslation()
  const fieldName = 'redirect-url'

  return (
    <div data-testid="create-oauth-redirect-url">
      <Field
        className={classNames(classes.Form__SpacingStyles)}
        name={fieldName}
        title={t('secrets.createOAuth.fields.redirectUrl.title')}
        inputComponent={CopyableText}
        text={callbackUrl}
      />
    </div>
  )
}
