import { object } from 'yup'
import { type OptionalObjectSchema } from 'yup/lib/object'

import { useOnboardingContext } from 'context'

import { useFormNavigation } from 'hooks'

import {
  warehouseProvisioningValidationSchema,
  type OnboardingFormNavigationSteps,
  type OnboardingFormNavigationStepsType
} from 'modules/Onboarding/OnboardingForm'
import { warehouseValuesSchema } from 'modules/Projects/CreateProject/CreateProjectForm'

export const useValidationSchema = (
  steps: OnboardingFormNavigationStepsType
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): OptionalObjectSchema<any> => {
  const { currentStep } =
    useFormNavigation<OnboardingFormNavigationSteps>(steps)
  const { projectType } = useOnboardingContext()

  const warehouseSchema = warehouseValuesSchema(projectType)

  const valuesSchema = {
    ...warehouseProvisioningValidationSchema,
    ...warehouseSchema
  }

  return currentStep ? valuesSchema[currentStep] : object({})
}
