import { useEffect, useMemo, useState, type FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'

import {
  LoadingSpinner,
  Toaster,
  Typography,
  type SortState
} from '@matillion/component-library'

import { useCheckPermissions, useGetSecretReferences } from 'api/hooks'
import type { GETSecretReferencesResponseParams } from 'api/types/SecretReferencesResponse'

import { QueryKey } from 'constants/endpoint'
import { AppRoutes } from 'constants/route'

import { sortByColumn } from 'modules/Secrets/utils'

import { PermissionsType, SecretReferenceTypes } from 'types'
import { ResourceType } from 'types/ResourceTypes'

import ListingHeader from './components/ListingHeader'
import NoCreatePermission from './components/NoCreatePermission'
import NoData from './components/NoData'
import NoReadPermission from './components/NoReadPermission'
import SecretDefinitionDataGrid from './components/SecretDefinitionDataGrid'

const SecretDefinitionListing: FunctionComponent = () => {
  const { makeToast } = Toaster.useToaster()
  const { projectId } = useParams()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const queryClient = useQueryClient()

  const [sortState, setSortState] = useState<
    Partial<SortState<GETSecretReferencesResponseParams>>
  >({ name: 'ASC' })

  const {
    data: secretReferenceData = [],
    isError: isSecretReferenceError,
    isLoading: isSecretReferenceLoading,
    refetch
  } = useGetSecretReferences(projectId!, SecretReferenceTypes.PASSWORD)

  const { data: createPermission, isLoading: isCreatePermissionLoading } =
    useCheckPermissions({
      resourceType: ResourceType.Project,
      resourceId: projectId!,
      permission: PermissionsType.CreateSecrets
    })
  const { data: viewPermission, isLoading: isViewPermissionLoading } =
    useCheckPermissions({
      resourceType: ResourceType.Project,
      resourceId: projectId!,
      permission: PermissionsType.ViewSecrets
    })

  const sortedSecrets = useMemo(
    () => sortByColumn(secretReferenceData, sortState),
    [sortState, secretReferenceData]
  )

  const redirectCallback = () => {
    navigate(AppRoutes.getCreateSecretDefinition(projectId!))
  }

  const handleDelete = (secretName: string, isSuccess = true) => {
    if (isSuccess) {
      refetch()

      makeToast({
        title: t('secrets.deleteSecretReference.responseMessage.success', {
          secretDefinition: secretName
        }),
        message: '',
        type: 'success'
      })
    } else {
      makeToast({
        title: t('secrets.deleteSecretReference.responseMessage.error', {
          secretDefinition: secretName
        }),
        message: '',
        type: 'error'
      })
    }
  }

  const getContent = () => {
    return isSecretReferenceError ? (
      <Typography>{t('secrets.secretDefinitionListing.error')}</Typography>
    ) : (
      <SecretDefinitionDataGrid
        secrets={sortedSecrets!}
        onSort={setSortState}
        onDeleteHandler={handleDelete}
      />
    )
  }

  useEffect(() => {
    sortByColumn(secretReferenceData, sortState)

    return () => {
      queryClient.removeQueries([
        QueryKey.USER_PERMISSIONS,
        PermissionsType.CreateSecrets
      ])
      queryClient.removeQueries([
        QueryKey.USER_PERMISSIONS,
        PermissionsType.DeleteSecrets
      ])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (
    !secretReferenceData.length &&
    !isSecretReferenceError &&
    !isSecretReferenceLoading &&
    !isViewPermissionLoading &&
    !isCreatePermissionLoading
  ) {
    if (!createPermission) {
      return <NoCreatePermission />
    } else {
      return <NoData onAddSecret={redirectCallback} />
    }
  }

  return (
    <>
      <ListingHeader
        onAddSecret={redirectCallback}
        permission={createPermission}
      />
      {isSecretReferenceLoading ||
      isViewPermissionLoading ||
      (isCreatePermissionLoading && !secretReferenceData.length) ? (
        <LoadingSpinner />
      ) : (
        <>{!viewPermission ? <NoReadPermission /> : getContent()}</>
      )}
    </>
  )
}

export default SecretDefinitionListing
