import {
  type SecretReference,
  type TablePrefixTypeId,
  type TemporalMappingId,
  type TransformationTypeId
} from './types'

export enum StreamingDestinationId {
  Snowflake = 'snowflake',
  S3 = 's3',
  ABS = 'abs'
}

export enum StreamingDestinationName {
  Snowflake = 'Snowflake',
  S3 = 'Amazon S3',
  ABS = 'Azure Blob'
}

export const STREAMING_DESTINATIONS = {
  [StreamingDestinationId.Snowflake]: {
    id: StreamingDestinationId.Snowflake,
    name: StreamingDestinationName.Snowflake,
    icon: 'https://connector-logos.matillion.com/Snowflake.svg',
    docs: {
      overview:
        'https://docs.matillion.com/data-productivity-cloud/streaming/docs/snowflake-streaming-destination/'
    }
  },
  [StreamingDestinationId.S3]: {
    id: StreamingDestinationId.S3,
    name: StreamingDestinationName.S3,
    icon: 'https://connector-logos.matillion.com/AmazonS3.svg',
    docs: {
      overview:
        'https://docs.matillion.com/data-productivity-cloud/streaming/docs/amazon-s3-streaming-destination/'
    }
  },
  [StreamingDestinationId.ABS]: {
    id: StreamingDestinationId.ABS,
    name: StreamingDestinationName.ABS,
    icon: 'https://connector-logos.matillion.com/AzureBlob.svg',
    docs: {
      overview:
        'https://docs.matillion.com/data-productivity-cloud/streaming/docs/azure-blob-storage-streaming-destination/'
    }
  }
}

export interface DestinationConfigType {
  type: StreamingDestinationId
}

export interface SnowflakeDestinationConfigType extends DestinationConfigType {
  connection?: SnowflakeConnectionType
  role?: string
  warehouse?: string
  database?: string
  stageSchema?: string
  stageName?: string
  stagePrefix?: string
  tableSchema?: string
  tablePrefixType?: TablePrefixTypeId
  transformationType?: TransformationTypeId
  temporalMapping?: TemporalMappingId
}

export interface S3DestinationConfigType extends DestinationConfigType {
  bucket?: string
  prefix?: string
}

export interface AzureBlobDestinationConfigType extends DestinationConfigType {
  container?: string
  prefix?: string
  accountName?: string
  accountKey?: SecretReference
}

export interface SnowflakeConnectionType {
  accountName?: string
  user?: string
  password?: SecretReference
  jdbcProperties?: Record<string, string>
}

export const isSnowflakeDestination = (
  destination: DestinationConfigType
): destination is SnowflakeDestinationConfigType => {
  return destination.type === StreamingDestinationId.Snowflake
}
