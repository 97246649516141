import { type ComponentType } from 'react'

import classNames from 'classnames'
import { kebabCase } from 'lodash'

import { Radio, Typography } from '@matillion/component-library'

import classes from './ProviderCard.module.scss'

export interface ProviderCardProps {
  logo: ComponentType<{
    className?: string
    disabled?: boolean
    title?: string
  }>
  providerName: string
  handleClick?: () => void
  selected?: boolean
}

const ProviderCard = ({
  logo: Logo,
  providerName,
  handleClick,
  selected = false
}: ProviderCardProps) => {
  const isDisabled = !handleClick

  return (
    <button
      type="button"
      onClick={handleClick}
      className={classNames(
        classes.ProviderCard,
        selected && classes['ProviderCard--selected']
      )}
      disabled={isDisabled}
      title="provider-card-btn"
      data-testid={`provider-card-button-${kebabCase(providerName)}`}
    >
      <div className={classes.ProviderCard__Container}>
        <Radio
          className={classes.ProviderCard__Radio}
          checked={selected}
          onChange={handleClick}
          disabled={isDisabled}
          title="provider-radio-btn"
        />
        <div className={classes.ProviderCard__Content}>
          <div className={classes.ProviderCard__LogoContainer}>
            <Logo
              className={classes.ProviderCard__Logo}
              disabled={isDisabled}
              title="provider-logo"
            />
          </div>
          <Typography>{providerName}</Typography>
        </div>
      </div>
    </button>
  )
}
export default ProviderCard
