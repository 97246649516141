import { type SecretReference } from './types'

export enum StreamingSourceId {
  Postgres = 'postgres',
  Oracle = 'oracle',
  MySQL = 'mysql',
  SQLServer = 'sqlserver',
  Db2IbmI = 'db2ibmi'
}

export const isStreamingSourceId = (
  value: string
): value is StreamingSourceId => {
  return Object.values<string>(StreamingSourceId).includes(value)
}

export const hasDatabaseProperty = (
  source: SourceConfigType
): source is SourceConfigType & {
  connection?:
    | SQLServerConnectionType
    | OracleConnectionType
    | PostgresConnectionType
} => {
  return (
    source.type === StreamingSourceId.Oracle ||
    source.type === StreamingSourceId.SQLServer ||
    source.type === StreamingSourceId.Postgres
  )
}

export const hasPdbProperty = (
  source: SourceConfigType
): source is SourceConfigType & { connection?: OracleConnectionType } => {
  return source.type === StreamingSourceId.Oracle
}

export const groupTablesBySchema = (tables: SourceTable[]): SourceSchema[] => {
  const schemas: SourceSchema[] = []
  tables.forEach((table) => {
    const i = schemas.findIndex((schema) => schema.schema === table.schema)
    if (i === -1) {
      schemas.push({
        schema: table.schema,
        tables: [table.table]
      })
    } else {
      schemas[i].tables.push(table.table)
    }
  })
  return schemas
}

interface CommonConnectionType {
  host?: string
  port?: number
  user?: string
  password?: SecretReference
  jdbcProperties?: Record<string, string>
}

export interface PostgresConnectionType extends CommonConnectionType {
  database?: string
}

export interface OracleConnectionType extends CommonConnectionType {
  database?: string
  pdb?: string
}

export interface MySQLConnectionType extends CommonConnectionType {}

export interface SQLServerConnectionType extends CommonConnectionType {
  database?: string
}

export interface Db2IbmIConnectionType extends CommonConnectionType {}

export type SourceConnectionType =
  | PostgresConnectionType
  | OracleConnectionType
  | MySQLConnectionType
  | SQLServerConnectionType
  | Db2IbmIConnectionType

export interface SourceTable {
  schema: string
  table: string
}

export interface SourceSchema {
  schema: string
  tables: string[]
}

export interface SnapshotType {
  initial: {
    enabled: boolean
  }
  onDemandSnapshot: {
    enabled: boolean
    signalTable?: SourceTable
  }
}

export interface SourceConfigType {
  type: StreamingSourceId
  connection?: SourceConnectionType
  tables?: SourceTable[]
  snapshot?: SnapshotType
}

export enum StreamingSourceName {
  Postgres = 'PostgreSQL',
  Oracle = 'Oracle',
  MySQL = 'MySQL',
  SQLServer = 'Microsoft SQL Server',
  Db2IbmI = 'DB2 for IBM i'
}

export const STREAMING_SOURCES = {
  [StreamingSourceId.Postgres]: {
    id: StreamingSourceId.Postgres,
    name: StreamingSourceName.Postgres,
    icon: 'https://connector-logos.matillion.com/PostgreSQL.svg',
    docs: {
      overview:
        'https://docs.matillion.com/data-productivity-cloud/streaming/docs/postgresql-streaming-source/',
      signalTable:
        'https://docs.matillion.com/data-productivity-cloud/streaming/docs/postgresql-streaming-source/#create-a-signal-table'
    }
  },
  [StreamingSourceId.Oracle]: {
    id: StreamingSourceId.Oracle,
    name: StreamingSourceName.Oracle,
    icon: 'https://connector-logos.matillion.com/OracleDatabase.svg',
    docs: {
      overview:
        'https://docs.matillion.com/data-productivity-cloud/streaming/docs/oracle-streaming-source/',
      signalTable:
        'https://docs.matillion.com/data-productivity-cloud/streaming/docs/oracle-streaming-source/#create-a-signal-table'
    }
  },
  [StreamingSourceId.MySQL]: {
    id: StreamingSourceId.MySQL,
    name: StreamingSourceName.MySQL,
    icon: 'https://connector-logos.matillion.com/MySQL.svg',
    docs: {
      overview:
        'https://docs.matillion.com/data-productivity-cloud/streaming/docs/mysql-streaming-source/',
      signalTable: undefined
    }
  },
  [StreamingSourceId.SQLServer]: {
    id: StreamingSourceId.SQLServer,
    name: StreamingSourceName.SQLServer,
    icon: 'https://connector-logos.matillion.com/MicrosoftSQLServer.svg',
    docs: {
      overview:
        'https://docs.matillion.com/data-productivity-cloud/streaming/docs/microsoft-sql-server-streaming-source/',
      signalTable:
        'https://docs.matillion.com/data-productivity-cloud/streaming/docs/microsoft-sql-server-streaming-source/#create-a-signal-table'
    }
  },
  [StreamingSourceId.Db2IbmI]: {
    id: StreamingSourceId.Db2IbmI,
    name: StreamingSourceName.Db2IbmI,
    icon: 'https://connector-logos.matillion.com/IBMDB2.svg',
    docs: {
      overview:
        'https://docs.matillion.com/data-productivity-cloud/streaming/docs/db2-for-ibm-i-streaming-source/',
      signalTable: undefined
    }
  }
}

export const DEFAULT_SOURCE_PORTS = {
  [StreamingSourceId.MySQL]: 3306,
  [StreamingSourceId.SQLServer]: 1433,
  [StreamingSourceId.Oracle]: 1521,
  [StreamingSourceId.Db2IbmI]: 8471,
  [StreamingSourceId.Postgres]: 5432
}
