import { useMemo, useState } from 'react'

import classnames from 'classnames'
import { orderBy } from 'lodash'

import {
  DataGrid,
  type DataGridColumnProps,
  type DataGridRow,
  type SortState
} from '@matillion/component-library'

import classes from 'modules/Projects/StreamingPipelineDashboard/Common/components/SortableDataGrid/SortableDataGrid.module.scss'

interface SortableDataGridProps<T extends DataGridRow> {
  columns: Array<DataGridColumnProps<T>>
  rows: T[]
  defaultSort: Partial<SortState<T>>
  'data-testid'?: string
  rowClassName?: string
  className?: string
}

const SortableDataGrid = <T extends DataGridRow>({
  columns,
  rows,
  defaultSort,
  'data-testid': dataTestId,
  rowClassName,
  className
}: SortableDataGridProps<T>) => {
  const [sortedColumn, setSortedColumn] =
    useState<Partial<SortState<T>>>(defaultSort)

  const sortableColumns = columns
    .filter((column) => column.sortable)
    .map((column) => column.key)

  const sortedRows = useMemo(() => {
    const [[selectedColumn, sortOrder]] = Object.entries(sortedColumn)
    const column = selectedColumn as keyof DataGridRow
    const order = sortOrder.toLowerCase() as 'asc' | 'desc'

    return orderBy(rows, column, order)
  }, [rows, sortedColumn])

  return (
    <DataGrid
      onSort={(sortState) => {
        const [[column]] = Object.entries(sortState)
        if (sortableColumns.includes(column)) {
          setSortedColumn(sortState)
        }
      }}
      columns={columns}
      rows={sortedRows}
      rowClassName={classnames(rowClassName, classes.SortableDataGrid__Row)}
      className={classnames(className, classes.SortableDataGrid)}
      data-testid={dataTestId}
      defaultSort={defaultSort}
    />
  )
}

export default SortableDataGrid
