import { type POSTBranchRequest } from 'api/types'

import { type FormValues } from 'modules/Projects/CreateBranch/CreateBranch.util'

export const createBranchMutationData = (
  formValues: FormValues
): POSTBranchRequest => ({
  name: formValues.branchName.trim(),
  parentBranch: formValues.branchFrom,
  defaultEnvironmentId: formValues.environment?.id
})
