import {
  StrictMode,
  type FunctionComponent,
  type PropsWithChildren
} from 'react'
import { BrowserRouter } from 'react-router-dom'

import { Toaster } from '@matillion/component-library'
import {
  GitApiContextProvider,
  GitContextProvider,
  GitOAuthProvider,
  type DeploymentEnvironment
} from '@matillion/git-component-library'
import {
  AuthProvider,
  FlagProvider,
  RegionGuard,
  ServiceRegistryProvider
} from '@matillion/hub-client'

import config from 'config'

import ProjectExplorerQueryClientProvider from './api/ProjectExplorerQueryClientProvider'

const Providers: FunctionComponent<PropsWithChildren> = ({ children }) => {
  return (
    <Toaster.Provider theme="dark" withFade position="top" align="right">
      <AuthProvider environment={config.authEnvironment}>
        <FlagProvider
          launchDarklyClientId={config.launchDarkly.clientId}
          flags={config.launchDarkly.defaultFlags}
          waitForFlags
        >
          <RegionGuard strictAccountRegion>
            <GitOAuthProvider environment={config.environment}>
              <GitApiContextProvider
                environment={config.environment as DeploymentEnvironment}
              >
                <GitContextProvider enableFileSummaries={false}>
                  <ProjectExplorerQueryClientProvider>
                    <ServiceRegistryProvider>
                      <BrowserRouter>{children}</BrowserRouter>
                    </ServiceRegistryProvider>
                  </ProjectExplorerQueryClientProvider>
                </GitContextProvider>
              </GitApiContextProvider>
            </GitOAuthProvider>
          </RegionGuard>
        </FlagProvider>
      </AuthProvider>
    </Toaster.Provider>
  )
}

const RenderProviders: FunctionComponent<PropsWithChildren> = ({ children }) =>
  config.strictMode ? (
    <StrictMode>{Providers({ children })}</StrictMode>
  ) : (
    Providers({ children })
  )

export default RenderProviders
