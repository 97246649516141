import { type ChangeEvent, type FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'

import classNames from 'classnames'
import { Field as FormikField, type FieldProps } from 'formik'

import { Field, Toggle } from '@matillion/component-library'

import {
  FormFields,
  SecretValueInputType
} from 'modules/Secrets/CreateSecretDefinitionForm/CreateSecretDefinitionForm.utils'
import classes from 'modules/Secrets/SecretDefinitionFormContent/SecretDefinitionFormContent.module.scss'

const SecretValueToggle: FunctionComponent = () => {
  const { t } = useTranslation()

  return (
    <div
      data-testid="secret-definition-secret-value-toggle"
      className={classNames(
        classes.SecretDefinitionFormContent__Field,
        classes['SecretDefinitionFormContent__Field--normal']
      )}
    >
      <FormikField name={FormFields.secretValueToggle}>
        {({
          field,
          meta,
          form: { handleChange, setFieldValue, values }
        }: FieldProps<string>) => (
          <Field
            {...field}
            title={t('secrets.definitionForm.fields.secretValueToggle.title')}
            supportText={t(
              'secrets.definitionForm.fields.secretValueToggle.supportText'
            )}
            inputComponent={Toggle}
            overlayText={true}
            checked={meta.value === SecretValueInputType.MULTI}
            value={field.value}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              const { value } = e.target
              handleChange(e)
              setFieldValue(
                'secretValueToggle',
                value === SecretValueInputType.MULTI
                  ? SecretValueInputType.SINGLE
                  : SecretValueInputType.MULTI
              )
            }}
            data-testid="secret-definition-secret-value-toggle-input"
          />
        )}
      </FormikField>
    </div>
  )
}

export default SecretValueToggle
