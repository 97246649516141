import { useQuery, type UseQueryOptions } from 'react-query'

import { type AxiosError } from 'axios'

import { getEnvironmentAgents } from 'api/clients'
import { useClient, useNetworkErrorToast } from 'api/hooks'
import { type AgentStatus, type GETEnvironmentAgentsResponse } from 'api/types'

import { QueryKey } from 'constants/endpoint'

import { AgentType } from 'types/Agents'

type TData = GETEnvironmentAgentsResponse
type TError = AxiosError<unknown>
type Options = Omit<
  UseQueryOptions<TData, TError, TData>,
  'queryKey' | 'queryFn'
>

export const useGetStreamingAgents = (
  agentStatus: AgentStatus,
  options?: Options
) => {
  const client = useClient()
  const makeErrorToast = useNetworkErrorToast()

  return useQuery<TData, TError>({
    queryKey: [QueryKey.STREAMING_AGENTS_LIST, agentStatus],
    queryFn: async () =>
      getEnvironmentAgents(client, {
        agentType: AgentType.STREAMING,
        agentStatus
      }),
    onError: (error) => {
      makeErrorToast({ message: error.message })
    },
    ...options
  })
}
