import { type AxiosInstance } from 'axios'

import { mockAxios } from '__mocks__/axiosMock'

export type GETEnvironmentsResponse = Data[]

interface Resource {
  id: string
  type: string
}

interface Data {
  resource: Resource
  permission: string
}

export const checkPermission = async (
  postData: Data,
  /* istanbul ignore next */
  client: AxiosInstance = mockAxios
) => {
  const { data } = await client.post('/api/v1/permission/check', postData)
  return data
}

export const checkBulkPermission = async (
  postData: Data[],
  /* istanbul ignore next */
  client: AxiosInstance = mockAxios
): Promise<Data[]> => {
  const { data } = await client.post('/api/v1/permission/bulk-check', postData)
  return data
}
