import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

import { type FormikHelpers, type FormikProps } from 'formik'
import { isMatch, pick } from 'lodash'

import { Loader, Toaster } from '@matillion/component-library'

import { useGetEnvironmentAgents, useGetEnvironments } from 'api/hooks'
import { useGetSecretReference } from 'api/hooks/useGetSecretReference'
import { type GETEnvironmentsResponseParams } from 'api/types'

import Form from 'components/Form'
import ProjectExplorerModal from 'components/Modal/ProjectExplorerModal'

import { ENVIRONMENT_STORAGE_ITEM } from 'constants/persistance'
import {
  AppRoutes,
  PROJECT_DETAILS_ENVIRONMENTS,
  PROJECTS_CREDENTIALS
} from 'constants/route'

import { useFormNavigation } from 'hooks'

import { steps } from 'modules/Projects/EditEnvironment/EditEnvironmentForm.steps'
import { initialEditEnvironmentValues } from 'modules/Projects/EditEnvironment/EditEnvironmentForm.util'
import EditEnvironmentRoutes from 'modules/Projects/EditEnvironment/EditEnvironmentRoutes'
import { useEditEnvironmentFormSubmit } from 'modules/Projects/EditEnvironment/hooks/useEditEnvironmentFormSubmit'
import { useValidationSchema } from 'modules/Projects/EditEnvironment/hooks/useValidationSchema'
import { type EditEnvironmentFormikValueTypes } from 'modules/Projects/EditEnvironment/types'

import { AgentCloudProvider } from 'types/AgentCloudProvider'

export function getSecretReferenceId(
  selectedEnvironment?: GETEnvironmentsResponseParams
): string | undefined {
  let secretReferenceId = selectedEnvironment?.warehouseConnection.secretId
  if (!secretReferenceId) {
    const authentication =
      selectedEnvironment?.warehouseConnection.authentication
    if (authentication?.password) {
      secretReferenceId = authentication.password
    } else if (authentication?.privateKey) {
      secretReferenceId = authentication.privateKey
    }
  }
  return secretReferenceId
}

const EditEnvironmentForm = () => {
  const { envId, projectId } = useParams()
  const { makeToast } = Toaster.useToaster()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [selectedEnvironment, setSelectedEnvironment] =
    useState<GETEnvironmentsResponseParams>()
  const { isLastStep, progress, stepIndex, currentStep } =
    useFormNavigation(steps)
  const onSubmit = useEditEnvironmentFormSubmit()
  const validationSchema = useValidationSchema()
  const formikRef = useRef<FormikProps<EditEnvironmentFormikValueTypes>>(null)
  const [showModal, setShowModal] = useState(false)
  const [modalSubmitting, setModalSubmitting] = useState(false)

  const {
    data: environmentsData = [],
    isError: environmentsError,
    isLoading: isEnvironmentsLoading
  } = useGetEnvironments(projectId!)

  const {
    data: listOfAgents = [],
    isError: isAgentsError,
    isLoading: isAgentsLoading
  } = useGetEnvironmentAgents()

  useEffect(() => {
    if (environmentsData.length) {
      setSelectedEnvironment(
        environmentsData.filter((x) => x.id === envId!)?.[0]
      )
    }
  }, [envId, environmentsData])

  // temporary fix for the secretReferenceId for environments created with key_pair authentication
  const secretReferenceId = getSecretReferenceId(selectedEnvironment)

  const {
    data: secretReference,
    isError: secretReferenceError,
    isLoading: secretReferenceLoading
  } = useGetSecretReference(projectId!, secretReferenceId ?? '', envId, {
    enabled: !!selectedEnvironment
  })

  const secretLocationId = secretReference?.locationId

  if (
    isEnvironmentsLoading ||
    !selectedEnvironment ||
    !secretLocationId ||
    isAgentsLoading ||
    secretReferenceLoading
  ) {
    return <Loader />
  }

  if (environmentsError || isAgentsError || secretReferenceError) {
    makeToast({
      title: t('editEnvironment.errors.loading'),
      message: '',
      type: 'error'
    })
    navigate(
      AppRoutes.getProjectDetails(projectId!, PROJECT_DETAILS_ENVIRONMENTS)
    )
    return null
  }

  const agentName =
    listOfAgents.find((x) => x.agentId === selectedEnvironment.agentId)?.name ??
    selectedEnvironment.agentName

  const agentCloudProvider =
    listOfAgents
      .find((x) => x.agentId === selectedEnvironment.agentId)
      ?.cloudProviderId.toString() ?? AgentCloudProvider.AWS

  const initialValues = initialEditEnvironmentValues(
    selectedEnvironment,
    agentName,
    secretLocationId,
    agentCloudProvider,
    selectedEnvironment.defaultRole,
    t
  )

  const updateValuesWithCloudCredentials = (
    values: EditEnvironmentFormikValueTypes
  ) => {
    const { aws, azure, gcp } = selectedEnvironment.platformProviderSecrets
    return {
      ...values,
      awsCredentials: aws,
      azureCredentials: azure,
      gcpCredentials: gcp
    }
  }

  const handleSubmit = async (
    values: EditEnvironmentFormikValueTypes,
    formikHelpers: FormikHelpers<EditEnvironmentFormikValueTypes>
  ) => {
    const selectedValues: Array<
      Partial<keyof EditEnvironmentFormikValueTypes>
    > = [
      'environmentName',
      'etlAgent',
      'matillionHostedAgentId',
      'account',
      'username',
      'secretName',
      'secretKey',
      'secretValue',
      'secretReferenceId',
      'port',
      'secretLocationId',
      'ssl'
    ]
    const comparableValues = pick(values, selectedValues)
    const comparableInitialValues = pick(initialValues, selectedValues)

    const skipSecretValueCreation =
      isMatch(comparableValues, comparableInitialValues) ||
      isMatch(comparableInitialValues, comparableValues)

    if (!skipSecretValueCreation && currentStep === PROJECTS_CREDENTIALS) {
      // eslint-disable-next-line @typescript-eslint/no-confusing-void-expression, @typescript-eslint/await-thenable
      await formikHelpers.setSubmitting(false)
      setShowModal(true)
    } else {
      await onSubmit({
        values: updateValuesWithCloudCredentials(values),
        setValues: formikHelpers.setValues,
        skipSecretValueCreation
      })
    }
  }

  const continueToNextPage = () => {
    void (async () => {
      if (formikRef.current) {
        setModalSubmitting(true)
        await onSubmit({
          values: updateValuesWithCloudCredentials(formikRef.current.values),
          setValues: formikRef.current.setValues
        }).finally(() => {
          setModalSubmitting(false)
          setShowModal(false)
        })
      }
    })()
  }

  return (
    <>
      <Form<EditEnvironmentFormikValueTypes>
        formikValues={{
          onSubmit: handleSubmit,
          initialValues,
          validateOnMount: true,
          enableReinitialize: true,
          validationSchema: validationSchema,
          initialTouched: false,
          innerRef: formikRef
        }}
        stepInfo={{
          stepIndex,
          isLastStep,
          progress
        }}
        translationPrefix="editEnvironment"
        persistingStorageId={ENVIRONMENT_STORAGE_ITEM}
        persistenceExclusions={['secretValue']}
      >
        <EditEnvironmentRoutes />
      </Form>
      {showModal && (
        <ProjectExplorerModal
          onCancel={() => {
            setShowModal(false)
          }}
          heading={t('editEnvironment.modal.credentialsModal.title')}
          content={t('editEnvironment.modal.credentialsModal.content')}
          onValidate={continueToNextPage}
          waiting={modalSubmitting}
          alt="primary"
        />
      )}
    </>
  )
}
export default EditEnvironmentForm
