import { useEffect, useState, type FC } from 'react'
import { useTranslation } from 'react-i18next'

import { useFormikContext } from 'formik'

import {
  Button,
  LoadingSpinner,
  Modal,
  Typography,
  type DataGridColumnProps,
  type DataGridRow
} from '@matillion/component-library'

import { useFetchStreamingSourceTables } from 'api/hooks/streaming'

import { ModalContent, ModalHeading } from 'components/Modal'
import { SearchBar } from 'components/SearchBar'
import SortableDataGrid from 'components/SortableDataGrid/SortableDataGrid'

import {
  getDatabaseString,
  mapSourceConnectionFormToDefinition,
  useFormMetadata,
  type FormValues
} from 'modules/Projects/CreateStreamingPipeline/FormContent'

import classes from './TableSelector.module.scss'

export interface TableGridRow extends DataGridRow {
  id: string
}

export interface TableSelectorProps {
  title: string
  schema: string
  columns: Array<DataGridColumnProps<TableGridRow>>
  rows: TableGridRow[]
  backButtonText: string
  selectButtonText: string
  onClose: () => void
  onPrevious: () => void
}

export const TableSelector: FC<TableSelectorProps> = ({
  title,
  schema,
  columns,
  rows,
  backButtonText,
  selectButtonText,
  onClose,
  onPrevious
}) => {
  const { t } = useTranslation()
  const { values } = useFormikContext<FormValues>()
  const { setMetadataProperty } = useFormMetadata()
  const {
    mutateAsync: mutateFetchTables,
    isLoading,
    isError: isFetchTablesError
  } = useFetchStreamingSourceTables()
  const [filter, setFilter] = useState('')
  const filteredRows = rows.filter((row) =>
    row.id.toLowerCase().includes(filter.toLowerCase())
  )

  useEffect(() => {
    setMetadataProperty(`source.schemas.${schema}.tablesLoading`, true)
    mutateFetchTables({
      agentId: values.agent.id,
      type: values.source.type.id,
      connection: mapSourceConnectionFormToDefinition(values.source.connection),
      schema: schema
    }).then((response) => {
      setMetadataProperty(`source.schemas.${schema}.tables`, response)
      setMetadataProperty(`source.schemas.${schema}.tablesLoading`, false)
    })
  }, [
    mutateFetchTables,
    schema,
    setMetadataProperty,
    values.agent.id,
    values.source.type.id,
    values.source.connection
  ])

  return (
    <Modal
      onCancel={onClose}
      ariaLabelledBy="modal-title"
      size="large"
      className={classes.TableSelector__Modal}
    >
      <ModalHeading data-testid="streaming-table-selector-modal-title">
        {title}
      </ModalHeading>
      <ModalContent>
        <div className={classes.TableSelector__Toolbar}>
          <div>
            <Typography
              type="bcs"
              className={classes.TableSelector__BreadcrumbHeader}
            >
              {`${t(
                'createStreamingPipeline.fields.pipelineConfig.tableSelector.breadcrumbHeader1'
              )} / ${t(
                'createStreamingPipeline.fields.pipelineConfig.tableSelector.breadcrumbHeader2'
              )}`}
            </Typography>
            <Typography type="bcs">
              {`${getDatabaseString(values, t)} / ${schema}`}
            </Typography>
          </div>
          <SearchBar value={filter} onChange={setFilter} />
        </div>

        {isFetchTablesError && (
          <Typography data-testid="table-selector-error">
            {t(
              'createStreamingPipeline.fields.pipelineConfig.tableSelector.errorLoading'
            )}
          </Typography>
        )}
        {!isFetchTablesError && isLoading && (
          <div className={classes.TableSelector__LoadingSpinnerWrapper}>
            <LoadingSpinner />
          </div>
        )}
        {!isFetchTablesError && !isLoading && (
          <SortableDataGrid
            columns={columns}
            rows={filteredRows}
            data-testid="schema-selector-table"
            defaultSort={{ id: 'ASC' }}
            className={classes.TableSelector__Grid}
            compact
          />
        )}

        <div className={classes.TableSelector__ButtonWrapper}>
          <Button
            alt="secondary"
            text={backButtonText}
            onClick={onPrevious}
            data-testid="streaming-table-selector-back-button"
          />
          <Button
            text={selectButtonText}
            onClick={onClose}
            data-testid="streaming-table-selector-select-button"
          />
        </div>
      </ModalContent>
    </Modal>
  )
}
