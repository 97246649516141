import { useEffect, useMemo, useState, type FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'

import {
  LoadingSpinner,
  Toaster,
  Typography,
  type SortState
} from '@matillion/component-library'

import { useCheckPermissions, useGetSecretReferences } from 'api/hooks'
import { type GETSecretReferencesResponseParams } from 'api/types'

import { QueryKey } from 'constants/endpoint'
import { AppRoutes } from 'constants/route'

import { sortByColumn } from 'modules/Secrets/utils'

import { PermissionsType, SecretReferenceTypes } from 'types'
import { ResourceType } from 'types/ResourceTypes'

import CloudProviderCredentialsDataGrid from './components/CloudProviderCredentialsDataGrid'
import ListingHeader from './components/ListingHeader'
import NoCreatePermission from './components/NoCreatePermission'
import NoData from './components/NoData'
import NoReadPermission from './components/NoReadPermission'

export const CloudProviderCredentialsListing: FunctionComponent = () => {
  const { makeToast } = Toaster.useToaster()
  const { projectId } = useParams()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const queryClient = useQueryClient()

  const [sortState, setSortState] = useState<
    Partial<SortState<GETSecretReferencesResponseParams>>
  >({ name: 'ASC' })

  const {
    data: cpcData = [],
    isError: isCPCError,
    isLoading: isCPCLoading
  } = useGetSecretReferences(
    projectId!,
    SecretReferenceTypes.CLOUD_PLATFORM_CREDENTIALS
  )

  const { data: createPermission, isLoading: isCreatePermissionLoading } =
    useCheckPermissions({
      resourceType: ResourceType.Project,
      resourceId: projectId!,
      permission: PermissionsType.CreateSecrets
    })
  const { data: viewPermission, isLoading: isViewPermissionLoading } =
    useCheckPermissions({
      resourceType: ResourceType.Project,
      resourceId: projectId!,
      permission: PermissionsType.ViewSecrets
    })

  const sortedCPCs = useMemo(
    () => sortByColumn(cpcData, sortState),
    [cpcData, sortState]
  )

  const redirectCallback = () => {
    navigate(AppRoutes.getCreateCloudProviderCredential(projectId!))
  }

  const handleDelete = (name: string, isSuccess = true) => {
    if (isSuccess) {
      makeToast({
        title: t(
          'secrets.deleteCloudProviderCredential.responseMessage.success',
          {
            credentialName: name
          }
        ),
        message: '',
        type: 'success'
      })
    } else {
      makeToast({
        title: t(
          'secrets.deleteCloudProviderCredential.responseMessage.error',
          {
            credentialName: name
          }
        ),
        message: '',
        type: 'error'
      })
    }
  }

  const getContent = () => {
    return isCPCError ? (
      <Typography data-testid="cloud-provider-credentials-fail-support-text">
        {t('secrets.cloudProviderCredentialsListing.error')}
      </Typography>
    ) : (
      <CloudProviderCredentialsDataGrid
        credentials={sortedCPCs!}
        onSort={setSortState}
        onDeleteHandler={handleDelete}
      />
    )
  }

  useEffect(() => {
    sortByColumn(cpcData, sortState)

    return () => {
      queryClient.removeQueries([
        QueryKey.USER_PERMISSIONS,
        PermissionsType.CreateSecrets
      ])
      queryClient.removeQueries([
        QueryKey.USER_PERMISSIONS,
        PermissionsType.DeleteSecrets
      ])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (
    !cpcData.length &&
    !isCPCError &&
    !isCPCLoading &&
    !isViewPermissionLoading &&
    !isCreatePermissionLoading
  ) {
    if (!createPermission) {
      return <NoCreatePermission />
    } else {
      return <NoData onAdd={redirectCallback} />
    }
  }

  return (
    <>
      <ListingHeader onAdd={redirectCallback} permission={createPermission} />
      {isCPCLoading ||
      isViewPermissionLoading ||
      (isCreatePermissionLoading && !cpcData.length) ? (
        <LoadingSpinner />
      ) : (
        <>{!viewPermission ? <NoReadPermission /> : getContent()}</>
      )}
    </>
  )
}

export default CloudProviderCredentialsListing
