import { type FC } from 'react'
import { useTranslation } from 'react-i18next'

import classnames from 'classnames'

import { Badge, Typography } from '@matillion/component-library'

import { AgentStatus } from 'api/types'

import { StreamingPipelineStatus } from 'types/Streaming'

import classes from './StatusBadge.module.scss'

interface StatusBadgeProps {
  status: StreamingPipelineStatus | AgentStatus
  className?: string
  size?: 'regular' | 'small'
}

const translationMappings = {
  [StreamingPipelineStatus.STARTING]: 'streamingStatus.starting',
  [StreamingPipelineStatus.SNAPSHOTTING]: 'streamingStatus.snapshotting',
  [StreamingPipelineStatus.STREAMING]: 'streamingStatus.streaming',
  [StreamingPipelineStatus.STOPPING]: 'streamingStatus.stopping',
  [StreamingPipelineStatus.NOT_RUNNING]: 'streamingStatus.notRunning',
  [StreamingPipelineStatus.UNKNOWN]: 'streamingStatus.unknown',
  [StreamingPipelineStatus.ERROR]: 'streamingStatus.error',
  [AgentStatus.PENDING]: 'agentStatus.pending',
  [AgentStatus.RUNNING]: 'agentStatus.running',
  [AgentStatus.UNKNOWN]: 'agentStatus.unknown',
  [AgentStatus.STOPPED]: 'agentStatus.stopped'
}

type validBadgeColours = 'red' | 'green' | 'blue' | 'grey' | 'yellow'

export const StatusBadge: FC<StatusBadgeProps> = ({
  status,
  className,
  size = 'regular'
}) => {
  const { t } = useTranslation()

  let colour: validBadgeColours
  switch (status) {
    case StreamingPipelineStatus.ERROR:
      colour = 'red'
      break
    case StreamingPipelineStatus.STARTING:
    case StreamingPipelineStatus.SNAPSHOTTING:
    case StreamingPipelineStatus.STOPPING:
    case AgentStatus.RUNNING:
      colour = 'green'
      break
    case StreamingPipelineStatus.STREAMING:
      colour = 'blue'
      break
    default:
      colour = 'grey'
  }

  if (size === 'small') {
    return (
      <Typography
        as="span"
        format="mc"
        weight="bold"
        className={classnames(
          className,
          classes.StatusBadge__Small,
          classes[`StatusBadge--${colour}`]
        )}
        data-testid="status-badge"
      >
        {t(translationMappings[status])}
      </Typography>
    )
  }

  return (
    <Badge
      theme="filled"
      className={className}
      colour={colour}
      data-testid="status-badge"
    >
      {t(translationMappings[status])}
    </Badge>
  )
}
