import { type ChangeEvent, type FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'

import classNames from 'classnames'
import { useFormikContext } from 'formik'

import { Field, Textarea } from '@matillion/component-library'

import classes from 'components/Form/Form.module.scss'

import { type ProjectDetailsI } from 'types/FormTypes'

type FormikValueTypes = Pick<ProjectDetailsI, 'description'>

const Description: FunctionComponent = () => {
  const { t } = useTranslation()
  const { errors, handleBlur, handleChange, touched, values } =
    useFormikContext<FormikValueTypes>()

  return (
    <div data-testid="add-new-project-description">
      <Field
        title={t('fields.description.title')}
        optionalLabelText={t('fields.description.titleSuffix')}
        inputComponent={Textarea}
        name="description"
        data-testid="add-new-project-description-input"
        value={values.description}
        placeholder={t('fields.description.placeholderText')}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          handleChange(e)
        }}
        onBlur={handleBlur}
        className={classNames(classes.Form__SpacingStyles)}
        supportText={t('fields.description.supportText')}
        errorText={
          errors?.description && touched.description
            ? t(errors.description)
            : null
        }
        hasError={!!errors?.description && touched.description}
        maxLength={1000}
      />
    </div>
  )
}

export default Description
