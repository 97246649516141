import { type FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'

import classNames from 'classnames'

import classes from 'components/Form/Form.module.scss'
import { Header, HeaderTitle } from 'components/Header'

import {
  Account,
  Username,
  WarehouseAgentCredentials
} from 'modules/Common/Fields'
import Port from 'modules/Common/WarehouseSources/Redshift/Credentials/Fields/Port'
import Ssl from 'modules/Common/WarehouseSources/Redshift/Credentials/Fields/Ssl'

import { WarehouseLabels } from 'types'

interface RedshiftCredentialsProps {
  onCancel?: () => void
  onPrevious?: () => void
  showCancel?: boolean
  showPrevious?: boolean
  modalContent?: string
  excludeHeader?: boolean
}

export const RedshiftCredentials: FunctionComponent<
  RedshiftCredentialsProps
> = ({
  onCancel,
  onPrevious,
  showCancel = true,
  showPrevious = false,
  modalContent,
  excludeHeader = false
}) => {
  const { t } = useTranslation()

  const fields = (
    <>
      <Account translationProxy={'endpoint'} />
      <Port />
      <Ssl />
      <Username />
      <WarehouseAgentCredentials />
    </>
  )

  if (excludeHeader) {
    return fields
  }
  return (
    <>
      <Header
        onCancel={onCancel}
        showCancel={showCancel}
        onPrevious={onPrevious}
        showPrevious={showPrevious}
        modalContent={modalContent}
      >
        <HeaderTitle data-testid="specify-credentials-title">
          {t('formContent.credentials.title', {
            projectType: WarehouseLabels.Redshift
          })}
        </HeaderTitle>
      </Header>
      <div
        className={classNames(
          classes.Form__InnerWrap,
          classes['Form__InnerWrap--space-top']
        )}
      >
        {fields}
      </div>
    </>
  )
}
