import { type FunctionComponent, type PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'

import classNames from 'classnames'

import {
  Section,
  Typography,
  type TypographyProps
} from '@matillion/component-library'

import { CancelAction } from 'components/CancelAction'
import { ReturnLink } from 'components/ReturnLink'

import classes from './Header.module.scss'

interface HeaderTitleProps {
  'data-testid'?: string
  className?: string
  format?: TypographyProps['format']
  centerAlign?: boolean
  headerElement?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
  withBottomSpace?: boolean
}

interface HeaderProps {
  fullWidth?: boolean
  centerAlign?: boolean
  showPrevious?: boolean
  showCancel?: boolean
  onPrevious?: () => void
  onCancel?: () => void
  modalContent?: string
  modalCancel?: string
  modalValidate?: string
  className?: string
  'data-testid'?: string
}

export const HeaderTitle: FunctionComponent<
  PropsWithChildren<HeaderTitleProps>
> = ({
  'data-testid': dataTestId,
  className,
  format = 'dts',
  centerAlign = true,
  headerElement = 'h1',
  children,
  withBottomSpace = true
}) => {
  return (
    <Typography
      as={headerElement}
      format={format}
      className={classNames(
        classes.Header__Title,
        {
          [classes['Header__Title--bottom-space']]: withBottomSpace,
          [classes['Header__Title--align-center']]: centerAlign
        },
        className
      )}
      data-testid={dataTestId}
    >
      {children}
    </Typography>
  )
}

export const HeaderWithCTA: FunctionComponent<
  PropsWithChildren<HeaderTitleProps>
> = ({ children }) => {
  return (
    <div className={classNames(classes.Header__CTAWrapper)}>{children}</div>
  )
}

export const Header: FunctionComponent<PropsWithChildren<HeaderProps>> = ({
  fullWidth = false,
  centerAlign = true,
  showPrevious = false,
  showCancel = false,
  onPrevious,
  onCancel,
  modalContent,
  modalCancel,
  modalValidate,
  className,
  children,
  'data-testid': dataTestId
}) => {
  const { t } = useTranslation()

  return (
    <Section
      size={12}
      subgrid
      className={classNames(classes.Header)}
      data-testid={dataTestId}
    >
      {!fullWidth && (
        <Section size={2} noPadding className={classes.Header__ActionLeft}>
          {showPrevious && <ReturnLink text="Previous" onClick={onPrevious} />}
        </Section>
      )}
      <Section
        size={fullWidth ? 12 : 6}
        offset={fullWidth ? 0 : 1}
        noPadding
        className={classNames(
          classes.Header__TitleSlot,
          {
            [classes['Header__TitleSlot--align-center']]: centerAlign
          },
          className
        )}
      >
        {children}
      </Section>
      {!fullWidth && (
        <Section
          size={2}
          offset={1}
          noPadding
          className={classes.Header__ActionRight}
        >
          {showCancel && onCancel && (
            <CancelAction
              onConfirm={onCancel}
              content={modalContent ?? t('modal.discardEditor.content')}
              cancel={modalCancel ?? t('modal.discardEditor.cancel')}
              validate={modalValidate ?? t('modal.discardEditor.validate')}
            />
          )}
        </Section>
      )}
    </Section>
  )
}
