import { type ReactNode } from 'react'

import classNames from 'classnames'

import {
  LoadingSpinner,
  type AutoCompleteItemId,
  type BadgeProps
} from '@matillion/component-library'

import { ClusterState } from 'api/createProjectForm/types'

import classes from 'modules/Common/WarehouseSources/Databricks/Defaults/Fields/Compute.module.scss'

export const getStatusProps = (
  compute: AutoCompleteItemId
): {
  className?: string
  colour?: BadgeProps['colour']
  children: ReactNode
} => {
  switch (compute.state) {
    case ClusterState.Pending:
    case ClusterState.Restarting:
    case ClusterState.Starting:
      return {
        colour: 'blue',
        children: (
          <div>
            <LoadingSpinner className={classNames(classes.StartingUp)} />
            Starting up...
          </div>
        )
      }

    case ClusterState.Started:
    case ClusterState.Running:
    case ClusterState.Resizing:
      return {
        colour: 'green',
        children: 'Running'
      }

    case ClusterState.Terminated:
    case ClusterState.Stopped:
    case ClusterState.Terminating:
    case ClusterState.Stopping:
    case ClusterState.Deleting:
      return {
        colour: 'red',
        children: 'Stopped'
      }

    case ClusterState.Unknown:
    case ClusterState.Deleted:
    case ClusterState.Error:
    default:
      return {
        colour: 'red',
        children: 'Error'
      }
  }
}
