import { type FunctionComponent } from 'react'
import { useNavigate } from 'react-router-dom'

import { Button, Tooltip, Typography } from '@matillion/component-library'

import { AppRoutes } from 'constants/route'

import classes from './PipelineNameCell.module.scss'

export interface PipelineNameCellProps {
  name: string
  projectId: string
  pipelineId: string
}

const PipelineNameCell: FunctionComponent<PipelineNameCellProps> = ({
  name,
  projectId,
  pipelineId
}) => {
  const navigate = useNavigate()

  return (
    <Tooltip content={name}>
      <Button
        alt="text"
        onClick={() => {
          navigate(
            AppRoutes.getStreamingPipelineDashboard(projectId, pipelineId)
          )
        }}
        data-testid="pipeline-name-button"
        className={classes.PipelineNameCell__Button}
      >
        <Typography format="bcm" data-testid="pipeline-name-text">
          {name}
        </Typography>
      </Button>
    </Tooltip>
  )
}

export default PipelineNameCell
