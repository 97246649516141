import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'

import { type AxiosError } from 'axios'

import { Toaster } from '@matillion/component-library'

import { postValidateDestinationConfiguration } from 'api/clients'
import { useNetworkErrorToast, useStreamingClient } from 'api/hooks/index'
import {
  type ErrorResponse,
  type POSTValidateDestinationConfigurationResponse
} from 'api/types'

import { QueryKey } from 'constants/endpoint'

import { type DestinationConfigType } from 'types/Streaming'

type TError = AxiosError<ErrorResponse>

interface TVariables {
  agentId: string
  target: DestinationConfigType
}

export const usePostValidateDestinationConfiguration = () => {
  const streamingClient = useStreamingClient()
  const makeErrorToast = useNetworkErrorToast()
  const { clearToasts, makeToast } = Toaster.useToaster()
  const { t } = useTranslation()

  return useMutation<
    POSTValidateDestinationConfigurationResponse,
    TError,
    TVariables
  >({
    mutationKey: [QueryKey.STREAMING_VALIDATE_DESTINATION_CONFIGURATION],
    mutationFn: async ({ target, agentId }) => {
      makeToast({
        title: t('streamingActions.validateConfiguration.sending'),
        message: '',
        type: 'info'
      })
      return postValidateDestinationConfiguration(
        { agentId },
        target,
        streamingClient
      )
    },
    onError: (error) => {
      makeErrorToast({
        message: error.response?.data.detail ?? error.message
      })
    },
    onSuccess: (response: POSTValidateDestinationConfigurationResponse) => {
      clearToasts()
      // the response may be 2XX with 'success = false' because validation failed
      if (!response.success) {
        makeErrorToast({
          message: response.message
        })
      } else {
        makeToast({
          title: t('streamingActions.validateConfiguration.success'),
          message: '',
          type: 'success'
        })
      }
    }
  })
}
