import { useMutation, type UseMutationOptions } from 'react-query'

import { type AxiosError } from 'axios'

import { getDatabricksComputeResources } from 'api/createProjectForm/client'
import type { GETDatabricksComputeResourceRequest } from 'api/createProjectForm/types/DatabricksComputeResourcesRequest'
import type { GETDatabricksComputeResourcesResponse } from 'api/createProjectForm/types/DatabricksComputeResourcesResponse'
import { useClient } from 'api/hooks/useClient'

import { QueryKey } from 'constants/endpoint'

type TData = GETDatabricksComputeResourcesResponse
type TError = AxiosError<unknown>

interface TVariables {
  values: GETDatabricksComputeResourceRequest
}

type Options = UseMutationOptions<TData, AxiosError, TVariables>

interface MutateDatabricksComputeResourcesProps {
  options?: Options
  showErrorDetailMessage?: boolean
}

export const useMutateDatabricksComputeResources = ({
  options,
  showErrorDetailMessage = false
}: MutateDatabricksComputeResourcesProps = {}) => {
  const client = useClient({ showErrorDetailMessage })

  return useMutation<TData, TError, TVariables>(
    [QueryKey.DATABRICKS_COMPUTE_RESOURCES],
    async (data: TVariables) => {
      const { values } = data
      return getDatabricksComputeResources(values, client)
    },
    options
  )
}
