import { useEffect, useState, type FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

import { Loader, Toaster } from '@matillion/component-library'

import { useGetMha, useGetProject, usePostSchedule } from 'api/hooks'
import { createScheduleMutationData } from 'api/mutation'

import Form from 'components/Form'

import { CREATE_SCHEDULE } from 'constants/persistance'
import { AppRoutes, PROJECT_DETAILS_SCHEDULES } from 'constants/route'

import { useHandleScheduleSubmit } from 'hooks'
import { useFlags } from 'hooks/flags'

import { validationSchema } from 'modules/Projects/CreateSchedule/FormContent'
import CreateScheduleContent from 'modules/Projects/CreateSchedule/FormContent/CreateScheduleContent'
import {
  generateCronFromInputs,
  getInitialValues
} from 'modules/Projects/CreateSchedule/FormContent/CreateScheduleContent.util'
import { type ScheduleFormValues } from 'modules/Projects/CreateSchedule/types'
import { isMHARunning } from 'modules/utils'

import { AgentsSecretsHost, ScheduleDisplayMode } from 'types'

const CreateScheduleForm: FunctionComponent = () => {
  const translationPrefix = 'createSchedule'
  const browserSessionData = window.sessionStorage.getItem(CREATE_SCHEDULE)
  const { t } = useTranslation()
  const { projectId } = useParams()
  const navigate = useNavigate()
  const {
    data: projectData = {
      agentAndSecretHostLocation: AgentsSecretsHost.CustomerHosted
    },
    isLoading: isProjectLoading
  } = useGetProject(projectId!)
  const {
    data: mhaData = { status: undefined, agentId: undefined },
    isError: mhaError,
    isLoading: mhaLoading
  } = useGetMha({
    options: {
      enabled:
        projectData.agentAndSecretHostLocation ===
        AgentsSecretsHost.MatillionHosted
    }
  })
  const { mutateAsync: mutateAsyncSchedule } = usePostSchedule(projectId!)
  const { makeToast } = Toaster.useToaster()
  const [submitForm] = useHandleScheduleSubmit<ScheduleFormValues>()

  const {
    enableBasicAndAdvancedScheduleLayout,
    enableStartTimeInputScheduleOptions
  } = useFlags()
  const initialValues = getInitialValues({
    preloadedValues: browserSessionData && JSON.parse(browserSessionData),
    enableStandardMode: Boolean(enableBasicAndAdvancedScheduleLayout)
  })
  const [scheduleInitialValues, setScheduleInitialValues] =
    useState(initialValues)

  useEffect(() => {
    if (
      projectData.agentAndSecretHostLocation ===
      AgentsSecretsHost.MatillionHosted
    ) {
      const status = mhaData?.status
      if (isMHARunning(status) && mhaData.agentId) {
        const matillionHostedAgentId = mhaData.agentId
        setScheduleInitialValues({
          ...initialValues,
          agent: {
            id: matillionHostedAgentId,
            name: 'Matillion Hosted Agent'
          }
        })
      }

      if (!isMHARunning(status) && !mhaLoading) {
        makeToast({
          title: t('createSchedule.error.mhaNotReady'),
          message: '',
          type: 'error'
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    mhaData,
    projectData.agentAndSecretHostLocation,
    mhaLoading,
    makeToast,
    t
  ])
  useEffect(() => {
    if (
      projectData.agentAndSecretHostLocation ===
      AgentsSecretsHost.MatillionHosted
    ) {
      if (mhaError) {
        makeToast({
          title: t('createSchedule.error.getMhaError'),
          message: '',
          type: 'error'
        })
      }
    }
  }, [projectData.agentAndSecretHostLocation, mhaError, makeToast, t])

  const publishSchedule = async (
    values: ScheduleFormValues,
    onPublishStatus: (success: boolean, scheduleName?: string) => void,
    onFinish: () => void
  ) =>
    mutateAsyncSchedule({
      values: createScheduleMutationData(
        {
          ...values,
          schedule: {
            ...values.schedule,
            cronExpression:
              values.schedule.displayMode === ScheduleDisplayMode.BASIC
                ? generateCronFromInputs(values)
                : values.schedule.cronExpression
          }
        },
        true,
        !!enableStartTimeInputScheduleOptions
      )
    })
      .then((_res) => {
        onPublishStatus(true, values.name)
      })
      .catch((_res) => {
        onPublishStatus(false)
      })
      .finally(onFinish)

  const handleOnCancel = () => {
    navigate(AppRoutes.getProjectDetails(projectId!, PROJECT_DETAILS_SCHEDULES))
  }

  if (isProjectLoading) {
    return <Loader />
  }

  return (
    <Form<ScheduleFormValues>
      formikValues={{
        initialValues: scheduleInitialValues,
        initialTouched: false,
        validationSchema: validationSchema,
        onSubmit: async (formValues) =>
          submitForm(formValues, publishSchedule, translationPrefix),
        validateOnMount: true,
        enableReinitialize: true
      }}
      translationPrefix={translationPrefix}
      persistingStorageId={CREATE_SCHEDULE}
    >
      <CreateScheduleContent
        showCancel
        onCancel={handleOnCancel}
        modalContent={t('createSchedule.modal.content')}
      />
    </Form>
  )
}

export default CreateScheduleForm
