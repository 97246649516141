import { type FunctionComponent } from 'react'

import { StatusBadge } from 'components/Streaming/StatusBadge/StatusBadge'

import StatusEnhancementText from 'modules/Projects/StreamingPipelineDashboard/Header/components/StatusEnhancementText/StatusEnhancementText'
import classes from 'modules/Projects/StreamingPipelineDashboard/Header/components/StreamingDashboardStatus/StreamingDashboardStatus.module.scss'

import {
  StreamingPipelineStatus,
  type StreamingPipelineMetrics
} from 'types/Streaming'

interface StreamingDashboardStatusProps {
  status: StreamingPipelineStatus
  metrics?: StreamingPipelineMetrics
}

const StreamingDashboardStatus: FunctionComponent<
  StreamingDashboardStatusProps
> = ({ status, metrics }) => {
  return (
    <div
      className={classes.StreamingDashboardStatus__Container}
      data-testid="pipeline-status-tag"
    >
      <StatusBadge
        status={status}
        className={
          status === StreamingPipelineStatus.NOT_RUNNING ||
          status === StreamingPipelineStatus.UNKNOWN
            ? classes.StreamingDashboardStatus__GreyOutline
            : undefined
        }
      />
      <StatusEnhancementText status={status} metrics={metrics} />
    </div>
  )
}

export default StreamingDashboardStatus
