import { type FC } from 'react'
import { useTranslation } from 'react-i18next'

import { Typography } from '@matillion/component-library'

import { SupportText } from 'modules/Projects/CreateStreamingPipeline/FormContent/ConfigurePipelineDetailsPage/Components/SupportText/SupportText'

import classes from './SnapshotConfigHeader.module.scss'

export interface SnapshotConfigHeaderProps {
  dataTestId?: string
}

export const SnapshotConfigHeader: FC<SnapshotConfigHeaderProps> = ({
  dataTestId
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'createStreamingPipeline.fields.pipelineConfig.snapshotHeader'
  })
  return (
    <div data-testid={dataTestId}>
      <Typography
        format="bcs"
        weight="bold"
        className={classes.SnapshotConfigHeader__Title}
      >
        {t('title')}{' '}
        <Typography
          format="bcs"
          weight="bold"
          className={classes.SnapshotConfigHeader__Optional}
          as="span"
        >
          {t('optionalLabelText')}
        </Typography>
      </Typography>
      <SupportText
        supportText={t('supportText')}
        supportDocs={t('supportDocs')}
      />
    </div>
  )
}
