import { Typography, type FieldWidget } from '@matillion/component-library'

import { useGetEnvironmentAgents } from 'api/hooks'

import { useFlags } from 'hooks/flags'

import {
  agentCloudProviderName,
  type AgentCloudProvider
} from 'types/AgentCloudProvider'

export const useAgentCloudProviderFieldWidget = () => {
  const { enableAgentIconsAndText } = useFlags()

  const agentCloudProviderLabel = (agentCloudProvider: AgentCloudProvider) => {
    const agentType = agentCloudProviderName[agentCloudProvider]
    return {
      label: (
        <Typography format="mc" data-testid="status-badge-row-item">
          {agentType}
        </Typography>
      ),
      visible: true
    }
  }

  const { data: listOfAgents = [] } = useGetEnvironmentAgents({
    retryOnMount: false
  })

  const agentCloudProviderFieldWidget = () => {
    if (!enableAgentIconsAndText) return null

    return new Map<string, FieldWidget>(
      listOfAgents.map((item) => [
        item.agentId,
        agentCloudProviderLabel(
          item.cloudProviderId.toString() as AgentCloudProvider
        )
      ])
    )
  }

  return { agentCloudProviderFieldWidget }
}
