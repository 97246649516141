import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from 'react-query'

import { type AxiosError } from 'axios'

import { Toaster } from '@matillion/component-library'

import { deleteStreamingPipeline } from 'api/clients'
import { useNetworkErrorToast, useStreamingClient } from 'api/hooks/index'
import {
  type ErrorResponse,
  type GETStreamingPipelinesResponse
} from 'api/types'

import { QueryKey } from 'constants/endpoint'

type TError = AxiosError<ErrorResponse>

interface TVariables {
  pipelineId: string
  pipelineName: string
}

export const useDeleteStreamingPipeline = (projectId: string) => {
  const queryClient = useQueryClient()
  const streamingClient = useStreamingClient()
  const makeErrorToast = useNetworkErrorToast()
  const { makeToast } = Toaster.useToaster()
  const { t } = useTranslation()

  const listPipelinesQueryKey = [QueryKey.STREAMING_PIPELINES_LIST, projectId]

  return useMutation<unknown, TError, TVariables>({
    mutationKey: [QueryKey.STREAMING_PIPELINE_DELETE, projectId],
    mutationFn: async ({ pipelineId }) => {
      await deleteStreamingPipeline({ projectId, pipelineId }, streamingClient)
    },
    onMutate: async ({ pipelineId }) => {
      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({
        queryKey: listPipelinesQueryKey
      })

      // optimistically update to the new value
      queryClient.setQueryData(
        listPipelinesQueryKey,
        (old?: GETStreamingPipelinesResponse) =>
          old?.filter((item) => item.id !== pipelineId) ?? []
      )
    },
    onError: (error) => {
      makeErrorToast({ message: error.response?.data.detail ?? error.message })
      // trigger a refresh to roll back the optimistic update
      queryClient.invalidateQueries({ queryKey: listPipelinesQueryKey })
    },
    onSuccess: (data, { pipelineName }) => {
      makeToast({
        title: t('streamingActions.delete.success', { pipelineName }),
        message: '',
        type: 'success'
      })
      // invalidate Assigned Agents list so the Agent assigned to the deleted pipeline is unlocked
      queryClient.invalidateQueries({
        queryKey: [QueryKey.STREAMING_ASSIGNED_AGENTS_LIST, projectId]
      })
    }
  })
}
