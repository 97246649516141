import { useQuery, type UseQueryOptions } from 'react-query'

import { type AxiosError } from 'axios'

import { getStreamingPipelines } from 'api/clients'
import { useNetworkErrorToast, useStreamingClient } from 'api/hooks/index'
import { type GETStreamingPipelinesResponse } from 'api/types'

import { QueryKey } from 'constants/endpoint'

type TData = GETStreamingPipelinesResponse
type TError = AxiosError<unknown>
type TOptions = UseQueryOptions<TData, TError, TData>

export const useGetStreamingPipelines = (
  projectId?: string,
  options?: TOptions
) => {
  const streamingClient = useStreamingClient()
  const makeErrorToast = useNetworkErrorToast()

  return useQuery<TData, TError>(
    [QueryKey.STREAMING_PIPELINES_LIST, projectId],
    // @ts-expect-error 'enabled' property below disables request if projectId is undefined
    async () => getStreamingPipelines({ projectId }, streamingClient),
    {
      ...options,
      enabled: !!projectId,
      onError: (error) => {
        makeErrorToast({ message: error.message })
      }
    }
  )
}
