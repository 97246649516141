import { type FC } from 'react'
import { useTranslation } from 'react-i18next'

import { Field as FormikField, useFormikContext, type FieldProps } from 'formik'

import { Field } from '@matillion/component-library'

import {
  type FormValues,
  type SnowflakeDestinationFormValues
} from 'modules/Projects/CreateStreamingPipeline/FormContent'
import { AdditionalConfig } from 'modules/Projects/CreateStreamingPipeline/FormContent/ConfigurePipelineDetailsPage/Components/Properties/AdditionalConfig/AdditionalConfig'
import { FIELD_NAMES_SNOWFLAKE_JDBC_PROPERTIES } from 'modules/Projects/CreateStreamingPipeline/FormContent/ConfigurePipelineDetailsPage/types'

export const SnowflakeJdbcParameters: FC = () => {
  const { t } = useTranslation('translation', {
    keyPrefix:
      'createStreamingPipeline.fields.destinationConfig.snowflake.jdbcProperties'
  })
  const { values } = useFormikContext<FormValues>()

  return (
    <FormikField name={FIELD_NAMES_SNOWFLAKE_JDBC_PROPERTIES}>
      {({ field, meta }: FieldProps<string>) => (
        <Field
          {...field}
          inputComponent={AdditionalConfig}
          title={t('title')}
          optionalLabelText={t('optionalLabelText')}
          fieldName={FIELD_NAMES_SNOWFLAKE_JDBC_PROPERTIES}
          additionalConfig={
            (values.target as SnowflakeDestinationFormValues).connection
              .jdbcProperties
          }
          modalHeading={t('modal.heading')}
          dataTestId="configure-streaming-pipeline-snowflake-jdbc-properties"
        />
      )}
    </FormikField>
  )
}
