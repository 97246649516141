import { type FC } from 'react'

import { useFormikContext } from 'formik'

import { Typography } from '@matillion/component-library'

import { useFetchStreamingSourceTables } from 'api/hooks/streaming'

import { NO_DATA } from 'constants/strings'

import {
  mapSourceConnectionFormToDefinition,
  useFormMetadata,
  type FormValues
} from 'modules/Projects/CreateStreamingPipeline/FormContent/index'

import classes from './TablesSelectedRowContent.module.scss'

export interface TablesSelectedRowContentProps {
  schema: string
  tablesSelected: number
}

export const TablesSelectedRowContent: FC<TablesSelectedRowContentProps> = ({
  schema,
  tablesSelected
}) => {
  const { metadata, setMetadataProperty } = useFormMetadata()
  const { values } = useFormikContext<FormValues>()
  const { mutateAsync: mutateFetchTables, isLoading } =
    useFetchStreamingSourceTables()

  let content = NO_DATA
  if (tablesSelected > 0) {
    if (metadata.source.schemas[schema].tables === undefined && !isLoading) {
      content = `${tablesSelected} / ${NO_DATA}`
      mutateFetchTables({
        agentId: values.agent.id,
        type: values.source.type.id,
        connection: mapSourceConnectionFormToDefinition(
          values.source.connection
        ),
        schema: schema
      }).then((response) => {
        setMetadataProperty(`source.schemas.${schema}.tables`, response)
      })
    } else {
      content = `${tablesSelected} / ${metadata.source.schemas[schema].tables?.length ?? NO_DATA}`
    }
  }

  return (
    <div className={classes.TablesSelectedRowContent__RowWrapper}>
      <Typography format="bcs" data-testid="tables-selected-row-content">
        {content}
      </Typography>
    </div>
  )
}
