import { useTranslation } from 'react-i18next'
import { type UseQueryOptions } from 'react-query'

import { type AxiosError } from 'axios'

import { useNetworkErrorToast } from 'api/hooks'
import {
  AgentStatus,
  type GETAssignedStreamingAgentIdsResponse,
  type GETEnvironmentAgentsResponse
} from 'api/types'

import { useGetAssignedStreamingAgentIds } from './useGetAssignedStreamingAgentIds'
import { useGetStreamingAgents } from './useGetStreamingAgents'

type TError = AxiosError<unknown>
type TAssignedAgentOptions = UseQueryOptions<
  GETAssignedStreamingAgentIdsResponse,
  TError,
  GETAssignedStreamingAgentIdsResponse
>
type TAgentOptions = UseQueryOptions<
  GETEnvironmentAgentsResponse,
  TError,
  GETEnvironmentAgentsResponse
>

export const useGetAssignableStreamingAgents = (
  getAssignedAgentsOptions?: TAssignedAgentOptions,
  getAgentsOptions?: TAgentOptions
) => {
  const makeErrorToast = useNetworkErrorToast()
  const { t } = useTranslation()
  const {
    data: assignedAgents = { agentIds: [] },
    isLoading: isLoadingAssignedAgents,
    isError: isAssignedAgentsError,
    isSuccess: assignedAgentsSuccess
  } = useGetAssignedStreamingAgentIds(getAssignedAgentsOptions)
  const {
    data: agents = [],
    isLoading: isLoadingAgents,
    isError: isAgentsError,
    isSuccess: agentsSuccess
  } = useGetStreamingAgents(AgentStatus.RUNNING, getAgentsOptions)

  const assignableAgents = agents.filter((agent) => {
    return !assignedAgents.agentIds.includes(agent.agentId)
  })

  if (
    !isLoadingAgents &&
    !isLoadingAssignedAgents &&
    (isAgentsError || isAssignedAgentsError)
  ) {
    makeErrorToast({
      message: t('streamingActions.loadingAgents.error')
    })
  }

  return {
    data: assignableAgents,
    isLoading: isLoadingAssignedAgents || isLoadingAgents,
    isError: isAssignedAgentsError || isAgentsError,
    isSuccess: assignedAgentsSuccess && agentsSuccess
  }
}
