import { type FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'

import { Field as FormikField, type FieldProps } from 'formik'

import {
  AutoComplete,
  Field,
  type FieldWidget
} from '@matillion/component-library'

import {
  type EnvironmentAgent,
  type GETEnvironmentAgentsResponse
} from 'api/types'

import { StatusBadge } from 'components/Streaming/StatusBadge/StatusBadge'

import classes from 'modules/Projects/CreateStreamingPipeline/FormContent/CreatePipelinePage/Fields/Agent/Agent.module.scss'
import { AgentSupportText } from 'modules/Projects/CreateStreamingPipeline/FormContent/CreatePipelinePage/Fields/Agent/AgentSupportText'
import { FIELD_NAMES_AGENT } from 'modules/Projects/CreateStreamingPipeline/FormContent/CreatePipelinePage/Fields/types'
import {
  hasErrorOrIsEmpty,
  resolveErrorText
} from 'modules/Projects/CreateStreamingPipeline/FormContent/index'

interface AgentProps {
  agents: GETEnvironmentAgentsResponse
  isAgentsLoading: boolean
  disabled: boolean
}

export const Agent: FunctionComponent<AgentProps> = ({
  agents,
  isAgentsLoading,
  disabled
}) => {
  const { t } = useTranslation()
  const sortedAgents = agents
    .map((agent: EnvironmentAgent) => ({
      id: agent.agentId,
      name: agent.name
    }))
    .sort((a, b) => a.name.localeCompare(b.name))

  const widgets = new Map<string, FieldWidget>()
  agents.forEach((agent) => {
    widgets.set(agent.agentId, {
      label: (
        <StatusBadge
          status={agent.status}
          size="small"
          className={classes.Agent__Status}
        />
      ),
      visible: true
    })
  })

  return (
    <FormikField name={FIELD_NAMES_AGENT}>
      {({
        field,
        meta,
        form: { values, isSubmitting }
      }: FieldProps<string>) => (
        <div data-testid="configure-streaming-pipeline-agent">
          <Field
            {...field}
            data-testid="configure-streaming-pipeline-agent-input"
            title={t('createStreamingPipeline.fields.agent.title')}
            loading={isAgentsLoading}
            placeholder={
              !isAgentsLoading && sortedAgents.length === 0
                ? t('createStreamingPipeline.fields.agent.noAgents')
                : undefined
            }
            supportText={<AgentSupportText />}
            name={FIELD_NAMES_AGENT}
            inputComponent={AutoComplete}
            availableItems={sortedAgents}
            hasError={hasErrorOrIsEmpty({
              values,
              fieldName: FIELD_NAMES_AGENT,
              meta,
              fieldHasId: true
            })}
            errorText={resolveErrorText(t, meta)}
            disabled={
              disabled ||
              isSubmitting ||
              isAgentsLoading ||
              sortedAgents.length === 0
            }
            givenWidgets={widgets}
          />
        </div>
      )}
    </FormikField>
  )
}
