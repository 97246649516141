import { useMutation, type UseMutationOptions } from 'react-query'

import { type AxiosError } from 'axios'

import { deleteSchedule } from 'api/clients'
import { type DELETEScheduleValues } from 'api/types'

import { useClient } from './useClient'

type Options = UseMutationOptions<
  void,
  AxiosError,
  {
    values: DELETEScheduleValues
  }
>

export const useDeleteSchedule = (options?: Options) => {
  const client = useClient()

  return useMutation(async (data: { values: DELETEScheduleValues }) => {
    const { values } = data
    return deleteSchedule({ ...values }, client)
  }, options)
}
