import { useFormikContext } from 'formik'

import type { ScheduleFormValues } from 'modules/Projects/CreateSchedule/types'

import { checkIfDateTimeInThePast } from 'utils/checkIfDateTimeInThePast/checkIfDateTimeInThePast'
import { nowPlus2Minutes } from 'utils/nowPlus2Minutes'

interface DateTimeProps {
  customDate?: string
  customTime?: string
}

export const useHandleSchedulePastDateTime = () => {
  const today = nowPlus2Minutes()
  const {
    values: { schedule: { startDate, timezone } } = {
      schedule: { startDate: undefined }
    },
    setFieldValue
  } = useFormikContext<ScheduleFormValues>()

  const resetTime = () => {
    if (!timezone?.id) return
    const currentTimeAtTimezone = today.toLocaleTimeString('en-GB', {
      timeZone: timezone.id,
      hour: '2-digit',
      minute: '2-digit'
    })
    const [day, month, year] = today
      .toLocaleDateString('en-GB', {
        timeZone: timezone.id
      })
      .split('/')

    setFieldValue('schedule.startTime', currentTimeAtTimezone)
    setFieldValue('schedule.startDate', `${year}-${month}-${day}`)
  }

  const handlePastDateTime = ({ customDate, customTime }: DateTimeProps) => {
    const date = customDate ?? startDate
    const time = customTime
    if (
      date &&
      checkIfDateTimeInThePast({
        customDate: date,
        customTime: time,
        dateToCompare: today,
        offset: timezone?.utc
      })
    ) {
      resetTime()
    }
  }

  return { handlePastDateTime, resetTime }
}
