import timezonesList from 'timezones-list'

export interface TimezoneElementTypes {
  id: string
  name: string
}

export const DEFAULT_TIMEZONE = {
  id: 'Europe/London',
  name: 'Europe/London'
}

export interface TimezoneListTypes {
  label: string
  tzCode: string
  name: string
  utc: string
}

export const getDefaultTimezone = (): TimezoneElementTypes => {
  let defaultValue
  try {
    // see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/resolvedOptions
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone

    const mainUserTimeZone = timezonesList.find(
      (e) => e.tzCode === userTimezone
    )

    defaultValue = mainUserTimeZone
      ? {
          id: mainUserTimeZone.tzCode,
          name: mainUserTimeZone.tzCode
        }
      : undefined
  } catch (e) {
    defaultValue = undefined
  }

  if (!defaultValue) {
    return DEFAULT_TIMEZONE
  }

  return defaultValue
}

/**
 *
 * @returns The list of time zones with calculated timezone offset to account for daylight saving
 */

export const getTimezonesList = (): TimezoneListTypes[] => {
  const UTC = {
    label: 'Etc/UTC (GMT+00:00)',
    tzCode: 'Etc/UTC',
    name: '(GMT+00:00) UTC',
    utc: '+00:00'
  }
  return [
    ...timezonesList.map((tz) => {
      let tzOffset
      // Not all timezones from the timezonesList exist in the Date object
      // Therefore we need to keep the "original" timezone if it does not exist
      try {
        const offset = new Date()
          .toLocaleDateString(undefined, {
            timeZone: tz.tzCode,
            timeZoneName: 'longOffset'
          })
          .split('GMT')[1]
        tzOffset = offset || '+00:00'
      } catch {
        tzOffset = tz.utc
      }

      return {
        ...tz,
        utc: tzOffset
      }
    }),
    UTC
  ]
}

export const timezones = getTimezonesList()
