import { type FC } from 'react'
import { useTranslation } from 'react-i18next'

import { isEmpty } from 'lodash'

import { Alert, Typography } from '@matillion/component-library'

import classes from './ValidationErrors.module.scss'

interface ValidationErrorsProps {
  dataTestId: string
  errorMessage?: string
  titleTranslationKey: string
}

export const ValidationErrors: FC<ValidationErrorsProps> = ({
  dataTestId,
  errorMessage,
  titleTranslationKey
}) => {
  const { t } = useTranslation()

  return (
    <Alert
      type="error"
      title={t(titleTranslationKey)}
      data-testid={dataTestId}
      message={<Typography format="bcs">{errorMessage}</Typography>}
      hidden={isEmpty(errorMessage)}
      className={classes.ValidationErrors}
    />
  )
}
