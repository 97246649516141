import { useFormikContext } from 'formik'

import { DefaultSchema } from 'modules/Common/Fields'
import { type CreateProjectFormikValueTypes } from 'modules/Projects/CreateProject/CreateProjectForm'

const RedshiftSchema = () => {
  const { values } = useFormikContext<CreateProjectFormikValueTypes>()
  const isFieldEnabled = !!values.defaultDatabase && !!values.defaultDatabase.id

  return (
    <DefaultSchema
      listenerField={'defaultDatabase'}
      isFieldEnabled={isFieldEnabled}
    />
  )
}

export default RedshiftSchema
