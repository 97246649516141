import { useQuery, type UseQueryOptions } from 'react-query'

import { type AxiosError } from 'axios'

import { getEnvironments } from 'api/clients'
import { type GETEnvironmentsResponse } from 'api/types'

import { QueryKey } from 'constants/endpoint'

import { useClient } from './useClient'

type TData = GETEnvironmentsResponse
type TError = AxiosError<unknown>
type Options = Omit<
  UseQueryOptions<TData, TError, TData>,
  'queryKey' | 'queryFn'
>

export const useGetEnvironments = (projectId: string, options?: Options) => {
  const client = useClient()

  return useQuery<TData, TError>(
    [QueryKey.ENVIRONMENTS_LIST, projectId],
    async () => {
      return getEnvironments(
        {
          projectId
        },
        client
      )
    },
    options
  )
}
