import { type FunctionComponent } from 'react'
import { Link } from 'react-router-dom'

import classNames from 'classnames'

import { Skeleton, Typography } from '@matillion/component-library'

import classes from './Breadcrumbs.module.scss'

interface Items {
  text: string
  href?: string
  isLoading?: boolean
}

interface BreadcrumbsProps {
  className?: string
  'data-testid'?: string
  items: Items[]
}

export const Breadcrumbs: FunctionComponent<BreadcrumbsProps> = ({
  className,
  'data-testid': dataTestId,
  items
}) => {
  if (!items.length) {
    return null
  }

  return (
    <nav
      className={classNames(classes.Breadcrumbs, className)}
      data-testid={dataTestId}
      aria-label="breadcrumb"
    >
      <ol className={classNames(classes.Breadcrumbs__list)}>
        {items.map((item, index) => (
          <li
            key={`${item.text}_${index}`}
            className={classNames(classes['Breadcrumbs__list-item'])}
            data-testid={dataTestId && `${dataTestId}-list-item-${index + 1}`}
          >
            {item.isLoading ? (
              <Skeleton width={150} height={18} aria-label="Skeleton Loader" />
            ) : (
              <>
                {item.href ? (
                  <Typography
                    as={Link}
                    format="bcs"
                    to={item.href}
                    className={classNames(classes.Breadcrumbs__link)}
                  >
                    {item.text}
                  </Typography>
                ) : (
                  <Typography as="p" format="bcs">
                    {item.text}
                  </Typography>
                )}
              </>
            )}
          </li>
        ))}
      </ol>
    </nav>
  )
}
