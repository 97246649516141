export enum ClusterType {
  AllPurpose = 'ALL_PURPOSE',
  SqlWarehouse = 'SQL_WAREHOUSE'
}

export enum ClusterState {
  Started = 'STARTED',
  Starting = 'STARTING',
  Pending = 'PENDING',
  Restarting = 'RESTARTING',
  Running = 'RUNNING',
  Resizing = 'RESIZING',
  Stopped = 'STOPPED',
  Stopping = 'STOPPING',
  Deleted = 'DELETED',
  Deleting = 'DELETING',
  Terminated = 'TERMINATED',
  Terminating = 'TERMINATING',
  Error = 'ERROR',
  Unknown = 'UNKNOWN'
}

export const enabledStatus = [ClusterState.Resizing, ClusterState.Running]

export const startingStatus = [
  ClusterState.Pending,
  ClusterState.Restarting,
  ClusterState.Starting,
  ClusterState.Started
]

export const stoppedStatus = [
  ClusterState.Stopped,
  ClusterState.Stopping,
  ClusterState.Deleted,
  ClusterState.Deleting,
  ClusterState.Terminated,
  ClusterState.Terminating,
  ClusterState.Error,
  ClusterState.Unknown
]

export interface GETDatabricksComputeResourceResponseParams {
  clusterName: string
  clusterId: string
  clusterType: ClusterType | ''
  state?: ClusterState | ''
}

export type GETDatabricksComputeResourcesResponse =
  GETDatabricksComputeResourceResponseParams[]
