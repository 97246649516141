import type { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'

import { Icon, Typography } from '@matillion/component-library'

import { Header, HeaderTitle } from 'components/Header'

import ProjectConfigurationCard from 'modules/FormsContent/ProjectConfigurationSelection/ProjectConfigurationCard'
import classes from 'modules/FormsContent/ProjectConfigurationSelection/ProjectConfigurationSelection.module.scss'

import { ProjectConfigurationType } from 'types'

interface ProjectConfigurationSelectionProps {
  onCancel?: () => void
  modalContent?: string
  onPrevious: () => void
}

const ProjectConfigurationSelection: FunctionComponent<
  ProjectConfigurationSelectionProps
> = ({ modalContent, onCancel, onPrevious }) => {
  const { t } = useTranslation()

  return (
    <>
      <Header
        onCancel={onCancel}
        showCancel={true}
        showPrevious={true}
        modalContent={modalContent}
        onPrevious={onPrevious}
      >
        <HeaderTitle data-testid="project-configuration-title">
          {t('projectConfiguration.title')}
        </HeaderTitle>
        <Typography
          format="bcm"
          data-testid="project-configuration-support-text"
        >
          {t('projectConfiguration.supportText')}
        </Typography>
        <Typography
          format="bcm"
          data-testid="project-configuration-support-text2"
        >
          {t('projectConfiguration.supportText2')}{' '}
          <a
            href="https://docs.matillion.com/data-productivity-cloud/designer/docs/projects/#add-a-new-project"
            target="_blank"
            rel="noreferrer"
            data-testid="project-configuration-support-link"
            className={classes.ProjectConfigurationSelection__Link}
          >
            {t('projectConfiguration.linkText')} <Icon.Export />
          </a>
        </Typography>
      </Header>
      <div className={classes.ProjectConfigurationSelection__Container}>
        <ProjectConfigurationCard
          title={t('projectConfiguration.matillion.title')}
          supportText={t('projectConfiguration.matillion.supportText')}
          typeOfSetUp={t('projectConfiguration.matillion.typeOfSetUp')}
          bullets={t('projectConfiguration.matillion.bulletPoints', {
            returnObjects: true
          })}
          desiredProjectConfiguration={ProjectConfigurationType.Matillion}
        />
        <ProjectConfigurationCard
          title={t('projectConfiguration.advanced.title')}
          supportText={t('projectConfiguration.advanced.supportText')}
          typeOfSetUp={t('projectConfiguration.advanced.typeOfSetUp')}
          bullets={t('projectConfiguration.advanced.bulletPoints', {
            returnObjects: true
          })}
          desiredProjectConfiguration={ProjectConfigurationType.Advanced}
        />
      </div>
    </>
  )
}

export default ProjectConfigurationSelection
