import {
  type ClipboardEvent,
  type DragEvent,
  type FunctionComponent
} from 'react'
import { useTranslation } from 'react-i18next'

import classNames from 'classnames'
import { useFormikContext } from 'formik'

import { Field } from '@matillion/component-library'

import classes from 'components/Form/Form.module.scss'

import { type FormValues } from 'modules/Projects/ProjectsListing/DeleteProject/DeleteProjectForm.util'

const ConfirmProjectName: FunctionComponent = () => {
  const { errors, handleBlur, handleChange, touched, values } =
    useFormikContext<FormValues>()
  const { t } = useTranslation()

  return (
    <div data-testid="delete-project-confirm-project-name">
      <Field
        title={t('deleteProject.fields.confirmProjectName.title')}
        name="confirmProjectName"
        data-testid="delete-project-confirm-project-name-input"
        value={values.confirmProjectName}
        placeholder={t(
          'deleteProject.fields.confirmProjectName.placeholderText'
        )}
        onChange={handleChange}
        onBlur={handleBlur}
        onPaste={(e: ClipboardEvent<HTMLInputElement>) => {
          e.preventDefault()
        }}
        onDrop={(e: DragEvent<HTMLDivElement>) => {
          e.preventDefault()
        }}
        className={classNames(classes.Form__SpacingStyles)}
        supportText={t('deleteProject.fields.confirmProjectName.supportText')}
        errorText={
          errors?.confirmProjectName && touched.confirmProjectName
            ? t(errors.confirmProjectName)
            : null
        }
        hasError={'confirmProjectName' in errors && touched.confirmProjectName}
        required
      />
    </div>
  )
}

export default ConfirmProjectName
