import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import axios, {
  type AxiosRequestConfig,
  type InternalAxiosRequestConfig
} from 'axios'

import { useAuth, useUser } from '@matillion/hub-client'

import { useNetworkErrorToast } from 'api/hooks/useNetworkErrorToast'
import { type ErrorResponse } from 'api/types'

import config from 'config'

import { API_BASE_URL, QUERY_SMART_TIMEOUT } from 'constants/endpoint'

interface TokenOptionsProps {
  audience?: string
  scope?: string
}

export interface UseClientProps {
  tokenOptions?: TokenOptionsProps
  axiosConfig?: AxiosRequestConfig
  showErrorDetailMessage?: boolean
}

export const useClient = (params?: UseClientProps) => {
  const { getToken } = useAuth()
  const { organisation, user } = useUser()
  const { t } = useTranslation()
  const makeErrorToast = useNetworkErrorToast()

  return useMemo(() => {
    const apiBaseUrl = API_BASE_URL.replace('{{region}}', organisation.region!)

    const axiosInstance = axios.create({
      baseURL: params?.axiosConfig?.baseURL || apiBaseUrl,
      timeout: QUERY_SMART_TIMEOUT,
      timeoutErrorMessage: t('error.query.timeout'),
      ...params?.axiosConfig
    })

    axiosInstance.interceptors.response.use(
      (response) => response,
      (error) => {
        if (params?.showErrorDetailMessage) {
          const errorDetail = (error.response?.data as ErrorResponse)?.detail
          makeErrorToast({
            title: t('error.unexpected.title'),
            message: errorDetail || t('error.unexpected.message')
          })
        }
        throw error
      }
    )

    axiosInstance.interceptors.request.use(
      async (interceptedConfig: InternalAxiosRequestConfig) => {
        const options = {
          audience: params?.tokenOptions?.audience || config.apiBaseAudience,
          scope: params?.tokenOptions?.scope || config.apiBaseScope
        }

        const token = await getToken(options)

        interceptedConfig.headers.setAuthorization(`Bearer ${token}`)
        interceptedConfig.headers.set('Account-Id', organisation.id)
        interceptedConfig.headers.set('Username', user.sub)

        return interceptedConfig
      }
    )

    return axiosInstance
  }, [
    params,
    t,
    getToken,
    user,
    organisation.id,
    organisation.region,
    makeErrorToast
  ])
}
