import { type FunctionComponent, type PropsWithChildren } from 'react'

import classNames from 'classnames'

import { Layout, Page } from '@matillion/component-library'

import AppFooter from './AppFooter'
import AppHeader from './AppHeader'
import classes from './AppLayout.module.scss'

const AppLayout: FunctionComponent<PropsWithChildren> = ({ children }) => (
  <Layout header={<AppHeader />} footer={<AppFooter />}>
    <Page>
      <div className={classNames(classes.AppLayout__Page)}>{children}</div>
    </Page>
  </Layout>
)

export default AppLayout
