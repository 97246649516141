import { useState, type FunctionComponent, type SVGProps } from 'react'

import classNames from 'classnames'

import { Icon, Typography } from '@matillion/component-library'

import { Header, HeaderTitle } from 'components/Header'

import classes from './StatusHeader.module.scss'

interface StatusHeaderProps {
  primaryText: string
  isComplete: boolean
  isError?: boolean
  'data-testid'?: string
  secondaryText?: string
  isDisabled?: boolean
  onCancel?: () => void
  showCancel?: boolean
  modalContent?: string
}

export const StatusHeader: FunctionComponent<StatusHeaderProps> = ({
  primaryText,
  isComplete,
  isError = false,
  'data-testid': dataTestId,
  secondaryText,
  isDisabled,
  showCancel,
  onCancel,
  modalContent
}) => {
  const [completed, setCompleted] = useState(false)

  const getIcon = () => {
    return completed ? (
      <PrefixedIcon
        isError={isError}
        className={classNames(classes.StatusHeader__Icon, {
          [classes['StatusHeader__Icon--AnimateOut']]: !isComplete
        })}
        onTransitionEnd={() => {
          setCompleted(isComplete)
        }}
        aria-label="Finished"
        dataTestId={dataTestId}
        height={20}
        width={20}
      />
    ) : (
      <Icon.Loading
        className={classNames(
          classes.StatusHeader__Icon,
          classes['StatusHeader__Icon--Loading'],
          {
            [classes['StatusHeader__Icon--AnimateOut']]: isComplete
          },
          {
            [classes['StatusHeader__Icon--Disabled']]: isDisabled
          }
        )}
        onTransitionEnd={() => {
          setCompleted(isComplete)
        }}
        aria-label="Loading"
        data-testid={dataTestId && `${dataTestId}-loading-icon`}
      />
    )
  }

  return (
    <Header
      showCancel={showCancel}
      onCancel={onCancel}
      modalContent={modalContent}
      data-testid={dataTestId}
    >
      <HeaderTitle>
        {getIcon()}
        {primaryText}
      </HeaderTitle>
      <Typography
        format="bcm"
        data-testid={dataTestId && `${dataTestId}-secondary`}
      >
        {secondaryText}
      </Typography>
    </Header>
  )
}

interface PrefixedIconProps {
  isError?: boolean
  dataTestId?: string
}

const PrefixedIcon: FunctionComponent<
  SVGProps<SVGSVGElement> & PrefixedIconProps
> = ({ isError = false, dataTestId, ...props }) =>
  isError ? (
    <Icon.Error
      data-testid={dataTestId && `${dataTestId}-completed-icon-error`}
      {...props}
    />
  ) : (
    <Icon.GrandTick
      data-testid={dataTestId && `${dataTestId}-completed-icon-tick`}
      {...props}
    />
  )
