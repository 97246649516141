import { useTranslation } from 'react-i18next'
import {
  Navigate,
  Route,
  Routes,
  useNavigate,
  useParams
} from 'react-router-dom'

import { capitalize } from 'lodash'

import { ENVIRONMENT_STORAGE_ITEM } from 'constants/persistance'
import {
  PROJECT_DETAILS_ENVIRONMENTS,
  PROJECTS_CREDENTIALS,
  PROJECTS_DEFAULTS
} from 'constants/route'

import * as Sources from 'modules/Common/WarehouseSources'
import EditEnvironmentAgentAndCreds from 'modules/Projects/EditEnvironment/FormContent'

const EditEnvironmentRoutes = () => {
  const navigate = useNavigate()
  const { projectId } = useParams()
  const { t } = useTranslation()

  const persistedFormValues = sessionStorage.getItem(ENVIRONMENT_STORAGE_ITEM)
  const projectType = persistedFormValues
    ? JSON.parse(persistedFormValues).type
    : ''

  const handleOnCancel = () => {
    window.sessionStorage.removeItem(ENVIRONMENT_STORAGE_ITEM)
    navigate(`/${projectId!}/${PROJECT_DETAILS_ENVIRONMENTS}`)
  }

  const warehouseDefaultsRoutes = Object.entries(Sources).reduce<JSX.Element[]>(
    (result, [label, Component]) => {
      if (label === capitalize(projectType)) {
        result.push(
          <Route
            key={`${label}-route`}
            path={`${PROJECTS_DEFAULTS}/*`}
            element={
              <Component
                showCancel
                onCancel={handleOnCancel}
                onPrevious={() => {
                  navigate(PROJECTS_CREDENTIALS, { replace: true })
                }}
                showPrevious
                modalContent={t('editEnvironment.modal.content')}
                isEditEnvironment
              />
            }
          />
        )
      }
      return result
    },
    []
  )

  return (
    <Routes>
      <Route
        path={`${PROJECTS_CREDENTIALS}/*`}
        element={<EditEnvironmentAgentAndCreds />}
      />
      {warehouseDefaultsRoutes}
      <Route
        path="*"
        element={<Navigate replace to={PROJECTS_CREDENTIALS} />}
      />
    </Routes>
  )
}

export default EditEnvironmentRoutes
