import { useTranslation } from 'react-i18next'

import classNames from 'classnames'

import classes from 'components/Form/Form.module.scss'
import { Header, HeaderTitle } from 'components/Header'

import { GitProviderSelectionContent } from 'modules/FormsContent/GitProviderSelection/GitProviderSelectionContent'

interface GitProviderSelectionProps {
  onCancel?: () => void
  modalContent?: string
  onPrevious?: () => void
}

const GitProviderSelection = ({
  onCancel,
  modalContent,
  onPrevious
}: GitProviderSelectionProps) => {
  const { t } = useTranslation()

  return (
    <>
      <Header
        onCancel={onCancel}
        showCancel
        showPrevious
        modalContent={modalContent}
        onPrevious={onPrevious}
      >
        <HeaderTitle data-testid="git-provider-selection-title">
          {t('formContent.gitProviderSelection.title')}
        </HeaderTitle>
      </Header>
      <div
        className={classNames(
          classes.Form__InnerWrap,
          classes['Form__InnerWrap--large']
        )}
      >
        <GitProviderSelectionContent />
      </div>
    </>
  )
}

export default GitProviderSelection
