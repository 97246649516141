import { useQuery, type UseQueryOptions } from 'react-query'

import { type AxiosError } from 'axios'

import { getOAuthSchema } from 'api/clients'
import {
  mutateGetOAuthSchemaResponse,
  type GETOAuthSchemaQueryStringParams,
  type GETOAuthSchemaResponse
} from 'api/types'

import { QueryKey } from 'constants/endpoint'

import { useClient } from './useClient'

type TData = GETOAuthSchemaResponse
type TError = AxiosError<unknown>

export const useGetOAuthSchema = (
  params: GETOAuthSchemaQueryStringParams,
  options?: UseQueryOptions<TData, TError>
) => {
  const client = useClient()
  const { projectId, agentId } = params

  return useQuery<TData, TError>(
    [QueryKey.OAUTH_SCHEMA, projectId, agentId],
    async () => {
      const schema: GETOAuthSchemaResponse = await getOAuthSchema(
        params,
        client
      )
      return mutateGetOAuthSchemaResponse(schema)
    },
    options
  )
}
