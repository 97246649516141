import { type FC } from 'react'
import { useTranslation } from 'react-i18next'

import { Alert, Typography } from '@matillion/component-library'

export interface StageConfigAlertProps {
  editing: false
}

export const StageConfigAlert: FC<StageConfigAlertProps> = ({ editing }) => {
  const { t } = useTranslation('translation', {
    keyPrefix:
      'createStreamingPipeline.fields.destinationConfig.snowflake.stageAlert'
  })

  return (
    <Alert
      type="warning"
      title={t('title')}
      data-testid="stage-config-alert"
      message={
        <Typography format="bcs">
          {editing ? t('bodyEditing') : t('body')}
        </Typography>
      }
    />
  )
}
