import { NO_DATA } from 'constants/strings'

import { getMappedSecretManager } from 'modules/Projects/CreateStreamingPipeline'

import { type SecretManagerId } from 'types/Streaming'

export const formatField = (fieldContent?: string | number): string => {
  if (!fieldContent) {
    return NO_DATA
  }
  return fieldContent.toString()
}

export const formatSecretManager = (
  secretManagerId?: SecretManagerId
): string => {
  if (!secretManagerId) {
    return NO_DATA
  }
  return getMappedSecretManager(secretManagerId).name
}
