import { type FocusEvent, type FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'

import { Field as FormikField, type FieldProps } from 'formik'

import { Field } from '@matillion/component-library'

import {
  hasErrorOrIsEmpty,
  resolveErrorText
} from 'modules/Projects/CreateStreamingPipeline/FormContent'

import { FIELD_NAMES_NAME } from './types'

export const Name: FunctionComponent = () => {
  const { t } = useTranslation()

  return (
    <FormikField name={FIELD_NAMES_NAME}>
      {({
        field,
        meta,
        form: { values, setFieldValue, handleBlur, isSubmitting }
      }: FieldProps<string>) => (
        <div data-testid="configure-streaming-pipeline-name">
          <Field
            {...field}
            title={t('createStreamingPipeline.fields.name.title')}
            name={FIELD_NAMES_NAME}
            data-testid="configure-streaming-pipeline-name-input"
            supportText={t('createStreamingPipeline.fields.name.supportText')}
            errorText={resolveErrorText(t, meta)}
            hasError={hasErrorOrIsEmpty({
              values,
              fieldName: FIELD_NAMES_NAME,
              meta
            })}
            onBlur={(e: FocusEvent<HTMLInputElement>) => {
              setFieldValue(FIELD_NAMES_NAME, e.target.value.trim())
              handleBlur(e)
            }}
            required
            disabled={isSubmitting}
          />
        </div>
      )}
    </FormikField>
  )
}
