import {
  useEffect,
  useState,
  type ChangeEvent,
  type FunctionComponent
} from 'react'
import { useTranslation } from 'react-i18next'

import classNames from 'classnames'
import { useFormikContext } from 'formik'
import { capitalize } from 'lodash'

import {
  AutoComplete,
  Field,
  type AutoCompleteItemId
} from '@matillion/component-library'

import { useGetNumberOfDaysInMonth } from 'hooks'
import { useFlags } from 'hooks/flags'

import classes from 'modules/FormsContent/Form.module.scss'
import scheduleClasses from 'modules/Projects/CreateSchedule/FormContent/CreateSchedule.module.scss'
import { type ScheduleFormValues } from 'modules/Projects/CreateSchedule/types'

import { ScheduleMethod } from 'types'

const getDays = (validNumberOfDays: number) =>
  Array.from(Array(validNumberOfDays).keys()).map((day) => day + 1)

export const Interval: FunctionComponent = () => {
  const { enableHourAndMinuteUnitScheduleOptions } = useFlags()
  const { handleChange, setFieldValue, values } =
    useFormikContext<ScheduleFormValues>()
  const { t } = useTranslation()
  const validDayCountInSelectedMonth = useGetNumberOfDaysInMonth(
    new Date(values.schedule.startDate)
  )
  const [repeatEveryRange, setRepeatEveryRange] = useState<number[]>([])

  useEffect(() => {
    switch (values.schedule.unit) {
      case ScheduleMethod.WEEK:
        setRepeatEveryRange([1])
        break
      case ScheduleMethod.HOUR:
        setRepeatEveryRange(
          Array.from(Array(23).keys()).map((hour) => hour + 1)
        )
        break
      case ScheduleMethod.MINUTE:
        setRepeatEveryRange(Array.from(Array(59).keys()).map((min) => min + 1))
        break
      default:
        setRepeatEveryRange(getDays(validDayCountInSelectedMonth))
    }
  }, [validDayCountInSelectedMonth, values.schedule.unit])

  const getRepeatEveryInput = (val: string | number) => ({
    target: {
      name: 'schedule.repeatEvery',
      value: val
    }
  })

  const getUnitInput = (val: string | number) => ({
    target: {
      name: 'schedule.unit',
      value: val
    }
  })

  useEffect(() => {
    if (
      values.schedule.unit === ScheduleMethod.DAY &&
      Number(values.schedule.repeatEvery) > validDayCountInSelectedMonth
    ) {
      handleChange(getRepeatEveryInput(validDayCountInSelectedMonth))
    }
  }, [
    handleChange,
    validDayCountInSelectedMonth,
    values.schedule.repeatEvery,
    values.schedule.unit
  ])

  return (
    <div className={scheduleClasses.Interval}>
      <div
        data-testid="schedule-repeat-every"
        className={classNames(
          classes.Form__Input,
          classes['Form__Input--short']
        )}
      >
        <Field
          inputComponent={AutoComplete}
          availableItems={repeatEveryRange.map((val) => ({
            name: val.toString(),
            id: val
          }))}
          title={t('createSchedule.fields.repeatEvery.title')}
          name="repeatEvery"
          className={classes.Form__Field}
          data-testid="schedule-repeat-every-input"
          value={values.schedule.repeatEvery}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            try {
              const value = (e.target.value as unknown as AutoCompleteItemId).id
              handleChange(getRepeatEveryInput(value))
            } catch {}
          }}
          disabled={values.schedule.unit === ScheduleMethod.WEEK}
        />
      </div>
      <div data-testid="schedule-unit" className={classes.Form__Input}>
        <Field
          inputComponent={AutoComplete}
          availableItems={[
            ...(enableHourAndMinuteUnitScheduleOptions
              ? [
                  {
                    name: capitalize(ScheduleMethod.MINUTE),
                    id: ScheduleMethod.MINUTE
                  },
                  {
                    name: capitalize(ScheduleMethod.HOUR),
                    id: ScheduleMethod.HOUR
                  }
                ]
              : []),
            {
              name: capitalize(ScheduleMethod.DAY),
              id: ScheduleMethod.DAY
            },
            {
              name: capitalize(ScheduleMethod.WEEK),
              id: ScheduleMethod.WEEK
            }
          ]}
          title={t('createSchedule.fields.unit.title')}
          name="unit"
          className={classes.Form__Field}
          data-testid="schedule-unit-input"
          value={values.schedule.unit}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            try {
              const value = (e.target.value as unknown as AutoCompleteItemId).id
              setFieldValue('schedule.repeatEvery', 1)
              handleChange(getUnitInput(value))
            } catch {}
          }}
        />
      </div>
    </div>
  )
}
