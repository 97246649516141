import type { ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useParams } from 'react-router-dom'

import classNames from 'classnames'
import { useFormikContext } from 'formik'

import { Field } from '@matillion/component-library'
import { useLDClient } from '@matillion/hub-client'

import classes from 'components/Form/Form.module.scss'

import { AppRoutes, PROJECTS_CREDENTIALS } from 'constants/route'

import { useFlags } from 'hooks/flags'

import { type CreateProjectFormikValueTypes } from 'modules/Projects/CreateProject/CreateProjectForm'

import { AgentsSecretsHost, Warehouse } from 'types'

export const SecretValue = () => {
  const location = useLocation()
  const { projectId, envId } = useParams()
  const isEditEnvironment = location.pathname.includes(
    AppRoutes.getEnvironmentEdit(projectId!, envId!)
  )

  const { errors, handleBlur, handleChange, touched, values } =
    useFormikContext<CreateProjectFormikValueTypes>()

  const { t } = useTranslation()
  const isMatillionHostedEditEnvironment =
    values.agentsSecretsManagement === AgentsSecretsHost.MatillionHosted &&
    isEditEnvironment

  const { track: sendLDMetric } = useLDClient()

  const warehouse = values.type
  const isOnboardingSnowflakeCredentialsScreen =
    location.pathname.includes(
      `${AppRoutes.getOnboardingAdd()}/${PROJECTS_CREDENTIALS}`
    ) && warehouse === Warehouse.Snowflake

  const handleFieldChange = (e: ChangeEvent<HTMLInputElement>) => {
    handleChange(e)
    if (isOnboardingSnowflakeCredentialsScreen) {
      sendLDMetric('snowflake-credentials-form-q-3-password-changed')
    }
  }

  const { enableDatabricksTokenOnlyAuth } = useFlags()
  const accessTokenOnly =
    warehouse === Warehouse.Databricks && enableDatabricksTokenOnlyAuth

  const supportText = () => {
    const supportTextKey = isMatillionHostedEditEnvironment
      ? 'matillionHosted'
      : 'customerHosted'

    if (accessTokenOnly) {
      return t(`fields.personalAccessToken.supportText.${supportTextKey}`)
    }

    return !isOnboardingSnowflakeCredentialsScreen
      ? t(`fields.passwordSnowflake.supportText.${supportTextKey}`)
      : t(`fields.secretValue.supportText.${supportTextKey}`)
  }

  const title = () => {
    if (accessTokenOnly) {
      return t('fields.personalAccessToken.title')
    }

    return !isOnboardingSnowflakeCredentialsScreen
      ? t('fields.secretValue.title')
      : t('fields.passwordSnowflake.title')
  }

  const placeholder = () => {
    if (accessTokenOnly) {
      return t('fields.personalAccessToken.placeholderText')
    }

    return !isOnboardingSnowflakeCredentialsScreen
      ? t('fields.secretValue.placeholderText')
      : t('fields.passwordSnowflake.placeholderText')
  }

  return (
    <div
      data-testid={`${values.type}-credentials-secret-value`}
      className={classNames(isEditEnvironment && classes.Form__FixedHeight)}
    >
      <Field
        type="password"
        title={title()}
        name="secretValue"
        data-testid={`${values.type}-credentials-secret-value-input`}
        data-tracker-id={`${values.type}-credentials-password-input`}
        value={values.secretValue}
        placeholder={placeholder()}
        onChange={handleFieldChange}
        onBlur={handleBlur}
        className={classNames(classes.Form__SpacingStyles)}
        supportText={supportText()}
        errorText={
          errors?.secretValue && touched.secretValue
            ? t(errors.secretValue)
            : null
        }
        hasError={Boolean(errors?.secretValue) && Boolean(touched.secretValue)}
        required={!isEditEnvironment}
      />
    </div>
  )
}
