import { type FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'

import { NoResourcesAvailable } from 'components/NoResourcesAvailable'

import config from 'config'

interface NoDataProps {
  onAddSecret: VoidFunction
}

const NoData: FunctionComponent<NoDataProps> = ({ onAddSecret }) => {
  const { t } = useTranslation()
  const secretHelpRedirect = config.projectExplorer.createSecretHelpRedirect
  return (
    <NoResourcesAvailable
      title={t('secrets.createFirstSecretDefinition.title')}
      supportText={t('secrets.createFirstSecretDefinition.supportText')}
      docUrl={secretHelpRedirect}
      linkText={t('secrets.createFirstSecretDefinition.linkText')}
      buttonText={t('secrets.createFirstSecretDefinition.buttonText')}
      onButtonClick={onAddSecret}
      data-testid="add-first-secret-definition"
    />
  )
}

export default NoData
