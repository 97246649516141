import { type GETProjectMembersResponse } from 'api/types'

export const getProjectMembersMutation = (
  accountMembersResponse: GETProjectMembersResponse
): GETProjectMembersResponse => {
  return accountMembersResponse.map(
    ({ firstName, lastName, email, formattedUsername, ...rest }) => ({
      firstName: firstName?.trim(),
      lastName: lastName?.trim(),
      email: email,
      formattedUsername: `${firstName ?? ''} ${lastName ?? ''}`.trim() || email,
      ...rest
    })
  )
}
