import type { ChangeEvent, FocusEvent, FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'

import { useFormikContext } from 'formik'

import { AutoComplete, Field } from '@matillion/component-library'

import classes from 'modules/FormsContent/Form.module.scss'
import { groupAutocompleteTimezones } from 'modules/Projects/CreateSchedule/FormContent/Fields/Schedule/Common/Timezone/Timezones.util'
import { type ScheduleFormValues } from 'modules/Projects/CreateSchedule/types'
import { renderFormikError } from 'modules/utils'

export const Timezone: FunctionComponent = () => {
  const { t } = useTranslation()
  const { values, handleChange, handleBlur, errors, touched } =
    useFormikContext<ScheduleFormValues>()

  const timezoneError = renderFormikError(
    errors.schedule?.timezone,
    Boolean(touched.schedule?.timezone)
  )

  const getValueInput = (val: string) => ({
    target: {
      name: 'schedule.timezone',
      value: val
    }
  })

  return (
    <div data-testid="schedule-timezone">
      <Field
        inputComponent={AutoComplete}
        availableItems={groupAutocompleteTimezones()}
        title={t('createSchedule.fields.timezone.title')}
        name="timezone"
        className={classes.Form__Field}
        data-testid="schedule-timezone-input"
        value={values.schedule.timezone}
        placeholder={
          timezoneError
            ? t('createSchedule.fields.timezone.error.placeholderText')
            : t('createSchedule.fields.timezone.placeholderText')
        }
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          handleChange(getValueInput(e.target.value))
        }}
        onBlur={(e: FocusEvent<HTMLInputElement>) => {
          handleBlur(getValueInput(e.target.value))
        }}
        errorText={timezoneError ? t(timezoneError) : ''}
        hasError={
          Boolean(errors?.schedule?.timezone?.id) &&
          Boolean(touched.schedule?.timezone)
        }
        required
      />
    </div>
  )
}

export default Timezone
