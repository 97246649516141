import { type FC } from 'react'
import { useTranslation } from 'react-i18next'

import { useFormikContext, type FormikValues } from 'formik'
import { get } from 'lodash'

import {
  Dropdown,
  Typography,
  type DropdownItem
} from '@matillion/component-library'

import { SupportText } from 'modules/Projects/CreateStreamingPipeline/FormContent/ConfigurePipelineDetailsPage/Components/SupportText/SupportText'

import classes from './StreamingDropdown.module.scss'

export interface StreamingDropdownProps {
  name: string
  items: DropdownItem[]
  title: string
  supportText?: string
  supportDocs?: string
  dataTestId: string
}

export const StreamingDropdown: FC<StreamingDropdownProps> = ({
  name,
  items,
  title,
  supportText,
  supportDocs,
  dataTestId
}) => {
  const { values, setFieldValue } = useFormikContext<FormikValues>()
  const { t } = useTranslation()

  return (
    <div
      data-testid={dataTestId}
      className={classes.StreamingDropdown__Wrapper}
    >
      <Typography
        format="bcs"
        weight="bold"
        data-testid={dataTestId + '-title'}
        className={classes.StreamingDropdown__Title}
      >
        {t(title)}
      </Typography>
      <Dropdown
        data-testid={dataTestId + '-input'}
        value={get(values, name)?.id}
        items={items}
        colorTheme="white"
        className={classes.StreamingDropdown__Dropdown}
        handleSelectedItem={(item) => {
          if (item) {
            setFieldValue(name, item)
          }
        }}
      />
      {supportText && (
        <SupportText
          supportText={t(supportText)}
          supportDocs={supportDocs && t(supportDocs)}
          data-testid={dataTestId + '-support-text'}
        />
      )}
    </div>
  )
}
