import { type FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'

import { LoadingSpinner, Typography } from '@matillion/component-library'

import { useGetStreamingPipelineAlertPreference } from 'api/hooks/streaming/useGetStreamingPipelineAlertPreference'

import AlertPreferenceToggle from 'modules/Projects/StreamingPipelineDashboard/Events/AlertPreferenceToggle/AlertPreferenceToggle'

interface AlertPreferenceProps {
  projectId: string
  pipelineId: string
}

const AlertPreference: FunctionComponent<AlertPreferenceProps> = ({
  projectId,
  pipelineId
}) => {
  const { t } = useTranslation()
  const { data, isLoading, isError } = useGetStreamingPipelineAlertPreference(
    projectId,
    pipelineId,
    {
      cacheTime: 0
    }
  )

  if (isLoading) {
    return <LoadingSpinner data-testid="loading-spinner" />
  }

  if (!data || isError) {
    return (
      <Typography format="bcm" data-testid="alert-preference-error-message">
        {t('streamingPipelineDashboard.events.alerts.noData')}
      </Typography>
    )
  }

  return (
    <AlertPreferenceToggle
      projectId={projectId}
      pipelineId={pipelineId}
      initialChecked={data.enabled}
    />
  )
}

export default AlertPreference
