import { useEffect, useMemo, type FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { useFormikContext } from 'formik'

import { AutoComplete, Field } from '@matillion/component-library'

import { useGetSecretReferences } from 'api/hooks'

import classes from 'components/Form/Form.module.scss'

import { renderFormikError } from 'modules/utils'

import { SecretReferenceTypes } from 'types'
import { type CloudProviders } from 'types/CloudProviders'
import { type AssociateCredentialsWithEnvironmentFormI } from 'types/FormTypes'

export interface GenericCredentialsProps {
  cloudProvider: CloudProviders
  fieldId: keyof AssociateCredentialsWithEnvironmentFormI
  fieldName: string
}

export const GenericCredentials: FunctionComponent<GenericCredentialsProps> = ({
  cloudProvider,
  fieldId,
  fieldName
}) => {
  const { errors, handleBlur, handleChange, touched, values, setFieldValue } =
    useFormikContext<AssociateCredentialsWithEnvironmentFormI>()
  const { t } = useTranslation()
  const { projectId } = useParams()

  const {
    data: listOfSecretReferences = [],
    isError,
    isLoading
  } = useGetSecretReferences(
    projectId!,
    SecretReferenceTypes.CLOUD_PLATFORM_CREDENTIALS,
    { retryOnMount: false }
  )

  const cloudCredentialReferences = useMemo(
    () =>
      listOfSecretReferences
        .filter((secret) => secret.metadata?.provider === cloudProvider)
        .sort((a, b) => a.name.localeCompare(b.name)),
    [cloudProvider, listOfSecretReferences]
  )

  const formikError = renderFormikError(
    errors[fieldId],
    Boolean(touched[fieldId])
  )

  const providerError = useMemo(() => {
    if (isError) {
      return t(
        'associateCredentials.fields.cloudCredentialField.error.loadingError',
        { cloudProvider: fieldName }
      )
    } else if (formikError) {
      return t(formikError)
    }
    return ''
  }, [isError, formikError, t, fieldName])

  const noSelection = { id: '0', name: 'No Selection' } as const

  useEffect(() => {
    if (cloudCredentialReferences.length === 0 && !isLoading) {
      setFieldValue(fieldId, { id: '0', name: 'No credentials available' })
    }
  }, [cloudCredentialReferences.length, fieldId, isLoading, setFieldValue])

  return (
    <div data-testid={`associate-credentials-${cloudProvider}`}>
      <Field
        inputComponent={AutoComplete}
        availableItems={[
          noSelection,
          ...cloudCredentialReferences.map((item) => ({
            id: item.id,
            name: item.name
          }))
        ]}
        loading={isLoading}
        title={t('associateCredentials.fields.cloudCredentialField.title', {
          cloudProvider: fieldName
        })}
        name={fieldId}
        data-testid={`associate-credentials-${cloudProvider}-input`}
        value={values[fieldId]}
        placeholder={t(
          'associateCredentials.fields.cloudCredentialField.placeholderText',
          {
            cloudProvider: fieldName
          }
        )}
        onChange={handleChange}
        onBlur={handleBlur}
        className={classes.Form__SpacingStyles}
        supportText={t(
          'associateCredentials.fields.cloudCredentialField.supportText'
        )}
        errorText={isLoading ? undefined : providerError}
        hasError={
          Boolean(errors?.[fieldId]?.id) &&
          Boolean(touched[fieldId]) &&
          !isLoading
        }
        disabled={cloudCredentialReferences.length === 0 && !isLoading}
        required
      />
    </div>
  )
}
