import { object, string } from 'yup'

import {
  PROJECTS_CREATE_ENVIRONMENT,
  PROJECTS_CREDENTIALS,
  PROJECTS_DEFAULTS
} from 'constants/route'

import {
  type CreateEnvironmentFormikValueTypes,
  type CreateEnvironmentSteps
} from 'modules/Projects/CreateEnvironment/CreateEnvironmentForm/types'
import { alphaNumericMildCharsWithLeadEndSpacesRegEx } from 'modules/Projects/CreateProject/CreateProjectForm'

import { AgentsSecretsHost } from 'types'

export type CreateEnvironmentStepsType = Record<
  CreateEnvironmentSteps,
  {
    previousStep?: CreateEnvironmentSteps
    currentStep: CreateEnvironmentSteps
    nextStep?: CreateEnvironmentSteps
  }
>

export const steps: CreateEnvironmentStepsType = {
  [PROJECTS_CREATE_ENVIRONMENT]: {
    previousStep: undefined,
    currentStep: PROJECTS_CREATE_ENVIRONMENT,
    nextStep: PROJECTS_CREDENTIALS
  },
  [PROJECTS_CREDENTIALS]: {
    previousStep: PROJECTS_CREATE_ENVIRONMENT,
    currentStep: PROJECTS_CREDENTIALS,
    nextStep: PROJECTS_DEFAULTS
  },
  [PROJECTS_DEFAULTS]: {
    previousStep: PROJECTS_CREDENTIALS,
    currentStep: PROJECTS_DEFAULTS,
    nextStep: undefined
  }
}

export const alphaNumericMildCharsRegEx = /^([\w()-]\s?)+$/

export const initialValues: CreateEnvironmentFormikValueTypes = {
  account: '',
  environmentDefaultAccess: {
    id: '',
    name: ''
  },
  defaultRole: {
    id: '',
    name: ''
  },
  defaultWarehouse: {
    id: '',
    name: ''
  },
  defaultDatabase: {
    id: '',
    name: ''
  },
  defaultSchema: {
    id: '',
    name: ''
  },
  environmentName: '',
  etlAgent: {
    id: '',
    name: '',
    agentCloudProvider: '-1'
  },
  matillionHostedAgentId: '',
  secretName: {
    id: '',
    name: ''
  },
  privateKeySecretName: {
    id: '',
    name: ''
  },
  passphraseSecretName: {
    id: '',
    name: ''
  },
  passphraseSecretKey: {
    id: '',
    name: ''
  },
  secretKey: {
    id: '',
    name: ''
  },
  secretValue: '',
  secretLocationId: '',
  secretReferenceId: '',
  awsSecretReferenceId: '',
  username: '',
  agentsSecretsManagement: AgentsSecretsHost.CustomerHosted,
  port: null,
  ssl: false,
  type: '',
  compute: {
    id: '',
    name: '',
    clusterId: '',
    clusterName: '',
    clusterType: ''
  },
  catalog: {
    id: '',
    name: ''
  },
  awsAccessKeyId: '',
  awsSecretAccessKey: '',
  defaultStorageLocation: { name: '', id: '' },
  credentialsType: {
    id: 'password',
    name: 'Username and password'
  },
  passphrase: ''
}

export const genericValuesSchema = (availableEnvironments: string[] = []) => {
  return {
    [PROJECTS_CREATE_ENVIRONMENT]: object({
      environmentName: string()
        .required('fields.environmentName.error.required')
        .test(
          'environmentName',
          'fields.environmentName.error.notUnique',
          (value) => {
            if (availableEnvironments.length === 0) {
              return true
            }
            return availableEnvironments.findIndex((p) => p === value) === -1
          }
        )
        .matches(
          alphaNumericMildCharsWithLeadEndSpacesRegEx,
          'fields.environmentName.error.regEx'
        ),
      etlAgent: object({
        id: string().required('fields.etlAgent.error.required'),
        name: string().required('fields.etlAgent.error.required')
      })
        .when('agentsSecretsManagement', {
          is: AgentsSecretsHost.MatillionHosted,
          then: (_existingSchema) => {
            return object({
              id: string().optional(),
              name: string().optional()
            })
          }
        })
        .required('fields.etlAgent.error.unmatched')
        .nullable()
    })
  }
}
