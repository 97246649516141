import { type FC } from 'react'
import { useTranslation } from 'react-i18next'

import classNames from 'classnames'
import { useFormikContext } from 'formik'

import {
  Typography,
  type DataGridColumnProps
} from '@matillion/component-library'

import {
  TableSelector,
  type TableGridRow
} from 'modules/Projects/CreateStreamingPipeline/FormContent/ConfigurePipelineDetailsPage/Components/TableSelection'
import {
  useFormMetadata,
  type FormValues
} from 'modules/Projects/CreateStreamingPipeline/FormContent/index'

import classes from './TableSelector.module.scss'
import { TableSelectorRadio } from './TableSelectorRadio'

interface SignalTableSelectorProps {
  schema: string
  onClose: () => void
  onPrevious: () => void
}

export const SignalTableSelector: FC<SignalTableSelectorProps> = ({
  schema,
  onClose,
  onPrevious
}) => {
  const { t } = useTranslation()
  const { values, setFieldValue } = useFormikContext<FormValues>()
  const { metadata } = useFormMetadata()

  const availableTables = metadata.source.schemas[schema].tables ?? []
  const selectedTable = values.source.snapshot.onDemandSnapshot.signalTable
  const isSelectedTableInThisSchema = selectedTable?.schema === schema

  const columns: Array<DataGridColumnProps<TableGridRow>> = [
    {
      key: 'id',
      headerClassName: classes.TableSelector__ColumnHeader,
      sortable: true,
      title: t(
        'createStreamingPipeline.fields.pipelineConfig.tableSelector.nameColumn'
      ),
      as: Typography,
      'data-testid': 'table-selector-table-name',
      mapValues: (value) => ({
        type: 'bcs',
        children: value.id
      })
    },
    {
      key: 'radioButtons',
      sortable: false,
      headerClassName: classNames(
        classes.TableSelector__ColumnHeader,
        classes.TableSelector__Unclickable
      ),
      className: classes.TableSelector__RadioButtonColumn,
      title: t(
        'createStreamingPipeline.fields.pipelineConfig.signalTableSelector.radioButtonColumn'
      ),
      as: TableSelectorRadio,
      name: 'signal-table',
      mapValues: (value) => ({
        checked:
          isSelectedTableInThisSchema && selectedTable?.table === value.id,
        name: 'signal-table',
        value: value.id,
        'data-testid': `table-selector-radio-button-${value.id}`,
        onChange: async () =>
          setFieldValue('source.snapshot.onDemandSnapshot.signalTable', {
            schema,
            table: value.id
          })
      })
    }
  ]

  const rows: TableGridRow[] = availableTables.map((tableName) => ({
    id: tableName
  }))

  return (
    <TableSelector
      schema={schema}
      title={t(
        'createStreamingPipeline.fields.pipelineConfig.signalTableSelector.title'
      )}
      columns={columns}
      rows={rows}
      backButtonText={t(
        'createStreamingPipeline.fields.pipelineConfig.signalTableSelector.backButton'
      )}
      selectButtonText={t(
        'createStreamingPipeline.fields.pipelineConfig.signalTableSelector.selectButton'
      )}
      onClose={onClose}
      onPrevious={onPrevious}
    />
  )
}
