import { type FunctionComponent } from 'react'

import classNames from 'classnames'

import { Icon } from '@matillion/component-library'

import classes from './Icon.module.scss'

export const RedWarning: FunctionComponent = () => (
  <Icon.Warning
    className={classNames(classes.WarningIcon, classes.WarningIcon__Red)}
  />
)
