import type { GETSchedulesResponse } from 'api/types'

export enum ColumnKeys {
  name = 'name',
  jobName = 'job',
  environment = 'environment',
  schedule = 'schedule',
  lastRun = 'lastRun',
  nextRun = 'nextRun',
  action = 'action'
}

export type ScheduleTypes = Exclude<ColumnKeys, 'action'>

export type SortTypes = { [key in ColumnKeys]: ColumnKeys }

export interface SortedScheduleDataTypes extends GETSchedulesResponse {
  id: string
}

export interface ActionCellTypes {
  isError?: boolean
  item: SortedScheduleDataTypes
  projectId: string
}
