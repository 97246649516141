import { useMutation } from 'react-query'

import { type AxiosError } from 'axios'

import { postSnowflakeListWarehousesRequest } from 'api/clients'
import { useNetworkErrorToast, useStreamingClient } from 'api/hooks/index'
import {
  type ErrorResponse,
  type POSTSnowflakeListWarehousesResponse
} from 'api/types'

import { QueryKey } from 'constants/endpoint'

import { type SnowflakeConnectionType } from 'types/Streaming'

type TError = AxiosError<ErrorResponse>
interface TVariables {
  agentId: string
  connection: SnowflakeConnectionType
  role: string
}

export const useFetchSnowflakeWarehouses = () => {
  const streamingClient = useStreamingClient()
  const makeErrorToast = useNetworkErrorToast()

  return useMutation<POSTSnowflakeListWarehousesResponse, TError, TVariables>({
    mutationKey: [QueryKey.STREAMING_LIST_SNOWFLAKE_WAREHOUSES],
    mutationFn: async ({ connection, agentId, role }) => {
      return postSnowflakeListWarehousesRequest(
        { agentId, role },
        connection,
        streamingClient
      )
    },
    onError: (error) => {
      makeErrorToast({
        message: error.response?.data.detail ?? error.message
      })
    }
  })
}
