import type { ValidDayInt, ValidDays } from 'types'

export interface DaysType {
  id: ValidDayInt
  name: ValidDays
}

export const days: readonly DaysType[] = [
  {
    id: 1,
    name: 'Mon'
  },
  {
    id: 2,
    name: 'Tue'
  },
  {
    id: 3,
    name: 'Wed'
  },
  {
    id: 4,
    name: 'Thu'
  },
  {
    id: 5,
    name: 'Fri'
  },
  {
    id: 6,
    name: 'Sat'
  },
  {
    id: 7,
    name: 'Sun'
  }
]

export const mapDayToText = (
  rawDays: ValidDays[] | ValidDayInt[]
): ValidDays[] => {
  let newDays: ValidDays[] = []

  for (const day of rawDays) {
    let newDay = day
    const dayAsInt = parseInt(JSON.stringify(day))

    // If the 'weekly' CRON value is a number, e.g. 3 (instead of being text, e.g. WED)
    if (!Number.isNaN(dayAsInt)) {
      newDay = days.find((d) => d.id === dayAsInt)!.name
    }

    newDays = [...newDays, newDay as ValidDays]
  }

  return newDays
}
