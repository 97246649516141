import { type ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'

import { useFormikContext } from 'formik'

import { Field } from '@matillion/component-library'

import classes from 'components/Form/Form.module.scss'

import { useFormikFieldSpacesToHyphens } from 'hooks'

import type { CommonFieldsTypes } from 'modules/Common/Fields/types'

import { type EnvironmentDetailsI } from 'types/FormTypes'

type FormikValueTypes = Pick<EnvironmentDetailsI, 'environmentName'>

interface EnvironmentNameProps extends CommonFieldsTypes {
  disabled?: boolean
}

export const EnvironmentName = ({
  'data-testid': dataTestId = 'common-environment-name',
  onChange,
  disabled = false
}: EnvironmentNameProps) => {
  const { errors, handleBlur, handleChange, touched, values } =
    useFormikContext<FormikValueTypes>()
  const fieldName = 'environmentName'
  const spacesToHyphens = useFormikFieldSpacesToHyphens({ fieldName })

  const { t } = useTranslation()
  return (
    <div data-testid={dataTestId}>
      <Field
        maxLength={disabled ? undefined : 255}
        title={t('fields.environmentName.title')}
        name={fieldName}
        data-testid={`${dataTestId}-input`}
        value={values.environmentName}
        placeholder={t('fields.environmentName.placeholderText')}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          handleChange(e)
          spacesToHyphens(e)
          if (onChange) {
            onChange(e)
          }
        }}
        onBlur={handleBlur}
        className={classes.Form__SpacingStyles}
        supportText={t('fields.environmentName.supportText')}
        errorText={
          errors?.environmentName && touched.environmentName
            ? t(errors.environmentName, {
                environmentName: values.environmentName
              })
            : null
        }
        hasError={!!errors?.environmentName && touched.environmentName}
        required
        disabled={disabled}
      />
    </div>
  )
}
