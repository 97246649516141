import { useState, type FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'

import { Icon } from '@matillion/component-library'

import { useCheckPermissions, useDeleteSecretReference } from 'api/hooks'
import type { GETSecretReferencesResponseParams } from 'api/types'

import EllipsesActionItem, {
  type ThemeType
} from 'components/EllipsesActionItem/EllipsesActionItem'
import ConfirmCancelModal from 'components/Modal/ProjectExplorerModal'

import { QueryKey } from 'constants/endpoint'

import { PermissionsType, SecretReferenceTypes } from 'types'
import { ResourceType } from 'types/ResourceTypes'

interface ActionCellProps {
  secret: GETSecretReferencesResponseParams
  onSecretDetailsHandler: VoidFunction
  onDeleteHandler: (secretName: string, isSuccess?: boolean) => void
}

const ActionCell: FunctionComponent<ActionCellProps> = ({
  secret: { id, name },
  onSecretDetailsHandler,
  onDeleteHandler
}) => {
  const { projectId } = useParams()
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const { mutateAsync, isLoading } = useDeleteSecretReference(projectId!)

  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false)
  const { data: deletePermission } = useCheckPermissions({
    resourceType: ResourceType.Project,
    resourceId: projectId!,
    permission: PermissionsType.DeleteSecrets
  })

  const onSwitchModal = () => {
    setIsDeleteModalVisible((v) => !v)
  }

  const onDelete = () => {
    mutateAsync({ values: { id } })
      .then(() => {
        queryClient.invalidateQueries([
          QueryKey.SECRET_REFERENCES,
          projectId!,
          SecretReferenceTypes.PASSWORD
        ])
        onSwitchModal()

        onDeleteHandler(name)
      })
      .catch(() => {
        onDeleteHandler(name, false)
      })
  }

  return (
    <>
      <EllipsesActionItem
        actions={[
          {
            title: t('secrets.secretDefinitionListing.actions.view'),
            action: onSecretDetailsHandler,
            icon: <Icon.Container />,
            dataTestId: 'ellipses-action-view-secret-details'
          },
          ...(deletePermission
            ? [
                {
                  title: t('secrets.secretDefinitionListing.actions.delete'),
                  action: onSwitchModal,
                  icon: <Icon.Trash />,
                  type: 'danger' as ThemeType,
                  dataTestId: 'ellipses-action-delete-secret'
                }
              ]
            : [])
        ]}
      />

      {isDeleteModalVisible && (
        <ConfirmCancelModal
          onValidate={onDelete}
          onCancel={onSwitchModal}
          content={t('secrets.deleteSecretReference.modal.content')}
          validate={t('secrets.deleteSecretReference.modal.validate')}
          waiting={isLoading}
        />
      )}
    </>
  )
}

export default ActionCell
