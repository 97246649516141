import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { Navigate, useParams } from 'react-router-dom'

import { EditableText, Typography } from '@matillion/component-library'

import {
  useCheckPermissions,
  useGetProject,
  useNetworkErrorToast
} from 'api/hooks'
import { usePatchProject } from 'api/hooks/usePatchProject'

import { Breadcrumbs } from 'components/Breadcrumbs'
import { Header, HeaderTitle } from 'components/Header'

import { QueryKey } from 'constants/endpoint'
import { AppRoutes } from 'constants/route'

import { useFlags } from 'hooks/flags'

import ProjectDetailsSummary from 'modules/Projects/ProjectDetails/components/ProjectDetailsSummary'

import { PermissionsType } from 'types'
import { ResourceType } from 'types/ResourceTypes'

import PlaceholderFields from './PlaceholderFields'
import classes from './ProjectDetailsHeader.module.scss'

const ProjectDetailsHeader = () => {
  const { projectId } = useParams()
  const { t } = useTranslation()
  const {
    data: projectData,
    error,
    isError,
    isLoading: isProjectLoading
  } = useGetProject(projectId!)
  const makeErrorToast = useNetworkErrorToast()
  const queryClient = useQueryClient()
  const { mutateAsync } = usePatchProject()
  const [projectName, setProjectName] = useState('')
  const [projectDescription, setProjectDescription] = useState('')
  const { enableEditProjectDetails } = useFlags()
  const { data: editPermission = { data: false } } = useCheckPermissions({
    resourceType: ResourceType.Project,
    resourceId: projectId!,
    permission: PermissionsType.EditProject
  })

  useEffect(() => {
    if (projectData) {
      setProjectName(projectData.name)
      setProjectDescription(projectData.description)
    }
  }, [projectData])

  const breadcrumbList = [
    {
      text: t('projectsListing.title'),
      href: AppRoutes.getDashboard(),
      isLoading: false
    },
    {
      text: projectData?.name as string,
      isLoading: isProjectLoading
    }
  ]

  if (isError) {
    makeErrorToast({ message: error.message })
    return <Navigate to={AppRoutes.getDashboard()} />
  }

  if (!projectData && !isProjectLoading) {
    makeErrorToast({ message: 'The project does not exist' })
    return <Navigate to={AppRoutes.getDashboard()} />
  }

  const handleProjectNameChange = async (newName: string) => {
    try {
      await mutateAsync({
        values: { name: newName },
        projectId: projectId!
      })

      setProjectName(newName)
      queryClient.invalidateQueries({
        queryKey: [QueryKey.PROJECT_DETAIL, projectId]
      })
      queryClient.invalidateQueries({
        queryKey: [QueryKey.PROJECTS_LIST]
      })
    } catch (_e) {
      // TO-DO: update MCL to take custom error handler
      const oldValue = projectName
      setProjectName('')
      setTimeout(() => {
        setProjectName(oldValue)
      }, 0)
    }
  }
  const handleProjectDescriptionChange = async (newDescription: string) => {
    try {
      await mutateAsync({
        values: { description: newDescription },
        projectId: projectId!
      })

      setProjectDescription(newDescription)
      queryClient.invalidateQueries({
        queryKey: [QueryKey.PROJECT_DETAIL, projectId]
      })
      queryClient.invalidateQueries({
        queryKey: [QueryKey.PROJECTS_LIST]
      })
    } catch (_e) {
      // TO-DO: update MCL to take custom error handler
      const oldValue = projectDescription
      setProjectDescription('')
      setTimeout(() => {
        setProjectDescription(oldValue)
      }, 0)
    }
  }

  return (
    <div className={classes.ProjectDetailsHeader}>
      <Breadcrumbs
        items={breadcrumbList}
        data-testid="project-details-breadcrumbs"
      />
      <div className={classes.ProjectDetailsHeader__TopSection}>
        <Header
          fullWidth
          centerAlign={false}
          className={classes.ProjectDetailsHeader__Header}
        >
          {isProjectLoading ? (
            <PlaceholderFields />
          ) : (
            <>
              {enableEditProjectDetails && editPermission ? (
                <>
                  <EditableText
                    text={projectName}
                    onChange={(e) => {
                      void handleProjectNameChange(e)
                    }}
                    typographyProps={{ format: 'tl' }}
                    data-testid="project-detail-title"
                  />
                  <EditableText
                    text={projectDescription}
                    onChange={(e) => {
                      void handleProjectDescriptionChange(e)
                    }}
                    data-testid="project-detail-support-text"
                    allowEmptyText
                    placeholder={t(
                      'projectDetail.header.description.placeholder'
                    )}
                  />
                </>
              ) : (
                <>
                  <HeaderTitle
                    data-testid="project-detail-title"
                    centerAlign={false}
                    format="tl"
                  >
                    {projectData.name}
                  </HeaderTitle>
                  {Boolean(projectData.description) && (
                    <Typography
                      className={classes.ProjectDetailsHeader__Description}
                      data-testid="project-detail-support-text"
                    >
                      {projectData.description}
                    </Typography>
                  )}
                </>
              )}
            </>
          )}
        </Header>
        <div className={classes.ProjectDetailsHeader__PanelWrapper}>
          <ProjectDetailsSummary />
        </div>
      </div>
    </div>
  )
}

export default ProjectDetailsHeader
