import { type FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'

import { NO_DATA } from 'constants/strings'

import MetricsHeader from 'modules/Projects/StreamingPipelineDashboard/Metrics/components/MetricsHeader/MetricsHeader'
import { type SummaryField } from 'modules/Projects/StreamingPipelineDashboard/Summary/components/StreamingSummaryBlock/StreamingSummaryBlock'

import {
  type SourceSchema,
  type StreamingPipelineMetrics
} from 'types/Streaming'

interface SelectedTablesHeaderProps {
  onDatabaseClick: () => void
  database?: string
  selectedSchema?: SourceSchema
  metrics?: StreamingPipelineMetrics
  filter: string
  setFilter: (value: string) => void
}

const SelectedTablesHeader: FunctionComponent<SelectedTablesHeaderProps> = ({
  onDatabaseClick,
  database,
  selectedSchema,
  metrics,
  filter,
  setFilter
}) => {
  const { t } = useTranslation()

  const additionalMetricFields: SummaryField[] = []
  if (!selectedSchema) {
    additionalMetricFields.push({
      heading: t('streamingPipelineDashboard.metrics.tables.rowsChanged'),
      content: metrics?.rowsChangedLastMinute?.toString() ?? NO_DATA,
      testId: 'rows-captured-last-minute'
    })
  }

  return (
    <MetricsHeader
      onDatabaseClick={onDatabaseClick}
      fields={additionalMetricFields}
      database={database}
      selectedSchema={selectedSchema?.schema}
      filter={filter}
      setFilter={setFilter}
    />
  )
}

export default SelectedTablesHeader
