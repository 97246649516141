import { useMutation, type UseMutationOptions } from 'react-query'

import { type AxiosError } from 'axios'

import { updateEnvironmentV2 } from 'api/clients'
import { useClient } from 'api/hooks/useClient'
import { type PUTEnvironmentRequestBody } from 'api/types'

import { QueryKey } from 'constants/endpoint'

type TError = AxiosError<unknown>
interface TVariables {
  values: PUTEnvironmentRequestBody
  environmentId: string
  projectId: string
}

type Options = UseMutationOptions<unknown, AxiosError, TVariables>

export const usePutEnvironmentV2 = (options?: Options) => {
  const client = useClient()

  return useMutation<unknown, TError, TVariables>(
    [QueryKey.EDIT_ENVIRONMENT],
    async (data: TVariables) => {
      const { values, environmentId, projectId } = data
      return updateEnvironmentV2({ projectId, environmentId }, values, client)
    },
    options
  )
}
