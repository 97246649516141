import { add } from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'

// Method so that it instantiates a new object every time to avoid accidental mutations between components
/**
 *
 * @param timezone Optional timezone eg. "Europe/London"
 * @returns The current time plus 2 min. If timezone not provided the returned date will be based on user's locale
 */
export const nowPlus2Minutes = (timezone?: string) =>
  timezone
    ? utcToZonedTime(add(Date.now(), { minutes: 2 }), timezone)
    : add(new Date(), {
        minutes: 2
      })
