import { type FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'

import classNames from 'classnames'

import { Typography } from '@matillion/component-library'

import classes from 'components/Form/Form.module.scss'
import { Header, HeaderTitle } from 'components/Header'

import {
  CloudCredentialName,
  Environment
} from 'modules/Projects/AssociateCloudCredentials/AssociateEnvironmentToCloudCredential/FormContent/Fields'

interface AssociateCredentialsProps {
  onFormCancel: () => void
}

const AssociateEnvironmentToCloudCredential: FunctionComponent<
  AssociateCredentialsProps
> = ({ onFormCancel }) => {
  const { t } = useTranslation()

  return (
    <>
      <Header
        onCancel={onFormCancel}
        modalContent={t('associateCredentials.confirmCancelModal.content')}
        showCancel
      >
        <HeaderTitle data-testid="associate-to-environment-provider-title">
          {t('associateCredentials.title')}
        </HeaderTitle>
        <Typography as="p" data-testid="associate-to-environment-provider-text">
          {t('associateCredentials.environmentToCloudCredentials.supportText')}
        </Typography>
      </Header>
      <div
        className={classNames(
          classes.Form__InnerWrap,
          classes['Form__InnerWrap--space-top']
        )}
      >
        <CloudCredentialName />
        <Environment />
      </div>
    </>
  )
}

export default AssociateEnvironmentToCloudCredential
