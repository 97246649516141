import { useEffect, useState, type FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import classNames from 'classnames'
import { capitalize } from 'lodash'

import {
  Button,
  DataGrid,
  Icon,
  Loader,
  type SortState
} from '@matillion/component-library'

import { useGetProject, useGetProjects } from 'api/hooks'
import { type GETProjectResponse } from 'api/types'

import { CircledImageContent } from 'components/CircledImageContent'
import { GridLayout } from 'components/GridLayout'
import { Header, HeaderTitle, HeaderWithCTA } from 'components/Header'
import * as Logos from 'components/Logo'
import { TypographyWithToolTip } from 'components/TypographyWithTooltip'

import { AppRoutes } from 'constants/route'

import { useFlags } from 'hooks/flags'

import { WarehouseLabels } from 'types'

import { ColumnKeys } from './'
import ActionCell from './ActionCell'
import { DeleteProjectForm } from './DeleteProject'
import classes from './ProjectsListing.module.scss'
import { projectsSort } from './projectsListing.util'

interface ProjectToDelete {
  id: string
  name: string
}

const ProjectsListing: FunctionComponent = () => {
  const [sortState, setSortState] =
    useState<Partial<SortState<GETProjectResponse>>>()
  const [selectedProjectId, setSelectedProjectId] = useState<string>('')
  const [projectToDelete, setProjectToDelete] = useState<ProjectToDelete>()
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { enableProjectListMeatballNav } = useFlags()

  const {
    data: projectsData,
    isLoading: isProjectsLoading,
    refetch: refetchProjects
  } = useGetProjects()

  const { isError: isSelectedProjectError, refetch: refetchProject } =
    useGetProject(selectedProjectId, { enabled: false })

  if (sortState) {
    const projectsSortFunction = projectsSort(sortState)
    projectsData?.sort(projectsSortFunction)
  }

  useEffect(() => {
    if (selectedProjectId) {
      refetchProject()
    }
  }, [refetchProject, selectedProjectId])

  useEffect(() => {
    if (!isSelectedProjectError && !!selectedProjectId) {
      navigate(AppRoutes.getProjectDetails(selectedProjectId))
    }
    if (sortState === undefined) {
      const projectsSortFunction = projectsSort({ name: 'ASC' })
      setSortState({ name: 'ASC' })
      projectsData?.sort(projectsSortFunction)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSelectedProjectError, selectedProjectId])

  const resetModals = () => {
    setIsDeleteModalOpen(false)
  }

  const handleDeleteWrapup = () => {
    resetModals()
    refetchProjects()
  }

  const handleProjectDelete = (project: ProjectToDelete) => {
    setProjectToDelete({ id: project.id, name: project.name })
    setIsDeleteModalOpen(true)
  }

  if (isProjectsLoading) {
    return <Loader />
  }

  return (
    <GridLayout>
      <Header fullWidth centerAlign={false}>
        <HeaderWithCTA>
          <HeaderTitle data-testid="projects-title" centerAlign={false}>
            {t('projectsListing.title')}
          </HeaderTitle>
          <Button
            text={t('projectsListing.buttonText')}
            onClick={() => {
              navigate(AppRoutes.getProjectsAdd())
            }}
            data-testid="add-project-button"
            alt="positive"
          />
        </HeaderWithCTA>
      </Header>
      <div className={classNames(classes.ProjectsListing)}>
        <DataGrid
          className={classNames(classes.ProjectsListing__Grid)}
          rowClassName={classNames(classes.ProjectsListing__GridRow)}
          data-testid="projects-data-grid"
          aria-label="Projects Data Grid"
          defaultSort={{ name: 'ASC' }}
          columns={[
            {
              key: ColumnKeys.name,
              title: t('projectsListing.column.col1'),
              as: Button,
              className: classNames(classes.ProjectsListing__GridCell),
              mapValues: (row: GETProjectResponse) => ({
                children: row.name,
                onClick: () => {
                  setSelectedProjectId(row.id)
                },
                alt: 'text',
                className: classNames(
                  classes['ProjectsListing--content-link'],
                  classes['ProjectsListing--content-large'],
                  classes['ProjectsListing--content-ellipsis-name']
                )
              }),
              sortable: true
            },
            {
              key: ColumnKeys.description,
              title: t('projectsListing.column.col2'),
              as: TypographyWithToolTip,
              className: classNames(
                classes.ProjectsListing__GridCell,
                classes['ProjectsListing__GridCell--stretch-col']
              ),
              mapValues: (row: GETProjectResponse) => ({
                children: (
                  <span
                    className={classNames(
                      classes['ProjectsListing--content-ellipsis']
                    )}
                  >
                    {row.description}
                  </span>
                ),
                tooltip: row.description
              }),
              sortable: true
            },
            {
              key: ColumnKeys.warehouse,
              title: t('projectsListing.column.col3'),
              as: CircledImageContent,
              className: classNames(classes.ProjectsListing__GridCell),
              mapValues: (row: GETProjectResponse) => {
                const warehouseName = capitalize(row.warehouse)
                const name =
                  WarehouseLabels[warehouseName as keyof typeof WarehouseLabels]
                const image =
                  Logos[warehouseName as keyof typeof Logos] ??
                  Icon[warehouseName as keyof typeof Icon]

                return {
                  Image: image,
                  logoName: warehouseName,
                  displayName: name,
                  imageSize: 34,
                  className: classNames(classes.ProjectsListing__Profile)
                }
              },
              sortable: true
            },
            ...(enableProjectListMeatballNav
              ? [
                  {
                    key: ColumnKeys.action,
                    title: t('projectsListing.column.col5'),
                    as: ActionCell,
                    className: classNames(
                      classes.ProjectsListing__GridCell,
                      classes['ProjectsListing__GridCell--action']
                    ),
                    mapValues: (row: GETProjectResponse) => ({
                      handleViewDetails: () => {
                        setSelectedProjectId(row.id)
                      },
                      clickDeleteProjectHandler: () => {
                        handleProjectDelete({ id: row.id, name: row.name })
                      }
                    }),
                    sortable: false
                  }
                ]
              : [])
          ]}
          rows={projectsData!}
          onSort={setSortState}
        />
      </div>
      {isDeleteModalOpen && (
        <DeleteProjectForm
          handleDeleteWrapup={handleDeleteWrapup}
          resetModals={resetModals}
          projectId={projectToDelete!.id}
          projectName={projectToDelete!.name}
        />
      )}
    </GridLayout>
  )
}

export default ProjectsListing
