import { useMemo, type ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'

import { useFormikContext } from 'formik'

import { AutoComplete, Field } from '@matillion/component-library'

import { useGetEnvironmentAgents } from 'api/hooks'

import classes from 'components/Form/Form.module.scss'

import { useAgentCloudProviderFieldWidget } from 'hooks/useAgentCloudProviderFieldWidget'

import type { CommonFieldsTypes } from 'modules/Common/Fields/types'
import { renderFormikError } from 'modules/utils'

import { type EnvironmentFormI } from 'types/FormTypes'

export const EnvironmentAgent = ({
  'data-testid': dataTestId = 'common-environment-agent',
  onChange
}: CommonFieldsTypes) => {
  const { t } = useTranslation()
  const {
    errors,
    handleBlur,
    handleChange,
    touched,
    values,
    setTouched,
    setValues
  } = useFormikContext<EnvironmentFormI>()

  const { agentCloudProviderFieldWidget } = useAgentCloudProviderFieldWidget()

  const {
    data: listOfAgents = [],
    isError,
    isLoading
  } = useGetEnvironmentAgents({ retryOnMount: false })

  const etlAgentError = renderFormikError(
    errors.etlAgent,
    Boolean(touched.etlAgent)
  )

  const agentsError = useMemo(() => {
    if (isError) {
      return t('fields.etlAgent.error.loadingError')
    }
    if (etlAgentError) {
      return t(etlAgentError)
    }
    if (listOfAgents.length === 0) {
      return t('fields.etlAgent.error.noItemsFound')
    }
    return ''
  }, [etlAgentError, t, isError, listOfAgents])

  const handleValueUpdates = (e: ChangeEvent<HTMLInputElement>) => {
    const notTouchedAutocomplete = undefined
    const emptyAutocomplete = { name: '', id: '' }
    setValues({
      ...values,
      [e.target.name]: e.target.value,
      secretKey: emptyAutocomplete,
      secretName: emptyAutocomplete
    })
    setTouched({
      ...touched,
      etlAgent: notTouchedAutocomplete,
      secretKey: notTouchedAutocomplete,
      secretName: notTouchedAutocomplete
    })
  }

  return (
    <div data-testid={dataTestId}>
      <Field
        inputComponent={AutoComplete}
        availableItems={listOfAgents.map((item) => ({
          id: item.agentId,
          name: item.name,
          agentCloudProvider: item.cloudProviderId.toString()
        }))}
        givenWidgets={agentCloudProviderFieldWidget()}
        loading={isLoading}
        title={t('fields.etlAgent.title')}
        name="etlAgent"
        data-testid={`${dataTestId}-input`}
        value={values.etlAgent}
        placeholder={
          isError
            ? t('fields.etlAgent.error.placeholderText')
            : t('fields.etlAgent.placeholderText')
        }
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          handleChange(e)
          handleValueUpdates(e)
        }}
        onBlur={handleBlur}
        className={classes.Form__SpacingStyles}
        supportText={t('fields.etlAgent.supportText')}
        errorText={isLoading ? undefined : agentsError}
        hasError={
          Boolean(errors?.etlAgent?.id) &&
          Boolean(touched.etlAgent) &&
          !isLoading
        }
        required
        disabled={isError}
      />
    </div>
  )
}
