import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import classNames from 'classnames'
import { useFormikContext } from 'formik'

import { AutoComplete, Field } from '@matillion/component-library'

import { useGetEnvironmentAgents } from 'api/hooks'

import { useAgentCloudProviderFieldWidget } from 'hooks/useAgentCloudProviderFieldWidget'

import { type CommonFieldsTypes } from 'modules/Common/Fields/types'
import classes from 'modules/FormsContent/Form.module.scss'
import { renderFormikError } from 'modules/utils'

import { type AgentCloudProvider } from 'types/FormTypes'

export interface AgentFormikContext {
  agent: AgentCloudProvider
}

export const Agent = ({
  'data-testid': dataTestId = 'common-field-agent'
}: CommonFieldsTypes) => {
  const { errors, handleBlur, handleChange, touched, values } =
    useFormikContext<AgentFormikContext>()
  const { t } = useTranslation()
  const { agentCloudProviderFieldWidget } = useAgentCloudProviderFieldWidget()
  const {
    data: listOfAgents = [],
    isError: agentIsError,
    isLoading: agentIsLoading
  } = useGetEnvironmentAgents({ retryOnMount: false })

  const agentError = renderFormikError(errors.agent, Boolean(touched.agent))

  const getErrorMessage = useMemo(() => {
    if (agentIsError) {
      return t('commonForm.fields.agent.error.loadingError')
    } else if (!listOfAgents.length) {
      return t('commonForm.fields.agent.error.noItemsFound')
    } else if (agentError) {
      return t(agentError)
    }

    return ''
  }, [agentError, agentIsError, listOfAgents.length, t])

  return (
    <div data-testid={dataTestId}>
      <Field
        inputComponent={AutoComplete}
        loading={agentIsLoading}
        availableItems={listOfAgents.map((item) => ({
          id: item.agentId,
          name: item.name,
          agentCloudProvider: item.cloudProviderId.toString()
        }))}
        givenWidgets={agentCloudProviderFieldWidget()}
        title={t('commonForm.fields.agent.title')}
        name="agent"
        data-testid={`${dataTestId}-input`}
        value={values.agent}
        placeholder={
          agentIsError
            ? t('commonForm.fields.agent.error.placeholderText')
            : t('commonForm.fields.agent.placeholderText')
        }
        onChange={handleChange}
        onBlur={handleBlur}
        className={classNames(classes.Form__Field)}
        supportText={t('commonForm.fields.agent.supportText')}
        errorText={agentIsLoading ? undefined : getErrorMessage}
        hasError={
          Boolean(errors?.agent?.id) &&
          Boolean(touched.agent) &&
          !agentIsLoading
        }
        required
        disabled={agentIsError || !listOfAgents.length}
      />
    </div>
  )
}
