import { useState, type FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'

import { Icon } from '@matillion/component-library'

import { useCheckPermissions, useDeleteSecretReference } from 'api/hooks'
import { type GETSecretReferencesResponseParams } from 'api/types'

import EllipsesActionItem from 'components/EllipsesActionItem'
import ConfirmCancelModal from 'components/Modal/ProjectExplorerModal'

import { QueryKey } from 'constants/endpoint'

import { PermissionsType, SecretReferenceTypes } from 'types'
import { ResourceType } from 'types/ResourceTypes'

interface ActionCellProps {
  credential: GETSecretReferencesResponseParams
  onDeleteHandler: (name: string, isSuccess?: boolean) => void
}

const ActionCell: FunctionComponent<ActionCellProps> = ({
  credential: { id, name },
  onDeleteHandler
}) => {
  const { projectId } = useParams()
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const { mutateAsync, isLoading } = useDeleteSecretReference(projectId!)

  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false)
  const { data: deletePermission } = useCheckPermissions({
    resourceType: ResourceType.Project,
    resourceId: projectId!,
    permission: PermissionsType.DeleteSecrets
  })

  const onSwitchModal = () => {
    setIsDeleteModalVisible((v) => !v)
  }

  const onDelete = () => {
    mutateAsync({ values: { id } })
      .then(async () => {
        await queryClient.invalidateQueries([
          QueryKey.SECRET_REFERENCES,
          projectId!,
          SecretReferenceTypes.CLOUD_PLATFORM_CREDENTIALS
        ])
        onSwitchModal()

        onDeleteHandler(name)
      })
      .catch(() => {
        onDeleteHandler(name, false)
      })
  }

  return deletePermission ? (
    <>
      <EllipsesActionItem
        actions={[
          {
            title: t('secrets.cloudProviderCredentialsListing.actions.delete'),
            action: onSwitchModal,
            icon: <Icon.Trash />,
            type: 'danger',
            dataTestId: 'ellipses-action-delete-cloud-provider-credential'
          }
        ]}
      />

      {isDeleteModalVisible && (
        <ConfirmCancelModal
          onValidate={onDelete}
          onCancel={onSwitchModal}
          content={t('secrets.deleteCloudProviderCredential.modal.content')}
          validate={t('secrets.deleteCloudProviderCredential.modal.validate')}
          waiting={isLoading}
        />
      )}
    </>
  ) : null
}

export default ActionCell
