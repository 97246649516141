import { type AutoCompleteItemId } from '@matillion/component-library'

import { timezones } from 'constants/timezones'

export const sortedTimezonesAutocomplete = (): AutoCompleteItemId[] => {
  const autocompleteTimezones = timezones.map((zone) => ({
    id: zone.tzCode,
    name: zone.tzCode,
    disabled: false,
    utc: zone.utc
  }))
  autocompleteTimezones.sort((zone1, zone2) =>
    zone1.name < zone2.name ? -1 : 1
  )
  return autocompleteTimezones
}

export const groupAutocompleteTimezones = (): AutoCompleteItemId[] => {
  const timezoneList = sortedTimezonesAutocomplete()
  let previousGroup = ''
  const grouppedTimezones = timezoneList.reduce<AutoCompleteItemId[]>(
    (groupArray, currentTimezone) => {
      const currentGroup = currentTimezone.name.split('/')[0]
      if (currentGroup !== previousGroup) {
        previousGroup = currentGroup
        groupArray.push({
          id: `group-${currentGroup}`,
          name: currentGroup,
          disabled: true
        })
      }
      groupArray.push(currentTimezone)
      return groupArray
    },
    []
  )
  return grouppedTimezones
}
