import { type ChangeEvent } from 'react'

import { useFormikContext } from 'formik'

interface FormikFieldSpacesToHyphensProps {
  fieldName: string
}
export const useFormikFieldSpacesToHyphens = ({
  fieldName
}: FormikFieldSpacesToHyphensProps) => {
  const { setFieldValue } = useFormikContext<unknown>()

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFieldValue(fieldName, e.currentTarget.value.replace(/\s/g, '-'))
  }

  return handleInputChange
}
