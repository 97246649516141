import { type FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'

import { Modal, Typography } from '@matillion/component-library'

import snowflakeUrlHint from 'assets/snowflakeUrlHint.png'

import classes from 'modules/Common/WarehouseSources/Snowflake/Credentials/SnowflakeAccountHelp.module.scss'

interface SnowflakeAccountHelpProps {
  handleCloseModal: () => void
}

export const SnowflakeAccountHelp: FunctionComponent<
  SnowflakeAccountHelpProps
> = ({ handleCloseModal }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'warehouseProvisioning.snowflake.accountHelp'
  })
  return (
    <Modal
      size="mid-large"
      data-testid="snowflake-account-url-guide-modal"
      onCancel={handleCloseModal}
      ariaLabelledBy="modal-title"
    >
      <Typography format="tm">{t('title')}</Typography>
      <br />
      <Typography>
        {t('appLink.before')}
        <a
          href={t('appLink.link')}
          target="_blank"
          rel="noreferrer"
          className={classes.SnowflakeAccountHelp__Link}
        >
          {t('appLink.text')}
        </a>
        {t('appLink.after')}
      </Typography>
      <br />
      <Typography>{t('instructions')}</Typography>
      <br />
      <div>
        <img
          src={snowflakeUrlHint}
          alt="Snowflake account URL hint"
          className={classes.SnowflakeAccountHelp__Image}
        />
      </div>
      <br />
      <Typography>
        {t('documentationLink.before')}
        <a
          href={t('documentationLink.link')}
          target="_blank"
          rel="noreferrer"
          className={classes.SnowflakeAccountHelp__Link}
        >
          {t('documentationLink.text')}
        </a>
        {t('documentationLink.after')}
      </Typography>
    </Modal>
  )
}
