import { type FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import { Navigate, useNavigate } from 'react-router-dom'

import { Loader } from '@matillion/component-library'

import { useGetProjects } from 'api/hooks'
import { useGetProjectsStatus } from 'api/hooks/useGetProjectsStatus'

import { NoResourcesAvailable } from 'components/NoResourcesAvailable'

import { AppRoutes } from 'constants/route'

import { useClearSessionStorageForms } from 'hooks/clearSessionStorageForms'

import ProjectsListing from 'modules/Projects/ProjectsListing'

const ProjectsDashboard: FunctionComponent = () => {
  const { data: projectsData, isLoading } = useGetProjects()
  const { data: projectsStatus, isLoading: projectsStatusLoading } =
    useGetProjectsStatus()
  useClearSessionStorageForms()
  const { t } = useTranslation()
  const navigate = useNavigate()

  if (isLoading || projectsStatusLoading) {
    return <Loader />
  }

  if (projectsStatus?.followOnboarding) {
    return <Navigate to={AppRoutes.getOnboarding()} />
  }

  return projectsData?.length ? (
    <ProjectsListing />
  ) : (
    <NoResourcesAvailable
      title={t('createProject.title')}
      supportText={t('createProject.supportText')}
      buttonText={t('createProject.buttonText')}
      onButtonClick={() => {
        navigate(AppRoutes.getProjectsAdd())
      }}
      data-testid="no-projects-available"
    />
  )
}

export default ProjectsDashboard
