import { useState, type FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'

import { Typography } from '@matillion/component-library'

import SelectedTableExplorer from 'modules/Projects/StreamingPipelineDashboard/Metrics/components/SelectedTableExplorer/SelectedTableExplorer'
import classes from 'modules/Projects/StreamingPipelineDashboard/Metrics/components/SelectedTables/SelectedTables.module.scss'
import SelectedTablesHeader from 'modules/Projects/StreamingPipelineDashboard/Metrics/components/SelectedTablesHeader/SelectedTablesHeader'

import {
  groupTablesBySchema,
  type SourceSchema,
  type SourceTable,
  type StreamingPipelineMetrics
} from 'types/Streaming'

interface SelectedTablesProps {
  database?: string
  tables: SourceTable[]
  metrics?: StreamingPipelineMetrics
}

const SelectedTables: FunctionComponent<SelectedTablesProps> = ({
  database,
  tables,
  metrics
}) => {
  const { t } = useTranslation()

  const [selectedSchema, setSelectedSchema] = useState<SourceSchema>()
  const [filter, setFilter] = useState<string>('')

  if (tables.length === 0) {
    return (
      <div
        data-testid="no-tables-selected"
        className={classes.SelectedTables__MessageContainer}
      >
        <Typography format="ts" className={classes.SelectedTables__Heading}>
          {t('streamingPipelineDashboard.metrics.tables.noTables.heading')}
        </Typography>
        <Typography format="bcm">
          {t('streamingPipelineDashboard.metrics.tables.noTables.content')}
        </Typography>
      </div>
    )
  }

  return (
    <div
      className={classes.SelectedTables__Container}
      data-testid="tables-selected-content"
    >
      <SelectedTablesHeader
        onDatabaseClick={() => {
          setSelectedSchema(undefined)
          setFilter('')
        }}
        selectedSchema={selectedSchema}
        database={database}
        metrics={metrics}
        filter={filter}
        setFilter={setFilter}
      />
      <SelectedTableExplorer
        schemas={groupTablesBySchema(tables)}
        selectedSchema={selectedSchema}
        onSchemaSelect={(schema: SourceSchema) => {
          setSelectedSchema(schema)
          setFilter('')
        }}
        filter={filter}
      />
    </div>
  )
}

export default SelectedTables
