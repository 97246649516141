import type {
  GETEnvironmentResponse,
  GETEnvironmentsResponseParams,
  PUTEnvironmentRequestBody
} from 'api/types'

import { CloudProviders } from 'types/CloudProviders'
import { type AutoCompleteProps } from 'types/FormTypes'

interface FormValues extends GETEnvironmentResponse {
  agent: string
  awsCredentials?: AutoCompleteProps
  azureCredentials?: AutoCompleteProps
  gcpCredentials?: AutoCompleteProps
}

export const updateEnvironmentWithMultipleCloudCredentialsRequest = ({
  agent,
  awsCredentials,
  azureCredentials,
  gcpCredentials,
  ...environmentValues
}: FormValues): PUTEnvironmentRequestBody => {
  const requestBody: PUTEnvironmentRequestBody = {
    ...environmentValues,
    agent
  }

  if (awsCredentials?.id !== '0') {
    requestBody.awsCredentialsSecret = awsCredentials?.id
  }
  if (azureCredentials?.id !== '0') {
    requestBody.azureCredentialsSecret = azureCredentials?.id
  }

  if (gcpCredentials?.id !== '0') {
    requestBody.gcpCredentialsSecret = gcpCredentials?.id
  }

  return requestBody
}

interface FormValuesSingleCloudCredential {
  cloudCredentialId: string
  cloudCredentialProviderType: CloudProviders
  environment: GETEnvironmentsResponseParams
}

export const updateEnvironmentWithSingleCloudCredentialRequest = ({
  cloudCredentialId,
  cloudCredentialProviderType,
  environment
}: FormValuesSingleCloudCredential): PUTEnvironmentRequestBody => {
  let providerType = ''
  switch (cloudCredentialProviderType) {
    case CloudProviders.AWS:
      providerType = 'awsCredentialsSecret'
      break
    case CloudProviders.AZURE:
      providerType = 'azureCredentialsSecret'
      break
    case CloudProviders.GCP:
      providerType = 'gcpCredentialsSecret'
      break
    default:
      break
  }
  const providerSecrets: {
    awsCredentialsSecret?: string | null
    azureCredentialsSecret?: string | null
    gcpCredentialsSecret?: string | null
  } = {
    awsCredentialsSecret: environment.platformProviderSecrets.aws,
    azureCredentialsSecret: environment.platformProviderSecrets.azure,
    gcpCredentialsSecret: environment.platformProviderSecrets.gcp,
    [providerType]: cloudCredentialId
  }

  return {
    agent: environment.agentId,
    defaultRole: environment.defaultRole,
    warehouseConnection: environment.warehouseConnection,
    ...providerSecrets
  }
}
