import { createRoot } from 'react-dom/client'
import TagManager from 'react-gtm-module'
import { Route, Routes } from 'react-router-dom'

import { datadogLogs } from '@datadog/browser-logs'
import { datadogRum } from '@datadog/browser-rum'

import 'styles/base.scss'

import {
  GIT_OAUTH_REDIRECT,
  GitInterstitialAuthRedirectPage
} from '@matillion/git-component-library'

import config from 'config'

import 'config/i18n.config'

import App from 'App'

import { LOGOUT, OAUTH_REDIRECT } from 'constants/route'

import { initMarketo } from 'marketo'

import { Logout } from 'modules/Auth'
import OAuthHandler from 'modules/OAuthHandler'

import Providers from './Providers'

if (config.datadog) {
  const { rumService, service, ...options } = config.datadog

  datadogLogs.init({
    ...options,
    service,
    forwardErrorsToLogs: true,
    sampleRate: 100
  })

  datadogRum.init({
    ...options,
    version: config.version,
    service: rumService,
    sampleRate: 100,
    allowedTracingOrigins: [
      // https://regexr.com/7eq8s
      /^https:\/\/.+\.(?:platform|core)(?:-.+)?\.matillion\.com$/
    ]
  })
}

if (config.gtmId) {
  TagManager.initialize({ gtmId: config.gtmId })
}

if (config.marketo) {
  initMarketo(config.marketo.id)
}

const container = document.getElementById('root')
container?.setAttribute('data-app-environment', config.environment)
container?.setAttribute('data-app-version', config.version)

if (process.env.NODE_ENV === 'development' && config.environment !== 'test') {
  if (window.location.protocol === 'http:') {
    // Only import the MSW if app running locally and in http
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    import(/* webpackChunkName: "msw-dev-chunk" */ './api/mocks/browser').then(
      async ({ worker }) =>
        worker.start({ waitUntilReady: true }).then(() => {
          console.log('MSW working!')
        })
    )
  }
}

// @ts-expect-error container should never be empty
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container)!

root.render(
  <Providers>
    <Routes>
      <Route path={LOGOUT} element={<Logout />} />
      <Route path={OAUTH_REDIRECT} element={<OAuthHandler />} />
      <Route
        path={GIT_OAUTH_REDIRECT}
        element={<GitInterstitialAuthRedirectPage />}
      />
      <Route path="*" element={<App />} />
    </Routes>
  </Providers>
)
