import { type FC } from 'react'
import { useTranslation } from 'react-i18next'

import { Typography } from '@matillion/component-library'

import { ExternalLink } from 'components/ExternalLink'

import classes from './RecommendedDocumentation.module.scss'

export interface RecommendedDocumentationProps {
  subject: string
  documentationLink: string
  dataTestId?: string
}

export const RecommendedDocumentation: FC<RecommendedDocumentationProps> = ({
  subject,
  documentationLink,
  dataTestId
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'createStreamingPipeline.fields.common.documentation'
  })

  return (
    <div data-testid={dataTestId}>
      <Typography
        format="bcs"
        weight="bold"
        className={classes.RecommendedDocumentation__Title}
      >
        {t('title')}
      </Typography>
      <Typography format="bcs">
        {t('textBeforeType')} {subject} {t('textAfterType')}{' '}
        <ExternalLink
          href={documentationLink}
          text={t('linkText')}
          format="bcs"
          className={classes.RecommendedDocumentation__Link}
        />
        .
      </Typography>
    </div>
  )
}
