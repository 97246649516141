import { useMutation } from 'react-query'

import { type AxiosError } from 'axios'

import { postSnowflakeListRolesRequest } from 'api/clients'
import { useNetworkErrorToast, useStreamingClient } from 'api/hooks/index'
import {
  type ErrorResponse,
  type POSTSnowflakeListRolesResponse
} from 'api/types'

import { QueryKey } from 'constants/endpoint'

import { type SnowflakeConnectionType } from 'types/Streaming'

type TError = AxiosError<ErrorResponse>
interface TVariables {
  agentId: string
  connection: SnowflakeConnectionType
}

export const useFetchSnowflakeRoles = () => {
  const streamingClient = useStreamingClient()
  const makeErrorToast = useNetworkErrorToast()

  return useMutation<POSTSnowflakeListRolesResponse, TError, TVariables>({
    mutationKey: [QueryKey.STREAMING_LIST_SNOWFLAKE_ROLES],
    mutationFn: async ({ connection, agentId }) => {
      return postSnowflakeListRolesRequest(
        { agentId },
        connection,
        streamingClient
      )
    },

    onError: (error) => {
      makeErrorToast({
        message: error.response?.data.detail ?? error.message
      })
    }
  })
}
