import { t } from 'i18next'

export const roles: { [key: string]: string } = {
  read_only: t('accessListing.roles.readOnly'),
  user: t('accessListing.roles.user'),
  admin: t('accessListing.roles.admin')
}

// eslint-disable-next-line @typescript-eslint/member-delimiter-style
export const availableRoleItems: Array<{ id: string; name: string }> =
  Object.entries(roles).map(([id, name]) => ({ id, name }))
