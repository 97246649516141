export const useGetNumberOfDaysInMonth = (date: Date): number => {
  if (!date.getTime()) {
    return 0
  }

  const selectedYear = date.getFullYear()
  const selectedMonth = date.getMonth() + 1

  return new Date(selectedYear, selectedMonth, 0).getDate()
}
