import { type FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'

import { Icon } from '@matillion/component-library'

import EllipsesActionItem from 'components/EllipsesActionItem'

import { type ActionCellTypes } from '.'

const ActionCell: FunctionComponent<ActionCellTypes> = ({
  handleViewDetails,
  clickDeleteProjectHandler
}) => {
  const { t } = useTranslation()

  return (
    <EllipsesActionItem
      actions={[
        {
          title: t('projectsListing.actions.view'),
          action: handleViewDetails,
          icon: <Icon.File />,
          dataTestId: 'ellipses-action-view-project-details'
        },
        {
          title: t('projectsListing.actions.delete'),
          action: clickDeleteProjectHandler,
          icon: <Icon.Trash />,
          type: 'danger',
          dataTestId: 'ellipses-action-delete-project'
        }
      ]}
    />
  )
}

export default ActionCell
