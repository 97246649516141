import { type FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { Icon } from '@matillion/component-library'

import { useCheckPermissions } from 'api/hooks'

import EllipsesActionItem from 'components/EllipsesActionItem/EllipsesActionItem'

import { useFlags } from 'hooks/flags'

import { PermissionsType } from 'types'
import { ResourceType } from 'types/ResourceTypes'

import { type ActionCellTypes } from '.'

const ActionCell: FunctionComponent<ActionCellTypes> = ({
  onEditClick,
  onAssociateCredsClick,
  onEditUsersClick,
  item
}) => {
  const { t } = useTranslation()
  const { enableEnvironmentDefaultAccess } = useFlags()
  const { projectId } = useParams()
  const { data: editPermission } = useCheckPermissions({
    resourceType: ResourceType.Project,
    resourceId: projectId!,
    permission: PermissionsType.EditEnvironments
  })

  const handleEditClick = () => {
    onEditClick(item)
  }
  const handleEditUsersClick = () => {
    onEditUsersClick(item)
  }
  const handleAssociateCredsClick = () => {
    onAssociateCredsClick(item)
  }

  return editPermission ? (
    <EllipsesActionItem
      actions={
        enableEnvironmentDefaultAccess
          ? [
              {
                title: t('environmentsListing.actions.associateCreds'),
                action: handleAssociateCredsClick,
                icon: <Icon.Cloud />,
                dataTestId: 'ellipses-action-associate-credentials'
              },
              {
                title: t('environmentsListing.actions.edit'),
                action: handleEditClick,
                icon: <Icon.Pencil />,
                dataTestId: 'ellipses-action-edit-environment'
              },
              {
                title: t('environmentsListing.actions.editUsers'),
                action: handleEditUsersClick,
                icon: <Icon.Pencil />,
                dataTestId: 'ellipses-action-edit-environment-users'
              }
            ]
          : [
              {
                title: t('environmentsListing.actions.associateCreds'),
                action: handleAssociateCredsClick,
                icon: <Icon.Cloud />,
                dataTestId: 'ellipses-action-associate-credentials'
              },
              {
                title: t('environmentsListing.actions.edit'),
                action: handleEditClick,
                icon: <Icon.Pencil />,
                dataTestId: 'ellipses-action-edit-environment'
              }
            ]
      }
    />
  ) : null
}

export default ActionCell
