import { useMemo } from 'react'
import { MutationCache, QueryCache, QueryClient } from 'react-query'

import { useGitErrorHandler } from '@matillion/git-component-library'

import { QUERY_SMART_RETRIES } from 'constants/endpoint'

export const useProjectExplorerQueryClient = () => {
  const { handleMutationError, handleQueryError } = useGitErrorHandler()

  return useMemo(() => {
    return new QueryClient({
      mutationCache: new MutationCache({
        // @ts-expect-error TODO: Should start working after SEPE-2272
        onError: handleMutationError
      }),
      queryCache: new QueryCache({
        // @ts-expect-error TODO: Should start working after SEPE-2272
        onError: handleQueryError
      }),
      defaultOptions: {
        queries: {
          staleTime: 1000 * 60 * 5, // 5 minutes
          refetchOnWindowFocus: false,
          refetchOnReconnect: false,
          refetchOnMount: true,
          retry: QUERY_SMART_RETRIES,
          retryOnMount: true
        }
      }
    })
  }, [handleQueryError, handleMutationError])
}

export default useProjectExplorerQueryClient
