import { useMutation, type UseMutationOptions } from 'react-query'

import { type AxiosError } from 'axios'

import { updateSchedule } from 'api/clients'
import { useClient } from 'api/hooks/useClient'
import { type PUTScheduleRequest } from 'api/types'

import { QueryKey } from 'constants/endpoint'

type TError = AxiosError<unknown>
interface TVariables {
  values: PUTScheduleRequest
  scheduleId: string
}

type Options = UseMutationOptions<unknown, AxiosError, TVariables>

export const usePutSchedule = (options?: Options) => {
  const client = useClient()

  return useMutation<unknown, TError, TVariables>(
    [QueryKey.EDIT_SCHEDULE],
    async (data: TVariables) => {
      const { values, scheduleId } = data
      return updateSchedule(scheduleId, values, client)
    },
    options
  )
}
