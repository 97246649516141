import { useState, type FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'

import {
  Field,
  Icon,
  Toggle,
  Tooltip,
  Typography
} from '@matillion/component-library'

import { usePutStreamingPipelineAlertPreference } from 'api/hooks/streaming/usePutStreamingPipelineAlertPreference'

import classes from './AlertPreferenceToggle.module.scss'

interface AlertPreferenceToggleProps {
  projectId: string
  pipelineId: string
  initialChecked: boolean
}

const AlertPreferenceToggle: FunctionComponent<AlertPreferenceToggleProps> = ({
  projectId,
  pipelineId,
  initialChecked
}) => {
  const { t } = useTranslation()

  const { mutate, isLoading } = usePutStreamingPipelineAlertPreference(
    projectId,
    pipelineId
  )

  const [currentPreference, setCurrentPreference] = useState(initialChecked)

  return (
    <div className={classes.AlertPreferenceToggle__Container}>
      <Typography format="bcm">
        {t('streamingPipelineDashboard.events.alerts.fieldText')}
      </Typography>
      <Tooltip
        content={
          <Typography format="bcm">
            {t('streamingPipelineDashboard.events.alerts.tooltip')}
          </Typography>
        }
        className={classes.AlertPreferenceToggle__Tooltip}
      >
        <div data-testid="alert-tooltip-icon">
          <Icon.InfoNoBg className={classes.AlertPreferenceToggle__Icon} />
        </div>
      </Tooltip>
      <Field
        checked={currentPreference}
        onChange={() => {
          mutate(
            { values: { enabled: !currentPreference } },
            {
              onSuccess: () => {
                setCurrentPreference(!currentPreference)
              }
            }
          )
        }}
        disabled={isLoading}
        inputComponent={Toggle}
        data-testid="alert-preference-toggle"
      />
    </div>
  )
}

export default AlertPreferenceToggle
