import { useEffect, useMemo, useState, type FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'

import {
  LoadingSpinner,
  Toaster,
  Typography,
  type SortState
} from '@matillion/component-library'

import { useCheckPermissions, useGetSecretReferences } from 'api/hooks'
import { type GETSecretReferencesResponseParams } from 'api/types'

import { QueryKey } from 'constants/endpoint'
import { AppRoutes } from 'constants/route'

import { sortByColumn } from 'modules/Secrets/utils'

import { PermissionsType, SecretReferenceTypes } from 'types'
import { ResourceType } from 'types/ResourceTypes'

import ListingHeader from './components/ListingHeader'
import NoCreatePermission from './components/NoCreatePermission'
import NoData from './components/NoData'
import NoReadPermission from './components/NoReadPermission'
import OAuthDataGrid from './components/OAuthDataGrid'

const OAuthListing: FunctionComponent = () => {
  const { makeToast } = Toaster.useToaster()
  const { projectId } = useParams()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const queryClient = useQueryClient()

  const [sortState, setSortState] = useState<
    Partial<SortState<GETSecretReferencesResponseParams>>
  >({ name: 'ASC' })

  const {
    data: oAuthsData = [],
    isError: isOAuthError,
    isLoading: isOAuthLoading,
    refetch
  } = useGetSecretReferences(projectId!, [
    SecretReferenceTypes.OAUTH_USER_TOKENS,
    SecretReferenceTypes.OAUTH_AUTHORIZATION_CODE,
    SecretReferenceTypes.OAUTH_CLIENT_CRED,
    SecretReferenceTypes.OAUTH_CLIENT_CREDENTIALS,
    SecretReferenceTypes.COMPOSITE_TOKEN
  ])

  const { data: createPermission, isLoading: isCreatePermissionLoading } =
    useCheckPermissions({
      resourceType: ResourceType.Project,
      resourceId: projectId!,
      permission: PermissionsType.CreateOAuths
    })
  const { data: viewPermission, isLoading: isViewPermissionLoading } =
    useCheckPermissions({
      resourceType: ResourceType.Project,
      resourceId: projectId!,
      permission: PermissionsType.ViewOAuths
    })

  const sortedOAuths = useMemo(
    () => sortByColumn(oAuthsData, sortState),
    [sortState, oAuthsData]
  )

  const redirectCallback = () => {
    navigate(AppRoutes.getCreateOAuthConnection(projectId!))
  }

  const handleDelete = (name: string, isSuccess = true) => {
    if (isSuccess) {
      refetch()

      makeToast({
        title: t('secrets.deleteOAuth.responseMessage.success', {
          oauth: name
        }),
        message: '',
        type: 'success'
      })
    } else {
      makeToast({
        title: t('secrets.deleteOAuth.responseMessage.error', {
          oauth: name
        }),
        message: '',
        type: 'error'
      })
    }
  }

  const getContent = () => {
    return isOAuthError ? (
      <Typography>{t('secrets.oauthListing.error')}</Typography>
    ) : (
      <OAuthDataGrid
        oauths={sortedOAuths!}
        onSort={setSortState}
        onDeleteHandler={handleDelete}
      />
    )
  }

  useEffect(() => {
    sortByColumn(oAuthsData, sortState)

    return () => {
      queryClient.removeQueries([
        QueryKey.USER_PERMISSIONS,
        PermissionsType.CreateOAuths
      ])
      queryClient.removeQueries([
        QueryKey.USER_PERMISSIONS,
        PermissionsType.DeleteOAuths
      ])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (
    !oAuthsData.length &&
    !isOAuthError &&
    !isOAuthLoading &&
    !isViewPermissionLoading &&
    !isCreatePermissionLoading
  ) {
    if (!createPermission) {
      return <NoCreatePermission />
    } else {
      return <NoData onAddOAuth={redirectCallback} />
    }
  }

  return (
    <>
      <ListingHeader
        onAddOAuth={redirectCallback}
        permission={createPermission}
      />
      {isOAuthLoading ||
      isViewPermissionLoading ||
      (isCreatePermissionLoading && !oAuthsData.length) ? (
        <LoadingSpinner />
      ) : (
        <>{!viewPermission ? <NoReadPermission /> : getContent()}</>
      )}
    </>
  )
}

export default OAuthListing
