export const useIsValidJSON = (text?: string): boolean => {
  if (!text) return false

  try {
    JSON.parse(text)
    return true
  } catch (e) {
    return false
  }
}
