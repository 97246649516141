import { useState, type FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'

import { Typography } from '@matillion/component-library'

import SnapshottingHeader from 'modules/Projects/StreamingPipelineDashboard/Metrics/components/SnapshottingHeader/SnapshottingHeader'
import SnapshottingTableExplorer from 'modules/Projects/StreamingPipelineDashboard/Metrics/components/SnapshottingTableExplorer/SnapshottingTableExplorer'

import {
  isStoppable,
  type RowsScanned,
  type StreamingPipelineSnapshotMetrics,
  type StreamingPipelineStatus
} from 'types/Streaming'

import classes from './Snapshotting.module.scss'

interface SnapshottingProps {
  snapshotMetrics?: StreamingPipelineSnapshotMetrics
  database?: string
  status: StreamingPipelineStatus
}

const Snapshotting: FunctionComponent<SnapshottingProps> = ({
  snapshotMetrics,
  database,
  status
}) => {
  const { t } = useTranslation()

  const [selectedSchema, setSelectedSchema] = useState<RowsScanned>()
  const [filter, setFilter] = useState<string>('')

  if (!snapshotMetrics) {
    const heading = isStoppable(status)
      ? t('streamingPipelineDashboard.metrics.snapshot.noSnapshot.heading')
      : t('streamingPipelineDashboard.metrics.snapshot.pipelineStopped.heading')
    const content = isStoppable(status)
      ? t('streamingPipelineDashboard.metrics.snapshot.noSnapshot.content')
      : t('streamingPipelineDashboard.metrics.snapshot.pipelineStopped.content')
    const dataTestId = isStoppable(status)
      ? 'no-snapshot-data'
      : 'no-snapshot-stopped'
    return (
      <div
        data-testid={dataTestId}
        className={classes.Snapshotting__MessageContainer}
      >
        <Typography format="ts" className={classes.Snapshotting__Heading}>
          {heading}
        </Typography>
        <Typography format="bcm">{content}</Typography>
      </div>
    )
  }

  return (
    <div
      className={classes.Snapshotting__Container}
      data-testid="snapshotting-content"
    >
      <SnapshottingHeader
        onDatabaseClick={() => {
          setSelectedSchema(undefined)
          setFilter('')
        }}
        selectedSchema={selectedSchema}
        database={database}
        snapshotMetrics={snapshotMetrics}
        filter={filter}
        setFilter={setFilter}
      />
      <SnapshottingTableExplorer
        schemas={snapshotMetrics.schemas ?? []}
        selectedSchema={selectedSchema}
        onSchemaSelect={(schema: RowsScanned) => {
          setSelectedSchema(schema)
          setFilter('')
        }}
        filter={filter}
      />
    </div>
  )
}

export default Snapshotting
