import { useFormikContext } from 'formik'

import { DefaultDatabase } from 'modules/Common/Fields'
import { type CreateProjectFormikValueTypes } from 'modules/Projects/CreateProject/CreateProjectForm'

const SnowflakeDatabase = () => {
  const { values } = useFormikContext<CreateProjectFormikValueTypes>()
  const isFieldEnabled = !!values.defaultRole?.id

  return (
    <DefaultDatabase
      listenerFields={['defaultRole']}
      isFieldEnabled={isFieldEnabled}
    />
  )
}

export default SnowflakeDatabase
