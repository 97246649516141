import type {
  POSTEnvironmentRequest,
  PUTEnvironmentRequestBody
} from 'api/types'

import { type CreateEnvironmentFormikValueTypes } from 'modules/Projects/CreateEnvironment/CreateEnvironmentForm'

import { AgentsSecretsHost, type Warehouse } from 'types'
import {
  type AutoCompleteProps,
  type DatabricksDefaultsI,
  type RedshiftCloudCredentialsI,
  type RedshiftDefaultsI
} from 'types/FormTypes'

import { getCredentials } from './createWarehouseDefaultsMutation'

export const createEnvironmentMutationData = (
  formValues: CreateEnvironmentFormikValueTypes,
  projectName: string,
  keyPairEnabled = false
): POSTEnvironmentRequest => {
  const data = updateEnvironmentMutationData(formValues)

  const authentication = keyPairEnabled
    ? {
        type: formValues.credentialsType.id,
        ...getCredentials(formValues)
      }
    : undefined

  const secretId = keyPairEnabled ? undefined : formValues.secretReferenceId

  return {
    name: `${projectName.trim()}-${formValues.environmentName.trim()}`,
    agent: data.agent,
    defaultRole: data.defaultRole,
    warehouseConnection: {
      ...data.warehouseConnection,
      secretId,
      authentication
    }
  }
}

export interface CloudCredentials {
  awsCredentials?: string | null
  azureCredentials?: string | null
  gcpCredentials?: string | null
}

interface FormValues
  extends DatabricksDefaultsI,
    RedshiftCloudCredentialsI,
    RedshiftDefaultsI,
    CloudCredentials {
  agentsSecretsManagement: AgentsSecretsHost
  account: string
  username: string
  tempSecretReferenceId?: string
  secretReferenceId: string
  passphraseSecretReferenceId?: string
  environmentDefaultAccess: AutoCompleteProps
  defaultRole: AutoCompleteProps
  defaultDatabase: AutoCompleteProps
  defaultWarehouse: AutoCompleteProps
  defaultSchema: AutoCompleteProps
  etlAgent: AutoCompleteProps
  matillionHostedAgentId: string
  credentialsType: {
    id: string
    name: string
  }
  passphrase?: string
  type: Warehouse | ''
  port?: number | null
  ssl?: boolean | null
}

export const updateEnvironmentMutationData = (
  formValues: FormValues,
  keyPairEnabled: boolean | string[] = false
): PUTEnvironmentRequestBody => {
  const authentication = keyPairEnabled
    ? {
        type: formValues.credentialsType.id,
        ...getCredentials(formValues)
      }
    : undefined

  let secretId: string | undefined =
    formValues.tempSecretReferenceId ?? formValues.secretReferenceId
  secretId = keyPairEnabled ? undefined : secretId

  return {
    agent:
      formValues.agentsSecretsManagement === AgentsSecretsHost.CustomerHosted
        ? formValues.etlAgent.id
        : formValues.matillionHostedAgentId,
    awsCredentialsSecret: formValues.awsCredentials,
    azureCredentialsSecret: formValues.azureCredentials,
    gcpCredentialsSecret: formValues.gcpCredentials,
    defaultRole: formValues.environmentDefaultAccess.id,
    warehouseConnection: {
      type: formValues.type,
      url: formValues.account.trim(),
      username: formValues.username.trim(),
      secretId,
      ...(formValues.defaultRole?.id && {
        defaultRole: formValues.defaultRole.id
      }),
      ...(formValues.defaultDatabase?.id && {
        defaultDatabase: formValues.defaultDatabase.id
      }),
      ...(formValues.defaultWarehouse?.id && {
        defaultWarehouse: formValues.defaultWarehouse.id
      }),
      ...(formValues.defaultStorageLocation?.id && {
        defaultStorageLocation: formValues.defaultStorageLocation.id
      }),
      defaultSchema: formValues.defaultSchema.id,
      port: formValues?.port,
      ssl: formValues?.ssl,
      ...(formValues.catalog?.id && {
        defaultCatalog: formValues.catalog.id
      }),
      ...(formValues.compute?.clusterId && {
        clusterId: formValues.compute.clusterId,
        clusterName: formValues.compute.clusterName,
        clusterType: formValues.compute.clusterType,
        state: formValues.compute.state
      }),
      authentication
    }
  }
}
