import { object, string } from 'yup'

import { type GETSecretReferencesResponse } from 'api/types'

import { useIsValidJSON } from 'hooks'

import { type FormValues } from 'modules/Projects/CloudProviderCredentials'

import { CloudProviders } from 'types/CloudProviders'
import { type AgentCloudProvider } from 'types/FormTypes'

export const providerValues: Partial<FormValues> = {
  accessKeyID: '',
  tenantID: '',
  clientID: '',
  secretKey: '',
  serviceAccount: ''
}

export const initialValues = (
  provider: CloudProviders,
  agent?: AgentCloudProvider
): FormValues => ({
  name: '',
  provider,
  agent: agent || { id: '', name: '', agentCloudProvider: '' },
  ...providerValues
})

export const commonSchema = (
  listOfSecretReferences: GETSecretReferencesResponse
) => ({
  name: string()
    .required(
      'cloudProviderCredentials.fields.common.credentialName.error.required'
    )
    .test(
      'name',
      'cloudProviderCredentials.fields.common.credentialName.error.notUnique',
      (value) => {
        if (!listOfSecretReferences.length) {
          return true
        }
        return listOfSecretReferences.findIndex((p) => p.name === value) === -1
      }
    ),
  provider: string()
    .oneOf(Object.values(CloudProviders))
    .required('cloudProviderCredentials.fields.common.provider.error.required'),
  agent: object({
    id: string().required('commonForm.fields.agent.error.required'),
    name: string().required('commonForm.fields.agent.error.required')
  })
    .nullable()
    .required('commonForm.fields.agent.error.unmatched')
})

export const dynamicProvidersSchema = (
  listOfSecretReferences: GETSecretReferencesResponse = []
) => {
  const cs = commonSchema(listOfSecretReferences)
  return {
    [CloudProviders.AWS]: object({
      ...cs,
      accessKeyID: string().required(
        'cloudProviderCredentials.fields.aws.accessKeyID.error.required'
      ),
      secretKey: string().required(
        'cloudProviderCredentials.fields.aws.secretKey.error.required'
      )
    }),
    [CloudProviders.AZURE]: object({
      ...cs,
      tenantID: string().required(
        'cloudProviderCredentials.fields.azure.tenantID.error.required'
      ),
      clientID: string().required(
        'cloudProviderCredentials.fields.azure.clientID.error.required'
      ),
      secretKey: string().required(
        'cloudProviderCredentials.fields.azure.secretKey.error.required'
      )
    }),
    [CloudProviders.GCP]: object({
      ...cs,
      serviceAccount: string()
        .required(
          'cloudProviderCredentials.fields.gcp.serviceAccount.error.required'
        )
        .test(
          'json format',
          'cloudProviderCredentials.fields.gcp.serviceAccount.error.invalidJSONInput',
          (value) => useIsValidJSON(value)
        )
    })
  }
}
