import { useEffect } from 'react'

export const usePolling = (
  onPollingTimeout: () => void,
  handlePolling: (suspendInterval?: () => void) => Promise<void>,
  isComplete = false,
  onCompleteCallback?: () => void,
  enabled = true
) => {
  useEffect(() => {
    if (!enabled || isComplete) return
    const startTime = Date.now()
    const timeOut = 3000 * 140
    handlePolling()

    const agentStatusTimer = setInterval(() => {
      if (Date.now() - startTime < timeOut) {
        handlePolling(() => {
          clearInterval(agentStatusTimer)
        })
      } else {
        clearInterval(agentStatusTimer)
        onPollingTimeout()
      }
    }, 3000)

    return () => {
      clearInterval(agentStatusTimer)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enabled, isComplete]) // Causing a refetch of the polling when enabled changes

  useEffect(() => {
    if (!isComplete || !onCompleteCallback) return

    // Redirect user to next page after small delay
    const timeout = setTimeout(() => {
      onCompleteCallback()
    }, 1500)

    return () => {
      clearTimeout(timeout)
    }
  }, [isComplete, onCompleteCallback])
}
