import { ONBOARDING_STORAGE_ITEM } from 'constants/persistance'

export const redirectToDesigner = (
  projectId: string,
  environmentId: string,
  agentId: string,
  designerFrontendUrl: string
) => {
  const url = new URL(
    `${designerFrontendUrl}/project/${encodeURIComponent(
      projectId
    )}/branch/main`
  )

  url.searchParams.append('environmentId', environmentId)
  url.searchParams.append('agentId', agentId)

  window.location.assign(url)
  // need to clear the session storage AFTER redirecting to designer, otherwise PE redirects user back to the start of onboarding when session storage is cleared
  window.sessionStorage.removeItem(ONBOARDING_STORAGE_ITEM)
}
