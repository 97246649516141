import { type FC } from 'react'
import { useTranslation } from 'react-i18next'

import classnames from 'classnames'

import { Typography } from '@matillion/component-library'
import { useUser } from '@matillion/hub-client'

import { ExternalLink } from 'components/ExternalLink'

import classes from 'modules/Projects/StreamingListing/components/Requirements/Requirements.module.scss'
import { getAgentsListingUrl } from 'modules/utils'

interface RequirementsProps {
  className?: string
}

export const Requirements: FC<RequirementsProps> = ({ className }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'streamingListing.requirements'
  })
  const { organisation } = useUser()

  return (
    <Typography
      format="bcm"
      className={classnames(classes.StreamingHeader__Requirements, className)}
      data-testid="requirements-text"
    >
      {t('text1')}
      <ExternalLink
        href={getAgentsListingUrl(organisation.subdomain, organisation.region)}
        text={t('agents')}
        className={classes.StreamingHeader__Link}
      />
      {t('text2')}
      <ExternalLink
        href={t('documentationLink')}
        text={t('documentation')}
        className={classes.StreamingHeader__Link}
      />
    </Typography>
  )
}
