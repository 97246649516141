import { useState, type FC } from 'react'
import { useTranslation } from 'react-i18next'

import { Field as FormikField, useFormikContext, type FieldProps } from 'formik'

import { Field } from '@matillion/component-library'

import { usePostValidateStreamingSignalTable } from 'api/hooks/streaming/usePostValidateStreamingSignalTable'
import { type POSTValidateStreamingConnectionResponse } from 'api/types'

import { InputButton } from 'components/InputButton/InputButton'

import { SchemaSelector } from 'modules/Projects/CreateStreamingPipeline/FormContent/ConfigurePipelineDetailsPage/Components/SchemaSelector'
import { SupportText } from 'modules/Projects/CreateStreamingPipeline/FormContent/ConfigurePipelineDetailsPage/Components/SupportText/SupportText'
import { FIELD_NAMES_SIGNAL_TABLE } from 'modules/Projects/CreateStreamingPipeline/FormContent/ConfigurePipelineDetailsPage/types'
import {
  mapSourceConnectionFormToDefinition,
  useFormMetadata,
  type FormValues
} from 'modules/Projects/CreateStreamingPipeline/FormContent/index'

import { STREAMING_SOURCES } from 'types/Streaming'

import { SignalTableSelector } from './SignalTableSelector'

export const SignalTableInput: FC = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'createStreamingPipeline.fields.pipelineConfig.signalTableInput'
  })
  const { t: tNoPrefix } = useTranslation()

  const { values } = useFormikContext<FormValues>()
  const { metadata, setMetadataProperty } = useFormMetadata()

  const { mutateAsync: mutateValidateSignalTable } =
    usePostValidateStreamingSignalTable()

  const [showSchemaSelector, setShowSchemaSelector] = useState<boolean>(false)
  const [selectedSchema, setSelectedSchema] = useState<string>()

  const disabled = metadata.validation.sourceConnection.isError !== false
  const selectedSignalTable =
    values.source.snapshot.onDemandSnapshot.signalTable
  const value = selectedSignalTable?.table
    ? `${selectedSignalTable.schema}/${selectedSignalTable.table}`
    : undefined

  const setIsError = (isError: boolean | null) => {
    setMetadataProperty('validation.pipelineConfiguration.isError', isError)
    setMetadataProperty('validation.signalTable.isError', isError)
  }

  const setErrorMessage = (message: string | null) => {
    setMetadataProperty(
      'validation.pipelineConfiguration.errorMessage',
      message
    )
    setMetadataProperty('validation.signalTable.errorMessage', message)
  }

  const onValidation = (result: POSTValidateStreamingConnectionResponse) => {
    if (result.success) {
      // we don't want to trigger the accordion to close here
      setIsError(null)
      setErrorMessage(null)
    } else {
      setIsError(true)
      setErrorMessage(result.message)
    }
  }

  const onValidationError = (result: {
    response: { data: { detail?: string } }
    message: string
  }) => {
    setIsError(true)
    setErrorMessage(result.response.data.detail ?? result.message)
  }

  const validateSignalTable = () => {
    const signalTable = values.source.snapshot.onDemandSnapshot.signalTable
    if (signalTable) {
      mutateValidateSignalTable({
        type: values.source.type.id,
        connection: mapSourceConnectionFormToDefinition(
          values.source.connection
        ),
        signalTable,
        agentId: values.agent.id
      }).then(onValidation, onValidationError)
    }
  }

  return (
    <div data-testid="signal-table-field-wrapper">
      <FormikField name={FIELD_NAMES_SIGNAL_TABLE}>
        {({ field, meta }: FieldProps<string>) => (
          <Field
            {...field}
            inputComponent={InputButton}
            dataTestId={'signal-table-input-button'}
            value={value}
            title={t('title')}
            placeholder={t('placeholder')}
            onClick={() => {
              setShowSchemaSelector(true)
            }}
            supportText={
              <SupportText
                supportText={t('supportText')}
                supportDocs={
                  STREAMING_SOURCES[values.source.type.id].docs.signalTable
                }
              />
            }
            disabled={disabled}
            disabledTooltipText={t('disabledTooltip')}
            hasError={!!meta.error || metadata.validation.signalTable.isError}
            isErrored={!!meta.error || metadata.validation.signalTable.isError}
            errorText={
              tNoPrefix(meta.error ?? '') ||
              metadata.validation.signalTable.errorMessage
            }
          />
        )}
      </FormikField>

      {showSchemaSelector && (
        <SchemaSelector
          onClose={() => {
            setShowSchemaSelector(false)
            validateSignalTable()
          }}
          onTransition={() => {
            setShowSchemaSelector(false)
          }}
          title={tNoPrefix(
            'createStreamingPipeline.fields.pipelineConfig.schemaSelector.signalTitle'
          )}
          setSelectedSchema={setSelectedSchema}
          showTablesSelected={false}
        />
      )}
      {selectedSchema && (
        <SignalTableSelector
          schema={selectedSchema}
          onClose={() => {
            setSelectedSchema(undefined)
            validateSignalTable()
          }}
          onPrevious={() => {
            setSelectedSchema(undefined)
            setShowSchemaSelector(true)
          }}
        />
      )}
    </div>
  )
}
