import { type FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'

import { Typography } from '@matillion/component-library'

import AlertPreference from 'modules/Projects/StreamingPipelineDashboard/Events/AlertPreference/AlertPreference'

import classes from './StreamingEventsHeader.module.scss'

interface StreamingEventsHeaderProps {
  projectId: string
  pipelineId: string
}

const StreamingEventsHeader: FunctionComponent<StreamingEventsHeaderProps> = ({
  projectId,
  pipelineId
}) => {
  const { t } = useTranslation()

  return (
    <div className={classes.StreamingEventsHeader__Container}>
      <Typography format="ts">
        {t('streamingPipelineDashboard.events.title')}
      </Typography>
      <AlertPreference projectId={projectId} pipelineId={pipelineId} />
    </div>
  )
}

export default StreamingEventsHeader
