import { useMemo, useState } from 'react'

import { object } from 'yup'

import { type GenericSchemaType } from 'types'

import CreateSecretDefinitionForm from './CreateSecretDefinitionForm'
import { SecretDefinitionFormContext } from './SecretDefinitionFormContext'

const SecretDefinitionFormProvider = () => {
  const [validationSchema, setValidationSchema] = useState<GenericSchemaType>(
    object({})
  )

  const values = useMemo(
    () => ({
      validationSchema,
      setValidationSchema
    }),
    [validationSchema, setValidationSchema]
  )

  return (
    <SecretDefinitionFormContext.Provider value={values}>
      <CreateSecretDefinitionForm />
    </SecretDefinitionFormContext.Provider>
  )
}
export default SecretDefinitionFormProvider
