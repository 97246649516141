import { type FC } from 'react'
import { useTranslation } from 'react-i18next'

import { useFormikContext } from 'formik'

import { Button } from '@matillion/component-library'

import { usePostValidateStreamingConnection } from 'api/hooks/streaming'
import { type POSTValidateStreamingConnectionResponse } from 'api/types'

import {
  mapSourceConnectionFormToDefinition,
  sourcePanelIsInvalid,
  type FormValues
} from 'modules/Projects/CreateStreamingPipeline/FormContent'

import classes from './SubmitButton.module.scss'

interface SourceConnectButtonProps {
  setIsError: (isError: boolean) => void
  setErrorMessage: (message: string | null) => void
}

export const SourceConnectButton: FC<SourceConnectButtonProps> = ({
  setIsError,
  setErrorMessage
}) => {
  const { t } = useTranslation()
  const { mutateAsync: mutateValidateConnection } =
    usePostValidateStreamingConnection()
  const { values } = useFormikContext<FormValues>()

  const onValidation = (result: POSTValidateStreamingConnectionResponse) => {
    if (result.success) {
      setIsError(false)
      setErrorMessage(null)
    } else {
      setIsError(true)
      setErrorMessage(result.message)
    }
  }

  const onValidationError = (result: {
    response: { data: { detail?: string } }
    message: string
  }) => {
    setIsError(true)
    setErrorMessage(result.response.data.detail ?? result.message)
  }

  return (
    <div
      data-testid="source-connect-button-wrapper"
      className={classes.SubmitButton__Wrapper}
    >
      <Button
        text={t(
          'createStreamingPipeline.fields.sourceConfig.connectButton.text'
        )}
        onClick={() => {
          if (sourcePanelIsInvalid(values)) {
            setIsError(true)
            setErrorMessage(t('createStreamingPipeline.missingValues'))
          } else {
            mutateValidateConnection({
              type: values.source.type.id,
              connection: mapSourceConnectionFormToDefinition(
                values.source.connection
              ),
              agentId: values.agent.id
            }).then(onValidation, onValidationError)
          }
        }}
        data-testid="source-connect-button"
        alt="primary"
      />
    </div>
  )
}
