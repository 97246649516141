import { useQuery, type UseQueryOptions } from 'react-query'

import { type AxiosError } from 'axios'

import { getSecretReference } from 'api/clients'
import { type GETSecretReferencesResponseParams } from 'api/types'

import { QueryKey } from 'constants/endpoint'

import { useClient } from './useClient'

type TData = GETSecretReferencesResponseParams
type TError = AxiosError<unknown>
type Options = Omit<
  UseQueryOptions<TData, TError, TData>,
  'queryKey' | 'queryFn'
>

export const useGetSecretReference = (
  projectId: string,
  secretReferenceId: string,
  envId: string | undefined,
  options?: Options
) => {
  const client = useClient()
  return useQuery<TData, TError>(
    [QueryKey.SECRET_REFERENCES, projectId, secretReferenceId, envId],
    async () =>
      getSecretReference(
        {
          projectId,
          secretReferenceId
        },
        client
      ),
    options
  )
}
