import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from 'react-query'

import { type AxiosError } from 'axios'

import { Toaster } from '@matillion/component-library'

import { stopStreamingPipeline } from 'api/clients'
import { useNetworkErrorToast, useStreamingClient } from 'api/hooks/index'
import { type ErrorResponse } from 'api/types'

import { QueryKey } from 'constants/endpoint'

type TError = AxiosError<ErrorResponse>
interface TVariables {
  pipelineId: string
  pipelineName: string
}

export const usePostStreamingPipelineStop = (projectId: string) => {
  const streamingClient = useStreamingClient()
  const makeErrorToast = useNetworkErrorToast()
  const { clearToasts, makeToast } = Toaster.useToaster()
  const { t } = useTranslation()
  const queryClient = useQueryClient()

  return useMutation<unknown, TError, TVariables>({
    mutationKey: [QueryKey.STREAMING_PIPELINE_STOP, projectId],
    mutationFn: async ({ pipelineId, pipelineName }) => {
      makeToast({
        title: t('streamingActions.stop.sending', { pipelineName }),
        message: '',
        type: 'info'
      })
      return stopStreamingPipeline({ projectId, pipelineId }, streamingClient)
    },
    onError: (error) => {
      makeErrorToast({ message: error.response?.data.detail ?? error.message })
    },
    onSuccess: (data, { pipelineId, pipelineName }) => {
      clearToasts()
      makeToast({
        title: t('streamingActions.stop.success', { pipelineName }),
        message: '',
        type: 'success'
      })
      setTimeout(() => {
        // Invalidate the status query to update the status after a short timeout
        queryClient.invalidateQueries([
          QueryKey.STREAMING_PIPELINE_STATUS,
          pipelineId
        ])
      }, 2000)
    }
  })
}
