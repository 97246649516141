export enum ColumnKeys {
  name = 'name',
  status = 'status',
  source = 'source',
  destination = 'destination',
  action = 'action'
}

export type SortableColumnsType = {
  [key in ColumnKeys]: ColumnKeys
}
