import i18next from 'i18next'
import { object, string } from 'yup'

import {
  type GETEnvironmentResponse,
  type GETSecretReferencesResponse
} from 'api/types'

import { type AutoCompleteProps } from 'types/FormTypes'

export const formSchema = ({ withGcp = false }) =>
  object({
    azureCredentials: object({
      id: string().required(
        i18next.t(
          'associateCredentials.fields.cloudCredentialField.error.required',
          { cloudProvider: 'Azure' }
        )
      ),
      name: string().required(
        i18next.t(
          'associateCredentials.fields.cloudCredentialField.error.required',
          { cloudProvider: 'Azure' }
        )
      )
    })
      .required(
        'associateCredentials.fields.cloudCredentialField.error.unmatched'
      )
      .nullable(),
    awsCredentials: object({
      id: string().required(
        i18next.t(
          'associateCredentials.fields.cloudCredentialField.error.required',
          { cloudProvider: 'AWS' }
        )
      ),
      name: string().required(
        i18next.t(
          'associateCredentials.fields.cloudCredentialField.error.required',
          { cloudProvider: 'AWS' }
        )
      )
    })
      .required(
        'associateCredentials.fields.cloudCredentialField.error.unmatched'
      )
      .nullable(),
    gcpCredentials: object({
      id: withGcp
        ? string().required(
            i18next.t(
              'associateCredentials.fields.cloudCredentialField.error.required',
              { cloudProvider: 'GCP' }
            )
          )
        : string().optional(),
      name: withGcp
        ? string().required(
            i18next.t(
              'associateCredentials.fields.cloudCredentialField.error.required',
              { cloudProvider: 'GCP' }
            )
          )
        : string().optional()
    })
      .required(
        'associateCredentials.fields.cloudCredentialField.error.unmatched'
      )
      .nullable()
  })

export interface FormValues {
  azureCredentials?: AutoCompleteProps
  awsCredentials?: AutoCompleteProps
  gcpCredentials?: AutoCompleteProps
  environmentName: string
}

export const initialAssociateCredentialsValues = (
  selectedEnvironment: GETEnvironmentResponse,
  secretReferences: GETSecretReferencesResponse
): FormValues => {
  const azureSecretCredentials = secretReferences.find(
    (x) => x.id === selectedEnvironment.platformProviderSecrets?.azure
  )
  const awsSecretCredentials = secretReferences.find(
    (x) => x.id === selectedEnvironment.platformProviderSecrets?.aws
  )

  const gcpSecretCredentials = secretReferences.find(
    (x) => x.id === selectedEnvironment.platformProviderSecrets?.gcp
  )

  return {
    azureCredentials: azureSecretCredentials ?? { id: '', name: '' },
    awsCredentials: awsSecretCredentials ?? { id: '', name: '' },
    gcpCredentials: gcpSecretCredentials ?? { id: '', name: '' },
    environmentName: selectedEnvironment?.name
  }
}
