import { useMutation, type UseMutationOptions } from 'react-query'

import { type AxiosError } from 'axios'

import { deleteBranch } from 'api/clients'
import { type DELETEBranchParams } from 'api/types'

import { useClient } from './useClient'

type Options = UseMutationOptions<
  void,
  AxiosError,
  {
    values: DELETEBranchParams
  }
>

export const useDeleteBranch = (options?: Options) => {
  const client = useClient({ showErrorDetailMessage: true })

  return useMutation(async (data: { values: DELETEBranchParams }) => {
    const { values } = data

    return deleteBranch(values, client)
  }, options)
}
