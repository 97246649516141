import { type FunctionComponent } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'

import {
  ADD,
  ASSOCIATE_CREDENTIALS,
  ASSOCIATE_ENVIRONMENT,
  CREATE_SECRET_DEFINITION,
  CREATE_STREAMING_PIPELINE,
  EDIT,
  EDIT_STREAMING_PIPELINE,
  GENERIC_PROJECT_DETAILS_TAB,
  PROJECT_DETAILS_BRANCHES,
  PROJECT_DETAILS_CLOUD_CREDENTIALS,
  PROJECT_DETAILS_ENVIRONMENTS,
  PROJECT_DETAILS_OAUTH,
  PROJECT_DETAILS_SCHEDULES,
  PROJECTS_ADD,
  PROJECTS_EDIT,
  STREAMING_PIPELINE_DASHBOARD
} from 'constants/route'

import { useFlags } from 'hooks/flags'

import AssociateCloudCredentialsToEnvironmentForm from 'modules/Projects/AssociateCloudCredentials/AssociateCloudCredentialsToEnvironment/AssociateCloudCredentialsToEnvironmentForm'
import AssociateEnvironmentToCloudCredentialForm from 'modules/Projects/AssociateCloudCredentials/AssociateEnvironmentToCloudCredential'
import CloudProviderCredentialsFormProvider from 'modules/Projects/CloudProviderCredentials'
import CreateBranch from 'modules/Projects/CreateBranch/CreateBranch'
import CreateEnvironment from 'modules/Projects/CreateEnvironment'
import CreateOAuthFormProvider from 'modules/Projects/CreateOAuth'
import CreateSchedule from 'modules/Projects/CreateSchedule'
import CreateStreamingPipeline from 'modules/Projects/CreateStreamingPipeline'
import EditBranch from 'modules/Projects/EditBranch/EditBranchForm'
import EditEnvironmentForm from 'modules/Projects/EditEnvironment/EditEnvironmentForm'
import EditSchedule from 'modules/Projects/EditSchedule'
import StreamingPipelineDashboard from 'modules/Projects/StreamingPipelineDashboard'
import SecretDefinitionFormProvider from 'modules/Secrets/CreateSecretDefinitionForm'

import ProjectDetails from './ProjectDetails'

const ProjectDetailsRoutes: FunctionComponent = () => {
  const { enableStreamingPipelines } = useFlags()

  return (
    <Routes>
      <Route
        path={`${PROJECT_DETAILS_ENVIRONMENTS}/${PROJECTS_ADD}/*`}
        element={<CreateEnvironment />}
      />
      <Route
        path={`${PROJECT_DETAILS_ENVIRONMENTS}/${PROJECTS_EDIT}/:envId/*`}
        element={<EditEnvironmentForm />}
      />
      <Route
        path={`${PROJECT_DETAILS_ENVIRONMENTS}/${ASSOCIATE_CREDENTIALS}/:envId`}
        element={<AssociateCloudCredentialsToEnvironmentForm />}
      />
      <Route
        path={`${PROJECT_DETAILS_ENVIRONMENTS}/${ASSOCIATE_ENVIRONMENT}/:secretId`}
        element={<AssociateEnvironmentToCloudCredentialForm />}
      />
      <Route
        path={CREATE_SECRET_DEFINITION}
        element={<SecretDefinitionFormProvider />}
      />
      <Route
        path={`${PROJECT_DETAILS_OAUTH}/${PROJECTS_ADD}/*`}
        element={<CreateOAuthFormProvider />}
      />
      <Route
        path={`${PROJECT_DETAILS_SCHEDULES}/${PROJECTS_ADD}/*`}
        element={<CreateSchedule />}
      />
      <Route
        path={`${PROJECT_DETAILS_SCHEDULES}/${PROJECTS_EDIT}/:scheduleId`}
        element={<EditSchedule />}
      />
      <Route
        path={`${PROJECT_DETAILS_CLOUD_CREDENTIALS}/${PROJECTS_ADD}`}
        element={<CloudProviderCredentialsFormProvider />}
      />
      <Route path={GENERIC_PROJECT_DETAILS_TAB} element={<ProjectDetails />} />
      <Route
        path={`${PROJECT_DETAILS_BRANCHES}/${ADD}/*`}
        element={<CreateBranch />}
      />
      <Route
        path={`${PROJECT_DETAILS_BRANCHES}/${EDIT}/:branchId`}
        element={<EditBranch />}
      />
      {enableStreamingPipelines && (
        <>
          <Route
            path={`${CREATE_STREAMING_PIPELINE}/*`}
            element={<CreateStreamingPipeline />}
          />
          <Route
            path={`${EDIT_STREAMING_PIPELINE}/:pipelineId/*`}
            element={<CreateStreamingPipeline />}
          />
          <Route
            path={`${STREAMING_PIPELINE_DASHBOARD}/:pipelineId`}
            element={<StreamingPipelineDashboard />}
          />
        </>
      )}
      <Route
        path="*"
        element={<Navigate to={PROJECT_DETAILS_BRANCHES} replace />}
      />
    </Routes>
  )
}

export default ProjectDetailsRoutes
