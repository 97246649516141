import { AgentsSecretsHost } from 'types'
import { type AutoCompleteProps } from 'types/FormTypes'

interface FormValuesTypes {
  agentsSecretsManagement: string
  username: string
  account: string
  etlAgent: AutoCompleteProps
  matillionHostedAgentId: string
  secretReferenceId: string
}

export const createGetWarehouseComputeResourceMutationData = ({
  agentsSecretsManagement,
  username,
  etlAgent,
  matillionHostedAgentId,
  account,
  secretReferenceId
}: FormValuesTypes) => {
  return {
    agentId:
      agentsSecretsManagement === AgentsSecretsHost.MatillionHosted
        ? matillionHostedAgentId
        : etlAgent.id,
    username: username.trim(),
    account: account.trim(),
    secretReferenceId: secretReferenceId
  }
}
