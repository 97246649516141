import { useState, type FunctionComponent } from 'react'

import { useFormikContext } from 'formik'
import { isEmpty } from 'lodash'

import { InputButton } from 'components/InputButton/InputButton'

import { type FormValues } from 'modules/Projects/CreateStreamingPipeline/FormContent'
import {
  ParameterValuePicker,
  type PropertyGridRow
} from 'modules/Projects/CreateStreamingPipeline/FormContent/ConfigurePipelineDetailsPage/Components/Properties/ParameterValuePicker/ParameterValuePicker'

import classes from './AdditionalConfig.module.scss'

interface AdditionalConfigProps {
  additionalConfig: Record<string, string>
  fieldName: string
  modalHeading: string
  dataTestId: string
}

export const AdditionalConfig: FunctionComponent<AdditionalConfigProps> = ({
  additionalConfig,
  fieldName,
  modalHeading,
  dataTestId
}) => {
  const { setFieldValue } = useFormikContext<FormValues>()

  const [rows, setRows] = useState<PropertyGridRow[]>(() =>
    Object.entries(additionalConfig).length > 0
      ? Object.entries(additionalConfig).map(([parameter, value], index) => ({
          id: index + 1,
          parameter,
          value
        }))
      : [
          {
            id: 0,
            parameter: '',
            value: ''
          }
        ]
  )
  const [savedRows, setSavedRows] = useState<PropertyGridRow[]>(rows)
  const [showPropertyEditor, setShowPropertyEditor] = useState(false)

  const onSave = () => {
    const params: Record<string, string> = {}
    rows.forEach((row) => {
      if (!isEmpty(row.parameter) && !isEmpty(row.value)) {
        params[row.parameter] = row.value
      }
    })
    setSavedRows(rows)
    setFieldValue(fieldName, params)
    setShowPropertyEditor(false)
  }

  const onCancel = () => {
    setRows(savedRows)
    setShowPropertyEditor(false)
  }

  const inputText = savedRows
    .filter((row) => !isEmpty(row.parameter) && !isEmpty(row.value))
    .map((row) => row.parameter)
    .join(', ')

  return (
    <>
      <InputButton
        dataTestId={dataTestId}
        onClick={() => {
          setShowPropertyEditor(true)
        }}
        value={inputText}
        className={classes.AdditionalConfig__Button}
      />
      {showPropertyEditor && (
        <ParameterValuePicker
          onSave={onSave}
          onCancel={onCancel}
          headingText={modalHeading}
          rows={rows}
          setRows={setRows}
        />
      )}
    </>
  )
}
