import { useMutation, type UseMutationOptions } from 'react-query'

import { type AxiosError } from 'axios'

import { postSecretValueV2 } from 'api/clients'
import { useClient } from 'api/hooks'
import {
  type POSTSecretValueResponse,
  type POSTSecretValueV2Request
} from 'api/types'

import { QueryKey } from 'constants/endpoint'

type TData = POSTSecretValueResponse
type TError = AxiosError<unknown>
interface TVariables {
  values: POSTSecretValueV2Request
}

type Options = UseMutationOptions<
  POSTSecretValueResponse,
  AxiosError,
  TVariables
>

interface PostSecretValueV2Options {
  projectId?: string
  options?: Options
  showErrorDetailMessage?: boolean
}

/**
 * Creates the secret value for users with or without existing projects.
 *
 * @param {string|undefined} projectId - The ID of a project you wish to
 *          associate the secret value to. Leave null when project is
 *          not known.
 * @param {Object|undefined} options - useQuery options
 * @version 2
 */
export const usePostSecretValueV2 = ({
  projectId,
  options,
  showErrorDetailMessage = true
}: PostSecretValueV2Options = {}) => {
  const client = useClient({ showErrorDetailMessage })

  return useMutation<TData, TError, TVariables>(
    [QueryKey.SECRET_VALUE],
    async (data: { values: POSTSecretValueV2Request }) => {
      const { values } = data
      return postSecretValueV2(
        values,
        {
          projectId
        },
        client
      )
    },
    options
  )
}
