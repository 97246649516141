export enum AgentsSecretsHost {
  MatillionHosted = 'MATILLION',
  CustomerHosted = 'CUSTOMER',
  AzureHosted = 'AZURE'
}

export enum AgentHostLabel {
  Matillion = 'Full SaaS',
  Customer = 'Hybrid SaaS'
}
