import { type ChangeEvent, type FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'

import { useFormikContext } from 'formik'

import { AutoComplete, Field } from '@matillion/component-library'

import classes from 'components/Form/Form.module.scss'

import { useFlags } from 'hooks/flags'

import { Warehouse, WarehouseLabels } from 'types'
import { type AutoCompleteProps, type ProjectDetailsI } from 'types/FormTypes'

type FormikValueTypes = Pick<ProjectDetailsI, 'type'>

const Type: FunctionComponent = () => {
  const { t } = useTranslation()
  const { errors, handleBlur, handleChange, setValues, touched, values } =
    useFormikContext<FormikValueTypes>()
  const { enableRedshiftProjects, enableDatabricksProjects } = useFlags()

  const handleTypeSelect = (
    e: ChangeEvent<HTMLInputElement> & {
      target: { value: AutoCompleteProps }
    }
  ) => {
    setValues({
      ...values,
      type: e.target.value?.id as Warehouse
    })
  }

  const availableItems = [
    { id: Warehouse.Snowflake, name: WarehouseLabels.Snowflake },
    ...(enableRedshiftProjects
      ? [
          {
            id: Warehouse.Redshift,
            name: WarehouseLabels.Redshift
          }
        ]
      : []),
    ...(enableDatabricksProjects
      ? [
          {
            id: Warehouse.Databricks,
            name: WarehouseLabels.Databricks
          }
        ]
      : [])
  ]

  return (
    <div data-testid="add-new-project-type">
      <Field
        inputComponent={AutoComplete}
        title={t('fields.type.title')}
        name="type"
        data-testid="add-new-project-type-input"
        availableItems={availableItems}
        value={values.type}
        placeholder={t('fields.type.placeholderText')}
        onChange={(
          e: ChangeEvent<HTMLInputElement> & {
            target: { value: AutoCompleteProps }
          }
        ) => {
          handleChange(e)
          handleTypeSelect(e)
        }}
        onBlur={handleBlur}
        className={classes.Form__SpacingStyles}
        supportText={t('fields.type.supportText')}
        errorText={errors?.type && touched.type ? t(errors.type) : null}
        hasError={!!errors?.type && touched.type}
        required
      />
    </div>
  )
}

export default Type
