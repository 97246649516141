import config from 'config'

export enum QueryKey {
  PROJECTS_LIST = 'PROJECTS_LIST',
  PROJECTS_STATUS = 'PROJECTS_STATUS',
  PROJECT_DETAIL = 'PROJECT_DETAIL',
  CREATE_PROJECT = 'CREATE_PROJECT',
  PATCH_PROJECT = 'PATCH_PROJECT',
  BRANCHES_LIST = 'BRANCHES_LIST',
  CREATE_BRANCH = 'CREATE_BRANCH',
  PATCH_BRANCH = 'PATCH_BRANCH',
  STREAMING_PIPELINES_LIST = 'STREAMING_PIPELINES_LIST',
  STREAMING_PIPELINE = 'STREAMING_PIPELINE',
  STREAMING_PIPELINE_METRICS = 'STREAMING_PIPELINE_METRICS',
  STREAMING_PIPELINE_STATUS = 'STREAMING_PIPELINE_STATUS',
  STREAMING_PIPELINE_EVENTS = 'STREAMING_PIPELINE_EVENTS',
  STREAMING_PIPELINE_ALERT_PREFERENCE = 'STREAMING_PIPELINE_ALERT_PREFERENCE',
  STREAMING_PIPELINE_ALERT_PREFERENCE_UPDATE = 'STREAMING_PIPELINE_ALERT_PREFERENCE_UPDATE',
  STREAMING_PIPELINE_CREATE = 'STREAMING_PIPELINE_CREATE',
  STREAMING_PIPELINE_UPDATE = 'STREAMING_PIPELINE_UPDATE',
  STREAMING_PIPELINE_START = 'STREAMING_PIPELINE_START',
  STREAMING_PIPELINE_STOP = 'STREAMING_PIPELINE_STOP',
  STREAMING_PIPELINE_DELETE = 'STREAMING_PIPELINE_DELETE',
  STREAMING_AGENTS_LIST = 'STREAMING_AGENTS_LIST',
  STREAMING_ASSIGNED_AGENTS_LIST = 'STREAMING_ASSIGNED_AGENTS_LIST',
  STREAMING_VALIDATE_SOURCE_CONNECTION = 'STREAMING_VALIDATE_SOURCE_CONNECTION',
  STREAMING_VALIDATE_DESTINATION_CONFIGURATION = 'STREAMING_VALIDATE_DESTINATION_CONFIGURATION',
  STREAMING_VALIDATE_SIGNAL_TABLE = 'STREAMING_VALIDATE_SIGNAL_TABLE',
  STREAMING_LIST_SOURCE_SCHEMAS = 'STREAMING_LIST_SOURCE_SCHEMAS',
  STREAMING_LIST_SOURCE_TABLES = 'STREAMING_LIST_SOURCE_TABLES',
  STREAMING_LIST_SNOWFLAKE_ROLES = 'STREAMING_LIST_SNOWFLAKE_ROLES',
  STREAMING_LIST_SNOWFLAKE_WAREHOUSES = 'STREAMING_LIST_SNOWFLAKE_WAREHOUSES',
  STREAMING_LIST_SNOWFLAKE_DATABASES = 'STREAMING_LIST_SNOWFLAKE_DATABASES',
  STREAMING_LIST_SNOWFLAKE_SCHEMAS = 'STREAMING_LIST_SNOWFLAKE_SCHEMAS',
  STREAMING_LIST_SNOWFLAKE_STAGES = 'STREAMING_LIST_SNOWFLAKE_STAGES',
  ENVIRONMENTS_LIST = 'ENVIRONMENTS_LIST',
  CREATE_ENVIRONMENT = 'CREATE_ENVIRONMENT',
  EDIT_ENVIRONMENT = 'EDIT_ENVIRONMENT',
  EDIT_ENVIRONMENT_MEMBERS = 'EDIT_ENVIRONMENT_MEMBERS',
  SECRET_REFERENCES = 'SECRET_REFERENCES',
  ENVIRONMENTS_AGENTS = 'ENVIRONMENTS AGENTS',
  SECRET_LOCATION = 'SECRET_LOCATION',
  SECRET_NAMES = 'SECRET_NAMES',
  SECRET_KEYS = 'SECRET_KEYS',
  SECRET_REFERENCE = 'SECRET_REFERENCE',
  WAREHOUSE_DEFAULTS = 'WAREHOUSE_DEFAULTS',
  CREATE_SCHEDULE = 'CREATE_SCHEDULE',
  JOBS_LIST = 'JOBS_LIST',
  SCHEDULES_LIST = 'SCHEDULES_LIST',
  CREATE_MHA = 'CREATE_MHA',
  MATILLION_HOSTED_AGENTS = 'MATILLION_HOSTED_AGENTS',
  SECRET_VALUE = 'SECRET_VALUE',
  PATCH_SECRET_VALUE = 'PATCH_SECRET_VALUE',
  ACCOUNT_MEMBERS_LIST = 'ACCOUNT_MEMBERS_LIST',
  PROJECT_MEMBERS_LIST = 'PROJECT_MEMBERS_LIST',
  ENVIRONMENT_MEMBERS_LIST = 'ENVIRONMENT_MEMBERS_LIST',
  NEW_PROJECT_ROLES = 'NEW_PROJECT_ROLES',
  REMOVE_PROJECT_MEMBERS = 'REMOVE_PROJECT_MEMBERS',
  CREATE_OAUTH = 'CREATE_OAUTH',
  COMPLETE_OAUTH = 'COMPLETE_OAUTH',
  OAUTH_SCHEMA = 'OAUTH_SCHEMA',
  CREATE_WAREHOUSE_PROVISIONING = 'CREATE_WAREHOUSE_PROVISIONING',
  WAREHOUSE_PROVISIONING = 'WAREHOUSE_PROVISIONING',
  USER_PERMISSIONS = 'USER_PERMISSIONS',
  EDIT_SCHEDULE = 'EDIT_SCHEDULE',
  DATABRICKS_COMPUTE_RESOURCES = 'DATABRICKS_COMPUTE_RESOURCES'
}

export const API_BASE_URL = config.projectExplorer.apiBaseUrl

export const QUERY_SMART_RETRIES = 0
export const QUERY_SMART_TIMEOUT = 40 * 1000
