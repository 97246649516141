import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'

import { type AxiosError } from 'axios'

import { Toaster } from '@matillion/component-library'

import { postValidateStreamingSignalTable } from 'api/clients'
import { useNetworkErrorToast, useStreamingClient } from 'api/hooks/index'
import {
  type ErrorResponse,
  type POSTValidateStreamingSignalTableResponse
} from 'api/types'

import { QueryKey } from 'constants/endpoint'

import {
  type SourceConnectionType,
  type SourceTable,
  type StreamingSourceId
} from 'types/Streaming'

type TError = AxiosError<ErrorResponse>
interface TVariables {
  agentId: string
  type: StreamingSourceId
  connection: SourceConnectionType
  signalTable: SourceTable
}

export const usePostValidateStreamingSignalTable = () => {
  const streamingClient = useStreamingClient()
  const makeErrorToast = useNetworkErrorToast()
  const { clearToasts, makeToast } = Toaster.useToaster()
  const { t } = useTranslation()

  return useMutation<
    POSTValidateStreamingSignalTableResponse,
    TError,
    TVariables
  >({
    mutationKey: [QueryKey.STREAMING_VALIDATE_SIGNAL_TABLE],
    mutationFn: async ({ type, connection, signalTable, agentId }) => {
      makeToast({
        title: t('streamingActions.validateSignalTable.sending'),
        message: '',
        type: 'info'
      })

      return postValidateStreamingSignalTable(
        { type, agentId },
        { connection, signalTable },
        streamingClient
      )
    },
    onError: (error) => {
      makeErrorToast({
        message: error.response?.data.detail ?? error.message
      })
    },
    onSuccess: (response: POSTValidateStreamingSignalTableResponse) => {
      clearToasts()
      // the response may be 2XX with 'success = false' because validation failed
      if (!response.success) {
        makeErrorToast({
          message: response.message
        })
      } else {
        makeToast({
          title: t('streamingActions.validateSignalTable.success'),
          message: '',
          type: 'success'
        })
      }
    }
  })
}
