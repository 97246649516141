import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'

import type { AxiosError } from 'axios'

import { Toaster } from '@matillion/component-library'

import { putStreamingPipelineAlertPreference } from 'api/clients'
import { useStreamingClient } from 'api/hooks/streaming/useStreamingClient'
import { useNetworkErrorToast } from 'api/hooks/useNetworkErrorToast'
import type {
  ErrorResponse,
  PUTStreamingPipelineAlertPreferenceRequestValues
} from 'api/types'

import { QueryKey } from 'constants/endpoint'

interface TVariables {
  values: PUTStreamingPipelineAlertPreferenceRequestValues
}
type TError = AxiosError<ErrorResponse>

export const usePutStreamingPipelineAlertPreference = (
  projectId: string,
  pipelineId: string
) => {
  const streamingClient = useStreamingClient()
  const makeErrorToast = useNetworkErrorToast()
  const { clearToasts, makeToast } = Toaster.useToaster()
  const { t } = useTranslation()

  return useMutation<unknown, TError, TVariables>({
    mutationKey: [
      QueryKey.STREAMING_PIPELINE_ALERT_PREFERENCE_UPDATE,
      projectId,
      pipelineId
    ],
    mutationFn: async ({ values }) => {
      makeToast({
        title: t('streamingPipelineDashboard.events.alerts.toast.sending'),
        message: '',
        type: 'info'
      })
      return putStreamingPipelineAlertPreference(
        values,
        { projectId, pipelineId },
        streamingClient
      )
    },
    onError: (error) => {
      makeErrorToast({ message: error.response?.data.detail ?? error.message })
    },
    onSuccess: () => {
      clearToasts()
      makeToast({
        title: t('streamingPipelineDashboard.events.alerts.toast.success'),
        message: '',
        type: 'success'
      })
    }
  })
}
