import type { RegionCode } from '@matillion/hub-client'

import { getEnvFromUrl } from 'config'

export const getAgentSetupUrl = (
  internalAgentId: string,
  subdomain: string,
  region?: RegionCode
) => {
  return `${getAgentsListingUrl(subdomain, region)}/${internalAgentId}`
}

export const getAgentsListingUrl = (
  subdomain: string,
  region: RegionCode = 'us1'
) => {
  const env = getEnvFromUrl()
  const envSuffix = env === 'prod' ? '' : `-${env}`

  return `https://${subdomain}.agents${envSuffix}.${region}.matillion.com/agents`
}

export const getContactSalesUrl = (subdomain: string) => {
  return `${getBillingBaseURL(subdomain)}/contact`
}

const getBillingBaseURL = (subdomain: string) => {
  const env = getEnvFromUrl()
  const envSuffix = env === 'prod' ? '' : `-${env}`

  return `https://${subdomain}.billing${envSuffix}.matillion.com`
}
