import { Environment as CommonEnvironment } from 'modules/Common/Fields'
import { useResetPipelineField } from 'modules/Common/Fields/Environment/hooks/resetPipelineField'

import { PermissionsType } from 'types'

export const Environment = () => {
  const onChange = useResetPipelineField()

  return (
    <CommonEnvironment
      data-testid="create-schedule-environment"
      permission={PermissionsType.CreateSchedules}
      onChange={onChange}
    />
  )
}
