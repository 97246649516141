import { type FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'

import classNames from 'classnames'
import { Field as FormikField, type FieldProps } from 'formik'

import { Field, Input, Textarea } from '@matillion/component-library'

import {
  FormFields,
  resolveFormikError,
  SecretValueInputType
} from 'modules/Secrets/CreateSecretDefinitionForm/CreateSecretDefinitionForm.utils'
import SecretValueToggle from 'modules/Secrets/SecretDefinitionFormContent/Fields/SecretValueToggle'
import classes from 'modules/Secrets/SecretDefinitionFormContent/SecretDefinitionFormContent.module.scss'

const SecretValue: FunctionComponent = () => {
  const { t } = useTranslation()

  return (
    <>
      <SecretValueToggle />
      <div data-testid="secret-definition-secret-value">
        <FormikField name={FormFields.secretValue}>
          {({
            field,
            meta,
            form: { handleBlur, values }
          }: FieldProps<string>) => (
            <Field
              {...field}
              type={
                values.secretValueToggle === SecretValueInputType.SINGLE
                  ? 'password'
                  : 'text'
              }
              className={classNames(
                classes.SecretDefinitionFormContent__Field,
                classes['SecretDefinitionFormContent__Field--normal']
              )}
              title={t('secrets.definitionForm.fields.secretValue.title')}
              placeholder={t(
                'secrets.definitionForm.fields.secretValue.placeholder'
              )}
              supportText={t(
                'secrets.definitionForm.fields.secretValue.supportText'
              )}
              errorText={resolveFormikError(t, meta)}
              autoComplete="off"
              data-testid="secret-definition-secret-value-input"
              onBlur={handleBlur}
              inputComponent={
                values.secretValueToggle === SecretValueInputType.SINGLE
                  ? Input
                  : Textarea
              }
            />
          )}
        </FormikField>
      </div>
    </>
  )
}

export default SecretValue
