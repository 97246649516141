import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { useFormikContext } from 'formik'

import {
  GitFeatureFlagProvider,
  RepositorySelectionForm,
  type DeploymentEnvironment
} from '@matillion/git-component-library'

import config from 'config'

import { AppRoutes } from 'constants/route'

import type { CreateProjectFormikValueTypes } from 'modules/Projects/CreateProject/CreateProjectForm'

import classes from './GitRepositorySelection.module.scss'

export const GitRepositorySelectionContent = () => {
  const navigate = useNavigate()
  const { setFieldValue, values } =
    useFormikContext<CreateProjectFormikValueTypes>()

  useEffect(() => {
    // Manual reset on mount as the form is uncontrolled
    setFieldValue('repository', '')
    setFieldValue('repositoryValidationLoading', false)
    setFieldValue('repositoryError', false)
    setFieldValue('providerParameters', {})
  }, [setFieldValue])

  useEffect(() => {
    if (values.provider === '') {
      navigate(AppRoutes.getProjectsAddGitProvider())
    }
  }, [navigate, values.provider])

  return (
    <div className={classes.GitRepositorySelection}>
      <GitFeatureFlagProvider
        environment={config.environment as DeploymentEnvironment}
      >
        <RepositorySelectionForm />
      </GitFeatureFlagProvider>
    </div>
  )
}
