import { useState, type FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { useFormikContext } from 'formik'

import { Icon, Tab, Tabs, Typography } from '@matillion/component-library'

import { useGetProject } from 'api/hooks'

import { Header, HeaderTitle } from 'components/Header'

import { useFlags } from 'hooks/flags'

import classes from 'modules/FormsContent/Form.module.scss'
import { type ScheduleFormValues } from 'modules/Projects/CreateSchedule/types'

import { AgentsSecretsHost, ScheduleDisplayMode } from 'types'

import scheduleClasses from './CreateSchedule.module.scss'
import { Agent, Environment, Pipeline, ScheduleName } from './Fields'
import { AdvancedSchedule, StandardSchedule } from './Fields/Schedule'

interface CreateScheduleContentProps {
  onCancel?: () => void
  showCancel?: boolean
  modalContent?: string
}

const CreateScheduleContent: FunctionComponent<CreateScheduleContentProps> = ({
  onCancel,
  modalContent,
  showCancel = false
}) => {
  const { t } = useTranslation()
  const { projectId } = useParams()
  const { data } = useGetProject(projectId!)
  const { setFieldValue, values } = useFormikContext<ScheduleFormValues>()
  const [activeTab, setActiveTab] = useState(
    values.schedule.displayMode === ScheduleDisplayMode.ADVANCED ? 1 : 0
  )
  const {
    enableBasicAndAdvancedScheduleLayout,
    enableStartTimeInputScheduleOptions
  } = useFlags()

  return (
    <>
      <Header
        onCancel={onCancel}
        showCancel={showCancel}
        modalContent={modalContent}
      >
        <HeaderTitle data-testid="create-schedule-title">
          {t('createSchedule.title')}
        </HeaderTitle>
        <Typography as="p" data-testid="create-schedule-support-text">
          {t('createSchedule.supportText')}
        </Typography>
      </Header>
      <div className={classes.Form}>
        <ScheduleName />
        <Environment />
        <Pipeline />
        {data?.agentAndSecretHostLocation !==
          AgentsSecretsHost.MatillionHosted && <Agent />}
        {enableBasicAndAdvancedScheduleLayout ? (
          <div className={classes.Form__TabContainer}>
            <Tabs
              tabFormat="stretch"
              activeIndex={activeTab}
              onChange={(e) => {
                setFieldValue(
                  'schedule.displayMode',
                  e.value !== 0
                    ? ScheduleDisplayMode.ADVANCED
                    : ScheduleDisplayMode.BASIC
                )
                setActiveTab(e.value)
              }}
            >
              <Tab title={t('createSchedule.tabs.standard.title')}>
                <StandardSchedule />
              </Tab>
              <Tab title={t('createSchedule.tabs.advanced.title')}>
                <AdvancedSchedule />
              </Tab>
            </Tabs>
          </div>
        ) : (
          <AdvancedSchedule />
        )}
        {!enableStartTimeInputScheduleOptions && (
          <div className={classes.Form__Field}>
            <div
              data-testid="create-schedule-helper-text"
              className={scheduleClasses.HelpText}
            >
              <Icon.Info className={scheduleClasses.HelpText__Icon} />
              <Typography format="bcs">
                {t('createSchedule.helpTextOld')}
              </Typography>
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default CreateScheduleContent
