export enum Warehouse {
  Snowflake = 'snowflake',
  Redshift = 'redshift',
  Databricks = 'databricks'
}

export enum WarehouseLabels {
  Redshift = 'Amazon Redshift',
  Databricks = 'Databricks',
  Snowflake = 'Snowflake'
}

export enum ProvisionType {
  Customer = 'customer',
  Trial = 'trial'
}
