import { type FunctionComponent } from 'react'

import { Typography } from '@matillion/component-library'

interface PanelColumnProps {
  title: string
  subtitle: string
  'data-testid': string
}

const PanelColumn: FunctionComponent<PanelColumnProps> = ({
  title,
  subtitle,
  'data-testid': dataTestId
}) => {
  return (
    <div data-testid={`project-detail-summary-${dataTestId}-column`}>
      <Typography format="bcs" weight="bold">
        {title}
      </Typography>
      <Typography format="mc">{subtitle}</Typography>
    </div>
  )
}

export default PanelColumn
