import { type FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'

import { Table as T, Typography } from '@matillion/component-library'

import { PropertyRowBlock } from 'modules/Projects/StreamingPipelineDashboard/Summary/components/PropertyRowBlock/PropertyRowBlock'
import classes from 'modules/Projects/StreamingPipelineDashboard/Summary/components/PropertyTable/PropertyTable.module.scss'

import {
  type StreamingDestinationId,
  type StreamingSourceId
} from 'types/Streaming'

const { TableHeaderCell, Table, TableBody, TableRow } = T

export interface PropertySet {
  properties?: Record<string, string | boolean>
  subject?: StreamingDestinationId | StreamingSourceId
}

interface PropertyTableProps {
  title: string
  propertySets: PropertySet[]
  dataTestId?: string
  hidden: boolean
  className?: string
}

const PropertyTable: FunctionComponent<PropertyTableProps> = ({
  title,
  propertySets,
  dataTestId,
  hidden,
  className
}) => {
  const { t } = useTranslation()

  if (
    propertySets.every(
      (set) => !set.properties || Object.values(set.properties).length === 0
    ) ||
    hidden
  ) {
    return null
  }

  return (
    <div data-testid={dataTestId} className={className}>
      <Typography format="bcl">{title}</Typography>
      <Table alt="light" className={classes.PropertyTable__Table}>
        <TableBody>
          <TableRow key="header" className={classes.PropertyTable__TableHeader}>
            <TableHeaderCell width="6" align="left">
              {t('streamingPipelineDashboard.summary.fields.common.property')}
            </TableHeaderCell>
            <TableHeaderCell width="6" align="left">
              {t('streamingPipelineDashboard.summary.fields.common.value')}
            </TableHeaderCell>
          </TableRow>
          {propertySets.map(({ properties, subject }) => (
            <PropertyRowBlock
              properties={properties}
              subject={subject}
              key={subject ?? 'advanced'}
            />
          ))}
        </TableBody>
      </Table>
    </div>
  )
}

export default PropertyTable
