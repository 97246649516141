import { useCallback } from 'react'

import { usePostSecretReference } from 'api/hooks'
import {
  createSecretReferenceMutationData,
  createSecretReferenceMutationDataPassphrase,
  createSecretReferenceMutationDataPrivateKey
} from 'api/mutation'

import type { CreateEnvironmentFormikValueTypes } from 'modules/Projects/CreateEnvironment/CreateEnvironmentForm'
import type { EditEnvironmentFormikValueTypes } from 'modules/Projects/EditEnvironment/types'

import { SecretReferenceTypes } from 'types'

export const useHandleCHASecretReferences = () => {
  const { mutateAsync: mutateAsyncSecretReference } = usePostSecretReference()

  const handleCHASecretReferences = useCallback(
    async (
      values:
        | EditEnvironmentFormikValueTypes
        | CreateEnvironmentFormikValueTypes,
      enableKeyPairAuthentication = false
    ) => {
      let createSecretRefResponse
      let createPassphraseSecretRefResponse

      if (values.secretName.id) {
        createSecretRefResponse = await mutateAsyncSecretReference({
          values: createSecretReferenceMutationData(
            values,
            SecretReferenceTypes.DWH_PASSWORD
          )
        })
      } else {
        createSecretRefResponse = await mutateAsyncSecretReference({
          values: createSecretReferenceMutationDataPrivateKey(
            values,
            SecretReferenceTypes.DWH_PASSWORD
          )
        })
      }

      if (
        values.passphraseSecretName.id &&
        values.passphraseSecretKey.id &&
        enableKeyPairAuthentication
      ) {
        createPassphraseSecretRefResponse = await mutateAsyncSecretReference({
          values: createSecretReferenceMutationDataPassphrase(
            values,
            SecretReferenceTypes.DWH_PASSWORD
          )
        })
      }

      return {
        createSecretRefResponse,
        createPassphraseSecretRefResponse
      }
    },
    [mutateAsyncSecretReference]
  )

  return handleCHASecretReferences
}
