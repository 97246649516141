import { Navigate, Route, Routes, useNavigate, type To } from 'react-router-dom'

import {
  CREATE_STREAMING_PIPELINE_DETAILS,
  CREATE_STREAMING_PIPELINE_START
} from 'constants/route'

import ConfigurePipelineDetailsPage from './FormContent/ConfigurePipelineDetailsPage/ConfigurePipelineDetailsPage'
import CreatePipelinePage from './FormContent/CreatePipelinePage/CreatePipelinePage'

const ConfigureRoutes = () => {
  const navigate = useNavigate()
  const onPrevious = (page?: string) => {
    navigate(page as To, { replace: true })
  }

  return (
    <Routes>
      <Route
        path={`${CREATE_STREAMING_PIPELINE_START}/*`}
        element={<CreatePipelinePage />}
      />
      <Route
        path={`${CREATE_STREAMING_PIPELINE_DETAILS}/*`}
        element={<ConfigurePipelineDetailsPage onPrevious={onPrevious} />}
      />
      <Route
        path="*"
        element={<Navigate replace to={CREATE_STREAMING_PIPELINE_START} />}
      />
    </Routes>
  )
}

export default ConfigureRoutes
