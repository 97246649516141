import { type FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'

import { DataGrid, type DataGridProps } from '@matillion/component-library'

import { type GETSecretReferencesResponseParams } from 'api/types'

import ActionCell from 'modules/Secrets/OAuthListing/components/ActionCell'
import NameCell from 'modules/Secrets/OAuthListing/components/NameCell'
import classes from 'modules/Secrets/OAuthListing/OAuthListing.module.scss'
import { ColumnKeys } from 'modules/Secrets/utils'

interface OAuthDataGridProps {
  oauths: GETSecretReferencesResponseParams[]
  onDeleteHandler: (name: string, isSuccess?: boolean) => void
  onSort: DataGridProps<GETSecretReferencesResponseParams>['onSort']
}

const OAuthDataGrid: FunctionComponent<OAuthDataGridProps> = ({
  oauths,
  onDeleteHandler,
  onSort
}) => {
  const { t } = useTranslation()

  return (
    <DataGrid
      className={classes.OAuthListing__Grid}
      rows={oauths}
      onSort={onSort}
      defaultSort={{ name: 'ASC' }}
      columns={[
        {
          key: ColumnKeys.name,
          title: t('secrets.oauthListing.column.col1', {
            length: oauths.length
          }),
          as: NameCell,
          mapValues: ({ name }) => ({ name }),
          sortable: true
        },
        {
          key: ColumnKeys.actions,
          title: t('secrets.oauthListing.column.col2'),
          as: ActionCell,
          mapValues: (oauth) => ({ oauth, onDeleteHandler }),
          className: classes['OAuthListing__GridCell--actions']
        }
      ]}
      data-testid="oauths-data-grid"
    />
  )
}

export default OAuthDataGrid
