import { useEffect, type FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { useFormikContext } from 'formik'

import { Field, LoadingSpinner } from '@matillion/component-library'

import { useGetBranch } from 'api/hooks'
import type { GETBranchResponse } from 'api/types'

import classes from 'modules/Projects/EditBranch/EditBranch.module.scss'

const Name: FunctionComponent = () => {
  const { t } = useTranslation()
  const { getFieldProps, setFieldValue } = useFormikContext()
  const fieldName = 'branchName'
  const field = getFieldProps(fieldName)
  const { projectId, branchId } = useParams()

  const undefinedBranch: GETBranchResponse = {
    id: '',
    name: '',
    defaultEnvironmentName: '',
    defaultEnvironmentId: ''
  }

  const { data: branch = undefinedBranch, isLoading } = useGetBranch(
    projectId!,
    branchId!
  )

  useEffect(() => {
    if (branch?.name) {
      setFieldValue(fieldName, branch.name)
    }
  }, [branch.name, setFieldValue])

  return (
    <div data-testid="edit-branch-name">
      <Field
        {...field}
        className={classes['EditBranch__Field--normal']}
        title={t('editBranch.fields.branchName.title')}
        name={fieldName}
        data-testid="edit-branch-name-input"
        disabled
        inputComponent={isLoading ? LoadingSpinner : undefined}
      />
    </div>
  )
}

export default Name
