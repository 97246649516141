import getMarketoInstance from 'marketo/getMarketoInstance'

/**
 * Initialises the Marketo library. Other Marketo calls will not work
 * unless the function has been called.
 *
 * @param clientId The Munchkin ID of your Marketo instance
 */
export const initMarketo = (clientId: string) => {
  const instance = getMarketoInstance()
  if (!instance) {
    initialise(clientId)
  }
}

const initialise = (clientId: string) => {
  let didInit = false
  const initMunchkin = () => {
    if (!didInit) {
      didInit = true
      window?.Munchkin?.init(clientId, { apiOnly: true })
    }
  }
  // If marketo is not setup through GTM then go ahead and call the munchkin script
  if (!window?.Munchkin) {
    const s = document.createElement('script')
    s.type = 'text/javascript'
    s.async = true
    s.src = '//munchkin.marketo.net/munchkin.js'
    s.onload = initMunchkin
    document.getElementsByTagName('head')[0].appendChild(s)
  } else {
    initMunchkin()
  }
}
