import { useMemo, type ChangeEvent, type FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useParams } from 'react-router-dom'

import classNames from 'classnames'
import { useFormikContext } from 'formik'

import { AutoComplete, Field, Toaster } from '@matillion/component-library'

import { useGetSecretKeys } from 'api/createProjectForm/hooks'
import { usePostSecretReference } from 'api/hooks'
import { createSecretReferenceMutationData } from 'api/mutation'

import classes from 'components/Form/Form.module.scss'

import { AppRoutes } from 'constants/route'

import { useCreateEnvironmentContext } from 'context'

import { type CreateProjectFormikValueTypes } from 'modules/Projects/CreateProject/CreateProjectForm'
import { renderFormikError } from 'modules/utils'

import { SecretReferenceTypes } from 'types'
import { type AutoCompleteProps } from 'types/FormTypes'

export interface SecretKeyProps {
  fieldName?: keyof CreateProjectFormikValueTypes
  secretNameField?: keyof CreateProjectFormikValueTypes
  testName?: string
  required?: boolean
}

export const SecretKey: FunctionComponent<SecretKeyProps> = ({
  fieldName = 'secretKey',
  testName = 'secret-key',
  secretNameField = 'secretName',
  required = true
}) => {
  const {
    errors,
    handleBlur,
    handleChange,
    touched,
    values,
    setFieldValue,
    setTouched
  } = useFormikContext<CreateProjectFormikValueTypes>()
  const { secretLocationId: contextSecretLocationId } =
    useCreateEnvironmentContext()
  const { t } = useTranslation()
  const location = useLocation()
  const { projectId, envId } = useParams()
  const { makeToast, clearToasts } = Toaster.useToaster()
  const { mutateAsync: mutateAsyncSecretReference } = usePostSecretReference()

  const secretLocationId = values.secretLocationId || contextSecretLocationId

  const isEnabled =
    !!values[secretNameField]?.id && !!secretLocationId && !!values.etlAgent?.id

  const {
    data: secretKeysData = [],
    isError,
    isLoading
  } = useGetSecretKeys(
    values[secretNameField]?.id,
    secretLocationId,
    values.etlAgent?.id,
    { enabled: isEnabled }
  )

  const secretKeyError = renderFormikError(
    errors[fieldName] as AutoCompleteProps,
    Boolean(touched[fieldName])
  )

  const getErrorMessage = useMemo(() => {
    if (isError) {
      return t('fields.secretKey.error.loadingError')
    }

    if (!secretKeysData?.length && isEnabled) {
      return t('fields.secretKey.error.noItemsFound')
    }

    if (secretKeyError) {
      return t(secretKeyError)
    }

    return null
  }, [isEnabled, isError, secretKeysData, secretKeyError, t])

  const refetchSecretReferenceId = async (e: ChangeEvent<HTMLInputElement>) => {
    // We need to create a new Secret Reference when the Secret Key changes
    // Only used in Edit Environment because it is a single page
    // For create Environment it happens on the "continue" button
    if (projectId && envId) {
      if (
        AppRoutes.getEnvironmentEdit(projectId, envId) === location.pathname
      ) {
        clearToasts()
        const value = e.target.value as unknown as AutoCompleteProps
        try {
          if (value) {
            const response = await mutateAsyncSecretReference({
              values: createSecretReferenceMutationData(
                {
                  ...values,
                  secretKey: value,
                  secretLocationId
                },
                SecretReferenceTypes.DWH_PASSWORD
              )
            })
            setFieldValue('secretReferenceId', response.secretId)
          }
        } catch (error: unknown) {
          makeToast({
            title: t('editEnvironment.responseMessage.secretReferenceError'),
            message: '',
            type: 'error'
          })
        }
      }
    }
  }

  // We need to update the fields when we Edit the environment
  const handleValueUpdates = (_e: ChangeEvent<HTMLInputElement>) => {
    const notTouchedAutocomplete = undefined
    setTouched({
      ...touched,
      [fieldName]: notTouchedAutocomplete
    })
  }

  const secretKeyFieldId = `${values.type}-credentials-${testName}`
  const error = errors[fieldName] as AutoCompleteProps | undefined

  return (
    <div data-testid={secretKeyFieldId}>
      <Field
        inputComponent={AutoComplete}
        availableItems={secretKeysData.map((item) => ({
          id: item,
          name: item
        }))}
        loading={isLoading}
        title={t(`fields.${fieldName}.title`)}
        name={fieldName}
        data-testid={`${secretKeyFieldId}-input`}
        value={values[fieldName]}
        placeholder={t(`fields.${fieldName}.placeholderText`)}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          refetchSecretReferenceId(e)
          handleChange(e)
          handleValueUpdates(e)
        }}
        onBlur={handleBlur}
        className={classNames(classes.Form__SpacingStyles)}
        supportText={t(`fields.${fieldName}.supportText`)}
        errorText={isLoading ? undefined : getErrorMessage}
        hasError={
          Boolean(typeof error === 'object' ? error.id : false) &&
          Boolean(touched[fieldName]) &&
          !isLoading
        }
        required={required}
        disabled={isError || !isEnabled || !secretKeysData.length}
      />
    </div>
  )
}
