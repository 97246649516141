import type { ChangeEvent, FocusEvent, FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'

import classNames from 'classnames'
import { useFormikContext } from 'formik'

import {
  AutoComplete,
  Field,
  type AutoCompleteItemId
} from '@matillion/component-library'

import classes from 'modules/FormsContent/Form.module.scss'
import scheduleClasses from 'modules/Projects/CreateSchedule/FormContent/CreateSchedule.module.scss'
import { type ScheduleFormValues } from 'modules/Projects/CreateSchedule/types'

import { ScheduleMethod } from 'types'

const hours = Array.from(Array(24).keys())
const minutes = Array.from(Array(60).keys())

export const Time: FunctionComponent = () => {
  const { values, handleChange, handleBlur } =
    useFormikContext<ScheduleFormValues>()
  const { t } = useTranslation()

  const getHourInput = (val: string | number) => ({
    target: {
      name: 'schedule.hour',
      value: val
    }
  })

  const getMinuteInput = (val: string | number) => ({
    target: {
      name: 'schedule.minute',
      value: val
    }
  })

  const showHourField =
    values.schedule.unit !== ScheduleMethod.HOUR &&
    values.schedule.unit !== ScheduleMethod.MINUTE

  const showMinuteField = values.schedule.unit !== ScheduleMethod.MINUTE

  if (!showHourField && !showMinuteField) {
    return null
  }

  return (
    <div className={scheduleClasses.Time}>
      {showHourField && (
        <div
          data-testid="schedule-hour"
          className={classNames(
            classes.Form__Input,
            classes['Form__Input--short']
          )}
        >
          <Field
            inputComponent={AutoComplete}
            availableItems={hours.map((hour) => ({
              name: hour.toString(),
              id: hour
            }))}
            title={t('createSchedule.fields.hour.title')}
            name="hour"
            className={classes.Form__Field}
            data-testid="schedule-hour-input"
            value={values.schedule.hour}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              try {
                const value = (e.target.value as unknown as AutoCompleteItemId)
                  .id
                handleChange(getHourInput(value))
              } catch {}
            }}
            onBlur={(e: FocusEvent<HTMLInputElement>) => {
              try {
                const value = (e.target.value as unknown as AutoCompleteItemId)
                  .id
                handleBlur(getHourInput(value))
              } catch {}
            }}
          />
        </div>
      )}
      <div data-testid="schedule-minute" className={classes.Form__Input}>
        <Field
          inputComponent={AutoComplete}
          availableItems={minutes.map((minute) => ({
            name: minute.toString(),
            id: minute
          }))}
          title={t('createSchedule.fields.minute.title')}
          name="minute"
          className={classes.Form__Field}
          data-testid="schedule-minute-input"
          value={values.schedule.minute}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            try {
              const value = (e.target.value as unknown as AutoCompleteItemId).id
              handleChange(getMinuteInput(value))
            } catch {}
          }}
          onBlur={(e: FocusEvent<HTMLInputElement>) => {
            try {
              const value = (e.target.value as unknown as AutoCompleteItemId).id
              handleBlur(getMinuteInput(value))
            } catch {}
          }}
        />
      </div>
    </div>
  )
}
