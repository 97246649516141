import { useClient } from 'api/hooks/useClient'

import config from 'config'

export const usePermissionsClient = () => {
  return useClient({
    axiosConfig: {
      baseURL: config.permissions.apiUrl
    }
  })
}
