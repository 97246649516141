import { type FunctionComponent } from 'react'

import {
  STREAMING_DESTINATIONS,
  STREAMING_SOURCES,
  StreamingSourceId,
  type StreamingDestinationId
} from 'types/Streaming'

import classes from './StreamingIcon.module.scss'

interface CellIconProps {
  type: StreamingSourceId | StreamingDestinationId
}

export const StreamingIcon: FunctionComponent<CellIconProps> = ({ type }) => {
  const isSource = Object.values<string>(StreamingSourceId).includes(type)
  const src = isSource
    ? STREAMING_SOURCES[type as StreamingSourceId]?.icon
    : STREAMING_DESTINATIONS[type as StreamingDestinationId]?.icon

  return (
    <img
      src={src}
      alt={type}
      className={classes.StreamingIcon}
      data-testid={isSource ? 'source-icon' : 'destination-icon'}
    />
  )
}
