import { useCallback, useEffect, useMemo, type FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'

import { useFormikContext } from 'formik'

import {
  AutoComplete,
  Field,
  type AutoCompleteItem
} from '@matillion/component-library'

import {
  useGetVaultNames,
  usePostSecretLocation
} from 'api/createProjectForm/hooks'
import { createPostSecretLocationMutationData } from 'api/createProjectForm/mutation'

import {
  createAutoCompleteVaultItems,
  resolveFormikError
} from 'modules/Secrets/CreateSecretDefinitionForm/CreateSecretDefinitionForm.utils'

import { AgentsSecretsHost } from 'types'

interface VaultNameProps {
  projectId: string
  agentId: string
  locationIdFieldName?: string
  className?: string
  testIdPrefix?: string
}

interface VaultNameValues {
  locationId: string
  secretLocationId: string
  etlAgent: {
    id: string
  }
  agent: {
    id: string
  }
}

export const VaultName: FunctionComponent<VaultNameProps> = ({
  projectId,
  agentId,
  locationIdFieldName = 'locationId',
  className,
  testIdPrefix = ''
}) => {
  const { t } = useTranslation()
  const {
    getFieldMeta,
    getFieldProps,
    getFieldHelpers,
    initialValues,
    setFieldValue
  } = useFormikContext<VaultNameValues>()
  const fieldProps = getFieldProps(locationIdFieldName)
  const fieldMeta = getFieldMeta(locationIdFieldName)
  const { setTouched, setValue } = getFieldHelpers(locationIdFieldName)
  const { mutateAsync: mutateAsyncSecretLocation } = usePostSecretLocation()

  const isEnabled = Boolean(agentId)

  const {
    data: vaultNameResponse,
    isError,
    isLoading,
    refetch
  } = useGetVaultNames(agentId, {
    enabled: isEnabled
  })

  const setLocationId = useCallback(
    async (item: AutoCompleteItem) => {
      setFieldValue('secretName', '')
      let selectedLocationId = item.value?.id
      if (item.value?.id === item.value?.name) {
        // We need to create a new location id as doesnt currently exist in SRS
        const response = await mutateAsyncSecretLocation({
          values: createPostSecretLocationMutationData({
            projectName: projectId,
            agentsSecretsManagement: AgentsSecretsHost.AzureHosted,
            matillionHostedAgentId: '',
            etlAgent: { id: agentId, name: '' },
            vaultName: item.value?.name
          })
        })
        selectedLocationId = response.secretLocationId
      }
      return setValue(selectedLocationId)
    },
    [mutateAsyncSecretLocation, setValue, agentId, projectId, setFieldValue]
  )

  const refetchOnOpen = async () => {
    await refetch()
  }

  const errorText = useMemo(() => {
    if (isError) {
      return t('secrets.definitionForm.fields.vault.error.loadingError')
    }

    if (vaultNameResponse?.vaults.length === 0) {
      return t('secrets.definitionForm.fields.vault.error.noItemsFound')
    }

    return resolveFormikError(t, fieldMeta)
  }, [isError, vaultNameResponse, t, fieldMeta])

  const initialSecretLocation =
    initialValues?.locationId ?? initialValues?.secretLocationId
  const initialAgentId = initialValues?.etlAgent?.id ?? initialValues?.agent?.id

  useEffect(() => {
    if (agentId !== initialAgentId) {
      setValue(vaultNameResponse?.vaults[0]?.locationId)
      setTouched(false)
    } else {
      setValue(initialSecretLocation)
      setTouched(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agentId, vaultNameResponse, initialAgentId, initialSecretLocation])

  return (
    <div data-testid={testIdPrefix + '-vault-name'}>
      <Field
        {...fieldProps}
        disabled={isError || !isEnabled}
        loading={isLoading}
        data-testid={testIdPrefix + '-vault-name-input'}
        className={className}
        title={t('secrets.definitionForm.fields.vault.title')}
        placeholder={t('secrets.definitionForm.fields.vault.placeholder')}
        inputComponent={AutoComplete}
        availableItems={createAutoCompleteVaultItems(
          vaultNameResponse?.vaults ?? [
            { locationId: fieldProps.value, vaultName: '[Default]' }
          ]
        )}
        supportText={
          fieldProps.value === vaultNameResponse?.vaults[0]?.locationId
            ? vaultNameResponse?.defaultVault
            : ''
        }
        errorText={errorText}
        value={fieldProps.value}
        onChange={useCallback(
          async (e: { target: AutoCompleteItem }) => setLocationId(e.target),
          [setLocationId]
        )}
        onOpen={refetchOnOpen}
      />
    </div>
  )
}

export default VaultName
