import { v4 as uuidv4 } from 'uuid'

import { type POSTSecretReferenceRequest } from 'api/types'

import { type SecretReferenceTypes } from 'types'
import { type AutoCompleteProps } from 'types/FormTypes'

interface FormValuesTypes {
  environmentName: string
  secretName: AutoCompleteProps
  secretKey: AutoCompleteProps
  secretLocationId: string
  credentialsType: AutoCompleteProps
  passphrase?: string
  passphraseSecretName?: AutoCompleteProps
  passphraseSecretKey?: AutoCompleteProps
  privateKeySecretName?: AutoCompleteProps
}

export const createSecretReferenceMutationData = (
  formValues: FormValuesTypes,
  secretType: SecretReferenceTypes
): POSTSecretReferenceRequest => {
  const shortUuid = uuidv4().substring(0, 8)

  return {
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    name: `${formValues.environmentName.trim()}-dwh-pwd-${shortUuid}`,
    type: secretType,
    secretName: formValues.secretName.id,
    secretKey: formValues.secretKey.id,
    locationId: formValues.secretLocationId
  }
}

export const createSecretReferenceMutationDataPrivateKey = (
  formValues: FormValuesTypes,
  secretType: SecretReferenceTypes
): POSTSecretReferenceRequest => {
  const shortUuid = uuidv4().substring(0, 8)

  console.log(formValues)
  return {
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    name: `${formValues.environmentName.trim()}-dwh-pwd-${shortUuid}-private-key`,
    type: secretType,
    secretName: formValues.privateKeySecretName?.id,
    locationId: formValues.secretLocationId
  }
}

export const createSecretReferenceMutationDataPassphrase = (
  formValues: FormValuesTypes,
  secretType: SecretReferenceTypes
): POSTSecretReferenceRequest => {
  const shortUuid = uuidv4().substring(0, 8)
  console.log(formValues)

  return {
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    name: `${formValues.environmentName.trim()}-dwh-pwd-${shortUuid}-passphrase`,
    type: secretType,
    secretName: formValues.passphraseSecretName?.id,
    secretKey: formValues.passphraseSecretKey?.id,
    locationId: formValues.secretLocationId
  }
}
