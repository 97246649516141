import awsEksIcon from 'assets/aws-eks-icon.svg'
import awsFargateIcon from 'assets/aws-fargate-icon.svg'
import azureAciIcon from 'assets/azure-aci-icon.svg'
import azureAksIcon from 'assets/azure-aks-icon.svg'
import azureContainerAppIcon from 'assets/azure-container-app-icon.svg'
import otherIcon from 'assets/other-icon.png'

import { RuntimePlatform } from './types'

interface UsableRuntimePlatform {
  id: number
  name: string
  icon: string
}

export const awsFargateRuntimePlatform: UsableRuntimePlatform = {
  id: 1,
  name: RuntimePlatform[RuntimePlatform.Fargate],
  icon: awsFargateIcon
}

export const azureAciRuntimePlatform: UsableRuntimePlatform = {
  id: 2,
  name: RuntimePlatform[RuntimePlatform.ACI],
  icon: azureAciIcon
}

export const awsEksRuntimePlatform: UsableRuntimePlatform = {
  id: 4,
  name: RuntimePlatform[RuntimePlatform.EKS],
  icon: awsEksIcon
}

export const azureAksRuntimePlatform: UsableRuntimePlatform = {
  id: 5,
  name: RuntimePlatform[RuntimePlatform.AKS],
  icon: azureAksIcon
}

export const azureContainerAppRuntimePlatform: UsableRuntimePlatform = {
  id: 9,
  name: RuntimePlatform[RuntimePlatform['Container App']],
  icon: azureContainerAppIcon
}

export const otherRuntimePlatform: UsableRuntimePlatform = {
  id: 10,
  name: RuntimePlatform[RuntimePlatform.Other],
  icon: otherIcon
}

export const runtimePlatforms: UsableRuntimePlatform[] = [
  awsFargateRuntimePlatform,
  azureAciRuntimePlatform,
  awsEksRuntimePlatform,
  azureAksRuntimePlatform,
  azureContainerAppRuntimePlatform,
  otherRuntimePlatform
]
