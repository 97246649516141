import { type FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'

import classNames from 'classnames'

import { Modal, Table as T, Typography } from '@matillion/component-library'

import { type GETSecretReferencesResponseParams } from 'api/types/SecretReferencesResponse'

import { ModalContent, ModalHeading } from 'components/Modal'

import { AgentCloudProvider } from 'types/AgentCloudProvider'

import classes from './SecretDefinitionDetailsModal.module.scss'

const { Table, TableBody, TableRow, TableCell } = T

interface SecretDefinitionDetailsModalProps {
  secret: GETSecretReferencesResponseParams
  onCancel: VoidFunction
}

const SecretDefinitionDetailsModal: FunctionComponent<
  SecretDefinitionDetailsModalProps
> = ({ secret, onCancel }) => {
  const { t } = useTranslation()
  const agentCloudProvider = secret.metadata?.cloudProviderID
  const rows = [
    [t('secrets.secretDefinitionDetails.name'), secret.name],
    [t('secrets.secretDefinitionDetails.description'), secret.description]
  ]
  if (agentCloudProvider === AgentCloudProvider.AZURE) {
    rows.push([
      t('secrets.secretDefinitionDetails.vaultAzureName'),
      secret.vaultSecretName
    ])
  } else {
    rows.push(
      [
        t('secrets.secretDefinitionDetails.vaultSecretName'),
        secret.vaultSecretName
      ],
      [
        t('secrets.secretDefinitionDetails.vaultSecretKey'),
        secret.vaultSecretKey
      ]
    )
  }

  return (
    <Modal
      className={classes.DetailsModal}
      onCancel={onCancel}
      ariaLabelledBy="Secret Definition details"
      data-testid="secret-reference-details-modal"
    >
      <ModalHeading>{secret.name}</ModalHeading>

      <ModalContent>
        <Table alt="light">
          <TableBody>
            {rows.map(([key, value]) => (
              <TableRow key={key} className={classes.DetailsModal__TableRow}>
                <TableCell
                  width="4"
                  align="left"
                  className={classNames(
                    classes.DetailsModal__TableCell,
                    classes['DetailsModal__TableCell--left']
                  )}
                >
                  <Typography weight="bold">{key}</Typography>
                </TableCell>
                <TableCell
                  width="8"
                  align="right"
                  className={classNames(
                    classes.DetailsModal__TableCell,
                    classes['DetailsModal__TableCell--right']
                  )}
                >
                  <Typography>{value}</Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </ModalContent>
    </Modal>
  )
}

export default SecretDefinitionDetailsModal
