import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'

import { type AxiosError } from 'axios'

import { Toaster } from '@matillion/component-library'

import { postValidateStreamingConnection } from 'api/clients'
import { useNetworkErrorToast, useStreamingClient } from 'api/hooks/index'
import {
  type ErrorResponse,
  type POSTValidateStreamingConnectionResponse
} from 'api/types'

import { QueryKey } from 'constants/endpoint'

import {
  type SnowflakeConnectionType,
  type SourceConnectionType,
  type StreamingDestinationId,
  type StreamingSourceId
} from 'types/Streaming'

type TError = AxiosError<ErrorResponse>
interface TVariables {
  agentId: string
  type: StreamingSourceId | StreamingDestinationId
  connection: SourceConnectionType | SnowflakeConnectionType
  silent?: boolean
}

export const usePostValidateStreamingConnection = () => {
  const streamingClient = useStreamingClient()
  const makeErrorToast = useNetworkErrorToast()
  const { clearToasts, makeToast } = Toaster.useToaster()
  const { t } = useTranslation()

  return useMutation<
    POSTValidateStreamingConnectionResponse,
    TError,
    TVariables
  >({
    mutationKey: [QueryKey.STREAMING_VALIDATE_SOURCE_CONNECTION],
    mutationFn: async ({ type, connection, agentId, silent = false }) => {
      if (!silent) {
        makeToast({
          title: t('streamingActions.validateConnection.sending'),
          message: '',
          type: 'info'
        })
      }
      return postValidateStreamingConnection(
        { type, agentId },
        connection,
        streamingClient
      )
    },
    onError: (error, { silent }) => {
      if (!silent) {
        makeErrorToast({
          message: error.response?.data.detail ?? error.message
        })
      }
    },
    onSuccess: (
      response: POSTValidateStreamingConnectionResponse,
      { silent }
    ) => {
      if (!silent) {
        clearToasts()
        // the response may be 2XX with 'success = false' because validation failed
        if (!response.success) {
          makeErrorToast({
            message: response.message
          })
        } else {
          makeToast({
            title: t('streamingActions.validateConnection.success'),
            message: '',
            type: 'success'
          })
        }
      }
    }
  })
}
