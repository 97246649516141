import { type ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import classNames from 'classnames'
import { useFormikContext } from 'formik'

import { AutoComplete, Field } from '@matillion/component-library'

import {
  HAS_PERMISSION,
  useCheckBulkPermissions,
  useGetEnvironments
} from 'api/hooks'

import { type CommonFieldsTypes } from 'modules/Common/Fields/types'
import classes from 'modules/FormsContent/Form.module.scss'
import { renderFormikError } from 'modules/utils'

import { PermissionsType } from 'types'
import { type AutoCompleteProps } from 'types/FormTypes'
import { ResourceType } from 'types/ResourceTypes'

export interface EnvironmentFormikContext {
  environment: AutoCompleteProps
}

interface EnvironmentProps extends CommonFieldsTypes {
  permission?: PermissionsType
}

export const Environment = ({
  'data-testid': dataTestId = 'common-field-environment',
  permission = PermissionsType.ViewEnvironment,
  onChange
}: EnvironmentProps) => {
  const { projectId } = useParams()

  const { errors, handleBlur, handleChange, touched, values } =
    useFormikContext<EnvironmentFormikContext>()
  const { t } = useTranslation()
  const {
    data: listOfEnvironments = [],
    isError: environmentIsError,
    isLoading: environmentIsLoading
  } = useGetEnvironments(projectId!, { retryOnMount: false })

  const environmentError = renderFormikError(
    errors.environment,
    Boolean(touched.environment)
  )

  const resources = listOfEnvironments.map((env) => {
    return {
      resourceId: env.id,
      resourceType: ResourceType.Environment,
      permission
    }
  })

  const {
    data: permissions = [],
    isError: permissionsError,
    isLoading: permissionIsLoading
  } = useCheckBulkPermissions(resources)

  const permittedEnvironments = listOfEnvironments.filter((env) => {
    return permissions.some((perm) => {
      return env.id === perm.resource?.id && perm.permission === HAS_PERMISSION
    })
  })

  const getErrorMessage = () => {
    if (environmentIsError || permissionsError) {
      return t('commonForm.fields.environment.error.loadingError')
    } else if (!permittedEnvironments.length) {
      return t('commonForm.fields.environment.error.noItemsFound')
    } else if (environmentError) {
      return t(environmentError)
    }

    return ''
  }

  return (
    <div data-testid={dataTestId}>
      <Field
        inputComponent={AutoComplete}
        loading={environmentIsLoading || permissionIsLoading}
        availableItems={permittedEnvironments
          .map((x) => ({ id: x.id, name: x.name }))
          .sort((a, b) => a.name.localeCompare(b.name))}
        title={t('commonForm.fields.environment.title')}
        name="environment"
        data-testid={`${dataTestId}-input`}
        value={values.environment}
        placeholder={
          environmentIsError
            ? t('commonForm.fields.environment.error.placeholderText')
            : t('commonForm.fields.environment.placeholderText')
        }
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          handleChange(e)
          if (onChange) {
            onChange(e)
          }
        }}
        onBlur={handleBlur}
        className={classNames(classes.Form__Field)}
        supportText={t('commonForm.fields.environment.supportText')}
        errorText={
          environmentIsLoading || permissionIsLoading
            ? undefined
            : getErrorMessage()
        }
        hasError={
          Boolean(errors?.environment?.id) &&
          Boolean(touched.environment) &&
          !environmentIsLoading &&
          !permissionIsLoading
        }
        required
        disabled={environmentIsError || !permittedEnvironments.length}
      />
    </div>
  )
}
