import { useEffect, type ChangeEvent } from 'react'

import { useFormikContext } from 'formik'

import { Field, TimeInput } from '@matillion/component-library'

import { useHandleSchedulePastDateTime } from 'hooks/handleSchedulePastDateTime/useHandleSchedulePastDateTime'

import formClasses from 'modules/FormsContent/Form.module.scss'
import type { ScheduleFormValues } from 'modules/Projects/CreateSchedule/types'

export const StartTime = () => {
  const { handlePastDateTime, resetTime } = useHandleSchedulePastDateTime()
  const {
    values: {
      schedule: { startTime, startDate, timezone }
    },
    handleChange
  } = useFormikContext<ScheduleFormValues>()

  const onTimeBlur = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    // If there is no time or the time is invalid it is set to an empty string
    if (value === '') {
      resetTime()
    } else {
      handlePastDateTime({ customTime: e.target.value })
    }
  }

  useEffect(() => {
    handlePastDateTime({ customTime: startTime, customDate: startDate })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timezone, startDate])

  return (
    <div
      className={formClasses.Form__Input}
      data-testid="schedule-starts-on-time"
    >
      <Field
        inputComponent={TimeInput}
        title="Time"
        value={startTime}
        onBlur={onTimeBlur}
        onChange={handleChange}
        name="schedule.startTime"
        data-testid="schedule-starts-on-time-input"
        required
      />
    </div>
  )
}
