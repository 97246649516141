import { type FC } from 'react'

import { Icon } from '@matillion/component-library'

import classes from './AddTableButton.module.scss'

interface AddTableButtonProps {
  schema: string
  onTransition: () => void
  setSelectedSchema: (schema: string) => void
}

export const AddTableButton: FC<AddTableButtonProps> = ({
  schema,
  onTransition,
  setSelectedSchema
}) => (
  <Icon.Cog
    onClick={() => {
      onTransition()
      setSelectedSchema(schema)
    }}
    className={classes.AddTableButton__CogIcon}
    data-testid={`add-table-button-${schema}`}
  />
)
