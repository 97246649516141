import { isBefore } from 'date-fns'

import {
  type GETSchedulesResponse,
  type POSTScheduleValues,
  type PUTScheduleRequest
} from 'api/types'

import { type ScheduleFormValues } from 'modules/Projects/CreateSchedule/types'
import { type EditScheduleFormValues } from 'modules/Projects/EditSchedule/types'

import { nowPlus2Minutes } from 'utils/nowPlus2Minutes'

// As this is not a time picker it defaults the time to 00:00:00
// However, we need to ensure that the schedule is in the future and not affected by the seconds being behind
// as it takes some time to hit scheduler based on the network
// Setting this to the next 2 minutes if the date pick is Today
const getStartTime = (
  date: string,
  time: string,
  enableStartTimeInputScheduleOptions: boolean,
  timezone: string
) => {
  if (enableStartTimeInputScheduleOptions) {
    const constructedDate = date + 'T' + time + ':00' + timezone

    return new Date(constructedDate).toISOString().split('.')[0]
  }

  const [year, month, day] = date.split('-')
  const constructedFromFormUtcTimestamp = Date.UTC(
    Number(year),
    Number(month) - 1, // this is the index of the month starting at 0
    Number(day)
  )
  const utcDateTimeNow = nowPlus2Minutes().toISOString()
  const utcDateNow = utcDateTimeNow.split('T')[0]
  const userSelectedUtcDateTime = new Date(
    constructedFromFormUtcTimestamp
  ).toISOString()
  const userSelectedUtcDate = userSelectedUtcDateTime.split('T')[0]

  if (
    utcDateNow === userSelectedUtcDate ||
    isBefore(new Date(userSelectedUtcDate), new Date(utcDateNow))
  ) {
    return utcDateTimeNow.split('.')[0]
  }

  return userSelectedUtcDateTime.split('.')[0]
}

const getEnableScheduleStartTime = (date: Date) => {
  const today = new Date()
  if (date < today) {
    return nowPlus2Minutes().toISOString().split('.')[0]
  }
  return date.toISOString().split('.')[0]
}

export const createScheduleMutationData = (
  formValues: ScheduleFormValues,
  scheduleEnabled: boolean,
  enableStartTimeInputScheduleOptions = false
): POSTScheduleValues => ({
  environmentName: formValues.environment.name.trim(),
  environmentId: formValues.environment.id,
  jobName: formValues.pipeline.name,
  agentId: formValues.agent.id,
  schedule: {
    cronExpression: formValues.schedule.cronExpression,
    timezone: formValues.schedule.timezone.id,
    startTime: getStartTime(
      formValues.schedule.startDate,
      formValues.schedule.startTime,
      enableStartTimeInputScheduleOptions,
      formValues.schedule.timezone.utc
    ),
    scheduleEnabled,
    name: formValues.name.trim(),
    displayMode: formValues.schedule.displayMode
  }
})

export const editScheduleMutationData = (
  formValues: EditScheduleFormValues,
  scheduleEnabled: boolean,
  projectId: string,
  enableStartTimeInputScheduleOptions: boolean
): PUTScheduleRequest => {
  const data = createScheduleMutationData(
    formValues,
    scheduleEnabled,
    enableStartTimeInputScheduleOptions
  )
  return { ...data, projectId }
}

const enableScheduleData = (
  scheduleData: GETSchedulesResponse,
  enableSchedule: boolean
): POSTScheduleValues => ({
  environmentName: scheduleData.environment,
  environmentId: scheduleData.environmentId,
  jobName: scheduleData.job,
  agentId: scheduleData.agentId,
  schedule: {
    cronExpression: scheduleData.cronExpression,
    timezone: scheduleData.timezone,
    startTime: getEnableScheduleStartTime(new Date(scheduleData.startTime)),
    scheduleEnabled: enableSchedule,
    name: scheduleData.name,
    displayMode: scheduleData.displayMode
  }
})

export const enableScheduleMutationData = (
  scheduleData: GETSchedulesResponse,
  enableSchedule: boolean,
  projectId: string
): PUTScheduleRequest => {
  const data = enableScheduleData(scheduleData, enableSchedule)
  return { ...data, projectId }
}
