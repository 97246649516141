import { type FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'

import classNames from 'classnames'
import { capitalize } from 'lodash'

import classes from 'components/Form/Form.module.scss'
import { Header, HeaderTitle } from 'components/Header'

import {
  Catalog,
  Compute,
  DatabricksSchema
} from 'modules/Common/WarehouseSources/Databricks/Defaults/Fields'

import { Warehouse as WarehouseType } from 'types'

interface DatabricksDefaultsProps {
  onCancel?: () => void
  onPrevious?: () => void
  showCancel?: boolean
  showPrevious?: boolean
  modalContent?: string
  isEditEnvironment?: boolean
}

export const DatabricksDefaults: FunctionComponent<DatabricksDefaultsProps> = ({
  onCancel,
  onPrevious,
  showCancel = false,
  showPrevious = false,
  modalContent,
  isEditEnvironment = false
}) => {
  const { t } = useTranslation()

  return (
    <>
      <Header
        onCancel={onCancel}
        showCancel={showCancel}
        onPrevious={onPrevious}
        showPrevious={showPrevious}
        modalContent={modalContent}
      >
        <HeaderTitle data-testid="databricks-default-title">
          {t(
            isEditEnvironment
              ? 'formContent.editEnvironmentDefaults.title'
              : 'formContent.defaults.title',
            {
              projectType: capitalize(WarehouseType.Databricks)
            }
          )}
        </HeaderTitle>
      </Header>
      <div
        className={classNames(
          classes.Form__InnerWrap,
          classes['Form__InnerWrap--space-top']
        )}
      >
        <Compute />
        <Catalog />
        <DatabricksSchema />
      </div>
    </>
  )
}
