import { useState, type FC } from 'react'
import { useTranslation } from 'react-i18next'

import { useFormikContext } from 'formik'

import {
  Button,
  Modal,
  Typography,
  type DataGridColumnProps,
  type DataGridRow
} from '@matillion/component-library'

import { ModalContent, ModalHeading } from 'components/Modal'
import { SearchBar } from 'components/SearchBar'
import SortableDataGrid from 'components/SortableDataGrid/SortableDataGrid'

import {
  useFormMetadata,
  type FormValues
} from 'modules/Projects/CreateStreamingPipeline/FormContent'
import { getDatabaseString } from 'modules/Projects/CreateStreamingPipeline/FormContent/FormContent.util'

import { AddTableButton } from './Components/AddTableButton'
import { SchemaRowContent } from './Components/SchemaRowContent'
import { TablesSelectedRowContent } from './Components/TablesSelectedRowContent'
import classes from './SchemaSelector.module.scss'

export interface SchemaSelectorProps {
  onClose: () => void
  onTransition: () => void
  setSelectedSchema: (schema: string) => void
  title: string
  showTablesSelected?: boolean
}

interface SchemaGridRow extends DataGridRow {
  id: string
  tablesSelected: number
}

export const SchemaSelector: FC<SchemaSelectorProps> = ({
  onClose,
  onTransition,
  setSelectedSchema,
  title,
  showTablesSelected = true
}) => {
  const { t } = useTranslation()
  const { metadata } = useFormMetadata()
  const { values } = useFormikContext<FormValues>()
  const [filter, setFilter] = useState<string>('')

  const selectedTables = values.source.tables
  const allSchemas = metadata.source.schemas

  const columns: Array<DataGridColumnProps<SchemaGridRow>> = [
    {
      key: 'id',
      headerClassName: classes.SchemaSelector__ColumnHeader,
      sortable: true,
      title: t(
        'createStreamingPipeline.fields.pipelineConfig.schemaSelector.schemaColumn'
      ),
      as: SchemaRowContent,
      mapValues: (value) => ({
        schemaName: value.id
      })
    },
    ...(showTablesSelected
      ? [
          {
            key: 'tablesSelected',
            headerClassName: classes.SchemaSelector__ColumnHeader,
            className: classes.SchemaSelector__TablesSelectedColumn,
            sortable: true,
            title: t(
              'createStreamingPipeline.fields.pipelineConfig.schemaSelector.tablesSelectedColumn'
            ),
            as: TablesSelectedRowContent,
            mapValues: (value: SchemaGridRow) => ({
              schema: value.id,
              tablesSelected: value.tablesSelected
            })
          }
        ]
      : []),
    {
      title: '',
      key: 'cogButton',
      className: classes.SchemaSelector__CogButtonColumn,
      sortable: false,
      as: AddTableButton,
      mapValues: (value) => ({
        schema: value.id,
        onTransition,
        setSelectedSchema
      })
    }
  ]

  const filteredSchemas = Object.keys(allSchemas).filter((schema) =>
    schema.toLowerCase().includes(filter.toLowerCase())
  )

  const rows: SchemaGridRow[] = filteredSchemas.map((schema) => ({
    id: schema,
    tablesSelected: selectedTables.filter((table) => table.schema === schema)
      .length
  }))

  return (
    <Modal
      onCancel={onClose}
      ariaLabelledBy="modal-title"
      size="large"
      className={classes.SchemaSelector__Modal}
    >
      <ModalHeading data-testid="streaming-schema-selector-modal-title">
        {title}
      </ModalHeading>
      <ModalContent>
        <div className={classes.SchemaSelector__Toolbar}>
          <div>
            <Typography
              type="bcs"
              className={classes.SchemaSelector__BreadcrumbHeader}
            >
              {t(
                'createStreamingPipeline.fields.pipelineConfig.schemaSelector.breadcrumbHeader'
              )}
            </Typography>

            <Typography type="bcs">{getDatabaseString(values, t)}</Typography>
          </div>
          <SearchBar value={filter} onChange={setFilter} />
        </div>

        <SortableDataGrid
          columns={columns}
          rows={rows}
          data-testid="schema-selector-table"
          defaultSort={{ id: 'ASC' }}
          className={classes.SchemaSelector__Grid}
          compact
        />
        <div className={classes.SchemaSelector__ButtonWrapper}>
          <Button
            alt="secondary"
            text={t(
              'createStreamingPipeline.fields.pipelineConfig.schemaSelector.backButton'
            )}
            onClick={onClose}
            data-testid="streaming-schema-selector-back-button"
          />
          <Button
            text={t(
              'createStreamingPipeline.fields.pipelineConfig.schemaSelector.finishButton'
            )}
            onClick={onClose}
            data-testid="streaming-schema-selector-finish-button"
          />
        </div>
      </ModalContent>
    </Modal>
  )
}
