import { type FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'

import {
  Typography,
  type DataGridColumnProps,
  type DataGridRow
} from '@matillion/component-library'

import SortableDataGrid from 'modules/Projects/StreamingPipelineDashboard/Common/components/SortableDataGrid/SortableDataGrid'
import SchemaButton from 'modules/Projects/StreamingPipelineDashboard/Metrics/components/SchemaButton/SchemaButton'

import { type SourceSchema } from 'types/Streaming'

import classes from './SelectedTableExplorer.module.scss'

interface SelectedSchemaGridRow extends DataGridRow {
  id: string
  details: SourceSchema
}

interface SelectedTableExplorerProps {
  onSchemaSelect: (schema: SourceSchema) => void
  selectedSchema?: SourceSchema
  schemas: SourceSchema[]
  filter: string
}

const SelectedTableExplorer: FunctionComponent<SelectedTableExplorerProps> = ({
  onSchemaSelect,
  selectedSchema,
  schemas,
  filter
}) => {
  const { t } = useTranslation()

  if (selectedSchema) {
    const columns: Array<DataGridColumnProps<DataGridRow>> = [
      {
        key: 'id',
        sortable: true,
        title: `${t('streamingPipelineDashboard.metrics.common.tables')} (${selectedSchema.tables.length})`,
        as: Typography,
        mapValues: (value) => ({
          format: 'bcs',
          children: value.id,
          'data-testid': 'selected-table-name'
        })
      }
    ]

    const filteredTables = selectedSchema.tables.filter((table) =>
      table.toLowerCase().includes(filter.toLowerCase())
    )
    const rows = filteredTables.map(
      (table): DataGridRow => ({
        id: table
      })
    )
    return (
      <SortableDataGrid
        columns={columns}
        rows={rows}
        data-testid="selected-tables-listing"
        defaultSort={{ id: 'ASC' }}
      />
    )
  }

  const columns: Array<DataGridColumnProps<SelectedSchemaGridRow>> = [
    {
      key: 'id',
      sortable: true,
      title: `${t('streamingPipelineDashboard.metrics.common.schemas')} (${schemas.length})`,
      as: SchemaButton,
      className: classes.SelectedTableExplorer__LeftColumn,
      mapValues: (row) => ({
        text: row.id,
        onClick: () => {
          onSchemaSelect(row.details)
        }
      })
    },
    {
      key: 'numOfTables',
      sortable: true,
      title: `${t('streamingPipelineDashboard.metrics.common.tables')} (${schemas.reduce((total, schema) => total + schema.tables.length, 0)})`,
      as: Typography,
      className: classes.SelectedTableExplorer__RightColumn,
      mapValues: (row) => ({
        children: row.details.tables.length,
        format: 'bcs'
      })
    }
  ]

  const filteredSchemas = schemas.filter((schema) =>
    schema.schema.toLowerCase().includes(filter.toLowerCase())
  )
  const rows = filteredSchemas.map(
    (schema): SelectedSchemaGridRow => ({
      id: schema.schema,
      details: schema
    })
  )

  return (
    <SortableDataGrid
      columns={columns}
      rows={rows}
      data-testid="selected-schemas-listing"
      defaultSort={{ id: 'ASC' }}
    />
  )
}

export default SelectedTableExplorer
