import { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'

import classNames from 'classnames'
import { useFormikContext } from 'formik'

import { AutoComplete, Field } from '@matillion/component-library'

import { useGetEnvironments } from 'api/hooks'

import { QueryKey } from 'constants/endpoint'
import { AppRoutes, PROJECT_DETAILS_ENVIRONMENTS } from 'constants/route'

import classes from 'modules/FormsContent/Form.module.scss'
import { type FormValues } from 'modules/Projects/AssociateCloudCredentials/AssociateEnvironmentToCloudCredential/AssociateEnvironmentToCloudCredential.util'
import { renderFormikError } from 'modules/utils'

export const Environment = () => {
  const { projectId } = useParams()
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const { errors, handleBlur, handleChange, touched, values } =
    useFormikContext<FormValues>()
  const { t } = useTranslation()
  const {
    data: listOfEnvironments = [],
    isError: environmentIsError,
    isLoading: environmentIsLoading
  } = useGetEnvironments(projectId!)

  const environmentError = renderFormikError(
    errors.environment,
    Boolean(touched.environment)
  )

  useEffect(() => {
    queryClient.invalidateQueries({ queryKey: QueryKey.ENVIRONMENTS_LIST })
  }, [queryClient])

  const getErrorMessage = useMemo(() => {
    if (environmentIsError) {
      return t('associateCredentials.fields.environment.error.loadingError')
    } else if (!listOfEnvironments.length) {
      return t('associateCredentials.fields.environment.error.noItemsFound')
    } else if (environmentError) {
      return t(environmentError)
    }

    return ''
  }, [environmentError, environmentIsError, listOfEnvironments.length, t])

  listOfEnvironments.sort((a, b) => a.name.localeCompare(b.name))

  if (
    !listOfEnvironments.length &&
    !environmentIsError &&
    !environmentIsLoading
  ) {
    navigate(AppRoutes.getProjectDetails(PROJECT_DETAILS_ENVIRONMENTS))
  }

  return (
    <div data-testid="associate-environment-to-cloud-credential-environment">
      <Field
        inputComponent={AutoComplete}
        loading={environmentIsLoading}
        availableItems={listOfEnvironments}
        title={t('associateCredentials.fields.environment.title')}
        name="environment"
        data-testid="associate-environment-to-cloud-credential-environment-input"
        value={values.environment}
        placeholder={
          environmentIsError
            ? t('commonForm.fields.environment.error.placeholderText')
            : t('commonForm.fields.environment.placeholderText')
        }
        onChange={handleChange}
        onBlur={handleBlur}
        className={classNames(classes.Form__Field)}
        supportText={t('associateCredentials.fields.environment.supportText')}
        errorText={environmentIsLoading ? undefined : getErrorMessage}
        hasError={
          Boolean(errors?.environment) &&
          Boolean(touched.environment) &&
          !environmentIsLoading
        }
        required
        disabled={
          environmentIsError ||
          (!listOfEnvironments.length && !environmentIsLoading)
        }
      />
    </div>
  )
}
