import { type FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { Toaster, Typography } from '@matillion/component-library'

import { useDeleteProjectMembers } from 'api/hooks'

import ConfirmCancelModal from 'components/Modal/ProjectExplorerModal'

import { type RemoveUserProps } from './types'

const RemoveUser: FunctionComponent<RemoveUserProps> = ({
  onCancelModal,
  onSubmit,
  user
}) => {
  const { t } = useTranslation()
  const { projectId = '' } = useParams()
  const { mutateAsync } = useDeleteProjectMembers(projectId)
  const { makeToast, clearToasts } = Toaster.useToaster()

  const handleSubmit = () => {
    clearToasts()
    mutateAsync({
      values: {
        userIds: [user.id]
      }
    })
      .then((_response) => {
        makeToast({
          title: t('accessListing.removeUser.responseMessage.success', {
            user: user.formattedUsername
          }),
          message: '',
          type: 'success'
        })
      })
      .catch((_error) => {
        makeToast({
          title: t('accessListing.removeUser.responseMessage.error'),
          message: '',
          type: 'error'
        })
      })
      .finally(onSubmit)
  }

  const modalContent = (
    <>
      {t('accessListing.removeUser.contentBefore')}
      <Typography weight="bold" as="span" data-testid="remove-user-name">
        {user.formattedUsername}
      </Typography>
      {t('accessListing.removeUser.contentAfter')}
    </>
  )

  return (
    <ConfirmCancelModal
      heading={t('accessListing.removeUser.heading')}
      content={modalContent}
      cancel={t('accessListing.removeUser.cancelButtonText')}
      validate={t('accessListing.removeUser.submitButtonText')}
      onValidate={handleSubmit}
      onCancel={onCancelModal}
    />
  )
}

export default RemoveUser
