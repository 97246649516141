import { type FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'

import { Typography } from '@matillion/component-library'

import { type StreamingPipelineResponse } from 'api/types'

import { mapEnabledDisabled } from 'modules/Projects/StreamingPipelineDashboard/Summary/components/StreamingDashboardSummary/StreamingDashboardSummary.util'
import classes from 'modules/Projects/StreamingPipelineDashboard/Summary/components/StreamingSourceSummary/StreamingSourceSummary.module.scss'
import {
  StreamingSummaryBlock,
  type SummaryField
} from 'modules/Projects/StreamingPipelineDashboard/Summary/components/StreamingSummaryBlock/StreamingSummaryBlock'
import {
  formatField,
  formatSecretManager
} from 'modules/Projects/StreamingPipelineDashboard/Summary/StreamingSummary.util'

import {
  hasDatabaseProperty,
  hasPdbProperty,
  STREAMING_SOURCES,
  StreamingSourceId
} from 'types/Streaming'
import { hasReadReplicaProperty } from 'types/Streaming/engine'

interface StreamingSourceSummaryProps {
  pipeline: StreamingPipelineResponse
  isExpanded: boolean
}

const StreamingSourceSummary: FunctionComponent<
  StreamingSourceSummaryProps
> = ({ pipeline, isExpanded }) => {
  const { t } = useTranslation()

  const fields: SummaryField[] = [
    {
      heading: t('streamingPipelineDashboard.summary.fields.common.platform'),
      content: formatField(STREAMING_SOURCES[pipeline.source.type]?.name)
    },
    {
      heading: t('streamingPipelineDashboard.summary.fields.source.user'),
      content: formatField(pipeline.source.connection?.user)
    },
    {
      heading: t('streamingPipelineDashboard.summary.fields.source.host'),
      content: formatField(pipeline.source.connection?.host)
    }
  ]

  if (isExpanded) {
    fields.push({
      heading: t('streamingPipelineDashboard.summary.fields.source.port'),
      content: formatField(pipeline.source.connection?.port)
    })
    if (hasDatabaseProperty(pipeline.source)) {
      fields.push({
        heading: t('streamingPipelineDashboard.summary.fields.common.database'),
        content: formatField(pipeline.source.connection?.database)
      })
    }
    if (hasPdbProperty(pipeline.source)) {
      fields.push({
        heading: t('streamingPipelineDashboard.summary.fields.source.pdb'),
        content: formatField(pipeline.source.connection?.pdb)
      })
    }
    if (pipeline.source.type === StreamingSourceId.SQLServer) {
      fields.push({
        heading: t(
          'streamingPipelineDashboard.summary.fields.config.readReplica'
        ),
        content: mapEnabledDisabled(
          hasReadReplicaProperty(pipeline.engine?.advanced),
          t
        )
      })
    }
    fields.push(
      {
        heading: t(
          'streamingPipelineDashboard.summary.fields.common.secretManager'
        ),
        content: formatSecretManager(pipeline.source.connection?.password?.type)
      },
      {
        heading: t(
          'streamingPipelineDashboard.summary.fields.common.secretName'
        ),
        content: formatField(pipeline.source.connection?.password?.name)
      }
    )
  }

  return (
    <div data-testid="summary-source">
      <div className={classes.StreamingSourceSummary__Title}>
        <Typography format="bcl">
          {t('streamingPipelineDashboard.summary.fields.source.title')}
        </Typography>
        <img
          alt={pipeline.source.type}
          src={STREAMING_SOURCES[pipeline.source.type]?.icon}
          className={classes.StreamingSourceSummary__Icon}
        />
      </div>
      <StreamingSummaryBlock fields={fields} />
    </div>
  )
}

export default StreamingSourceSummary
