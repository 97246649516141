import { type ChangeEvent, type FunctionComponent } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import classNames from 'classnames'
import { useFormikContext } from 'formik'

import { Field, Radio, Typography } from '@matillion/component-library'

import classes from 'components/Form/Form.module.scss'

import classesAgentDeployment from 'modules/FormsContent/AgentDeployment/AgentDeployment.module.scss'

import { AgentsSecretsHost } from 'types'
import { type CommonFormI } from 'types/FormTypes'

type FormikValueTypes = CommonFormI

const AgentsSecretsManagement: FunctionComponent = () => {
  const { t } = useTranslation()
  const { values, handleChange, setFieldValue } =
    useFormikContext<FormikValueTypes>()

  return (
    <div
      data-testid="agent-deployment-field"
      className={classNames(classesAgentDeployment.AgentDeployment__Container)}
    >
      <Field
        name="agentsSecretsManagement"
        title={t('agentDeployment.fields.agentsSecretsManagement.title')}
        text={t(
          'agentDeployment.fields.agentsSecretsManagement.radioChoice1.label'
        )}
        value={values.agentsSecretsManagement}
        className={classNames(classes.Form__SpacingStyles)}
        checked={
          values?.agentsSecretsManagement === AgentsSecretsHost.MatillionHosted
        }
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          handleChange(e)
          setFieldValue(
            'agentsSecretsManagement',
            AgentsSecretsHost.MatillionHosted
          )
        }}
        inputComponent={Radio}
        data-testid="full-agent-deployment-radio"
      ></Field>
      <Field
        name="agentsSecretsManagement"
        text={t(
          'agentDeployment.fields.agentsSecretsManagement.radioChoice2.label'
        )}
        supportText={
          <Typography
            format="bcs"
            className={classesAgentDeployment.AgentDeployment__RadioSupportText}
            data-testid="hybrid-agent-deployment-radio-support-text"
          >
            {t(
              'agentDeployment.fields.agentsSecretsManagement.radioChoice2.supportText'
            )}
            <span
              className={classesAgentDeployment.AgentDeployment__RadioHyperlink}
            >
              <Trans
                i18nKey="agentDeployment.fields.agentsSecretsManagement.radioChoice2.supportText2"
                components={{
                  Link: (
                    <a
                      href="https://docs.matillion.com/data-productivity-cloud/agent/docs/create-an-agent/"
                      target="_blank"
                      rel="noreferrer"
                      data-testid="hybrid-agent-deployment-radio-support-link"
                    />
                  )
                }}
              />
            </span>
          </Typography>
        }
        value={values.agentsSecretsManagement}
        className={classNames(classes.Form__RadioButtonSpacing)}
        checked={
          values?.agentsSecretsManagement === AgentsSecretsHost.CustomerHosted
        }
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          handleChange(e)
          setFieldValue(
            'agentsSecretsManagement',
            AgentsSecretsHost.CustomerHosted
          )
        }}
        inputComponent={Radio}
        data-testid="hybrid-agent-deployment-radio"
      ></Field>
    </div>
  )
}

export default AgentsSecretsManagement
