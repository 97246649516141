import { useState, type FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'

import { Tab, Tabs, Typography } from '@matillion/component-library'

import SelectedTables from 'modules/Projects/StreamingPipelineDashboard/Metrics/components/SelectedTables/SelectedTables'
import Snapshotting from 'modules/Projects/StreamingPipelineDashboard/Metrics/components/Snapshotting/Snapshotting'

import {
  StreamingPipelineStatus,
  type SourceTable,
  type StreamingPipelineMetrics
} from 'types/Streaming'

import classes from './StreamingDashboardMetrics.module.scss'

enum MetricsTabs {
  TABLES = 0,
  SNAPSHOT = 1
}

interface StreamingDashboardMetricsProps {
  database?: string
  tables: SourceTable[]
  metrics?: StreamingPipelineMetrics
  status: StreamingPipelineStatus
}

const StreamingDashboardMetrics: FunctionComponent<
  StreamingDashboardMetricsProps
> = ({ database, tables, metrics, status }) => {
  const { t } = useTranslation()

  const initialTab =
    status === StreamingPipelineStatus.SNAPSHOTTING
      ? MetricsTabs.SNAPSHOT
      : MetricsTabs.TABLES
  const [activeTab, setActiveTab] = useState(initialTab)

  return (
    <div className={classes.StreamingDashboardMetrics__Container}>
      <Typography data-testid="streaming-pipeline-metrics-title" format="ts">
        {t('streamingPipelineDashboard.metrics.title')}
      </Typography>
      <Tabs
        tabFormat="compact"
        activeIndex={activeTab}
        onChange={(e) => {
          setActiveTab(e.value)
        }}
        className={classes.StreamingDashboardMetrics__Tabs}
      >
        <Tab
          title={t('streamingPipelineDashboard.metrics.tables.title')}
          data-testid="tables-selected-tab"
        >
          <SelectedTables
            database={database}
            tables={tables}
            metrics={metrics}
          />
        </Tab>
        <Tab
          title={t('streamingPipelineDashboard.metrics.snapshot.title')}
          data-testid="snapshotting-tab"
        >
          <Snapshotting
            database={database}
            snapshotMetrics={metrics?.snapshot}
            status={status}
          />
        </Tab>
      </Tabs>
    </div>
  )
}

export default StreamingDashboardMetrics
