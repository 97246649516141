import { useMutation, type UseMutationOptions } from 'react-query'

import { type AxiosError } from 'axios'

import { patchProject } from 'api/clients'
import type { PATCHProjectRequest } from 'api/types'

import { QueryKey } from 'constants/endpoint'

import { useClient } from './useClient'

type TError = AxiosError<unknown>
interface TVariables {
  values: PATCHProjectRequest
  projectId: string
}

type Options = UseMutationOptions<unknown, AxiosError, TVariables>

/**
 * Updates the name and/or description details of a project.
 *
 * @param projectId - pass in projectId to path
 * @param {Object|undefined} options - useQuery options
 */
export const usePatchProject = (
  showErrorDetailMessage = true,
  options?: Options
) => {
  const client = useClient({ showErrorDetailMessage })

  return useMutation<unknown, TError, TVariables>(
    [QueryKey.PATCH_PROJECT],
    async (data: TVariables) => {
      const { values, projectId } = data
      return patchProject(
        values,
        {
          projectId
        },
        client
      )
    },
    options
  )
}
