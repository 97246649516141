import { type TFunction } from 'react-i18next'

import { object, ref, string, type InferType } from 'yup'

export const getValidationSchema = (t?: TFunction) =>
  object({
    projectName: string(),
    confirmProjectName: string()
      .oneOf([ref('projectName')], '')
      .required('deleteProject.fields.confirmProjectName.error.required')
  })

const schema = getValidationSchema()

export type FormValues = InferType<typeof schema>

export const getInitialValues = (selectedProjectName: string) => ({
  projectName: selectedProjectName,
  confirmProjectName: ''
})
