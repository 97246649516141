import { type AxiosInstance } from 'axios'

import { mockAxios } from '__mocks__/axiosMock'

import type {
  GETSecretKeyRequest,
  GETSecretKeyResponse,
  GETSecretNameRequest,
  GETSecretNameResponse,
  GETWarehouseDefaultsRequest,
  GETWarehouseDefaultsResponse,
  POSTSecretLocationRequest,
  POSTSecretLocationResponse,
  WarehouseLookupTypes
} from 'api/createProjectForm/types'
import { type GETDatabricksComputeResourceRequest } from 'api/createProjectForm/types/DatabricksComputeResourcesRequest'
import { type GETDatabricksComputeResourcesResponse } from 'api/createProjectForm/types/DatabricksComputeResourcesResponse'
import type { POSTProjectRequest, POSTProjectResponse } from 'api/types'

import type { GETVaultNameRequest } from './types/VaultNameRequest'
import type { GETVaultNameResponse } from './types/VaultNameResponse'

export const getSecretName = async (
  params: GETSecretNameRequest,
  /* istanbul ignore next */
  client: AxiosInstance = mockAxios
): Promise<GETSecretNameResponse> => {
  const { agentId, secretLocationId } = params

  const path = `/v1/gateway/srs/agents/${encodeURIComponent(
    agentId
  )}/location/${encodeURIComponent(secretLocationId)}/secrets`

  const { data } = await client.get(path)

  return data
}

export const getSecretKey = async (
  params: GETSecretKeyRequest,
  /* istanbul ignore next */
  client: AxiosInstance = mockAxios
): Promise<GETSecretKeyResponse> => {
  const { agentId, secretLocationId, secretName } = params

  const path = `/v1/gateway/srs/agents/${encodeURIComponent(
    agentId
  )}/location/${encodeURIComponent(
    secretLocationId
  )}/keys?secretName=${secretName}`

  const { data } = await client.get(path)

  return data
}

export const postSecretLocation = async (
  values: POSTSecretLocationRequest,
  /* istanbul ignore next */
  client: AxiosInstance = mockAxios
): Promise<POSTSecretLocationResponse> => {
  const path = '/v1/gateway/srs/secret-location'
  const { data } = await client.post(path, values)

  return data
}

export const getWarehouseDefaults = async (
  params: GETWarehouseDefaultsRequest,
  lookupType: WarehouseLookupTypes,
  /* istanbul ignore next */
  client: AxiosInstance = mockAxios
): Promise<GETWarehouseDefaultsResponse> => {
  const path = `/v1/gateway/warehouse-defaults/lookup/${lookupType}`
  const { data } = await client.get(path, { params })
  return data
}

export const postWarehouseDefaults = async (
  values: GETWarehouseDefaultsRequest,
  lookupType: WarehouseLookupTypes,
  /* istanbul ignore next */
  client: AxiosInstance = mockAxios
): Promise<GETWarehouseDefaultsResponse> => {
  const path = `/v2/gateway/warehouse-defaults/lookup/${lookupType}`
  const { data } = await client.post(path, values)
  return data
}

export const getDatabricksComputeResources = async (
  params: GETDatabricksComputeResourceRequest,
  /* istanbul ignore next */
  client: AxiosInstance = mockAxios
): Promise<GETDatabricksComputeResourcesResponse> => {
  const path = '/v1/gateway/warehouse-defaults/databricks/lookup/compute'
  const { data } = await client.get(path, { params })

  return data
}

export const postProject = async (
  values: POSTProjectRequest,
  /* istanbul ignore next */
  client: AxiosInstance = mockAxios,
  version = 'v1'
): Promise<POSTProjectResponse> => {
  const path = `/${version}/projects/initialise`
  const { data } = await client.post(path, values)
  return data
}

export const getVaults = async (
  params: GETVaultNameRequest,
  /* istanbul ignore next */
  client: AxiosInstance = mockAxios
): Promise<GETVaultNameResponse> => {
  const { agentId } = params

  const path = `/v1/gateway/srs/agents/${encodeURIComponent(agentId)}/vaults`

  const { data } = await client.get(path)

  return data
}
