import { type FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'

import { Typography } from '@matillion/component-library'

const NoData: FunctionComponent = () => {
  const { t } = useTranslation()

  return (
    <>
      {/* // TO-DO: Replace this with template of the 'No Resource' page when improving the UI  */}
      <Typography format="bcm">{t('environmentsListing.noData')}</Typography>
    </>
  )
}

export default NoData
