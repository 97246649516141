import { type FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'

import classNames from 'classnames'
import { useFormikContext } from 'formik'
import { capitalize } from 'lodash'

import classes from 'components/Form/Form.module.scss'
import { Header, HeaderTitle } from 'components/Header'

import { useFlags } from 'hooks/flags'

import {
  Account,
  Username,
  WarehouseAgentCredentials
} from 'modules/Common/Fields'
import { type CreateProjectFormikValueTypes } from 'modules/Projects/CreateProject/CreateProjectForm'

import { Warehouse } from 'types'

interface DatabricksCredentialsProps {
  onCancel?: () => void
  onPrevious?: () => void
  showCancel?: boolean
  showPrevious?: boolean
  modalContent?: string
  excludeHeader?: boolean
}

export const DatabricksCredentials: FunctionComponent<
  DatabricksCredentialsProps
> = ({
  onCancel,
  onPrevious,
  showCancel = false,
  showPrevious = false,
  modalContent,
  excludeHeader = false
}) => {
  const { t } = useTranslation()
  const { enableDatabricksTokenOnlyAuth } = useFlags()
  const { values } = useFormikContext<CreateProjectFormikValueTypes>()
  if (enableDatabricksTokenOnlyAuth) {
    values.username = 'token'
  }

  const fields = (
    <>
      <Account translationProxy={'instanceName'} />
      {enableDatabricksTokenOnlyAuth ? null : <Username />}
      <WarehouseAgentCredentials />
    </>
  )

  if (excludeHeader) {
    return fields
  }

  return (
    <>
      <Header
        onCancel={onCancel}
        showCancel={showCancel}
        onPrevious={onPrevious}
        showPrevious={showPrevious}
        modalContent={modalContent}
      >
        <HeaderTitle data-testid="specify-credentials-title">
          {t('formContent.credentials.title', {
            projectType: capitalize(Warehouse.Databricks)
          })}
        </HeaderTitle>
      </Header>
      <div
        className={classNames(
          classes.Form__InnerWrap,
          classes['Form__InnerWrap--space-top']
        )}
      >
        {fields}
      </div>
    </>
  )
}
