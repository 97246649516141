import { useMutation, type UseMutationOptions } from 'react-query'

import { type AxiosError } from 'axios'

import { postMha } from 'api/clients'
import { useClient } from 'api/hooks'

import { QueryKey } from 'constants/endpoint'

type Options = UseMutationOptions<void, AxiosError>

interface PostMhaProps {
  options?: Options
  showErrorDetailMessage?: boolean
}

export const usePostMha = ({
  options,
  showErrorDetailMessage
}: PostMhaProps) => {
  const client = useClient({ showErrorDetailMessage })

  return useMutation(
    [QueryKey.CREATE_MHA],
    async () => postMha(client),
    options
  )
}
