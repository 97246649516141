import { type FunctionComponent } from 'react'
import { useLocation } from 'react-router-dom'

import { HubHeader } from '@matillion/hub-client'

const AppHeader: FunctionComponent = () => {
  const location = useLocation()

  const regex = /onboarding\/add\/[a-zA-Z]*/g
  const shouldHideMenus = location.pathname.search(regex) !== -1
  return (
    <HubHeader
      platformMenuHidden={shouldHideMenus}
      userMenuHidden={shouldHideMenus}
      disableHubLink={shouldHideMenus}
    />
  )
}

export default AppHeader
