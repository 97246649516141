import {
  type StreamingDestinationId,
  type StreamingDestinationName,
  type StreamingSourceId,
  type StreamingSourceName
} from 'types/Streaming'

export const FIELD_NAMES_NAME = 'name'
export const FIELD_NAMES_AGENT = 'agent'
export const FIELD_NAMES_SOURCE = 'source.type'
export const FIELD_NAMES_DESTINATION = 'target.type'

export interface SourceAutocompleteType {
  id: StreamingSourceId
  name: StreamingSourceName
}

export interface DestinationAutocompleteType {
  id: StreamingDestinationId
  name: StreamingDestinationName
}
