import { useQuery, type UseQueryOptions } from 'react-query'
import { useParams } from 'react-router-dom'

import { type AxiosError } from 'axios'

import { getAssignedStreamingAgentIds } from 'api/clients'
import { useNetworkErrorToast, useStreamingClient } from 'api/hooks/index'
import { type GETAssignedStreamingAgentIdsResponse } from 'api/types'

import { QueryKey } from 'constants/endpoint'

type TData = GETAssignedStreamingAgentIdsResponse
type TError = AxiosError<unknown>
type TOptions = UseQueryOptions<TData, TError, TData>

export const useGetAssignedStreamingAgentIds = (options?: TOptions) => {
  const streamingClient = useStreamingClient()
  const makeErrorToast = useNetworkErrorToast()
  const { projectId } = useParams()

  return useQuery<TData, TError>(
    [QueryKey.STREAMING_ASSIGNED_AGENTS_LIST, projectId],
    async () =>
      // @ts-expect-error 'enabled' property below disables request if projectId is undefined
      getAssignedStreamingAgentIds({ projectId }, streamingClient),
    {
      ...options,
      enabled: !!projectId,
      onError: (error) => {
        makeErrorToast({ message: error.message })
      }
    }
  )
}
