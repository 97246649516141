import { type FC } from 'react'

import classNames from 'classnames'

import { Icon, Tooltip, Typography } from '@matillion/component-library'

import classes from './InputButton.module.scss'

interface InputButtonProps {
  dataTestId: string
  onClick: () => void
  disabled?: boolean
  value?: string
  placeholder?: string
  disabledTooltipText?: string
  className?: string
  isErrored?: boolean
}

export const InputButton: FC<InputButtonProps> = ({
  dataTestId,
  onClick,
  disabled = false,
  value,
  placeholder,
  disabledTooltipText,
  className,
  isErrored = false
}) => (
  <Tooltip content={disabled ? disabledTooltipText : undefined}>
    <button
      className={classNames(
        className,
        classes.InputButton__Button,
        disabled && classes.InputButton__Disabled,
        isErrored && classes.InputButton__Errored
      )}
      type="button"
      data-testid={dataTestId}
      onClick={onClick}
      disabled={disabled}
    >
      <Typography
        aria-hidden
        className={classNames(
          classes.InputButton__Text,
          !value && placeholder && classes.InputButton__Placeholder,
          disabled && classes.InputButton__Disabled
        )}
        format="bcs"
        as="span"
      >
        {value ?? placeholder}
      </Typography>
      <Icon.Cog className={classes.InputButton__Icon} />
    </button>
  </Tooltip>
)
