import { type FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

import classnames from 'classnames'

import { Button, Modal, Typography } from '@matillion/component-library'

import { AppRoutes } from 'constants/route'

import { StreamingFeatures } from 'modules/Projects/StreamingListing/components/StreamingFeatures/StreamingFeatures'
import { getContactSalesUrl } from 'modules/utils'

import classes from './NoDataModal.module.scss'

interface NoDataModalProps {
  onClose: VoidFunction
  organisationSubdomain: string
}

export const NoDataModal: FunctionComponent<NoDataModalProps> = ({
  onClose,
  organisationSubdomain
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'streamingListing.modal'
  })
  const navigate = useNavigate()
  const { projectId } = useParams()

  return (
    <Modal
      size="mid-large"
      data-testid="list-streaming-view-features-modal"
      onCancel={onClose}
      ariaLabelledBy="modal-title"
      className={classes.NoDataModal__Modal}
    >
      <Typography
        format="dts"
        weight="bold"
        className={classnames(
          classes.NoDataModal__Title,
          classes['NoDataModal__Title--green']
        )}
      >
        {t('noData.title')}
      </Typography>
      <Typography
        format="bcl"
        weight="bold"
        className={classnames(
          classes.NoDataModal__SupportText,
          classes['NoDataModal__SupportText--white']
        )}
      >
        {t('noData.titleSupport')}
      </Typography>
      <StreamingFeatures />
      <div className={classes.NoDataModal__ButtonWrapper}>
        <Button
          text={t('common.talkToSales')}
          alt="secondary"
          size="lg"
          onClick={() => {
            window.open(getContactSalesUrl(organisationSubdomain), '_self')
          }}
          className={classes.NoDataModal__Button}
          data-testid="talk-to-sales-modal-button"
        />
        <Button
          text={t('noData.addPipeline')}
          alt="positive"
          size="lg"
          onClick={() => {
            navigate(AppRoutes.getStreamingPipelineCreate(projectId!))
          }}
          className={classnames(
            classes.NoDataModal__Button,
            classes.NoDataModal__AddPipelineButton
          )}
          data-testid="add-pipeline-modal-button"
        />
      </div>
    </Modal>
  )
}
