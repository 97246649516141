import { type FunctionComponent, type SVGProps } from 'react'

export const Databricks: FunctionComponent<SVGProps<SVGSVGElement>> = ({
  'aria-label': ariaLabel = 'Databricks Logo',
  ...otherProps
}) => {
  return (
    <svg
      aria-label={ariaLabel}
      viewBox="-2.5 0 80 80"
      width="80"
      height="80"
      {...otherProps}
    >
      <path
        d="M 70.073 32.939 L 36.916 51.639 L 1.69 31.724 L 0.046 32.608 L 0.046 47.06 L 36.956 67.981 L 70.111 49.288 L 70.111 56.974 L 36.956 75.676 L 1.69 55.76 L 0.046 56.642 L 0.046 59.079 L 36.956 80 L 74 59.079 L 74 44.625 L 72.355 43.741 L 36.916 63.659 L 3.88 44.957 L 3.88 37.396 L 36.916 55.964 L 73.956 35.043 L 73.956 20.799 L 72.057 19.789 L 36.916 39.577 L 5.655 21.894 L 36.916 4.331 L 62.725 18.785 L 65.045 17.563 L 65.045 15.796 L 36.916 0 L 0 20.837 L 0 23.071 L 36.916 43.991 L 70.073 25.295 L 70.073 32.939 Z"
        fill="#FF3621"
      />
    </svg>
  )
}
