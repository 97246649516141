import { MhaStatus } from 'api/types'

export const isMHARunning = (status: string | undefined): boolean => {
  if (!status) {
    return false
  }

  return (
    status.toLowerCase() === MhaStatus.HEALTHY.toLowerCase() ||
    status.toLowerCase() === MhaStatus.RUNNING.toLowerCase()
  )
}

export const isMHAFailed = (status: string | undefined): boolean => {
  if (!status) {
    return false
  }

  return status.toLowerCase() === MhaStatus.FAILURE.toLowerCase()
}
