import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { useFormikContext } from 'formik'

import {
  GitFeatureFlagProvider,
  ProviderSelectionForm,
  type DeploymentEnvironment
} from '@matillion/git-component-library'

import config from 'config'

import { AppRoutes } from 'constants/route'

import type { CreateProjectFormikValueTypes } from 'modules/Projects/CreateProject/CreateProjectForm'

export const GitProviderSelectionContent = () => {
  const {
    values: { provider },
    submitForm
  } = useFormikContext<CreateProjectFormikValueTypes>()
  const navigate = useNavigate()

  // Handle matillion hosted git selection
  useEffect(() => {
    if (provider === 'matillion') {
      navigate(AppRoutes.getProjectsAddAgentDeployment())
    }
  }, [navigate, provider])

  // Handles external git selection
  const handleSuccessfulSelection = () => {
    submitForm()
  }

  return (
    <GitFeatureFlagProvider
      environment={config.environment as DeploymentEnvironment}
    >
      <ProviderSelectionForm
        onSuccessfulSelection={handleSuccessfulSelection}
      />
    </GitFeatureFlagProvider>
  )
}
