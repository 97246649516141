import { type FunctionComponent } from 'react'

import classnames from 'classnames'

import { Typography, type TypographyProps } from '@matillion/component-library'

import { TypographyWithToolTip } from 'components/TypographyWithTooltip'

import classes from 'modules/Projects/StreamingPipelineDashboard/Common/components/HeadingWithContent/HeadingWithContent.module.scss'

interface HeadingWithContentProps {
  heading: string
  content: string
  className?: string
  'data-testid'?: string
  headingFormat: Extract<TypographyProps['format'], string>
  contentFormat: Extract<TypographyProps['format'], string>
  alignContent?: 'left' | 'right'
}
export const HeadingWithContent: FunctionComponent<HeadingWithContentProps> = ({
  heading,
  content,
  className,
  headingFormat,
  contentFormat,
  'data-testid': dataTestId,
  alignContent = 'left'
}) => {
  return (
    <div
      className={classnames(
        classes.HeadingWithContent__HeadingWithContent,
        className,
        {
          [classes.HeadingWithContent__RightAlign]: alignContent === 'right'
        }
      )}
      data-testid={dataTestId ?? 'heading-content-pair'}
    >
      <Typography
        format={headingFormat}
        className={classes.HeadingWithContent__Heading}
        data-testid="heading"
      >
        {heading}
      </Typography>
      <div
        className={classnames({
          [classes.HeadingWithContent__RightAlign]: alignContent === 'right'
        })}
      >
        <TypographyWithToolTip
          tooltip={content}
          format={contentFormat}
          className={classes.HeadingWithContent__Content}
          data-testid="content"
        >
          {content}
        </TypographyWithToolTip>
      </div>
    </div>
  )
}
