import { type SortOpts, type SortState } from '@matillion/component-library'

import { type GETStreamingPipelineSummaryResponse } from 'api/types'

import {
  hasDatabaseProperty,
  STREAMING_DESTINATIONS,
  STREAMING_SOURCES,
  StreamingDestinationId,
  type AzureBlobDestinationConfigType,
  type DestinationConfigType,
  type S3DestinationConfigType,
  type SnowflakeDestinationConfigType
} from 'types/Streaming'

import { ColumnKeys, type SortableColumnsType } from './types'

export const streamingPipelineSort =
  (sortState: Partial<SortState<SortableColumnsType>>) =>
  (
    a: GETStreamingPipelineSummaryResponse,
    b: GETStreamingPipelineSummaryResponse
  ): number => {
    const sortStateType = Object.keys(sortState)[0] as ColumnKeys
    const sortOrder: SortOpts | undefined = Object.values(sortState)[0]
    let valueA
    let valueB
    switch (sortStateType) {
      case ColumnKeys.source:
        valueA = getSourceDescriptor(a.source)
        valueB = getSourceDescriptor(b.source)
        break
      case ColumnKeys.destination:
        valueA = getDestinationDescriptor(a.target)
        valueB = getDestinationDescriptor(b.target)
        break
      case ColumnKeys.status:
        valueA = a.status
        valueB = b.status
        break
      default:
        valueA = a.name
        valueB = b.name
    }

    if (sortOrder === 'ASC') {
      return valueA.localeCompare(valueB)
    } else {
      return valueB.localeCompare(valueA)
    }
  }

export const getSourceDescriptor = (
  source: GETStreamingPipelineSummaryResponse['source']
) => {
  const sourceName = STREAMING_SOURCES[source.type].name
  if (!source.connection) {
    return sourceName
  }
  const dbString: string =
    hasDatabaseProperty(source) && source.connection.database
      ? source.connection.database
      : ''
  return `${sourceName}.${dbString}.${getString(source.connection.host)}`
}

export const getDestinationDescriptor = (
  destination: DestinationConfigType
) => {
  switch (destination.type) {
    case StreamingDestinationId.Snowflake: {
      const snowflakeDestination = destination as SnowflakeDestinationConfigType
      return `${STREAMING_DESTINATIONS[snowflakeDestination.type].name}.${getString(snowflakeDestination.database)}.${getString(snowflakeDestination.warehouse)}`
    }
    case StreamingDestinationId.S3: {
      const s3Destination = destination as S3DestinationConfigType
      return `${STREAMING_DESTINATIONS[s3Destination.type].name}.${getString(s3Destination.bucket)}.${getString(s3Destination.prefix)}`
    }
    case StreamingDestinationId.ABS: {
      const absDestination = destination as AzureBlobDestinationConfigType
      return `${STREAMING_DESTINATIONS[absDestination.type].name}.${getString(absDestination.container)}.${getString(absDestination.prefix)}`
    }
  }
}

const getString = (value?: string) => value ?? ''
