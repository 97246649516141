import {
  useEffect,
  useState,
  type ComponentProps,
  type FunctionComponent
} from 'react'

import {
  AutoComplete,
  Badge,
  type AutoCompleteProps,
  type FieldWidget
} from '@matillion/component-library'

import { getStatusProps } from 'modules/Common/WarehouseSources/Databricks/Defaults/getStatus'

const ComputeInput: FunctionComponent<ComponentProps<typeof AutoComplete>> = ({
  availableItems,
  ...props
}: AutoCompleteProps) => {
  const [givenWidgets, setGivenWidgets] = useState(
    new Map<string | number, FieldWidget>()
  )
  useEffect(() => {
    const tempMap = new Map(givenWidgets)
    for (const item of availableItems) {
      tempMap.set(item.id, {
        label: (
          <Badge
            data-testid="status-badge-row-item"
            theme="filled"
            {...getStatusProps(item)}
          />
        ),
        visible: true
      })
    }
    setGivenWidgets(tempMap)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availableItems.toString()])

  return (
    <div>
      <AutoComplete
        data-testid="databricks-defaults-compute-input"
        name="compute"
        {...props}
        availableItems={availableItems}
        givenWidgets={givenWidgets}
      />
    </div>
  )
}

export default ComputeInput
