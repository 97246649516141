import { useMutation, type UseMutationOptions } from 'react-query'

import { type AxiosError } from 'axios'

import { updateEnvironmentMembers } from 'api/clients'
import { useClient } from 'api/hooks/useClient'
import type { PUTEnvironmentMembersRequestBody } from 'api/types/EnvironmentUsersRequest'

import { QueryKey } from 'constants/endpoint'

type TError = AxiosError<unknown>
interface TVariables {
  values: PUTEnvironmentMembersRequestBody
}

export const usePutEnvironmentMembers = (
  environmentId: string,
  options?: UseMutationOptions<unknown, AxiosError, TVariables>
) => {
  const client = useClient()

  return useMutation<unknown, TError, TVariables>(
    [QueryKey.EDIT_ENVIRONMENT_MEMBERS, environmentId],
    async (data: TVariables) => {
      const { values } = data
      return updateEnvironmentMembers({ environmentId }, values, client)
    },
    options
  )
}
