import { useMutation, type UseMutationOptions } from 'react-query'

import { type AxiosError } from 'axios'

import { completeOAuthV2 } from 'api/clients'
import { useClient } from 'api/hooks'
import { type POSTCompleteOAuthRequestV2 } from 'api/types'

import { QueryKey } from 'constants/endpoint'

type TError = AxiosError<unknown>

interface TVariables {
  values: POSTCompleteOAuthRequestV2
}

type Options = UseMutationOptions<void, AxiosError, TVariables>

export const useCompleteOAuthV2 = (projectId: string, options?: Options) => {
  const client = useClient({ showErrorDetailMessage: true })

  // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
  return useMutation<void, TError, TVariables>(
    [QueryKey.COMPLETE_OAUTH, projectId],
    async (data: { values: POSTCompleteOAuthRequestV2 }) => {
      const { values } = data
      return completeOAuthV2(values, { projectId }, client)
    },
    options
  )
}
