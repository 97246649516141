import { type FunctionComponent, type PropsWithChildren } from 'react'

import { Typography } from '@matillion/component-library'

import classes from './Modal.module.scss'

interface ModalProps {
  'data-testid'?: string
}

export const ModalHeading: FunctionComponent<PropsWithChildren<ModalProps>> = ({
  'data-testid': dataTestId,
  children
}) => {
  return (
    <div className={classes.Modal__Heading}>
      <Typography as="h2" format="tl" id="modal-title" data-testid={dataTestId}>
        {children}
      </Typography>
    </div>
  )
}
