import { type FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'

import { Breadcrumbs } from 'components/Breadcrumbs'
import { Header, HeaderTitle, HeaderWithCTA } from 'components/Header'

import { AppRoutes } from 'constants/route'

import { StreamingDashboardActions } from 'modules/Projects/StreamingPipelineDashboard/Header/components/index'
import classes from 'modules/Projects/StreamingPipelineDashboard/Header/components/StreamingDashboardHeader/StreamingDashboardHeader.module.scss'
import StreamingDashboardStatus from 'modules/Projects/StreamingPipelineDashboard/Header/components/StreamingDashboardStatus/StreamingDashboardStatus'

import {
  type StreamingPipelineMetrics,
  type StreamingPipelineStatus
} from 'types/Streaming'

interface StreamingDashboardHeaderProps {
  projectId: string
  pipelineId: string
  pipelineName: string
  status: StreamingPipelineStatus
  metrics?: StreamingPipelineMetrics
}

const StreamingDashboardHeader: FunctionComponent<
  StreamingDashboardHeaderProps
> = ({ projectId, pipelineId, pipelineName, metrics, status }) => {
  const { t } = useTranslation()

  const breadcrumbList = [
    {
      text: t('streamingPipelineDashboard.backtrack'),
      href: AppRoutes.getStreamingPipelinesListing(projectId),
      isLoading: false
    }
  ]

  return (
    <div
      className={classes.StreamingPipelineHeader}
      data-testid="streaming-pipeline-dashboard"
    >
      <Breadcrumbs
        items={breadcrumbList}
        data-testid="streaming-dashboard-breadcrumbs"
        className={classes.StreamingPipelineHeader__Breadcrumbs}
      />
      <Header
        fullWidth
        centerAlign={false}
        className={classes.StreamingPipelineHeader__Header}
      >
        <HeaderWithCTA>
          <>
            <HeaderTitle
              data-testid="streaming-dashboard-title"
              centerAlign={false}
              format="tl"
              withBottomSpace={false}
              className={classes.StreamingPipelineHeader__HeaderText}
            >
              {pipelineName}
            </HeaderTitle>
            <StreamingDashboardActions
              projectId={projectId}
              pipelineId={pipelineId}
              pipelineName={pipelineName}
              status={status}
            />
          </>
        </HeaderWithCTA>
      </Header>
      <StreamingDashboardStatus status={status} metrics={metrics} />
    </div>
  )
}

export default StreamingDashboardHeader
