import { type FunctionComponent } from 'react'

import { HeadingWithContent } from 'modules/Projects/StreamingPipelineDashboard/Common/components/HeadingWithContent/HeadingWithContent'
import classes from 'modules/Projects/StreamingPipelineDashboard/Summary/components/StreamingSummaryBlock/StreamingSummaryBlock.module.scss'

export interface SummaryField {
  heading: string
  content: string
  testId?: string
}

interface StreamingSummaryBlockProps {
  fields: SummaryField[]
}
export const StreamingSummaryBlock: FunctionComponent<
  StreamingSummaryBlockProps
> = ({ fields }) => {
  return (
    <div className={classes.StreamingSummaryBlock__Detail}>
      {fields.map((field, i) => (
        <HeadingWithContent
          heading={field.heading}
          content={field.content}
          key={field.heading}
          headingFormat="mc"
          contentFormat="bcs"
        />
      ))}
    </div>
  )
}
