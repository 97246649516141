import { type FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'

import { type AxiosError } from 'axios'

import { Loader, Toaster } from '@matillion/component-library'

import {
  useGetEnvironments,
  useGetProject,
  usePostSecretReference,
  usePostSecretValueV2
} from 'api/hooks'
import { createSecretValueV2MutationData } from 'api/mutation'

import Form from 'components/Form'

import { QueryKey } from 'constants/endpoint'
import { AppRoutes, PROJECT_DETAILS_SECRET_DEFINITIONS } from 'constants/route'

import {
  initialValuesCreate,
  type FormValues
} from 'modules/Secrets/CreateSecretDefinitionForm/CreateSecretDefinitionForm.utils'
import SecretDefinitionForm from 'modules/Secrets/SecretDefinitionFormContent'

import { AgentsSecretsHost, SecretReferenceTypes } from 'types'
import { AgentCloudProvider } from 'types/AgentCloudProvider'

import { useSecretDefinitionFormContext } from './SecretDefinitionFormContext'

const CreateSecretDefinitionForm: FunctionComponent = () => {
  const { t } = useTranslation()
  const { projectId } = useParams()
  const navigate = useNavigate()
  const { makeToast } = Toaster.useToaster()
  const queryClient = useQueryClient()
  const { mutateAsync: mutateSecretReference } =
    usePostSecretReference(projectId)
  const { mutateAsync: mutateSecretValueV2 } = usePostSecretValueV2({
    projectId
  })

  const { validationSchema } = useSecretDefinitionFormContext()

  const { data: projectData, isLoading: isProjectLoading } = useGetProject(
    projectId!
  )

  const { data: environments, isLoading: isEnvironmentsLoading } =
    useGetEnvironments(projectId!)
  const agentAndSecretHost = projectData?.agentAndSecretHostLocation
  const locationId = projectData?.secretLocationIds[0] as string
  const agentId = environments?.[0].agentId as string
  const isMHA = agentAndSecretHost === AgentsSecretsHost.MatillionHosted

  const navigateToProjectDetails = () => {
    navigate(
      AppRoutes.getProjectDetails(
        projectId!,
        PROJECT_DETAILS_SECRET_DEFINITIONS
      )
    )
  }
  const onSubmit = async (values: FormValues) => {
    try {
      if (agentAndSecretHost === AgentsSecretsHost.MatillionHosted) {
        await mutateSecretValueV2({
          values: createSecretValueV2MutationData(
            {
              ...values,
              matillionHostedAgentId: agentId,
              secretLocationId: locationId,
              secretValue: {
                password: values.secretValue!
              },
              metadata: {
                cloudProviderID: AgentCloudProvider.MATILLION
              }
            },
            false,
            SecretReferenceTypes.PASSWORD
          )
        })
        makeToast({
          title: t('secrets.createSecretDefinition.responseMessage.success', {
            secretDefinitionName: values.name
          }),
          message: '',
          type: 'success'
        })
      } else {
        await mutateSecretReference({
          values: {
            ...values,
            locationId: values.locationId ?? locationId,
            metadata: {
              cloudProviderID: values.agent.agentCloudProvider
            }
          }
        })
        makeToast({
          title: t('secrets.createSecretDefinition.responseMessage.success', {
            secretDefinitionName: values.name
          }),
          message: '',
          type: 'success'
        })
      }
      queryClient.invalidateQueries(QueryKey.SECRET_REFERENCES)

      navigateToProjectDetails()
    } catch (error: unknown) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const err = error as AxiosError<any>

      // If neither of these match then a generic error message is given
      const messageString = err?.response?.data?.detail

      let errorMessage = t(
        'secrets.createSecretDefinition.responseMessage.error'
      )
      if (messageString) {
        errorMessage = messageString
      }
      makeToast({
        title: errorMessage,
        message: '',
        type: 'error'
      })
    }
  }

  if (isProjectLoading || isEnvironmentsLoading) {
    return <Loader />
  }

  return (
    <Form<FormValues>
      formikValues={{
        validationSchema: validationSchema,
        onSubmit: onSubmit,
        initialValues: initialValuesCreate(locationId),
        validateOnMount: true,
        initialTouched: false
      }}
      translationPrefix="secrets.createSecretDefinition"
    >
      <SecretDefinitionForm
        isMHA={isMHA}
        locationId={locationId}
        onCancel={navigateToProjectDetails}
        showCancel
      />
    </Form>
  )
}

export default CreateSecretDefinitionForm
