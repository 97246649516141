import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, type To } from 'react-router-dom'

import { type AxiosError } from 'axios'
import { type FormikHelpers } from 'formik'
import { v4 as uuidv4 } from 'uuid'

import { Toaster } from '@matillion/component-library'
import { useLDClient } from '@matillion/hub-client'

import { WarehouseLookupTypes } from 'api/createProjectForm/types'
import {
  useMutateDatabricksComputeResources,
  useMutateWarehouseDefaults,
  usePostSecretValueV2
} from 'api/hooks'
import { usePatchSecretValueV2 } from 'api/hooks/usePatchSecretValueV2'
import {
  createSecretValueV2MutationData,
  createSecretValueV2UpsertMutationData,
  createWarehouseDefaultsMutation
} from 'api/mutation'
import {
  type ErrorResponse,
  type PATCHSecretValueV2Request,
  type POSTSecretValueV2Request
} from 'api/types'

import {
  AppRoutes,
  PROJECTS_CLOUD_CREDENTIALS,
  PROJECTS_CREATE_AGENT,
  PROJECTS_CREDENTIALS,
  PROVISIONING_TRIAL_WAREHOUSE,
  WAREHOUSE
} from 'constants/route'

import { useOnboardingContext } from 'context'

import { useFormNavigation } from 'hooks'
import { useFlags } from 'hooks/flags'

import { useMarketo } from 'marketo/hooks'

import { type OnboardingFormikValueTypes } from 'modules/Onboarding/OnboardingForm'
import {
  useFinishOnboarding,
  useOnboardingSteps
} from 'modules/Onboarding/OnboardingForm/hooks'

import { Warehouse } from 'types'
import { CloudProviders } from 'types/CloudProviders'

export const useOnboardingSubmit = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [callToMarketo] = useMarketo()
  const { makeToast, clearToasts } = Toaster.useToaster()
  const { mutateAsync: mutateAsyncSecretValueV2Post } = usePostSecretValueV2({
    showErrorDetailMessage: false
  })
  const { mutateAsync: mutateAsyncSecretValueV2Patch } = usePatchSecretValueV2()
  const { mutateAsync: mutateAsyncWarehouseRoleLookup } =
    useMutateWarehouseDefaults(WarehouseLookupTypes.ROLE)
  const { mutateAsync: mutateAsyncWarehouseComputeResourceLookup } =
    useMutateDatabricksComputeResources()
  const { mutateAsync: mutateAsyncWarehouseDatabaseLookup } =
    useMutateWarehouseDefaults(WarehouseLookupTypes.DATABASE)
  const { mutateAsync: mutateAsyncWarehouseLocationLookup } =
    useMutateWarehouseDefaults(WarehouseLookupTypes.LOCATION)
  const { enableKeyPairAuthentication } = useFlags()

  const {
    setProjectType,
    setSecretReferenceId,
    isTrialWarehouse,
    setAwsSecretReferenceId,
    awsSecretReferenceId
  } = useOnboardingContext()

  const { mutateProject: finishOnboarding } = useFinishOnboarding()

  const steps = useOnboardingSteps(isTrialWarehouse)

  const formNavigation = useFormNavigation(steps)
  const { isLastStep, nextStep, currentStep } = formNavigation

  const location = useLocation()
  const { track: sendLDMetric, flush: flushLDMetrics } = useLDClient()

  const validateCloudCredentials = async (
    values: OnboardingFormikValueTypes
  ) => {
    return mutateAsyncWarehouseLocationLookup({
      values: createWarehouseDefaultsMutation({
        ...values,
        etlAgent: {
          id: '',
          name: ''
        }
      })
    })
  }

  const sendExperimentMetrics = async () => {
    if (
      location.pathname.includes(
        `${AppRoutes.getOnboardingAdd()}/${PROJECTS_CREDENTIALS}`
      )
    ) {
      sendLDMetric('snowflake-credentials-form-continue-success')
      await flushLDMetrics()
    }
  }

  const getSecretReferenceIdOrName = (values: {
    awsSecretReferenceId?: string
    projectName: string
    environmentName: string
  }) => {
    const name = `${uuidv4().substring(0, 4)}-DefaultCredentials`
    return values.awsSecretReferenceId
      ? { secretReferenceId: values.awsSecretReferenceId }
      : { secretName: name, name }
  }

  const upsertCloudCredentials = async (
    values: POSTSecretValueV2Request | PATCHSecretValueV2Request
  ) => {
    if ('secretReferenceId' in values) {
      await mutateAsyncSecretValueV2Patch({ values })
      return { secretId: values.secretReferenceId }
    } else {
      return mutateAsyncSecretValueV2Post({ values })
    }
  }

  const handleNextStep = async (newValues: OnboardingFormikValueTypes) => {
    if (currentStep === PROJECTS_CLOUD_CREDENTIALS) {
      const { secretId } = await upsertCloudCredentials(
        createSecretValueV2UpsertMutationData({
          agentId: newValues.matillionHostedAgentId,
          secretLocationId: newValues.secretLocationId,
          secretValue: {
            accessKeyID: newValues.awsAccessKeyId,
            secretKey: newValues.awsSecretAccessKey,
            type: CloudProviders.AWS
          },
          ...getSecretReferenceIdOrName({
            ...newValues,
            awsSecretReferenceId:
              newValues.awsSecretReferenceId || awsSecretReferenceId
          })
        })
      )

      setAwsSecretReferenceId(secretId)
      newValues = {
        ...newValues,
        awsSecretReferenceId: secretId
      }
      await validateCloudCredentials(newValues)
    }

    if (currentStep === WAREHOUSE) {
      setProjectType(newValues.type)
    }

    if (currentStep === PROJECTS_CREDENTIALS) {
      let passphraseResponse
      const response = await mutateAsyncSecretValueV2Post({
        values: createSecretValueV2MutationData({
          ...newValues,
          secretValue: {
            password: newValues.secretValue
          },
          name: newValues.environmentName
        })
      })

      if (newValues.passphrase && enableKeyPairAuthentication) {
        passphraseResponse = await mutateAsyncSecretValueV2Post({
          values: createSecretValueV2MutationData({
            ...newValues,
            name: newValues.environmentName,
            secretValue: {
              password: newValues.passphrase
            }
          })
        })
      }

      setSecretReferenceId(response.secretId)
      newValues = {
        ...newValues,
        secretReferenceId: response.secretId,
        passphraseSecretReferenceId: passphraseResponse?.secretId
      }

      // Ensure that there is a connection to warehouse defaults role in order to continue
      // Otherwise it throws an error which is caught below and does not proceed to the next page
      switch (newValues.type) {
        case Warehouse.Snowflake:
          await mutateAsyncWarehouseRoleLookup({
            values: createWarehouseDefaultsMutation(
              {
                ...newValues,
                etlAgent: {
                  id: '',
                  name: ''
                }
              },
              !!enableKeyPairAuthentication
            )
          })
          await sendExperimentMetrics()
          break
        case Warehouse.Redshift:
          await mutateAsyncWarehouseDatabaseLookup({
            values: createWarehouseDefaultsMutation({
              ...newValues,
              etlAgent: {
                id: '',
                name: ''
              }
            })
          })
          break
        case Warehouse.Databricks:
          await mutateAsyncWarehouseComputeResourceLookup({
            values: createWarehouseDefaultsMutation({
              ...newValues,
              etlAgent: {
                id: '',
                name: ''
              }
            })
          })
          break
      }
    } else if (nextStep === PROVISIONING_TRIAL_WAREHOUSE) {
      callToMarketo('clickLink', '/onboarding/matillion-trial-warehouse')
    } else if (nextStep === PROJECTS_CREATE_AGENT) {
      callToMarketo('clickLink', '/onboarding/customer-own-warehouse')
    }
    return newValues
  }

  const submitForm = async (
    newValues: OnboardingFormikValueTypes,
    { resetForm, setSubmitting }: FormikHelpers<OnboardingFormikValueTypes>
  ) => {
    clearToasts()
    setSubmitting(true)
    if (isLastStep) {
      await finishOnboarding(newValues)
    } else {
      try {
        newValues = await handleNextStep(newValues)
        navigate(nextStep as To, { replace: true })
      } catch (error: unknown) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const err = error as AxiosError<any>

        const errorDetail = (err?.response?.data as ErrorResponse)?.detail

        makeToast({
          title: t('error.unexpected.title'),
          message: errorDetail ?? t('error.unexpected.message'),
          type: 'error'
        })
      } finally {
        setProjectType(newValues.type)
        // Resetting form with the new user-inputted field defaults to reset validation within the wizard
        resetForm({ values: newValues })
      }
    }
  }

  return {
    submitForm,
    steps,
    ...formNavigation
  }
}
