import { useFormikContext } from 'formik'

import { DefaultRole } from 'modules/Common/Fields'
import { type CreateProjectFormikValueTypes } from 'modules/Projects/CreateProject/CreateProjectForm'

const SnowflakeRole = () => {
  const { values } = useFormikContext<CreateProjectFormikValueTypes>()
  const isFieldEnabled =
    !!values.secretReferenceId && !!values.account && !!values.username
  const listenerFields: Array<keyof CreateProjectFormikValueTypes> = [
    'secretReferenceId',
    'account',
    'username'
  ]
  return (
    <DefaultRole
      isFieldEnabled={isFieldEnabled}
      listenerFields={listenerFields}
    />
  )
}

export default SnowflakeRole
