import { useMutation, type UseMutationOptions } from 'react-query'

import { type AxiosError } from 'axios'

import { patchBranch } from 'api/clients'
import { type PATCHBranchRequest } from 'api/types'

import { QueryKey } from 'constants/endpoint'

import { useClient } from './useClient'

type TData = unknown
type TError = AxiosError<unknown>
interface TVariables {
  values: PATCHBranchRequest
}

type Options = UseMutationOptions<unknown, AxiosError, TVariables>

export const usePatchBranch = (
  projectId: string,
  branchId: string,
  options?: Options
) => {
  const client = useClient()

  return useMutation<TData, TError, TVariables>(
    [QueryKey.PATCH_BRANCH, projectId, branchId],
    async (data: { values: PATCHBranchRequest }) => {
      const { values } = data
      return patchBranch(
        values,
        {
          projectId,
          branchId
        },
        client
      )
    },
    options
  )
}
