import { type FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'

import { Toaster } from '@matillion/component-library'

import { useDeleteBranch } from 'api/hooks'

import { RedWarning } from 'components/Icon'
import ConfirmCancelModal from 'components/Modal/ProjectExplorerModal'

import { QueryKey } from 'constants/endpoint'

interface DeleteBranchModalProps {
  resetModals: () => void
  branchId: string
  branchName: string
}

const DeleteBranchModal: FunctionComponent<DeleteBranchModalProps> = ({
  resetModals,
  branchId,
  branchName
}) => {
  const { t } = useTranslation()
  const { mutateAsync: deleteBranchMutation, isLoading } = useDeleteBranch()
  const { projectId } = useParams()
  const { makeToast } = Toaster.useToaster()
  const queryClient = useQueryClient()

  const handleDelete = () => {
    deleteBranchMutation({
      values: { branchId: branchId, projectId: projectId! }
    })
      .then((_r) => {
        resetModals()
        queryClient.invalidateQueries([QueryKey.BRANCHES_LIST, projectId])
        makeToast({
          title: t('deleteBranch.success', {
            branchName: branchName
          }),
          message: '',
          type: 'success'
        })
      })
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      .catch(() => {})
  }

  return (
    <ConfirmCancelModal
      heading={t('deleteBranch.title')}
      content={t('deleteBranch.supportText')}
      validate={t('deleteBranch.buttons.submit')}
      onCancel={resetModals}
      onValidate={handleDelete}
      icon={<RedWarning />}
      waiting={isLoading}
    />
  )
}

export default DeleteBranchModal
