import { useEffect, type FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { useFormikContext } from 'formik'

import {
  AutoComplete,
  Field,
  LoadingSpinner
} from '@matillion/component-library'

import { useGetBranch, useGetEnvironments } from 'api/hooks'
import type { GETBranchResponse, GETEnvironmentsResponse } from 'api/types'

import classes from 'modules/Projects/EditBranch/EditBranch.module.scss'
import {
  resolveErrorText,
  type FormValues
} from 'modules/Projects/EditBranch/EditBranchForm.util'

const Environment: FunctionComponent<{
  availableEnvironments: GETEnvironmentsResponse
}> = ({ availableEnvironments }) => {
  const { t } = useTranslation()
  const { projectId, branchId } = useParams()

  const { getFieldProps, getFieldMeta, setFieldValue } =
    useFormikContext<FormValues>()

  const undefinedBranch: GETBranchResponse = {
    id: '',
    name: '',
    defaultEnvironmentName: '',
    defaultEnvironmentId: ''
  }

  const { data: environmentsData = [], isLoading: environmentsLoading } =
    useGetEnvironments(projectId!)
  const { data: defaultBranch = undefinedBranch, isLoading: branchLoading } =
    useGetBranch(projectId!, branchId!)

  const fieldName = 'defaultEnvironment'
  const field = getFieldProps(fieldName)
  const meta = getFieldMeta(fieldName)
  const env = availableEnvironments
    .map((x) => ({ id: x.id, name: x.name }))
    .sort((a, b) => a.name.localeCompare(b.name))

  useEffect(() => {
    console.log(environmentsData.length > 0, field.value?.id)
    if (environmentsData.length > 0 && !field.value?.id) {
      console.log('updating')
      const defaultEnvironment = environmentsData.find(
        (environment) =>
          environment.name === defaultBranch.defaultEnvironmentName
      )

      if (defaultEnvironment) {
        setFieldValue(fieldName, {
          id: defaultEnvironment.id,
          name: defaultEnvironment.name
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultBranch.defaultEnvironmentName, environmentsData, setFieldValue])

  return (
    <div data-testid="edit-branch-default-environment">
      <Field
        {...field}
        data-testid="edit-branch-default-environment-input"
        className={classes['EditBranch__Field--spaced']}
        title={t('editBranch.fields.defaultEnvironment.title')}
        name={fieldName}
        placeholder={t('editBranch.fields.defaultEnvironment.placeholderText')}
        inputComponent={
          branchLoading || environmentsLoading ? LoadingSpinner : AutoComplete
        }
        availableItems={env}
        errorText={resolveErrorText(t, meta)}
        value={field.value || null}
        isLoading={branchLoading || environmentsLoading}
      />
    </div>
  )
}

export default Environment
