import { useParams } from 'react-router-dom'

import { object } from 'yup'
import { type OptionalObjectSchema } from 'yup/lib/object'

import { useGetEnvironments, useGetProject } from 'api/hooks'

import { useFormNavigation } from 'hooks'
import { useFlags } from 'hooks/flags'

import {
  genericValuesSchema,
  steps
} from 'modules/Projects/CreateEnvironment/CreateEnvironmentForm/CreateEnvironmentForm.util'
import { type CreateEnvironmentSteps } from 'modules/Projects/CreateEnvironment/CreateEnvironmentForm/types'
import { warehouseValuesSchema } from 'modules/Projects/CreateProject/CreateProjectForm'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useValidationSchema = (): OptionalObjectSchema<any> => {
  const { currentStep } = useFormNavigation<CreateEnvironmentSteps>(steps)
  const { projectId } = useParams()
  const { data: projectData } = useGetProject(projectId!)
  const { data: environments } = useGetEnvironments(projectId!)
  const { enableKeyPairAuthentication } = useFlags()
  const projectType = projectData?.warehouse ?? ''

  const warehouseSchema = warehouseValuesSchema(
    projectType,
    false,
    !!enableKeyPairAuthentication
  )
  const valuesSchema = {
    ...genericValuesSchema(environments?.map((x) => x.name)),
    ...warehouseSchema
  }

  return currentStep ? valuesSchema[currentStep] : object({})
}
